import * as widgetTypes from '../components/generics/utils/widgetTypes';
import * as dataTypes from '../components/generics/utils/fieldDataTypes';
import ViewConfig, { FieldViewField, EntityField, Entity } from '../reducers/ViewConfigType';
import translateViewField from '../fieldFactory/translation/fromEntity/getTypeFromEntityField';
import range from 'lodash/range';
import { isFieldViewField } from '../components/generics/utils/viewConfigUtils';
import { validDataTypeWidgetCombinations } from './validWidgetCombinations';
// Errors within the context of a view.
// basically apply these functions to a group of fields, then filter nulls to get all errors.

export const viewFieldWidgetTypeError: (field: FieldViewField) => string | null = (field: FieldViewField) =>
    Object.values(widgetTypes).indexOf(field.widgetType) === -1
        ? `[1] Unknown widgetType ${field.widgetType} on field ${field.field}`
        : null;

export const entityFieldDataTypeError = (field: EntityField) =>
    Object.values(dataTypes).indexOf(field.dataType) === -1
        ? `[2] unknown dataType ${field.dataType} on entity field ${field.name}`
        : null;

export const widgetAndDataTypeCombinationError = (field: FieldViewField, viewConfig: ViewConfig) => {
    const entity = viewConfig.entities[field.entity];
    if (!entity) {
        return `[3] Entity ${field.entity} referred to by viewField ${field.field} does not exist`;
    }
    const fieldexpcpy = field.field.slice();
    const lastDtSeperatedField = fieldexpcpy.split('.').pop();
    if (lastDtSeperatedField) {
        const entityField = entity.fields[lastDtSeperatedField];
        if (!entityField) {
            return `[4] field ${field.field} not found on entity ${entity.name}`;
        }
        if (!validDataTypeWidgetCombinations[entityField.dataType]) {
            return `[2] (caught entity error): unknown dataType ${entityField.dataType} on ${entityField.name} for entity ${entity.name}`;
        } else if (validDataTypeWidgetCombinations[entityField.dataType].indexOf(field.widgetType) === -1) {
            return `[8] unexpected widgetType ${field.widgetType} for dataType ${entityField.dataType}`;
        }
        try {
            translateViewField(
                field.widgetType,
                viewConfig.entities[field.entity].fields[lastDtSeperatedField].dataType,
                field.field,
                field.searchType,
            );
            return null;
        } catch (e) {
            return `[8] ${e.message}`;
        }
    } else {
        throw new Error(`nothing popped when seperating field ${field.field} by .`);
    }
};

export const fieldReferenceError: (field: EntityField, viewConfig: ViewConfig) => string | null = (
    field: EntityField,
    viewConfig: ViewConfig,
) => {
    if (
        (field.dataType === 'REFMANYJOIN' ||
            field.dataType === 'REFMANY' ||
            field.dataType === 'REFMANYMANY' ||
            field.dataType === 'REFONE' ||
            field.dataType === 'REFONEJOIN') &&
        !field.relatedEntity
    ) {
        return `[5] field ${field.name} has type ${field.dataType}, but no relatedEntity`;
    }
    if ((field.dataType === 'REFMANY' || field.dataType === 'REFMANYJOIN') && !field.relatedField) {
        return `[6] field ${field.name} has type ${field.dataType} and relatedEntity ${field.relatedEntity}, but no relatedField`;
    }
    if (field.relatedEntity) {
        const errs = ['List', 'Edit', 'Show', 'Create']
            .map((viewType) => {
                if (!viewConfig.views[`${field.relatedEntity}${viewType}`]) {
                    return ` [${field.relatedEntity}${viewType}] `;
                }
                return null;
            })
            .filter((e) => e);
        if (errs.length > 0) {
            return `[7] field ${field.name} has related entity ${
                field.relatedEntity
            } but there is lacking the following views:
                ${errs.join('; ')}`;
        }
    }
    return null;
};

export const entityErrors = (entity: Entity, viewConfig: ViewConfig) => {
    const allViewFieldsthatReferenceEntity: FieldViewField[] = new Array<FieldViewField>().concat(
        ...Object.values(viewConfig.views).map(
            (v) =>
                Object.values(v.fields).filter(
                    (f) => isFieldViewField(f) && f.entity === entity.name,
                ) as FieldViewField[],
        ),
    );
    const fieldInvalidDataTypes = Object.values(entity.fields).map((f) => [f.name, entityFieldDataTypeError(f)]);
    const fieldReferenceErrors: [string, string | null][] = Object.values(entity.fields).map(
        (f: EntityField): [string, string | null] => [f.name, fieldReferenceError(f, viewConfig)],
    );
    const fieldReferenceErrorsWithFilteredType7 = fieldReferenceErrors.filter(
        ([eField, error]) =>
            (error && error.charAt(1) !== '7') || allViewFieldsthatReferenceEntity.find((vf) => vf.field === eField),
    );
    const fieldErrors: {
        [field: string]: string[];
    } = Object.assign(
        {},
        ...Object.keys(entity.fields)
            .map((fname) => ({
                [fname]: [
                    ...fieldInvalidDataTypes.filter(([name, e]) => name === fname && e).map(([_, e]) => e),
                    ...fieldReferenceErrorsWithFilteredType7
                        .filter(([name, e]) => name === fname && e)
                        .map(([_, e]) => e),
                ],
            }))
            .filter((fObj) => ([] as any[]).concat(...Object.values(fObj)).length > 0),
    );
    return fieldErrors;
};

export function isString(data: undefined | string): data is string {
    return typeof data === 'string';
}
/*
    @param entity: the base entity of the field we are looking at
    @param fieldExpr: the fieldExpression, ending in a ref field (last field in chain needs a 'relatedEntity' property.
*/
export const getFieldExprErrorIfExists = (
    viewConfig: ViewConfig,
    entity: string,
    fieldExpr: string,
    prevFieldExpr: string = '',
): string | null => {
    if (!viewConfig.entities[entity]) {
        throw Error(`Entity "${entity}" does not exist`);
    }
    const viewFieldRefPath = fieldExpr.split('.');
    const entityField = viewConfig.entities[entity].fields[viewFieldRefPath[0]];
    if (!entityField) {
        return `[1] field "${viewFieldRefPath[0]}" in fieldExpression "${prevFieldExpr}${fieldExpr}" not found on entity "${entity}"`;
    }
    if (viewFieldRefPath.length === 1) {
        if (viewConfig.entities[entity].fields[fieldExpr]) {
            return null;
        }
        return `[2] No corresponding field: "${fieldExpr}" from "${prevFieldExpr}${fieldExpr} on entity "${entity}"`;
    } else {
        const nextEntity = viewConfig.entities[entity].fields[viewFieldRefPath[0]].relatedEntity;
        if (isString(nextEntity)) {
            return getFieldExprErrorIfExists(
                viewConfig,
                nextEntity,
                range(1, viewFieldRefPath.length)
                    .map((i) => viewFieldRefPath[i])
                    .join('.'),
                `${prevFieldExpr}${viewFieldRefPath[0]}`,
            );
        }
        return `[3] relatedEntity not found on the path "${prevFieldExpr}${fieldExpr}". "${
            viewFieldRefPath[0]
        }": "entities->${viewConfig.entities[entity].name}->${
            viewConfig.entities[entity].fields[viewFieldRefPath[0]].name
        }"has no relatedEntity defined`;
    }
};

export const viewFieldErrors = (
    view: ViewConfig['views'][0],
    viewConfig: ViewConfig,
): {
    [field: string]: string[];
} => {
    return Object.assign(
        {},
        ...Object.entries(view.fields)
            .map(([key, value]) =>
                isFieldViewField(value)
                    ? {
                          [key]: [
                              getFieldExprErrorIfExists(viewConfig, view.entity, value.field),
                              widgetAndDataTypeCombinationError(value, viewConfig),
                          ].filter((e) => e),
                      }
                    : {},
            )
            .filter((obj) => (Object.values(obj)[0] || []).length > 0), // filter fields without errors
    );
};
