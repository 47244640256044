import React, { FunctionComponent, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import ControlledTextField from 'react-hook-form-utils/ControlledTextField';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import CasetivitySelect from 'components/CasetivitySelect';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

interface EditActionButtonProps {
    initialValues?: EntityViewConfig['entityActions'][0];
    onSubmit: (data: EntityViewConfig['entityActions'][0]) => void;
    disabled?: boolean;
}
const EditActionButton: FunctionComponent<EditActionButtonProps> = (props) => {
    const methods = useForm({
        defaultValues: props.initialValues && {
            ...props.initialValues,
            type: (props.initialValues as any).url
                ? 'link'
                : (props.initialValues as any).processDefinitionKey
                ? 'start-form'
                : 'action',
        },
        mode: props.disabled ? 'onSubmit' : 'onBlur',
    });
    useEffect(() => {
        methods.register('action');
        return () => {
            methods.unregister('action');
        };
    }, []); // eslint-disable-line

    const action = methods.watch('action');
    const type = methods.watch('type');
    const processDefs = useSelector((state: RootState) =>
        state.bpm.processDefinitions.allIds.map((id) => state.bpm.processDefinitions.byId[id]),
    );

    const pdkError = methods.errors?.['processDefinitionKey'];

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(({ type, ...data }: any) => {
                    if (type === 'link') {
                        const { action, processDefinitionKey, params, successMessage, ...rest } = data;
                        props.onSubmit(rest as any);
                    } else if (type === 'start-form') {
                        const { url, action, ...rest } = data as any;
                        props.onSubmit(rest as any);
                    } else {
                        const { url, processDefinitionKey, params, successMessage, ...rest } = data as any;
                        props.onSubmit(rest as any);
                    }
                })}
            >
                <FormControl component="fieldset">
                    <FormLabel component="legend">Button type</FormLabel>
                    <Controller
                        rules={{ required: true }}
                        control={methods.control}
                        name="type"
                        as={
                            <RadioGroup>
                                <FormControlLabel value="link" control={<Radio />} label="Link" />
                                <FormControlLabel value="action" control={<Radio />} label="Action" />
                                <FormControlLabel value="start-form" control={<Radio />} label="Start Form" />
                            </RadioGroup>
                        }
                    />
                </FormControl>
                <div style={{ height: '1em' }} />
                {type === 'action' && (
                    <JSONEditorDemo
                        options={{
                            mode: props.disabled ? 'view' : 'tree',
                        }}
                        json={
                            action || {
                                type: '',
                                payload: {},
                            }
                        }
                        onChangeJSON={(action) => {
                            methods.setValue('action', action, {
                                shouldDirty: true,
                                shouldValidate: true,
                            });
                        }}
                    />
                )}
                <ControlledTextField
                    disabled={props.disabled}
                    rules={{ required: 'All buttons need a label' }}
                    label="Label *"
                    name="label"
                    defaultValue={props.initialValues && props.initialValues['label']}
                />
                {type === 'link' && (
                    <ControlledTextField
                        disabled={props.disabled}
                        label="Url"
                        name="url"
                        defaultValue={props.initialValues && props.initialValues['url']}
                    />
                )}
                {type === 'start-form' && (
                    <>
                        <Controller
                            disabled={props.disabled}
                            rules={{ required: 'Start form buttons need a process definition key' }}
                            label="Process Definition Key"
                            name="processDefinitionKey"
                            defaultValue={props.initialValues?.['processDefinitionKey']}
                            control={methods.control}
                            render={(attrs) => (
                                <FormControl error={Boolean(pdkError)} style={{ width: '100%' }}>
                                    <InputLabel shrink id="ppdk-label">
                                        Select Process Definition
                                    </InputLabel>
                                    <CasetivitySelect
                                        labelId="ppdk-label"
                                        id="ppdk-sortDir"
                                        label="Select Process Definition"
                                        {...attrs}
                                        fullWidth
                                        style={{ minWidth: 100 }}
                                        SelectDisplayProps={{
                                            'aria-invalid': Boolean(pdkError),
                                            'aria-describedby': pdkError ? 'pdk-help' : undefined,
                                        }}
                                    >
                                        {({ OptionComponent }) => [
                                            <OptionComponent key="none-default" value="">
                                                None
                                            </OptionComponent>,
                                            ...processDefs
                                                .filter((pd) => pd.hasStartForm)
                                                .map((pd) => (
                                                    <OptionComponent key={pd.key} value={pd.key}>
                                                        {pd.name}
                                                    </OptionComponent>
                                                )),
                                        ]}
                                    </CasetivitySelect>
                                    {pdkError && (
                                        <FormHelperText aria-live="assertive" id={'pdk-help'} error>
                                            {pdkError?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                        <p>
                            Pass parameters to the form in a templated query string. e.g.
                            <pre>{'?field1=value1&field2=$[entityValue]'}</pre>
                        </p>
                        <ControlledTextField
                            disabled={props.disabled}
                            label="Query Parameters"
                            name="params"
                            defaultValue={props.initialValues?.['params']}
                        />
                        <ControlledTextField
                            disabled={props.disabled}
                            label="Success Message"
                            name="successMessage"
                            defaultValue={props.initialValues?.['successMessage']}
                        />
                    </>
                )}
                {type !== 'start-form' && (
                    <ControlledTextField
                        disabled={props.disabled}
                        label="Redirect rule"
                        name="redirectOnSuccess"
                        defaultValue={props.initialValues && props.initialValues['redirectOnSuccess']}
                    />
                )}
                <ControlledTextField
                    disabled={props.disabled}
                    rules={{ required: 'All buttons need a key' }}
                    label="Key *"
                    name="key"
                    defaultValue={props.initialValues && props.initialValues['key']}
                />
                <ControlledTextField
                    disabled={props.disabled}
                    label="Display Rule"
                    name="displayRule"
                    debounced={true}
                    defaultValue={props.initialValues && props.initialValues['displayRule']}
                />
                {!props.disabled && (
                    <Button color="primary" variant="contained" type="submit">
                        Save
                    </Button>
                )}
            </form>
        </FormProvider>
    );
};
export default EditActionButton;
