/**
 * This is to register and respond to differences between ts-spel and spel2js evaluated expressions encountered during runtime.
 */

import { Ast } from 'ts-spel/lib/lib/Ast';

export type Details =
    | {
          _type: 'eval';
          msg?: string;
          ast?: Ast;
          expression: string;
          relevantValues: {};
          results: [any, any];
          url?: string;
          meta?: Record<string, unknown>;
      }
    | {
          _type: 'other';
          msg?: string;
          ast?: Ast; // no ast if cannot parse
          expression: string;
          results: [any, any];
          url?: string;
          meta?: Record<string, unknown>;
      };

export class Registry {
    private resultDiffs: Details[] = [];
    private subscriptions: ((allDetails: Details[]) => void)[] = [];
    constructor() {
        this.addDiff = this.addDiff.bind(this);
        this.clearDiffs = this.clearDiffs.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
    }
    static stringify(obj: any): string {
        return JSON.stringify(obj, Object.getOwnPropertyNames(obj), 1);
    }
    public addDiff(details: Details) {
        this.resultDiffs.push({
            ...details,
            url: details.url ?? window.location.href,
        });
        this.subscriptions.forEach((cb) => {
            cb(this.resultDiffs);
        });
    }
    public clearDiffs() {
        this.resultDiffs = [];
    }
    public subscribe(cb: (allDetails: Details[]) => void) {
        this.subscriptions.push(cb);
    }
    public unsubscribe(cb: (allDetails: Details[]) => void) {
        this.subscriptions = this.subscriptions.filter((scb) => scb !== cb);
    }
}

const detailsRegistry = new Registry();

export default detailsRegistry;
