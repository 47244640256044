import * as actions from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, flatMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { of, concat, Observable } from 'rxjs';
import getValuesetsForFormAction from 'bpm/task-form/epic/getValuesetsForFormAction';
import isuuid from 'isuuid';
const getStartFormFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(actions.getStartForm)),
        withLatestFrom(state$.pipe(map((state) => state.viewConfig))),
        flatMap(
            ([action, viewConfig]): Observable<RootAction> =>
                (() => {
                    const pdid = action.payload.processDefinitionId;
                    const [pdkey, uuid] = (() => {
                        const _pdkey = pdid.slice(0, pdid.indexOf(':'));
                        const _uuid = pdid.slice(pdid.lastIndexOf(':') + 1);
                        return [_pdkey, _uuid];
                    })();
                    if (isuuid(uuid) && (window as any).CASETIVITY_USE_SERVICE_WORKER) {
                        // fetch by start-form key rather than the id with revision and uuid,
                        // so we can cache by url
                        return services.getStartFormByProcessDefinitionName(pdkey, action.payload.taskFormKey);
                    }
                    return services.getStartForm(action.payload.processDefinitionId, action.payload.taskFormKey);
                })().pipe(
                    flatMap((response) =>
                        concat<RootAction>(
                            of(actions.getStartFormSuccess(action.payload, response)),
                            of(getValuesetsForFormAction(response, viewConfig)),
                        ),
                    ),
                    catchError((err) => of(actions.getStartFormFailure(action.payload, err))),
                ),
        ),
    );
export default getStartFormFlow;
