import React, { Suspense } from 'react';
const DCMapComponent = React.lazy(() => import('./DCComponent'));
const NHMapComponent = React.lazy(() => import('./NHComponent'));
const MAMapComponent = React.lazy(() => import('./MAComponent'));

interface ComponentProps {
    version?: 'ma' | 'dc' | 'nh';
}
const Component: React.FunctionComponent<ComponentProps> = ({ version }) => {
    const Loading = <div>Loading...</div>;
    if (version === 'dc') {
        return (
            <Suspense fallback={Loading}>
                <DCMapComponent />
            </Suspense>
        );
    }
    if (version === 'nh') {
        return (
            <Suspense fallback={Loading}>
                <NHMapComponent />
            </Suspense>
        );
    }
    return (
        <Suspense fallback={Loading}>
            <MAMapComponent />
        </Suspense>
    );
};
export default { Component };
