import * as dca from './actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { Dashboard, Widget, DashboardConfig } from '../types';
import { Subtract } from 'utility-types';
import { initial, success, pending, failure } from '@devexperts/remote-data-ts';
import { LOAD_VIEW_CONFIG_SUCCESS } from 'viewConfig/constants';
import { serialize, serialrd } from 'reducers/lists/list/serializeDeserialize';

interface WidgetMap {
    [widgetId: string]: Widget;
}
// convert the WidgetArray to a mapping indexed by the widget id
export type DashboardConfigWithWidgetMap = Subtract<
    Dashboard['dashboardConfig'],
    Pick<Dashboard['dashboardConfig'], 'widgets'>
> & {
    widgets: WidgetMap;
};

export const getDashConfigWithWidgetMap = (dc: DashboardConfig): DashboardConfigWithWidgetMap => {
    return {
        ...dc,
        widgets: dc.widgets.reduce((prev, curr) => {
            prev[curr.id] = curr;
            return prev;
        }, {} as WidgetMap),
    };
};

interface DashboardConfigState {
    configs: {
        [displayName: string]: {
            readyForWidgetsToDisplay: boolean;
            dashboardConfig: DashboardConfigWithWidgetMap;
        } & Pick<Dashboard, 'hideConfigChange' | 'id' | 'displayName'>;
    };
    allDashboardNames: serialrd<string[], Error>;
    loadedAllOnce: boolean;
}
const initialState = { configs: {}, allDashboardNames: serialize(initial), loadedAllOnce: false };

export default function DashboardConfigReducer(
    state: DashboardConfigState = initialState,
    action: RootAction,
): DashboardConfigState {
    switch (action.type) {
        case getType(dca.loadAllDashboards): {
            return {
                ...state,
                allDashboardNames: serialize(pending),
            };
        }
        case getType(dca.loadAllDashboardsSuccess):
            // case DashboardConfigEventType.ConfigLoadSuccess:
            const allDashboardNames = action.payload.map((d) => d.displayName);
            allDashboardNames.sort();
            const configs = action.payload.reduce(
                (prev, curr) => {
                    prev[curr.displayName] = {
                        readyForWidgetsToDisplay: false,
                        hideConfigChange: curr.hideConfigChange,
                        dashboardConfig: getDashConfigWithWidgetMap(curr.dashboardConfig),
                        id: curr.id,
                        displayName: curr.displayName,
                    };
                    return prev;
                },
                { ...state.configs },
            );
            return {
                ...state,
                allDashboardNames: serialize(success(allDashboardNames)),
                configs,
                loadedAllOnce: true,
            };
        case LOAD_VIEW_CONFIG_SUCCESS: {
            return {
                ...state,
                // e.g. if we impersonate a new user, we need to fetch the dashboard fresh
                loadedAllOnce: false,
            };
        }
        case getType(dca.loadAllDashboardFailure): {
            return {
                ...state,
                allDashboardNames: serialize(failure(JSON.parse(JSON.stringify(action.error)))),
            };
        }
        default:
            return state;
    }
}
