import React, { useReducer, useMemo, useEffect } from 'react';
import produce from 'immer';
import merge from 'lodash/merge';
import { View } from 'reducers/ViewConfigType';

type ViewData = Partial<View> & { entityVersion?: number };
export interface UniversalViewWizardProps {
    view: ViewData;
    children: (args: {
        state: UniversalViewWizardState['stepState'];
        dispatch: (action: UniversalViewWizardAction) => void;
    }) => JSX.Element;
    persist?: boolean;
}

export type UniversalViewWizardState = {
    stepState: (
        | {
              type: 'CREATE' | 'SHOW' | 'EDIT' | 'MATCH' | 'MERGE' | 'COMPONENT';
              step: 1 | 2 | 3;
          }
        | {
              type: 'LIST';
              step: 1 | 2 | 3 | 4;
          }
        | {
              type: 'CREATE' | 'SHOW' | 'EDIT' | 'LIST' | 'MATCH' | 'MERGE' | 'COMPONENT';

              step: 'review';
          }
    ) & {
        view: ViewData;
    };
    meta?:
        | {
              type: 'byId';
              existingViewId: string;
          }
        | {
              type: 'byName';
              existingViewName: string;
          };
};
export type UniversalViewWizardAction =
    | {
          // set data and go to next step
          type: 'next';
          viewData: ViewData;
          meta?:
              | {
                    type: 'clear';
                }
              | {
                    type: 'pickExistingById';
                    existingViewId: string;
                }
              | {
                    type: 'pickExistingByName';
                    existingViewName: string;
                };
      }
    | {
          type: 'back';
      }
    | {
          type: 'to';
          step: 1 | 2 | 3 | 4 | 'review';
      }
    | {
          type: 'patch';
          viewData: Partial<ViewData>;
      };
const universalViewWizardReducer = (
    state: UniversalViewWizardState,
    action: UniversalViewWizardAction,
): UniversalViewWizardState => {
    switch (action.type) {
        case 'to': {
            const { step } = action;
            return {
                ...state,
                stepState: {
                    ...state.stepState,
                    step,
                } as any,
            };
        }
        case 'back': {
            if (state.stepState.step === 'review' || state.stepState.step > 1) {
                return produce(state, (ds) => {
                    const { step, view } = state.stepState;
                    if (step === 'review') {
                        if (view.viewType === 'LIST') {
                            ds.stepState.step = 4;
                        } else {
                            ds.stepState.step = 3;
                        }
                        return ds;
                    } else {
                        ds.stepState.step = (step - 1) as 1 | 2 | 3 | 4;
                        return ds;
                    }
                });
            }
            // throw error?
            return state;
        }
        case 'patch': {
            if (state.stepState.step === 'review') {
                return produce(state, (ds) => {
                    ds.stepState.view = merge(state.stepState.view, action.viewData);
                });
            }
            return state;
        }
        case 'next': {
            const { viewData, meta } = action;
            const { stepState } = state;
            if (typeof stepState.step === 'number') {
                const { type } = stepState;
                return produce(state, (ds) => {
                    const lastStep = type === 'LIST' ? 4 : 3;
                    if (stepState.step === lastStep) {
                        ds.stepState.step = 'review';
                    } else {
                        ds.stepState.step = ((stepState.step as number) + 1) as any;
                    }
                    ds.stepState.view = viewData;

                    if (meta) {
                        if (meta.type === 'clear') {
                            delete ds.meta;
                        } else if (meta.type === 'pickExistingById') {
                            ds.meta = { type: 'byId', existingViewId: meta.existingViewId };
                        } else if (meta.type === 'pickExistingByName') {
                            ds.meta = { type: 'byName', existingViewName: meta.existingViewName };
                        }
                    }
                    return ds;
                });
            }
            // throw error?
            return state;
        }
        default:
            return state;
    }
};
export const SESH_STORAGE_KEY = 'UniversalViewWizardPersist';
const UniversalViewWizard: React.FunctionComponent<UniversalViewWizardProps> = (props) => {
    const initial: UniversalViewWizardState = useMemo(() => {
        if (props.persist) {
            const item = sessionStorage.getItem(SESH_STORAGE_KEY);
            if (item) {
                const res = JSON.parse(item);
                return res;
            }
        }
        return { stepState: { type: props.view.viewType, view: props.view, step: 1 } };
    }, []); // eslint-disable-line
    const [state, dispatch] = useReducer(universalViewWizardReducer, initial);
    useEffect(() => {
        sessionStorage.setItem(SESH_STORAGE_KEY, JSON.stringify(state));
    }, [state]);
    const { stepState } = state;
    return props.children({ state: stepState, dispatch });
};

export default UniversalViewWizard;
