import React, { ComponentProps } from 'react';
import { Select as MuiSelect, SelectProps as MuiSelectProps, MenuItem } from '@material-ui/core';
import { getUseNativeSelectSelector } from 'util/applicationConfig';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

type RequireValue<T> = Omit<T, 'value'> & {
    value: string | number | string[];
};
type MenuItemPropsWithRequiredValue = RequireValue<ComponentProps<typeof MenuItem>>;
type OptionPropsWithRequiredValue = RequireValue<
    React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>
>;
type MenuOptionComponent =
    | React.ComponentType<MenuItemPropsWithRequiredValue>
    | React.ComponentType<OptionPropsWithRequiredValue>;
interface SelectProps extends MuiSelectProps {
    children: (args: {
        native: boolean;
        OptionComponent: MenuOptionComponent;
    }) => React.ReactElement<OptionPropsWithRequiredValue | MenuItemPropsWithRequiredValue>[] | null | undefined;
}

const Option: React.ComponentType<OptionPropsWithRequiredValue> = (props) => {
    return <option {...props} />;
};
const CasetivitySelect: React.FunctionComponent<SelectProps> = (props) => {
    const configuredForNativeSelect = useSelector(getUseNativeSelectSelector);
    const native = Boolean(props.native || configuredForNativeSelect);

    const OptionComponent: MenuOptionComponent = native ? Option : MenuItem;
    const CN = 'casetivity-select';
    const className = props.className ? classNames(props.className, CN) : CN;
    return (
        <MuiSelect {...props} className={className} native={native}>
            {props.children({
                native,
                OptionComponent,
            })}
        </MuiSelect>
    );
};

export default CasetivitySelect;
