import { TableCell } from '@material-ui/core';
import makeCasetivityAdhocList from 'components/adhoclist';
import { Link } from 'react-router-dom';
import React from 'react';
import getNoResultsElement from 'components/generics/genericList/getNoResultsTextElement';
import ListDescription from './Description';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import { Order } from 'mui-rest-list/lib/src/components/makeAdhocList';
import NameFilter from './shared/NameFilter';
import Message from 'i18n/components/Message';

type ReportData = {
    name: string;
    description: string;
    id: string;
};
const { AdhocList, AdhocListColumn, getPagedObservable } = makeCasetivityAdhocList<ReportData>();

const StandardReports = () => {
    const getDataObservable = React.useCallback(
        (params: { size: number; page: number; orderBy: Order; filter: { name: '' } }) => {
            const sortStr = params.orderBy ? `&sort=${params.orderBy.join(',')}` : '';
            const filterStr = params.filter?.name ? `&name.equals=${params.filter.name}*` : '';
            return getPagedObservable(
                `api/reports/standard?page=${params.page}&size=${params.size}${sortStr}${filterStr}`,
            );
        },
        [],
    );

    const showHiddenOptions = useExpressionTesterOpen() !== 'CLOSED';

    return (
        <AdhocList
            type="paginated"
            hasRefresh
            pagesNIndexed={0}
            titleOptions={{
                type: 'Typography',
                TypographyProps: {
                    variant: 'h5',
                    component: 'div',
                    style: {
                        marginLeft: '1rem',
                        marginTop: '1rem',
                    },
                },
                text: 'Reports You Can Personalize',
            }}
            initialFilter={{ name: '' }}
            renderFilter={(params) => {
                return <NameFilter {...params} />;
            }}
            renderNoResults={() => getNoResultsElement(<Message id="list.noResults" dm="No results found" />)}
            renderBelowTitle={() => <ListDescription messageId="reports.standard.description" />}
            defaultSize={10}
            getDataObservable={getDataObservable}
            tableCaption="Reports available"
        >
            <AdhocListColumn sortable title="Name" fieldKey="name" />
            <AdhocListColumn sortable title="Description" fieldKey="description" />
            <AdhocListColumn
                title="Actions"
                hideColTitle
                fieldKey="name"
                renderdata={(name, props) => (
                    <TableCell>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <Link aria-label="Run report definition" to={`/run-report/${name}`}>
                                Run
                            </Link>
                            {showHiddenOptions && (
                                <Link aria-label="Edit report definition" to={`/ReportDefinition/${props.id}`}>
                                    Edit
                                </Link>
                            )}
                            <Link aria-label="Extend this report definition" to={`/personalize-report/${name}`}>
                                Personalize
                            </Link>
                        </div>
                    </TableCell>
                )}
            />
        </AdhocList>
    );
};

export default StandardReports;
