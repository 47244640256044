import { getDataWithExpansion } from '../../../../../../components/generics/form/getFormInitial';
import {
    getRefEntityName,
    isADirectRefOneField,
    isADirectValuesetOneField,
} from '../../../../../../components/generics/utils/viewConfigUtils';
import { mapOption } from 'fp-ts/lib/Array';
import { none, some } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { identity } from 'fp-ts/lib/function';
import set from 'lodash/set';
import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';

const updateDataOnRefChange = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    baseEntityType: string,
    entities: {},
    fieldValuePairs: [string, unknown][],
    expressionAndReadOnlyFields: string[], // these are what we need to lookup based on reference changes
) => {
    const expansionPaths = mapOption(fieldValuePairs, ([f, value]) => {
        if (f.endsWith('Ids')) {
            return none;
        }
        return tryCatch(() => {
            const expansionPath = f.endsWith('Id') ? f.slice(0, -2) : f;
            const isDirectRef1 = isADirectRefOneField(viewConfig, baseEntityType, expansionPath);
            const isDirectVs1 = isADirectValuesetOneField(viewConfig, baseEntityType, expansionPath);
            if (isDirectRef1 || isDirectVs1) {
                const refEntity = isDirectVs1
                    ? 'Concept'
                    : getRefEntityName(viewConfig, baseEntityType, expansionPath, 'TRAVERSE_PATH');
                if (refEntity) {
                    return some({
                        expansionPath,
                        refEntity,
                        value: value as string,
                    });
                }
            }
            return none;
        }).fold((e) => {
            console.error(e);
            console.log('Error above occurred building expansionsPaths on ' + baseEntityType);
            return none;
        }, identity);
    });

    const dataAtPaths = expansionPaths.flatMap(({ expansionPath, refEntity, value }) => {
        const remainingAfterBase = (paths: string[]) => {
            const acc = [];
            paths.forEach((path) => {
                if (path.startsWith(expansionPath) && path[expansionPath.length] === '.') {
                    acc.push(path.slice(expansionPath.length + 1));
                }
            });
            return acc;
        };

        const v = (() => {
            let res = getDataWithExpansion('EXPANDED')(
                viewConfig,
                refEntity,
                value,
                remainingAfterBase(expressionAndReadOnlyFields).filter((p) => {
                    try {
                        return (
                            !isADirectRefOneField(viewConfig, refEntity, p) &&
                            !isADirectValuesetOneField(viewConfig, refEntity, p)
                        );
                    } catch (e) {
                        return true;
                    }
                }),
                entities,
            );
            fieldValuePairs.forEach(([path, _v]) => {
                const [remainingPath] = remainingAfterBase([path]);
                if (remainingPath) {
                    set(res, remainingPath, _v);
                }
            });
            return res;
        })();
        const keys = Object.keys(v);
        if (refEntity === 'Concept' && keys.length === 0) {
            // we never are actually overwriting this data, so never null it out (like we do in the lines below).
            // only update fields like '.group' or '.code' to whatever is needed on the backend.
            return [];
        }
        return [[expansionPath, keys.length > 0 ? v : null] as [string, null | {}]];
    });
    return dataAtPaths;
};

export default updateDataOnRefChange;
