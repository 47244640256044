import React from 'react';
import { connect } from 'react-redux';
import { getRefEntityName } from '../../../components/generics/utils/viewConfigUtils';
import { createSelector } from 'reselect';
import { RootState } from '../../../reducers/rootReducer';
import ViewConfig from '../../../reducers/ViewConfigType';
import { Subtract } from 'utility-types';
import TextField from '@material-ui/core/TextField';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import uniqueId from 'lodash/uniqueId';

const styles = (theme) => ({
    textField: {
        width: '100%',
    },
});

interface HardReferenceProps {
    parentEntityIdValue: string | number;
    label: string;
    source: string;
    reference: string;
    resource: string;
    title?: string;
    classes: {
        textField: string;
    };
}
const HardReferenceComponent: React.SFC<HardReferenceProps> = (props: HardReferenceProps) => {
    const labelId = React.useRef(uniqueId('hardRef'));
    return (
        <TextField
            label={props.label}
            InputLabelProps={{ id: labelId.current }}
            inputProps={{ 'aria-labelledby': labelId.current }}
            multiline={true}
            disabled={true}
            className={props.classes.textField}
            defaultValue={props.title}
            margin="none"
        />
    );
};
const getRefEntity = (viewConfig: ViewConfig, resource: string, source: string) =>
    getRefEntityName(viewConfig, resource, source.endsWith('Id') ? source.slice(0, -2) : source);

type preConnectedProps = Subtract<HardReferenceProps, Pick<HardReferenceProps, 'reference' | 'title' | 'classes'>>;
const makeMapStateToProps = () => {
    const referencedEntityTitleSelector = createSelector(
        (state: RootState, props: preConnectedProps) => getRefEntity(state.viewConfig, props.resource, props.source),
        (state: RootState, props: preConnectedProps) => props.parentEntityIdValue,
        (state: RootState, props: preConnectedProps) => state.admin.entities,
        (reference, id, entities) =>
            (entities[reference] && entities[reference][id] && entities[reference][id].title) || id,
    );
    const mapStateToProps = (state: RootState, props: preConnectedProps) => ({
        title: referencedEntityTitleSelector(state, props),
    });
    return mapStateToProps;
};

const HardReference: React.SFC<preConnectedProps> = compose(
    withStyles(styles),
    connect(makeMapStateToProps),
)(HardReferenceComponent);

export default HardReference;
