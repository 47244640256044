import { processContext } from 'bpm/components/processContext';
import { activeTabContext } from 'components/generics/form/TabbableForm';
import {
    CreateEntry,
    EditEntry,
    Entry,
    RecordType,
    Target,
} from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/Entry';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
type PathEntry = {
    source: string;
    fromEntity: string;
    tabKey?: string;
    type: RecordType;
};
type NestingContext = {
    path: PathEntry[];
    entriesAtCurrentLevel: Target[];
};
export const nestingContext = createContext<NestingContext>({
    path: [],
    entriesAtCurrentLevel: [],
});

export const useNestingContext = (source: string, fromEntity: string) => {
    const t = useContext(activeTabContext);
    const tabKey = t._type !== 'NOT_IN_A_TAB' ? t.tabKey : undefined;

    const { path } = useContext(nestingContext);
    const taskId = useContext(processContext).taskId;

    const constructOfflineSaveEntry = useCallback(
        (params: { entry: EditEntry | CreateEntry }): Entry => {
            let targetChain = path.reverse().reduce(
                (prev, curr) => {
                    return {
                        source: curr.source,
                        fromEntity: curr.fromEntity,
                        recordType: curr.type,
                        tag: 'link' as const,
                        next: prev,
                        tabKey: curr.tabKey,
                    };
                },
                {
                    ...params.entry,
                    tabKey,
                    source,
                    fromEntity,
                } as Target,
            );
            return {
                taskId,
                target: targetChain,
            };
        },
        [path, taskId, tabKey, source, fromEntity],
    );
    return {
        constructOfflineSaveEntry,
    };
};

const NestingContextProvider: React.FC<{
    currentId: string;
    source: string;
    fromEntity: string;
    recordPendingCreate?: boolean;
    entriesAtCurrentLevel?: Target[];
}> = ({ source, fromEntity, currentId, recordPendingCreate, children, entriesAtCurrentLevel = [] }) => {
    const t = useContext(activeTabContext);
    const tabKey = t._type !== 'NOT_IN_A_TAB' ? t.tabKey : undefined;
    const { path } = useContext(nestingContext);
    const providedContext: NestingContext = useMemo(() => {
        if (path.some((e) => e.type.tag === 'pending_creation')) {
            return {
                path: [
                    ...path,
                    { fromEntity, source, tabKey, type: { tag: 'pending_prev_create', substituteId: currentId } },
                ],
                entriesAtCurrentLevel,
            };
        }
        return {
            path: [
                ...path,
                {
                    source,
                    tabKey,
                    fromEntity,
                    type: recordPendingCreate
                        ? {
                              tag: 'pending_creation',
                              substituteId: currentId,
                          }
                        : { tag: 'exists', id: currentId },
                },
            ],
            entriesAtCurrentLevel,
        };
    }, [path, currentId, source, tabKey, recordPendingCreate, entriesAtCurrentLevel, fromEntity]);

    return <nestingContext.Provider value={providedContext}>{children}</nestingContext.Provider>;
};

export default NestingContextProvider;
