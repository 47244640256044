import * as loginActions from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, catchError, flatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { of, concat, defer, Observable } from 'rxjs';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { getResponseAndThrowErrorForNon200 } from 'sideEffect/crud/util/epics/CoreCrud/shared';
import { push as pushAction } from 'connected-react-router';
import { fetchEnd, fetchStart } from 'actions/aor/fetchActions';
import { AuthResponse } from './definitions';
import * as viewConfig from 'viewConfig/actions';
import { AjaxError } from 'rxjs/ajax';
import { storageController } from 'storage';
import { tryAllRetriesNeedingAuth } from 'IndexedDB';
import { getCacheOnLoginSelector } from 'util/applicationConfig';
import { profilesLoaded } from './profiles/actions';
import * as config from 'config';
import buildHeaders from 'sideEffect/buildHeaders';

const getCacheOnLogin = <T>(cacheOnLogin: { url: string }[]) =>
    tap<T>(() => {
        // this is provided in serviceWorker.cacheOnLogin
        cacheOnLogin.forEach(({ url }) => {
            // just going to fetch these and not do anything with them.
            // The service-worker is going to intercept and cache them for us.
            fetch(config.BACKEND_BASE_URL + (url?.startsWith('/') ? url.slice(1) : url), {
                credentials: 'same-origin',
                headers: buildHeaders({
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    includeCredentials: true,
                }),
            });
        });
    });

export const translateError = (err: AjaxError) =>
    err.message && err.message.includes('ajax error 401')
        ? err.response.AuthenticationException
        : err.message && err.message.includes('ajax error 4')
        ? '%{auth.login.failed}'
        : err.message && err.message.includes('ajax error 5')
        ? 'Server Error'
        : err.message && err.message.includes('ajax error')
        ? 'System Error'
        : err.message || 'Authentication failed, please retry';

const loginFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf([loginActions.login, loginActions.loginWithMfaCode])),
        tap(() => {
            storageController.clear();
        }),
        withLatestFrom(state$.pipe(map(getCacheOnLoginSelector))),
        flatMap(([action, cacheOnLogin]) =>
            concat(
                of(fetchStart()),
                (isActionOf(loginActions.login)(action)
                    ? services.authenticate(action.payload)
                    : services.mfaAuthenticateService(action.payload)
                ) // this is hit on retry once user provides mfa details
                    .pipe(
                        getResponseAndThrowErrorForNon200,
                        flatMap((r: AuthResponse) =>
                            defer(() =>
                                !r.mfa_required
                                    ? of(r).pipe(
                                          tap((r: AuthResponse) => {
                                              storageController.setToken(r.id_token);
                                          }),
                                          tap((r: AuthResponse) => {
                                              tryAllRetriesNeedingAuth();
                                          }),
                                          getCacheOnLogin(cacheOnLogin),
                                          flatMap((r) => {
                                              const actionObservables: Observable<RootAction>[] = [
                                                  of(
                                                      isActionOf(loginActions.login)(action)
                                                          ? loginActions.loginSuccess()
                                                          : loginActions.loginWithMfaCodeSuccess(),
                                                  ),
                                                  of(fetchEnd()),
                                                  ...(r.data?.profiles && r.data.profiles.length > 0
                                                      ? [of(profilesLoaded(r.data.profiles))]
                                                      : []),
                                                  of(viewConfig.load(false)),
                                                  of(
                                                      action.redirectTo
                                                          ? pushAction(action.redirectTo)
                                                          : r.loginRedirect
                                                          ? pushAction(r.loginRedirect)
                                                          : pushAction('/'),
                                                  ),
                                              ];
                                              return concat(...actionObservables);
                                          }),
                                      )
                                    : of(r).pipe(
                                          flatMap((r) => {
                                              return concat(
                                                  of(
                                                      loginActions.loginMfaRequired(
                                                          r,
                                                          action.redirectTo || r.loginRedirect,
                                                      ),
                                                  ),
                                                  of(fetchEnd()),
                                              );
                                          }),
                                      ),
                            ),
                        ),
                        catchError((err) => {
                            const notify = action.errorCb(err);
                            if (!notify) {
                                return concat(of(loginActions.loginFailure(err)), of(fetchEnd()));
                            }
                            const errorMessage = translateError(err);
                            return concat(
                                of(loginActions.loginFailure(err)),
                                of(fetchEnd()),
                                of(
                                    enqueueSnackbarAction({
                                        message: errorMessage,
                                        options: { variant: 'warning' },
                                    }),
                                ),
                            );
                        }),
                    ),
            ),
        ),
    );
};
export default loginFlow;
