import { FormFieldUnion, TableFormField } from 'fieldFactory/translation/fromFlowable/types';
import { evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { TaskForm } from '../../reducers/taskFormType';

const internationalizeFields = (intl: IntlShape, fields: FormFieldUnion[]) => {
    return fields?.map((f) => {
        const name = evaluateFormattedText(intl, f.name);
        if (f.type === 'table') {
            const columnObj = internationalizeFields(intl, f.params.columnObj);
            const result: TableFormField = {
                ...f,
                name,
                params: {
                    ...f.params,
                    columnObj,
                    columns: JSON.stringify(columnObj),
                },
            };
            return result;
        }
        return {
            ...f,
            name,
        };
    });
};

const useInternationalizedForm = (formDefinition: TaskForm) => {
    const intl = useIntl();
    return useMemo(
        () => ({
            ...formDefinition,
            fields: internationalizeFields(intl, formDefinition?.fields),
        }),
        [intl, formDefinition],
    );
};
export const InternationalizeForm: React.FC<{
    taskForm: TaskForm;
    children: (props: { internationalizedForm: TaskForm }) => JSX.Element;
}> = (props) => {
    const internationalizedForm = useInternationalizedForm(props.taskForm);
    return props.children({ internationalizedForm });
};
export default useInternationalizedForm;
