import { CRUD_UPDATE, CRUD_UPDATE_FAILURE, CRUD_UPDATE_SUCCESS } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { ErrorsCbs } from '../errorTypes';
import { EntityBase } from 'sideEffect/services';
import { Subtract } from 'utility-types';
import { NormalizedResponse } from '../util/normalizeEntityResponse';

export interface UpdateParams {
    resource: string;
    data: {};
    previousData?: {};
    restUrl?: string; // for overriding the default from viewConfig.
    // redirectTo?: 'edit' | 'show' | 'list' | null;
    cb?: <T extends EntityBase>(id?: string, data?: T) => void;
    errorsCbs?: ErrorsCbs;
    /**
     * formId
     * totally optional. For tracking when our data updates, so we can reset components with component-level state when the data within is saved.
     */
    formId?: string;
    visibleAndEditableFields?: string[];
}
export const crudUpdate = createCustomAction(CRUD_UPDATE, (type) => {
    const defaultArgs: Partial<UpdateParams> = {
        // redirectTo: 'edit',
        errorsCbs: {},
    };
    return (args: UpdateParams) => {
        const mergedArgs: UpdateParams = {
            ...defaultArgs,
            ...args,
        };
        return {
            type,
            payload: mergedArgs,
        };
    };
});
type cleanedPayload = Subtract<UpdateParams, Pick<UpdateParams, 'cb' | 'errorsCbs'>>;
export const crudUpdateFailure = createCustomAction(CRUD_UPDATE_FAILURE, (type) => {
    return (error: Error, requestPayload: cleanedPayload) => {
        return {
            type,
            payload: error,
            requestPayload,
        };
    };
});

export const crudUpdateSuccess = createCustomAction(CRUD_UPDATE_SUCCESS, (type) => {
    return <T>(
        data: NormalizedResponse<T, string> | null,
        requestPayload: cleanedPayload,
        dontOverwrite?: {
            [entityType: string]: {
                [id: string]: {
                    [field: string]: true;
                };
            };
        },
    ) => {
        return {
            type,
            payload: {
                data,
            },
            meta: {
                dontOverwrite,
            },
            requestPayload,
        };
    };
});
