const ExpressionEventType = {
    SubmitExpression: 'SUBMIT_EXPRESSION',
    SubmitExpressionSuccess: 'SUBMIT_EXP_SUCCESS',
    SubmitExpressionFailure: 'SUBMIT_EXP_FAILURE',
    SubmitVisExpressionEntity: 'SUBMIT_VIS_EXPRESSION_ENTITY',
    SubmitVisExpressionTask: 'SUBMIT_VIS_EXPRESSION_TASK',
    ClearValue: 'CLEAR_VALUE',
    ClearTaskOverrides: 'CLEAR_TASK',
    ClearEntityOverrides: 'CLEAR_ENTITY',
};

const ExpressionTestEventCreator = {
    submitExpression: (entityType: string, entityId: number, calcExpression: string) => ({
        type: ExpressionEventType.SubmitExpression,
        entityType,
        entityId,
        calcExpression,
    }),
    clearValue: () => ({
        type: ExpressionEventType.ClearValue,
    }),
    submitVisExpressionEntity: (expressionConfig: {}) => ({
        type: ExpressionEventType.SubmitVisExpressionEntity,
        expressionConfig,
    }),
    submitVisExpressionTask: (visExpression: string) => ({
        type: ExpressionEventType.SubmitVisExpressionTask,
        visExpression,
    }),
    clearTaskOverrides: () => ({
        type: ExpressionEventType.ClearTaskOverrides,
    }),
    clearEntityOverrides: () => ({
        type: ExpressionEventType.ClearEntityOverrides,
    }),
};

export { ExpressionEventType, ExpressionTestEventCreator };
