import React from 'react';
import { PopperPlacementType, Tooltip } from '@material-ui/core';

const withTooltip = (text: string, placement?: PopperPlacementType) => (BaseComponent) => (props) =>
    (
        <Tooltip title={text} placement={placement}>
            <div>
                <BaseComponent {...props} />
            </div>
        </Tooltip>
    );
export default withTooltip;
