import React from 'react';
import { RootState } from 'reducers/rootReducer';
import { getRestUrl, getRefEntityName, getDataTypeForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import { connect } from 'react-redux';
import get from 'lodash/get';
import DownloadFromLink from 'fieldFactory/display/components/DownloadFromLink';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import ViewConfig from 'reducers/ViewConfigType';
import { createSelector } from 'reselect';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import BlobImage from 'fieldFactory/display/components/BlobImage';
/*
    Usage.

    This component is READ ONLY, even on TaskForms and Edit forms. It is a download link that only uses saved data. It may be present on a form, but on a non-writeable field.
    Usually source would be 'id' on a XDoc
    so relatedDoc.id
    or just 'id' on a XDoc Create/Edit/Show.

    This means we only take 'id' and 'entityType' (aka resource here)
    and traverse to get whatever data was last gotten from the entity.

    This means that to update the DmsDocument entity, a seperate field has to write to dmsDoc.document
    which will always be empty on an editable form, becasue we never get it on the entity.
*/

interface DmsDocBaseProps {
    source: string;
}
interface DmsDocInputProps extends DmsDocBaseProps {
    type: 'Input(Entity)';
    resource: string;
    input: {
        value?: string;
    };
}
interface DmsDocPropsStandalone extends DmsDocBaseProps {
    type: 'Input(NoBackingEntity)';
    reference: string;
    input: {
        value?: string;
    };
}
interface DmsDocDisplayProps extends DmsDocBaseProps {
    type: 'Display(Entity)';
    resource: string;
    record: {};
}
type DmsDocProps = DmsDocInputProps | DmsDocDisplayProps | DmsDocPropsStandalone;

const popOffProperty = (source: string) => source.slice(0, source.lastIndexOf('.'));

const pathTo = (field: string) => (source: string) =>
    source.indexOf('.') === -1 ? field : popOffProperty(source) + `.${field}`;

type documentId = string | undefined;
function getDocumentId(props: DmsDocDisplayProps): documentId;
function getDocumentId(props: DmsDocInputProps): documentId;
function getDocumentId(props: DmsDocPropsStandalone): documentId;
function getDocumentId(props: DmsDocProps): documentId;
function getDocumentId(props: DmsDocProps) {
    return props.type === 'Display(Entity)' ? get(props.record, pathTo('id')(props.source)) : props.input.value;
}
type documentResource = string | undefined;
function getDocumentResource(props: DmsDocDisplayProps, viewConfig: ViewConfig): documentResource;
function getDocumentResource(props: DmsDocInputProps, viewConfig: ViewConfig): documentResource;
function getDocumentResource(props: DmsDocPropsStandalone, viewConfig: ViewConfig): documentResource;
function getDocumentResource(props: DmsDocProps, viewConfig: ViewConfig): documentResource;
function getDocumentResource(props: DmsDocProps, viewConfig: ViewConfig) {
    if (props.type === 'Display(Entity)' || props.type === 'Input(Entity)') {
        if (!props.source.includes('.')) {
            return props.resource;
        }
        return getRefEntityName(viewConfig, props.resource, popOffProperty(props.source));
    }
    return props.reference;
}
const getSourceOnDocument = (props: DmsDocProps) =>
    props.type === 'Display(Entity)' || props.type === 'Input(Entity)'
        ? props.source.slice(props.source.lastIndexOf('.') + 1)
        : props.source;
const createDocumentMetadataSelector = (getProperty: (source: string) => string) => {
    const getEntities = createGetEntities();
    const selector = createSelector(
        (state: RootState, props: DmsDocProps) => getSourceOnDocument(props),
        (state: RootState, props: DmsDocProps) => getDocumentId(props),
        (state: RootState, props: DmsDocProps) => getDocumentResource(props, state.viewConfig),
        (state: RootState, props: DmsDocProps) => state.viewConfig,
        getEntities,
        (source, id, entityType, viewConfig, entities) => {
            return traverseGetData(viewConfig, getProperty(source), { id, entityType }, entities, true).getOrElse(
                undefined,
            );
        },
    );
    return selector;
};
const makeMapStateToProps = () => {
    const getFileType = createDocumentMetadataSelector((source) => `${source}ContentType`);
    const getFileName = createDocumentMetadataSelector((source) => `${source}FileName`);
    // used to see if document (file) was uploaded successfully. Only indicator that download/<id> will actually work.
    const getDocumentIdentifier = createDocumentMetadataSelector((source) => `${source}Identifier`);
    const mapStateToProps = (state: RootState, props: DmsDocProps) => {
        const documentId = getDocumentId(props);
        const documentResource = getDocumentResource(props, state.viewConfig);
        const documentFileType = getFileType(state, props);
        const documentFileName = getFileName(state, props);
        const documentIdentifier = getDocumentIdentifier(state, props);
        const isAnyBlob =
            getDataTypeForFieldExpr(
                state.viewConfig,
                documentResource,
                props.source.indexOf('.') === -1 ? props.source : popOffProperty(props.source),
            ) === 'ANYBLOB';
        const sourceOnDocument = getSourceOnDocument(props);
        return {
            restUrl: getRestUrl(documentResource)(state),
            documentId,
            documentResource,
            documentIdentifier,
            documentFileType,
            documentFileName,
            isAnyBlob,
            sourceOnDocument,
        };
    };
    return mapStateToProps;
};
type DmsDocComponentProps = DmsDocProps & ReturnType<ReturnType<typeof makeMapStateToProps>>;

const DmsDocComponent: React.FunctionComponent<
    DmsDocComponentProps & {
        children: (props: { url: string; fileName: string; contentType: string }) => JSX.Element;
    }
> = (props) => {
    let queryParam = `anyBlobFieldIdentifier=${props.documentIdentifier}&anyBlobField=${props.sourceOnDocument}`;
    if (props.documentId) {
        queryParam = 'entityId=' + props.documentId + '&' + queryParam;
    }
    if (!props.documentIdentifier) {
        return null;
    }
    return props.children({
        url: `${props.restUrl}/download?${queryParam}`,
        fileName: props.documentFileName,
        contentType: props.documentFileType,
    });
};
const WithDMSDoc = connect(makeMapStateToProps)(DmsDocComponent);

const DmsDoc = (props: DmsDocProps) => {
    return (
        <WithDMSDoc {...props}>
            {({ url, fileName, contentType }) => {
                return <DownloadFromLink url={url} fileName={fileName} contentType={contentType} />;
            }}
        </WithDMSDoc>
    );
};
export const DmsImageDoc: React.FunctionComponent<DmsDocProps & { altText: string; imageWidth?: string }> = (props) => {
    const { altText, ...dmsDocProps } = props;
    return (
        <WithDMSDoc {...dmsDocProps}>
            {({ url }) => {
                return (
                    <div style={{ width: props.imageWidth || '100%' }}>
                        <BlobImage url={url} altText={props.altText} />
                    </div>
                );
            }}
        </WithDMSDoc>
    );
};
export default DmsDoc;
