import { EXPORT_VIEW_CONFIG } from './constants';

const ExportViewConfigEventType = {
    ExportConfigFull: EXPORT_VIEW_CONFIG,
    ExportConfigCore: 'EXPORT_CORE_CONFIG',
    ExportConfigUserExcluded: 'EXPORT_USER_EXCLUDED_CONFIG',
    ExportConfigCoreUserExcluded: 'EXPORT_CORE_USER_EXCLUDED_CONFIG',
    ExportProcesses: 'EXPORT_PROCESSES',
};

const ExportViewConfigEventCreator = {
    exportViewConfigFull: () => ({ type: ExportViewConfigEventType.ExportConfigFull }),
    exportViewConfigCore: () => ({ type: ExportViewConfigEventType.ExportConfigCore }),
    exportViewConfigUserExcluded: () => ({ type: ExportViewConfigEventType.ExportConfigUserExcluded }),
    exportViewConfigCoreUserExcluded: () => ({ type: ExportViewConfigEventType.ExportConfigCoreUserExcluded }),
    exportProcesses: () => ({ type: ExportViewConfigEventType.ExportProcesses }),
};
export { ExportViewConfigEventType, ExportViewConfigEventCreator };
