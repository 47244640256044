import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { useSelector } from 'react-redux';
import {
    getAppTitleSelector,
    getOverrideLoginAppbarTitleSelector,
    getToolbarImageUrlSelector,
} from 'util/applicationConfig';
import { getDisplayTitle } from 'components/Title';
import MinimalUIToolbarForStandaloneApp from 'components/layouts/MinimalUIToolbarForStandaloneApp';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        applicationHeader: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    }),
);

const LoginAppBar = (props) => {
    const appTitle = useSelector(getAppTitleSelector);
    const displayTitle = useSelector(getDisplayTitle);
    const overrideLoginAppbarTitle = useSelector(getOverrideLoginAppbarTitleSelector);
    const imageLink = useSelector(getToolbarImageUrlSelector);
    const classes = useStyles();
    return (
        <>
            <MinimalUIToolbarForStandaloneApp />
            {overrideLoginAppbarTitle ? (
                <span style={{ padding: '1em' }} className={classes.applicationHeader}>
                    <SafeHtmlAsReact html={overrideLoginAppbarTitle} />
                </span>
            ) : (
                <div style={{ padding: '1em', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    {imageLink && (
                        <img style={{ maxHeight: '52px', paddingRight: '1em', width: 'auto' }} src={imageLink} alt="" />
                    )}
                    {appTitle ? (
                        <Typography className={classes.applicationHeader} variant="h4" component="h1">
                            <SafeHtmlAsReact html={appTitle} />
                        </Typography>
                    ) : (
                        <Typography className={classes.applicationHeader} variant="h4" component="h1">
                            {displayTitle}
                        </Typography>
                    )}
                </div>
            )}
        </>
    );
};
export default LoginAppBar;
