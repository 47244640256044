import React from 'react';
import LinkDialog from '../button/LinkDialog';
import Show from '../genericShow';
import useViewConfig from 'util/hooks/useViewConfig';
import { fromNullable } from 'fp-ts/lib/Option';

interface PopoverRecordTitleProps {
    text: string;
    recordId: string;
    resource: string;
}
const PopoverRecordTitle: React.SFC<PopoverRecordTitleProps> = (props: PopoverRecordTitleProps) => {
    const viewConfig = useViewConfig();
    const defaultShowViewName = fromNullable(viewConfig.entities[props.resource])
        .mapNullable((e) => e.defaultViews)
        .mapNullable((dv) => dv.SHOW)
        .mapNullable((s) => s.name)
        .getOrElse(`${props.resource}Show`);
    const ariaLabelledBy = `open-${props.recordId}`;
    const ariaDescribedBy = 'opens dialog';
    return (
        <LinkDialog
            renderLabel={({ onClick }) => (
                <a // eslint-disable-line
                    href="javascript:;" // eslint-disable-line
                    id={ariaLabelledBy}
                    onClick={onClick}
                >
                    {props.text}
                </a>
            )}
            ariaLabelledBy={ariaLabelledBy}
            ariaDescribedBy={ariaDescribedBy}
            content={
                <div style={{ minWidth: '85vw' }}>
                    <Show
                        {...{
                            actions: null,
                            formId: `merge-popover-${props.recordId}`,
                            viewName: defaultShowViewName,
                            match: {
                                params: {
                                    id: props.recordId,
                                },
                            },
                            resource: props.resource,
                        }}
                    />
                </div>
            }
        />
    );
};
export default PopoverRecordTitle;
