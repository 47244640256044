import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDefaultListViewName } from 'components/generics/utils/viewConfigUtils/index';
import { push as pushAction } from 'connected-react-router';
import GenericList from 'components/generics/genericList';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { currentUserProperties } from '../../../../expressions/contextUtils/currentUser';
import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import useViewConfig from 'util/hooks/useViewConfig';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import { parseTemplateString } from 'viewConfigCalculations/util/parseTemplateString';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import useEntities from 'util/hooks/useEntities';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            position: 'relative',
            overflowY: 'auto',
            maxHeight: 270,
            '@media print': {
                overflowY: 'unset',
                maxHeight: 'unset',
            },
        },
    }),
);

interface EntitySearchWidgetProps {
    entity: string;
    viewName?: string;
    filterConfig?: string;
    navToTemplate?: string;
    showSearchFields?: boolean;
}

const emptyObj = {};
const EntitySearchWidget: React.FC<EntitySearchWidgetProps> = ({
    entity,
    filterConfig,
    viewName: viewNameOverride,
    navToTemplate,
    showSearchFields = false,
}) => {
    const [location, setLocation] = useState<{ pathname: string; search: string }>({
        pathname: '/' + entity,
        search: '',
    });
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    const classes = useStyles();
    const push = useCallback((url: string) => dispatch(pushAction(url)), [dispatch]);
    const viewName = useMemo(
        () => viewNameOverride ?? getDefaultListViewName(viewConfig, entity),
        [viewConfig, entity, viewNameOverride],
    );
    const viewFound = useMemo(() => Boolean(viewConfig?.views[viewName]), [viewConfig, viewName]);
    const filterObject = useMemo(() => {
        if (filterConfig) {
            const result = evaluateFilterString(filterConfig, {
                ...createRootContext({
                    options: {
                        dateFormat: viewConfig.application.dateFormat,
                    },
                }),
                ...currentUserProperties(viewConfig),
            });
            if (result) {
                return getFilterFromFilterString(result);
            }
        }
        return {};
    }, [filterConfig, viewConfig]);
    const expansions = useMemo(() => {
        if (!navToTemplate) {
            return [];
        }
        try {
            const result = parseTemplateString(navToTemplate, viewConfig, entity);
            return result.expansionsRequired.map((expansion) => {
                return expansion
                    .split('.')
                    .filter((subStr) => subStr !== '_ALL_')
                    .join('.');
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }, [navToTemplate, viewConfig, entity]);

    const evaluate = useEvaluateTemplate(navToTemplate ?? '');

    const entities = useEntities();
    const getExpandedRecord = useCallback(
        (selectedId) => {
            return denormalizeEntitiesByPaths(entities, expansions, viewConfig, entity, selectedId);
        },
        [entities, viewConfig, entity, expansions],
    );
    const onRowSelect = useMemo(() => {
        if (entity === 'AppCase') {
            return undefined;
        }
        return (selectedData: { id: string }[]) => {
            const [selectedRecord] = selectedData;
            if (navToTemplate) {
                const expandedRecord = getExpandedRecord(selectedRecord.id);
                const url = evaluate(expandedRecord);
                push(url);
            } else {
                const url = `/${entity}/${selectedRecord.id}/show`;
                push(url);
            }
        };
    }, [entity, push, evaluate, navToTemplate, getExpandedRecord]);

    if (!viewConfig || !viewFound) {
        return <span>The view "{viewName}" could not be found in the viewConfig.</span>;
    }
    return (
        <div style={{ margin: '1em' }}>
            <GenericList
                onRowSelect={onRowSelect}
                filter={filterObject}
                actions={null}
                hasCreate={false}
                multiSelectable={false}
                updateUrlFromFilter={false}
                renderList={({ defaultRenderer, ...args }) => {
                    return defaultRenderer({
                        ...args,
                        classes,
                    });
                }}
                appendExpansions={expansions}
                viewName={viewName}
                fakePush={setLocation}
                location={location}
                resource={entity}
                formId={null}
                title=""
                customTitleElement={<div />}
                useCard={false}
                selectedData={emptyObj}
                showImmediately={!showSearchFields}
                showFilters={showSearchFields}
            />
        </div>
    );
};
export default EntitySearchWidget;
