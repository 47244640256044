import React, { useState, useCallback, useEffect } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { initial, RemoteData, pending, success, failure } from '@devexperts/remote-data-ts';
import { AjaxError, ajax } from 'rxjs/ajax';
import { refreshJwt, getOptions, getUrl } from 'sideEffect/services';
import SelectRecord from 'layout-editor/build-layout/steps/components/SelectRecord';
import RefreshIcon from '@material-ui/icons/Refresh';

interface MatchScoreProps {
    entityType: string;
    entityId: string;
}
const TestMatchScore: React.FunctionComponent<MatchScoreProps> = ({ entityId, entityType }) => {
    const [otherId, setOtherId] = useState('');
    const [state, setState] = useState<RemoteData<AjaxError, any>>(initial);

    const submit = useCallback(() => {
        setState(pending);
    }, []);

    useEffect(() => {
        if (otherId) {
            submit();
        }
    }, [otherId, submit]);

    useEffect(() => {
        if (state.isPending()) {
            const $ajax = refreshJwt(
                ajax({
                    ...getOptions(
                        getUrl(
                            `api/util/dedupe/match-score-evaluator?entityType=${entityType}&id=${entityId}&idToCompare=${otherId}`,
                        ),
                        'POST',
                    ),
                    responseType: 'text',
                }),
            );

            const subscription = $ajax.subscribe(
                (res) => {
                    setState(success(res.response));
                },
                (error: AjaxError) => {
                    setState(failure(error));
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [state, setState, entityId, entityType, otherId]);

    return (
        <div style={{ padding: '1em' }}>
            Get the matching score by comparing this record with another:
            <div style={{ height: '1em' }} />
            <SelectRecord
                onSelect={(maybeId) => maybeId.fold(null, (id) => setOtherId(id))}
                resource={entityType}
                renderSelection={(maybeId) =>
                    maybeId.foldL(
                        () => <p>Select a record above</p>,
                        (id) =>
                            state.fold(
                                null,
                                <CircularProgress />,
                                (error) => (
                                    <div>
                                        Failed:{' '}
                                        {error.message === 'ajax error'
                                            ? 'Network error'
                                            : JSON.stringify(error.response, null, 2)}
                                    </div>
                                ),
                                (success) => (
                                    <div>
                                        <IconButton
                                            onClick={() => {
                                                submit();
                                            }}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                        <div>Success: {success}</div>
                                    </div>
                                ),
                            ),
                    )
                }
            />
        </div>
    );
};

export default TestMatchScore;
