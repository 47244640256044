import React, { FunctionComponent, useMemo, useState, useCallback, useContext, useEffect } from 'react';
import { DashboardConfig, Dashboard, AttributeSecurity } from 'dashboard2/dashboard-config/types';
import { useWidgets } from 'dashboard2/components/WithWidgets';
import { getDashConfigWithWidgetMap } from 'dashboard2/dashboard-config/load-dashboards/reducer';
import GenericMovableGrid from './GenericEditableGrid';
import WidgetFactory from 'dashboard2/service/WidgetFactory';
import renderFieldAdder from './renderFieldAdder';
import PopoverRefInput from 'fieldFactory/popovers/PopoverRefInput';
import {
    TextField,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Button,
    CircularProgress,
    useTheme,
} from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { services } from 'sideEffect/services';
import { AjaxError } from 'rxjs/ajax';
import Check from '@material-ui/icons/Check';
import Warning from '@material-ui/icons/Warning';
import { useDispatch } from 'react-redux';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';
import AttributeSecurityForm from './attributeSecurity/AttributeSecurityForm';

interface DashEditorProps {
    dashConfig: DashboardConfig;
    setDashConfig: (dashConfig: DashboardConfig) => void;
}
const DashEditor: FunctionComponent<DashEditorProps> = (props) => {
    const { dashConfig, setDashConfig } = props;
    const overrideDashConfig = useMemo(() => {
        return getDashConfigWithWidgetMap(dashConfig);
    }, [dashConfig]);
    // const setDashConfig = useCallback((dc: DashboardConfigWithWidgetMap): DashboardConfig => {
    //     return {
    //         ...dc,
    //         widgets: Object.values(dc.widgets),
    //     };
    // }, []);
    const { widgetElements, grid } = useWidgets(overrideDashConfig, false);
    const fields = useMemo(
        () =>
            widgetElements.map((e) => {
                return React.cloneElement(e, {
                    'data-originaldefinition': JSON.stringify({
                        ...JSON.parse(e.props['data-originaldefinition']),
                        ...grid.layouts.md.find((i) => i.i === e.props.id),
                    }),
                });
            }),
        [widgetElements, grid.layouts.md],
    );
    const onLayoutChange = useCallback(
        ({ layout }) => {
            const mdLayout = layout.map(({ 'data-originaldefinition': originalDefinition, w, h, x, y, id }) => ({
                w,
                h,
                x,
                y,
                i: JSON.parse(originalDefinition).id,
            }));
            setDashConfig({
                ...dashConfig,
                grid: {
                    ...dashConfig.grid,
                    layouts: {
                        ...dashConfig.grid.layouts,
                        md: mdLayout,
                    },
                },
                widgets: layout
                    .map((l) => JSON.parse(l['data-originaldefinition']))
                    .map(({ id, type, title, definition }) => ({
                        id,
                        type,
                        title,
                        definition,
                    })),
            });
        },
        [dashConfig, setDashConfig],
    );
    const getLayoutFromElements = useCallback(
        (elems, opts) => {
            return elems.map((elem) => {
                return (
                    elem.props &&
                    elem.props['data-originaldefinition'] && {
                        ...JSON.parse(elem.props['data-originaldefinition']),
                        ...grid.layouts.md.find((i) => i.i === elem.key), //<- this works
                        'data-originaldefinition': elem.props['data-originaldefinition'],
                        content: elem,
                    }
                );
            });
        },
        [grid.layouts.md],
    );
    return (
        grid &&
        widgetElements && (
            <GenericMovableGrid
                newFieldH={3}
                rowHeight={30}
                compactType="vertical"
                autoCalcHeight={false}
                adjustLayoutLeft={false}
                onLayoutChange={onLayoutChange}
                columnStartsAt={0}
                createField={WidgetFactory.create}
                fields={fields}
                getLayoutFromElements={getLayoutFromElements}
                renderFieldAdder={renderFieldAdder}
            />
        )
    );
};

const startingDash = require('./startingDash.json');

type SubmissionState =
    | {
          type: 'SUCCESS';
      }
    | { type: 'NOT_SUBMITTING' }
    | { type: 'SUBMITTING' }
    | {
          type: 'ERROR';
          error: AjaxError;
      };
const useSubmitDash = () => {
    const [state, setState] = useState<SubmissionState>({ type: 'NOT_SUBMITTING' });
    const submit = useCallback(
        (dash: Partial<Dashboard>) => {
            setState({ type: 'SUBMITTING' });
            const $ajax = services.saveDashboard(dash);
            const subscription = $ajax.subscribe(
                (res) => {
                    if (res.status >= 200 && res.status < 300) {
                        setState({ type: 'SUCCESS' });
                    } else {
                        setState({ type: 'NOT_SUBMITTING' });
                    }
                },
                (error: AjaxError) => {
                    setState({ type: 'ERROR', error });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        },
        [setState],
    );
    return [state, submit] as const;
};

interface DashEditorControllerProps {
    initialDashId?: string;
}
const DashEditorController: FunctionComponent<DashEditorControllerProps> = ({ initialDashId }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [dashConfig, setDashConfig] = useState<DashboardConfig | null>(startingDash);
    const [attributeSecurityConfig, setAttributeSecurityConfig] = useState<AttributeSecurity>();
    const [displayName, setDisplayName] = useState<string>('');
    const [pickedDashId, setPickedDashId] = useState<string | null>(null);
    useEffect(() => {
        if (initialDashId) {
            // in order for the Dash to load properly from the data, we need to
            // unmount and mount it again when the dash loads
            setDashConfig(null);
            dispatch(
                crudGetOne({
                    id: initialDashId,
                    view: -1,
                    resource: 'Dashboard',
                    cb: (id: string, _result: any) => {
                        const result: {
                            id: string;
                            config: string;
                            displayName: string;
                        } = _result;
                        if (result) {
                            setPickedDashId(id);
                            if (result.config) {
                                const dashboard = JSON.parse(result.config) as Dashboard;
                                setDashConfig(dashboard.dashboardConfig);
                                setAttributeSecurityConfig(dashboard.attributeSecurity);
                            }
                            setDisplayName(result.displayName);
                        }
                    },
                    errorsCbs: {
                        '*': () => {
                            alert('Dashboard not found.');
                        },
                    },
                }),
            );
        }
    }, []); // eslint-disable-line
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const [submissionState, submit] = useSubmitDash();
    const save = useCallback(() => {
        let dashToSubmit: any = {
            name: dashConfig.name,
            displayName: displayName,
            config: JSON.stringify({
                dashboardConfig: dashConfig,
                attributeSecurity: attributeSecurityConfig,
                loaded: false,
            }),
            // 'displayName'
            // 'hideConfigChange'
        };
        if (pickedDashId) {
            dashToSubmit.id = pickedDashId;
        }
        submit(dashToSubmit);
    }, [dashConfig, pickedDashId, displayName, submit, attributeSecurityConfig]);
    return (
        <div style={{ margin: '1em', padding: '1em' }}>
            <div style={{ marginBottom: '1em', paddingBottom: '1em' }}>
                <PopoverRefInput
                    label="Select Dashboard"
                    resource="Dashboard"
                    reference="Dashboard"
                    source="pickedDashId"
                    options={{}}
                    input={{
                        value: pickedDashId,
                        onBlur: (v, data) => {
                            if (typeof v !== 'undefined') {
                                setPickedDashId(v || null);
                                if (v && data.config) {
                                    const dashboard = JSON.parse(data.config);
                                    setDashConfig(dashboard.dashboardConfig);
                                    setAttributeSecurityConfig(dashboard.attributeSecurity);
                                    setDisplayName(data.displayName || '');
                                } else {
                                    setDashConfig(startingDash);
                                    setDisplayName('');
                                }
                            }
                        },
                    }}
                    meta={{}}
                />
                <div style={{ height: '2em' }} />
                <TextField
                    label="Dashboard Name"
                    fullWidth
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({
                        shrink: true,
                        disabled: false,
                    })}
                    value={(dashConfig && dashConfig.name) || ''}
                    onChange={(e) => {
                        setDashConfig({
                            ...dashConfig,
                            name: e.target.value,
                        });
                    }}
                    onBlur={(e) => {
                        setDashConfig({
                            ...dashConfig,
                            name: e.target.value,
                        });
                    }}
                />
                <div style={{ height: '2em' }} />
                <TextField
                    label="Dashboard (Display) Name"
                    fullWidth
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({
                        shrink: true,
                        disabled: false,
                    })}
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    onBlur={(e) => setDisplayName(e.target.value)}
                />
                <div style={{ height: '2em' }} />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Inspect JSON</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <pre>{dashConfig && JSON.stringify(dashConfig, null, 1)}</pre>
                    </AccordionDetails>
                </Accordion>
                <div style={{ height: '2em' }} />
                <AttributeSecurityForm value={attributeSecurityConfig} onChange={setAttributeSecurityConfig} />
            </div>
            {dashConfig && <DashEditor key={pickedDashId} dashConfig={dashConfig} setDashConfig={setDashConfig} />}
            {submissionState.type === 'ERROR' ? (
                <pre style={{ color: theme.palette.error.main }}>
                    {submissionState.error.name}
                    <br />
                    {submissionState.error.message} <br />
                    {typeof submissionState.error.response === 'object' &&
                        JSON.stringify(submissionState.error.response, null, 1)}
                </pre>
            ) : null}
            <div style={{ height: '2em' }}>
                <Button
                    disabled={submissionState.type === 'SUBMITTING'}
                    color="primary"
                    variant="contained"
                    onClick={save}
                >
                    Save{' '}
                    {submissionState.type === 'SUBMITTING' ? (
                        <CircularProgress style={{ height: 15, width: 15 }} />
                    ) : submissionState.type === 'SUCCESS' ? (
                        <Check />
                    ) : submissionState.type === 'ERROR' ? (
                        <Warning />
                    ) : null}
                </Button>
            </div>
        </div>
    );
};

export default DashEditorController;
