import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import AttemptRequest from '../../../components/AttemptRequest';
import * as config from '../../../config';
import buildHeaders from 'sideEffect/buildHeaders';
import Message from 'i18n/components/Message';

const getLazyR = (processId: string, closeReasonId: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/process-instances/terminate`, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            processInstanceId: processId,
            closeReasonId,
        }),
    });

export const closeProcessButton = (processId: string, closeReasonId: string) => (onSuccessCb: () => void) =>
    (
        <AttemptRequest
            type="external"
            renderer={({ attemptAction }) =>
                (state) =>
                    state._tag === 'failure' ? (
                        <div>
                            <Message id="action.actionFailed" dm="Action failed" />.
                            <a // eslint-disable-line
                                href="javascript:;" // eslint-disable-line
                                onClick={attemptAction}
                            >
                                <Message id="action.tryAgain" dm="Try Again" />?
                            </a>
                        </div>
                    ) : state._tag === 'pending' ? (
                        <Button style={{ marginLeft: 'auto' }} variant="contained" color="default" disabled={true}>
                            <Message id="processes.processDetails.closeCase" dm="Close Case" />
                            <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                        </Button>
                    ) : state._tag === 'unsubmitted' ? (
                        <Button
                            style={{ marginLeft: 'auto' }}
                            variant="contained"
                            color="primary"
                            onClick={attemptAction}
                        >
                            <Message id="processes.processDetails.closeCase" dm="Close Case" />
                        </Button>
                    ) : (
                        <span style={{ marginLeft: 'auto' }}>
                            <Message
                                id="processes.task.closedSuccess"
                                dm="Task {0} has been closed successfully."
                                values={{
                                    '0': processId,
                                }}
                            />
                        </span>
                    )}
            lazyRequest={getLazyR(processId, closeReasonId)}
            onSuccess={onSuccessCb}
        />
    );
