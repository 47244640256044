import { fromNullable } from 'fp-ts/lib/Option';
import { RootState } from 'reducers/rootReducer';

const getTabToPrefetch = <T extends { id: string; resource: string; viewName: string }>(state: RootState, props: T) => {
    const recentTabResource = state.recentTab.resource;
    const recentTabKey = state.recentTab.tabKey;
    const recentTabRecordId = state.recentTab.id;
    const firstTabKey = fromNullable(state.viewConfig.views[props.viewName])
        .mapNullable((view) => view.tabs)
        .mapNullable((tab) => Object.keys(tab)[0])
        .getOrElse(undefined);

    if (recentTabRecordId === props.id && recentTabResource === props.resource && recentTabKey) {
        return recentTabKey;
    }
    return firstTabKey;
};
export default getTabToPrefetch;
