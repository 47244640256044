import range from 'lodash/range';
import * as FieldDataType from '../../fieldDataTypes';
import { RootState } from '../../../../../reducers/rootReducer';
import { EntityField, Entity } from '../../../../../reducers/ViewConfigType';
import { fromNullable } from 'fp-ts/lib/Option';
import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';

type ViewConfig = RootState['viewConfig'];

export function isString(data: undefined | string): data is string {
    return typeof data === 'string';
}
export const getLastFieldInExpression = (codedFieldName: string): string =>
    codedFieldName.split('.').pop() || codedFieldName;

export const isValidEntityFieldExpression = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entity: string,
    fieldExpr: string,
    lastMustBeEntity = false,
) => {
    if (!viewConfig.entities[entity]) {
        return false;
    }
    const viewFieldRefPath = fieldExpr.split('.');
    const entityField = viewConfig.entities[entity].fields[viewFieldRefPath[0]];
    if (!entityField) {
        return false;
    }
    if (viewFieldRefPath.length === 1) {
        if (lastMustBeEntity) {
            return (
                entityField.dataType === 'REFMANY' ||
                entityField.dataType === 'REFMANYJOIN' ||
                entityField.dataType === 'REFMANYMANY' ||
                entityField.dataType === 'REFONE' ||
                entityField.dataType === 'REFONEJOIN' ||
                entityField.dataType === 'VALUESET' ||
                entityField.dataType === 'VALUESETMANY'
            );
        }
        return true;
    } else {
        const nextEntity = viewConfig.entities[entity].fields[viewFieldRefPath[0]].relatedEntity;
        if (isString(nextEntity)) {
            return isValidEntityFieldExpression(
                viewConfig,
                nextEntity,
                range(1, viewFieldRefPath.length)
                    .map((i) => viewFieldRefPath[i])
                    .join('.'),
            );
        }
        return false;
    }
};

/*
    @param entity: the base entity of the field we are looking at
    @param fieldExpr: the fieldExpression, ending in a ref field (last field in chain needs a 'relatedEntity' property.
*/
export const getRefEntity = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entity: string,
    fieldExpr: string,
    allowLinkedXTraversal: boolean = false,
): Entity => {
    if (!viewConfig.entities[entity]) {
        throw Error(`Entity "${entity}" does not exist`);
    }
    const viewFieldRefPath = fieldExpr.split('.');
    const linkedPrefix = 'linked';
    const nextStepInPath = viewFieldRefPath[0];
    if (
        allowLinkedXTraversal &&
        nextStepInPath &&
        nextStepInPath !== 'linkedEntity' &&
        nextStepInPath.startsWith(linkedPrefix) &&
        nextStepInPath.charAt(linkedPrefix.length).toUpperCase() === nextStepInPath.charAt(linkedPrefix.length)
    ) {
        const relEntity = nextStepInPath.includes('.')
            ? nextStepInPath.slice(linkedPrefix.length, nextStepInPath.indexOf('.'))
            : nextStepInPath.slice(linkedPrefix.length);
        if (viewFieldRefPath.length === 1 && relEntity) {
            return viewConfig.entities[relEntity];
        } else {
            return getRefEntity(
                viewConfig,
                relEntity,
                range(1, viewFieldRefPath.length)
                    .map((i) => viewFieldRefPath[i])
                    .join('.'),
                allowLinkedXTraversal,
            );
        }
    }
    const entityField = viewConfig.entities[entity].fields[nextStepInPath];
    if (!entityField) {
        throw Error(`field "${nextStepInPath}" in "${fieldExpr}" not found on entity "${entity}"`);
    }
    if (viewFieldRefPath.length === 1) {
        const relEntity: string | undefined = viewConfig.entities[entity].fields[fieldExpr].relatedEntity;
        if (isString(relEntity)) {
            return viewConfig.entities[relEntity];
        }
        throw Error(`No related Entity for field: "${fieldExpr}" on entity "${entity}"`);
    } else {
        const nextEntity = viewConfig.entities[entity].fields[nextStepInPath].relatedEntity;
        if (isString(nextEntity)) {
            return getRefEntity(
                viewConfig,
                nextEntity,
                range(1, viewFieldRefPath.length)
                    .map((i) => viewFieldRefPath[i])
                    .join('.'),
                allowLinkedXTraversal,
            );
        }
        throw Error(`relatedEntity not found on the path "${fieldExpr}":
        "${nextStepInPath}": "${viewConfig.entities[entity].fields[nextStepInPath]}"
        has no relatedEntity field`);
    }
};

export const someEntityInPathIsDynamic = <_ViewConfig extends { entities: ViewConfigEntities } = ViewConfig>(
    viewConfig: _ViewConfig,
    entityName: string,
    fieldName: string,
    allowLinkedXTraversal: boolean = true,
): boolean => {
    const entity = viewConfig.entities[entityName];
    if (!entity) {
        throw Error(`Entity "${entityName}" not found in someEntityInPathIsDynamic call`);
    }
    const isDynamic = entity['isDynamic'];
    if (isDynamic) {
        return true;
    }
    const [curr, ...rest] = fieldName.split('.');
    const relatedEntity = entity.fields[curr]?.relatedEntity;
    if (!relatedEntity) {
        return false;
    }
    return someEntityInPathIsDynamic(viewConfig, relatedEntity, rest.join('.'), allowLinkedXTraversal);
};

export const getEntityFieldFromPath = <_ViewConfig extends { entities: ViewConfigEntities } = ViewConfig>(
    viewConfig: _ViewConfig,
    entityName: string,
    fieldName: string,
): [entityName: string, entityField: EntityField] => {
    const entity = viewConfig.entities[entityName];
    if (!entity) {
        throw Error(`Entity "${entityName}" not found in getEntityFieldFromPath call `);
    }
    const fieldPath = fieldName.split('.');
    if (fieldPath.length > 1) {
        const relatedEntityToNestedField = getRefEntity(
            viewConfig,
            entityName,
            range(0, fieldPath.length - 1)
                .map((i) => fieldPath[i])
                .join('.'),
            false,
        );
        return [relatedEntityToNestedField.name, relatedEntityToNestedField.fields[fieldPath[fieldPath.length - 1]]];
    }
    if (!entity.fields[fieldName]) {
        throw Error(`field "${fieldName}" not found on entity "${entityName}"`);
    }
    return [entityName, entity.fields[fieldName]];
};

export const getAttrOfTraversedFieldExpr = <
    T extends keyof EntityField,
    _ViewConfig extends { entities: ViewConfigEntities } = ViewConfig,
>(
    viewConfig: _ViewConfig,
    entityName: string,
    fieldName: string,
    attribute: keyof EntityField,
    allowLinkedXTraversal: boolean = true,
): EntityField[T] => {
    const entity = viewConfig.entities[entityName];
    if (!entity) {
        throw Error(`Entity "${entityName}" not found in getAttributeOfEntityField call for attribute "${attribute}"`);
    }
    const fieldPath = fieldName.split('.');
    if (fieldPath.length > 1) {
        // we need to look up the field
        const relatedEntityToNestedField = getRefEntity(
            viewConfig,
            entityName,
            range(0, fieldPath.length - 1)
                .map((i) => fieldPath[i])
                .join('.'),
            allowLinkedXTraversal,
        );
        return relatedEntityToNestedField.fields[fieldPath[fieldPath.length - 1]][attribute] as EntityField[T];
    }
    if (!entity.fields[fieldName]) {
        if (fieldName.startsWith('linked')) {
            if (entity.fields['linkedEntity']) {
                return entity.fields['linkedEntity'][attribute] as EntityField[T];
            }
        }
        throw Error(`field "${fieldName}" not found on entity "${entityName}"`);
    }
    return entity.fields[fieldName][attribute] as EntityField[T];
};

/*
    This allows us to also accomodate the "linkedXXXX.restof.field.expressions..."" syntax,
    such as when we need to get the valueSetCode of a field in the linkedEntity search on the process list.
    In this case entity can be undefined, since we get the entity name from XXXX in the example above
*/
const nextIfLinked = (entityName: string | undefined, fieldName: string) => {
    const linkedPrefix = 'linked';
    if (
        (typeof entityName === 'undefined' || entityName === 'AppCase' || entityName === 'RelatedCase') &&
        fieldName.startsWith(linkedPrefix) &&
        fieldName.charAt(linkedPrefix.length).toUpperCase() === fieldName.charAt(linkedPrefix.length)
    ) {
        return {
            nextEntity: fieldName.includes('.')
                ? fieldName.slice(linkedPrefix.length, fieldName.indexOf('.'))
                : fieldName.slice(linkedPrefix.length),
            nextField: fieldName.includes('.') ? fieldName.slice(fieldName.indexOf('.') + 1) : '',
        };
    }
    return null;
};
export const getAttrOfTraversedFieldExprIncludingLinkedX = <
    T extends keyof EntityField,
    _ViewConfig extends { entities: ViewConfigEntities } = ViewConfig,
>(
    viewConfig: _ViewConfig,
    entityName: string | undefined,
    fieldName: string,
    attribute: keyof EntityField,
): EntityField[T] => {
    return getAttrOfTraversedFieldExpr<T, _ViewConfig>(viewConfig, entityName, fieldName, attribute, true);
};

const POP_LAST = 'POP_LAST';
const TRAVERSE_PATH = 'TRAVERSE_PATH';
/*
    !!!IMPORTANT REGARDING USAGE!!!

    "POP_LAST" and "TRAVERSE_PATH" describe the relationship between entityName and fieldName/fieldExpression

    =================================================================================================================
    POP_LAST
    =================================================================================================================
    POP_LAST indexes:
        entities[entityName].fields[fieldName.split('.').last()]

    [WHEN TO USE]:
        when we have access to a view field (ViewField). e.g:

    viewConfig;
    {
        views:  {
            [ClientList]: {
                fields: {
                    [client.currentAddress.state]: {         <------ this is the viewField (as opposed to entity field)
                        entity: 'Address',
                        field: 'client.currentAddress.state',
                        widgetType: ValueSet
                        ...
                    }
                }
            }
        }
    }
    since we already know 'state' belongs to 'Address', we simpy get (Address['state'])

    =================================================================================================================
    TRAVERSE_PATH
    =================================================================================================================
    TRAVERSE_PATH gets us our target field recursively, based on an entity given.

    e.g. ('Client', 'currentAddress.state) => returns Address['state'] (due to chained lookup)

    [WHEN TO USE]:
        When we know the entity at the base of the field expression, but DON'T know the viewField.entity.
        (basically, the component level of, say, a ValueSetSelect field, we only know
            1. the resource (root entity)
            2. the fieldExpression.
        We don't know the entity of the final field in the field expression, like we do when we have the ViewField.

*/
type EtF = 'POP_LAST' | 'TRAVERSE_PATH';

const propertyOfLastEntityFieldInExpr = <
    T extends keyof EntityField,
    ViewConfig extends { entities: ViewConfigEntities },
>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    property: keyof EntityField,
): EntityField[T] =>
    (viewConfig.entities[entityName].fields[getLastFieldInExpression(fieldName)] || {})[property] as EntityField[T];

/* LABEL */
export const getLabelForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | null =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'label', ViewConfig>(viewConfig, entityName, fieldName, 'label')
        : getAttrOfTraversedFieldExpr<'label', ViewConfig>(viewConfig, entityName, fieldName, 'label');

/* DESCRIPTION */
export const getDescriptionForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'description', ViewConfig>(viewConfig, entityName, fieldName, 'description')
        : getAttrOfTraversedFieldExpr<'description', ViewConfig>(viewConfig, entityName, fieldName, 'description');

/* REQUIRED */
export const getRequiredForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'required', ViewConfig>(viewConfig, entityName, fieldName, 'required')
        : getAttrOfTraversedFieldExpr<'required', ViewConfig>(viewConfig, entityName, fieldName, 'required');

/* MIN_SIZE*/
export const getMinSizeForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): number | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'minSize', ViewConfig>(viewConfig, entityName, fieldName, 'minSize')
        : getAttrOfTraversedFieldExpr<'minSize', ViewConfig>(viewConfig, entityName, fieldName, 'minSize');

/* MAX_SIZE */
export const getMaxSizeForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): number | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'maxSize', ViewConfig>(viewConfig, entityName, fieldName, 'maxSize')
        : getAttrOfTraversedFieldExpr<'maxSize', ViewConfig>(viewConfig, entityName, fieldName, 'maxSize');

/* PATTERN */
export const getPatternForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'pattern', ViewConfig>(viewConfig, entityName, fieldName, 'pattern')
        : getAttrOfTraversedFieldExpr<'pattern', ViewConfig>(viewConfig, entityName, fieldName, 'pattern');

/* DATA_TYPE */
export const getDataTypeForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): (typeof FieldDataType)[keyof typeof FieldDataType] =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'dataType', ViewConfig>(viewConfig, entityName, fieldName, 'dataType')
        : getAttrOfTraversedFieldExpr<'dataType', ViewConfig>(viewConfig, entityName, fieldName, 'dataType');

export const getIsExpensiveForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'expensive', ViewConfig>(viewConfig, entityName, fieldName, 'expensive')
        : getAttrOfTraversedFieldExpr<'expensive', ViewConfig>(viewConfig, entityName, fieldName, 'expensive');

/* Calculated And not GENERATED_ID */
export const isCalculatedUnsortableField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => {
    const calcType =
        relationship === POP_LAST
            ? propertyOfLastEntityFieldInExpr<'calcType', ViewConfig>(viewConfig, entityName, fieldName, 'calcType')
            : getAttrOfTraversedFieldExpr<'calcType', ViewConfig>(viewConfig, entityName, fieldName, 'calcType');
    return calcType === 'CALC_DYNAMIC';
};
export const fieldIsSortable = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => {
    return (
        !isCalculatedUnsortableField<ViewConfig>(viewConfig, entityName, fieldName, relationship) &&
        getDataTypeForFieldExpr<ViewConfig>(viewConfig, entityName, fieldName, relationship) !== 'TEXTBLOB'
    );
};

/* VALUE_SET */
export const getValueSetForFieldExpr = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string | undefined,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined => {
    if (relationship === POP_LAST) {
        if (entityName) {
            return propertyOfLastEntityFieldInExpr<'valueSet', ViewConfig>(
                viewConfig,
                entityName,
                fieldName,
                'valueSet',
            );
        }
        throw new Error('entityName cannot be undefined with POP_LAST relationship in getValueSetForFieldExpr.');
    }
    return getAttrOfTraversedFieldExprIncludingLinkedX<'valueSet', ViewConfig>(
        viewConfig,
        entityName,
        fieldName,
        'valueSet',
    );
};

/* RELATED_FIELD */
export const getRelatedField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'relatedField', ViewConfig>(viewConfig, entityName, fieldName, 'relatedField')
        : getAttrOfTraversedFieldExpr<'relatedField', ViewConfig>(viewConfig, entityName, fieldName, 'relatedField');

export const getPathBackFromFieldPath = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    baseEntity: string,
    path: string,
    linkedEntityFormat: 'linkedEntity' | 'linked<entityType>' = 'linked<entityType>',
): string => {
    const entity = viewConfig.entities[baseEntity];
    if (!entity) {
        throw Error(`Entity "${baseEntity}" not found in getPathBackFromFieldPath call`);
    }
    const fieldPath = path.split('.');
    let currentEntity: string = baseEntity;
    return fieldPath.reduce((prev, curr) => {
        const currentRelatedFieldBack = (() => {
            const fieldBack = getRelatedField(viewConfig as any, currentEntity, curr, 'TRAVERSE_PATH');
            return fieldBack === 'linkedEntity' && linkedEntityFormat === 'linked<entityType>'
                ? `linked${currentEntity}`
                : fieldBack;
        })();

        currentEntity = getRefEntityName(viewConfig as any, currentEntity, curr, 'TRAVERSE_PATH');
        return currentRelatedFieldBack + (prev ? '.' : '') + prev;
    }, '');
};
/* ACCESS_LEVEL */
export const getAccessLevelForEntityField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): number =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'accessLevel', ViewConfig>(viewConfig, entityName, fieldName, 'accessLevel')
        : getAttrOfTraversedFieldExpr<'accessLevel', ViewConfig>(viewConfig, entityName, fieldName, 'accessLevel');

/*
    Is - a utilities
*/

export const isValueSetField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.VALUESET;

export const isValueSetManyField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.VALUESET_MANY;

export const isBooleanField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.BOOLEAN;

export const isValueSetOrValueSetManyField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean =>
    isValueSetField(viewConfig, entityName, fieldName, relationship) ||
    isValueSetManyField(viewConfig, entityName, fieldName, relationship);

export const isRefOneField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.REFONE;

export const isRefManyField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.REFMANY;

export const isRefManyManyField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.REFMANYMANY;

export const getRefEntityName = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = TRAVERSE_PATH,
): string => {
    if (relationship === POP_LAST) {
        const fieldParts = fieldName.split('.');
        const lastFieldPart = fieldParts[fieldParts.length - 1];
        const relatedEntity = fromNullable(viewConfig.entities[entityName].fields[lastFieldPart])
            .mapNullable((f) => f.relatedEntity)
            .getOrElse(undefined);
        if (relatedEntity) {
            return fromNullable(viewConfig.entities[relatedEntity])
                .mapNullable((re) => re.name)
                .getOrElse(undefined);
        }
    }
    return getRefEntity(viewConfig, entityName, fieldName).name;
};

export const getRefEntityLabel = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = TRAVERSE_PATH,
): string => {
    if (relationship === POP_LAST) {
        const relatedEntity = fromNullable(viewConfig.entities[entityName].fields[fieldName])
            .mapNullable((f) => f.relatedEntity)
            .getOrElse(undefined);
        if (relatedEntity) {
            return fromNullable(viewConfig.entities[relatedEntity])
                .mapNullable((e) => e.displayName)
                .getOrElse(undefined);
        }
    }
    return getRefEntity(viewConfig, entityName, fieldName).displayName;
};

export const isADirectRefOneField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    resource: string,
    path: string,
) => {
    return (
        !path.endsWith('Id') &&
        !path.endsWith('Ids') &&
        isValidEntityFieldExpression(viewConfig, resource, path) &&
        isRefOneField(viewConfig, resource, path, 'TRAVERSE_PATH')
    );
};
export const isADirectValuesetOneField = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    resource: string,
    path: string,
) => {
    return (
        !path.endsWith('Id') &&
        !path.endsWith('Ids') &&
        isValidEntityFieldExpression(viewConfig, resource, path) &&
        isValueSetField(viewConfig, resource, path, 'TRAVERSE_PATH')
    );
};

export const getLongestRefonePathInPath = <ViewConfig extends { entities: ViewConfigEntities }>(
    { entities }: ViewConfig,
    entityName: string,
    path: string,
    depth: number = 0,
): string => {
    if (path === '') {
        return '';
    }
    const entity = entities[entityName];
    if (!entity) {
        throw Error(
            `Entity "${entityName}" not found in getLongestRefonePathInPath(viewConfig,"${entityName}", "${path}")`,
        );
    }
    const fieldPath = path.split('.');
    if (fieldPath.length > 0) {
        const [field] = fieldPath;
        const adjustedPath = field.endsWith('Id') ? field.slice(0, -2) : field;

        const linkedNext = nextIfLinked(entityName, path);
        try {
            if (linkedNext) {
                const rest = linkedNext.nextField
                    ? getLongestRefonePathInPath({ entities }, linkedNext.nextEntity, linkedNext.nextField, depth + 1)
                    : '';
                return rest ? adjustedPath + '.' + rest : adjustedPath;
            }
            const entityField = entity.fields[adjustedPath];
            if (entityField) {
                if (
                    entityField.dataType === 'REFONE' ||
                    entityField.dataType === 'VALUESET' ||
                    entityField.dataType === 'REFONEJOIN'
                ) {
                    const nextEntity = entityField.relatedEntity;

                    const rest = getLongestRefonePathInPath(
                        { entities },
                        nextEntity,
                        fieldPath.slice(1).join('.'),
                        depth + 1,
                    );
                    return rest ? adjustedPath + '.' + rest : adjustedPath;
                }
                return '';
            }
            throw new Error(
                `field ${
                    adjustedPath !== field ? `${adjustedPath}/${field}` : field
                } not found on entity ${entityName}`,
            );
        } catch (e) {
            if (depth > 0) {
                throw e;
            } else {
                console.error(e);
                throw Error(
                    `Error above occurred in getLongestRefonePathInPath(viewConfig,"${entityName}", "${path}")`,
                );
            }
        }
    }
    return '';
};
