import { useEffect, useRef } from 'react';
import { State } from './StateUnion';

export const useResetStateToInitialAfterASecondOfSuccess = (state: State, setState: Function) => {
    const isSuccess = state.type === 'SUCCESS';

    const prevWasSuccessRef = useRef(isSuccess);

    useEffect(() => {
        if (!prevWasSuccessRef.current && isSuccess) {
            const to = setTimeout(() => {
                setState({ type: 'INITIAL' });
            }, 1000);

            return () => clearTimeout(to);
        }
        prevWasSuccessRef.current = isSuccess;
    }, [isSuccess]); // eslint-disable-line
};
