/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['1e30b551'] = validate10;
const schema11 = {
    type: 'object',
    properties: {
        sortable: { title: 'Sortable', type: 'boolean' },
        inlineEditable: {
            title: 'Inline Editable',
            type: 'boolean',
            description: "Allow inline edits of the field. Don't do this by default.",
        },
    },
    dependencies: {
        inlineEditable: {
            oneOf: [
                {
                    properties: {
                        inlineEditable: { enum: [true] },
                        editableExpression: { title: 'Editable Expression', type: 'string' },
                    },
                    required: [],
                },
            ],
        },
    },
    required: [],
    $id: '1e30b551',
};

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="1e30b551" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.inlineEditable !== undefined) {
            const _errs2 = errors;
            let valid1 = false;
            let passing0 = null;
            const _errs3 = errors;
            if (data && typeof data == 'object' && !Array.isArray(data)) {
                if (data.inlineEditable !== undefined) {
                    let data0 = data.inlineEditable;
                    if (!(data0 === true)) {
                        const err0 = {
                            instancePath: instancePath + '/inlineEditable',
                            schemaPath: '#/dependencies/inlineEditable/oneOf/0/properties/inlineEditable/enum',
                            keyword: 'enum',
                            params: {
                                allowedValues:
                                    schema11.dependencies.inlineEditable.oneOf[0].properties.inlineEditable.enum,
                            },
                            message: 'must be equal to one of the allowed values',
                            schema: schema11.dependencies.inlineEditable.oneOf[0].properties.inlineEditable.enum,
                            parentSchema: schema11.dependencies.inlineEditable.oneOf[0].properties.inlineEditable,
                            data: data0,
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                }
                if (data.editableExpression !== undefined) {
                    let data1 = data.editableExpression;
                    if (typeof data1 !== 'string') {
                        const err1 = {
                            instancePath: instancePath + '/editableExpression',
                            schemaPath: '#/dependencies/inlineEditable/oneOf/0/properties/editableExpression/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.inlineEditable.oneOf[0].properties.editableExpression.type,
                            parentSchema: schema11.dependencies.inlineEditable.oneOf[0].properties.editableExpression,
                            data: data1,
                        };
                        if (vErrors === null) {
                            vErrors = [err1];
                        } else {
                            vErrors.push(err1);
                        }
                        errors++;
                    }
                }
            }
            var _valid0 = _errs3 === errors;
            if (_valid0) {
                valid1 = true;
                passing0 = 0;
            }
            if (!valid1) {
                const err2 = {
                    instancePath,
                    schemaPath: '#/dependencies/inlineEditable/oneOf',
                    keyword: 'oneOf',
                    params: { passingSchemas: passing0 },
                    message: 'must match exactly one schema in oneOf',
                    schema: schema11.dependencies.inlineEditable.oneOf,
                    parentSchema: schema11.dependencies.inlineEditable,
                    data,
                };
                if (vErrors === null) {
                    vErrors = [err2];
                } else {
                    vErrors.push(err2);
                }
                errors++;
            } else {
                errors = _errs2;
                if (vErrors !== null) {
                    if (_errs2) {
                        vErrors.length = _errs2;
                    } else {
                        vErrors = null;
                    }
                }
            }
        }
        if (data.sortable !== undefined) {
            let data2 = data.sortable;
            if (typeof data2 !== 'boolean') {
                const err3 = {
                    instancePath: instancePath + '/sortable',
                    schemaPath: '#/properties/sortable/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.sortable.type,
                    parentSchema: schema11.properties.sortable,
                    data: data2,
                };
                if (vErrors === null) {
                    vErrors = [err3];
                } else {
                    vErrors.push(err3);
                }
                errors++;
            }
        }
        if (data.inlineEditable !== undefined) {
            let data3 = data.inlineEditable;
            if (typeof data3 !== 'boolean') {
                const err4 = {
                    instancePath: instancePath + '/inlineEditable',
                    schemaPath: '#/properties/inlineEditable/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.inlineEditable.type,
                    parentSchema: schema11.properties.inlineEditable,
                    data: data3,
                };
                if (vErrors === null) {
                    vErrors = [err4];
                } else {
                    vErrors.push(err4);
                }
                errors++;
            }
        }
    } else {
        const err5 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}

exports['-66c6d02e'] = validate11;
const schema12 = { type: 'object', properties: { inlineEditable: { enum: [true] } }, $id: '-66c6d02e' };

function validate11(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="-66c6d02e" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.inlineEditable !== undefined) {
            let data0 = data.inlineEditable;
            if (!(data0 === true)) {
                const err0 = {
                    instancePath: instancePath + '/inlineEditable',
                    schemaPath: '#/properties/inlineEditable/enum',
                    keyword: 'enum',
                    params: { allowedValues: schema12.properties.inlineEditable.enum },
                    message: 'must be equal to one of the allowed values',
                    schema: schema12.properties.inlineEditable.enum,
                    parentSchema: schema12.properties.inlineEditable,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
    } else {
        const err1 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema12.type,
            parentSchema: schema12,
            data,
        };
        if (vErrors === null) {
            vErrors = [err1];
        } else {
            vErrors.push(err1);
        }
        errors++;
    }
    validate11.errors = vErrors;
    return errors === 0;
}
