import React from 'react';

export const DefaultSpinner = () => (
    <div style={{ minHeight: 65 }}>
        <div className="spinnyloader">Loading...</div>
    </div>
);

interface DeferredSpinnerProps {
    renderSpinner?: () => JSX.Element | null;
    renderWhileDeferred?: () => JSX.Element | string | null;
}
interface DeferredSpinnerState {
    displayMessage: boolean;
}
class DeferredSpinner extends React.Component<DeferredSpinnerProps, DeferredSpinnerState> {
    public timer: NodeJS.Timeout;
    public _isMounted: boolean;
    public rafTO: number;
    constructor(props: DeferredSpinnerProps) {
        super(props);
        this.enableMessage = this.enableMessage.bind(this);

        this.state = {
            displayMessage: false,
        };
        this._isMounted = true;
        this.timer = setTimeout(this.enableMessage, 200);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.timer);
        if (this.rafTO) {
            window.cancelAnimationFrame(this.rafTO);
        }
    }

    enableMessage() {
        if (this._isMounted) {
            this.rafTO = requestAnimationFrame(() => {
                this.setState({ displayMessage: true });
            });
        }
    }

    render() {
        const { displayMessage } = this.state;

        if (!displayMessage) {
            if (this.props.renderWhileDeferred) {
                return this.props.renderWhileDeferred();
            }
            return null;
        }

        return this.props.renderSpinner?.() ?? <DefaultSpinner />;
    }
}

export default DeferredSpinner;
