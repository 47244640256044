export interface SearchValidations {
    [viewName: string]: {
        expression: string;
        message: string;
        dataPaths: string[];
        expansionsRequired: string[];
        valuesetFieldsRequired: {
            [field: string]: string; // can get the valueset
        };
    }[];
}
export default (previousState: SearchValidations = {}, { type, payload }): SearchValidations => {
    if (type === 'SEARCH_VALIDATIONS_GENERATED') {
        return payload;
    }
    return previousState;
};
