import { ACTIONBUTTON_LOCALSTORAGE_KEY } from './constants';
import { ActionButtonExps } from './ActionButtonExps';
import { fromPredicate } from 'fp-ts/lib/Option';
import { getStorage } from 'storage/storage';

const initialize = (): ActionButtonExps => {
    return fromPredicate<string>(Boolean)(getStorage().getItem(ACTIONBUTTON_LOCALSTORAGE_KEY))
        .map((se) => JSON.parse(se))
        .getOrElse({});
};
export default initialize;
