import React from 'react';
import { Button, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            margin: '10px 24px',
            position: 'relative',
        },
    });

interface CloseButtonProps {
    handleClose: () => void;
}
interface CloseButtonComponentProps extends CloseButtonProps, WithStyles<typeof styles> {}

const CloseButtonComponent: React.SFC<CloseButtonComponentProps> = ({ handleClose, classes }) => (
    <Button endIcon={<Clear />} className={classes.button} color="primary" variant="outlined" onClick={handleClose}>
        Close
    </Button>
);

const CloseButton = withStyles(styles)(CloseButtonComponent);
export default CloseButton;
