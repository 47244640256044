import { EntityBase, GenericCrudArgs } from 'sideEffect/services';
import { concat, of } from 'rxjs';
import { fetchStart } from 'actions/aor/fetchActions';
import { stripCallbacksFromPayload } from '../../../stripCallbacks';
import { GetFailureActionCreators, GetSuccessActionCreators, GetInitialActions } from '../../../../actionTypes';
import { ErrorsCbs } from '../../../../errorTypes';

import {
    FlowOptions,
    FlowContext,
    getResponseAndThrowErrorForNon200,
    handleErrors,
    flatMapResponseEntityToSuccessActions,
    callSuccessCb,
} from '../shared';

interface GetFlowOptions<D> extends FlowOptions<D> {
    failureAction: GetFailureActionCreators;
    successAction: GetSuccessActionCreators;
    errorsCbs: ErrorsCbs;
    successCb: (id?: string, data?: EntityBase) => void;
}

export interface GetFlowContext extends FlowContext {
    initialRequestPayload: GetInitialActions['payload'];
    restUrl: string;
}

const crudGetOneFlow = <D>(
    requestArgs: GenericCrudArgs<D>,
    params: GetFlowOptions<D>,
    context: GetFlowContext,
    triggerFetchStart: boolean = true,
) => {
    const ajax$ = params.service(requestArgs).pipe(
        getResponseAndThrowErrorForNon200,
        callSuccessCb(params.successCb),
        flatMapResponseEntityToSuccessActions(params.successAction, context),
        handleErrors(params.errorsCbs, (err) =>
            params.failureAction(err, stripCallbacksFromPayload(context.initialRequestPayload)),
        ),
    );
    if (triggerFetchStart) {
        return concat(of(fetchStart()), ajax$);
    }
    return ajax$;
};
export default crudGetOneFlow;
