import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Clear from '@material-ui/icons/Clear';
import { datify } from '../DateInput/datify';
import { DateFormatContext } from '../../../dateFormat/Broadcasts';
import moment, { Moment } from 'moment';
import { IconButton } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import memoizeOne from 'memoize-one';
import { TextFieldUtils } from 'fieldFactory/input/hooks/useTextFieldUtils';
import { useSelector } from 'react-redux';
import { getUseFullWidthDateFieldsSelector } from 'util/applicationConfig';

export type DayUSTInputProps = ({ mode: 'YYYYMMDD' } | { mode: 'ISO'; setUstTime?: string }) & {
    isRequired?: boolean;
    addField?: boolean;

    input: any;
    meta: any;
    label: string | null;
    options?: {};
    disabled?: boolean;
    fullWidth?: boolean;
    ariaInputProps?: {};
    renderLabel?: boolean;
    /**
     * TODO add override for timezone noon
     */
    mode?: 'YYYYMMDD' | 'ISO';
    hideCalendarButton?: boolean;
    overrideAriaLabel?: string;
    dateFormat?: string;
};

const DayUSTInput: React.FC<DayUSTInputProps> = (props) => {
    const _dateFormat = useContext(DateFormatContext);
    const dateFormat = props.dateFormat ?? _dateFormat;
    const lastValueWasEmptyRef = useRef(!props.input.value);
    useEffect(() => {
        // update after component updates
        lastValueWasEmptyRef.current = !props.input.value;
    });
    const errorMessageId = useMemo(() => uniqueId('date-errormsg'), []);
    const pickerInputRef = useRef<HTMLInputElement>();
    const setUstTime = props.mode === 'ISO' ? props.setUstTime : undefined;

    const initialFocusedDate = useMemo(() => {
        if (props.mode === 'YYYYMMDD' || !setUstTime) {
            return moment(new Date()).hour(0).toDate();
        }
        const day = moment(new Date()).format('YYYY-MM-DD');
        return moment(new Date(`${day}T${setUstTime ?? '12:00:00'}Z`));
    }, [setUstTime, props.mode]);

    const [key, refresh] = useReducer((state) => state + 1, 1);
    const [invalidDateFormat, setInvalidDateFormat] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const onChange = props.input.onChange;
    const onBlur = props.input.onBlur;

    const handleChange = useCallback(
        (e: Moment, inputValue: string) => {
            setInputValue(inputValue);
            if (!e) {
                onChange(null);
                onBlur(null);
                setInvalidDateFormat(false);
                return;
            }
            if (e.isValid && !e.isValid()) {
                setInvalidDateFormat(true);
                return;
            }

            if (e.isValid()) {
                const year = (e.get('year') + '').padStart(4, '0');
                const month = (e.get('month') + 1 + '').padStart(2, '0');
                const day = ('' + e.date()).padStart(2, '0');
                let d = [year, month, day].join('-');
                if (props.mode === 'ISO') {
                    d = d + `T${setUstTime ?? '12:00:00'}Z`;
                }
                onChange(d);
                onBlur(d);
            } else {
                onChange(null);
                onBlur(null);
            }
            setInvalidDateFormat(false);
        },
        [onBlur, onChange, props.mode, setUstTime],
    );

    const clearInput = useCallback(() => {
        onChange({ target: { value: null } });
        onBlur(null);
    }, [onBlur, onChange]);

    const handleBlur = useCallback(
        (e) => {
            const text = e.target.value;
            const m = moment(text + 'T12:00:00Z', dateFormat, true);
            if (m.isValid()) {
                handleChange(m, text);
            } else if (!text) {
                handleChange(null, text);
            } else {
                if (e.relatedTarget && e.relatedTarget.nodeName === 'BUTTON' && pickerInputRef.current === e.target) {
                    // skip clear mask because the calendar button was clicked
                } else {
                    refresh();
                    // maybe this can be implemented by reassigning the original text value to the ref.
                }
            }
        },
        [dateFormat, handleChange],
    );
    useEffect(() => {
        // when the input is remounted, lets fix the invalidDateFormat according to whether the displayed value is valid
        // fixes IADSS-198
        const valueAfterRefresh = pickerInputRef.current?.value;
        if (valueAfterRefresh && invalidDateFormat && moment(valueAfterRefresh, dateFormat, true).isValid()) {
            setInvalidDateFormat(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    const handleClose = useCallback(() => {
        refresh();
    }, []);
    const _getInputValue = useMemo(
        () =>
            memoizeOne((value: string, mode: 'YYYYMMDD' | 'ISO') => {
                return datify(value, mode) || null;
            }),
        [],
    );
    const getInputValue = useCallback(() => {
        return _getInputValue(props.input.value, props.mode);
    }, [props.mode, props.input.value, _getInputValue]);

    const {
        input,
        label,
        meta,
        options,
        ariaInputProps,
        disabled = false,
        renderLabel,
        hideCalendarButton = false,
        overrideAriaLabel,
    } = props;
    const { touched, error } = meta;
    const inputProps = {};
    const htmlErrorMessageId = (touched && error && errorMessageId) || undefined;
    const fullWidth = useSelector(getUseFullWidthDateFieldsSelector);
    if (htmlErrorMessageId) {
        inputProps['aria-describedby'] = htmlErrorMessageId;
        inputProps['aria-errormessage'] = htmlErrorMessageId;
    }
    return (
        <div style={fullWidth ? { width: '100%' } : undefined}>
            <div style={{ position: 'relative', display: 'inline-block', width: fullWidth ? '100%' : undefined }}>
                <TextFieldUtils meta={meta}>
                    {({
                        createInputLabelProps,
                        fieldVariant,
                        muiErrorProp,
                        InputPropsClasses,
                        createFormHelperTextProps,
                        helperText,
                    }) => (
                        <>
                            <EvaluateFormattedMessage>
                                {({ translate }) => {
                                    const invalidDateMsg =
                                        invalidDateFormat &&
                                        (!lastValueWasEmptyRef.current ||
                                            // if all characters filled, display the error if it's invalid, even if the date is not accepted.
                                            /\d{2}\/\d{2}\/\d{4}/.test(inputValue))
                                            ? translate({ id: 'date.invalidFormat' })
                                            : '';
                                    return (
                                        <KeyboardDatePicker
                                            placeholder={dateFormat}
                                            color="primary"
                                            size="medium"
                                            type={undefined}
                                            rows={undefined}
                                            rowsMax={undefined}
                                            onFocus={undefined}
                                            className={undefined}
                                            ref={undefined}
                                            style={undefined}
                                            innerRef={undefined}
                                            // Have to apply the above as undefined due to its current typings
                                            onChange={handleChange}
                                            value={getInputValue()}
                                            allowKeyboardControl={true}
                                            disabled={Boolean(disabled)}
                                            format={dateFormat}
                                            initialFocusedDate={initialFocusedDate}
                                            KeyboardButtonProps={{
                                                ...(hideCalendarButton
                                                    ? { disabled: true, style: { display: 'none' } }
                                                    : undefined),
                                                'aria-label': `Select date from calendar for ${
                                                    overrideAriaLabel ?? label
                                                }`,
                                                classes: {
                                                    root: 'casetivity-hide-printing',
                                                },
                                            }}
                                            inputRef={(ref) => {
                                                pickerInputRef.current = ref;
                                            }}
                                            key={key}
                                            onClose={handleClose}
                                            leftArrowButtonProps={{
                                                'aria-label': 'Previous month',
                                            }}
                                            rightArrowButtonProps={{
                                                'aria-label': 'Next month',
                                            }}
                                            InputLabelProps={
                                                // no warning styles if invalidDate
                                                invalidDateMsg
                                                    ? {
                                                          disabled: false,
                                                          shrink: true,
                                                      }
                                                    : createInputLabelProps({
                                                          disabled: false, // keep the label dark
                                                          shrink: true,
                                                      })
                                            }
                                            inputVariant={fieldVariant}
                                            InputProps={{
                                                inputProps: {
                                                    ...ariaInputProps,
                                                    ...inputProps,
                                                    'aria-label': overrideAriaLabel ?? label,
                                                },
                                                // no warning css if invalidDate (force error colors)
                                                classes: invalidDateMsg ? undefined : InputPropsClasses,
                                            }}
                                            onBlur={handleBlur}
                                            margin="none"
                                            label={renderLabel && label}
                                            error={Boolean(muiErrorProp || invalidDateMsg)}
                                            helperText={
                                                muiErrorProp
                                                    ? `${invalidDateMsg ? invalidDateMsg + '\n' : ''}${
                                                          helperText ?? ''
                                                      }`
                                                    : invalidDateMsg || undefined
                                            }
                                            FormHelperTextProps={
                                                // no warning css if invalidDate (force error colors)
                                                invalidDateMsg
                                                    ? getFormHelperTextProps(inputProps)
                                                    : createFormHelperTextProps(inputProps)
                                            }
                                            {...options}
                                            fullWidth={fullWidth}
                                        />
                                    );
                                }}
                            </EvaluateFormattedMessage>
                            {!disabled && input.value && input.value.length > 0 && (
                                <IconButton
                                    aria-label={`Clear date for ${overrideAriaLabel ?? label}`}
                                    style={
                                        !fieldVariant || fieldVariant === 'standard'
                                            ? {
                                                  position: 'absolute',
                                                  right: 30 - (hideCalendarButton ? 25 : 0),
                                                  top: renderLabel ? 8 : -8,
                                              }
                                            : {
                                                  position: 'absolute',
                                                  right: 50 - (hideCalendarButton ? 45 : 0),
                                                  top: 4,
                                              }
                                    }
                                    onClick={clearInput}
                                >
                                    <Clear />
                                </IconButton>
                            )}
                        </>
                    )}
                </TextFieldUtils>
            </div>
        </div>
    );
};

DayUSTInput.defaultProps = {
    isRequired: false,
    addField: true,
    options: {},
    ariaInputProps: {},
    renderLabel: true,
    mode: 'YYYYMMDD',
};
export default DayUSTInput;
