import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import Clear from '@material-ui/icons/Clear';
import uniqueId from 'lodash/uniqueId';
import { IconButton, Typography, TypographyProps, useTheme } from '@material-ui/core';
import GenericList, { getRenderAtRowEnd } from '../../../../../components/generics/genericList/index';
import getRenderer from '../../../../../components/generics/genericList/renderList';
import { customShowRedirects } from '../../../../../components/generics/overrides';
import difference from 'lodash/difference';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { allowsEdit, getAccessLevelForEntity } from 'components/generics/utils/viewConfigUtils';
import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { useDispatch, useSelector } from 'react-redux';
import useViewConfig from 'util/hooks/useViewConfig';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import formatError from 'fieldFactory/util/formatError';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

const RemoveFromList: React.SFC<{ onClick: () => void }> = (props) => (
    <IconButton
        size="small"
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onClick();
        }}
        aria-label={'Remove from list'}
    >
        <Clear />
    </IconButton>
);

export interface ListSelectProps {
    embeddedInFormId?: string;
    showListWhenDisabled?: boolean;
    labelVariant?: TypographyProps['variant'];
    noResultsHtml: string;
    openTo?: 'edit' | 'show';
    expansions: string[];
    noRecentlyVisited?: boolean;
    singleSelect?: boolean;
    disabled?: boolean;
    label: string;
    meta: { touched?: boolean; error?: string | string[]; initial?: string | string[] };
    actions: any;
    hasCreate: boolean;
    multiSelectable: boolean;
    disableCellClick?: boolean;
    updateUrlFromFilter: boolean;
    viewName: string;
    name: string;
    filter: {};
    source: string;
    input: {
        value: string[];
        onBlur: (value: string[]) => void;
    };
    hyperlinks?: boolean;
    useCheckboxes?: boolean;
}

interface ListSelectState {
    location: { pathname: string; search: string };
}

interface SelectedValuesSelectorProps {
    resource: string;
    selectedIds: string[];
}
const createSelectedValuesSelector = () => {
    const getEntities = createGetEntities();
    const resourceEntities = createSelector(
        getEntities,
        (state: RootState, props: SelectedValuesSelectorProps) => props.resource,
        (entities, resource) => entities[resource],
    );
    const selectedValuesSelector = createSelector(
        resourceEntities,
        (state: RootState, props: SelectedValuesSelectorProps) => props.selectedIds,
        (
            resourceEntries,
            selectedIds,
        ): {
            [id: string]: {
                title?: string;
            };
        } =>
            Object.fromEntries(
                selectedIds.filter((id) => resourceEntries?.[id]).map((id) => [id, resourceEntries[id]]),
            ),
    );
    return selectedValuesSelector;
};

export const ListSelect: React.FC<ListSelectProps> = (props) => {
    const {
        viewName,
        input,
        expansions,
        openTo,
        source,
        hasCreate,
        disabled = false,
        useCheckboxes,
        filter,
        hyperlinks = true,
        meta = {},
        showListWhenDisabled = true,
        noResultsHtml,
        embeddedInFormId,
        disableCellClick = false,
    } = props;
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName]?.entity;
    const errorMessageId = useMemo(() => uniqueId('listselect-errormessageid-'), []);
    const currentIds: string[] = useMemo(() => {
        if (input.value && typeof input.value === 'string') {
            return JSON.parse(input.value);
        }
        return input.value || [];
    }, [input.value]);
    const selectedValuesSelector = useMemo(createSelectedValuesSelector, []);
    const selectedRecords = useSelector((state: RootState) => {
        return selectedValuesSelector(state, {
            resource,
            selectedIds: currentIds,
        });
    });

    const [state, setState] = useState<ListSelectState>({
        location: {
            pathname: `/${resource}`,
            search: `?${filter ? stringify({ filter: JSON.stringify(filter) }) : ''}`,
        },
    });
    const dispatch = useDispatch();
    const fetchDataset = useCallback(
        (ids: string[]) => {
            if (ids.length === 0) {
                return;
            }
            if (ids.length === 1) {
                // most 'updates' are one at a time (e.g. adding records)
                // so in that case don't do a list fetch
                dispatch(
                    crudGetOneAction({
                        id: ids[0],
                        resource,
                        view: -1,
                        appendExpansions: expansions,
                    }),
                );
            } else {
                dispatch(
                    crudGetListAction({
                        resource,
                        view: null,
                        appendExpansions: expansions,
                        filter: {
                            id__IN: ids,
                        },
                        pagination: {
                            page: 1,
                            perPage: 1000,
                        },
                        sort: {
                            field: 'id',
                            order: 'DESC',
                        },
                    }),
                );
            }
        },
        [expansions, resource, dispatch],
    );

    const prevIdsRef = useRef([]);
    useEffect(() => {
        const addedIds = difference(currentIds, prevIdsRef.current);
        if (addedIds.length > 0) {
            fetchDataset(addedIds);
        }
        prevIdsRef.current = currentIds;
    }, [currentIds, fetchDataset]);

    const viewHasSearchDefined = useMemo(() => {
        return Object.keys(viewConfig.views[viewName]?.searchFields ?? {}).length > 0;
    }, [viewName, viewConfig]);
    const hasEditPermission = useMemo(() => {
        return allowsEdit(getAccessLevelForEntity(viewConfig, resource));
    }, [resource, viewConfig]);

    const hasSearchDefined = useMemo(() => {
        return Object.keys(filter).length > 0;
    }, [filter]);
    const theme = useTheme();

    const selectedElems = Object.entries(selectedRecords).map(([id, record]) => (
        <li key={id}>
            <div style={{ display: 'flex' }}>
                {hyperlinks ? (
                    <Link to={`/${resource}/${id}${hasEditPermission ? '' : '/show'}`} style={{ paddingTop: 15 }}>
                        {record.title}
                    </Link>
                ) : (
                    <span>{record.title}</span>
                )}
                {!disabled && (
                    <div>
                        <RemoveFromList
                            onClick={() => {
                                const { [id]: toRemove, ...rest } = selectedRecords;
                                input.onBlur(Object.keys(rest));
                            }}
                        />
                    </div>
                )}
            </div>
        </li>
    ));
    if (disabled && showListWhenDisabled === false) {
        const htmlDisabledListLabelId = source + '-disabledList-label';
        const title = (
            <Typography id={htmlDisabledListLabelId} variant={props.labelVariant ?? 'h5'} component="div">
                {props.label}
            </Typography>
        );
        return (
            <div>
                {title}
                <ul aria-labelledby={htmlDisabledListLabelId}>{selectedElems}</ul>
            </div>
        );
    }
    return (
        <div>
            <EntityInspect
                openTo={openTo}
                reference={resource}
                formId={`task-form-list-${viewName}-${source}`}
                renderComponent={({
                    formId,
                    reference,
                    keyForReload,
                    onRowSelect, // this we WONT use here.
                    selectId,
                    selectedId,
                }) => (
                    <GenericList
                        {...{
                            ...props,
                            embeddedInFormId,
                            resource,
                            useCard: false,
                            isPopover: false,
                            showImmediately: hasSearchDefined,
                            alwaysPreventInitialSearch: !hasSearchDefined && viewHasSearchDefined,
                            hasCreate: !disabled && hasCreate,
                            formId: formId,
                            multiSelectable: !disabled && useCheckboxes,
                            selectedData: !disabled && useCheckboxes ? selectedRecords : undefined,
                            renderNoResults: noResultsHtml
                                ? ({ ...s }) => {
                                      return <SafeHtmlAsReact html={noResultsHtml} />;
                                  }
                                : undefined,
                            onRowSelect:
                                customShowRedirects[reference] &&
                                customShowRedirects[reference].find((r) => r._isRowClick)
                                    ? undefined
                                    : (selectedData, allData) => selectedData[0] && selectId(selectedData[0].id),
                            renderList: (r) =>
                                getRenderer(
                                    {},
                                    {},
                                )({
                                    ...r,
                                    ariaProps: {
                                        'aria-describedby': meta.touched && meta.error ? errorMessageId : undefined,
                                    },
                                    single: props.singleSelect,
                                    ...(props.disableCellClick
                                        ? {
                                              noClick: true,
                                              keepRowEnds: true,
                                          }
                                        : undefined),
                                    renderAtRowEnd: getRenderAtRowEnd({
                                        displayPopoverEditButton: false,
                                        rowButtons: null,
                                    }),
                                    onRowSelectBulk:
                                        useCheckboxes && !disabled
                                            ? (selected, allData) => {
                                                  const ids = selected.map((e) => e.id);
                                                  fetchDataset(ids);
                                                  input.onBlur(ids);
                                              }
                                            : undefined,
                                }),
                            fakePush: (location) => {
                                setState((state) => ({ ...state, location }));
                            },
                            location: state.location,
                            title: props.label,
                            customTitleElement: props.labelVariant && (
                                <Typography variant={props.labelVariant} component="div">
                                    {props.label}
                                </Typography>
                            ),
                        }}
                    />
                )}
            />
            <EvaluateFormattedMessage>
                {({ evaluateFormattedMessage, translate }) =>
                    meta.touched && meta.error ? (
                        <span id={errorMessageId} style={{ fontSize: 'small', color: theme.palette.error.dark }}>
                            {translate({ id: 'validate.error' })}: {evaluateFormattedMessage(formatError(meta.error))}
                        </span>
                    ) : null
                }
            </EvaluateFormattedMessage>
            <ul>{selectedElems}</ul>
        </div>
    );
};
export default ListSelect;
