import getConfiguredValidation from './getConfiguredValidation';
import getInputTypeFromEntityField from './getTypeFromEntityField';
import * as fieldTypes from '../../fieldTypes';
import {
    getLabelForFieldExpr,
    getDescriptionForFieldExpr,
    getDataTypeForFieldExpr,
    isFieldViewField,
    isAddressVerificationField,
    isEventField,
    isComponentField,
} from '../../../components/generics/utils/viewConfigUtils';
import ViewConfig, { ViewField, EntityField } from '../../../reducers/ViewConfigType';
// import { EntityFormField } from './types';
import { FieldFromEntity as EntityOutputField, validationFunction } from '../types';
import { DataSource } from '../types/DataSource';
import { Mode } from 'fieldFactory/Mode';
import { IntlShape } from 'react-intl';
import { evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';

export const mapEntityField = (
    intl: IntlShape,
    viewConfig: ViewConfig,
    viewFieldDefinition: ViewField,
    entityFieldDefinition: EntityField | undefined,
    resource?: string,
    mode?: Mode,
    fieldInstanceIdentifier?: string,
): EntityOutputField => {
    const translateLabel = (label?: string) => {
        if (!label) {
            return label;
        }
        return evaluateFormattedText(intl, label);
    };
    const originalDefinition = JSON.stringify(viewFieldDefinition);
    const { widgetType, column, row, span } = viewFieldDefinition;
    if (isAddressVerificationField(viewFieldDefinition)) {
        const { label = null, config, field } = viewFieldDefinition;
        return {
            name: field || `AddressVerification:${row}:${column}:${span}`,
            label: translateLabel(label),
            type: fieldTypes.ADDRESS_VERIFICATION,
            config,
            'data-originaldefinition': originalDefinition,
            row,
            column,
            span,
            _dataSource: DataSource.ENTITY,
            fieldInstanceIdentifier,
        };
    } else if (isFieldViewField(viewFieldDefinition)) {
        const { searchType, field: name, entity } = viewFieldDefinition;
        const getLabel = () => {
            const entityLabel = entityFieldDefinition && entityFieldDefinition.label;
            const viewLabel =
                (viewFieldDefinition && viewFieldDefinition.label) || getLabelForFieldExpr(viewConfig, entity, name);
            return translateLabel(viewLabel || entityLabel);
        };
        const label = getLabel();
        const refEntityName: string | undefined = entityFieldDefinition
            ? entityFieldDefinition.relatedEntity
            : undefined;
        const validate: validationFunction[] = getConfiguredValidation(viewConfig, entity, name, widgetType);
        const dataType = getDataTypeForFieldExpr(viewConfig, entity, name);
        const description = viewFieldDefinition.description || getDescriptionForFieldExpr(viewConfig, entity, name);
        return {
            name,
            configuredEntity: entity,
            refEntityName,
            label,
            description,
            validate,
            'data-originaldefinition': originalDefinition,
            type: getInputTypeFromEntityField(widgetType, dataType, name, searchType, mode === 'Input'),
            column,
            row,
            span,
            searchType,
            _dataSource: DataSource.ENTITY,
            // addition of config below for the AddressValidation field is done outside of the type system rightnow.
            // This is because I don't know what widgetTypes will be involved yet.
            // But: It looks like config will be a permentant fixture
            // for editable/disabled fields, so may have to allow all fields to have access.
            config: (viewFieldDefinition as any).config,
            fieldInstanceIdentifier,
        } as any;
    } else if (isEventField(viewFieldDefinition)) {
        const { label = null, config } = viewFieldDefinition;
        return {
            name: viewFieldDefinition.label || `Event:${row}:${column}:${span}`,
            label: translateLabel(label),
            type: fieldTypes.EVENT,
            config,
            row,
            column,
            'data-originaldefinition': originalDefinition,
            span,
            _dataSource: DataSource.ENTITY,
            fieldInstanceIdentifier,
        };
    } else if (isComponentField(viewFieldDefinition)) {
        // Can just render placeholder
        return {
            configuredEntity: viewFieldDefinition.entity,
            name: viewFieldDefinition.field || `expression:r${row}c${column}`,
            label: '',
            type: fieldTypes.COMPONENT,
            row,
            'data-originaldefinition': originalDefinition,
            column,
            span,
            _dataSource: DataSource.ENTITY,
            fieldInstanceIdentifier,
        };
    } else {
        // html expression field
        const { label = null, config: htmlConfig } = viewFieldDefinition;
        return {
            name: viewFieldDefinition.field || `expression:r${row}c${column}`,
            label: translateLabel(label),
            type: fieldTypes.HTML_EXPRESSION,
            htmlConfig,
            row,
            'data-originaldefinition': originalDefinition,
            column,
            span,
            _dataSource: DataSource.ENTITY,
            fieldInstanceIdentifier,
        };
    }
};

// second arg to fit standard format of fieldDefinitions + linkedEntity
const translateViewFields =
    (intl: IntlShape, viewConfig: ViewConfig, resource?: string, mode?: Mode) =>
    (fieldDefinitions: ViewField[] | [string, ViewField][]): EntityOutputField[] => {
        const firstEntry = fieldDefinitions[0]; // going to use this to check what format is being passed.
        const _fieldDefinitions: [string | undefined, ViewField][] =
            firstEntry &&
            Array.isArray(firstEntry) &&
            firstEntry.length === 2 &&
            (typeof firstEntry[0] === 'string' || typeof firstEntry[0] === 'undefined')
                ? (fieldDefinitions as [string | undefined, ViewField][])
                : (fieldDefinitions as ViewField[]).map((vf) => [undefined, vf] as [undefined, ViewField]);
        return _fieldDefinitions.map(([fieldInstanceIdentifier, viewField], i) => {
            try {
                return mapEntityField(
                    intl,
                    // maps entity viewField to the standard fieldDefinition
                    viewConfig,
                    viewField,
                    isFieldViewField(viewField)
                        ? viewConfig.entities[viewField.entity].fields[viewField.field]
                        : undefined,
                    resource,
                    mode,
                    fieldInstanceIdentifier,
                );
            } catch (e) {
                console.log('e caught', e);
                const originalDefinition = JSON.stringify(viewField);
                return {
                    name: `${isFieldViewField(viewField) && viewField.field}-${i}`,
                    label: `${viewField.label}-${i}`,
                    type: fieldTypes.HTML_EXPRESSION as 'html-expression',
                    htmlConfig: `${e.message}`, // This is not quite safe.
                    row: viewField.row,
                    'data-originaldefinition': originalDefinition,
                    column: viewField.column,
                    span: viewField.span,
                    _dataSource: DataSource.ENTITY as DataSource.ENTITY,
                    fieldInstanceIdentifier,
                };
            }
        });
    };

export default translateViewFields;
