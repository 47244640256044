import { Button, CardActions, CardContent, CircularProgress, Dialog, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { ErrorMessage, Formik } from 'formik';
import { clearWaitingForMfa, loginWithMfaCode } from 'auth/actions';
import ReactAuthCodeInput from 'fieldFactory/input/components/AuthCodeInput/ReactAuthCodeInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import {
    getFirstLoginTotpQrExplainerHtmlSelector,
    getTotpCodeEntryExplainerHtmlSelector,
} from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

interface FormData {
    mfaCode: string;
}

const Mfa = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const mfa = useSelector((state: RootState) => state.mfa);
    const intl = useIntl();
    const { evaluateFormattedMessage } = useEvaluateFormattedMessage();
    const firstLoginTotpQrExplainerHtml = useSelector(getFirstLoginTotpQrExplainerHtmlSelector);
    const totpCodeEntryExplainerHtml = useSelector(getTotpCodeEntryExplainerHtmlSelector);
    if (mfa.type === 'null') {
        return null;
    }
    return (
        <Dialog open={true}>
            <div>
                <Formik
                    initialValues={{ mfaCode: '' }}
                    validate={(values: FormData) => {
                        let errors: Partial<FormData> = {};
                        if (!values.mfaCode || `${values.mfaCode}`.length < 6) {
                            errors.mfaCode = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        dispatch(
                            loginWithMfaCode(
                                {
                                    idToken: mfa.temporaryToken,
                                    mfaCode: values.mfaCode,
                                },
                                (error) => {
                                    setSubmitting(false);
                                    setStatus(error);
                                    return true;
                                },
                                mfa.redirectTo,
                            ),
                        );
                    }}
                >
                    {({
                        status,
                        isSubmitting,
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    Authenticator Code
                                </Typography>
                                <div>
                                    {mfa.mfaQrUrl || mfa.mfaQrImage ? (
                                        <div style={{ textAlign: 'center' }}>
                                            <div style={{ maxWidth: '400px', paddingTop: '1em', paddingBottom: '1em' }}>
                                                {firstLoginTotpQrExplainerHtml ? (
                                                    <SafeHtmlAsReact html={firstLoginTotpQrExplainerHtml} />
                                                ) : (
                                                    <p style={{ textAlign: 'left' }}>
                                                        Scan the QR Code to register using your authenticator app. Then
                                                        enter the code generated below.
                                                    </p>
                                                )}
                                            </div>
                                            {mfa.mfaQrUrl ? (
                                                <img src={mfa.mfaQrUrl} alt="QR Code" />
                                            ) : (
                                                <img src={'data:image/png;base64,' + mfa.mfaQrImage} alt="QR Code" />
                                            )}
                                            {mfa.mfaKey ? (
                                                <div>
                                                    <input
                                                        aria-label="Code to enter into authenticator"
                                                        style={{ textAlign: 'center' }}
                                                        value={mfa.mfaKey}
                                                    />
                                                </div>
                                            ) : null}
                                            <div style={{ textAlign: 'left', paddingLeft: '2em', paddingTop: '1em' }}>
                                                <Typography variant="h6" component="h3">
                                                    Confirm Code
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : totpCodeEntryExplainerHtml ? (
                                        <div>
                                            <SafeHtmlAsReact html={totpCodeEntryExplainerHtml} />
                                        </div>
                                    ) : null}
                                </div>
                                <br />
                                <div style={{ textAlign: 'center' }}>
                                    <ReactAuthCodeInput
                                        focusOnMount
                                        internalLabelPrefix={intl.formatMessage({ id: 'auth.code.authcode' })}
                                        characters={6}
                                        containerStyle={{
                                            padding: '16px',
                                            paddingTop: 0,
                                        }}
                                        inputStyle={{
                                            width: '2ch',
                                            padding: '8px',
                                            borderRadius: '8px',
                                            fontSize: '40px',
                                            textAlign: 'center',
                                            marginRight: '12px',
                                            border: '1px solid black',
                                            textTransform: 'uppercase',
                                        }}
                                        inputType="numeric"
                                        onChange={(value) => {
                                            setFieldValue('mfaCode', value);
                                        }}
                                    />
                                </div>
                                <div
                                    aria-live="assertive"
                                    style={{ width: '100%', textAlign: 'center', color: theme.palette.error.main }}
                                >
                                    <ErrorMessage name="mfaCode" />
                                    {status ? (
                                        <p
                                            style={{
                                                color: theme.palette.error.main,
                                            }}
                                        >
                                            {status.response?.AuthenticationException ? (
                                                evaluateFormattedMessage(status.response.AuthenticationException)
                                            ) : (
                                                <FormattedMessage
                                                    id="auth.code.retry"
                                                    description="Prompt to retry because code was incorrect"
                                                    defaultMessage="Authentication failed. Please retry"
                                                />
                                            )}
                                        </p>
                                    ) : null}
                                </div>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    aria-label="Submit"
                                    disabled={isSubmitting}
                                >
                                    {mfa.mfaQrUrl ? 'Register' : 'Submit'}
                                    {isSubmitting ? <CircularProgress style={{ height: 15, width: 15 }} /> : null}
                                </Button>
                                <Button
                                    aria-label="Close"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        dispatch(clearWaitingForMfa());
                                    }}
                                >
                                    Close
                                </Button>
                            </CardActions>
                        </form>
                    )}
                </Formik>
            </div>
        </Dialog>
    );
};
export default Mfa;
