import React, { Suspense } from 'react';
import type { FullFeaturedSPELEditorProps } from './FullFeaturedSpelEditor';

const Component = React.lazy(() => import('./FullFeaturedSpelEditor'));

const LazyFullFeaturedSpelEditor = (props: FullFeaturedSPELEditorProps) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    );
};
export default LazyFullFeaturedSpelEditor;
