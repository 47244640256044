import useViewConfig from './useViewConfig';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import { fromNullable, fromPredicate, Option } from 'fp-ts/lib/Option';

export const useResourceName = (viewName: string) => {
    const viewConfig = useViewConfig();
    return viewConfig.views[viewName].entity;
};

type ViewType = View['viewType'];
const getDefaultViewName =
    (viewConfig: ViewConfig) =>
    (resource: string, viewType: ViewType): Option<string> => {
        return fromNullable(resource)
            .mapNullable((entityName) => viewConfig.entities[entityName])
            .mapNullable((entity) => entity.defaultViews)
            .mapNullable((dvs) => dvs[viewType])
            .mapNullable((s) => s.name);
    };

const getDefaultRouteFromView =
    (viewConfig: ViewConfig) =>
    (viewName: string, viewType: ViewType): Option<string> => {
        const resource = viewConfig.views[viewName].entity;
        const plainRoute = getDefaultViewName(viewConfig)(resource, viewType)
            .mapNullable((relatedViewName) => viewConfig.views[relatedViewName])
            .chain((showView) => fromPredicate<string>(Boolean)(showView.route))
            .map((route) => (route.startsWith('/') ? route : `/${route}`));
        return plainRoute;
    };

export const useDefaultRouteFromView = (viewName: string, viewType: ViewType) => {
    const viewConfig = useViewConfig();
    return getDefaultRouteFromView(viewConfig)(viewName, viewType);
};
