/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['45ee8282'] = validate10;
const schema11 = {
    type: 'object',
    title: 'Claimable Tasks Widget',
    description: '',
    properties: {
        processKey: { items: { type: 'string', title: 'Process Key' }, title: 'Process Keys', type: 'array' },
        taskKey: { items: { type: 'string', title: 'Task Key' }, title: 'Task Keys', type: 'array' },
        roles: { items: { type: 'string', title: 'Role' }, title: 'Roles', type: 'array' },
        viewName: {
            title: 'View Name',
            type: 'string',
            description: 'Optionally, provide the name of a custom TaskInstance List view to override the default',
        },
        onlyUnassigned: { title: 'Show only unassigned tasks', type: 'boolean' },
        onlyAssignedToUser: { title: 'Show only tasks currently assigned to the current user', type: 'boolean' },
        claimTaskOnSelect: { title: 'Claim task on selection', type: 'boolean' },
        filter: { title: 'Filter', type: 'string', description: 'E.g. foo.bar__IN=el1;el2,key=value,key2=value2' },
    },
    dependencies: {},
    required: [],
    $id: '45ee8282',
};

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="45ee8282" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.processKey !== undefined) {
            let data0 = data.processKey;
            if (Array.isArray(data0)) {
                const len0 = data0.length;
                for (let i0 = 0; i0 < len0; i0++) {
                    let data1 = data0[i0];
                    if (typeof data1 !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/processKey/' + i0,
                            schemaPath: '#/properties/processKey/items/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.properties.processKey.items.type,
                            parentSchema: schema11.properties.processKey.items,
                            data: data1,
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                }
            } else {
                const err1 = {
                    instancePath: instancePath + '/processKey',
                    schemaPath: '#/properties/processKey/type',
                    keyword: 'type',
                    params: { type: 'array' },
                    message: 'must be array',
                    schema: schema11.properties.processKey.type,
                    parentSchema: schema11.properties.processKey,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err1];
                } else {
                    vErrors.push(err1);
                }
                errors++;
            }
        }
        if (data.taskKey !== undefined) {
            let data2 = data.taskKey;
            if (Array.isArray(data2)) {
                const len1 = data2.length;
                for (let i1 = 0; i1 < len1; i1++) {
                    let data3 = data2[i1];
                    if (typeof data3 !== 'string') {
                        const err2 = {
                            instancePath: instancePath + '/taskKey/' + i1,
                            schemaPath: '#/properties/taskKey/items/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.properties.taskKey.items.type,
                            parentSchema: schema11.properties.taskKey.items,
                            data: data3,
                        };
                        if (vErrors === null) {
                            vErrors = [err2];
                        } else {
                            vErrors.push(err2);
                        }
                        errors++;
                    }
                }
            } else {
                const err3 = {
                    instancePath: instancePath + '/taskKey',
                    schemaPath: '#/properties/taskKey/type',
                    keyword: 'type',
                    params: { type: 'array' },
                    message: 'must be array',
                    schema: schema11.properties.taskKey.type,
                    parentSchema: schema11.properties.taskKey,
                    data: data2,
                };
                if (vErrors === null) {
                    vErrors = [err3];
                } else {
                    vErrors.push(err3);
                }
                errors++;
            }
        }
        if (data.roles !== undefined) {
            let data4 = data.roles;
            if (Array.isArray(data4)) {
                const len2 = data4.length;
                for (let i2 = 0; i2 < len2; i2++) {
                    let data5 = data4[i2];
                    if (typeof data5 !== 'string') {
                        const err4 = {
                            instancePath: instancePath + '/roles/' + i2,
                            schemaPath: '#/properties/roles/items/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.properties.roles.items.type,
                            parentSchema: schema11.properties.roles.items,
                            data: data5,
                        };
                        if (vErrors === null) {
                            vErrors = [err4];
                        } else {
                            vErrors.push(err4);
                        }
                        errors++;
                    }
                }
            } else {
                const err5 = {
                    instancePath: instancePath + '/roles',
                    schemaPath: '#/properties/roles/type',
                    keyword: 'type',
                    params: { type: 'array' },
                    message: 'must be array',
                    schema: schema11.properties.roles.type,
                    parentSchema: schema11.properties.roles,
                    data: data4,
                };
                if (vErrors === null) {
                    vErrors = [err5];
                } else {
                    vErrors.push(err5);
                }
                errors++;
            }
        }
        if (data.viewName !== undefined) {
            let data6 = data.viewName;
            if (typeof data6 !== 'string') {
                const err6 = {
                    instancePath: instancePath + '/viewName',
                    schemaPath: '#/properties/viewName/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.viewName.type,
                    parentSchema: schema11.properties.viewName,
                    data: data6,
                };
                if (vErrors === null) {
                    vErrors = [err6];
                } else {
                    vErrors.push(err6);
                }
                errors++;
            }
        }
        if (data.onlyUnassigned !== undefined) {
            let data7 = data.onlyUnassigned;
            if (typeof data7 !== 'boolean') {
                const err7 = {
                    instancePath: instancePath + '/onlyUnassigned',
                    schemaPath: '#/properties/onlyUnassigned/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.onlyUnassigned.type,
                    parentSchema: schema11.properties.onlyUnassigned,
                    data: data7,
                };
                if (vErrors === null) {
                    vErrors = [err7];
                } else {
                    vErrors.push(err7);
                }
                errors++;
            }
        }
        if (data.onlyAssignedToUser !== undefined) {
            let data8 = data.onlyAssignedToUser;
            if (typeof data8 !== 'boolean') {
                const err8 = {
                    instancePath: instancePath + '/onlyAssignedToUser',
                    schemaPath: '#/properties/onlyAssignedToUser/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.onlyAssignedToUser.type,
                    parentSchema: schema11.properties.onlyAssignedToUser,
                    data: data8,
                };
                if (vErrors === null) {
                    vErrors = [err8];
                } else {
                    vErrors.push(err8);
                }
                errors++;
            }
        }
        if (data.claimTaskOnSelect !== undefined) {
            let data9 = data.claimTaskOnSelect;
            if (typeof data9 !== 'boolean') {
                const err9 = {
                    instancePath: instancePath + '/claimTaskOnSelect',
                    schemaPath: '#/properties/claimTaskOnSelect/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.claimTaskOnSelect.type,
                    parentSchema: schema11.properties.claimTaskOnSelect,
                    data: data9,
                };
                if (vErrors === null) {
                    vErrors = [err9];
                } else {
                    vErrors.push(err9);
                }
                errors++;
            }
        }
        if (data.filter !== undefined) {
            let data10 = data.filter;
            if (typeof data10 !== 'string') {
                const err10 = {
                    instancePath: instancePath + '/filter',
                    schemaPath: '#/properties/filter/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.filter.type,
                    parentSchema: schema11.properties.filter,
                    data: data10,
                };
                if (vErrors === null) {
                    vErrors = [err10];
                } else {
                    vErrors.push(err10);
                }
                errors++;
            }
        }
    } else {
        const err11 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err11];
        } else {
            vErrors.push(err11);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
