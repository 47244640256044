import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import { RelativeDate, decodeDiff, encodeDiff, isDiffEncoding } from './RelativeDate';
import USTDayInput from '../DayUSTInput/DayUSTInput';
import { initialModeContext } from 'custom-reports/run-report/initialModeContext';
import { decodePeriodStartEnd, encodePeriodStartEnd, isPeriodStartEndEncoding } from './PeriodStartEnd/utils';
import PeriodStartEnd from './PeriodStartEnd/PeriodStartEnd';

type EitherDateProps = {
    label: string;
    value: string;
    relativeDateAs: 'diff' | 'date'; // when in 'PeriodStartEnd' mode, this  means store the encoding
    onChange: (value: string) => void;
    source?: string;
};
export type Mode = 'Date' | 'RelativeDate' | 'PeriodStartEnd';
export const MultiRelativeDateField = (props: EitherDateProps) => {
    const initialModes = useContext(initialModeContext);

    const initialMode: Mode = useMemo(
        () =>
            (props.source && initialModes[props.source]) ||
            (isDiffEncoding(props.value)
                ? 'RelativeDate'
                : isPeriodStartEndEncoding(props.value)
                ? 'PeriodStartEnd'
                : 'Date'),
        [props.value, initialModes, props.source],
    );

    const [mode, setMode] = useState(initialMode);

    const RadioElement = (
        <div style={{ marginLeft: '1rem' }}>
            <RadioGroup
                value={mode}
                onChange={(e) => {
                    const newMode = e.target.value as Mode;
                    setMode(newMode);
                    if (props.relativeDateAs === 'diff') {
                        if (newMode === 'Date') {
                            if (isDiffEncoding(props.value)) {
                                const date = decodeDiff(props.value);
                                props.onChange(date);
                            }
                            if (isPeriodStartEndEncoding(props.value)) {
                                const date = decodePeriodStartEnd(props.value);
                                props.onChange(date);
                            }
                        }
                        if (newMode === 'RelativeDate' && props.value && !isDiffEncoding(props.value)) {
                            const dateToConvert: string = isPeriodStartEndEncoding(props.value)
                                ? decodePeriodStartEnd(props.value)
                                : props.value;
                            const relativeDate = encodeDiff(dateToConvert);
                            props.onChange(relativeDate);
                        }
                        if (newMode === 'PeriodStartEnd') {
                            const dateToConvert = isDiffEncoding(props.value) ? decodeDiff(props.value) : props.value;
                            const periodStartEndEncoding = encodePeriodStartEnd(dateToConvert);
                            console.log({
                                dateToConvert,
                                periodStartEndEncoding,
                            });
                            props.onChange(periodStartEndEncoding);
                        }
                    }
                }}
            >
                <FormControlLabel
                    control={<Radio size="small" style={{ padding: 0 }} />}
                    label={<span style={{ fontSize: '.8rem' }}>Specific Date</span>}
                    value="Date"
                />
                <FormControlLabel
                    control={<Radio size="small" style={{ padding: 0 }} />}
                    label={<span style={{ fontSize: '.8rem' }}>Relative Date</span>}
                    value="RelativeDate"
                />
                <FormControlLabel
                    control={<Radio size="small" style={{ padding: 0 }} />}
                    label={<span style={{ fontSize: '.8rem' }}>Period Start/End</span>}
                    value="PeriodStartEnd"
                />
            </RadioGroup>
        </div>
    );

    const calculatedDate = useMemo(() => {
        return isDiffEncoding(props.value) ? decodeDiff(props.value) : props.value;
    }, [props.value]);
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ minWidth: '280.8px', position: 'relative' }}>
                {mode === 'RelativeDate' ? (
                    <RelativeDate
                        relativeDateAs={props.relativeDateAs}
                        label={props.label}
                        value={props.value}
                        onChange={props.onChange}
                    />
                ) : mode === 'PeriodStartEnd' ? (
                    <PeriodStartEnd
                        relativeDateAs={props.relativeDateAs}
                        label={props.label}
                        value={props.value}
                        onChange={props.onChange}
                    />
                ) : (
                    <div style={{ marginTop: '.25em' }}>
                        <USTDayInput
                            meta={{}}
                            mode="YYYYMMDD"
                            input={{ value: calculatedDate, onChange: props.onChange, onBlur: props.onChange }}
                            label={props.label}
                        />
                    </div>
                )}
            </span>
            {RadioElement}
        </div>
    );
};

const ControlledMultiRelativeDate = (props) => {
    return (
        <MultiRelativeDateField
            source={props.source}
            relativeDateAs={props.relativeDateAs}
            value={props.input.value}
            onChange={props.input.onChange}
            label={props.label}
        />
    );
};
export default ControlledMultiRelativeDate;
