// check process.env.NODE_ENV if necessary
let backendBaseUrl =
    typeof window.CASETIVITY_SERVER_CONTEXT_ROOT === 'string'
        ? '/' + window.CASETIVITY_SERVER_CONTEXT_ROOT
        : process.env.REACT_APP_BACKEND_BASE_URL;
let taskPollPeriod = process.env.REACT_APP_TASK_POLL_PERIOD;

if (!backendBaseUrl) {
    backendBaseUrl = './';
}

if (!taskPollPeriod) {
    taskPollPeriod = 15;
}

export const BACKEND_BASE_URL = backendBaseUrl.replace(/\/\/$/, '/');
export const API_URL = `${BACKEND_BASE_URL}api/`;
export const TASK_POLL_PERIOD = taskPollPeriod;

export const REF_MANY_PER_PAGE = 10;
