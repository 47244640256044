import { useMemo } from 'react';
import { fromNullable } from 'fp-ts/lib/Option';
import useEntities from 'util/hooks/useEntities';

export const useMinimalRecord = (resource: string, id: string) => {
    const entities = useEntities();
    const dataLoaded = fromNullable(entities[resource])
        .mapNullable((e) => e[id])
        .fold(false, () => true);

    const currentEntityVersion = fromNullable(entities[resource])
        .mapNullable((e) => e[id])
        .mapNullable((r) => r.entityVersion)
        .toUndefined();
    const minimalRecord = useMemo(() => {
        return {
            id,
            entityVersion: currentEntityVersion,
            entityType: resource,
        };
    }, [currentEntityVersion, id, resource]);
    return { dataLoaded, minimalRecord };
};
