import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { createTask } from 'bpm/createTask/actions';
// import DateInput from '../../../../fieldFactory/input/components/DateInput';
import { reduxForm, Field } from 'redux-form';
import { processActivityEventCreator } from '../../../../actions/processActivityEvent';
import ITask from '../../../../types/task';
import { RootState } from '../../../../../reducers/rootReducer';
import { mapToApiType } from '../../../../dataAdapters/network/rest/tasks/entities/task';
import { Button, FormControl, InputLabel, CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
// import LongTextInput from '../../../../fieldFactory/input/components/LongTextInput';
import { Dispatch } from 'redux';
import { Activity } from 'bpm/reducer/types';
import uniqueId from 'lodash/uniqueId';
import { required } from 'fieldFactory/util/validate';
import withState from 'recompose/withState';
import CasetivitySelect from 'components/CasetivitySelect';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import createActivitiesSelector from '../selectors/activitiesSelector';
/*
    This is used to typecheck the fields in our form against our Task interface at compile time.
    for example, (use f.assignee, instead of "assignee")
*/
const f: { [key: string]: keyof ITask } = {
    dueDate: 'dueDate',
    priority: 'priority',
    name: 'name',
    description: 'description',
    taskDefinitionKey: 'taskDefinitionKey',
};

const styles = (theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.main,
        },
        formControl: {
            marginRight: theme.spacing(2),
            minWidth: 195,
        },
        menuItem: {
            zIndex: 4444,
        },
        inputStyle: {
            fontSize: 12,
            color: 'black',
        },
    });

const makeMapStateToProps = () => {
    const activitiesSelector = createActivitiesSelector();
    const mapStateToProps = (state: RootState, props: CreateTaskFormProps) => ({
        activities: activitiesSelector(state, props),
    });
    return mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: CreateTaskFormProps & {
        isPending: boolean;
        setPending: (pending: boolean) => void;
    },
) => ({
    onSubmit: (values: {}) => {
        ownProps.setPending(true);
        dispatch(
            createTask(
                {
                    ...values,
                    ...(ownProps.processId ? { processInstanceId: ownProps.processId } : {}),
                },
                (response) => {
                    ownProps.setPending(false);
                    ownProps.handleClose();
                    if (response.processInstanceId) {
                        dispatch(push(`/processes/${response.processInstanceId}/tasks/${response.id}/start`));
                    } else {
                        dispatch(push(`/tasks/${response.id}`));
                    }
                },
                {
                    '*': () => {
                        ownProps.setPending(false);
                    },
                },
            ),
        );
    },
    getProcessActivities: (
        pid: string,
        onSuccess: (data: Activity[]) => void = () => null,
        onError: () => void = () => null,
    ) => {
        dispatch(processActivityEventCreator.getProcessActivities(pid, onSuccess, onError));
    },
});

interface CreateTaskFormProps {
    processId: string;
    handleClose: () => void;
}

interface CreateTaskFormComponentProps
    extends CreateTaskFormProps,
        WithStyles<typeof styles>,
        ReturnType<typeof mapDispatchToProps>,
        ReturnType<ReturnType<typeof makeMapStateToProps>> {
    // from reduxform
    isPending: boolean;
    setPending: (pending: boolean) => void;
    handleSubmit: (cb: (values: ITask) => void) => () => void;
}

interface CreateTaskFormComponentState {
    open: boolean;
    currentDescription: string;
}
class CreateTaskFormComponent extends React.Component<CreateTaskFormComponentProps, CreateTaskFormComponentState> {
    inputLabelId = uniqueId('CreatTaskForm-inputLabel');
    state: CreateTaskFormComponentState = {
        open: false,
        currentDescription: '',
    };
    componentDidMount() {
        this.props.getProcessActivities(this.props.processId);
    }
    setCurrentDescription = (taskId) => {
        const { activities } = this.props;
        const currentDescription = (() => {
            if (activities.status !== 'success') {
                return '';
            }
            return activities.data.find((d) => d.id === taskId)?.description ?? '';
        })();
        this.setState({ currentDescription });
    };
    handleDropDownChange = (taskId) => {
        this.setCurrentDescription(taskId);
    };
    render() {
        const { activities, handleSubmit, onSubmit, classes, processId, handleClose } = this.props;
        if (activities.status === 'initial' || activities.status === 'pending') {
            return <CircularProgress />;
        }
        if (activities.status === 'error') {
            const error = activities.error;
            const msg = error instanceof Error ? error.message : error.body || error.status;
            return <div>Ad-hoc Tasks not available{msg ? `: ${msg}` : ''}</div>;
        }
        const activityData = activities.data;
        if (activityData.length === 0) {
            return <div>No Ad-hoc Tasks found for the current case.</div>;
        }
        const label = 'Activity Type';
        return (
            <div>
                <form autoComplete="off">
                    <h3 style={{ marginBottom: '1em' }}>New Task</h3>
                    {processId ? (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Field
                                name={f.taskDefinitionKey}
                                validate={required}
                                component={({ meta: { touched, error }, input }) => (
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink={true} id={this.inputLabelId}>
                                            {label}
                                        </InputLabel>
                                        <CasetivitySelect
                                            value={input.value}
                                            label={label}
                                            onChange={(event) => {
                                                input.onChange(event.target.value);
                                                this.handleDropDownChange(event.target.value);
                                            }}
                                            SelectDisplayProps={{ 'aria-describedby': this.inputLabelId }}
                                            inputProps={{
                                                name: 'activity-type',
                                                id: 'activity-type',
                                            }}
                                            MenuProps={{
                                                style: { zIndex: 324234 },
                                                MenuListProps: { title: 'Activity Type' },
                                            }}
                                        >
                                            {({ OptionComponent }) =>
                                                activityData &&
                                                Object.values(activityData).map((activity) => (
                                                    <OptionComponent
                                                        className={classnames('dropdownMenuItem', classes.menuItem)}
                                                        key={activity.id}
                                                        value={activity.id}
                                                    >
                                                        <EvaluateFormattedMessage>
                                                            {({ evaluateFormattedMessage }) => (
                                                                <>{evaluateFormattedMessage(activity.name)}</>
                                                            )}
                                                        </EvaluateFormattedMessage>
                                                    </OptionComponent>
                                                ))
                                            }
                                        </CasetivitySelect>
                                        <span className={classes.error}>
                                            {
                                                <EvaluateFormattedMessage>
                                                    {({ evaluateFormattedMessage }) => (
                                                        <>{touched && error ? evaluateFormattedMessage(error) : null}</>
                                                    )}
                                                </EvaluateFormattedMessage>
                                            }
                                        </span>
                                    </FormControl>
                                )}
                            />
                            {this.state.currentDescription}
                        </div>
                    ) : null}
                    <br />
                    {/*
                    <div>
                        <Field
                            name={f.dueDate}
                            component={DateInput}
                            label="Due Date"
                            aria-label="due date"
                        />
                    </div><br/>
                    <div>
                        <Field
                            name={f.description}
                            component={LongTextInput}
                            props={{ rows: 3, customStyles: {width: '50%'} }}
                            label="Description"
                            aria-label="description"
                        />
                    </div>
                    */}
                    <div style={{ textAlign: 'center', marginTop: '1em' }}>
                        <Button
                            color="primary"
                            disabled={this.props.isPending}
                            onClick={() => handleClose()}
                            style={{ marginRight: '5%' }}
                        >
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            disabled={this.props.isPending}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit((values: ITask) => onSubmit(mapToApiType(values)))}
                        >
                            Create {this.props.isPending && <CircularProgress style={{ height: 20, width: 20 }} />}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

const CreateTaskForm: React.SFC<CreateTaskFormProps> = compose(
    withState('isPending', 'setPending', false),
    connect(makeMapStateToProps, mapDispatchToProps),
    reduxForm({
        enableReinitialize: true,
        form: 'create-task-form',
    }),
    withStyles(styles),
)(CreateTaskFormComponent);

export default CreateTaskForm;
