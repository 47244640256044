import traverseGetData, { Entities } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import { postFixInUrl } from 'clients/utils/translateFieldWithSearchTypeAppended';
import _isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

const isDateIso = (str: string) => {
    return /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(str);
};

const isInstantIso = (str: string) => {
    return /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/.test(
        str,
    );
};

const isDateStringComparableByValue = (realValue: string, valueToQualifyAgainst: string) => {
    if (typeof realValue === 'string' && typeof valueToQualifyAgainst === 'string') {
        if (isInstantIso(realValue) && isInstantIso(valueToQualifyAgainst)) {
            // as long as it doesn't include timezone information (+ character), this is fine
            return true;
        }
        if (isDateIso(realValue) && isDateIso(valueToQualifyAgainst)) {
            return true;
        }
    }
    return false;
};

const compareNumbers = (left: string, comparator: '<' | '>' | '<=' | '>=' | '==', right: string) => {
    const leftFloat = parseFloat(left);
    const rightFloat = parseFloat(right);
    if (isNaN(leftFloat) || isNaN(rightFloat)) {
        return false;
    }
    switch (comparator) {
        case '<':
            return leftFloat < rightFloat;
        case '<=':
            return leftFloat <= rightFloat;
        case '==':
            return leftFloat === rightFloat;
        case '>':
            return leftFloat > rightFloat;
        case '>=':
            return leftFloat >= rightFloat;
    }
    return false;
};

const isEqual = (v1, v2) => {
    if (typeof v1 === 'string' && typeof v2 === 'string') {
        return v1.toLowerCase() === v2.toLowerCase();
    }
    if ((v1 === 'true' || v2 === 'true') && (v1 === true || v2 === true)) {
        return true;
    }
    if ((v1 === 'false' || v2 === 'false') && (v1 === false || v2 === false)) {
        return true;
    }
    if ((v1 === 'null' || v2 === 'null') && (v1 === null || v2 === null || v1 === undefined || v2 === undefined)) {
        return true;
    }
    if (compareNumbers(v1, '==', v2)) {
        return true;
    }
    return _isEqual(v1, v2);
};

const compares = (realValue: any = null, qualifier: keyof typeof postFixInUrl, _valueToQualifyAgainst: any = null) => {
    const valueToQualifyAgainst = Array.isArray(_valueToQualifyAgainst)
        ? _valueToQualifyAgainst.map((v) => (v === '_NULL_' ? null : v))
        : _valueToQualifyAgainst === '_NULL_'
        ? null
        : _valueToQualifyAgainst;
    switch (qualifier) {
        case 'CONTAINS_OR_NULL':
            if (isEmpty(realValue)) {
                return true;
            }
        // fall through to next case
        // eslint-disable-next-line
        case 'CONTAINS': {
            if (valueToQualifyAgainst === '') {
                return true;
            }
            if (
                (typeof realValue === 'string' || Array.isArray(realValue)) &&
                typeof valueToQualifyAgainst === 'string'
            ) {
                return realValue.includes(valueToQualifyAgainst);
            }
            // if either value isn't a string, return false.
            return false;
        }
        case 'DOES_NOT_CONTAIN': {
            if (
                (typeof realValue === 'string' || Array.isArray(realValue)) &&
                typeof valueToQualifyAgainst === 'string'
            ) {
                return !realValue.includes(valueToQualifyAgainst);
            }
            // if either value isn't a string, return false.
            return false;
        }
        case 'EXACT_OR_NULL':
            if (isEmpty(realValue)) {
                return true;
            }
        // fall through to next case
        // eslint-disable-next-line
        case 'EXACT': {
            if (Array.isArray(realValue)) {
                return realValue.some((rv) => isEqual(rv, valueToQualifyAgainst));
            }
            return isEqual(realValue, valueToQualifyAgainst);
        }
        case 'EQUAL_OR_NULL': {
            return isEmpty(realValue) || isEqual(realValue, valueToQualifyAgainst);
        }
        case 'NOT_EQUALS': {
            return !isEqual(realValue, valueToQualifyAgainst);
        }
        case 'GREATER': {
            if (isDateStringComparableByValue(realValue, valueToQualifyAgainst)) {
                return realValue > valueToQualifyAgainst;
            }
            return compareNumbers(realValue, '>', valueToQualifyAgainst);
        }
        case 'GREATER_OR_NULL': {
            if (isEmpty(realValue)) {
                return true;
            }
            if (isDateStringComparableByValue(realValue, valueToQualifyAgainst)) {
                return realValue > valueToQualifyAgainst;
            }
            return compareNumbers(realValue, '>', valueToQualifyAgainst);
        }
        case 'GREATER_EQUAL': {
            if (isDateStringComparableByValue(realValue, valueToQualifyAgainst)) {
                return realValue >= valueToQualifyAgainst;
            }
            return compareNumbers(realValue, '>=', valueToQualifyAgainst);
        }
        case 'IN': {
            if (Array.isArray(valueToQualifyAgainst)) {
                return valueToQualifyAgainst.some((qv) => isEqual(qv, realValue));
            }
            if (valueToQualifyAgainst && typeof valueToQualifyAgainst === 'string') {
                const listToCheckIn = valueToQualifyAgainst
                    .split(',')
                    .map((s) => s.trim())
                    .map((s) => (s === '_NULL_' ? null : s));
                if (Array.isArray(realValue)) {
                    return realValue.some((e) => listToCheckIn.some((lv) => isEqual(lv, e)));
                }
                return listToCheckIn.some((lv) => isEqual(lv, realValue));
            }

            // I guess 'IN' is a superset of 'equals' for non-arrays of values.
            //e.g. someField__IN=_NULL_ should be true if someField: null
            return isEqual(valueToQualifyAgainst, realValue);
        }
        case 'NOT_IN': {
            if (Array.isArray(valueToQualifyAgainst)) {
                return !valueToQualifyAgainst.includes(realValue);
            }
            if (valueToQualifyAgainst && typeof valueToQualifyAgainst === 'string') {
                const listToCheckIn = valueToQualifyAgainst
                    .split(',')
                    .map((s) => s.trim())
                    .map((s) => (s === '_NULL_' ? null : s));
                if (Array.isArray(realValue)) {
                    return !realValue.some((e) => listToCheckIn.includes(e));
                }
                return !listToCheckIn.some((rv) => isEqual(rv, realValue));
            }
            // false if valueToQualifyAgainst isn't an array
            return false;
        }
        case 'LESS': {
            if (isDateStringComparableByValue(realValue, valueToQualifyAgainst)) {
                return realValue < valueToQualifyAgainst;
            }

            return compareNumbers(realValue, '<', valueToQualifyAgainst);
        }
        case 'LESS_OR_NULL': {
            if (isEmpty(realValue)) {
                return true;
            }
            if (isDateStringComparableByValue(realValue, valueToQualifyAgainst)) {
                return realValue < valueToQualifyAgainst;
            }
            return compareNumbers(realValue, '<', valueToQualifyAgainst);
        }
        case 'LESS_EQUAL': {
            if (isDateStringComparableByValue(realValue, valueToQualifyAgainst)) {
                return realValue <= valueToQualifyAgainst;
            }
            return compareNumbers(realValue, '<=', valueToQualifyAgainst);
        }
        case 'NOT_EMPTY': {
            if (valueToQualifyAgainst && valueToQualifyAgainst !== 'false') {
                return !isEmpty(realValue);
            }
            return isEmpty(realValue);
        }
        case 'STARTS_WITH_OR_NULL':
            if (isEmpty(realValue)) {
                return true;
            }
        // fall through to next case
        // eslint-disable-next-line
        case 'STARTS_WITH': {
            if (typeof realValue === 'string' && typeof valueToQualifyAgainst === 'string') {
                return realValue.toLowerCase().startsWith(valueToQualifyAgainst.toLowerCase());
            }
            return false;
        }
        default: {
            // console.error(`BAD SEARCH MODIFIER ${qualifier}`);
            // consider it exact
            if (Array.isArray(realValue)) {
                return realValue.some((rv) => isEqual(rv, valueToQualifyAgainst));
            }
            return isEqual(realValue, valueToQualifyAgainst);
        }
    }
};

/*
representation would be something like:
{
    name__CONTAINS: 'foo',
    label__CONTAINS: 'bar',
    order__GREATER: '10',
    'parent.name__EXACT': 'qux',
    'view.name__CONTAINS': 'quux',
},
*/
const filterEntityByQueryRepresentation =
    <ViewConfig extends { entities: ViewConfigEntities }>(viewConfig: ViewConfig) =>
    (representation: { [fieldAndSearch: string]: string }) => {
        return (
            entity: {
                id: string;
                entityType: string;
            },
            entities: Entities,
        ) => {
            return Object.entries(representation).reduce((prev, [fieldAndSearch, value]) => {
                const [fieldPath, searchModifier] = fieldAndSearch.split('__');
                // get the value from fieldPath

                const realValue = traverseGetData(viewConfig, fieldPath, entity, entities, true)
                    .map((rv) =>
                        // on paths like "users.programs.id" we need to flatten the list of ids
                        Array.isArray(rv) ? rv.flat() : rv,
                    )
                    .getOrElse(null);

                const matches = compares(realValue, searchModifier as keyof typeof postFixInUrl, value);

                return prev && matches;
            }, true);
        };
    };

export default filterEntityByQueryRepresentation;
