import { SearchField } from 'reducers/ViewConfigType';
import { ViewField } from 'reducers/ViewConfigType';
import { isFieldViewField, isExpressionViewField, isComponentField } from 'components/generics/utils/viewConfigUtils';

export const getFieldKeyGetter = () => {
    const dupeCount: {
        [id: string]: number;
    } = {};
    return (viewField: ViewField & { overrideId?: string }) => {
        let id =
            isFieldViewField(viewField) ||
            isComponentField(viewField) ||
            (isExpressionViewField(viewField) && viewField.field && viewField.field.startsWith('$'))
                ? (viewField as { overrideId: string }).overrideId || viewField.field
                : `field_${viewField.row}_${viewField.column}`;
        const searchType = (viewField as SearchField).searchType;
        if (searchType) {
            id += `__${searchType}`;
        }
        if (typeof dupeCount[id] === 'number') {
            dupeCount[id]++;
            id += '_'.repeat(dupeCount[id]);
        } else {
            dupeCount[id] = 0;
        }
        return id;
    };
};
