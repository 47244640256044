import {
    getTabsTitlesFromView,
    getDataTypeForFieldExpr,
    expandComponentFields,
    mapFieldEntriesToDataFields,
} from '../utils/viewConfigUtils/index';
import { Config } from 'fieldFactory/ConfigTypes';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import ViewConfig, { ViewField } from 'reducers/ViewConfigType';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import { RootState } from 'reducers/rootReducer';
import { Target } from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/Entry';
import { ParentBackRefProperties } from '../genericCreate/ParentBackRefProperties';
import { OfflineDownloadedFields } from 'offline_app/offline_stateful_tasks/download/downloadedListViews/data';

export const getGetGenerateFields = <
    P extends {
        printMode?: boolean;
        referenceFieldsShouldFetchInitialData?: boolean;
        optInWriteable?: {
            [field: string]: FormFieldUnion;
        };
        record?: {};
        basePath?: string;
        match?: {};
        nonEditableAddressWidget?: boolean;
        resource?: string;
        formId?: string;
        DEBUG_disableAllFields?: boolean;
        isPopover?: boolean;
        isForCreate?: boolean;
        validate?: boolean;
        reviewOfflineDataModeForGroup?: {
            [source: string]: true;
        };
        backref?: ParentBackRefProperties;
    },
>(
    fieldFactory: (
        config: Config,
    ) => (liveProps: {}, selector: any) => (fieldInstanceEntries: [string, ViewField][]) => JSX.Element[],
    props: P,
    selector?: (state: RootState) => any,
    mode: Mode.INPUT | Mode.DISPLAY = Mode.INPUT,
) => {
    const config: Config = {
        dataSource: DataSource.ENTITY,
        mode,
        validate: mode === Mode.INPUT && props.validate !== false,
        connected: mode === Mode.INPUT,
        options: {
            optInWriteable: props.optInWriteable,
            ...(props.backref ?? {}),
        },
    };
    const getGenerateFields = (dataWasPrefetchedForThisGroup: boolean) => {
        return fieldFactory(config)(
            {
                isForCreate: props.isForCreate,
                record: props.record,
                resource: props.resource,
                basePath: props.basePath,
                match: props.match,
                nonEditableAddressWidget: props.nonEditableAddressWidget,
                referenceFieldsShouldFetchInitialData: (() => {
                    if (dataWasPrefetchedForThisGroup) {
                        return false;
                    }
                    return props.printMode || props.referenceFieldsShouldFetchInitialData;
                })(),
                shouldFetchValueset: false,
                embeddedInFormId: props.formId,
                disabled: props.DEBUG_disableAllFields,
                isPopover: props.isPopover,
                overrideFieldValueIfDisabled: true,
                reviewOfflineDataModeForGroup: props.reviewOfflineDataModeForGroup,
            },

            selector,
        );
    };
    return getGenerateFields;
};

const getFields = (
    fieldFactory: (config: Config) => (liveProps: {}) => (fieldInstanceEntries: [string, ViewField][]) => JSX.Element[],
    _props: {
        isForCreate?: boolean;
        printMode?: boolean;
        referenceFieldsShouldFetchInitialData?: boolean;
        optInWriteable?: {
            [field: string]: FormFieldUnion;
        };
        record?: {};
        basePath?: string;
        nonEditableAddressWidget?: boolean;
        match?: {};
        resource?: string;
        formId?: string;
        DEBUG_disableAllFields?: boolean;
        isPopover?: boolean;
        viewConfig: ViewConfig;
        viewName: string;
        tabToPrefetch?: string;
        validate?: boolean;
        reviewOfflineDataMode?: Target[];
        backref?: ParentBackRefProperties;
        offlineDownloadedFields?: OfflineDownloadedFields;
        expandComponents?: boolean;
    },
    selector?: (state: RootState) => any,
    mode: Mode.INPUT | Mode.DISPLAY = Mode.INPUT,
) => {
    const { reviewOfflineDataMode, offlineDownloadedFields, expandComponents, ...props } = _props;
    const getGenerateFields = (tabKey?: string) =>
        getGetGenerateFields(
            fieldFactory,
            {
                ...props,
                reviewOfflineDataModeForGroup: tabKey
                    ? reviewOfflineDataMode
                          ?.filter((t) => t.tabKey === tabKey)
                          .reduce((prev, curr) => {
                              prev[curr.source] = true;
                              return prev;
                          }, {})
                    : undefined,
            },
            selector,
            mode,
        );
    const isOfflineAvailableFieldEntry = (entry: [string, ViewField]) => {
        if (!offlineDownloadedFields) {
            return true;
        }
        const [, viewField] = entry;
        if (
            (viewField.widgetType === 'MULTISELECT' &&
                getDataTypeForFieldExpr(props.viewConfig, viewField.entity, viewField.field, 'POP_LAST') !==
                    'VALUESETMANY') ||
            (viewField.widgetType === 'SELECT' &&
                getDataTypeForFieldExpr(props.viewConfig, viewField.entity, viewField.field, 'POP_LAST') === 'REFONE')
        ) {
            return Boolean(offlineDownloadedFields?.[viewField.field]?.[viewField.config]);
        }
        return true;
    };

    const getFieldInstances = (tab?: string) => {
        const instances = Object.entries(
            tab
                ? props.viewConfig.views[props.viewName].tabs![tab].fields
                : props.viewConfig.views[props.viewName].fields,
        );
        const expandedFields = expandComponents
            ? expandComponentFields(_props.viewConfig, instances, props.viewConfig.views[props.viewName].entity, {
                  rebaseExpressionsWithinFields: true,
                  replaceXmanyWithMultiCard: props.printMode,
              }).expandedFieldsByRow.flat()
            : instances;

        return mapFieldEntriesToDataFields(
            props.viewConfig,
            expandedFields,
            props.viewConfig.views[props.viewName].entity,
        );
    };

    return {
        baseFields: getGenerateFields()(true)(getFieldInstances().filter(isOfflineAvailableFieldEntry)),
        fieldsByTab: Object.assign(
            {},
            ...getTabsTitlesFromView(props.viewConfig.views[props.viewName]).map((tabKey) => ({
                [tabKey]: getGenerateFields(tabKey)(tabKey === props.tabToPrefetch)(
                    getFieldInstances(tabKey).filter(isOfflineAvailableFieldEntry),
                ),
            })),
        ),
    };
};

export default getFields;
