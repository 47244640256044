import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { View } from '@mkanai/casetivity-shared-js/lib/view-config/views';
import { TextField, Button } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';

export interface FormData {
    label: View['tabs'][0]['label'];
}
interface AddTabProps {
    initialValues?: Partial<FormData>;
    onAdd: (data: FormData) => void;
}
const AddTab: React.FunctionComponent<AddTabProps> = (props) => {
    const methods = useForm<FormData>({
        defaultValues: props.initialValues || {
            label: '',
        },
    });

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <div style={{ padding: '1em', margin: '1em' }}>
            <h3>{props.initialValues ? 'Edit Tab' : 'Add Tab'}</h3>
            <form onSubmit={methods.handleSubmit(props.onAdd)}>
                <TextField
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    variant={fieldVariant}
                    margin="normal"
                    fullWidth
                    inputRef={methods.register}
                    label="Label"
                    name="label"
                />
                <Button variant="contained" color="primary" type="submit">
                    Save
                </Button>
            </form>
        </div>
    );
};
export default AddTab;
