import { Ast } from 'ts-spel/lib/lib/Ast';
import { methodNamesWithValueSetCodeArgument } from '.';
import simpleTraverseAst from 'ts-spel-utils/simpleTraverseAst';

/**
 * if this is generic enough, extract it as a generic traverse function
 */

const getValuesetCodeLiterals = (ast: Ast): Set<string> => {
    const codeLiterals = new Set<string>();
    simpleTraverseAst((node, precededByIfCompound) => {
        if (node.type === 'FunctionReference' || node.type === 'MethodReference') {
            const paramWeWant =
                methodNamesWithValueSetCodeArgument[
                    node.type === 'FunctionReference' ? node.functionName : node.methodName
                ];
            if (typeof paramWeWant === 'undefined') {
                return;
            }
            const nodeAtParam = node.args[paramWeWant];
            if (nodeAtParam.type === 'StringLiteral') {
                codeLiterals.add(nodeAtParam.value);
            }
        }
        // When we have #f(), we can check the second cb argument to ensure we are on the right method.
    })(ast);
    return codeLiterals;
};

export default getValuesetCodeLiterals;
