import buildHeaders from 'sideEffect/buildHeaders';
import { getUrl } from 'sideEffect/services';
const config = require('../../../../config.js');

export const getOnDrop =
    (
        eventCbs: {
            beforeRequest?: () => void;
            onResponse: (statusCode: number) => void;
            onSuccessWithData: (responseData: unknown) => void;
            afterRequest?: () => void;
            onNetworkError?: () => void;
        },
        taskId?,
    ) =>
    (files: File[]) => {
        const { beforeRequest, onSuccessWithData, onResponse, afterRequest, onNetworkError } = eventCbs;
        const callAfterRequest = () => {
            if (afterRequest) {
                afterRequest();
            }
        };
        const callBeforeRequest = () => {
            if (beforeRequest) {
                beforeRequest();
            }
        };
        const callOnError = () => {
            callAfterRequest();
            if (onNetworkError) {
                onNetworkError();
            }
        };
        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                const data = {
                    file: (reader!.result as string).split('base64,')[1],
                    fileFileName: files[0].name || '',
                    fileContentType: files[0].type || '',
                };

                const body = JSON.stringify(data);

                const url = getUrl(taskId ? `api/bpm/tasks/${taskId}/process-docs` : 'api/bpm/process-docs');
                const request = new Request(url, {
                    method: 'POST',
                    body: body,
                    credentials: 'same-origin',
                    headers: buildHeaders({
                        includeCredentials: true,
                        'Content-Type': 'application/json',
                    }),
                });
                callBeforeRequest();
                fetch(request)
                    .then((response) => {
                        onResponse(response.status);
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error();
                        } else {
                            return response.json();
                        }
                    })
                    .then((parsedData) => {
                        onSuccessWithData(parsedData);
                        callAfterRequest();
                    })
                    .catch(callOnError);
            },
            false,
        );
        if (files[0]) {
            reader.readAsDataURL(files[0]);
        }
    };
