import { View } from 'reducers/ViewConfigType';
import { fromPredicate, tryCatch } from 'fp-ts/lib/Option';

const getOverrideSecondarySorts = (view?: View) => {
    return fromPredicate<View>(Boolean)(view)
        .chain((v) => fromPredicate<string>(Boolean)(v.config))
        .chain((c) => tryCatch(() => JSON.parse(c)))
        .mapNullable((c) => c['overrideSecondarySorts'] as { field?: string; direction?: 'ASC' | 'DESC' }[])
        .map((l) => l.filter((s) => Boolean(s.field && s.direction)));
    // below is from when it wasn't an array
    // .chain(overrideSecondarySort =>
    //     fromPredicate((s: typeof overrideSecondarySort) => Boolean(s.field && s.direction))(overrideSecondarySort),
    // );
};
export default getOverrideSecondarySorts;
