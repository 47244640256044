import { ExpressionEvaluatorContext } from 'expressions/Provider/expressionEvaluatorContext';
import { SpelExpressionEvaluator } from 'spel2js';
import { SpelCompiledExpression } from '@mkanai/casetivity-shared-js/lib/spel/evaluate';
import { tryCatch } from 'fp-ts/lib/Either';
import { convertFieldsRequiredToUseableFields } from 'clients/utils/getFieldsRequiredForExpression';
import getFieldsInAst from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import getMethodAndFunctionNames from 'expressions/getMethodsAndFunctions';
import getValueSetCodeLiterals from 'expressions/getFieldsInAst/getValuesetCodeLiterals';
import { EvaluationResult } from '../CompileExpression';

const jsImpl: ExpressionEvaluatorContext = {
    compileExpression: (expression: string) => {
        try {
            const compiled: SpelCompiledExpression = SpelExpressionEvaluator.compile(expression);
            const ast = compiled._compiledExpression;
            // 'expansions' is what is needed to fetch data, e.g. for foo.?[#this.bar] this is foo.bar
            // but 'paths' is what is needed to initialize or traverse data: above would be foo._ALL_.bar
            const getExpansionsWithAll = () => getFieldsInAst(expression)(ast);
            const getPathsWithAll = () => getFieldsInAst(expression, true)(ast);
            const getExpansions = () => convertFieldsRequiredToUseableFields(getExpansionsWithAll(), true);
            const getExpansionsWithoutArrayDescendants = () =>
                convertFieldsRequiredToUseableFields(getPathsWithAll(), true);
            const methodsAndFunctions = getMethodAndFunctionNames(expression)(ast);
            const getValueSetLiterals = () => getValueSetCodeLiterals(expression)(ast);
            return {
                type: 'parse_success',
                evaluate: (context, functionsAndVariables) => {
                    return tryCatch(
                        () => compiled.eval(context, functionsAndVariables),
                        (e: Error) => e,
                    ).fold<EvaluationResult>(
                        (l) => {
                            console.error(l);
                            return {
                                type: 'evaluation_failure',
                                msg: l.message,
                            };
                        },
                        (result) => ({
                            type: 'evaluation_success',
                            result,
                        }),
                    );
                },
                getExpansions,
                getPathsWithAll,
                getExpansionsWithoutArrayDescendants,
                getExpansionsWithAll,
                methodsAndFunctions,
                getValueSetLiterals,
            };
        } catch (e) {
            // lets send stack to console so it's usable for debugging.
            console.error(e);
            return {
                type: 'parse_failure',
                i: 0,
                msg: e.toString(),
            };
        }
    },
    evaluateTemplate: (template, sanitizeResult) => {
        return (context, functionsAndVariables) =>
            tryCatch(() =>
                evaluateFilterString(
                    template,
                    {
                        ...functionsAndVariables,
                        ...context,
                    },
                    sanitizeResult,
                ),
            ).mapLeft((e) => [e.message]);
    },
};

export default jsImpl;
