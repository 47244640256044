import React, { useEffect, useMemo } from 'react';
import PopoverRefInput from 'fieldFactory/popovers/PopoverRefInput';
import { Option, fromNullable } from 'fp-ts/lib/Option';
import useDisplayName from 'components/generics/title/hooks/useDisplayName';
import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import set from 'lodash/set';
import { createDefaultView } from 'layout-editor/generateDefaultViews/GenerateDefaultViews';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
interface SelectRecordProps {
    resource: string;
    sessionStorageKey?: string;
    onSelect?: (id: Option<string>) => void;
    renderSelection: (id: Option<string>) => JSX.Element;
}
const SelectRecord: React.FunctionComponent<SelectRecordProps> = (props) => {
    const { resource, renderSelection, sessionStorageKey, onSelect } = props;
    const displayName = useDisplayName({ resource, type: 'SINGULAR' });
    const initial = useMemo(() => {
        return fromNullable(props.sessionStorageKey)
            .mapNullable((k) => sessionStorage.getItem(k))
            .mapNullable(JSON.parse);
    }, []); // eslint-disable-line
    const [selectedRecordId, setSelectedRecordId] = React.useState<Option<string>>(initial);
    useEffect(() => {
        if (sessionStorageKey) {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(selectedRecordId.toNullable()));
        }
    }, [selectedRecordId, sessionStorageKey]);

    const _viewConfig = useViewConfig();
    const hasListViewDefined = _viewConfig.entities[resource].defaultViews?.LIST?.name;

    const fallbackViewName = '_FALLBACK_' + resource + 'List';
    const viewConfig = useMemo(() => {
        if (hasListViewDefined) {
            return _viewConfig;
        }
        return produce(_viewConfig, (draftViewConfig) => {
            set(draftViewConfig.entities[resource], 'defaultViews.LIST.name', fallbackViewName);
            const defaultView = createDefaultView(_viewConfig.entities[resource], 'LIST');
            console.log({
                defaultView,
            });
            draftViewConfig.views[fallbackViewName] = {
                ...defaultView,
                name: fallbackViewName,
            };
            return draftViewConfig;
        });
    }, [_viewConfig, resource, hasListViewDefined, fallbackViewName]);

    const translatedLabel = useEvaluatedFormattedMessage(displayName);
    const ChildElem = (
        <div>
            <WithErrorBoundary>
                <PopoverRefInput
                    input={{
                        onBlur: (v) => {
                            if (typeof v !== 'undefined') {
                                const option = fromNullable(v);
                                setSelectedRecordId(option);
                                onSelect?.(option);
                            }
                        },
                        value: selectedRecordId.toNullable(),
                    }}
                    meta={{}}
                    viewName={hasListViewDefined ? undefined : fallbackViewName}
                    reference={resource}
                    resource={resource}
                    source="foo"
                    label={translatedLabel}
                    options={{}}
                />
            </WithErrorBoundary>
            {renderSelection(selectedRecordId)}
        </div>
    );
    if (!hasListViewDefined) {
        return (
            <OverriddenViewConfigContext.Provider value={viewConfig}>{ChildElem}</OverriddenViewConfigContext.Provider>
        );
    }
    return ChildElem;
};
export default SelectRecord;
