import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Refresh from '@material-ui/icons/Refresh';
import React, { useState } from 'react';
import useIsTheInstalledApp from 'util/hooks/useIsTheInstalledApp';
import { isStandalone } from 'util/isTheInstalledApp';

const useStyles = makeStyles((theme) => ({
    inlineItem: {
        display: 'inline-flex',
        flex: '0 0 auto',
        textAlign: 'center',
        alignItems: 'center',
        verticalAlign: 'middle',
    },
    fixedPositionToolbar: {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 2147483647,
        height: '30px',
        backgroundColor: 'black',
        display: 'flex',
    },
    spacer: {
        height: '30px',
        backgroundColor: 'black',
    },
}));

const MinimalUIToolbarForStandaloneApp = () => {
    // maybe to refresh when installed?
    useIsTheInstalledApp();
    const classes = useStyles();
    const [isReloading, setIsReloading] = useState(false);
    if (isStandalone()) {
        return (
            <div>
                <div className={classes.spacer} />
                <div className={classes.fixedPositionToolbar}>
                    <div className={classes.inlineItem}>
                        <IconButton
                            onClick={() => {
                                window.history.back();
                            }}
                            size="small"
                        >
                            <ArrowBack fontSize="small" style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                    <div style={{ width: '.5em' }} />
                    <div className={classes.inlineItem}>
                        <IconButton
                            onClick={() => {
                                setIsReloading(true);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 200);
                            }}
                            size="small"
                        >
                            {isReloading ? (
                                <CircularProgress style={{ color: 'white' }} size="1.125rem" />
                            ) : (
                                <Refresh fontSize="small" style={{ color: 'white' }} />
                            )}
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export default MinimalUIToolbarForStandaloneApp;
