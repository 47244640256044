import React, { useRef, useCallback, useState, useEffect, useContext } from 'react';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import moment, { Moment } from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import uniqueId from 'lodash/uniqueId';
import MaskedInput from 'react-text-mask';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import formatError from 'fieldFactory/util/formatError';

const timeMask = [/[01]/, /\d/, ':', /[012345]/, /\d/, ' ', /[aApP]/, 'M'];
function TextMaskCustom(props) {
    const { inputRef, mask, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={timeMask}
            placeholderChar={'_'}
            showMask={true}
        />
    );
}

type Input = any;
type Meta = any;

const hideIfNoInput = branch(
    (props) => !props.input, // input is not provided
    renderNothing,
);

export interface TimePickerProps {
    label: string;
    input: Input;
    meta: Meta;
    ariaInputProps?: {};
    renderLabel?: boolean;
    disabled?: boolean;
    options?: {};
    source?: string;
}

const getInputTextFromValue = (value: string) => {
    if (!value) {
        return '';
    }
    const m = moment(value, 'HH:mm:ss');
    // console.log(m.toString());
    if (m.isValid()) {
        return m.format('hh:mm A');
    }
    console.error('invalid date passed to timePicker: ', value);
    return '';
};
const isValidDateText = (inputText: string) =>
    inputText.trim().length === 8 && !inputText.includes('_') && moment(inputText, 'hh:mm A', true).isValid();

const TimePicker: React.FC<TimePickerProps> = (props) => {
    const inputEl = useRef<HTMLInputElement>(null);
    const {
        ariaInputProps = {},
        renderLabel = true,
        input,
        label,
        meta: { touched, error },
        disabled,
        options,
    } = props;
    const errorMessageId = useRef(uniqueId('datetimepickerErrormsg'));
    const [inputText, setInputText] = useState(getInputTextFromValue(input.value));
    useEffect(() => {
        // if input.value changed to something different than state, update state
        if (input.value) {
            setInputText(getInputTextFromValue(input.value));
        } else if (inputEl.current !== document.activeElement) {
            setInputText('');
        }
    }, [input.value]);
    const clearInput = useCallback(() => {
        input.onBlur('');
    }, [input]);
    const propagateMoment = useCallback(
        (value: Moment) => {
            if (value.isValid()) {
                input.onBlur(value.format('HH:mm:ss'));
                return true;
            }
            return false;
        },
        [input],
    );
    const inputProps = {
        'aria-errormessage': touched && error && errorMessageId.current,
    };
    const valid = isValidDateText(inputText);
    const value = valid ? moment(inputText, 'hh:mm a', true) : moment(inputText, ':::' /* <-invalid */, true); // value here uses the '_i' of the moment object to display
    const selectStart = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.selectionEnd - e.target.selectionStart > 0) {
            return;
        }
        const value: string = e.target.value;
        if (!value || typeof value !== 'string') {
            return;
        }
        if (value === '__:__ _M') {
            e.target.setSelectionRange(0, 0);
        } else if (value.endsWith('_:__ _M')) {
            e.target.setSelectionRange(1, 1);
        } else if (value.endsWith('__ _M')) {
            e.target.setSelectionRange(3, 3);
        } else if (value.endsWith('_ _M')) {
            e.target.setSelectionRange(4, 4);
        } else if (value.endsWith('_M')) {
            e.target.setSelectionRange(6, 6);
        }
    };

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);

    const { evaluateFormattedMessage, translate } = useEvaluateFormattedMessage();
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <KeyboardTimePicker
                inputRef={inputEl}
                keyboard={true}
                variant={fieldVariant}
                InputLabelProps={getInputLabelProps({
                    shrink: true,
                })}
                InputProps={{
                    onFocus: selectStart,
                    onMouseUp: selectStart,
                    onChange: (e) => {
                        setInputText(e.target.value);
                        if (isValidDateText(e.target.value)) {
                            input.onBlur(moment(e.target.value, 'hh:mm a').format('HH:mm:ss'));
                        } else if (input.value) {
                            input.onBlur(null);
                        }
                    },
                    value: inputText,
                    inputComponent: disabled ? undefined : TextMaskCustom,
                    inputProps: {
                        'aria-label': label,
                        ...inputProps,
                        ...ariaInputProps,
                    },
                }}
                KeyboardButtonProps={{
                    'aria-label': `Select time for ${label}`,
                }}
                {...input}
                value={value}
                onChange={(moment, inputText) => {
                    if (isValidDateText(inputText)) {
                        const propagated = propagateMoment(moment);
                        if (!propagated) {
                            setInputText(inputText);
                        }
                    }
                }}
                onBlur={(e) => {}}
                margin="none"
                label={renderLabel && label}
                error={!!(touched && error)}
                helperText={
                    touched && error
                        ? `${translate({ id: 'validate.error' })}: ${evaluateFormattedMessage(formatError(error))}`
                        : undefined
                }
                disabled={disabled}
                FormHelperTextProps={getFormHelperTextProps(inputProps)}
                {...options}
            />
            {!disabled && input.value && input.value.length > 0 && (
                <IconButton
                    aria-label={`Clear time for ${label}`}
                    style={
                        !fieldVariant || fieldVariant === 'standard'
                            ? {
                                  position: 'absolute',
                                  right: 30,
                                  top: renderLabel ? 8 : -8,
                              }
                            : {
                                  position: 'absolute',
                                  right: 50,
                                  top: 4,
                              }
                    }
                    onClick={clearInput}
                >
                    <Clear />
                </IconButton>
            )}
        </div>
    );
};

const TimeInput: React.SFC<TimePickerProps> = compose(hideIfNoInput)(TimePicker);

export default TimeInput;
