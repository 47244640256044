import { FormFieldUnion, FormFieldConfigs } from 'fieldFactory/translation/fromFlowable/types/index';
import { AvailableOptionsExpressions } from 'expressions/expressionArrays/formValuesInDynamicContext';
import { RootState } from 'reducers/rootReducer';

import { IAddressWidgetConfig } from 'fieldFactory/input/components/Address/types';
import stableStringify from 'fast-json-stable-stringify';
import { fromNullable } from 'fp-ts/lib/Option';

/*
    shared code used in form-contexts
*/

export const getFormValues = <Props extends { formId?: string }>(state: RootState, props: Props) =>
    fromNullable(state.form![props.formId ?? 'current-task-form'])
        .mapNullable((tf) => tf.values)
        .getOrElse(undefined);

export const getLiveFormInitial = (state: RootState) =>
    fromNullable(state.form!['current-task-form'])
        .mapNullable((tf) => tf.initial)
        .getOrElse(undefined);

export const getExpressionsFromFields = (expType: keyof FormFieldConfigs) => (fields: FormFieldUnion[]) =>
    Object.assign(
        {},
        ...(fields || []).map((field) => {
            const exp = field && field.params && field.params.configs && field.params.configs[expType];
            return exp ? { [field.id]: exp } : [];
        }),
    );

export const getConceptsAvailableExpressionsFromFields = (
    fields: FormFieldUnion[],
): {
    [source: string]: string;
} =>
    Object.assign(
        {},
        ...(fields || []).map((field) => {
            if (
                (field.type === 'value-set-dropdown' ||
                    field.type === 'value-set-multi-select' ||
                    field.type === 'value-set-multi-checkbox' ||
                    field.type === 'value-set-radiobox' ||
                    field.type === 'valueset-suggest') &&
                field.params.availableConcepts
            ) {
                const fieldSansId = field.id.endsWith('Id')
                    ? field.id.slice(0, -2)
                    : field.id.endsWith('Ids')
                    ? field.id.slice(0, -3)
                    : field.id;
                return { [fieldSansId]: field.params.availableConcepts };
            }
            return {};
        }),
    );

export const getOptionAvailabilityExpressionsFromFields = (fields: FormFieldUnion[]): AvailableOptionsExpressions => {
    const options: AvailableOptionsExpressions[] = (fields || []).flatMap((f): AvailableOptionsExpressions[] => {
        if (f.type === 'dropdown' || f.type === 'radio-buttons') {
            const { options: ddOptions } = f;
            if (ddOptions) {
                const optionsObj: AvailableOptionsExpressions[0] = {
                    emptyValue: f.hasEmptyValue ? ddOptions[0] : null,
                    optionVisibilities: Object.assign(
                        {},
                        ...ddOptions.map((o): AvailableOptionsExpressions[0]['optionVisibilities'] => {
                            return {
                                [stableStringify(o)]: (o.configs && o.configs.visibility) || 'ALWAYS_VISIBLE',
                            };
                        }),
                    ),
                };
                return [{ [f.id]: optionsObj }];
            }
        }
        return [];
    });
    return Object.assign({}, ...options);
};

export const fieldsToForceInsertion = (fields?: FormFieldUnion[]): string[] =>
    (fields || []).flatMap((f) => {
        if (f.type === 'address') {
            const addrConf = f.params && f.params.address;
            if (addrConf) {
                const configStr = addrConf.configs;
                if (configStr) {
                    try {
                        const parsedConfig: IAddressWidgetConfig = JSON.parse(configStr);
                        return Object.values(parsedConfig.fieldMapping);
                    } catch (e) {
                        console.error(e);
                        return [];
                    }
                }
            }
        }
        return [];
    });

export const getInjectedVisibilityTestExpressions = (
    state: RootState,
): null | undefined | { [fieldId: string]: string } => state && state.expressionEval && state.expressionEval.configTask;

export const wrapEachValueInArray = (obj: {}) =>
    Object.assign({}, ...Object.entries(obj).map(([key, exp]) => ({ [key]: [exp] })));

export const getValueset1Fields = (fields: FormFieldUnion[]) =>
    Object.assign(
        {},
        ...fields.flatMap((f) => {
            const adjustedFieldId = f.id.endsWith('Id') ? f.id.slice(0, -2) : f.id;
            return f.type === 'value-set-dropdown' ? [{ [adjustedFieldId]: f.params.valueSet }] : [];
        }),
    );
