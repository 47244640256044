import { Target } from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/Entry';

/**
 *
 * @param from id to replace
 * @param to id to replace 'from' with
 * @returns a new object, if the id was found and replaced; otherwise, null.
 */
const replaceId = (from: string, to: string) => {
    let replaced = false;
    const innerRecurse = (record: Target, markNextPending = false): Target => {
        if (record.tag === 'link') {
            if (record.recordType.tag === 'pending_creation') {
                if (record.recordType.substituteId === from) {
                    replaced = true;
                    return {
                        ...record,
                        recordType: {
                            tag: 'exists',
                            id: to,
                        },
                        next: innerRecurse(record.next, true),
                    };
                }
            }
            if (record.recordType.tag === 'exists') {
                return {
                    ...record,
                    next: innerRecurse(record.next),
                };
            }
            if (record.recordType.tag === 'pending_prev_create') {
                if (markNextPending) {
                    return {
                        ...record,
                        recordType: {
                            tag: 'pending_creation',
                            substituteId: record.recordType.substituteId,
                        },
                        next: innerRecurse(record.next),
                    };
                }
            }
        }
        return record;
    };
    return (record: Target) => {
        const result = innerRecurse(record);
        if (replaced) {
            return result;
        }
        return null;
    };
};
export default replaceId;
