import { loadAllDashboards, loadAllDashboardsSuccess, loadAllDashboardFailure } from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Dashboard } from '../types';

const loadAllDashboardsFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(loadAllDashboards)),
        flatMap((action) =>
            services.loadAllDashboardConfigs().pipe(
                map((response): Dashboard[] => {
                    return response.map((dashboardEntity) => {
                        try {
                            const config: Dashboard = JSON.parse(dashboardEntity.config);
                            config.id = dashboardEntity.id;
                            config.displayName = dashboardEntity.displayName || config.dashboardConfig.name;
                            return config;
                        } catch (e) {
                            console.error(`Bad dashboard configuration for dashboard:
                            ${dashboardEntity.displayName || dashboardEntity.name || dashboardEntity.id}
                            error logged below`);
                            console.error(e);
                            // Rethrow after logging.
                            throw e;
                        }
                    });
                }),
                map((response) => loadAllDashboardsSuccess(response)),
                catchError((err) => {
                    console.error(err);
                    return of(loadAllDashboardFailure(err));
                }),
            ),
        ),
    );
export default loadAllDashboardsFlow;
