import { createCustomAction } from 'typesafe-actions';
import { SET_LOCALE } from './constants';
import { Locale } from './domain';

export const setLocale = createCustomAction(SET_LOCALE, (type) => {
    return (locale: Locale) => ({
        type,
        payload: {
            locale,
        },
    });
});
