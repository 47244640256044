import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TextTemplatePage from 'templatePage/components';
import { getOverrideHomepageSelector } from 'util/applicationConfig';

const useOverrideHomepageComponent = () => {
    const override = useSelector(getOverrideHomepageSelector);
    return useMemo(() => {
        if (!override) {
            return undefined;
        }
        return () => <TextTemplatePage textTemplateName={override.textTemplateName} />;
    }, [override]);
};

export default useOverrideHomepageComponent;
