export type ValidationLevel = 'error' | 'warn';

export interface EntityValidations {
    [resource: string]: {
        level?: ValidationLevel;
        expression: string;
        message: string;
        dataPaths: string[];
        expansionsRequired: string[];
        valuesetLiterals: string[];
        valuesetFieldsRequired: {
            [field: string]: string; // can get the valueset
        };
        // Lets not store the compiled expressions...
        // could be a lot of wasted space in memory, and they aren't serializable anyway)
        // compiledExpression: SpelNode
    }[];
}
const entityValidationsReducer = (previousState: EntityValidations = {}, { type, payload }): EntityValidations => {
    if (type === 'ENTITY_VALIDATIONS_GENERATED') {
        return payload;
    }
    return previousState;
};
export default entityValidationsReducer;
