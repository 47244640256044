import { processTasksEventType as tasksForProcessEvent } from '../../actions/tasksForProcessEvent';
import { taskEventType as taskEvent } from '../../actions/taskEvent';
import ProcessInstance from '../../types/processInstance';
import { getType } from 'typesafe-actions';
import { getProcessInstanceSuccess } from 'bpm/processInstance/actions';
import { startProcessSuccess } from 'bpm/create-process-instance/actions';

export type ProcessInstanceState = Partial<ProcessInstance> & {
    tasks: (number | string)[] | null;
    openTasks: (number | string)[] | null;
    completedTasks: (number | string)[] | null;
    potentialUsers:
        | {
              id: string;
              login: string;
              title: string;
              canClaimTask: boolean;
              canAssignTask: boolean;
          }[]
        | null;
};

export default (
    state: ProcessInstanceState = {
        tasks: null,
        openTasks: null,
        completedTasks: null,
        potentialUsers: null,
    },
    action,
): ProcessInstanceState => {
    switch (action.type) {
        case getType(startProcessSuccess):
            return {
                tasks: state.tasks,
                openTasks: state.openTasks,
                completedTasks: state.completedTasks,

                // keep variables if unspecified
                variables: state.variables,
                ...action.payload.response,
                potentialUsers: state.potentialUsers,
            };
        case getType(getProcessInstanceSuccess): {
            return {
                tasks: state.tasks,
                openTasks: state.openTasks,
                completedTasks: state.completedTasks,

                // keep variables if unspecified
                variables: state.variables,
                ...action.response,
                potentialUsers: state.potentialUsers,
            };
        }
        case tasksForProcessEvent.getAllSuccess: {
            return {
                ...state,
                tasks: action.payload.data.map((task) => task.id),
            };
        }
        case tasksForProcessEvent.getOpenSuccess: {
            return {
                ...state,
                openTasks: action.payload.data.map((task) => task.id),
            };
        }
        case tasksForProcessEvent.getCompletedSuccess: {
            return {
                ...state,
                completedTasks: action.payload.data.map((task) => task.id),
            };
        }
        case taskEvent.updateSuccess:
        case taskEvent.getSuccess: {
            const isCompleted = !!action.payload.endDate; // truthy to boolean conversion

            // A task can be complete, or incomplete, not both.
            // Filter to prevent duplicate entry.
            const otherCompletedTasks = state.completedTasks
                ? state.completedTasks.filter((tid) => tid !== action.payload.id)
                : [];
            const otherOpenTasks = state.openTasks ? state.openTasks.filter((tid) => tid !== action.payload.id) : [];
            const otherTasks = state.tasks ? state.tasks.filter((tid) => tid !== action.payload.id) : [];
            return {
                ...state,
                // below, keep the falsy value for the other list.
                // An empty array should indicate "an entry of length 0". falsy means "unfetched"

                completedTasks: isCompleted
                    ? [...otherCompletedTasks, action.payload.id]
                    : state.completedTasks && otherCompletedTasks,

                openTasks: isCompleted ? state.openTasks && otherOpenTasks : [...otherOpenTasks, action.payload.id],

                tasks: [...otherTasks, action.payload.id],
            };
        }
        default:
            return state;
    }
};
