import React, { useMemo, FunctionComponent, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import EditableViewFormLayout from 'layout-editor/components/EditableViewFormLayout';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { Button, CardActions } from '@material-ui/core';
import formTypeContext from 'components/generics/form/formTypeContext';
import { FormContextProvider as ShowFormContextProvider } from 'components/generics/form/EntityFormContext/Show';
import { EntityFormContextProvider } from 'components/generics/form/EntityFormContext';
import { View } from 'reducers/ViewConfigType';
import { reduxForm } from 'redux-form';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Alert from '@material-ui/lab/Alert';
import ControlledJSONEditor from 'expression-tester/JsonEditorReact';

const Form = reduxForm({})((props) => <React.Fragment>{props.children}</React.Fragment>);

const record = {};
interface Step2Props {
    initialValues: Partial<View>;
    onSubmit: (
        action: {
            type: 'replace' | 'write';
            payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
        },
        navigate?: boolean,
    ) => void;
}
const Step2CreateEditShow: FunctionComponent<Step2Props> = (props) => {
    const { initialValues, onSubmit: _onSubmit } = props;
    const methods = useForm({
        defaultValues: initialValues,
    });
    const { handleSubmit, register, unregister, watch, setValue, reset } = methods;
    const view = watch() as View;
    React.useEffect(() => {
        register({ name: 'tabs' });
        register({ name: 'fields' });
        return () => {
            unregister('tabs');
            unregister('fields');
        };
    }, []); // eslint-disable-line

    const onSubmit = (data) => {
        _onSubmit({ type: 'write', payload: data });
    };
    const viewConfig = useViewConfig();
    const overrideViewConfig = useMemo(() => {
        return produce(viewConfig, (draft) => {
            draft.views[initialValues.name] = {
                ...initialValues,
                ...view,
            };
            return draft;
        });
    }, [viewConfig, view, initialValues]);
    if (!initialValues.viewType || !initialValues.entity || !initialValues.name) {
        return <div>Please fill out step 1.</div>;
    }
    if (
        initialValues.viewType !== 'CREATE' &&
        initialValues.viewType !== 'SHOW' &&
        initialValues.viewType !== 'EDIT' &&
        initialValues.viewType !== 'COMPONENT'
    ) {
        return <div>Wrong View Type {initialValues.viewType}</div>;
    }
    const inner = overrideViewConfig.views[initialValues.name] ? (
        <EditableViewFormLayout
            isCreate={initialValues.viewType === 'CREATE'}
            hasTabs={initialValues.viewType !== 'CREATE' && initialValues.viewType !== 'COMPONENT'}
            mode={initialValues.viewType === 'SHOW' ? 'SHOW' : 'EDIT'}
            entityType={initialValues.entity}
            view={overrideViewConfig.views[initialValues.name]}
            onViewChange={({ view }) => {
                setValue('tabs', view.tabs, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                setValue('fields', view.fields, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                // below isn't good - reordered object keys (tabs) aren't detected
                // if (deepEql(view, initialValues)) {
                //     reset();
                // }
            }}
        />
    ) : null;
    return (
        <div style={{ padding: '1em', margin: '1em' }}>
            <h2>Step 2: Add Page Elements</h2>
            <FormProvider {...methods}>
                <div>
                    <NavWarning />
                    <formTypeContext.Provider
                        value={initialValues.viewType === 'COMPONENT' ? 'EDIT' : initialValues.viewType}
                    >
                        <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                            {initialValues.viewType === 'SHOW' ? (
                                <ShowFormContextProvider
                                    overrides={{
                                        visibilityExps: {},
                                    }}
                                    overrideViewConfig={overrideViewConfig}
                                    viewName={initialValues.name}
                                    record={record as any}
                                >
                                    {inner}
                                </ShowFormContextProvider>
                            ) : initialValues.viewType === 'EDIT' ||
                              initialValues.viewType === 'CREATE' ||
                              initialValues.viewType === 'COMPONENT' ? (
                                <EntityFormContextProvider
                                    overrides={{
                                        visibilityExps: {},
                                    }}
                                    overrideViewConfig={overrideViewConfig}
                                    viewName={initialValues.name}
                                    record={record as any}
                                >
                                    <Form form="builder_form">{inner}</Form>
                                </EntityFormContextProvider>
                            ) : null}
                        </OverriddenViewConfigContext.Provider>
                    </formTypeContext.Provider>
                    <div style={{ marginTop: '1em', paddingTop: '1em', textAlign: 'right' }}>
                        <Button id="step2-submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                            Submit&nbsp;
                            <NavigateNext />
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </div>
    );
};

const WithState = <T extends any>({
    initial,
    children,
    onSubmit,
}: {
    initial: T;
    onSubmit: (data: T) => void;
    children: (props: { state: T; setState: (state: T) => void }) => JSX.Element;
}) => {
    const [state, setState] = useState(initial);
    return (
        <>
            {children({ state, setState })}
            <CardActions>
                <Button variant="contained" color="primary" onClick={() => onSubmit(state)}>
                    Submit
                </Button>
            </CardActions>
        </>
    );
};

interface ErrorBoundaryState {
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}
class Step2CreateEditShowWithErrorBoundary extends React.Component<Step2Props, ErrorBoundaryState> {
    constructor(props: Step2Props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({
            error,
            errorInfo,
        });
    }
    reset = () => {
        this.setState({ error: null, errorInfo: null });
    };
    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <Alert severity="error">
                        An error occurred, possibly because of model changes leaving dangling fields. Edit the form JSON
                        below, to see if it is recoverable.
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error?.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </Alert>
                    <WithState
                        initial={this.props.initialValues}
                        onSubmit={(json) => {
                            this.props.onSubmit({ type: 'write', payload: json as any }, false);
                            setImmediate(() => {
                                this.reset();
                            });
                        }}
                    >
                        {({ state, setState }) => <ControlledJSONEditor json={state} onChangeJSON={setState} a />}
                    </WithState>
                </div>
            );
        }
        return <Step2CreateEditShow {...this.props} />;
    }
}

export default Step2CreateEditShowWithErrorBoundary;
