import useExpressionTesterOpen, { ExpressionTesterOpen } from 'expression-tester/hooks/useExpressionTesterOpen';
import {
    EditViewInPlaceContext,
    editViewInPlaceContext,
} from 'layout-editor/editViewInPlaceContext/editViewInPlaceContext';
import React, { useCallback, useMemo, useState } from 'react';

const useEditViewInPlace = () => {
    const globallyOpen = useExpressionTesterOpen();

    const [manuallyOpen, setOpen] = useState<ExpressionTesterOpen>('CLOSED');

    const open = globallyOpen !== 'CLOSED' ? globallyOpen : manuallyOpen;

    const editViewInPlaceContextValue: EditViewInPlaceContext = useMemo(() => {
        return {
            editing: open !== 'CLOSED',
            set: (value) => setOpen(value ? 'OPEN_ALL' : 'CLOSED'),
        };
    }, [open, setOpen]);

    const Provider = useCallback(
        (props) => (
            <editViewInPlaceContext.Provider value={editViewInPlaceContextValue}>
                {props.children}
            </editViewInPlaceContext.Provider>
        ),
        [editViewInPlaceContextValue],
    );

    return {
        Provider,
        open,
        setOpen,
        manuallyOpen,
    };
};
export default useEditViewInPlace;
