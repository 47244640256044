import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { markTaskForOffline, setOfflineTasks, unMarkTaskForOffline } from './actions';

export type OfflineTasks = null | {
    [taskId: string]: true;
};
// treat null as 'not asynchronously loaded yet from idb'
// when it loads it will at least be an empty object.
const offlineTasksReducer = (state: OfflineTasks = null, action: RootAction): OfflineTasks => {
    switch (action.type) {
        case getType(markTaskForOffline): {
            return {
                ...state,
                [action.payload.taskId]: true,
            };
        }
        case getType(unMarkTaskForOffline): {
            return Object.fromEntries(Object.entries(state ?? {}).filter(([key]) => key !== action.payload.taskId));
        }
        case getType(setOfflineTasks): {
            return action.payload;
        }
        default:
            return state;
    }
};

export default offlineTasksReducer;
