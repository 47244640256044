import ViewConfig from 'reducers/ViewConfigType';
import { fromEither, Option, fromNullable } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { parsingOrValidationErrMsg } from 'expressions/formValidation';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import removeMethodHashes from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import { getDataTypeForFieldExpr, getFieldSourceFromPath } from 'components/generics/utils/viewConfigUtils';
import maybeLogError from 'viewConfigCalculations/util/logErrorIfPermissioned';
import getImpl from 'expressions/Provider/implementations/getImpl';

const getActionButtonDisplays = (
    key: string,
    resource: string,
    viewConfig: ViewConfig,
    viewConfiguration: Option<string>,
) =>
    viewConfiguration
        .map(parseConfig)
        .map((e) => e.map((c) => c.entityActions))
        // prints errors for the failed configs
        .map((e) =>
            e.mapLeft((error) => {
                maybeLogError(viewConfig)(parsingOrValidationErrMsg(error));
                return error;
            }),
        )
        .chain(fromEither)
        .chain(fromNullable)
        .map((actionButtons) => {
            // Either empty object or array object
            return mapOption(
                actionButtons
                    .filter((buttonConfig) => !!buttonConfig.displayRule)
                    .map((buttonConfig) => [buttonConfig.key, removeMethodHashes(buttonConfig.displayRule)]),
                ([buttonKey, expression]) =>
                    fromEither(
                        tryCatch(
                            () => {
                                const impl = getImpl();
                                const compiled = impl.compileExpression(expression);
                                if (compiled.type === 'parse_failure') {
                                    throw new Error(compiled.msg);
                                }
                                const expansionsRequired = compiled
                                    .getExpansionsWithAll()
                                    // Don't expand past indexes, like foo['bar']
                                    .map((df) => (df.includes('._ALL_') ? df.slice(0, df.indexOf('._ALL_')) : df));

                                const dataPaths = compiled.getPathsWithAll();
                                const valuesetLiterals = compiled.getValueSetLiterals();
                                return {
                                    expression,
                                    buttonKey,
                                    expansionsRequired,
                                    dataPaths,
                                    valuesetLiterals,
                                };
                            },
                            (e: Error) => {
                                // prints errors for the failed SPEL compilation
                                maybeLogError(viewConfig)('Error parsing SPEL entityConfig validation expression', e);
                                return e;
                            },
                        ).chain((d) =>
                            tryCatch(
                                () => {
                                    d.expansionsRequired
                                        .flatMap((df) => (df.startsWith('record.') ? [df.slice('record.'.length)] : []))
                                        .forEach((df) => {
                                            const source = getFieldSourceFromPath(viewConfig, resource, df);
                                            getDataTypeForFieldExpr(viewConfig, resource, source, 'TRAVERSE_PATH');
                                        });
                                    return d;
                                },
                                (e: Error) => {
                                    maybeLogError(viewConfig)(
                                        `the expression "${d.expression}" for button "${d.buttonKey}" contains unknown data fields. This could be due to not having the appropriate permissions. The button will be removed. Error below:`,
                                    );
                                    maybeLogError(viewConfig)(e);
                                    return e;
                                },
                            ),
                        ), // .chain(setupValuesetFieldsRequired(viewConfig, resource, expression))
                    ),
            );
        })
        .map(
            (v) =>
                ({
                    [key]: Object.assign({}, ...v.map((c) => ({ [c.buttonKey]: c }))),
                } as {
                    [key: string]: (typeof v)[0];
                }),
        );

export default getActionButtonDisplays;
