import { Action } from 'redux';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { fetchJson } from '../util/fetchUtils';
import { FETCH_START, FETCH_END, FETCH_ERROR } from 'actions/aor/types';
import { RootState } from '../reducers/rootReducer';
import { API_URL } from '../config';
import { fromNullable } from 'fp-ts/lib/Option';
import { VALUE_SET_GROUP_NOT_RECEIVED } from '../actions/constants';
import buildFetchOptions from './util/buildFetchOptions';
import { getType } from 'typesafe-actions';
import * as valueSetActions from 'valueSets/actions';

type ActionType = {
    payload: {
        valueSetCode: string;
        group: string;
    };
} & Action;
export function* loadValueSetGroup(action: ActionType) {
    const { valueSetCode, group } = action.payload;
    const fetchValueSetGroup = yield select((state: RootState): boolean =>
        fromNullable(state.valueSets)
            .chain((valueSets) => fromNullable(valueSets[valueSetCode]))
            .fold(
                true, // if the valueset isn't found, lets fetch
                (
                    valueSet, // if it is, and invalid is false, fetch depending on if group is not found or invalid
                ) => valueSet.invalid || fromNullable(valueSet.groups[group]).fold(true, (vsgroup) => vsgroup.invalid),
            ),
    );
    if (!fetchValueSetGroup) {
        // don't fetch
        // console.log(`cache is valid so don't load group ${group} for valueSet ${valueSetCode}`);
        return;
    } else {
        // console.log(`cache is invalid: loading group ${group} for valueSet ${valueSetCode}`);
    }
    yield put({ type: FETCH_START });
    try {
        const options = buildFetchOptions();
        const { status, body } = yield call(fetchJson, `${API_URL}value-set/${valueSetCode}/${group}`, options);
        if (status === 200) {
            const response = JSON.parse(body);
            yield put(valueSetActions.valuesetGroupReceived(response, action.payload));
            yield put({ type: FETCH_END });
        } else {
            yield put({ type: VALUE_SET_GROUP_NOT_RECEIVED, status });
            yield put({ type: FETCH_ERROR });
        }
    } catch (error) {
        yield put({ type: VALUE_SET_GROUP_NOT_RECEIVED, error });
        yield put({ type: FETCH_ERROR, error });
    }
}

export default function* getViewConfigOnLoginSaga() {
    yield takeEvery(getType(valueSetActions.loadValueSetGroup), function* (action: ActionType) {
        yield call(loadValueSetGroup, action);
    });
}
