const PREFIX = '__BACKREF_';
const DELIM = '__';

const DOT_ESCAPE = '_dot_';
const escapePath = (path: string) => {
    return path.split('.').join(DOT_ESCAPE);
};
const unescapePath = (escapedPath: string) => {
    return escapedPath.split(DOT_ESCAPE).join('.');
};

export const encodeMetadataKeyVal = (path: string, entityType: string) => {
    const key = `${PREFIX}${escapePath(path)}${DELIM}${entityType}`;
    return key;
};

const parseKey = (key: string) => {
    if (key.startsWith(PREFIX)) {
        const [path, entityType] = key.slice(PREFIX.length).split(DELIM);
        if (path && entityType) {
            return [unescapePath(path), entityType];
        }
    }
    return null;
};

export const maybeDecodeMetadataKeyVal = ([key, value]: [string, string]): {
    path: string;
    entityType: string;
    id: string;
} => {
    const t = parseKey(key);
    if (t) {
        const [path, entityType] = t;
        return {
            path,
            entityType,
            id: value,
        };
    }
    return null;
};
