import React, { useMemo } from 'react';
import { getRenderAtRowEnd } from 'components/generics/genericList';
import { RenderListArguments } from 'components/generics/genericList/List';
import { GenericShow } from 'components/generics';
import isEmbeddedViewContext from './embeddedViewContext';
import { IconButton } from '@material-ui/core';
import useViewConfig from 'util/hooks/useViewConfig';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import Edit from '@material-ui/icons/Edit';
import { allowsDelete, allowsEdit, getAccessLevelForEntity } from 'components/generics/utils/viewConfigUtils';
import Delete from '@material-ui/icons/Delete';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
// import Add from '@material-ui/icons/Add';
// import Search from '@material-ui/icons/Search';

export interface GetRenderMultiCardListConfig {
    viewName?: string;
    showViewName?: string;
    editViewName?: string;
    createViewName?: string;
    hasCreate?: boolean;
    hasEdit?: boolean;
    label?: string;
    handleDelete?: (record?: { id: string }) => void;
}
/**
 * TODOs
 * - Add 'Create' button.
 *
 */

type DefaultViewNames = { edit: string; show: string; create: string };
const DefaultViews = ({
    resource,
    children,
}: {
    resource: string;
    children: (args: DefaultViewNames) => JSX.Element;
}) => {
    const viewConfig = useViewConfig();
    const defaultViewNames: DefaultViewNames = useMemo(() => {
        return Object.fromEntries(
            (['EDIT', 'CREATE', 'SHOW'] as const).map(
                (k) =>
                    [k.toLowerCase(), viewConfig.entities[resource]?.defaultViews?.[k]?.name] as [
                        'create' | 'edit' | 'show',
                        string,
                    ],
            ),
        ) as DefaultViewNames;
    }, [resource, viewConfig]);
    return children(defaultViewNames);
};

const Permissions = ({
    children,
    resource,
}: {
    resource: string;
    children: (args: { accessLevel: number; printMode?: boolean }) => JSX.Element;
}) => {
    const viewConfig = useViewConfig();
    const printMode = useSelector((state: RootState) => state.printMode);
    return children({
        printMode,
        accessLevel: getAccessLevelForEntity(viewConfig, resource),
    });
};
const getRenderMultiCardList =
    (config: GetRenderMultiCardListConfig) =>
    (props: RenderListArguments & { renderAtRowEnd?: ReturnType<typeof getRenderAtRowEnd> }) => {
        return (
            <div>
                <div style={{ marginTop: '-.5em', marginBottom: '-.5em' }}>
                    {props.ids.map((id) => (
                        <div key={id} style={{ marginTop: '.5em', marginBottom: '.5em' }}>
                            <isEmbeddedViewContext.Provider value={true}>
                                <DefaultViews resource={props.resource}>
                                    {({ edit, show, create }) => (
                                        <EntityInspect
                                            reference={props.resource}
                                            openTo={config.showViewName ? 'show' : 'edit'}
                                            formId={
                                                'multicard:' +
                                                id +
                                                '-' +
                                                props.resource +
                                                ':' +
                                                (config.editViewName ?? edit)
                                            }
                                            editViewName={config.editViewName ?? edit}
                                            showViewName={config.showViewName ?? -1}
                                            renderComponent={(args) => (
                                                <GenericShow
                                                    embeddedInFormId={props.embeddedInFormId}
                                                    renderTitleElement={({ titleElem }) => (
                                                        <div
                                                            style={{ display: 'flex', justifyContent: 'space-between' }}
                                                        >
                                                            <div>
                                                                <h3>{titleElem}</h3>
                                                            </div>
                                                            <div>
                                                                {/* add access control here too */}
                                                                {
                                                                    <Permissions resource={props.resource}>
                                                                        {({ accessLevel, printMode }) =>
                                                                            !printMode && (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    {config.showViewName ? (
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            onClick={() =>
                                                                                                args.selectId(id)
                                                                                            }
                                                                                            aria-label={
                                                                                                'Inspect record ' +
                                                                                                    props.data[id]
                                                                                                        ?.title ?? ''
                                                                                            }
                                                                                        >
                                                                                            <RemoveRedEye />
                                                                                        </IconButton>
                                                                                    ) : config.hasEdit !== false &&
                                                                                      allowsEdit(accessLevel) ? (
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            onClick={() =>
                                                                                                args.selectId(id)
                                                                                            }
                                                                                            aria-label={
                                                                                                'Edit record ' +
                                                                                                    props.data[id]
                                                                                                        ?.title ?? ''
                                                                                            }
                                                                                        >
                                                                                            <Edit />
                                                                                        </IconButton>
                                                                                    ) : null}
                                                                                    {allowsDelete(accessLevel) &&
                                                                                    config.handleDelete ? (
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                config.handleDelete(
                                                                                                    props.data[id],
                                                                                                )
                                                                                            }
                                                                                            aria-label={
                                                                                                'Delete record ' +
                                                                                                    props.data[id]
                                                                                                        ?.title ?? ''
                                                                                            }
                                                                                        >
                                                                                            <Delete color="error" />
                                                                                        </IconButton>
                                                                                    ) : null}
                                                                                    {props.renderAtRowEnd?.(
                                                                                        props,
                                                                                        props.data[id],
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Permissions>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                    viewName={config.viewName ?? show}
                                                    createMobileAppBar={false}
                                                    resource={props.resource}
                                                    match={{
                                                        params: {
                                                            id,
                                                        },
                                                    }}
                                                    actions={<div />}
                                                    toolbar={null}
                                                />
                                            )}
                                        />
                                    )}
                                </DefaultViews>
                            </isEmbeddedViewContext.Provider>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
export default getRenderMultiCardList;
