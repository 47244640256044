/* 000 to 999 */
const zipByState = [
    '--',
    '--',
    '--',
    '--',
    '--',
    'NY',
    'PR',
    'PR',
    'VI',
    'PR',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'MA',
    'RI',
    'RI',
    'NH',
    'NH',
    'NH',
    'NH',
    'NH',
    'NH',
    'NH',
    'NH',
    'NH',
    'ME',
    'ME',
    'ME',
    'ME',
    'ME',
    'ME',
    'ME',
    'ME',
    'ME',
    'ME',
    'ME',
    'VT',
    'VT',
    'VT',
    'VT',
    'VT',
    'MA',
    'VT',
    'VT',
    'VT',
    'VT',
    'CT',
    'CT',
    'CT',
    'CT',
    'CT',
    'CT',
    'CT',
    'CT',
    'CT',
    'CT',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'NJ',
    'AE',
    'AE',
    'AE',
    'AE',
    'AE',
    'AE',
    'AE',
    'AE',
    'AE',
    '--',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'NY',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    'PA',
    '--',
    'PA',
    'PA',
    'PA',
    'PA',
    'DE',
    'DE',
    'DE',
    'DC',
    'VA',
    'DC',
    'DC',
    'DC',
    'DC',
    'MD',
    'MD',
    'MD',
    'MD',
    'MD',
    'MD',
    'MD',
    '--',
    'MD',
    'MD',
    'MD',
    'MD',
    'MD',
    'MD',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'VA',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    'WV',
    '--',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'NC',
    'SC',
    'SC',
    'SC',
    'SC',
    'SC',
    'SC',
    'SC',
    'SC',
    'SC',
    'SC',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'GA',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'FL',
    'AA',
    'FL',
    'FL',
    '--',
    'FL',
    '--',
    'FL',
    'FL',
    '--',
    'FL',
    'AL',
    'AL',
    'AL',
    '--',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'TN',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'MS',
    'GA',
    '--',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    '--',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    'KY',
    '--',
    '--',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    'OH',
    '--',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'IN',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'MI',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    '--',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    '--',
    '--',
    '--',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    'IA',
    '--',
    'WI',
    'WI',
    'WI',
    '--',
    'WI',
    'WI',
    '--',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'WI',
    'MN',
    'MN',
    '--',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    'MN',
    '--',
    'DC',
    'SD',
    'SD',
    'SD',
    'SD',
    'SD',
    'SD',
    'SD',
    'SD',
    '--',
    '--',
    'ND',
    'ND',
    'ND',
    'ND',
    'ND',
    'ND',
    'ND',
    'ND',
    'ND',
    '--',
    'MT',
    'MT',
    'MT',
    'MT',
    'MT',
    'MT',
    'MT',
    'MT',
    'MT',
    'MT',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    '--',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'IL',
    'MO',
    'MO',
    '--',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    '--',
    '--',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    '--',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    'MO',
    '--',
    'KS',
    'KS',
    'KS',
    '--',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'KS',
    'NE',
    'NE',
    '--',
    'NE',
    'NE',
    'NE',
    'NE',
    'NE',
    'NE',
    'NE',
    'NE',
    'NE',
    'NE',
    'NE',
    '--',
    '--',
    '--',
    '--',
    '--',
    '--',
    'LA',
    'LA',
    '--',
    'LA',
    'LA',
    'LA',
    'LA',
    'LA',
    'LA',
    '--',
    'LA',
    'LA',
    'LA',
    'LA',
    'LA',
    '--',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'AR',
    'OK',
    'OK',
    '--',
    'TX',
    'OK',
    'OK',
    'OK',
    'OK',
    'OK',
    'OK',
    'OK',
    'OK',
    '--',
    'OK',
    'OK',
    'OK',
    'OK',
    'OK',
    'OK',
    'OK',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'TX',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    'CO',
    '--',
    '--',
    '--',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'WY',
    'ID',
    'ID',
    'ID',
    'ID',
    'ID',
    'ID',
    'ID',
    '--',
    'UT',
    'UT',
    '--',
    'UT',
    'UT',
    'UT',
    'UT',
    'UT',
    '--',
    '--',
    'AZ',
    'AZ',
    'AZ',
    'AZ',
    '--',
    'AZ',
    'AZ',
    'AZ',
    '--',
    'AZ',
    'AZ',
    '--',
    '--',
    'AZ',
    'AZ',
    'AZ',
    '--',
    '--',
    '--',
    '--',
    'NM',
    'NM',
    '--',
    'NM',
    'NM',
    'NM',
    '--',
    'NM',
    'NM',
    'NM',
    'NM',
    'NM',
    'NM',
    'NM',
    'NM',
    'NM',
    '--',
    '--',
    '--',
    '--',
    'NV',
    'NV',
    '--',
    'NV',
    'NV',
    'NV',
    '--',
    'NV',
    'NV',
    '--',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    '--',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    '--',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'CA',
    'AP',
    'AP',
    'AP',
    'AP',
    'AP',
    'HI',
    'HI',
    'GU',
    'OR',
    'OR',
    'OR',
    'OR',
    'OR',
    'OR',
    'OR',
    'OR',
    'OR',
    'OR',
    'WA',
    'WA',
    'WA',
    'WA',
    'WA',
    'WA',
    'WA',
    '--',
    'WA',
    'WA',
    'WA',
    'WA',
    'WA',
    'WA',
    'WA',
    'AK',
    'AK',
    'AK',
    'AK',
    'AK',
];

export function simpleZipToState(zipCode: string) {
    const ix = parseInt(zipCode.slice(0, 3));
    return zipByState[ix];
}
export default function zipToState(zipCode: string) {
    const multiStateZipCodes = {
        '59221': ['MT', 'ND'],
        '73949': ['OK', 'TX'],
        '86515': ['AZ', 'NM'],
        '42223': ['KY', 'TN'],
        '86044': ['AZ', 'UT'],
        '84536': ['AZ', 'UT'],
        '97635': ['OR', 'CA'],
        '02861': ['MA', 'RI'],
        '71749': ['AR', 'LA'],
        '81137': ['CO', 'NM'],
        '63673': ['IL', 'MO'],
        '88063': ['NM', 'TX'],
        '89439': ['NV', 'CA'],
    };
    const multi = multiStateZipCodes[zipCode];
    return multi || [simpleZipToState(zipCode)];
}
