import React, { useCallback, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import FunctionTypeDoc from './FunctionDoc';
import { functionNames } from './FunctionDoc/docs';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: 400,
            maxWidth: 300,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);

export default function FunctionsSearch() {
    const classes = useStyles();
    const [searchText, setSearchText] = React.useState('');
    const [selectedFn, setSelectedFn] = React.useState('');

    const searchResults = useMemo(() => {
        return functionNames.filter((n) => n.toLowerCase().includes(searchText.toLowerCase()));
    }, [searchText]);

    const renderRow = useCallback(
        (props: ListChildComponentProps) => {
            const { index, style } = props;

            return (
                <ListItem
                    selected={selectedFn === searchResults[index]}
                    dense
                    button
                    style={style}
                    key={index}
                    onClick={() => setSelectedFn(searchResults[index])}
                >
                    <ListItemText primary={searchResults[index]} />
                </ListItem>
            );
        },
        [setSelectedFn, searchResults, selectedFn],
    );

    return (
        <div style={{ display: 'flex' }}>
            <div>
                <div style={{ marginBottom: '.5em' }}>
                    <TextField
                        fullWidth
                        label="Search Functions"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <div className={classes.root}>
                    <FixedSizeList height={400} width={300} itemSize={35} itemCount={searchResults.length}>
                        {renderRow}
                    </FixedSizeList>
                </div>
            </div>
            <div>
                <FunctionTypeDoc functionName={selectedFn} />
            </div>
        </div>
    );
}
