export interface CurrentProcessSearch {
    query: string;
}
export default (prevState: CurrentProcessSearch = { query: '' }, action): CurrentProcessSearch => {
    if (
        action.type === '@@router/LOCATION_CHANGE' &&
        ((action.payload || {}).location || {}).pathname === '/processes'
    ) {
        return {
            query: action.payload.location.search,
        };
    }
    return prevState;
};
