import React from 'react';
import { RenderListArguments, Data } from '../List';
import uniq from 'lodash/uniq';
import { ListItem, List, ListItemText, ListItemAvatar, Avatar, ListItemIcon /* Checkbox */ } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { fromNullable } from 'fp-ts/lib/Option';
import { customShowRedirects } from 'components/generics/overrides';
import { RootState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { createGetEntities } from '../../form/EntityFormContext/util/getEntities';

const tertiaryStyle = { float: 'right', opacity: 0.541176 };
const makeWEVCCmapStateToProps = () => {
    const getEntities = createGetEntities();
    return (state: RootState) => {
        return {
            viewConfig: state.viewConfig,
            entities: getEntities(state),
        };
    };
};
interface WithEntitiesAndViewConfigProps {
    render: (args: ReturnType<ReturnType<typeof makeWEVCCmapStateToProps>>) => JSX.Element | null;
}
interface WithEntitiesAndViewConfigComponentProps
    extends WithEntitiesAndViewConfigProps,
        ReturnType<ReturnType<typeof makeWEVCCmapStateToProps>> {}
const WithEntitiesAndViewConfigComponent = (props: WithEntitiesAndViewConfigComponentProps) => {
    const { viewConfig, entities } = props;
    return props.render({
        viewConfig,
        entities,
    });
};
export const WithEntitiesAndViewConfig: React.ComponentType<WithEntitiesAndViewConfigProps> = connect(
    makeWEVCCmapStateToProps,
)(WithEntitiesAndViewConfigComponent);

const getLinkComponent = (resource, record, id, entities, viewConfig) => {
    const link: string = fromNullable(customShowRedirects[resource])
        .map((rl) => rl.find((r) => r._isRowClick && r.redirectFormula))
        .chain(fromNullable)
        .fold(`/${resource}/${id}`, (rc) => rc.redirectFormula(record, entities, viewConfig));
    return (props) => <Link to={link} {...props} />;
};
type SimpleListDataProps = Pick<RenderListArguments, 'ids' | 'data' | 'selectedData' | 'resource'> &
    Partial<Pick<RenderListArguments, 'onRowSelect'>>;
type f<T> = (record: SimpleListDataProps['data'][0], id: string | number) => T;
interface SimpleListPresentationalProps {
    primaryText: f<React.ReactNode>;
    secondaryText: f<React.ReactNode>;
    tertiaryText?: f<React.ReactNode>;
    leftAvatar?: f<React.ReactElement<{}>>;
    leftIcon?: f<React.ReactElement<{}>>;
    rightAvatar?: f<React.ReactElement<{}>>;
    rightIcon?: f<React.ReactElement<{}>>;
    resultHeadingText?: JSX.Element | string | null;
}
export type SimpleListProps = SimpleListPresentationalProps & SimpleListDataProps & { multiSelectable?: boolean };
const SimpleList: React.SFC<SimpleListProps> = ({
    ids,
    data,
    primaryText,
    secondaryText,
    tertiaryText,
    leftAvatar,
    leftIcon,
    rightAvatar,
    rightIcon,
    selectedData,
    onRowSelect,
    resultHeadingText,
    resource,
    multiSelectable,
}) => (
    <List>
        {resultHeadingText}
        {ids.map((id) => (
            <ListItem
                key={id}
                button={true}
                onClick={
                    onRowSelect &&
                    selectedData &&
                    ((e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const newValues = multiSelectable
                            ? uniq<Data[0]>([...Object.values((selectedData || {}) as Data), data[id]])
                            : [data[id]];
                        onRowSelect(newValues, data);
                    })
                }
                component={(props) => (
                    <WithEntitiesAndViewConfig
                        render={({ entities, viewConfig }) => {
                            const C = getLinkComponent(resource, data[id], id, entities, viewConfig);
                            return <C {...props} />;
                        }}
                    />
                )}
            >
                {/* onRowSelect && selectedData && <Checkbox
                    checked={selectedData[id]}
                    tabIndex={-1}
                /> */}
                {leftIcon && <ListItemIcon>{leftIcon(data[id], id)}</ListItemIcon>}
                {leftAvatar && (
                    <ListItemAvatar>
                        <Avatar>{leftAvatar(data[id], id)}</Avatar>
                    </ListItemAvatar>
                )}
                <ListItemText
                    primary={
                        <div>
                            {primaryText(data[id], id)}
                            {tertiaryText && <span style={tertiaryStyle as any}> {tertiaryText(data[id], id)}</span>}
                        </div>
                    }
                    secondary={secondaryText && secondaryText(data[id], id)}
                />
                {rightIcon && <ListItemIcon>{rightIcon(data[id], id)}</ListItemIcon>}
                {rightAvatar && (
                    <ListItemAvatar>
                        <Avatar>{rightAvatar(data[id], id)}</Avatar>
                    </ListItemAvatar>
                )}
            </ListItem>
        ))}
    </List>
);

export default SimpleList;
