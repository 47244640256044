import ViewConfig, { View, ViewField } from 'reducers/ViewConfigType';
import { isExpressionViewField, getRefEntity } from 'components/generics/utils/viewConfigUtils';
import { getFieldKeyGetter } from 'layout-editor/util/getFieldKeyGetter';

const getBuiltFieldsObject = <VFT extends ViewField>(
    fields: any[],
    baseEntity: string,
    viewConfig: ViewConfig,
): {
    [field: string]: VFT;
} => {
    const getFieldKey = getFieldKeyGetter();
    let fieldsObj: {
        [field: string]: VFT;
    } = {};
    fields.forEach((f) => {
        fieldsObj[getFieldKey(f)] = !f.field
            ? f
            : {
                  entity: isExpressionViewField(f)
                      ? undefined
                      : f.field.includes('.')
                      ? getRefEntity(
                            // needs to handle, e.g. 'foo.linkedProvider.bar'
                            viewConfig,
                            baseEntity,
                            f.field.slice(0, f.field.lastIndexOf('.')),
                            true,
                        )?.name
                      : baseEntity,
                  ...f,
              };
    });
    return fieldsObj;
};

export const convert = (view: any, viewConfig: ViewConfig): View => {
    const { viewDefName, ...rest } = view;
    const newView: View = {
        name: view.viewDefName,
        ...rest,
        fields: getBuiltFieldsObject(view.fields, view.entity, viewConfig),
    };
    if (view.searchFields) {
        newView.searchFields = getBuiltFieldsObject(Object.values(view.searchFields), view.entity, viewConfig);
    }
    if (view.tabs) {
        newView.tabs = (() => {
            const newTabs = {};
            view.tabs.forEach(({ name, ...t }) => {
                newTabs[name] = t;
            });
            return newTabs;
        })();
        Object.values(newView.tabs).forEach((tab: any) => {
            tab.fields = getBuiltFieldsObject(Object.values(tab.fields), view.entity, viewConfig);
        });
    }
    return newView;
};
