import React, { useMemo } from 'react';
import StandardReports from './StandardReports';
import { Helmet } from 'react-helmet';
import PersonalizedReports from './PersonalizedReport';
import { AppBar, Card, Tab, Tabs, Typography, makeStyles } from '@material-ui/core';
import { getLocation } from 'connected-react-router';
import { parse } from 'querystring';
import { TabPanel, a11yProps } from 'components/tabUtils';
import { useSelector, useStore } from 'react-redux';
import { push } from 'connected-react-router';
import { MyReportsList } from 'report2/components/MyReports';
import ListDescription from './Description';

const tabIndexes = {
    standard: 0,
    personalized: 1,
    delivered: 2,
};

type Tab = keyof typeof tabIndexes;

const indexesToTab: [Tab, Tab, Tab] = Object.entries(tabIndexes).reduce(
    (prev, [tab, ix]) => {
        prev[ix] = tab;
        return prev;
    },
    [null, null, null],
);

const useStyles = makeStyles(() => ({
    tab: {
        textTransform: 'capitalize',
    },
}));

const tabLabels: {
    [tab in Tab]: string;
} = {
    standard: 'Reports you can personalize',
    personalized: 'Personalized',
    delivered: 'Delivered',
};

const Reports = () => {
    const classes = useStyles();
    const store = useStore();
    const { search, pathname } = useSelector(getLocation);
    const tab: Tab = useMemo(() => {
        const trimmedSearch = search.startsWith('?') ? search.slice(1) : search;
        return (parse(trimmedSearch)?.tab as Tab) ?? 'personalized'; // there should only be one;
    }, [search]);

    const tabIndex = tabIndexes[tab];

    const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
        const tab = indexesToTab[tabIndex];

        store.dispatch(push(`${pathname}?tab=${tab}`));
    };
    const _internal_id = 'reports';

    const label = tabLabels[tab];
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Helmet>
                <title>{label}</title>
            </Helmet>
            <AppBar position="static" style={{ zIndex: 'unset' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} arial-label="report tabs">
                    <Tab label={tabLabels.standard} className={classes.tab} {...a11yProps(_internal_id, 0)} />
                    <Tab label={tabLabels.personalized} className={classes.tab} {...a11yProps(_internal_id, 1)} />
                    <Tab label={tabLabels.delivered} className={classes.tab} {...a11yProps(_internal_id, 2)} />
                </Tabs>
            </AppBar>
            <TabPanel tabsName={_internal_id} index={0} value={tabIndex}>
                <Card>
                    <StandardReports />
                </Card>
            </TabPanel>
            <TabPanel tabsName={_internal_id} index={1} value={tabIndex}>
                <Card>
                    <PersonalizedReports />
                </Card>
            </TabPanel>
            <TabPanel tabsName={_internal_id} index={2} value={tabIndex}>
                <Card>
                    <Typography variant="h5" component="h2" style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                        Delivered Reports
                    </Typography>
                    <ListDescription messageId="reports.delivered.description" />
                    <MyReportsList />
                </Card>
            </TabPanel>
        </div>
    );
};
export default Reports;
