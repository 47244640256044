import * as taskPotentialUsers from '../actions';
import { ActionType, getType } from 'typesafe-actions';
import { failure, pending, success } from '@devexperts/remote-data-ts';
import { RootAction } from 'actions/rootAction';
import { serialize, serialrd } from 'reducers/lists/list/serializeDeserialize';

export type taskPotentialUsersAction = ActionType<typeof taskPotentialUsers>;

type PotentialUsersState = {
    [taskId: string]: serialrd<boolean, string>;
};
const reducer = (state: PotentialUsersState = {}, action: RootAction): PotentialUsersState => {
    switch (action.type) {
        case getType(taskPotentialUsers.getTaskPotentialUsers):
            return {
                ...state,
                [action.payload.taskId]: serialize(pending),
            };

        case getType(taskPotentialUsers.getTaskPotentialUsersFailure):
            return {
                ...state,
                [action.requestPayload.taskId]: serialize(failure(action.error.message)),
            };
        case getType(taskPotentialUsers.getTaskPotentialUsersSuccess):
            return {
                ...state,
                [action.requestPayload.taskId]: serialize(success(action.payload.canAssignTask)),
            };
        default:
            return state;
    }
};
export default reducer;
