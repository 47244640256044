import React from 'react';
import { RenderListArguments } from './List';
import { Chip } from '@material-ui/core';

interface Classes {
    root?: string;
    chip?: string;
}
interface Styles {
    root?: React.CSSProperties;
    chip?: React.CSSProperties;
}
interface ChipRendererArgs {
    handleDelete?: (record: { id?: string }) => void;
    disabled?: boolean;
}
const getRenderer = (classes: Classes, styles: Styles, c: ChipRendererArgs) => (r: RenderListArguments) => {
    const { onRowSelect } = r;
    return r.ids.map((id) => {
        const record = r.data[id];
        return (
            <Chip
                style={styles.chip}
                key={id}
                tabIndex={0}
                aria-roledescription="Button Press Delete key to delete"
                label={record && record.title}
                className={classes.chip}
                // the popover isn't editable for some reason, when called in RefManyMany.
                onClick={onRowSelect ? () => onRowSelect([record], r.data) : undefined}
                onDelete={() => !c.disabled && c.handleDelete && c.handleDelete(record)}
                deleteIcon={c.disabled || !c.handleDelete ? <span style={{ width: '.5em' }} /> : undefined}
            />
        );
    });
};

export default getRenderer;
