import { FormFieldUnion, TableFormField } from 'fieldFactory/translation/fromFlowable/types';
/*
    Takes a set of fields, and a path to a table in that set of fields.
    that path may point to a nested table, e.g. "table1.table2"
    the function returns a new set of fields, with the table pointed to (if it exists) replaced with fieldData

    The purpose of this function is to create a 'preview' of taskFormData for expressions to compile against, where we might be editing in a particular table
    e.g. table1.table2 and we need our current fieldData patched in.
*/
const insertOrReplaceTableField =
    (initialId: string, currentId: string) =>
    (fields: FormFieldUnion[], dotSeperatedTablePath: string, fieldData: FormFieldUnion) => {
        const subPaths = dotSeperatedTablePath.split('.');
        if (subPaths.length === 1) {
            const table = fields.find((f) => f.type === 'table' && f.id === dotSeperatedTablePath) as TableFormField;
            if (!table) {
                return fields;
            }
            return [
                ...fields.filter((f) => f !== table),
                {
                    ...table,
                    params: {
                        ...table.params,
                        columnObj: (() => {
                            const currentField = table.params.columnObj?.find(
                                (f) => f.id === initialId || f.id === currentId,
                            );
                            if (currentField) {
                                const ixOfCurrent = table.params.columnObj.indexOf(currentField);
                                const res = [
                                    ...table.params.columnObj.slice(0, ixOfCurrent),
                                    fieldData,
                                    ...table.params.columnObj.slice(ixOfCurrent + 1),
                                ];
                                return res;
                            }
                            return [...(table.params?.columnObj ?? []), fieldData];
                        })(),
                    },
                },
            ];
        }
        const [nextTable, ...restPath] = subPaths;
        const nextTableField = fields.find((f) => f.type === 'table' && f.id === nextTable) as TableFormField;
        const nextCols = nextTableField?.params?.columnObj;

        if (!nextCols || nextCols.length === 0) {
            return fields;
        }
        return [
            ...fields.filter((f) => f.id !== nextTable),
            {
                ...nextTableField,
                params: {
                    ...nextTableField.params,
                    columnObj: insertOrReplaceTableField(initialId, currentId)(nextCols, restPath.join('.'), fieldData),
                },
            },
        ];
    };
export default insertOrReplaceTableField;
