import * as React from 'react';
import { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { setSidebarVisibility as setSidebarVisibilityAction } from 'actions/aor/uiActions';
import memoizeOne from 'memoize-one';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';
import compose from 'recompose/compose';
import Sidebar from '../SsgSidebar';
import TaskDrawerWrapper from '../../bpm/components/TaskDrawer/TaskDrawer';
import AdminRoutes from '../../routes/AdminRoutes';
import { DefaultViews } from '../../util/DefaultViews';
import ViewConfig from '../../reducers/ViewConfigType';
import getResourcesFromViewConfig from '../../util/getResourceListFromConfig';
import getV1InitialTheme from './getMuiV1Theme';
import { getPrimaryColor, getSecondaryColor, getErrorColor } from './getThemeColors';
import { RootState } from '../../reducers/rootReducer';
import { Width } from 'bpm/components/layout/withAdjustedWidth';
import { SnackbarProvider } from 'notistack';
import Notifier from 'notistack/components/Notifier';
import LayoutRoot from './LayoutRoot';
import Themed from 'components/Themed';
import useViewConfig from 'util/hooks/useViewConfig';
import ToolbarMenu from './menu2/ToolbarMenu';
import SelectProfilePrompt from 'auth/profiles/components/SelectProfilePrompt';
import DeferredSpinner from 'components/DeferredSpinner';
import SidebarMenu from './menu2/mobile/SidebarMenu';

const snackbarProviderStyles = (theme: Theme) =>
    createStyles({
        success: { backgroundColor: theme.palette.primary.main },
    });
const SnackbarProviderComponent: React.SFC<WithStyles<typeof snackbarProviderStyles>> = (props) => (
    <SnackbarProvider
        classes={{
            variantSuccess: props.classes.success,
        }}
        preventDuplicate={true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
    >
        {props.children as any}
    </SnackbarProvider>
);
export const StyledSnackbarProvider = withStyles(snackbarProviderStyles)(SnackbarProviderComponent);

interface LayoutProps {
    authClient?: Function;
    customRoutes?: ReactElement<{}>[];
    dashboard?: Function | String;
    isLoading?: boolean;
    setSidebarVisibility: Function;
    themeV1: any;
    themeV0: {
        body: {};
        bodySmall: {};
    };
    width: Width;
    viewConfig: ViewConfig;
    viewConfigIsLoading: boolean;
    initialViewConfigLoadingFromAnon: boolean;
    withDrawerWrapper: boolean;
    appColor?: string;
    thm0: boolean;
    isSecureEnvironment?: boolean;
    printMode: boolean;
}

class Layout extends Component<LayoutProps> {
    _getV1InitialTheme = memoizeOne(getV1InitialTheme);
    _getGeneratedResources = memoizeOne((viewConfig: ViewConfig) => {
        if (!viewConfig || !viewConfig.entities) {
            return [];
        }
        return getResourcesFromViewConfig(viewConfig);
    });
    componentDidMount() {
        this.props.setSidebarVisibility(false);
    }

    componentWillReceiveProps(newProps: LayoutProps) {
        if (newProps.width !== 'xs' && newProps.width !== 'sm' && this.props.width !== newProps.width) {
            this.props.setSidebarVisibility(false);
        }
    }

    generatedResources() {
        return this._getGeneratedResources(this.props.viewConfig);
    }

    nonDefaultResourceViews(): ViewConfig['views'][0][] {
        const vc = this.props.viewConfig;
        if (!vc || !vc.entities) {
            return [];
        }
        return Object.values(vc.views).filter((v) => !DefaultViews.isDefaultView(vc, v.name));
    }
    getThemeArgs = () => [(this.props.appColor as any) || 'blue', 'grey'];
    createV1Theme = ({ appColor, printMode } = this.props) => {
        const theme = this._getV1InitialTheme(
            {
                primary: getPrimaryColor(appColor),
                secondary: getSecondaryColor(),
                error: getErrorColor(appColor),
                printMode,
            },
            true, // DOM sideEffect: rebuild the stylesheet for React-Web-Tabs
        );
        // set the document background color
        document.body.style.background = theme.palette.background.default;
        return theme;
    };
    render() {
        const {
            // authClient,
            customRoutes = [],
            dashboard,
            isSecureEnvironment,
            withDrawerWrapper,
            width,
            initialViewConfigLoadingFromAnon,
            printMode,
        } = this.props;
        const adminRoutes = (
            <AdminRoutes
                customRoutes={customRoutes}
                resources={this.generatedResources() as never[]}
                nonDefaultResourceViews={this.nonDefaultResourceViews()}
                /* authClient={authClient} this isn't used even in aor (as far as I can tell) */
                dashboard={dashboard}
            />
        );
        const isSmall = width === 'xs';
        return (
            <LayoutRoot>
                <div>
                    {!isSmall && !printMode && (
                        <span>
                            <div
                                style={{
                                    /* position: 'fixed', */ position: 'relative',
                                    width: '100%',
                                    zIndex: 100,
                                }}
                            >
                                <ToolbarMenu />
                            </div>
                        </span>
                    )}
                    {/* uncomment below if using position:fixed toolbar */}
                    {/* width !== 1 && <div style={{ height: muiTheme.toolbar.height }} /> */}
                    <Themed>
                        {({ theme }) => (
                            <div
                                id="maincontent"
                                className="body"
                                role="main"
                                style={{
                                    backgroundColor: printMode
                                        ? theme.palette.background.paper
                                        : theme.palette.background.default,
                                }}
                            >
                                <StyledSnackbarProvider>
                                    <React.Fragment>
                                        {!printMode && <SelectProfilePrompt />}
                                        {!isSecureEnvironment && <Notifier />}
                                        <div style={!isSmall && !printMode ? { padding: '1em' } : undefined}>
                                            {initialViewConfigLoadingFromAnon ? (
                                                <DeferredSpinner />
                                            ) : withDrawerWrapper ? (
                                                <TaskDrawerWrapper>{adminRoutes}</TaskDrawerWrapper>
                                            ) : (
                                                adminRoutes
                                            )}
                                        </div>
                                        {isSmall ? (
                                            <Sidebar>
                                                <SidebarMenu />
                                            </Sidebar>
                                        ) : null}
                                    </React.Fragment>
                                </StyledSnackbarProvider>
                            </div>
                        )}
                    </Themed>
                </div>
            </LayoutRoot>
        );
    }
}

function mapStateToProps(state: RootState) {
    const initialViewConfigLoadingFromAnon =
        state.viewConfigIsLoading && state.viewConfig?.user?.login === 'anonymousUser';
    return {
        initialViewConfigLoadingFromAnon,
        viewConfigIsLoading: state.viewConfigIsLoading,
        withDrawerWrapper:
            state.viewConfig &&
            state.viewConfig.application &&
            state.viewConfig.application.name !== 'rvrs' &&
            state.viewConfig.user.login !== 'anonymousUser',
        appColor: state.basicInfo && state.basicInfo.applicationColor,
        isSecureEnvironment: state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true,
        printMode: state.printMode,
    };
}

const enhance = compose(
    connect(mapStateToProps, {
        setSidebarVisibility: setSidebarVisibilityAction,
    }),
    withWidth({
        initialWidth: 'md',
    }),
    (BaseComponent) => (props) => {
        const viewConfig = useViewConfig();
        return <BaseComponent {...props} viewConfig={viewConfig} />;
    },
);

export default enhance(Layout);
