import { ExpressionEvaluatorContext } from 'expressions/Provider/expressionEvaluatorContext';
import { tryCatch } from 'fp-ts/lib/Either';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import isEqual from 'lodash/isEqual';
import detailsRegistry from 'temporary_registry/registry';
import jsImpl from '../jsImpl';
import tsImpl from '../tsImpl';
import get from 'lodash/get';
import set from 'lodash/set';
import flatten from 'flat';

const compatibilityImpl: ExpressionEvaluatorContext = {
    compileExpression: (expression: string) => {
        const js = jsImpl.compileExpression(expression);
        const ts = tsImpl.compileExpression(expression);
        if (js.type === 'parse_failure') {
            return js;
        }
        if (ts.type === 'parse_failure') {
            detailsRegistry.addDiff({
                _type: 'other',
                expression,
                msg: 'in compatibilityImpl compile step',
                results: [null, ts.msg],
            });
            return js;
        }
        type Success = typeof js;
        const getCompatibilityFn =
            (
                fnKey: keyof Pick<
                    Success,
                    | 'getExpansions'
                    | 'getExpansionsWithAll'
                    | 'getExpansionsWithoutArrayDescendants'
                    | 'getPathsWithAll'
                    | 'getValueSetLiterals'
                >,
            ) =>
            () => {
                const jsres = js[fnKey]();
                const tsres = ts[fnKey]();
                /**
                 * If result is e.g.
                 * subject, subject.foo
                 * we just care about subject.foo
                 */
                const reducedJsRes: string[] = (() => {
                    let res = {};
                    jsres.forEach((path) => {
                        if (!get(res, path)) {
                            set(res, path, {});
                        }
                    });
                    return Object.keys(flatten(res));
                })();
                if (!isEqual(new Set(reducedJsRes), new Set(tsres))) {
                    detailsRegistry.addDiff({
                        _type: 'other',
                        expression,
                        msg: `compatibilityImpl ${fnKey}()`,
                        results: [reducedJsRes, tsres],
                    });
                }
                return jsres;
            };
        if (!isEqual(new Set(js.methodsAndFunctions), new Set(ts.methodsAndFunctions))) {
            detailsRegistry.addDiff({
                _type: 'other',
                expression,
                msg: `compatibilityImpl methodsAndFunctions`,
                results: [js.methodsAndFunctions, ts.methodsAndFunctions],
            });
        }
        return {
            ...js,
            getExpansions: getCompatibilityFn('getExpansions'),
            getPathsWithAll: getCompatibilityFn('getPathsWithAll'),
            getExpansionsWithAll: getCompatibilityFn('getExpansionsWithAll'),
            getExpansionsWithoutArrayDescendants: getCompatibilityFn('getExpansionsWithoutArrayDescendants'),
            getValueSetLiterals: getCompatibilityFn('getValueSetLiterals'),
            evaluate(context, functionsAndVariables) {
                const jsResult = js.evaluate(context, functionsAndVariables);
                const tsResult = ts.evaluate(context, functionsAndVariables);
                if (jsResult.type === 'evaluation_failure') {
                    return jsResult;
                }
                if (tsResult.type === 'evaluation_failure') {
                    detailsRegistry.addDiff({
                        _type: 'eval',
                        expression,
                        msg: 'in compatibilityImpl evaluate - threw',
                        results: [jsResult.result, tsResult.msg],
                        relevantValues: context,
                    });
                } else if (!isEqual(jsResult.result, tsResult.result)) {
                    detailsRegistry.addDiff({
                        _type: 'eval',
                        expression,
                        msg: 'in compatibilityImpl evaluate',
                        results: [jsResult.result, tsResult.result],
                        relevantValues: context,
                    });
                }
                return jsResult;
            },
            rebase: ts.rebase,
            prettyPrint: ts.prettyPrint,
        };
    },
    evaluateTemplate: (template, sanitizeResult) => {
        return (context, functionsAndVariables) =>
            tryCatch(() =>
                evaluateFilterString(
                    template,
                    {
                        ...functionsAndVariables,
                        ...context,
                    },
                    sanitizeResult,
                ),
            ).mapLeft((e) => [e.message]);
    },
};

export default compatibilityImpl;
