/* eslint no-fallthrough: 0 */
import { combineReducers } from 'redux';
import { success, failure, initial, pending, RemoteData } from '@devexperts/remote-data-ts';
import ids, { ids as idsType } from './ids';
import total, { total as totalType } from './total';
import { ListAction } from '..';
import { getType } from 'typesafe-actions';
import * as listActions from 'sideEffect/crud/getList/actions';
import { crudDeleteSuccess } from 'sideEffect/crud/delete/actions';
import { ActionType } from 'typesafe-actions';
import { deserializeList, serializeList, serialrd } from './serializeDeserialize';

interface List {
    ids: idsType;
    total: totalType;
}

const listReducer = (resource: string) =>
    combineReducers<List>({
        ids: ids(resource),
        total: total(resource),
    });

export default (resource: string) => {
    const listRepresentationReducer = listReducer(resource);
    const reducer = (
        _previousState: serialrd<List, Error> = initial,
        action: ListAction | ActionType<typeof crudDeleteSuccess>,
    ): serialrd<List, Error> => {
        switch (action.type) {
            case getType(listActions.crudGetList): {
                const previousState: RemoteData<Error, List> = deserializeList(_previousState);
                if (!previousState.isSuccess()) {
                    return serializeList(pending);
                }
            }
            case getType(listActions.crudGetListSuccess): {
                const previousState: RemoteData<Error, List> = deserializeList(_previousState);
                return serializeList(
                    success(
                        listRepresentationReducer(previousState.isSuccess() ? previousState.value : undefined, action),
                    ),
                );
            }
            case getType(crudDeleteSuccess): {
                const previousState: RemoteData<Error, List> = deserializeList(_previousState);
                return serializeList(previousState.map((prevState) => listRepresentationReducer(prevState, action)));
            }
            case getType(listActions.crudGetListFailure): {
                const previousState: RemoteData<Error, List> = deserializeList(_previousState);
                if (!previousState.isSuccess()) {
                    return serializeList(failure(JSON.parse(JSON.stringify(action.payload))));
                }
            }
            default:
                return _previousState;
        }
    };
    return reducer;
};
