import React, { FunctionComponent } from 'react';
import VirtualizedViewDefTable from './VirtualizedViewDefTable';
import { Chip } from '@material-ui/core';
import type { Filter } from './VirtualizedViewDefTableComponent';

type ViewDef = any; // for now

interface PickViewDef {
    viewName?: string;
    setViewName?: (viewName: string, data: ViewDef) => void;
    fixedFilter?: Filter;
}
const PickViewDef: FunctionComponent<PickViewDef> = ({ viewName, setViewName, fixedFilter }) => {
    return (
        <div>
            {viewName ? (
                <span>
                    &nbsp;&nbsp;
                    <Chip label={viewName} onDelete={() => setViewName(null, null)} />
                </span>
            ) : (
                <VirtualizedViewDefTable setViewName={setViewName} fixedFilter={fixedFilter} />
            )}
        </div>
    );
};
export default PickViewDef;
