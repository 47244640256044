import { useSelector } from 'react-redux';
import { createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import { useMemo } from 'react';

const useValueSets = () => {
    const getValuesets = useMemo(createGetValueSets, []);
    const entities = useSelector(getValuesets);
    return entities;
};
export default useValueSets;
