import { createCustomAction } from 'typesafe-actions';
import {
    GET_TASK_POTENTIAL_USERS,
    GET_TASK_POTENTIAL_USERS_FAILURE,
    GET_TASK_POTENTIAL_USERS_SUCCESS,
} from './constants';
import { TaskPotentialUsersResponse } from 'sideEffect/services';

export const getTaskPotentialUsers = createCustomAction(GET_TASK_POTENTIAL_USERS, (type) => {
    return (taskId: string) => ({
        type,
        payload: {
            taskId,
        },
    });
});

export const getTaskPotentialUsersSuccess = createCustomAction(GET_TASK_POTENTIAL_USERS_SUCCESS, (type) => {
    return (data: TaskPotentialUsersResponse, requestPayload: { taskId: string }) => ({
        type,
        payload: data,
        requestPayload,
    });
});

export const getTaskPotentialUsersFailure = createCustomAction(GET_TASK_POTENTIAL_USERS_FAILURE, (type) => {
    return (error: Error, requestPayload: { taskId: string }) => ({
        type,
        error,
        requestPayload,
    });
});
