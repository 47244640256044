import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { Observable, concat, ObservableInput, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
// eslint-disable-next-line import/no-webpack-loader-syntax
import createWorker from 'workerize-loader?inline!./worker';
import * as GetSubsequentActionsWorker from './worker';

const vsWorkerInstance = createWorker<typeof GetSubsequentActionsWorker>();

// Attach an event listener to receive calculations from your worker
// vsWorkerInstance.addEventListener('message', message => {
//     console.log('New Message From Worker: ', message.data);
// });
// Run your calculations

// lets stick this in a service+++
export const { getSubsequentActions } = vsWorkerInstance;

const workerObservable = new Observable<RootAction[]>((observer) => {
    vsWorkerInstance.addEventListener('message', (message) => {
        if (message.data.result && Array.isArray(message.data.result)) {
            observer.next(message.data.result as RootAction[]);
        }
    });
});

const valuesetsNeededForFormEpic: Epic<RootAction, RootAction, RootState, {}> = () =>
    workerObservable.pipe(
        flatMap<RootAction[], ObservableInput<RootAction>>((actions) => concat(...actions.map((action) => of(action)))),
    );
export default valuesetsNeededForFormEpic;
