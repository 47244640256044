import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';

interface WithViewConfigProps {
    children: (args: { viewConfig: ViewConfig }) => JSX.Element | null;
}
const mapStateToProps = (state: RootState, props: WithViewConfigProps) => {
    return {
        viewConfig: state.viewConfig,
    };
};
const WithViewConfig: React.ComponentType<WithViewConfigProps> = connect(mapStateToProps)(
    ({ viewConfig, children }: ReturnType<typeof mapStateToProps> & WithViewConfigProps) => children({ viewConfig }),
);
export default WithViewConfig;
