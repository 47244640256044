import { IconButton, TableCell, Tooltip } from '@material-ui/core';
import makeCasetivityAdhocList from 'components/adhoclist';
import { Link } from 'react-router-dom';
import React, { useReducer } from 'react';
import getNoResultsElement from 'components/generics/genericList/getNoResultsTextElement';
import ListDescription from './Description';
import DeletePersonalizedReportButton from 'custom-reports/extend-report/DeleteReportButton';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import { Temporal } from '@js-temporal/polyfill';
import { Order } from 'mui-rest-list/lib/src/components/makeAdhocList';
import Delete from '@material-ui/icons/Delete';
import NameFilter from './shared/NameFilter';
import Message from 'i18n/components/Message';

type PersonalizedReportData = {
    name: string;
    description: string;
    id: string;
    parentReportId: string;
    lastRun?: string;
};
const { AdhocList, AdhocListColumn, getPagedObservable } = makeCasetivityAdhocList<PersonalizedReportData>();

const PersonalizedReports = () => {
    const [key, refresh] = useReducer((state) => state + 1, 1);
    const getDataObservable = React.useCallback(
        (params: { size: number; page: number; orderBy: Order; filter: { name: '' } }) => {
            const sortStr = params.orderBy
                ? `&sort=${[
                      params.orderBy[0] === 'id' ? 'parentReportName' : params.orderBy[0],
                      params.orderBy[1],
                  ].join(',')}`
                : '';
            const filterStr = params.filter?.name ? `&name.equals=${params.filter.name}*` : '';
            return getPagedObservable(
                `api/reports/personalize?page=${params.page}&size=${params.size}${sortStr}${filterStr}`,
            );
        },
        [],
    );

    const showHiddenOptions = useExpressionTesterOpen() !== 'CLOSED';
    return (
        <AdhocList
            key={key}
            type="paginated"
            hasRefresh
            pagesNIndexed={0}
            titleOptions={{
                type: 'Typography',
                TypographyProps: {
                    variant: 'h5',
                    component: 'div',
                    style: {
                        marginLeft: '1rem',
                        marginTop: '1rem',
                    },
                },
                text: 'Personalized Reports',
            }}
            initialFilter={{ name: '' }}
            renderFilter={(params) => <NameFilter {...params} />}
            renderNoResults={() => getNoResultsElement(<Message id="list.noResults" dm="No results found" />)}
            renderBelowTitle={() => <ListDescription messageId="reports.personalized.description" />}
            defaultSize={10}
            getDataObservable={getDataObservable}
            tableCaption="Reports available"
        >
            <AdhocListColumn
                title="Personalized From"
                fieldKey="id"
                sortable
                renderdata={(id, data) => (
                    <TableCell>
                        {showHiddenOptions ? (
                            <Link
                                aria-label="Go to the report definition"
                                to={`/ReportDefinition/${data.parentReportId}/show`}
                            >
                                {data.parentReportName}
                            </Link>
                        ) : (
                            <span>{data.parentReportName}</span>
                        )}
                    </TableCell>
                )}
            />
            <AdhocListColumn sortable title="Name" fieldKey="name" />
            <AdhocListColumn
                sortable
                title="Description"
                fieldKey="description"
                renderdata={(description, data) => (
                    <TableCell>
                        <span style={{ overflowWrap: 'anywhere' }}>{description}</span>
                    </TableCell>
                )}
            />
            <AdhocListColumn
                sortable
                initialSort="desc"
                title="Last Run"
                fieldKey="lastRun"
                renderdata={(lastRun, data) => (
                    <TableCell>{!!lastRun && Temporal.Instant.from(lastRun).toLocaleString()}</TableCell>
                )}
            />
            <AdhocListColumn
                title="Inspect Action"
                hideColTitle
                fieldKey="id"
                renderdata={(id, data) => (
                    <TableCell>
                        <div style={{ display: 'flex', gap: '1rem', verticalAlign: 'middle' }}>
                            <Link
                                style={{ margin: 'auto' }}
                                aria-label="Run this personalized report"
                                to={`/run-personalized-report/${id}`}
                            >
                                Run
                            </Link>
                            <Link
                                style={{ margin: 'auto' }}
                                aria-label="Edit this personalized report"
                                to={`/edit-personalized-report/${id}`}
                            >
                                Edit
                            </Link>
                            <DeletePersonalizedReportButton
                                onDelete={refresh}
                                id={id}
                                renderTrigger={({ onClick }) => (
                                    <Tooltip title="Delete">
                                        <IconButton size="small" aria-label="delete" onClick={onClick}>
                                            <Delete color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            />
                        </div>
                    </TableCell>
                )}
            />
        </AdhocList>
    );
};

export default PersonalizedReports;
