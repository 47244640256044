import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { entityConceptExpressionsGenerated } from './actions';
import { EntityFieldConceptExps } from './EntityFieldConceptExps';

export default (previousState: EntityFieldConceptExps = {}, action: RootAction): EntityFieldConceptExps => {
    switch (action.type) {
        case getType(entityConceptExpressionsGenerated):
            return action.payload;
        default:
            return previousState;
    }
};
