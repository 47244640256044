import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
    capitalize,
} from '@material-ui/core';
import { PersonalizedReport, PersonalizedReportConfig, PersonalizedReportSave } from 'custom-reports/types';
import React, { useMemo, useRef, useState } from 'react';
import { services } from 'sideEffect/services';
import ReportEditor, { getConflicts } from './Editor';
import { Link } from 'react-router-dom';
import usePersonalizedReport from 'custom-reports/hooks/usePersonalizedReport';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import DeletePersonalizedReportButton from './DeleteReportButton';
import { useStore } from 'react-redux';
import { push } from 'connected-react-router';
import Popup from 'components/Popup';
import Clear from '@material-ui/icons/Clear';
import { Formik } from 'formik';
import FormSaveNotifierTrigger from 'formSaveNotifier/components/Trigger';
import Helmet from 'react-helmet';
import Message from 'i18n/components/Message';
import { DebouncedField } from 'fieldFactory/input/components/DebouncedTextInput';
import { EvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import useCustomReportFieldError from './useCustomReportFieldError';

interface EditPersonalizedReportFormProps {
    initialPersonalizedReport: PersonalizedReport;
    id: string;
    onSubmit: (pr: PersonalizedReportSave) => void;
    onSubmitAsNew: (pr: PersonalizedReportSave) => void;
}
const EditPersonalizedReportForm = (props: EditPersonalizedReportFormProps) => {
    const [name, setName] = useState(props.initialPersonalizedReport.name);
    const [description, setDescription] = useState(props.initialPersonalizedReport.description);
    const descriptionDirty = description !== props.initialPersonalizedReport.description;
    const nameDirty = name !== props.initialPersonalizedReport.name;

    const initialConfig = useMemo(
        () => JSON.parse(props.initialPersonalizedReport.config),
        [props.initialPersonalizedReport.config],
    );
    const [configWithResolutions, setConfigWithResolutions] = useState<PersonalizedReportConfig>(initialConfig);
    const { incompatibleWithParent: initiallyIncompatibleWithParent } = useMemo(
        () =>
            getConflicts({
                personalizedReportConfig: initialConfig,
                reportDefinition: {
                    outputColumns: props.initialPersonalizedReport.outputColumns,
                    params: props.initialPersonalizedReport.params,
                },
            }),
        [initialConfig, props.initialPersonalizedReport],
    );

    const submit = (config: PersonalizedReportConfig) => {
        props.onSubmit({
            name,
            description,
            config: JSON.stringify(config),
        });
    };
    const store = useStore();
    const nameError = useCustomReportFieldError('name', name);
    const descriptionError = useCustomReportFieldError('description', description);
    return (
        <div>
            <Card style={{ marginBottom: '1em' }}>
                <Grid justifyContent="space-between" style={{ display: 'flex', padding: '1em' }}>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div>
                            <Typography variant="h5">Edit Personalized Report</Typography>
                        </div>
                        <div>
                            <DeletePersonalizedReportButton
                                title={props.initialPersonalizedReport.name}
                                id={props.id}
                                onDelete={() => {
                                    store.dispatch(push('/reports/'));
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ marginRight: '-1em' }}>
                        <span style={{ marginRight: '1em' }}>
                            <Button component={Link} to="/reports?tab=personalized" startIcon={<ArrowBack />}>
                                Back to all reports
                            </Button>
                        </span>
                        <span style={{ marginRight: '1em' }}>
                            <Button
                                disabled={initiallyIncompatibleWithParent}
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={`/run-personalized-report/${props.id}`}
                                endIcon={<ArrowForward />}
                            >
                                Run Report
                            </Button>
                        </span>
                    </div>
                </Grid>
                <Grid style={{ padding: '1em' }}>
                    <DebouncedField
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        onBlur={(e) => setName(e.target.value)}
                        InputProps={{
                            inputProps: {
                                'aria-label': 'Name',
                            },
                        }}
                        value={name}
                        label="Name"
                        error={!!nameError}
                        helperText={nameError && <EvaluatedFormattedMessage message={nameError} />}
                    />
                </Grid>
                <Grid style={{ padding: '1em' }}>
                    <DebouncedField
                        multiline
                        fullWidth
                        onChange={(e) => setDescription(e.target.value)}
                        onBlur={(e) => setDescription(e.target.value)}
                        value={description}
                        label="Description"
                        InputProps={{
                            inputProps: {
                                'aria-label': 'Description',
                            },
                        }}
                        error={!!descriptionError}
                        helperText={descriptionError && <EvaluatedFormattedMessage message={descriptionError} />}
                    />
                </Grid>
            </Card>
            <Popup<PersonalizedReportConfig>
                renderDialogContent={({ closeDialog, optionalData }) => (
                    <div style={{ margin: '1em' }}>
                        <Formik<{ name: string }>
                            initialValues={{
                                name,
                            }}
                            validateOnMount
                            validate={(values) => {
                                if (!values.name) {
                                    return { name: 'Required' };
                                }
                                if (values.name === props.initialPersonalizedReport.name) {
                                    return { name: 'The name must be different.' };
                                }
                                return {};
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                const newData = {
                                    name: values.name,
                                    description,
                                    parentReportId: props.initialPersonalizedReport.parentReportId,
                                    config: JSON.stringify(optionalData),
                                };
                                setSubmitting(true);
                                props.onSubmitAsNew(newData);
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, dirty }) => (
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <div
                                        style={{
                                            marginBottom: '1em',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            <Typography variant="h6">New Report</Typography>
                                        </div>
                                        <div>
                                            <IconButton size="small" onClick={closeDialog}>
                                                <Clear />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <TextField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        name="name"
                                        label="Report Name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                    <div style={{ marginTop: '1em' }}>
                                        <Button
                                            disabled={Object.keys(errors).length > 0}
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Create new Personalized Report
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )}
                renderToggler={({ openDialog }) => (
                    <ReportEditor
                        // remount when manual resolution occurs
                        key={JSON.stringify(configWithResolutions)}
                        disableSubmit={Boolean(descriptionError || nameError)}
                        reportDefinition={props.initialPersonalizedReport}
                        initialValue={configWithResolutions}
                        setInitialValue={setConfigWithResolutions}
                        onSubmitAsNew={(newConfig) => openDialog(newConfig)()}
                        onSubmit={(newConfig) => {
                            submit(newConfig);
                        }}
                        renderBelow={({ dirty }) => (
                            <FormSaveNotifierTrigger when={dirty || nameDirty || descriptionDirty} />
                        )}
                    />
                )}
            />
        </div>
    );
};

interface EditPersonalizedReportProps {
    id: string;
}
const EditPersonalizedReport = (props: EditPersonalizedReportProps) => {
    const store = useStore();
    const expressionTesterOpen = useExpressionTesterOpen() !== 'CLOSED';
    const [submittingState, setSubmittingState] = useState<'loading' | 'failed' | 'succeeded' | 'none'>('none');

    const isSucceeded = submittingState === 'succeeded';
    const keyRef = useRef(1);
    useMemo(() => {
        if (!isSucceeded) {
            keyRef.current += 1;
        }
    }, [isSucceeded]);

    const personalizedReport = usePersonalizedReport(props.id, keyRef.current);
    if (personalizedReport.status === 'success') {
        return (
            <div>
                <Helmet>
                    <title>{`Edit ${personalizedReport.data.name}`}</title>
                </Helmet>
                {submittingState !== 'none' && (
                    <Dialog open onClose={() => setSubmittingState('none')}>
                        <DialogTitle>{capitalize(submittingState)}</DialogTitle>
                        <DialogContent>
                            <div style={{ display: 'grid', placeItems: 'center', padding: '1em' }}>
                                {submittingState === 'loading' ? (
                                    <CircularProgress />
                                ) : submittingState === 'failed' ? (
                                    <ErrorIcon fontSize="large" color="error" />
                                ) : (
                                    <CheckIcon fontSize="large" color="primary" />
                                )}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography>
                                    {submittingState === 'loading' ? (
                                        <Message id="loading.pleaseWait" dm="Please wait..." />
                                    ) : submittingState === 'failed' ? (
                                        'An error has occurred.'
                                    ) : (
                                        'Save succeeded'
                                    )}
                                </Typography>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setSubmittingState('none')}>Close</Button>
                        </DialogActions>
                    </Dialog>
                )}
                <EditPersonalizedReportForm
                    key={keyRef.current}
                    id={props.id}
                    initialPersonalizedReport={personalizedReport.data}
                    onSubmitAsNew={(data) => {
                        setSubmittingState('loading');
                        services.personalizedReports.create(data).subscribe(
                            (res) => {
                                setSubmittingState('succeeded');
                                setTimeout(() => {
                                    store.dispatch(push('/edit-personalized-report/' + res.response['id']));
                                }, 1000);
                            },
                            (err) => {
                                setSubmittingState('failed');
                                console.error(err);
                            },
                        );
                    }}
                    onSubmit={(data) => {
                        setSubmittingState('loading');
                        services.personalizedReports
                            .update({
                                id: props.id,
                                parentReportId: personalizedReport.data.parentReportId,
                                ...data,
                            })
                            .subscribe(
                                (res) => {
                                    setSubmittingState('succeeded');
                                    setTimeout(() => {
                                        setSubmittingState('none');
                                    }, 1000);
                                },
                                (err) => {
                                    setSubmittingState('failed');
                                    console.error(err);
                                },
                            );
                    }}
                />
                {expressionTesterOpen ? <pre>{JSON.stringify(personalizedReport.data, null, 1)}</pre> : null}
            </div>
        );
    }
    return null;
};
export default EditPersonalizedReport;
