import React from 'react';
import { useSelector } from 'react-redux';
import { fromPredicate } from 'fp-ts/lib/Option';
import { parse } from 'querystring';
import { getLocation } from 'connected-react-router';

export const useRedirectTo = () => {
    const location = useSelector(getLocation);
    const search = location.search;
    const redirectTo = React.useMemo(() => {
        return fromPredicate<string>(Boolean)(search)
            .map((s) => s.slice(1))
            .chain(fromPredicate<string>(Boolean))
            .mapNullable(parse)
            .mapNullable((p) => p.redirectTo)
            .mapNullable((rt) => (Array.isArray(rt) ? rt[0] : rt))
            .toUndefined();
    }, [search]);
    return redirectTo;
};
