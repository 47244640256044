import React from 'react';
import SelectInput from 'bpm2/sharedComponents/ChoicesSelect2';
// import { RootState } from '../../../../reducers/rootReducer';
import { CurrentState } from '../../types';
import { CaseTypeDropdown } from '../../../ProcessList/components/dropdowns';
export { CaseTypeDropdown };

export const StateDropdown: React.SFC<{
    currentState: CurrentState;
    onChange: (value: CurrentState) => void;
}> = ({ onChange, currentState }) => (
    <SelectInput
        showNoneChoice={true}
        noneChoiceText="All"
        label="Task Status"
        input={{
            value: currentState,
            onBlur: (s) => onChange(s),
        }}
        choices={{
            Open: {
                name: 'Open',
            },
            Overdue: {
                name: 'Open (overdue)',
            },
            Closed: {
                name: 'Closed',
            },
        }}
    />
);

export const AssignedDropdown: React.SFC<{
    assigneeId: string;
    onChange: (userId: string) => void;
    users?: {
        [id: string]: { id: string; title: string };
    };
}> = ({ onChange, users = {}, assigneeId }) => (
    <SelectInput
        label="Task Assignee"
        input={{
            value: assigneeId,
            onBlur: onChange,
        }}
        nameProp="title"
        valueProp="id"
        choices={users}
    />
);
