/* eslint no-fallthrough: 0 */
import list from './list/index';
import { stringify } from 'query-string';
import { ActionType, getType } from 'typesafe-actions';
import { RootAction } from 'actions/rootAction';
import * as listActions from 'sideEffect/crud/getList/actions';
import * as deleteActions from 'sideEffect/crud/delete/actions';
import getResource from './list/getResource';

export type ListAction = ActionType<typeof listActions>;

export interface Lists {
    [filter: string]: ReturnType<ReturnType<typeof list>>;
}
export const getFilterString = (action: ListAction) => {
    const {
        pagination = { page: null, perPage: null },
        sort = { field: 'id', order: 'ASC' },
        filter,
    } = action.type === getType(listActions.crudGetList) ? action.payload : action.requestPayload;
    const filterString = `?${stringify({
        page: pagination.page,
        perPage: pagination.perPage,
        sort: sort.field,
        order: sort.order,
        filter: JSON.stringify(filter, Object.keys(filter).sort()),
    })}`;
    return filterString;
};

export default (resource: string) =>
    (previousState: Lists = {}, action: RootAction): Lists => {
        switch (action.type) {
            case getType(listActions.crudGetListSuccess):
            case getType(listActions.crudGetListFailure):
            case getType(listActions.crudGetList): {
                const actionResource = getResource(action);
                if (actionResource === resource) {
                    const filterString = getFilterString(action);
                    return {
                        ...previousState,
                        [filterString]: list(resource)(previousState[filterString], action),
                    };
                }
                return previousState;
            }
            case getType(deleteActions.crudDeleteSuccess): {
                const actionResource = getResource(action);
                if (actionResource === resource) {
                    return Object.assign(
                        {},
                        ...Object.entries(previousState).map(([key, val]) => ({
                            [key]: list(resource)(val, action),
                        })),
                    );
                }
            }
            default:
                return previousState;
        }
    };
