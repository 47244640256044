import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// import Restricted from './Restricted';
import { View } from '../reducers/ViewConfigType';
import CrudRoute from './CrudRoute';
import { getViewComponent } from '../util/getResourceListFromConfig';

const AdminRoutes: React.SFC<{
    customRoutes: Route[];
    nonDefaultResourceViews: View[];
    resources: any[];
    dashboard: any;
}> = ({ customRoutes, nonDefaultResourceViews, resources = [], dashboard }) => (
    <Switch>
        {customRoutes &&
            customRoutes.map((route, index) => (
                <Route
                    key={index}
                    exact={route.props.exact}
                    path={route.props.path}
                    component={route.props.component}
                    render={route.props.render}
                    children={route.props.children}
                />
            ))}
        {nonDefaultResourceViews
            .filter((f) => f.route)
            .sort((a, b) => (b as { route: string }).route.length - (a as { route: string }).route.length) // longest first
            .map((v) => {
                return (
                    <Route
                        path={(v as { route: string }).route.startsWith('/') ? v.route : `/${v.route}`}
                        exact={true}
                        key={v.name + ':' + v.route}
                        render={getViewComponent(v)}
                    />
                );
            })}
        {resources.map((resource) => (
            <Route
                path={`/${resource.name}`}
                key={resource.name}
                render={() => (
                    <CrudRoute
                        resource={resource.name}
                        list={resource.list}
                        create={resource.create}
                        edit={resource.edit}
                        show={resource.show}
                        options={resource.options}
                        possibleMatchView={resource.possibleMatchView}
                        specificMatchView={resource.specificMatchView}
                    />
                )}
            />
        ))}
        {dashboard ? (
            <Route path="/" render={() => React.createElement(dashboard)} />
        ) : (
            resources[0] && <Route exact={true} path="/" render={() => <Redirect to={`/${resources[0].name}`} />} />
        )}
    </Switch>
);

export default AdminRoutes;
