import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Search from '@material-ui/icons/Search';
import Reorder from 'react-reorder';
import { IconButton, ListItemSecondaryAction, TextField, Tooltip, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import uniqueId from 'lodash/uniqueId';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
        paper: {
            width: '100%',
            minWidth: 230,
            height: 400,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
        sticky: {
            backgroundColor: theme.palette.background.paper,
            zIndex: 100,
            position: 'sticky',
            top: 0,
            alignSelf: 'flex-start',
        },
    }),
);

function not(a: number[], b: number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

interface SortableTransferListProps {
    initialLeft: number[];
    right: number[];
    setRight: (value: number[]) => void;
    getItemTitle: (ix: number) => string;
    EmptyRightMessage: React.ReactNode;
    renderSecondaryAction?: (props: { ix: number }) => React.ReactNode;
    rightItemIsError?: (ix: number) => false | JSX.Element; // string here is error message
    error?: boolean;
}
export default function SortableTransferList({
    right,
    setRight,
    renderSecondaryAction,
    initialLeft,
    getItemTitle,
    EmptyRightMessage,
    error,
    rightItemIsError,
}: SortableTransferListProps) {
    const internalId = React.useMemo(() => uniqueId('SortableTransferList-'), []);
    const classes = useStyles();
    const [checked, setChecked] = React.useState<number[]>([]);
    const [left, setLeft] = React.useState<number[]>(initialLeft);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const [searchOpen, setSearchOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const customList = (items: number[]) => {
        const labelId = `${internalId}-listleftlabel`;
        return (
            <Paper className={classes.paper} style={{ position: 'relative' }}>
                <div className={classes.sticky}>
                    <div style={{ display: 'flex', marginLeft: '1rem', marginTop: '1rem' }}>
                        <Typography id={labelId} style={{ flex: 1 }} variant="h6">
                            Available Columns
                        </Typography>
                        <IconButton
                            color={searchOpen ? 'primary' : 'default'}
                            size="small"
                            aria-label="search"
                            onClick={() => setSearchOpen((open) => !open)}
                        >
                            <Search />
                        </IconButton>
                    </div>
                    {searchOpen && (
                        <span style={{ padding: '.5rem', display: 'flex', justifyContent: 'row-reverse' }}>
                            <TextField
                                fullWidth
                                margin="dense"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                label="Search"
                            />
                        </span>
                    )}
                </div>
                <List id={labelId} dense component="div" role="list">
                    {items
                        .filter((item) => {
                            if (searchOpen && searchText) {
                                return getItemTitle(item).toLowerCase().includes(searchText.toLowerCase());
                            }
                            return true;
                        })
                        .map((value: number) => {
                            const labelId = `${internalId}-right-transfer-list-item-${value}-label`;
                            return (
                                <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={getItemTitle(value)} />
                                </ListItem>
                            );
                        })}
                    <ListItem />
                </List>
            </Paper>
        );
    };

    const sortableListRight = (items: number[]) => {
        const labelId = `${internalId}-listrightlabel`;
        return (
            <Paper
                className={classes.paper}
                style={{ minWidth: 'unset', display: !items?.length ? 'flex' : undefined }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography id={labelId} style={{ marginLeft: '1rem', marginTop: '1rem' }} variant="h6">
                        Selected Columns
                    </Typography>
                    {error && (
                        <Tooltip title="You have errors below">
                            <ErrorIcon
                                fontSize="medium"
                                style={{ marginLeft: '1rem', verticalAlign: 'middle', marginTop: '1.25rem' }}
                                color="error"
                            />
                        </Tooltip>
                    )}
                </div>
                {!items?.length && (
                    <div style={{ display: 'grid', placeItems: 'center', margin: 'auto' }}>
                        {EmptyRightMessage ?? 'Select items from the left'}
                    </div>
                )}
                <List aria-labelledby={labelId} dense component="div" role="list">
                    <Reorder
                        itemKey="name"
                        list={items}
                        template={(props: { item: number }) => {
                            const labelId = `${internalId}-left-transfer-list-item-${props.item}-label`;
                            const isError = rightItemIsError?.(props.item) ?? false;
                            return (
                                <ListItem
                                    ContainerProps={{ role: 'listitem' }}
                                    ContainerComponent="div"
                                    key={props.item}
                                    role="none"
                                    button
                                    onClick={handleToggle(props.item)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            disabled={Boolean(isError)}
                                            checked={checked.indexOf(props.item) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={getItemTitle(props.item)} />
                                    {isError || (
                                        <ListItemSecondaryAction>
                                            {renderSecondaryAction?.({ ix: props.item })}
                                            <DragIndicatorIcon
                                                style={{
                                                    cursor: 'pointer',
                                                    opacity: 0.5,
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                            );
                        }}
                        callback={(event, itemThatHasBeenMoved, itemsPreviousIndex, itemsNewIndex, reorderedArray) => {
                            setRight(reorderedArray);
                        }}
                        selectedKey="tabKey"
                        disableReorder={false}
                        disableDragClass="no-drag"
                    />
                </List>
            </Paper>
        );
    };

    return (
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
            <Grid item>{customList(left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center" style={{ marginTop: '3rem' }}>
                    <Tooltip title="Move all right">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                    </Tooltip>
                    <Tooltip title="Move selected right">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                    </Tooltip>
                    <Tooltip title="Move selected left">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Tooltip>
                    <Tooltip title="Move all left">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid style={{ flex: 1 }} item>
                {sortableListRight(right)}
            </Grid>
        </Grid>
    );
}
