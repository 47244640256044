import { createSelector } from 'reselect';
import {
    FormFieldUnion,
    OptionFormField,
    SimpleFormField,
} from '../../../../fieldFactory/translation/fromFlowable/types/index';
import createDeepEqlSelector from 'components/generics/form/EntityFormContext/util/createDeepEqlSelector';
import { fromNullable, tryCatch } from 'fp-ts/lib/Option';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from '../../../../reducers/taskFormType';
interface GetInitialValuesProps {
    taskId?: string;
    relatedEntityResource?: string;
    relatedEntityId?: string;
}
export const isEntityLinked = (field) => !!(field.params && field.params.entityField);

/* Building initialValues selector */
export const getFormDefinition = (state: RootState, props: GetInitialValuesProps) => state.taskForms[props.taskId];
const getRelatedEntityRecord = (state: RootState, props: GetInitialValuesProps) =>
    props.relatedEntityResource &&
    props.relatedEntityId &&
    (state.admin.entities[props.relatedEntityResource] || {})[props.relatedEntityId];

export const taskClosed = (state: RootState, props: GetInitialValuesProps) =>
    Boolean(props.taskId) &&
    fromNullable(state.admin.entities)
        .mapNullable((e) => e['TaskInstance'])
        .mapNullable((ti) => ti[props.taskId])
        .mapNullable((tf: any) => tf.endTime)
        .fold(false, Boolean);

const expressionIsLiterallyFalse = (exp: string): boolean => {
    // matches ["false"] ["1 == 2"] ["2 == 1"]
    const alwaysFalseExpression = /^\s*(\[\s*")?\s*((2\s*==\s*1)|(false)|(1\s*==\s*2))\s*("\s*\])?\s*$/;
    return alwaysFalseExpression.test(exp);
};
export const parseInitialValue = (field: FormFieldUnion) => {
    if (field.type === 'dropdown' || field.type === 'radio-buttons') {
        if (field.value) {
            const initialOption =
                (field.options || []).find(({ name }) =>
                    typeof field.value === 'string'
                        ? field.value.includes(`name=${name}}`) || field.value === name
                        : field.value.name === name,
                ) || null;
            const visibilityOfInitialOption =
                (initialOption as OptionFormField['options'][any])?.configs?.visibility ?? null;
            if (visibilityOfInitialOption && expressionIsLiterallyFalse(visibilityOfInitialOption)) {
                return null;
            }
            return initialOption;
        }
        return field.hasEmptyValue && field.options && field.options[0] ? field.options[0] : field.value || null;
    } else if (field.type === 'wizard-control') {
        if (!field.value) {
            return 1;
        }
        if (typeof field.value === 'string') {
            return parseInt(field.value);
        }
        if (typeof field.value == 'number') {
            return field.value;
        }
    } else if (field.type === 'integer') {
        if (field.value || field.value === 0) {
            return parseInt(field.value, 10);
        } else {
            return null;
        }
    } else if (field.type === 'decimal') {
        if (field.value || field.value === 0) {
            return parseFloat(field.value);
        } else {
            return null;
        }
    } else if (
        field.type === 'value-set-multi-select' ||
        field.type === 'value-set-multi-checkbox' ||
        field.type === 'entity-multi-select-chip' ||
        field.type === 'multiple-entity-typeahead' ||
        field.type === 'list-view'
    ) {
        if (field.value) {
            // initial value given
            if (typeof field.value === 'string' && field.value.startsWith('[')) {
                return JSON.parse(field.value);
            } else if (Array.isArray(field.value)) {
                return field.value;
            }
        }
    } else if (field.type === 'boolean') {
        return field.value || false;
    } else if (field.type === 'table') {
        return field.value || [];
    }
    return field.value;
};

export const pickBetween =
    (taskField: FormFieldUnion, taskClosed: boolean) => (entityFieldValue: any, taskFieldValue: any) => {
        const lfb = fromNullable(taskField.params)
            .mapNullable((p) => p.configs)
            .mapNullable((c) => c.fieldConfig)
            .chain((fc) => tryCatch(() => JSON.parse(fc)))
            .mapNullable((c) => c.linkedFieldBehavior);
        if (taskClosed) {
            return lfb
                .mapNullable((lfb) => lfb.whenClosed)
                .fold(taskFieldValue, (wd) => (wd === 'unlink-from-entity' ? taskFieldValue : entityFieldValue));
        }
        return lfb
            .mapNullable((lfb) => lfb.whenEditable)
            .fold(
                entityFieldValue, // default use entity field.
                (we) =>
                    we === 'initialize-from-entity'
                        ? entityFieldValue
                        : we === 'initialize-from-taskform-unless-empty' && !taskFieldValue && taskFieldValue !== 0
                        ? entityFieldValue
                        : taskFieldValue,
            );
    };

export const getInitialValuesCombiner = (
    formDefinition: TaskForm,
    relatedEntityRecord?: {},
    taskIsClosed?: boolean,
) => {
    return (
        formDefinition &&
        formDefinition.fields &&
        formDefinition.fields
            .filter((f) => f.type !== 'expression')
            .reduce(
                (formObj, field) => ({
                    ...formObj,
                    [field.id]: isEntityLinked(field)
                        ? pickBetween(field, taskIsClosed)(
                              relatedEntityRecord && relatedEntityRecord[(field as SimpleFormField).params.entityField],
                              parseInitialValue(field),
                          )
                        : parseInitialValue(field),
                }),
                {},
            )
    );
};
export const createGetInitialValues = () => {
    return createDeepEqlSelector(
        createSelector(getFormDefinition, getRelatedEntityRecord, taskClosed, getInitialValuesCombiner),
    );
};

export const createGetStartFormInitialValues = () => {
    return createDeepEqlSelector(
        createSelector((state, props: { formDefinition: TaskForm }) => props.formDefinition, getInitialValuesCombiner),
    );
};
