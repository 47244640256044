import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import { processContext } from 'bpm/components/processContext';
import { createSelector } from 'reselect';

export interface ProcessConfigConfig {
    displayNotes?: string | boolean;
    minimalTaskHeader?: string | boolean;
    hideProcessHeader?: string | boolean;
    hideBackToCaseButton?: string | boolean;
    listActivitiesInline: boolean;
}

export const caseDefinitionSelector = (state: RootState, processId: string) => {
    return fromNullable(state.bpm.processInstances.byId[processId])
        .mapNullable((process) => process.businessKey)
        .mapNullable((bk) => state.bpm.processDefinitions.byId[bk])
        .getOrElse(null);
};

export const createGetProcessConfSelector = () => {
    return createSelector(
        (state: RootState, processId: string) => state.viewConfig,
        caseDefinitionSelector,
        (viewConfig, caseDefinition) => {
            return fromNullable(viewConfig.processes)
                .mapNullable((pis) => caseDefinition && pis[caseDefinition.key])
                .mapNullable((pi) => pi.config)
                .chain(fromPredicate<string>(Boolean))
                .chain((configStr) => tryCatch(() => JSON.parse(configStr) as ProcessConfigConfig));
        },
    );
};
// uses one in context if not provided
export const useGetProcessConf = (processId?: string) => {
    const { id: _processId } = React.useContext(processContext);
    const getProcessConfSelector = React.useMemo(createGetProcessConfSelector, []);
    const conf = useSelector((state: RootState) => getProcessConfSelector(state, processId || _processId));
    return conf;
};
