import { useEffect, useRef, useContext } from 'react';
import { formSaveNotifierContext } from '../Provider';
import uniqueId from 'lodash/uniqueId';
interface FormSaveNotifierTriggerProps {
    when?: boolean;
}

const FireOnWhenChange = (props: FormSaveNotifierTriggerProps) => {
    const componentIdRef = useRef(uniqueId('formSaveNotif-trigger'));
    const dispatch = useContext(formSaveNotifierContext);
    const { when } = props;
    useEffect(() => {
        const { current: formId } = componentIdRef;
        return () => {
            dispatch({
                type: 'dec',
                formId,
            });
        };
    }, []); // eslint-disable-line
    useEffect(() => {
        const { current: formId } = componentIdRef;
        if (when) {
            dispatch({
                type: 'inc',
                formId,
            });
        } else {
            dispatch({
                type: 'dec',
                formId,
            });
        }
    }, [when, dispatch]);
    return null;
};
export default FireOnWhenChange;
