import React, { useRef, useState, useMemo, useContext } from 'react';
import { createCurrentlySelectedEntitiesSelector, MultipleEntityTypeaheadCommonProps, useStyles } from './';
import { RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import Downshift from 'downshift';
import { FieldTitle } from '../../aor/FieldTitle';
import {
    Paper,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
    Input as StandardInput,
    FormHelperText,
    FilledInput,
    OutlinedInput,
    InputBaseProps,
} from '@material-ui/core';
import uniq from 'lodash/uniq';
import { getPluralName } from 'components/generics/utils/viewConfigUtils';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import useTextFieldUtils from 'fieldFactory/input/hooks/useTextFieldUtils';
import OfflineDropdown from '../OfflineDropdown';

const OfflineMultipleEntityTypeahead: React.FC<MultipleEntityTypeaheadCommonProps> = (props) => {
    const {
        label,
        randomizeNameAsBrowserAutocompleteHack = true,
        input: { value = [], onBlur },
        meta,
        disabled,
        renderLabel = true,
        emptyText = 'None Selected',
        isPopover,
        reference,
        dropdownPosition = 'below',
        source,
        allowEmptyQuery,
        filterString,
    } = props;
    const { touched, error } = meta;
    const uniqueNameToThrowOffChromeAutoFill = useRef(new Date().toISOString());
    const classes = useStyles(props);
    const refEntityDisplayNamePlural = useSelector((state: RootState) => getPluralName(state.viewConfig, reference));
    const getcurrentlySelectedEntities = useMemo(createCurrentlySelectedEntitiesSelector, []);
    const currentlySelectedEntities = useSelector((state: RootState) =>
        getcurrentlySelectedEntities(state, { reference, value }),
    );
    const errorMessageId = useRef(uniqueId('entity-typeahead'));
    const [inputValue, setInputValue] = useState('');
    const paperClass = dropdownPosition === 'below' ? classes.paper : classes.paperTop;
    const filterObject = getFilterFromFilterString(filterString);

    const { forceLabelShrink, fieldVariant } = useContext(themeOverrideContext);
    const Input = fieldVariant === 'filled' ? FilledInput : fieldVariant === 'outlined' ? OutlinedInput : StandardInput;
    const { InputPropsClasses, createInputLabelProps, createFormHelperTextProps, muiErrorProp, helperText } =
        useTextFieldUtils(meta);

    return (
        <EntityInspect
            reference={reference}
            formId={`frommultipleentitytypeahead ${source}`}
            renderComponent={(args) => (
                <div className={classes.root}>
                    <Downshift
                        inputValue={inputValue}
                        stateReducer={(state, changes) => {
                            if (changes.type === '__autocomplete_click_item__') {
                                return {
                                    ...changes,
                                    isOpen: true,
                                };
                            }
                            return changes;
                        }}
                        selectedItem={currentlySelectedEntities || []}
                        onSelect={(record, ds) => {
                            setInputValue('');
                            const newValue = uniq([...value, record.id]);
                            onBlur(newValue);
                        }}
                        itemToString={(record) => record.title}
                    >
                        {({
                            inputValue,
                            getInputProps,
                            getLabelProps,
                            getMenuProps,
                            getItemProps,
                            setItemCount,
                            clearItems,
                            selectedItem,
                            highlightedIndex,
                            isOpen,
                            getRootProps,
                            openMenu,
                            clearSelection,
                        }) => {
                            const InputProps = getInputProps(
                                (() => {
                                    const _inputProps = {
                                        'aria-errormessage': touched && error ? errorMessageId.current : undefined,
                                        placeholder: selectedItem ? selectedItem.title : emptyText,
                                        disabled,
                                        style: {
                                            textOverflow: 'ellipsis',
                                            marginRight: '60px',
                                        },
                                        onChange: (e) => {
                                            setInputValue(e.target.value);
                                        },
                                        onFocus: () => openMenu(),
                                        onClick: () => openMenu(),
                                        autoComplete: 'never',
                                    };
                                    if (randomizeNameAsBrowserAutocompleteHack) {
                                        _inputProps['name'] = uniqueNameToThrowOffChromeAutoFill.current;
                                    }
                                    return _inputProps;
                                })(),
                            );
                            return (
                                <FormControl
                                    variant={fieldVariant}
                                    key={fieldVariant}
                                    fullWidth={true}
                                    margin="none"
                                    error={muiErrorProp}
                                    disabled={disabled}
                                >
                                    {renderLabel && (
                                        <InputLabel
                                            {...createInputLabelProps()}
                                            focused={false}
                                            shrink={forceLabelShrink}
                                            {...getLabelProps()}
                                        >
                                            <FieldTitle label={label} isRequired={false} />
                                        </InputLabel>
                                    )}
                                    <div className={classes.chipArea}>
                                        {currentlySelectedEntities.map(({ title, id }) => (
                                            <Chip
                                                key={id}
                                                tabIndex={0}
                                                aria-roledescription="Button Press Delete key to delete"
                                                label={title || id}
                                                className={classes.chip}
                                                onClick={() => args.selectId(id)}
                                                onDelete={() => {
                                                    onBlur(value.filter((lid) => lid !== id));
                                                }}
                                                deleteIcon={disabled ? <span style={{ width: '.5em' }} /> : undefined}
                                            />
                                        ))}
                                    </div>
                                    <div {...getRootProps()} className={classes.container}>
                                        <Input
                                            fullWidth={true}
                                            classes={{
                                                ...InputPropsClasses,
                                                root: classnames(InputPropsClasses.root, classes.inputRoot),
                                                underline: classnames(
                                                    InputPropsClasses.underline,
                                                    classes.inputUnderline,
                                                ),
                                            }}
                                            inputProps={{
                                                ...InputProps,
                                                'aria-label': label,
                                            }}
                                            {...(InputProps as any)}
                                        />
                                        {muiErrorProp && (
                                            <FormHelperText
                                                error={muiErrorProp}
                                                {...createFormHelperTextProps(InputProps as InputBaseProps)}
                                            >
                                                {helperText}
                                            </FormHelperText>
                                        )}
                                        <div {...getMenuProps()}>
                                            {isOpen && (
                                                <Paper
                                                    className={
                                                        isPopover
                                                            ? classnames(paperClass, classes.popoverPaper)
                                                            : paperClass
                                                    }
                                                    square={true}
                                                >
                                                    {(() => {
                                                        if (!inputValue && !allowEmptyQuery) {
                                                            return (
                                                                <MenuItem component="div" aria-live="polite" disabled>
                                                                    You have to enter a search query
                                                                </MenuItem>
                                                            );
                                                        }

                                                        return (
                                                            <OfflineDropdown
                                                                resource={reference}
                                                                selectedItem={selectedItem}
                                                                highlightedIndex={highlightedIndex}
                                                                refEntityDisplayNamePlural={refEntityDisplayNamePlural}
                                                                getItemProps={getItemProps}
                                                                inputValue={inputValue}
                                                                filter={filterObject}
                                                                setItemCount={setItemCount}
                                                            />
                                                        );
                                                    })()}
                                                </Paper>
                                            )}
                                        </div>
                                    </div>
                                </FormControl>
                            );
                        }}
                    </Downshift>
                </div>
            )}
        />
    );
};

export default OfflineMultipleEntityTypeahead;
