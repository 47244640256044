import React from 'react';
import { useEffect, useState } from 'react';
import detailsRegistry, { Details } from './registry';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { saveAs } from 'file-saver';
import Alert from '@material-ui/lab/Alert/Alert';

const IssuesAlert = () => {
    const [details, setDetails] = useState<Details[]>();
    useEffect(() => {
        const cb = (allDetails: Details[]) => {
            setDetails(allDetails);
        };
        detailsRegistry.subscribe(cb);
        return () => {
            detailsRegistry.unsubscribe(cb);
        };
    }, [setDetails]);
    if (!details || details.length === 0) {
        return null;
    }
    return (
        <Alert severity="error">
            A sensitive refactoring is in progress, and {details.length} issues have been discovered. Please{' '}
            <button
                onClick={() => {
                    saveAs(new Blob([JSON.stringify(details, null, 2)], { type: 'application/json' }), 'details.json');
                }}
                className="casetivity-linkbutton"
            >
                Download
            </button>{' '}
            or{' '}
            <CopyToClipboard text={JSON.stringify(details)}>
                <button className="casetivity-linkbutton">copy</button>
            </CopyToClipboard>{' '}
            the details and inform the devs.
        </Alert>
    );
};

const IssuesAlertBanner = () => {
    return (
        <>
            <div style={{ position: 'sticky', left: 0, right: 0, top: 0, zIndex: 1000 }}>
                <IssuesAlert />
            </div>
        </>
    );
};

export default IssuesAlertBanner;
