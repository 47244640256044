import * as React from 'react';
import { useData } from 'fieldFactory/offline/XMany';
import useViewConfig from 'util/hooks/useViewConfig';
import { Button, Divider, IconButton } from '@material-ui/core';
import { Field, touch, untouch } from 'redux-form';
import Add from '@material-ui/icons/Add';
import WrappedSimpleForm from 'components/generics/genericCreate/SimpleForm2';
import deepEql from 'deep-eql';
import Clear from '@material-ui/icons/Clear';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import { useSelector, useStore } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import formTypeContext from 'components/generics/form/formTypeContext';
import { useInlineBackrefsToParent } from 'fieldFactory/display/components/RefmanyMultiselect/util/useInlineBackrefsToParent';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import { useBackrefProperties } from 'components/generics/form/EntityFormContext/util/createBackrefSelector';
import { getAccessLevelForEntityField } from 'components/generics/utils/viewConfigUtils';

const useCallWhenSubmitSucceeds = (meta, cb: () => void) => {
    const submitState = useSelector((state: RootState) => state.entityFormSubmissionState[meta.form]);
    const prevSubmitState = React.useRef(submitState);
    React.useEffect(() => {
        const shouldCall = prevSubmitState.current === 'submitting' && submitState === 'submitted';
        prevSubmitState.current = submitState;
        if (shouldCall) {
            cb();
        }
    }, [cb, submitState]);
};

interface InlineDatagridProps {
    source: string;
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    filter?: string;
    overrideViewName?: string;
    referencedByField?: string;
    handleSelectExistingRecord: (id: string) => void;
    showCreate?: boolean;
    disabled?: boolean;
    useCreateView?: boolean;
    createViewName?: string;
    renderNoData?: () => JSX.Element;
}

interface InlineDatagridFieldProps extends InlineDatagridProps {
    input: {
        onChange: (value: {}[]) => void;
        value?: {}[];
    };
    meta?: {
        submitFailed?: boolean;
        form?: string;
    };
}

const combineIntoFormValue = (dataRows: any[], addedRows: { _key: string; __touched?: boolean }[]) => {
    const value = [...dataRows.map(({ id }) => ({ id })), ...addedRows.map(({ _key, __touched, ...rest }) => rest)];
    return value;
};

const useBackrefIfNoParent = (referencedByField: string) => {
    const fc = useCurrentFormContext();
    const evaledBackref = useBackrefProperties(fc['initialValues'] ?? null);
    const pathBack = React.useMemo(() => {
        if (evaledBackref && referencedByField) {
            const pathToAddToExistingBackref = referencedByField.endsWith('.id')
                ? referencedByField.slice(0, -3)
                : (() => {
                      console.error('unexpected referencedByField: ' + referencedByField);
                      return null;
                  })();
            if (pathToAddToExistingBackref) {
                return {
                    ...evaledBackref,
                    path: [pathToAddToExistingBackref, evaledBackref.path].join('.'),
                };
            }
        }
        return null;
    }, [referencedByField, evaledBackref]);
    return pathBack;
};

function InlineCreateList({
    id,
    source,
    rootEntityType,
    resource,
    filter,
    input: { value, onChange },
    disabled,
    renderNoData,
    createViewName,
    referencedByField,
    meta,
}: InlineDatagridFieldProps) {
    const backrefsIfId = useInlineBackrefsToParent({
        parentEntityName: rootEntityType,
        parentId: id,
        endWith: '.id',
        linkedEntityFormat: 'linked<entityType>',
        source,
    });

    const backrefsIfNoId = useBackrefIfNoParent(referencedByField);
    const backrefs =
        backrefsIfId ??
        (() => {
            // even if we don't know our current record's id, it might be because we are in a CREATE view.
            // in that case, we can check the secret value in our initialValues which passes that data along from any possible ancestor views
            if (!backrefsIfNoId) return null;
            const { entityType, path, id } = backrefsIfNoId;
            return {
                parentEntityName: entityType,
                parentFieldInChild: path,
                parentId: id,
            };
        })();

    const data = useData(rootEntityType, source, id, filter);
    const rows = React.useMemo(() => Object.values(data) as any[], [data]);
    const viewConfig = useViewConfig();
    // TODO: support createViewName
    const inlineCreateViewName = createViewName || viewConfig.entities[resource]?.defaultViews?.CREATE?.name;

    const initialValue = React.useMemo(() => {
        if (Array.isArray(value)) {
            return value.filter((e) => !e['id']).map((e) => ({ ...e, _key: uuidv4() }));
        }
        return [];
    }, [value]);

    const [additionalRows, setAdditionalRows] = React.useState<{ _key: string; __touched?: boolean }[]>(initialValue);

    const resetAdditionalRows = React.useCallback(() => {
        setAdditionalRows([]);
    }, []);

    useCallWhenSubmitSucceeds(meta, resetAdditionalRows);

    const initialExpectedOuterValue = React.useMemo(() => {
        const value = combineIntoFormValue(rows, additionalRows);
        return value;
    }, []); // eslint-disable-line
    const expectedOuterValue = React.useRef<any[]>(initialExpectedOuterValue);

    const accessLevel = React.useMemo(() => {
        return getAccessLevelForEntityField(viewConfig, rootEntityType, source, 'TRAVERSE_PATH');
    }, [viewConfig, rootEntityType, source]);

    React.useEffect(() => {
        const newValue = combineIntoFormValue(rows, additionalRows);
        if (isEqual(new Set(newValue), new Set(expectedOuterValue.current))) {
            return;
        }
        expectedOuterValue.current = newValue;
        onChange(newValue);
    }, [rows, additionalRows, onChange]);

    const submitFailed = meta?.submitFailed;
    const store = useStore();

    const someRowTouched = additionalRows?.some((r) => r?.['__touched']);
    React.useEffect(() => {
        if (someRowTouched) {
            store.dispatch(touch(meta.form, source));
        } else {
            store.dispatch(untouch(meta.form, source));
        }
    }, [someRowTouched, store, meta.form, source]);
    const additionalRowElements = React.useMemo(() => {
        return (
            <ul style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
                {additionalRows.map((r, i) => (
                    <li key={r._key} style={{ marginTop: '1em' }}>
                        {/* Final-form embedded create form. */}
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <formTypeContext.Provider value="CREATE">
                                        <WrappedSimpleForm
                                            parentEntityIdValue={backrefs?.parentId}
                                            parentEntityName={backrefs?.parentEntityName}
                                            parentField={backrefs?.parentFieldInChild}
                                            outerSubmitFailed={submitFailed}
                                            viewName={inlineCreateViewName}
                                            formId={(source + ':tempadd:' + r._key).split('.').join('_~_')}
                                            // can we just subscribe here, somehow?
                                            save={(data) => {}}
                                            subscribe={(_values) => {
                                                const values = { ..._values, _key: r._key };

                                                if (deepEql(values, additionalRows[i])) {
                                                    return;
                                                }
                                                setAdditionalRows([
                                                    ...(i > 0 ? additionalRows.slice(0, i) : []),
                                                    values,
                                                    ...(i !== additionalRows.length - 1
                                                        ? additionalRows.slice(i + 1)
                                                        : []),
                                                ]);
                                            }}
                                            toolbar={<div />}
                                        />
                                    </formTypeContext.Provider>
                                </div>
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            setAdditionalRows([
                                                ...additionalRows.slice(0, i),
                                                ...additionalRows.slice(i + 1),
                                            ]);
                                        }}
                                        aria-label={'Delete new row ' + (i + 1)}
                                        size="small"
                                    >
                                        <Clear color="error" />
                                    </IconButton>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    </li>
                ))}
            </ul>
        );
    }, [additionalRows, source, inlineCreateViewName, submitFailed, backrefs]);

    return (
        <div>
            {!additionalRows?.length && renderNoData?.()}
            {!disabled && Boolean(additionalRows?.length) && additionalRowElements}
            {disabled || accessLevel <= 2 ? null : (
                <Button
                    color="primary"
                    endIcon={<Add />}
                    onClick={() => {
                        setAdditionalRows([...additionalRows, { _key: uuidv4() }]);
                    }}
                >
                    Add Row
                </Button>
            )}
        </div>
    );
}

const renderField = (props) => {
    return <InlineCreateList {...props} />;
};
const InlineCreateListField = (props: InlineDatagridProps) => {
    return <Field {...props} name={props.source} component={renderField} />;
};

export default InlineCreateListField;
