import { createCustomAction } from 'typesafe-actions';
import {
    USER_LOGIN,
    USER_LOGIN_FAILURE,
    USER_LOGIN_WITH_MFACODE,
    USER_LOGIN_MFA_REQUIRED,
    CLEAR_WAITING_FOR_MFA,
    USER_LOGIN_SUCCESS,
} from './constants';
import { AuthPayload, AuthResponse, MfaAuthPayload } from './definitions';
import { AjaxError } from 'rxjs/ajax';

export const login = createCustomAction(USER_LOGIN, (type) => {
    return (payload: AuthPayload, errorCb: (error: AjaxError) => boolean, redirectTo?: string) => ({
        type,
        payload,
        errorCb,
        redirectTo,
    });
});

export const loginSuccess = createCustomAction(USER_LOGIN_SUCCESS, (type) => {
    return () => ({
        type,
    });
});

export const loginFailure = createCustomAction(USER_LOGIN_FAILURE, (type) => {
    return (error: Error) => ({
        type,
        error,
    });
});

// signal to app Mfa is required to log in. Sets mfa details in reducer.
export const loginMfaRequired = createCustomAction(USER_LOGIN_MFA_REQUIRED, (type) => {
    return (response: AuthResponse, redirectTo?: string) => ({
        type,
        response,
        redirectTo,
    });
});

export const loginWithMfaCode = createCustomAction(USER_LOGIN_WITH_MFACODE, (type) => {
    return (payload: MfaAuthPayload, errorCb: (error: AjaxError) => boolean, redirectTo?: string) => ({
        type,
        payload,
        redirectTo,
        errorCb,
    });
});

export const loginWithMfaCodeSuccess = createCustomAction(USER_LOGIN_SUCCESS, (type) => {
    return () => ({
        type,
    });
});
export const clearWaitingForMfa = createCustomAction(CLEAR_WAITING_FOR_MFA, (type) => {
    return () => ({
        type,
    });
});
