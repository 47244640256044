import React from 'react';
import { EventConfiguration } from './types';
import { EntityEventField, FlowableEventField } from '../../../translation/types';
import { isFieldFromFlowable } from '../../fieldUtils';
import { BadConfig } from '../Address/util';
import decodeEventConfig from './util/decodeEventConfig';
import Event from './Event';

const getEventFieldGroup = (propConfiguration, fieldDefinition: EntityEventField | FlowableEventField, liveProps) => {
    try {
        const configStr: string | undefined = isFieldFromFlowable(fieldDefinition)
            ? fieldDefinition.params.event.configs
            : fieldDefinition.config;
        if (!configStr) {
            throw new Error('Event widget configuration is empty.');
        }
        const parsedConfig: EventConfiguration = decodeEventConfig(configStr, 'THROW_ERROR');
        return (
            <Event
                {...propConfiguration}
                /* source={} */
                /* validate={} */
                key={`${propConfiguration.source}-${propConfiguration.label}`}
                addField={true}
                config={parsedConfig}
                {...liveProps}
            />
        );
    } catch (e) {
        return (
            <BadConfig
                data-originaldefinition={propConfiguration['data-originaldefinition']}
                title={'Bad Configuration (Entity)'}
                e={e}
            />
        );
    }
};

export default getEventFieldGroup;
