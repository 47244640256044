import { IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Popup from 'components/Popup';
import React from 'react';
import { TransferChangesPayload } from './domain';
import TransferChanges from './TransferChanges';

interface TransferChangesPopupProps {
    renderToggler: (args: { openDialog: (optionalData: TransferChangesPayload) => () => void }) => JSX.Element;
}
const TransferChangesPopup: React.FC<TransferChangesPopupProps> = (props) => {
    return (
        <Popup<TransferChangesPayload>
            ComponentProps={{
                fullWidth: true,
            }}
            renderDialogContent={({ closeDialog, optionalData }) => {
                return (
                    <div>
                        <IconButton
                            size="small"
                            style={{ float: 'right', marginRight: '1em' }}
                            aria-label="close"
                            onClick={closeDialog}
                        >
                            <Clear />
                        </IconButton>
                        <TransferChanges {...optionalData} />
                    </div>
                );
            }}
            renderToggler={props.renderToggler}
        />
    );
};

export default TransferChangesPopup;
