import * as potentialUsers from './actions';
import { getType } from 'typesafe-actions';
import { initial, failure, pending, success } from '@devexperts/remote-data-ts';
import { ErrorDetails } from 'sagas/util/sagaFactory';
import orderBy from 'lodash/orderBy';
import { RootAction } from 'actions/rootAction';
import { deserialize, serialize, serialrd } from 'reducers/lists/list/serializeDeserialize';
import { AllPotentialUsers } from './types';

type PotentialUsersState = serialrd<AllPotentialUsers, ErrorDetails>;
const reducer = (state: PotentialUsersState = serialize(initial), action: RootAction): PotentialUsersState => {
    switch (action.type) {
        case getType(potentialUsers.getPotentialUsers):
            if (deserialize(state).isInitial()) {
                return serialize(pending);
            }
            // we only fetch once.
            return state;
        case getType(potentialUsers.getPotentialUsersFailure):
            return serialize(failure(JSON.parse(JSON.stringify(action.error))));
        case getType(potentialUsers.getPotentialUsersSuccess):
            const sorted = orderBy(action.payload, [(u) => (u.title || '').toLowerCase()], ['asc']);
            const logins = sorted.map((s) => s.login);
            const byLogin = {};
            sorted.forEach((potentialUser) => {
                byLogin[potentialUser.login] = potentialUser;
            });
            const byId = {};
            sorted.forEach((potentialUser) => {
                byId[potentialUser.id] = potentialUser;
            });
            return serialize(
                success({
                    byLogin,
                    logins,
                    byId,
                }),
            );
        default:
            return state;
    }
};
export default reducer;
