import { DeepPartial } from 'redux';
import { createBrowserHistory } from 'history';
import { RootState } from 'reducers/rootReducer';
import getRootReducer from 'reducers/appReducer';
import mapResourceToAdminProperty from 'util/mapResourceToAdminProperty';
import getResourcesFromViewConfig from 'util/getResourceListFromConfig';
import ViewConfig from 'reducers/ViewConfigType';

import { EntityValidations } from 'reducers/entityValidationsReducer';
import { SearchValidations } from 'reducers/searchValidationsReducer';
import { ViewEditableExps } from 'reducers/entityEditabilityReducer';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { REPLACE_STATE, CLEAR_STORE, CLEAR_STORE_BUT_KEEP_LOCATION } from 'actions/constants';
import initializeConceptExps from 'viewConfigCalculations/ConceptAvailabilityExpressions/initialize';
import initializeActionButtonExps from 'viewConfigCalculations/actionButtonDisplayExpressions/initialize';
import initializeFilterExps from 'viewConfigCalculations/filterExpressions/initialize';
import initializeTemplateExps from 'viewConfigCalculations/expressionTemplates/initialize';
import { getStorage } from 'storage/storage';
import { fromNullable } from 'fp-ts/lib/Option';
import { getVisibilityExps } from 'sagas/getEntityVisibilityExpsFromViewConfig';
import { overwriteViewsWithViewDefs } from 'util/hooks/useViewConfig';
import modifyViewConfig from 'modifyViewConfig/modifyViewConfig';
import { ViewValidations } from 'reducers/viewValidationsReducer';
import { Locale } from 'i18n/store/domain';
import injectMrgListViewsFromMatchViews from 'injectViews/injectMatchViewsFromMrgViews';

export const writeViewDefsToVC = (vc: ViewConfig): ViewConfig => {
    const res = overwriteViewsWithViewDefs(vc);
    // we need to generate the corresponding MrgListViews from viewDef-configured MATCH views.
    const withMrgViewsGeneratedFromViewDefsMatchViews = injectMrgListViewsFromMatchViews(res);
    return withMrgViewsGeneratedFromViewDefsMatchViews;
};

export const getAnonViewConfig = () => {
    const b = fromNullable((window as any).CASETIVITY_ANON_VIEW_CONFIG)
        .map(JSON.parse)
        .map(writeViewDefsToVC)
        .map((vc) => modifyViewConfig(vc))
        .toNullable();
    return b;
};

export const getInitialState = (): DeepPartial<RootState> | undefined => {
    const serializedVC = getStorage().getItem('viewconfig');
    let svc: ViewConfig | null = getAnonViewConfig();
    if (serializedVC) {
        svc = JSON.parse(serializedVC);
        svc = writeViewDefsToVC(svc);
    }
    let profiles = (() => {
        let profilesJson = getStorage().getItem('casetivity-profiles');
        if (profilesJson) {
            return JSON.parse(profilesJson);
        }
        return undefined;
    })();

    const serializedEntityValidations = getStorage().getItem('entityValidations');
    let entityVals: EntityValidations = {};
    if (serializedEntityValidations) {
        entityVals = JSON.parse(serializedEntityValidations);
    }
    const serializedViewValidations = getStorage().getItem('viewValidations');
    let viewVals: ViewValidations = {};
    if (serializedViewValidations) {
        viewVals = JSON.parse(serializedViewValidations);
    }

    const serializedSearchValidations = getStorage().getItem('searchValidations');
    let searchVals: SearchValidations = {};
    if (serializedSearchValidations) {
        searchVals = JSON.parse(serializedSearchValidations);
    }

    const serializedEntityVisibility = getStorage().getItem('entityVisibility');
    const entityVis: EntityVisibilityExps = serializedEntityVisibility
        ? JSON.parse(serializedEntityVisibility)
        : svc
        ? getVisibilityExps(svc)
        : {};

    const serializedEditabilityExpressions = getStorage().getItem('entityEditability');
    let entityEditabilityExps: ViewEditableExps = {};
    if (serializedEditabilityExpressions) {
        entityEditabilityExps = JSON.parse(serializedEditabilityExpressions);
    }
    const locale: Locale = (getStorage().getItem('locale') as Locale) || 'en';

    let reloadedResources: {}[] = [];
    if (svc) {
        reloadedResources = getResourcesFromViewConfig(svc);
    }
    return svc
        ? {
              profiles,
              locale,
              viewConfig: svc || {},
              admin: {
                  resources: Object.assign({}, ...reloadedResources.map((r) => mapResourceToAdminProperty(r))),
              },
              entityValidations: entityVals,
              viewValidations: viewVals,
              entityEditability: entityEditabilityExps,
              searchValidations: searchVals,
              entityVisibility: entityVis,
              entityConceptExps: initializeConceptExps(),
              actionButtonExps: initializeActionButtonExps(),
              viewItemFilterExps: initializeFilterExps(),
              templateExps: initializeTemplateExps(),
          }
        : undefined;
};
// export const initialS: DeepPartial<RootState> | undefined = getInitialState();

export const routerHistory = createBrowserHistory({
    basename: (window as any).CASETIVITY_SERVER_CONTEXT_ROOT || '/',
});
const rootReducer = getRootReducer(routerHistory);

const resettableAppReducer = (state: RootState, action) =>
    rootReducer(
        action.type === CLEAR_STORE_BUT_KEEP_LOCATION
            ? {
                  router: state.router,
                  basicInfo: state.basicInfo,
                  form: state.form,
                  globalAlerts: state.globalAlerts,
                  viewConfig: getAnonViewConfig() || {},
              }
            : action.type === CLEAR_STORE
            ? { basicInfo: state.basicInfo, viewConfig: getAnonViewConfig() || {} }
            : action.type === REPLACE_STATE
            ? { ...action.payload, debugMode: state.debugMode, viewStack: state.viewStack }
            : state,
        action,
    );

export default resettableAppReducer;
