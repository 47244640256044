import React, { useEffect, useMemo, useState } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import GenericEdit from 'components/generics/genericEdit/index2';
import Toolbar from 'components/generics/form/Toolbar.aor';
import CloseButton from 'fieldFactory/popovers/PopoverCloseButton';
import SaveButton from 'components/generics/button/SaveButton';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import flatten from 'flat';
import { UpdateParams } from 'sideEffect/crud/update/actions';
import moment from 'moment';
import { useRecord } from 'components/generics/genericCreate/useRecord';
import { ParentBackRefProperties } from 'components/generics/genericCreate/ParentBackRefProperties';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';
import isOffline from 'util/isOffline';
interface OfflineEditViewProps {
    resource: string;
    id: string;
    onClose: () => void;
    viewName?: string;
    initialData?: {};
    // can I override entities in a certain context? like override 'useEntities'? and provide that to the merge component?
    // might have to rewrite the merge view mechanism/wrapping component.
    editsToReview?: {};
    interceptSubmit?: (params: UpdateParams) => void;
    backref?: ParentBackRefProperties;
}
const OfflineEditView: React.FC<OfflineEditViewProps> = ({
    editsToReview,
    initialData,
    resource,
    id,
    onClose,
    viewName,
    interceptSubmit,
    backref,
}) => {
    const formId = useMemo(() => {
        return 'offline-merge-edit' + id.replace('.', '_') + ':' + Date.now();
    }, [id]);
    const viewConfig = useViewConfig();
    const defaultViewName = viewConfig.entities[resource]?.defaultViews?.EDIT?.name;
    const dispatch = useDispatch();
    const [shouldUseInitialOverride, setUseInitialOverride] = useState(true);
    const [mountReady, setMountReady] = useState(false);
    const [visibleReady, setVisibleReady] = useState(false);
    useEffect(() => {
        if (!mountReady) {
            return;
        }
        if (editsToReview) {
            let to2;
            let to = setTimeout(() => {
                const edits = flatten(editsToReview);
                Object.entries(edits).forEach(([k, v]) => {
                    dispatch(change(formId, k, v, true));
                });
                to2 = setTimeout(() => {
                    setUseInitialOverride(false);
                    setVisibleReady(true);
                }, 400);
            }, 100);
            return () => {
                clearTimeout(to);
                if (to2) {
                    clearTimeout(to2);
                }
            };
        } else {
            setVisibleReady(true);
        }
    }, [editsToReview, dispatch, mountReady, formId]);
    const injectSubmissionValues = useRecord(backref);

    /*
        To avoid a race condition with the above timeouts, we should fetch the record OURSELVES,
        then render the below once we are sure we have the updated remote data loaded.
    */
    const view = viewName ?? defaultViewName;
    useEffect(() => {
        if (isOffline()) {
            setMountReady(true);
            return;
        }
        if (moment(id).isValid()) {
            // is pending creation.
            setMountReady(true);
            return;
        }
        dispatch(
            crudGetOne({
                id,
                resource,
                view,
                cb: (id, data) => {
                    setMountReady(true);
                },
                errorsCbs: {
                    '*': () => {
                        // Edit view has error handling so lets just show it.
                        setMountReady(true);
                    },
                },
            }),
        );
    }, [id, resource, view, setMountReady, dispatch]);
    if (!mountReady) {
        return null;
    }
    return (
        <div style={!visibleReady ? { visibility: 'hidden' } : undefined}>
            <GenericEdit
                injectSubmissionValues={injectSubmissionValues}
                backref={backref}
                // used to only fetch for EDIT (if create, don't even try to fetch - the id is the offline 'creation' date.)
                // noFetch={moment(id).isValid()}
                // now that we guarantee the data is loaded by fetching beforehand to force the merge to work,
                // just never fetch ever anymore.
                noBaseRecordFetch
                noListsFetch={!isOffline() && moment(id).isValid()}
                overrideInitialValues={shouldUseInitialOverride ? initialData : undefined}
                interceptSubmit={interceptSubmit}
                noRedirectOnIdChange={true}
                formId={formId}
                actions={<div />}
                redirect={false}
                viewName={view}
                onSaveCb={() => {
                    // interceptSubmit handles everything for us.
                }}
                resource={resource}
                id={id}
                toolbar={
                    <Toolbar>
                        <SaveButton />
                        <CloseButton handleClose={onClose} />
                    </Toolbar>
                }
            />
        </div>
    );
};
export default OfflineEditView;
