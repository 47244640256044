import memoizeOne from 'memoize-one';
import ViewConfig from 'reducers/ViewConfigType';
import { getStorage } from 'storage/storage';

const parseOnce = memoizeOne((json: string) => {
    return JSON.parse(json);
});

export const getViewConfigFromStorage = (): ViewConfig | null => {
    const vcs = getStorage().getItem('viewconfig');
    if (vcs) {
        let vc: ViewConfig = parseOnce(vcs);
        return vc;
    }
    return null;
};
