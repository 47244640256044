import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';

export const parse = getNextBracketedExpression({
    bracketClosesOn: ']',
    bracketNaivelyOpensOn: '[',
    bracketOpensOn: '$[',
});

export const accumulateExpressions = (template: string, _parse = parse) => {
    const expressions: string[] = [];
    const evalString = (str: string) =>
        _parse(str).fold(str, ({ before, inner, after }) => {
            expressions.push(inner);
            evalString(after);
            return '';
        });
    evalString(template);
    return expressions;
};
