import { put, takeEvery, call, select } from 'redux-saga/effects';
import { fetchJson } from '../util/fetchUtils';
import { FETCH_START, FETCH_END, FETCH_ERROR } from 'actions/aor/types';
import { RootState } from '../reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { MULTIPLE_VALUE_SETS_NOT_RECEIVED } from '../actions/constants';
import buildFetchOptions from './util/buildFetchOptions';
import { getUrl } from 'sideEffect/services';
import { multipleValuesetsReceived, loadValueSets } from 'valueSets/actions';
import { getTransformConcept } from 'valueSets/transform';
import { ValueSetFromMultipleVsEndpoint } from 'valueSets/domain';
import { getType } from 'typesafe-actions';

export function* loadValueSetGroup(action: ReturnType<typeof loadValueSets>) {
    const { valuesets } = action.payload;
    const valuesetsToFetch: { valueSet: string; group?: string }[] = yield select(
        (
            state: RootState,
        ): {
            valueSet: string;
            group?: string;
        }[] =>
            valuesets.filter(({ valueSet, group }) =>
                fromNullable(state.valueSets[valueSet]).fold(
                    true,
                    (vs) =>
                        vs.invalid ||
                        (group && fromNullable(vs.groups[group]).fold(true, (vsgroup) => vsgroup.invalid)),
                ),
            ),
    );
    if (valuesetsToFetch.length === 0) {
        return;
    }
    yield put({ type: FETCH_START });
    try {
        const options = buildFetchOptions();
        // e.g. /api/value-set?values=TestConceptOne.G2,TestConceptTwo,TestConceptOne.G1,
        // TestConceptOne,FieldDataType&includeInActive=true
        const { status, body } = yield call(
            fetchJson,
            getUrl(
                `api/value-set?values=${valuesetsToFetch
                    .map(({ valueSet, group }) => (group ? `${valueSet}.${group}` : valueSet))
                    .join(',')}&includeInActive=true`,
            ),
            options,
        );
        if (status === 200) {
            const response: ValueSetFromMultipleVsEndpoint[] = JSON.parse(body);
            const receivedAction = multipleValuesetsReceived(
                valuesetsToFetch,
                {
                    response,
                    data: {
                        entities: {
                            Concept: (() => {
                                const concepts = {};
                                response.forEach((vs) => {
                                    vs.concepts.forEach((c) => {
                                        concepts[c.id] = getTransformConcept(vs.id, vs.code)(c);
                                    });
                                });
                                return concepts;
                            })(),
                        },
                        result: response.flatMap((vs) => vs.concepts.map((c) => c.id)),
                    },
                },
                action.payload,
            );
            yield put(receivedAction);
            yield put({ type: FETCH_END });
        } else {
            yield put({ type: MULTIPLE_VALUE_SETS_NOT_RECEIVED, status });
            yield put({ type: FETCH_ERROR });
        }
    } catch (error) {
        yield put({ type: MULTIPLE_VALUE_SETS_NOT_RECEIVED, error });
        yield put({ type: FETCH_ERROR, error });
    }
}

export default function* getViewConfigOnLoginSaga() {
    yield takeEvery(getType(loadValueSets), function* (action: ReturnType<typeof loadValueSets>) {
        yield call(loadValueSetGroup, action);
    });
}
