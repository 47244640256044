import React, { useState, FunctionComponent, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReportFormComponent, { ReportDefinition } from './ReportForm';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import useSubmit, { SubmissionState } from 'report2/hooks/useSubmit';
import LoadingOverlay from 'react-loading-overlay';
import Themed from 'components/Themed';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const exampleReportDefinition: ReportDefinition = {
    name: 'mishatest',
    longRunning: false,
    displayName: 'Misha Test',
    id: 'mishatest',
    definition: 'some definition here',
    description: "Misha's test report",
    config: {
        availableOutputs: ['PDF'],
        validations: [
            {
                expression: "SITE_PIN != 'foo'",
                message: "Provider name must not be 'foo'",
            },
            {
                expression: "SITE_PIN == 'bar'",
                message: "Provider name must be 'bar'",
            },
        ],
    },
    fields: [
        {
            id: 0,
            name: 'PROVIDER_NAME',
            label: 'PROVIDER NAME',
            type: 'entity-typeahead',
            fieldNames: ['P.id'],
            required: false,
            params: {
                entity: 'Provider',
            },
            valueSetType: false,
            valueSet: true,
            entityLookupType: true,
        },
        {
            id: 1,
            name: 'SITE_PIN',
            label: 'SITE PIN',
            type: 'text',
            fieldNames: ['P.vacman_pin'],
            required: false,
            params: {},
            valueSetType: false,
            valueSet: true,
            entityLookupType: false,
        },
        {
            id: 2,
            name: 'CONTACT_DATE_FROM',
            label: 'CONTACT DATE FROM',
            type: 'date',
            fieldNames: ['A.contact_date'],
            required: false,
            valueSetType: false,
            valueSet: true,
            entityLookupType: false,
        },
        {
            id: 3,
            name: 'CONACT_DATE_TO',
            label: 'CONACT DATE TO',
            type: 'date',
            fieldNames: ['A.contact_date'],
            required: false,
            valueSetType: false,
            valueSet: true,
            entityLookupType: false,
        },
        {
            id: 4,
            name: 'FOLLOW_UP_DATE_FROM',
            label: 'FOLLOW UP DATE FROM',
            type: 'date',
            fieldNames: ['A.followup_date'],
            required: true,
            valueSetType: false,
            valueSet: true,
            entityLookupType: false,
        },
        {
            id: 5,
            name: 'FOLLOW_UP_DATE_TO',
            label: 'FOLLOW UP DATE TO',
            type: 'date',
            fieldNames: ['A.followup_date'],
            required: false,
            valueSetType: false,
            valueSet: true,
            entityLookupType: false,
        },
    ],
};
interface ExperimentalReportEditorProps {
    initialReportDefinition: ReportDefinition;
}

export const RunReport = (props: {
    reportDefinitionId: string;
    name: string;
    longRunning?: boolean;
    children: (props: { onSubmit: (fileType: string, values: {}) => void }) => JSX.Element;
}) => {
    const { longRunning, name, reportDefinitionId, children } = props;
    const [successes, setSuccesses] = useState<(string | JSX.Element)[]>([]);
    const [state, submit] = useSubmit({
        reportDefinitionId,
        reportDefinitionName: name,
    });
    const prevStateType = useRef<SubmissionState['type']>('NONE');
    useEffect(() => {
        if (longRunning) {
            if (state.type === 'SUCCESS' && prevStateType.current !== 'SUCCESS') {
                setSuccesses([
                    <>
                        Your long-running {state.fileType} report has been submitted and you will receive an email when
                        it is available for download. You can also visit{' '}
                        <Link to="/report-logs-by-current-user">My Reports</Link> page for more information.
                    </>,
                    ...successes,
                ]);
            }
        }
        prevStateType.current = state.type;
    }, [state, setSuccesses, successes, longRunning]);
    return (
        <LoadingOverlay active={state.type === 'SUBMITTING'} spinner={true} text="Running Report...">
            <div>
                {successes.map((s, i) => {
                    return (
                        <Alert key={i} severity="success">
                            <AlertTitle>Success</AlertTitle>
                            {s}
                        </Alert>
                    );
                })}
            </div>
            {children({ onSubmit: submit })}
            {state.type === 'FAILED' && (
                <Themed>
                    {({ theme }) => (
                        <pre style={{ textAlign: 'center', width: '100%', color: theme.palette.error.dark }}>
                            Report Failed: {state.err.message}
                        </pre>
                    )}
                </Themed>
            )}
        </LoadingOverlay>
    );
};

const ExperimentalReportEditor: FunctionComponent<ExperimentalReportEditorProps> = ({
    initialReportDefinition = exampleReportDefinition,
}) => {
    const [reportDefinition, setReportDefinition] = useState(initialReportDefinition);
    const expressionTesterOpen = useExpressionTesterOpen() !== 'CLOSED';
    return (
        <React.Fragment>
            {expressionTesterOpen && <JSONEditorDemo json={reportDefinition} onChangeJSON={setReportDefinition} />}
            <WithErrorBoundary>
                {expressionTesterOpen ? (
                    <div style={{ margin: '1em' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={`/compare-report/${reportDefinition.name}`}
                        >
                            Compare with old
                        </Button>
                    </div>
                ) : null}

                <RunReport
                    reportDefinitionId={reportDefinition.id as string}
                    name={reportDefinition.name}
                    longRunning={reportDefinition.longRunning}
                >
                    {({ onSubmit }) => <ReportFormComponent reportDefinition={reportDefinition} onSubmit={onSubmit} />}
                </RunReport>
            </WithErrorBoundary>
        </React.Fragment>
    );
};

export default ExperimentalReportEditor;
