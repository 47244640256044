import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';

/**
 *
 * @param fields fields to search for table fields
 * @param calledInTable if true, returns (at least) all fields at the current level of recursion.
 * @returns if calledInTable is false, returns all fields that are children of tables, with their ids modified
 * to match the format <tableName>_c_<fieldName>
 * If in nested tables, it matches
 * <tableName>_c_<fieldName>_c_<fieldName>...
 */
const getTableFields = (fields: FormFieldUnion[], calledInTable = false): FormFieldUnion[] => {
    return fields.flatMap((f) => {
        if (f.type === 'table' && f.params.columnObj) {
            let res = [];
            f.params.columnObj.forEach((cf) => {
                const colId = `${f.id}_c_${cf.id}`;
                res.push({
                    ...cf,
                    id: colId,
                });
                if (cf.type === 'table') {
                    let nestedFields = getTableFields(cf.params.columnObj, true).map((nf) => ({
                        ...nf,
                        id: colId + '_c_' + nf.id,
                    }));
                    nestedFields.forEach((nf) => res.push(nf));
                }
            });
            return res;
        }
        if (calledInTable) {
            return [f];
        }
        return [];
    });
};
export default getTableFields;
