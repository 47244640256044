import * as React from 'react';
import { DefaultViews } from 'util/DefaultViews';
import ViewConfig from 'reducers/ViewConfigType';
import getResourcesFromViewConfig from 'util/getResourceListFromConfig';
import useViewConfig from 'util/hooks/useViewConfig';

const useResourcesAndViews = () => {
    const viewConfig = useViewConfig();
    const resources = React.useMemo(() => {
        if (!viewConfig || !viewConfig.entities) {
            return [];
        }
        return getResourcesFromViewConfig(viewConfig);
    }, [viewConfig]);
    const nonDefaultResourceViews: ViewConfig['views'][0][] = React.useMemo(() => {
        if (!viewConfig || !viewConfig.entities) {
            return [];
        }
        return Object.values(viewConfig.views).filter((v) => !DefaultViews.isDefaultView(viewConfig, v.name));
    }, [viewConfig]);
    return {
        resources,
        nonDefaultResourceViews,
    } as const;
};

export default useResourcesAndViews;
