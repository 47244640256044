import {
    allowsCreate,
    allowsEdit,
    getAllFieldsFromView,
    getRefEntity,
    isRefManyManyField,
    isRefOneField,
} from 'components/generics/utils/viewConfigUtils';
import ViewConfig, { FieldViewField } from 'reducers/ViewConfigType';
import {
    isFieldViewField,
    isValueSetOrValueSetManyField,
    isRefManyField,
    getRefEntityName,
    getValueSetForFieldExpr,
} from 'components/generics/utils/viewConfigUtils';
import get from 'lodash/get';

const getConfigProperty = (property: string) => (f: FieldViewField, defaultValue?: any) => {
    if (!f.config) {
        return defaultValue;
    }
    try {
        let parsedConfig = JSON.parse(f.config);
        return get(parsedConfig, property, defaultValue);
    } catch (e) {
        return defaultValue;
    }
};

const getEntityExpansionsForDepth = (viewConfig: ViewConfig) => {
    let getViewName = (type: 'SHOW' | 'EDIT' | 'LIST' | 'CREATE', f: FieldViewField) => {
        let configuredName = getConfigProperty(type.toLowerCase() + 'ViewName')(f);
        let altConfiguredName = getConfigProperty('viewOverride.' + type.toLowerCase())(f);
        let relatedEntityName = getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST');
        let defaultName = viewConfig.entities[relatedEntityName].defaultViews?.[type]?.name;
        return configuredName ?? altConfiguredName ?? defaultName;
    };

    const innerGetExpansions = (
        viewName: string,
        depthRemaining: number,
    ): {
        expansions: string[];
        valueSets: string[];
    } => {
        if (depthRemaining === 0) {
            return { expansions: [], valueSets: [] };
        }
        const entityType = viewConfig.views[viewName].entity;
        const viewType = viewConfig.views[viewName].viewType;
        let expansions = [];
        let valueSets = [];
        getAllFieldsFromView(viewConfig, viewName).forEach((f) => {
            if (!isFieldViewField(f)) {
                return;
            }
            const canCreate = () => {
                return allowsCreate(getRefEntity(viewConfig, entityType, f.field, true).accessLevel);
            };
            const canEdit = () => {
                return allowsEdit(getRefEntity(viewConfig, entityType, f.field, true).accessLevel);
            };
            const drillIntoView = (viewName: string) => {
                const results = innerGetExpansions(viewName, depthRemaining - 1);
                results.expansions.forEach((childExpansion) => {
                    expansions.push(f.field + '.' + childExpansion);
                });
                results.valueSets.forEach((vs) => {
                    valueSets.push(vs);
                });
            };
            if (isValueSetOrValueSetManyField(viewConfig, f.entity, f.field, 'POP_LAST')) {
                expansions.push(f.field);
                if (viewType === 'EDIT' || viewType === 'CREATE') {
                    let valueSet = getValueSetForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST');
                    valueSets.push(valueSet);
                }
            }
            if (isRefOneField(viewConfig, f.entity, f.field, 'POP_LAST')) {
                expansions.push(f.field);
                if (depthRemaining > 1) {
                    drillIntoView(getViewName('SHOW', f));
                    if (canEdit()) {
                        drillIntoView(getViewName('EDIT', f));
                    }
                }
            }
            if (isRefManyManyField(viewConfig, f.entity, f.field, 'POP_LAST')) {
                expansions.push(f.field);
                drillIntoView(getViewName('LIST', f));
                if (depthRemaining > 1) {
                    drillIntoView(getViewName('SHOW', f));
                    if (canEdit()) {
                        drillIntoView(getViewName('EDIT', f));
                    }
                }
            }
            if (isRefManyField(viewConfig, f.entity, f.field, 'POP_LAST')) {
                expansions.push(f.field);
                drillIntoView(getViewName('LIST', f));
                if (depthRemaining > 1) {
                    // drill down into CREATE, SHOW, EDIT views.

                    if (
                        // CREATE views: lets only fetch valueSets, and not candidate entities, because there can be too many.
                        canCreate() &&
                        getConfigProperty('hasCreate')(f, true)
                    ) {
                        drillIntoView(getViewName('CREATE', f));
                    }
                    drillIntoView(getViewName('SHOW', f));
                    if (canEdit()) {
                        drillIntoView(getViewName('EDIT', f));
                    }
                }
            }
        });
        return { expansions, valueSets };
    };
    return innerGetExpansions;
};

export default getEntityExpansionsForDepth;
