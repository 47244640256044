import { isFieldViewField } from 'components/generics/utils/viewConfigUtils';
import getImpl from 'expressions/Provider/implementations/getImpl';
import React, { useMemo } from 'react';
import ViewConfig, { ViewField } from 'reducers/ViewConfigType';
import useViewConfig from 'util/hooks/useViewConfig';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';

// TODO: replace other instances with this.
type ExpressionMeta = {
    expression: string;
    fieldName: string;
    expansionsRequired: string[];
    dataPaths: string[];
    valuesetLiterals: string[];
    valuesetFieldsRequired: {
        [fieldSource: string]: string;
    };
};
const generateExpression = (
    viewConfig: ViewConfig,
    expression: string,
    fieldName: string,
    entityType: string,
): ExpressionMeta => {
    try {
        const impl = getImpl();
        const compiled = impl.compileExpression(expression);
        if (compiled.type === 'parse_failure') {
            throw new Error(compiled.msg);
        }
        const [expansionsRequired, dataPaths, valuesetLiterals] = [
            compiled.getExpansionsWithAll(),
            compiled.getPathsWithAll(),
            compiled.getValueSetLiterals(),
        ] as [string[], string[], string[]];

        const res = {
            expression,
            fieldName,
            expansionsRequired,
            dataPaths,
            valuesetLiterals,
        };
        return setupValuesetFieldsRequired(
            viewConfig,
            entityType,
            expression,
        )(res).fold(
            (e) => {
                console.error(e);
                return null;
            },
            (res) => res,
        );
    } catch (e) {
        console.error(e);
        return null;
    }
};

export type ExpressionsByField = {
    [fieldName: string]: ExpressionMeta[];
};
const WithInlineEditableExpressions: React.FC<{
    fields: React.ReactElement<{ ['data-originaldefinition']: string }>[];
    entityType: string;
    children: (props: { editableExpressions: ExpressionsByField }) => JSX.Element;
}> = ({ fields, entityType, children }) => {
    const viewFields: ViewField[] = useMemo(
        () =>
            fields.flatMap((f) => {
                try {
                    return [JSON.parse(f.props['data-originaldefinition'])];
                } catch (e) {
                    console.error(e);
                }
                return [];
            }),
        [fields],
    );
    const viewConfig = useViewConfig();
    const editableExpressions = useMemo(() => {
        // TODO type this
        const expressions: ExpressionsByField = {};
        viewFields.forEach((vf) => {
            if (!isFieldViewField(vf)) {
                return;
            }
            if (!vf.config?.trim()) {
                return;
            }
            try {
                const config = JSON.parse(vf.config);
                if (!config.inlineEditable) {
                    return;
                }
                if (!config.editableExpression?.trim()) {
                    return;
                }
                const expressionWithMetaData = generateExpression(
                    viewConfig,
                    config.editableExpression,
                    vf.field,
                    entityType,
                );
                if (!expressionWithMetaData) {
                    return null;
                }
                // TODO: not sure if instead of vf.field I need to put '*Id'
                if (!expressions[vf.field]) {
                    expressions[vf.field] = [];
                }
                expressions[vf.field].push(expressionWithMetaData);
            } catch (e) {
                console.error(e);
            }
        });
        return expressions;
    }, [viewFields, entityType, viewConfig]);

    return children({
        editableExpressions,
    });
};
export default WithInlineEditableExpressions;
