import React, { useState } from 'react';

type OpenEdit = (params: { focusField: string }) => void;

interface EditableTableRowProps {
    children: (props: { openEdit: OpenEdit; editing: boolean; focusField: string | null }) => JSX.Element;
}
const EditableTableRow: React.FC<EditableTableRowProps> = (props) => {
    // initially focused field, or false
    const [editing, setEditing] = useState<string | false>(false);
    const openEdit: OpenEdit = React.useCallback(({ focusField }) => {
        setEditing(focusField);
    }, []);
    return props.children({
        openEdit,
        editing: !!editing,
        focusField: editing || null,
    });
};
export default EditableTableRow;
