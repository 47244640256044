import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, useTheme } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useUserIsSuper from 'util/hooks/useUserIsSuper';
import useViewConfig from 'util/hooks/useViewConfig';
import { overrideAppbarTitleSelector } from '../overrideAppbarTitleSelector';
import { push as pushAction } from 'connected-react-router';
import { RootState } from 'reducers/rootReducer';
import { getUseEmailAsAccountDisplaySelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import RefreshViewConfigButton from 'viewConfig/components/Refresh';
import ThemeableToolbarTitle from 'components/SsgToolbarTitle';
import generateMobileMenu from './generateMobileMenuElements';
import ExpandableListItem from './ExpandableItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { promptPickProfile } from 'auth/profiles/actions';
import ImportExportViewConfigBtn from 'components/importExportViewConfig';
import LoginButton from 'components/LoginButton';

const SidebarMenu = () => {
    const isSuper = useUserIsSuper();
    const { invertMenu } = useContext(themeOverrideContext);
    const theme = useTheme();
    const viewConfig = useViewConfig();
    const {
        overriddenAppbarTitleHtml = '',
        hideAllMenuItems,
        navLink,
        toolbarStyles,
    } = useSelector(overrideAppbarTitleSelector);
    const evaluate = useEvaluateTemplate(overriddenAppbarTitleHtml);
    const templatedHtml = useMemo(() => evaluate(overriddenAppbarTitleHtml), [overriddenAppbarTitleHtml, evaluate]);

    const dispatch = useDispatch();
    const logout = useCallback(() => {
        dispatch(pushAction('/logout-redirect'));
    }, [dispatch]);
    const profiles = useSelector((state: RootState) => state.profiles);
    const useEmailAsAccountDisplay = useSelector(getUseEmailAsAccountDisplaySelector);

    return (
        <div>
            <List>
                <ListItem>
                    {templatedHtml && navLink ? (
                        <NavLink to="/" style={{ textDecoration: 'none', color: 'unset', flex: 1 }}>
                            <SafeHtmlAsReact html={templatedHtml} />
                        </NavLink>
                    ) : templatedHtml ? (
                        <div style={{ flex: 1 }}>
                            <SafeHtmlAsReact html={templatedHtml} />
                        </div>
                    ) : (
                        <ThemeableToolbarTitle />
                    )}
                </ListItem>
                {!hideAllMenuItems && (
                    <>
                        {generateMobileMenu({ viewConfig, dispatch })(viewConfig.menus, true)}
                        {viewConfig?.user?.login && viewConfig?.user?.login !== 'anonymousUser' && (
                            <ExpandableListItem
                                label={
                                    <>
                                        Account&nbsp;
                                        <AccountCircle color="inherit" />
                                    </>
                                }
                            >
                                {() => (
                                    <List component="menu">
                                        <ListItem role="presentation">
                                            {useEmailAsAccountDisplay
                                                ? viewConfig.user?.email ?? viewConfig.user?.login
                                                : viewConfig.user?.login}
                                        </ListItem>
                                        {profiles.state === 'profile_selected' && profiles.profiles.length > 1 ? (
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    dispatch(promptPickProfile());
                                                }}
                                            >
                                                Switch Profile
                                            </ListItem>
                                        ) : null}
                                        <ListItem button onClick={logout}>
                                            Logout
                                        </ListItem>
                                    </List>
                                )}
                            </ExpandableListItem>
                        )}
                        {viewConfig?.user?.login === 'super' && <ImportExportViewConfigBtn mobile />}
                        <RefreshViewConfigButton mobile />
                        {viewConfig?.user?.login === 'anonymousUser' && <LoginButton />}
                    </>
                )}
            </List>
        </div>
    );
};
export default SidebarMenu;
