import React, { useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, InputLabel } from '@material-ui/core';
import CasetivitySelect from 'components/CasetivitySelect';

const SortDirField = (props: { defaultValue?: 'ASC' | 'DESC' }) => {
    const { register, unregister, setValue, watch } = useFormContext<{ sortDir: 'ASC' | 'DESC' | '' }>();
    useEffect(() => {
        register({ name: 'sortDir', value: props.defaultValue });
        return () => {
            unregister('sortDir');
        };
    }, []); // eslint-disable-line
    const value = watch('sortDir');
    const handleChange = useCallback(
        (event: React.ChangeEvent<{ value: any }>) => {
            setValue('sortDir', event.target.value, {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        [setValue],
    );
    const LABEL_TEXT = 'Sort Direction';
    return (
        <FormControl fullWidth>
            <InputLabel shrink={true} id="pick-sortDir-label">
                {LABEL_TEXT}
            </InputLabel>
            <CasetivitySelect
                labelId="pick-sortDir-label"
                id="pick-sortDir"
                label={LABEL_TEXT}
                value={value}
                onChange={handleChange}
                onBlur={handleChange}
                fullWidth
                style={{ minWidth: 100 }}
            >
                {({ OptionComponent }) => [
                    <OptionComponent key="none-default" value="">
                        None
                    </OptionComponent>,
                    <OptionComponent key="asc" value="ASC">
                        Ascending
                    </OptionComponent>,
                    <OptionComponent key="desc" value="DESC">
                        Descending
                    </OptionComponent>,
                ]}
            </CasetivitySelect>
        </FormControl>
    );
};
export default SortDirField;
