import { View } from 'reducers/ViewConfigType';
import { Omit } from 'utility-types';

export const defaultState = {
    viewData: {
        config: '',
        entity: '',
        fields: {},
        name: '',
        route: '',
        tabs: {},
        viewType: '' as '',
        searchFields: undefined,
    },
};

export type ViewData = Omit<View, 'viewType'> & { viewType: View['viewType'] | ''; entityVersion?: number };

export type ViewWizardAction =
    | { type: 'eraseMeta' }
    | {
          type: 'write';
          payload: Partial<ViewData>;
      }
    | {
          type: 'replace';
          payload: Partial<ViewData>;
      }
    | {
          type: 'clear';
      }
    | {
          type: 'pickExisting';
          meta:
              | {
                    existingViewId: string;
                }
              | {
                    existingViewName: string;
                };
          viewData: Partial<ViewData>;
      };
export type ViewWizardState = {
    meta?:
        | {
              existingViewId: string;
          }
        | {
              existingViewName: string;
          };
    viewData: Partial<ViewData>;
};
export default function viewWizardReducer(state: ViewWizardState, action: ViewWizardAction): ViewWizardState {
    switch (action.type) {
        case 'eraseMeta':
            return {
                ...state,
                meta: undefined,
            };
        case 'clear':
            return defaultState;
        case 'write':
            return {
                ...state,
                viewData: {
                    ...state.viewData,
                    ...action.payload,
                },
            };
        case 'pickExisting':
            return {
                meta: action.meta,
                viewData: action.viewData,
            };
        case 'replace':
            return {
                ...state,
                viewData: {
                    ...defaultState.viewData,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
