import { takeEvery, call } from 'redux-saga/effects';
import { clearCache } from 'expressions/contextUtils';

const LOC_CHANGE = '@@router/LOCATION_CHANGE';
interface LocChangePayload {
    pathname: string;
    search?: string;
    hash?: string;
}
interface LocChangeAction {
    type: typeof LOC_CHANGE;
    payload: LocChangePayload;
}

let prevPath = '';
export function* clearCacheOnPathChange(action: LocChangeAction) {
    if (prevPath !== action.payload.pathname) {
        prevPath = action.payload.pathname;
        yield call(clearCache);
    }
}
export default function* expressionTesterSaga() {
    yield takeEvery(LOC_CHANGE, function* (action: LocChangeAction) {
        yield call(clearCacheOnPathChange, action);
    });
}
