import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import {
    checkLastRequestTimeFailure,
    checkLastRequestTimeSuccess,
} from 'reauth-interval-and-retries/check-last-request/actions';

const a401OccurredOnReauthReducer = (state: boolean = false, action: RootAction): boolean => {
    switch (action.type) {
        case getType(checkLastRequestTimeFailure):
            return action._error && action._error.status === 401;
        case getType(checkLastRequestTimeSuccess):
            return false;
        default:
            return state;
    }
};
export default a401OccurredOnReauthReducer;
