import React from 'react';
import PrintTemplateNameLookupField from 'printTemplate/component/PrintTemplateField';
// import HtmlDisplay from '../display/components/HtmlDisplay';
import { HtmlDisplayComponentAsReact } from '../display/components/HtmlDisplay';
import { Tooltip } from '@material-ui/core';
import get from 'lodash/get';
import { EntityExpressionField } from '../translation/fromEntity/types/index';
import ServerTemplatedPrintTemplateField from 'printTemplate/serverTemplating/component/ServerTemplatedPrintTemplateField';
import {
    printTemplatePrefix,
    printTemplatePdfPrefix,
    printTemplateReportPrefix,
} from 'fieldFactory/util/expressionConstants';
import WithStringEvaluatedInFormContext from 'fieldFactory/popovers/PopoverRefInput/EvaluateStringTemplateInFormContext';
import { getTemplateDataPaths } from 'util/getTemplateDataPaths';
import ActionButton from './components/ActionButton/ActionButton';
import isOffline from 'util/isOffline';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

const ActionButtonPre = '<!--ActionButton';
const tooltipTextPre = 'tooltipText[';
const getTooltipText = (htmlConfig?: string): string | null => {
    if (
        htmlConfig &&
        typeof htmlConfig === 'string' &&
        htmlConfig.toLowerCase().indexOf(tooltipTextPre.toLowerCase()) !== -1
    ) {
        const textStartIndex = htmlConfig.toLowerCase().indexOf(tooltipTextPre.toLowerCase()) + tooltipTextPre.length;
        const textEndIndex = htmlConfig.indexOf(']', textStartIndex);
        const tooltipText = htmlConfig.slice(textStartIndex, textEndIndex);
        return tooltipText;
    }
    return null;
};

const WrapperComponent = (props) => <div>{props.children}</div>;
export default (fieldDefinition: EntityExpressionField, props, from: 'Entity' | 'Flowable') => {
    if (fieldDefinition.htmlConfig && typeof fieldDefinition.htmlConfig === 'string') {
        if (fieldDefinition.htmlConfig.startsWith(printTemplatePrefix)) {
            return (
                <PrintTemplateNameLookupField
                    hideMe={isOffline() || undefined}
                    {...props}
                    printTemplateName={fieldDefinition.htmlConfig.slice(printTemplatePrefix.length)}
                />
            );
        }
        const isPdf = fieldDefinition.htmlConfig.startsWith(printTemplatePdfPrefix);
        const isReport = fieldDefinition.htmlConfig.startsWith(printTemplateReportPrefix);
        if (isPdf || isReport) {
            return (
                <ServerTemplatedPrintTemplateField
                    hideMe={isOffline() || undefined}
                    {...props}
                    displayText={props.label === 'Label' ? undefined : props.label}
                    type={isReport ? 'report' : 'pdf'}
                    printTemplateName={fieldDefinition.htmlConfig.slice(printTemplatePdfPrefix.length)}
                />
            );
        }
        const trimmed = fieldDefinition.htmlConfig.trim();
        const HTML_COMMENT_TAIL = '-->';
        if (trimmed.startsWith(ActionButtonPre) && trimmed.endsWith(HTML_COMMENT_TAIL)) {
            const jsonConfig = trimmed.slice(ActionButtonPre.length, HTML_COMMENT_TAIL.length * -1);
            try {
                let actionButtonConfig = JSON.parse(jsonConfig);
                return (
                    <ActionButton
                        hideMe={isOffline() || undefined}
                        {...props}
                        definition={actionButtonConfig}
                        ButtonProps={{ color: 'primary', variant: 'contained' }}
                    />
                );
            } catch (e) {
                return (
                    <WrapperComponent hideMe={isOffline() || undefined} {...props}>
                        <div>Failed to parse ActionButtonConfig: {jsonConfig}</div>
                    </WrapperComponent>
                );
            }
        }
    }
    const getFromRecord = () => {
        if (props.record && props.source) {
            return get(props.record, props.source);
        }
        return undefined;
    };
    const getFromInput = () => {
        if (props.input && props.input.value) {
            return props.input.value;
        }
        return undefined;
    };
    const propsForGrid = {
        id: props.id || fieldDefinition.name,
        addField: true,
        addLabel: false,
    };
    const childFn = (evaluatedTemplate: string) => (
        <HtmlDisplayComponentAsReact {...propsForGrid} html={evaluatedTemplate} />
    );
    const templateString = fieldDefinition.htmlConfig || getFromRecord() || getFromInput();

    if (!templateString) {
        // when 'values' are provided to expression-fields in json-tables,
        // we don't have html expressions available at all in the field definition.
        // lets use the value when provided.

        // note that this means dynamic templates are unavailable.
        const NoStartingTemplateComponent = (props) => {
            if (!props.input?.value) {
                return <div />;
            }
            return childFn(props.input.value);
        };
        return <NoStartingTemplateComponent {...props} {...propsForGrid} />;
    }

    const { expansionsRequired, dataPaths } = getTemplateDataPaths(templateString);

    const HtmlDisplayComponent = (props: { id?: string; addField?: boolean; addLabel?: boolean }) =>
        from === 'Flowable' ? (
            <WithStringEvaluatedInFormContext from={from} templateString={templateString + ''}>
                {childFn}
            </WithStringEvaluatedInFormContext>
        ) : (
            <WithStringEvaluatedInFormContext
                from={from}
                context={{ type: 'adhoc', expansionsRequired, dataPaths, valuesetFieldsRequired: {} }}
                templateString={templateString + ''}
            >
                {childFn}
            </WithStringEvaluatedInFormContext>
        );

    const tooltipText = getTooltipText(fieldDefinition.htmlConfig);
    if (tooltipText) {
        const TComponent = (props) => {
            const translatedTooltipText = useEvaluatedFormattedMessage(tooltipText);
            return (
                <Tooltip
                    title={<span style={{ fontSize: 17, lineHeight: 1.3 }}>{translatedTooltipText}</span>}
                    placement="top-end"
                >
                    <span>
                        <HtmlDisplayComponent {...propsForGrid} />
                    </span>
                </Tooltip>
            );
        };
        return <TComponent {...propsForGrid} {...props} />;
    }
    return <HtmlDisplayComponent {...props} {...propsForGrid} />;
};
