import { createSelector } from 'reselect';
import set from 'lodash/set';
import { RootState } from '../../../reducers/rootReducer';
import { ConceptInApp } from 'valueSets/domain';

const getDefaultValues = (fields, data = {}, defaultValue = {}, valuesetDefaultValues = {}) => {
    const globalDefaultValue = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    const defaultValueFromFields = fields
        .map((child) => ({
            source: child.props.source,
            defaultValue:
                child.props.defaultValue ||
                (child.props.defaultCode || child.props.defaultCodes
                    ? valuesetDefaultValues[child.props.source]
                    : undefined),
        }))
        .reduce((prev, next) => {
            if (next.defaultValue != null) {
                set(
                    prev,
                    next.source,
                    typeof next.defaultValue === 'function' ? next.defaultValue() : next.defaultValue,
                );
            }
            return prev;
        }, {});
    return { ...globalDefaultValue, ...defaultValueFromFields, ...data };
};

const getFields = (state, props) => props.fields;
const getRecord = (state, props) => props.record;
const getDefaultValue = (state, props) => props.defaultValue;

const createConceptsByCodeSelector = () =>
    createSelector(
        (state: RootState, props) => state.admin.entities.Concept,
        (concepts) => Object.assign({}, ...Object.values(concepts).map((c: ConceptInApp) => ({ [c.code]: c }))),
    );
const createGetValuesetDefaultCodes = () =>
    createSelector(getFields, (fields): { [source: string]: string } =>
        Object.assign(
            {},
            ...fields.map((f) =>
                f.props.source && f.props.defaultCode ? { [f.props.source]: f.props.defaultCode } : {},
            ),
        ),
    );
const createGetValuesetManyDefaultCodes = () =>
    createSelector(getFields, (fields): { [source: string]: string[] } =>
        Object.assign(
            {},
            ...fields.map((f) =>
                f.props.source && f.props.defaultCodes ? { [f.props.source]: f.props.defaultCodes } : {},
            ),
        ),
    );

const createGetValuesetDefaultValues = () => {
    const getValuesetDefaultCodes = createGetValuesetDefaultCodes();
    const getValuesetManyDefaultCodes = createGetValuesetManyDefaultCodes();
    const conceptsByCodeSelector = createConceptsByCodeSelector();
    return createSelector(
        getValuesetDefaultCodes,
        getValuesetManyDefaultCodes,
        conceptsByCodeSelector,
        (codes, valuesetManyCodes, conceptsByCode) => {
            return Object.assign(
                {},
                ...Object.entries(codes).map(([source, code]) =>
                    conceptsByCode[code]
                        ? {
                              [source]: conceptsByCode[code].id,
                          }
                        : {},
                ),
                ...Object.entries(valuesetManyCodes).map(([source, defCodes]) => {
                    const translated = defCodes.map((c) => (conceptsByCode[c] || {}).id);
                    if (!translated.find((conc) => !conc)) {
                        // falsy not found - all concepts lookd up
                        return { [source]: translated };
                    }
                    return {};
                }),
            );
        },
    );
};

const createGetDefaultValues = () => {
    const getValuesetDefaultValues = createGetValuesetDefaultValues();
    return createSelector(
        getFields,
        getRecord,
        getDefaultValue,
        getValuesetDefaultValues,
        (fields, record, defaultValue, valuesetDefaultValues) =>
            getDefaultValues(fields, record, defaultValue, valuesetDefaultValues),
    );
};
export default createGetDefaultValues;
