import React from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';

export interface LoadMatchRecordProps {
    id: string;
    resource: string;
    onIdChange?: 'REDIRECT' | ((id: string) => void);
}

interface MapDispatchToProps {
    updateData: (props: LoadMatchRecordProps) => unknown;
    push: (newloc: string) => void;
}
const mapDispatchToProps: MapDispatchToProps = {
    updateData: (props: LoadMatchRecordComponentProps) => {
        const { resource, id, onIdChange = 'REDIRECT', push } = props;
        return crudGetOneAction({
            resource,
            id,
            view: `${resource}Match`,
            cb: (responseId, responseData) => {
                if (`${id}` !== `${responseId}`) {
                    if (onIdChange === 'REDIRECT') {
                        push(`/${resource}/${responseId}/matches`);
                    } else if (onIdChange) {
                        onIdChange(responseId);
                    }
                }
            },
        });
    },
    push: pushAction,
};
interface LoadMatchRecordComponentProps extends LoadMatchRecordProps, MapDispatchToProps {}

class LoadMatchRecordComponent extends React.Component<LoadMatchRecordComponentProps> {
    componentDidMount() {
        this.props.updateData(this.props);
    }
    componentWillReceiveProps(nextProps: LoadMatchRecordComponentProps) {
        if (this.props.id !== nextProps.id) {
            this.props.updateData(nextProps);
        }
    }
    render() {
        return this.props.children;
    }
}

const LoadMatchRecord: React.ComponentType<LoadMatchRecordProps> = connect(
    null,
    mapDispatchToProps,
)(LoadMatchRecordComponent);
export default LoadMatchRecord;
