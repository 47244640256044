import ViewConfig, { Entity, View } from '../../reducers/ViewConfigType';
import { isFieldViewField } from '../../components/generics/utils/viewConfigUtils';

export const createMatchView = (
    resource: string,
    mrgListFields: ViewConfig['views'][0]['fields'],
): ViewConfig['views'][0] => {
    return {
        name: `${resource}Match`,
        entity: resource,
        viewType: 'MATCH',
        route: null,
        fields: Object.assign(
            {},
            ...Object.entries(mrgListFields).flatMap(([k, field]) => {
                /*
                        i.e. stripping out 'score' field on 'PersonMrg'/
                    */
                if (isFieldViewField(field) && field.field !== 'score') {
                    const [, ...rest] = k.split('.');
                    const fieldOnTargetEntity = rest.join('.');
                    return [
                        {
                            [fieldOnTargetEntity]: {
                                ...field,
                                field: fieldOnTargetEntity,
                            },
                        },
                    ];
                }
                return [];
            }),
        ),
    };
};

const lowercaseFirst = (str: string) => {
    return str && str.charAt(0).toLowerCase() + str.slice(1);
};

export const createMrgListView = (matchView: View): View => {
    if (matchView.entity.endsWith('Mrg')) {
        console.warn(`view ${matchView.name} has entity configured as ${matchView.entity}
            when it should really be ${matchView.entity.slice(0, -3)}`);
    }
    const mrgEntity = `${matchView.entity}Mrg`;
    return {
        name: `${mrgEntity}List`,
        entity: mrgEntity,
        viewType: 'LIST',
        route: null,
        fields: Object.assign(
            {},
            ...Object.entries(matchView.fields).flatMap(([k, field]) => {
                if (isFieldViewField(field)) {
                    const fieldOnMrgEntity = `${lowercaseFirst(matchView.entity)}.${k}`;
                    return [
                        {
                            [fieldOnMrgEntity]: {
                                ...field,
                                field: fieldOnMrgEntity,
                            },
                        },
                    ];
                }
                return [];
            }),
            {
                score: {
                    entity: mrgEntity,
                    field: 'score',
                    label: 'Score',
                    widgetType: 'PERCENT',
                    row: 0,
                    column: 0,
                    span: 0,
                    order:
                        1 +
                        Object.values(matchView.fields).reduce((prev, curr) => {
                            if (curr.order && curr.order > prev) {
                                return curr.order;
                            }
                            return prev;
                        }, 1),
                },
            },
        ),
    };
};
// THIS IS THE ONE USED
const injectMrgListViewsFromMatchViews = (viewConfig: ViewConfig): ViewConfig => {
    const mrgListViews: ViewConfig['views'][0][] = Object.entries(viewConfig.views).flatMap(([viewName, view]) => {
        if (view.viewType === 'MATCH') {
            const correspondingMatchView = createMrgListView(view);
            return [correspondingMatchView];
        }
        return [];
    });
    const mrgListViewsObj: ViewConfig['views'] = Object.assign(
        {},
        ...mrgListViews.map((v) => {
            return { [v.name]: v };
        }),
    );
    const updatedMrgEntitiesHavingListViews: ViewConfig['entities'] = Object.assign(
        {},
        ...mrgListViews
            .map((v): [View, Entity] => [v, viewConfig.entities[v.entity]])
            .filter(([v, e]) => {
                if (!e) {
                    console.warn(`Entity ${v.entity} not looked up when autogenerating MrgList views`);
                }
                return !!e;
            }) // just in case the corresponding entity hasn't been loaded.
            .map(([v, e]) => ({
                [e.name]: {
                    ...e,
                    defaultViews: e.defaultViews && {
                        ...e.defaultViews,
                        LIST: {
                            name: v.name,
                        },
                    },
                },
            })),
    );
    return {
        ...viewConfig,
        entities: {
            ...viewConfig.entities,
            ...updatedMrgEntitiesHavingListViews,
        },
        views: {
            ...viewConfig.views,
            ...mrgListViewsObj,
        },
    };
};

export default injectMrgListViewsFromMatchViews;
