import React, { FunctionComponent } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    CardActions,
    IconButton,
    useTheme,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Popup from 'components/Popup';

// TODO add visibility
type Option = { id: string; name: string };
type Options = Option[];
export interface OptionsEditorProps {
    options: Options;
    disabled?: boolean;
    setOptions: (options: Options) => void;
    renderOptionEditor: (params: { initialValues: Option; onSubmit: (option: Option) => void }) => JSX.Element;
}

export const OptionsEditor: FunctionComponent<OptionsEditorProps> = ({
    options,
    setOptions,
    disabled,
    renderOptionEditor,
}) => {
    const theme = useTheme();
    const getRenderDialogContent =
        (initialValues?: Option, i?: number) =>
        ({ closeDialog }) =>
            (
                <div style={{ margin: '1em', padding: '1em', minWidth: '70vw' }}>
                    <h2>{initialValues ? 'Edit' : 'Add'} Option</h2>
                    {renderOptionEditor({
                        onSubmit: (data) => {
                            if (typeof i === 'number') {
                                const newData = [...options.slice(0, i), data, ...options.slice(i + 1)];
                                setOptions(newData);
                                closeDialog();
                            } else {
                                setOptions([...options, data]);
                                closeDialog();
                            }
                        },
                        initialValues,
                    })}
                </div>
            );
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Option id</TableCell>
                        <TableCell>Option name</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {options.map((v, i) => {
                        return (
                            <TableRow>
                                <TableCell>{v.id}</TableCell>
                                <TableCell>{v.name}</TableCell>
                                <TableCell>
                                    <Popup
                                        renderDialogContent={getRenderDialogContent(v, i)}
                                        renderToggler={({ openDialog }) => (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={openDialog()}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    />
                                    &nbsp;
                                    <Popup
                                        renderDialogContent={({ closeDialog }) => (
                                            <>
                                                <div style={{ margin: '1em' }}>Delete option?</div>
                                                <CardActions>
                                                    <Button onClick={closeDialog}>Cancel</Button>
                                                    <Button
                                                        style={{ color: theme.palette.error.main }}
                                                        onClick={() => {
                                                            const newData = [
                                                                ...options.slice(0, i),
                                                                ...options.slice(i + 1),
                                                            ];
                                                            setOptions(newData);
                                                            closeDialog();
                                                        }}
                                                    >
                                                        Option
                                                    </Button>
                                                </CardActions>
                                            </>
                                        )}
                                        renderToggler={({ openDialog }) => (
                                            <IconButton
                                                style={{ color: theme.palette.error.main }}
                                                onClick={openDialog()}
                                            >
                                                <Delete />
                                            </IconButton>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <br />
            <Popup
                renderDialogContent={getRenderDialogContent()}
                renderToggler={({ openDialog }) => (
                    <Button
                        style={{ float: 'right' }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={openDialog()}
                    >
                        &nbsp;Add&nbsp;
                        <Add />
                    </Button>
                )}
            />
        </>
    );
};
