import { getStorage } from './storage';

const TOKEN_KEY = 'jhi-authenticationtoken';
const PROFILE_KEY = 'jhi-authenticationtoken-profile';
const IMPERSONATION_KEY = 'jhi-authenticationtoken-impersonate';

class StorageController {
    onSetTokenSubscriptions: (() => void)[] = [];
    onClearTokenSubscriptions: (() => void)[] = [];
    clear() {
        getStorage().clear();
        this.onClearTokenSubscriptions.forEach((fn) => fn());
        this.clearSubscriptions();
    }
    setToken(id_token: string) {
        // needs to match getToken ordering.
        // impersonate or profile or token
        if (getStorage().getItem(IMPERSONATION_KEY)) {
            getStorage().setItem(IMPERSONATION_KEY, id_token);
        } else if (getStorage().getItem(PROFILE_KEY)) {
            getStorage().setItem(PROFILE_KEY, id_token);
        } else {
            getStorage().setItem(TOKEN_KEY, id_token);
        }
        this.onSetTokenSubscriptions.forEach((fn) => fn());
    }
    getToken() {
        return (
            getStorage().getItem(IMPERSONATION_KEY) ||
            getStorage().getItem(PROFILE_KEY) ||
            getStorage().getItem(TOKEN_KEY)
        );
    }
    // profile
    setCurrentProfileToken(token: string) {
        getStorage().setItem(PROFILE_KEY, token);
    }
    getCurrentProfileToken() {
        return getStorage().getItem(PROFILE_KEY);
    }
    clearCurrentProfileToken() {
        getStorage().removeItem(PROFILE_KEY);
    }
    // impersonation
    startImpersonating(new_token: string) {
        getStorage().setItem(IMPERSONATION_KEY, new_token);
    }
    stopImpersonating() {
        getStorage().removeItem(IMPERSONATION_KEY);
    }

    // subscriptions
    subscribeToSetToken(fn: () => void) {
        this.onSetTokenSubscriptions.push(fn);
    }
    unsubscribeToSetToken(fn: () => void) {
        this.onSetTokenSubscriptions = this.onSetTokenSubscriptions.filter((_fn) => _fn !== fn);
    }
    subscribeToClearToken(fn: () => void) {
        this.onClearTokenSubscriptions.push(fn);
    }
    clearSubscriptions() {
        this.onSetTokenSubscriptions = [];
        this.onClearTokenSubscriptions = [];
    }
}

export const storageController = new StorageController();
