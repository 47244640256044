import React, { FunctionComponent, useMemo } from 'react';
import Edit from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import { setExpressionTesterOpen } from 'expression-tester/actions';

const NavToWizardFab: FunctionComponent<{ viewName: string }> = React.memo((props) => {
    const { push } = useHistory();
    const getUserIsSuper = useMemo(
        () =>
            createSelector(
                (state: RootState) => state.viewConfig,
                (viewConfig) => viewConfig.user.roles.includes('ROLE_SUPER'),
            ),
        [],
    );
    const open = useExpressionTesterOpen();
    const dispatch = useDispatch();
    const userIsSuper = useSelector(getUserIsSuper);
    if (userIsSuper && open === 'OPEN_ALL') {
        return (
            <Fab
                color="primary"
                style={{ position: 'fixed', bottom: 40, right: 15 }}
                onClick={() => {
                    // lets close expression-tester, since we are going to wizard.
                    dispatch(setExpressionTesterOpen(false));
                    push(`/load-layout/${props.viewName}`);
                }}
                aria-label="naviage to view wizard"
            >
                <Edit />
            </Fab>
        );
    }
    return null;
});
export default NavToWizardFab;
