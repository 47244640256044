import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import { convert } from 'layout-editor/diff-viewsdefs-from-views/convertViewDefToView';
import memoizeOne from 'memoize-one';
import injectMrgListViewsFromMatchViews from 'injectViews/injectMatchViewsFromMrgViews';
import { createSelector } from 'reselect';

export const overwriteViewsWithViewDefs = memoizeOne((viewConfig: ViewConfig) => {
    return {
        ...viewConfig,
        views: Object.values(viewConfig.viewDefs || {}).reduce(
            (prev, { entity, name, definition }) => {
                if (viewConfig.entities[entity] && !viewConfig.previewMeta?.viewsChanged?.[name]) {
                    const viewDefinition: View = convert(JSON.parse(definition), viewConfig);
                    prev[name] = viewDefinition;
                }
                return prev;
            },
            { ...viewConfig.views },
        ),
    };
});

export const OverriddenViewConfigContext = React.createContext<undefined | ViewConfig>(undefined);

/**
 * global viewConfig selector
 */
export const viewConfigSelector = createSelector(
    (state: RootState) => state.viewConfig,
    (viewConfig) => {
        if (viewConfig.entities && viewConfig.views) {
            const withViewDefsApplied = overwriteViewsWithViewDefs(viewConfig);
            return injectMrgListViewsFromMatchViews(withViewDefsApplied);
        }
        return viewConfig;
    },
);

const useViewConfig = (useOverridden = true) => {
    const overriddenVC = useContext(OverriddenViewConfigContext);
    const vc = useSelector((state: RootState) => viewConfigSelector(state));
    if (!useOverridden) {
        return vc;
    }
    return overriddenVC || vc;
};
export default useViewConfig;
