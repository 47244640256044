import { fromNullable, tryCatch } from 'fp-ts/lib/Option';
import getExpansionsFromFilter from 'isomorphic-query-filters/expand';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';

export const getNeededInFilter = <FieldDefinition extends { params?: { filter?: string } }>(f: FieldDefinition) =>
    fromNullable(f.params)
        .mapNullable((params) => params.filter || null)
        .map((filter) => getExpansionsFromFilter(filter))
        .getOrElse([]);

export const getConfiguredExpansions = (f: FormFieldUnion) =>
    fromNullable(f.params)
        .mapNullable((p) => p.configs)
        .mapNullable((c) => c.fieldConfig)
        .chain((fc) => tryCatch(() => JSON.parse(fc)))
        .mapNullable((fc) => fc.expansions)
        .getOrElse([]);
