import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

interface WithCaseTitleProps {
    processId;
    children: (args: { title?: string }) => JSX.Element | null;
}

const WithCaseTitle = ({ processId, children }: WithCaseTitleProps) => {
    const title = useSelector((state: RootState) => {
        const { entities } = state.admin;
        return fromNullable(entities.ProcessInstance)
            .mapNullable((pi) => pi[processId] as any)
            .chain((p) => fromNullable(entities.AppCase).mapNullable((ac) => ac[p.appCaseId]))
            .mapNullable((appCase) => appCase.title)
            .toUndefined();
    });
    return children({ title });
};
export default WithCaseTitle;
