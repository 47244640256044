import React, { useCallback } from 'react';
import { saveAs } from 'file-saver';
import { Button, ButtonProps } from '@material-ui/core';
import Save from '@material-ui/icons/Save';

interface JsonDownloadProps {
    json: {} | [];
    title?: string;
    ButtonProps?: Partial<ButtonProps>;
}
const JsonDownload: React.SFC<JsonDownloadProps> = ({ json, title, ButtonProps }) => {
    const handleClick = useCallback(() => {
        let fileName = prompt('Filename:', '');
        if (fileName.endsWith('.json')) {
            fileName = fileName.slice(0, '.json'.length * -1);
        }
        const stringState = JSON.stringify(json, null, 2);
        saveAs(new Blob([stringState], { type: 'application/json' }), `${fileName}.json`);
    }, [json]);
    return (
        <Button endIcon={<Save />} variant="contained" color="primary" {...ButtonProps} onClick={handleClick}>
            {title || 'Export JSON'}
        </Button>
    );
};
export default JsonDownload;
