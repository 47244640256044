import React, { useContext, useEffect } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { loadValueSets } from 'valueSets/actions';
import { GetHandleTitleClickProps } from './GetHandleTitleClickProps';

const CARD_HEADER_OUTER_HEIGHT = '63px';
export const ShowWidgetsContext = React.createContext(true);

interface WidgetizeProps<TitleClickProps> {
    layout: {
        cardStyle?: React.CSSProperties;
        contentStyle?: React.CSSProperties;
    };
    title:
        | {
              title: string;
              GetHandleTitleClick?: React.ComponentType<GetHandleTitleClickProps & TitleClickProps>;
              GetHandleTitleClickProps?: TitleClickProps;
          }
        | 'none';
    children: JSX.Element;
    prefetchValuesets?: string[];
}
const WidgetizeComponent = <TitleClickProps extends {}>(props: WidgetizeProps<TitleClickProps>) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.prefetchValuesets && Array.isArray(props.prefetchValuesets)) {
            dispatch(loadValueSets(props.prefetchValuesets.map((valueSet) => ({ valueSet }))));
        }
    }, [props.prefetchValuesets, dispatch]);
    const showWidgetContent = useContext(ShowWidgetsContext);
    const getContentStyle = (): React.CSSProperties => {
        const {
            layout: { contentStyle: _contentStyle },
            title,
        } = props;
        return {
            overflow: 'auto',
            height: title === 'none' ? '100%' : `calc(100% - ${CARD_HEADER_OUTER_HEIGHT})`,
            ..._contentStyle,
        };
    };
    const getCardStyle = (): React.CSSProperties => {
        const {
            layout: { cardStyle },
        } = props;
        return showWidgetContent
            ? { width: '100%', height: '100%', boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.15)', ...cardStyle }
            : { display: 'none' };
    };
    const getCardHeader = (): JSX.Element | null => {
        const { title } = props;
        if (title !== 'none') {
            if (title.GetHandleTitleClick) {
                const { GetHandleTitleClickProps } = title;
                return (
                    <title.GetHandleTitleClick
                        render={({ handleTitleClick }) => (
                            <CardHeader
                                onClick={handleTitleClick}
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                color="primary"
                                titleTypographyProps={{
                                    component: 'h2',
                                }}
                                title={title.title}
                            />
                        )}
                        {...GetHandleTitleClickProps}
                    />
                );
            }
            return (
                <CardHeader
                    style={{ fontWeight: 'bold' }}
                    color="inherit"
                    titleTypographyProps={{
                        component: 'h2',
                    }}
                    title={title.title}
                />
            );
        }
        return null;
    };
    return (
        <Card style={getCardStyle()}>
            {getCardHeader()}
            <div style={getContentStyle()}>{props.children}</div>
        </Card>
    );
};

export default WidgetizeComponent;
