import React from 'react';
import { Card, Button, CardHeader } from '@material-ui/core';
import ListAlt from '@material-ui/icons/ListAlt';
import Edit from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import LabelImportant from '@material-ui/icons/LabelImportant';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
const ViewDefinitionManagement = (props) => {
    const secretMode = useExpressionTesterOpen() !== 'CLOSED';
    return (
        <Card style={{ marginBottom: '1em' }}>
            <CardHeader title="Manage View Definitions" />
            <div style={{ height: '1em' }} />
            <div style={{ paddingBottom: '1em', display: 'flex', justifyContent: 'space-evenly' }}>
                {secretMode && (
                    <>
                        <div>
                            <Button component={Link} to="/review-viewdefs" variant="contained" color="primary">
                                <ListAlt />
                                &nbsp;Review View Definitions
                            </Button>
                        </div>
                        <div>
                            <Button component={Link} to="/migrate-entityviews" variant="contained" color="primary">
                                <LabelImportant />
                                &nbsp;Migrate EntityViews to View Definitions
                            </Button>
                        </div>
                    </>
                )}
                <div>
                    <Button component={Link} to="/view-definition-editor" variant="contained" color="primary">
                        <Edit />
                        &nbsp;Build a new View or Edit an existing one
                    </Button>
                </div>
            </div>
        </Card>
    );
};
export default ViewDefinitionManagement;
