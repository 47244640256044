import { put } from 'redux-saga/effects';
import { taskEventType as taskEvent } from '../../actions/taskEvent';
import sagaFactory, { configType, HttpVerb } from '../../../sagas/util/sagaFactory';
import {
    // mapToAppType,
    TaskFromRestTask,
} from '../../dataAdapters/network/rest/tasks/entities/task';
import { getTaskForm } from 'bpm/task-form/actions';
import { ErrorDetails } from '../submitTaskFormSaga';

type payloadType = { taskId: string; userId: string };
const config: configType = {
    actionType: taskEvent.assign,
    verb: HttpVerb.PUT,
    url: (payload: payloadType) => `api/bpm/tasks/${payload.taskId}/action/assign`,
    body: (payload: payloadType) => ({
        assignee: payload.userId,
    }),
    success: function* (payload: payloadType, responseBody: TaskFromRestTask) {
        yield put({ type: taskEvent.get, payload: payload.taskId });
        yield put(getTaskForm(payload.taskId));
        /*
        const res = mapToAppType(responseBody);
        // filter out undefined keys because we don't get all the data back.
        Object.keys(res).forEach(key =>
            typeof res[key] === 'undefined' && delete res[key]
        );
        yield put({ type: taskEvent.assignSuccess, payload: res });
        */
    },
    failure: (payload, errorDetails: ErrorDetails) => {
        if (errorDetails && typeof errorDetails.error === 'object' && errorDetails.error.status === 403) {
            alert(
                'This task does not exist or is currently unavailable for you. Please refresh the page to load updates.',
            );
        }
        return {
            type: taskEvent.assignFailure,
        };
    },
};

const saga = sagaFactory(config);
export default saga;
