import React, { Suspense } from 'react';
import type { SpelEditorProps } from './SpelEditor';

const Component = React.lazy(() => import('./SpelEditor'));

const LazySpelEditor = (props: SpelEditorProps) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    );
};
export default LazySpelEditor;
