import * as React from 'react';
import { connect } from 'react-redux';
import { DateFormatBroadcast } from './Broadcasts';
import Moment from 'moment';
import { RootState } from 'reducers/rootReducer';

class DateFormatProviderComponent extends React.Component<{ viewConfig?: { application?: { dateFormat?: string } } }> {
    render() {
        return (
            <DateFormatBroadcast
                value={(Moment() as any).toMomentFormatString(
                    ((this.props.viewConfig || {}).application || {}).dateFormat || 'MM/dd/yyyy',
                )}
            >
                {this.props.children}
            </DateFormatBroadcast>
        );
    }
}
const DateFormatProvider = connect(({ viewConfig }: RootState) => ({
    viewConfig,
}))(DateFormatProviderComponent);

export default DateFormatProvider;
