import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { clearSessionStorage, getStorage } from 'storage/storage';
import { CLEAR_STORE_BUT_KEEP_LOCATION } from 'actions/constants';
import useWidth from 'util/hooks/useWidth';
import { Typography, createStyles, makeStyles } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';
import Message from 'i18n/components/Message';

const useStyles = makeStyles((theme) =>
    createStyles({
        root100: {
            width: '100%',
            height: '100%',
        },
        centerArea: {
            paddingTop: '50px',
            textAlign: 'center',
        },
    }),
);

const LoggedOutSplash: React.FC<{}> = () => {
    const classes = useStyles();
    const width = useWidth();
    const isMobile = width === 'sm' || width === 'xs';
    return (
        <div className={classes.root100}>
            <div className={classes.centerArea}>
                <Typography variant={isMobile ? 'h5' : 'h4'} component="div">
                    <Message
                        id="auth.logout.success"
                        dm="Logged out successfully."
                        defaultMessage="You have logged out successfully."
                    />
                </Typography>
            </div>
        </div>
    );
};

export interface UserLogoutRedirectRouteProps {
    location: Location;
}

const UserLogoutRedirectRoute = (props: UserLogoutRedirectRouteProps) => {
    const logoutRedirectUrl = useSelector((state: RootState) => {
        return state.basicInfo?.logoutRedirectUrl ?? '';
    });
    const store = useStore();
    const [showLoggedOutMsg, setShowLoggedOutMsg] = useState(false);
    useEffect(() => {
        // Lets push this onto the event queue because we want cleanup to happen for FormSaveNotifier (otherwise it prevents navigation away).
        const to = setTimeout(() => {
            getStorage().clear();
            clearSessionStorage();
            if (logoutRedirectUrl) {
                window.location.href =
                    // so, '/login', '/clppp/login', etc.
                    logoutRedirectUrl && logoutRedirectUrl.startsWith('/') && logoutRedirectUrl.endsWith('login')
                        ? // props.location.search here is something like
                          // ?redirectTo=%2Fprocesses%3Ffilter%3D%257B%2522processInstance.businessKey%2522%253A%2522ei_enrollment%2522%257D
                          // we will pass this to the login page
                          logoutRedirectUrl + `${props.location.search || ''}`
                        : logoutRedirectUrl;
            } else {
                // Window close works only in IE, not Chrome or Firefox.
                window.open('', '_self', '');
                window.close();

                // If the window cannot close, let's show something, and clear store.
                store.dispatch({ type: CLEAR_STORE_BUT_KEEP_LOCATION });
                setShowLoggedOutMsg(true);
            }
        }, 0);
        return () => {
            clearTimeout(to);
        };
    }, []); // eslint-disable-line

    if (showLoggedOutMsg) {
        return <LoggedOutSplash />;
    }
    return null;
};

export default UserLogoutRedirectRoute;
