import React from 'react';

interface AsyncEventsInProgressContext {
    eventCount: number;
    increment: () => void;
    decrement: () => void;
}
const stub = (fortype: 'increment' | 'decrement') => () => {
    console.error(`asyncEventsInProgress: "${fortype}" called outside of context provider`);
    return null;
};
export const asyncEventsInProgressContext = React.createContext<AsyncEventsInProgressContext>({
    eventCount: 0,
    increment: stub('increment'),
    decrement: stub('decrement'),
});
interface AsyncEventsInProgressProviderProps {}
interface AsyncEventsInProgressProviderState {
    eventCount: number;
}

export class AsyncEventsInProgressContextProvider extends React.Component<
    AsyncEventsInProgressProviderProps,
    AsyncEventsInProgressProviderState
> {
    state = {
        eventCount: 0,
    };
    getIncrementBy = (amount: number) => () => {
        this.setState((state) => ({
            eventCount: state.eventCount + amount,
        }));
    };

    render() {
        const { eventCount } = this.state;
        return (
            <asyncEventsInProgressContext.Provider
                value={{
                    eventCount,
                    increment: this.getIncrementBy(1),
                    decrement: this.getIncrementBy(-1),
                }}
            >
                {this.props.children}
            </asyncEventsInProgressContext.Provider>
        );
    }
}
export const asyncEventsInProgressContextHoc = (BaseComponent) => (props) =>
    (
        <AsyncEventsInProgressContextProvider>
            <BaseComponent {...props} />
        </AsyncEventsInProgressContextProvider>
    );
