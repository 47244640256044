import React, { FunctionComponent, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, CircularProgress, Divider, TextField, Typography, useTheme } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { RootState } from 'reducers/rootReducer';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getLoginLogoSelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { useRedirectTo } from './useRedirectTo';
import { PasswordResetButton } from './PasswordResetButton';
import { useFormatMessage } from 'react-intl-hooks';
import useBasicInfo from 'util/hooks/useBasicInfo';
import { AuthFormProps, FormData } from './LoginFormProps';

const AuthForm: FunctionComponent<AuthFormProps> = (props) => {
    const { onSubmit, initialData, renderForm } = props;
    const isLoading = useSelector((state: RootState) => state.admin.loading > 0);
    const methods = useForm<FormData>({
        defaultValues: initialData,
        mode: 'onChange',
    });
    const { errors } = methods;
    const theme = useTheme();
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const loginLogo = useSelector(getLoginLogoSelector);
    const appLogo = useSelector((state: RootState) => state.basicInfo?.logo ?? '/logo.jpeg');
    const appTitle = useSelector((state: RootState) => state.basicInfo?.application?.title);
    const t = useFormatMessage();
    const basicInfo = useBasicInfo();
    const redirectTo = useRedirectTo();
    const renderSaml2Logins = () => {
        if (!basicInfo.saml2Idps || !Object.keys(basicInfo.saml2Idps).length) {
            return null;
        }

        return (
            <div>
                {basicInfo.formLoginEnabled === false ? null : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '100%' }}>
                            <Divider style={{ marginTop: '.6rem' }} />
                        </div>
                        <div style={{ margin: '0 .5rem' }}>
                            <Typography>OR</Typography>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Divider style={{ marginTop: '.6rem' }} />
                        </div>
                    </div>
                )}
                {Object.entries(basicInfo.saml2Idps).map(([text, url]) => {
                    const queryString = redirectTo ? `?RelayState=${encodeURIComponent(redirectTo)}` : '';
                    return (
                        <div key={text + ',' + url} style={{ width: '100%', padding: theme.spacing(1) }}>
                            <Button href={url + queryString} variant="contained" color="primary" fullWidth>
                                {text}
                            </Button>
                        </div>
                    );
                })}
            </div>
        );
    };
    const forgotPasswordPrompt = t({ id: 'auth.login.forgotPasswordPrompt' }) as string;
    return (
        <form style={{ height: '100%' }} autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
            {renderForm({
                BelowLoginFormElement: renderSaml2Logins(),
                PasswordResetButtonElement: (
                    <PasswordResetButton<FormData>
                        hookFormDefaultValue={get(initialData, 'username') ?? ''}
                        hookFormFieldName="username"
                        hookFormControl={methods.control}
                        renderButton={({ openDialog }) => (
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth={true}
                                onClick={openDialog}
                                aria-label={forgotPasswordPrompt}
                            >
                                {forgotPasswordPrompt}
                            </Button>
                        )}
                    />
                ),
                Logo: loginLogo ? (
                    <SafeHtmlAsReact html={loginLogo} />
                ) : (
                    <img src={appLogo} height="auto" width={300} alt={appTitle ? `${appTitle} Logo` : ''} />
                ),
                UsernameElement: (
                    <Controller
                        id="auth-login-input"
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        label={t({ id: 'auth.login.login' })}
                        variant={fieldVariant}
                        name="username"
                        fullWidth
                        control={methods.control}
                        defaultValue={get(initialData, 'username') ?? ''}
                        margin="normal"
                        as={TextField}
                        rules={{
                            required: `${t({ id: 'validate.error' })}: ${t({ id: 'auth.login.login' })} ${t({
                                id: 'validate.required',
                            })}`,
                        }}
                        error={Boolean(get(errors, 'username'))}
                        helperText={get(errors, 'username')?.message}
                    />
                ),
                PasswordElement: (
                    <Controller
                        id="auth-password-input"
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        label={t({ id: 'auth.login.password' })}
                        type="password"
                        name="password"
                        variant={fieldVariant}
                        fullWidth
                        control={methods.control}
                        defaultValue={get(initialData, 'password') ?? ''}
                        margin="normal"
                        as={TextField}
                        rules={{
                            required: `${t({ id: 'validate.error' })}: ${t({ id: 'auth.login.password' })} ${t({
                                id: 'validate.required',
                            })}`,
                        }}
                        error={Boolean(get(errors, 'password'))}
                        helperText={get(errors, 'password')?.message}
                    />
                ),
                SubmitButtonElement: (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        id="submit"
                        fullWidth={true}
                    >
                        {t({ id: 'auth.login.signIn' })}
                        {isLoading && <CircularProgress size={25} thickness={2} />}
                    </Button>
                ),
            })}
        </form>
    );
};
export default AuthForm;
