import React, { useMemo, FunctionComponent, useReducer } from 'react';
import { Button, IconButton } from '@material-ui/core';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import ListView from 'templatePage/templatableComponents/ListView';
import { FormProvider, useForm } from 'react-hook-form';
import ListViewConfigController from 'layout-editor/listview-builder/ConfigEditor';
import { SearchValidations } from 'reducers/searchValidationsReducer';
import getValidation from 'sagas/util/getValidation';
import { getValidationExpForSearch } from 'components/generics/utils/viewConfigUtils';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Refresh from '@material-ui/icons/Refresh';
import useEditViewInPlace from 'expression-tester/entity-form/hooks/useEditViewInPlace';
import Popup from 'components/Popup';
import { expressionTesterOpenContext } from 'expression-tester/hooks/useExpressionTesterOpen';
import Edit from '@material-ui/icons/Edit';
import Result from './Result';
import Save from '@material-ui/icons/Save';
import deepEql from 'fast-deep-equal';
import ControlledListLayoutEditor from '../util/ControlledListFieldsEditor';
import Clear from '@material-ui/icons/Clear';

interface OverrideSearchValidationsContext {
    [viewName: string]: SearchValidations[0];
}
export const overrideSearchValidationsContext = React.createContext<OverrideSearchValidationsContext>({});

const useOverriddenSearchValidations = (viewName: string, entityViewConfigStr: string, viewConfig: ViewConfig) => {
    const overriddenSearchValidations: OverrideSearchValidationsContext = useMemo(() => {
        return fromPredicate<string>(Boolean)(entityViewConfigStr)
            .chain((conf) => tryCatch(() => JSON.parse(conf)))
            .chain((c) => fromPredicate<string>(Boolean)(c.searchValidations))
            .chain((sv) => {
                return getValidation(
                    viewName,
                    viewConfig.views[viewName].entity,
                    viewConfig,
                    tryCatch(() => getValidationExpForSearch(viewConfig, viewName)),
                );
            })
            .getOrElse({});
    }, [viewName, viewConfig, entityViewConfigStr]);
    return overriddenSearchValidations;
};

const useOverrideViewConfig = (initialValues: Partial<View>, view: Partial<View>) => {
    const viewConfig = useViewConfig();
    const overrideViewConfig = useMemo(() => {
        if (deepEql(viewConfig.views[view.name], { ...initialValues, ...view })) {
            return viewConfig;
        }
        return produce(viewConfig, (draft) => {
            draft.views[initialValues.name] = {
                ...initialValues,
                ...view,
            } as View;
            return draft;
        });
    }, [viewConfig, view, initialValues]);
    return overrideViewConfig;
};

const canRenderView = (viewValues: Partial<View>, viewConfig: ViewConfig) => {
    return viewValues.viewType && viewValues.entity && viewValues.name && viewConfig.views[viewValues.name];
};

interface Step3ListProps {
    initialValues: Partial<View>;
    onSubmit: (action: {
        type: 'replace' | 'write';
        payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
    }) => void;
}
const Step3List: FunctionComponent<Step3ListProps> = (props) => {
    const { initialValues, onSubmit: _onSubmit } = props;
    const methods = useForm({
        defaultValues: initialValues,
    });
    const { handleSubmit, watch } = methods;
    const view = watch() as View;

    const onSubmit = (data) => {
        _onSubmit({ type: 'write', payload: { ...initialValues, ...data } });
    };
    const overrideViewConfig = useOverrideViewConfig(initialValues, view);
    // Our EditableViewFormLayout looks for an overridden view-config to get 'fields' it edits and operates on,
    // so we have to fake it by providing a special overridenViewContext where fields is really our searchFields
    const overriddenSearchValidations: OverrideSearchValidationsContext = useOverriddenSearchValidations(
        initialValues.name,
        view.config,
        overrideViewConfig,
    );
    const [listViewKey, refresh] = useReducer((state) => state + 1, 1);
    if (!canRenderView(initialValues, overrideViewConfig)) {
        return <div>Please fill out step 1.</div>;
    }

    return (
        <div>
            <FormProvider {...methods}>
                <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                    <div style={{ padding: '1em', margin: '1em' }}>
                        <h2>Step 3: Configure</h2>
                        <ListViewConfigController viewName={initialValues.name} entity={initialValues.entity} />
                    </div>
                    <div style={{ marginTop: '1em', paddingTop: '1em', width: '100%', textAlign: 'right' }}>
                        <Button id="step3-submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                            Submit&nbsp;
                            <NavigateNext />
                        </Button>
                    </div>
                    <div style={{ padding: '1em', margin: '1em' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h2>Preview Changes Below</h2>&nbsp;
                            <IconButton aria-label="refresh" onClick={refresh}>
                                <Refresh />
                            </IconButton>
                        </div>
                        <NavWarning />
                        <expressionTesterOpenContext.Provider value="OPEN_EXPRESSIONPANEL">
                            <overrideSearchValidationsContext.Provider value={overriddenSearchValidations}>
                                <ListView key={listViewKey} viewName={initialValues.name} showActions />
                            </overrideSearchValidationsContext.Provider>
                        </expressionTesterOpenContext.Provider>
                    </div>
                </OverriddenViewConfigContext.Provider>
            </FormProvider>
        </div>
    );
};

export default Step3List;

export const ListExpressionEditor: FunctionComponent<{
    viewName: string;
    children: (props: { open: boolean }) => JSX.Element;
}> = ({ viewName, children }) => {
    const { open, manuallyOpen, Provider } = useEditViewInPlace();
    const viewConfig = useViewConfig();
    const initialValues = viewConfig.views[viewName];
    const methods = useForm({
        defaultValues: initialValues,
        /**
         * Had to set shouldUnregister: false because otherwise our fields and searchFields data
         * are be deleted from our avlues when 'setValue' is called setting 'config'.
         * Not sure exactly why, but I think it thinks the fields have been unmounted,
         * because it doesn't detect them with a ref or register/unregister call.
         */
        shouldUnregister: false,
    });
    const { handleSubmit, watch, reset, register, unregister, setValue } = methods;
    const view = watch() as View;
    const overrideViewConfig = useOverrideViewConfig(initialValues, view);
    const overriddenSearchValidations: OverrideSearchValidationsContext = useOverriddenSearchValidations(
        initialValues.name,
        view.config,
        overrideViewConfig,
    );
    const [listViewKey, refresh] = useReducer((state) => state + 1, 1);

    if (!canRenderView(initialValues, overrideViewConfig)) {
        return <div>Something went wrong.</div>;
    }

    return (
        <div>
            <FormProvider {...methods}>
                <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                    {open !== 'CLOSED' && (
                        <div style={{ padding: '1em', margin: '1em' }}>
                            <ListViewConfigController viewName={initialValues.name} entity={initialValues.entity} />
                        </div>
                    )}
                    {open !== 'CLOSED' && (
                        <div style={{ margin: '1em 0', padding: '1em' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <Popup
                                        ComponentProps={{
                                            fullWidth: true,
                                        }}
                                        renderDialogContent={({ closeDialog }) => (
                                            <div>
                                                <IconButton
                                                    size="small"
                                                    style={{ float: 'right', marginRight: '1em' }}
                                                    aria-label="close"
                                                    onClick={closeDialog}
                                                >
                                                    <Clear />
                                                </IconButton>
                                                <ControlledListLayoutEditor
                                                    view={overrideViewConfig.views[viewName]}
                                                    onChange={(action) => {
                                                        Object.entries(action.payload).forEach(([k, v]) => {
                                                            setValue(k, v, {
                                                                shouldDirty: true,
                                                            });
                                                        });
                                                        refresh();
                                                    }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ float: 'right', margin: '1em' }}
                                                    onClick={closeDialog}
                                                >
                                                    Close
                                                </Button>
                                            </div>
                                        )}
                                        renderToggler={({ openDialog }) => (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                endIcon={<Edit />}
                                                onClick={openDialog()}
                                            >
                                                Edit Layout
                                            </Button>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Popup
                                        renderDialogContent={({ closeDialog }) => {
                                            const entityVersion =
                                                overrideViewConfig.viewDefs?.[viewName]?.entityRevision;
                                            return (
                                                <Result
                                                    mode="PUT"
                                                    initialValues={{
                                                        ...overrideViewConfig.views[viewName],
                                                        entityVersion,
                                                    }}
                                                    prevViewName={viewName}
                                                    overrideViewConfig={overrideViewConfig}
                                                    diffFrom="provided"
                                                    old={initialValues}
                                                />
                                            );
                                        }}
                                        renderToggler={({ openDialog }) => (
                                            <Button
                                                endIcon={<Save />}
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                onClick={openDialog()}
                                            >
                                                Review and Save Changes
                                            </Button>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        {open !== 'CLOSED' && (
                            <div style={{ textAlign: 'center', marginBottom: '.5em' }}>
                                <Button onClick={refresh} endIcon={<Refresh />}>
                                    Remount List
                                </Button>
                            </div>
                        )}
                        <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                            <overrideSearchValidationsContext.Provider value={overriddenSearchValidations}>
                                <Provider key={listViewKey}>{children({ open: manuallyOpen !== 'CLOSED' })}</Provider>
                            </overrideSearchValidationsContext.Provider>
                        </OverriddenViewConfigContext.Provider>
                    </div>
                </OverriddenViewConfigContext.Provider>
            </FormProvider>
        </div>
    );
};
