import { RootAction } from 'actions/rootAction';
import { unsetCurrentlyWritingToOffline } from 'offline_app/offline_stateful_tasks/currentlyWritingToOfflineState/actions';
import { getType } from 'typesafe-actions';
import { setEntitySubmitsInTaskContext, unsetEntitySubmitsInTaskContext } from './actions';
import { Target } from './Entry';

const setErrorsInTargets = (t: Target, error: Target['error']): Target => {
    if (t.tag === 'link') {
        return {
            ...t,
            error,
            next: setErrorsInTargets(t.next, error),
        };
    }
    return {
        ...t,
        error,
    };
};

type EntitySubmitsInTaskContext =
    | {
          type: 'loaded';
          taskId: string;
          entries: Target[];
      }
    | {
          type: 'none';
      };
const entitySubmitsInTaskContextReducer = (
    state: EntitySubmitsInTaskContext = { type: 'none' },
    action: RootAction,
): EntitySubmitsInTaskContext => {
    switch (action.type) {
        case getType(setEntitySubmitsInTaskContext): {
            const entries = action.payload.entries.reduce((prev, entry) => {
                if (entry.taskId === action.payload.taskId) {
                    const newTarget = entry.error ? setErrorsInTargets(entry.target, entry.error) : entry.target;
                    prev.push(newTarget);
                }
                return prev;
            }, [] as Target[]);
            return {
                type: 'loaded',
                taskId: action.payload.taskId,
                entries,
            };
        }
        case getType(unsetCurrentlyWritingToOffline):
        case getType(unsetEntitySubmitsInTaskContext):
            return {
                type: 'none',
            };
        default:
            return state;
    }
};

export default entitySubmitsInTaskContextReducer;
