import { createContext } from 'react';

interface ProcessContext {
    id?: string;
    taskId?: string;
    refresh: () => void;
}
export const processContext = createContext<ProcessContext>({
    refresh: () => null,
});
