export interface ViewEditableExps {
    [viewName: string]: {
        [fieldName: string]: {
            valuesetFieldsRequired: any;
            expression: string;
            fieldName: string;
            valuesetLiterals: string[];
            dataPaths: string[];
            expansionsRequired: string[];
        }[];
    };
}
export default (previousState: ViewEditableExps = {}, { type, payload }): ViewEditableExps => {
    if (type === 'ENTITY_EDITIBILITY_GENERATED') {
        return payload;
    }
    return previousState;
};
