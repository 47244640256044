import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dialog, TextField, IconButton, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { getRenameTaskButton } from './rename';
import { taskEventCreator } from '../../../../actions/taskEvent';
import Edit from '@material-ui/icons/Edit';
import { RootState } from '../../../../../reducers/rootReducer';

interface RenameableTaskNameProps {
    taskId: string;
    rootStyle?: {};
    isAdmin?: boolean;
}
const mapStateToProps = (state: RootState, { taskId }: RenameableTaskNameProps) => ({
    task: taskId && state.bpm.tasks.byId[taskId],
});
const mapDispatchToProps = (dispatch, ownProps) => ({
    refresh: () => dispatch(taskEventCreator.getTask(ownProps.taskId)),
});

type DialogOpen =
    | {
          _tag: 'EditTaskName';
          name: string;
      }
    | false;

type EditTaskNameProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    RenameableTaskNameProps;

interface EditTaskNameState {
    dialogOpen: DialogOpen;
}

class EditTaskNameComponent extends React.Component<EditTaskNameProps, EditTaskNameState> {
    constructor(props: EditTaskNameProps) {
        super(props);
        this.state = {
            dialogOpen: false,
        };
    }
    handleNameChange = (e) => {
        const name = (e.target && e.target.value) || '';
        this.setState((prevState) => ({
            dialogOpen: prevState.dialogOpen && {
                ...prevState.dialogOpen,
                name,
            },
        }));
    };
    openDialog =
        (initialName: string = '') =>
        () => {
            this.setState({
                dialogOpen: {
                    _tag: 'EditTaskName',
                    name: initialName,
                },
            });
        };
    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };
    renderDialog = () => {
        const { dialogOpen } = this.state;
        const { taskId, refresh } = this.props;
        if (!dialogOpen) {
            return null;
        }
        return (
            <Dialog
                onClose={this.closeDialog}
                open={true}
                maxWidth={false}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <Card style={{ paddingTop: '1em', minWidth: 'calc(180px + calc(100vw - 180px) / 3)' }}>
                    <CardContent>
                        <TextField
                            id="editNameOfCompletedTask"
                            label="Task Name"
                            fullWidth={true}
                            value={dialogOpen.name || ''}
                            onChange={this.handleNameChange}
                        />
                    </CardContent>
                    <CardActions>
                        <Button color="default" onClick={this.closeDialog}>
                            Close
                        </Button>
                        {getRenameTaskButton(taskId, refresh)(dialogOpen.name)}
                    </CardActions>
                </Card>
            </Dialog>
        );
    };
    render() {
        const { task, rootStyle, isAdmin = true } = this.props;
        if (!task) {
            return null;
        }
        return (
            <React.Fragment>
                {this.renderDialog()}
                <div style={{ display: 'flex', ...rootStyle }}>
                    <TextField
                        disabled={true}
                        id="disabled-completed-task-name"
                        InputLabelProps={{ disabled: false }}
                        label="Task Name"
                        value={task && task.name}
                        defaultValue={task && task.name}
                        margin="none"
                        style={{ minWidth: '90%' }}
                    />
                    {isAdmin && (
                        <IconButton
                            aria-label="task name"
                            onClick={this.openDialog(task.name)}
                            style={{ width: 38, height: 38 }}
                        >
                            <Edit />
                        </IconButton>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const EditTaskName: React.SFC<RenameableTaskNameProps> = compose(connect(mapStateToProps, mapDispatchToProps))(
    EditTaskNameComponent,
);
export default EditTaskName;
