import { createCustomAction } from 'typesafe-actions';
import { ADD_OFFLINE_META, REMOVE_OFFLINE_META } from './constants';
import { OfflineMeta } from './domain';

export const addOfflineMeta = createCustomAction(ADD_OFFLINE_META, (type) => {
    return (taskId: string, taskMeta: OfflineMeta[string]) => ({
        type,
        payload: {
            taskId,
            taskMeta,
        },
    });
});

export const removeOfflineMeta = createCustomAction(REMOVE_OFFLINE_META, (type) => {
    return () => ({
        type,
    });
});
