import { AttributeSecurity } from 'dashboard2/dashboard-config/types';
import JsonSchemaForm from 'fieldFactory/input/components/JsonSchemaForm/JsonSchemaForm';
import React, { useMemo } from 'react';
const attributeSecurityFormSchemas = require('./formschema.json');
const schema = JSON.parse(attributeSecurityFormSchemas.schema);
const uiSchema = JSON.parse(attributeSecurityFormSchemas.uiSchema);

interface AttributeSecurityFormProps {
    value?: AttributeSecurity;
    onChange?: (value: AttributeSecurity) => void;
}
const AttributeSecurityForm: React.FC<AttributeSecurityFormProps> = ({ value, onChange }) => {
    const input = useMemo(() => {
        return {
            value: JSON.stringify(value) as any,
            onChange: (value: any) => onChange(JSON.parse(value)),
            onBlur: (value) => onChange(JSON.parse(value)),
        };
    }, [value, onChange]);
    return <JsonSchemaForm schema={schema} uiSchema={uiSchema} input={input} />;
};
export default AttributeSecurityForm;
