import * as React from 'react';
import { Selector, connect } from 'react-redux';
import FormField from './util/FormField';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { formContext } from '../bpm/components/TaskDetail/TaskForm/FormContext';
import uniqueId from 'lodash/uniqueId';
import { RootState } from 'reducers/rootReducer';

export const standardPadding = {
    paddingLeft: 'calc(.5em + 4px)',
    paddingRight: 'calc(.5em + 4px)',
};

const mapToFormField =
    (
        {
            record,
            resource,
            basePath,
            replacePeriodsInFieldName,
            ff,
        }: {
            record: {};
            resource: string;
            basePath: string;
            replacePeriodsInFieldName?: string;
            ff?: boolean;
        },
        selector: Selector<RootState, any> | null = null,
        formContextSelector: (() => (fc: ReturnType<typeof evaluateContext2>, props?: {}) => {}) | null = null,
    ) =>
    (components) =>
        components.map((input) => {
            if (input && input.props) {
                const key = `${input.props.source}-${(input.props.isExpression && input.props.value) || uniqueId()}`;
                // const id = `${input.props.source}-${(input.props.isExpression && input.props.value)}-${uniqueId()}`;
                if (input.props.dontConnect) {
                    const WrappedNotConnected = (props) => (
                        <div style={input.props.noPad ? undefined : standardPadding}>
                            {React.cloneElement(input, props)}
                        </div>
                    );
                    return <WrappedNotConnected {...input.props} key={key} />;
                }
                let WrapperWithInputProps: any = (props) => (
                    <div
                        className={`aor-input-${input.props.source}`}
                        style={{
                            ...input.props.style,
                            ...(input.props.noPad ? undefined : standardPadding),
                        }}
                    >
                        <FormField
                            replacePeriodsInFieldName={replacePeriodsInFieldName}
                            input={input}
                            resource={resource}
                            record={record}
                            basePath={basePath}
                            ff={ff}
                            {...props}
                        />
                    </div>
                );
                if (selector) {
                    WrapperWithInputProps = connect(selector)(WrapperWithInputProps);
                }
                if (formContextSelector) {
                    const instantiatedSelector = formContextSelector();
                    const formContextAppliedHoc = (BaseComponent) => (props) =>
                        (
                            <formContext.Consumer>
                                {(fc) => <BaseComponent {...props} {...instantiatedSelector(fc, props)} />}
                            </formContext.Consumer>
                        );
                    WrapperWithInputProps = formContextAppliedHoc(WrapperWithInputProps);
                }
                return <WrapperWithInputProps {...input.props} key={key} id={null} />;
            }
            return null;
        });
export default mapToFormField;
