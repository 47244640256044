import * as React from 'react';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';

/*
    By providing this Action button to a view,
    the record in the view can be added/removed from the source in 'mergeForm'
*/

interface AddRemoveRecordButtonProps {
    data?: { id: string | number; title: string };
    source: string;
}
interface AddRemoveRecordButtonComponentProps extends AddRemoveRecordButtonProps {
    values: (string | number)[];
    changeFieldValue: Function;
}
const AddRemoveRecordButtonComponent = ({
    values,
    changeFieldValue,
    data,
    source,
}: AddRemoveRecordButtonComponentProps) =>
    values && data ? (
        <Button
            color="primary"
            onClick={() =>
                changeFieldValue(
                    values && data && data.id && values.filter((v) => v === data.id)[0]
                        ? values.filter((v) => v !== data.id)
                        : [...values, data.id],
                )
            }
        >
            {values.indexOf(data.id) === -1 ? (
                <b>Add {data && data.title} to merged record</b>
            ) : (
                <b>Remove {data && data.title} from merged record</b>
            )}
        </Button>
    ) : (
        <div />
    );

const selector = formValueSelector('mergeForm');
const AddRemoveRecordButton: React.ComponentType<AddRemoveRecordButtonProps> = connect(
    (state: RootState, ownProps: { source: string }) => ({
        values: selector(state, ownProps.source),
    }),
    (dispatch, ownProps) => ({
        changeFieldValue: (value) => dispatch(change('mergeForm', ownProps.source, value)),
    }),
)(AddRemoveRecordButtonComponent);

export default AddRemoveRecordButton;
