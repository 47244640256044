import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { AjaxError } from 'rxjs/ajax';
import { getStorage } from 'storage/storage';
import { services } from 'sideEffect/services';
import { storageController } from 'storage';
import ReactAuthCodeInput from 'fieldFactory/input/components/AuthCodeInput/ReactAuthCodeInput';
import { Button, Card, CardActions, CardContent, CircularProgress, useTheme } from '@material-ui/core';
import LoginAppBar from 'auth/components/LoginAppBar';
import { useIntl } from 'react-intl';

interface EmailConfirmProps {
    onSuccess: () => void;
    promptElement: React.ReactNode;
}

type Status =
    | {
          type: 'initial';
      }
    | {
          type: 'error';
          errorType: 'network' | 'wrongCode' | 'UNEXPECTED' | 'UNEXPECTED_mfaRequired';
      }
    | {
          type: 'pending';
      }
    | {
          type: 'success';
      };

const MfaConfirm: FunctionComponent<EmailConfirmProps> = ({ onSuccess, promptElement }) => {
    const [mfaCode, setMfaCode] = useState<string>(null);
    const idToken = useMemo(() => {
        return storageController.getToken();
    }, []);
    const authCodeAllowedCharacters = React.useMemo(() => {
        return /^[0-9]/;
    }, []);
    const [status, setStatus] = useState<Status>({ type: 'initial' });
    const authenticate = useCallback(() => {
        setStatus({ type: 'pending' });
        const $ajax = services.mfaAuthenticateService({
            idToken,
            mfaCode,
        });
        const subscription = $ajax.subscribe(
            (res) => {
                if (res.response.mfaRequired) {
                    setStatus({ type: 'error', errorType: 'UNEXPECTED_mfaRequired' });
                } else {
                    storageController.setToken(res.response.id_token);
                    (global as any).REFERRED_AUTH = true; // will tell layout to refetch viewConfig when it mounts.
                    getStorage().removeItem('mfaType');
                    getStorage().removeItem('mfaRegistration');
                    setStatus({ type: 'success' });
                    onSuccess();
                }
            },
            (error: AjaxError) => {
                if (typeof error.status === 'number' && error.status >= 400 && error.status < 500) {
                    setStatus({ type: 'error', errorType: 'wrongCode' });
                } else if (!error.status) {
                    setStatus({ type: 'error', errorType: 'network' });
                } else {
                    setStatus({ type: 'error', errorType: 'UNEXPECTED' });
                }
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [mfaCode, idToken, onSuccess]);
    const theme = useTheme();
    const intl = useIntl();
    return (
        <div>
            <div style={{ position: 'fixed', top: 0 }}>
                <LoginAppBar />
            </div>
            <div
                style={{
                    backgroundColor: theme.palette.primary.dark,
                    height: '100vh',
                    display: 'grid',
                    placeItems: 'center',
                }}
            >
                <Card>
                    <CardContent>
                        <p style={{ textAlign: 'center' }}>{promptElement}</p>
                        <ReactAuthCodeInput
                            focusOnMount
                            internalLabelPrefix={intl.formatMessage({ id: 'auth.code.authcode' })}
                            characters={6}
                            containerStyle={{
                                padding: '16px',
                            }}
                            inputStyle={{
                                width: '2ch',
                                padding: '8px',
                                borderRadius: '8px',
                                fontSize: '40px',
                                textAlign: 'center',
                                marginRight: '12px',
                                border: '1px solid black',
                                textTransform: 'uppercase',
                            }}
                            allowedCharacters={authCodeAllowedCharacters}
                            onChange={(value) => {
                                setMfaCode(value);
                            }}
                        />
                        {status.type === 'error' ? (
                            <p style={{ textAlign: 'center', color: theme.palette.error.dark }}>
                                {status.errorType === 'network'
                                    ? 'You are not connected to the internet.'
                                    : status.errorType === 'wrongCode'
                                    ? 'Wrong authentication code.'
                                    : status.errorType === 'UNEXPECTED'
                                    ? 'Unexpected error'
                                    : status.errorType === 'UNEXPECTED_mfaRequired'
                                    ? 'Unexpected state: received mfaRequired'
                                    : null}
                            </p>
                        ) : null}
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={authenticate}
                            disabled={`${mfaCode}`.length < 6 || status.type === 'pending'}
                        >
                            Submit
                            {status.type === 'pending' ? (
                                <>
                                    &nbsp;
                                    <CircularProgress style={{ width: 20, height: 20 }} />
                                </>
                            ) : null}
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </div>
    );
};

export default MfaConfirm;
