class ScrollToFieldRegistry {
    private subscriptions: {
        [fieldPath: string]: (() => void)[];
    } = {};
    constructor() {
        this.subscribe = this.subscribe.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
    }
    public scrollTo(id: string) {
        this.subscriptions[id]?.forEach((cb) => {
            cb();
        });
    }
    public subscribe(id: string, cb: () => void) {
        if (!this.subscriptions[id]) {
            this.subscriptions[id] = [];
        }
        this.subscriptions[id].push(cb);
    }
    public unsubscribe(id: string, cb: () => void) {
        if (this.subscriptions[id]) {
            this.subscriptions[id] = this.subscriptions[id].filter((scb) => scb !== cb);
            if (this.subscriptions[id].length === 0) {
                delete this.subscriptions[id];
            }
        }
    }
}

const scrollToFieldRegistry = new ScrollToFieldRegistry();
window['scrollToFieldRegistry'] = scrollToFieldRegistry;
export default scrollToFieldRegistry;
