import { Ast } from 'ts-spel/lib/lib/Ast';

const simpleTraverseAst = (cb: (node: Ast, precededByIfCompound?: Ast[]) => void) => {
    const innerSimpleTraverseAst = (node: Ast, precedingCompound?: Ast[]): void => {
        // TODO handle #this and #root
        cb(node, precedingCompound);
        switch (node.type) {
            case 'PropertyReference':
                break;
            case 'VariableReference':
            case 'NumberLiteral':
            case 'NullLiteral':
            case 'BooleanLiteral':
            case 'StringLiteral':
                break;
            case 'CompoundExpression':
                node.expressionComponents.forEach((node, i, arr) => {
                    innerSimpleTraverseAst(node, arr.slice(0, i));
                });
                break;
            case 'Elvis':
                innerSimpleTraverseAst(node.expression);
                innerSimpleTraverseAst(node.ifFalse);
                break;
            case 'MethodReference':
            case 'FunctionReference':
                node.args.forEach((n) => innerSimpleTraverseAst(n));
                break;
            case 'Indexer':
                innerSimpleTraverseAst(node.index);
                break;
            case 'InlineList':
                node.elements.forEach((n) => innerSimpleTraverseAst(n));
                break;
            case 'InlineMap':
                Object.values(node.elements).forEach((n) => innerSimpleTraverseAst(n));
                break;
            case 'Negative':
                innerSimpleTraverseAst(node.value);
                break;
            case 'OpAnd':
            case 'OpBetween':
            case 'OpDivide':
            case 'OpEQ':
            case 'OpGE':
            case 'OpGT':
            case 'OpLE':
            case 'OpLT':
            case 'OpMatches':
            case 'OpMinus':
            case 'OpModulus':
            case 'OpMultiply':
            case 'OpNE':
            case 'OpOr':
            case 'OpPlus':
                innerSimpleTraverseAst(node.left);
                innerSimpleTraverseAst(node.right);
                break;
            case 'OpNot':
            case 'Projection':
            case 'SelectionAll':
            case 'SelectionFirst':
            case 'SelectionLast':
                innerSimpleTraverseAst(node.expression);
                break;
            case 'OpPower':
                innerSimpleTraverseAst(node.base);
                innerSimpleTraverseAst(node.expression);
                break;
            case 'Ternary':
                innerSimpleTraverseAst(node.expression);
                innerSimpleTraverseAst(node.ifFalse);
                innerSimpleTraverseAst(node.ifTrue);
                break;
        }
    };
    return (node: Ast) => innerSimpleTraverseAst(node);
};

export default simpleTraverseAst;
