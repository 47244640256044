import React, { FunctionComponent, useContext } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button } from '@material-ui/core';
import { DebouncedField } from 'fieldFactory/input/components/DebouncedTextInput';
import useViewConfig from 'util/hooks/useViewConfig';
import { useValidationResolver, useStyles, UpdateMeta } from '../EditExpression';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';

export interface ExpressionData {
    expression: string;
    fieldsRequired: string[];
    compileSuccess: boolean;
}

interface EditExpressionProps {
    viewName?: string;
    initialValues?: Partial<ExpressionData>;
    onSubmit: (data: ExpressionData) => void;
}

const EditExpression: FunctionComponent<EditExpressionProps> = (props) => {
    const classes = useStyles(props);
    const { viewName, onSubmit } = props;
    const viewConfig = useViewConfig();

    const resolver = useValidationResolver(viewName, viewConfig, true);
    const methods = useForm<ExpressionData>({
        resolver,
        defaultValues: props.initialValues,
        mode: 'all',
    });
    const { errors } = methods;

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Controller
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label="Expression *"
                    variant={fieldVariant}
                    as={DebouncedField}
                    margin="normal"
                    error={Array.isArray(errors.expression) ? errors.expression.length > 0 : Boolean(errors.expression)}
                    helperText={<ErrorMessage errors={errors} name="expression" />}
                    defaultValue={props.initialValues && props.initialValues['expression']}
                    fullWidth
                    name="expression"
                    control={methods.control as any}
                />
                <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                    Save
                </Button>
            </form>
            <UpdateMeta />
            <pre className={classes.error}>{errors['fieldsRequired']}</pre>
        </FormProvider>
    );
};
export default EditExpression;
