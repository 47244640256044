import { Card, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import ListOfOfflineTasks from './ListOfOfflineTasks';

const ListOfOfflineTasksPage = () => {
    return (
        <Card>
            <CardHeader title="My Offline Work" />
            <CardContent>
                <ListOfOfflineTasks />
            </CardContent>
        </Card>
    );
};
export default ListOfOfflineTasksPage;
