import { createCustomAction } from 'typesafe-actions';
import {
    GET_PUBLIC_GLOBAL_ALERTS,
    GET_PUBLIC_GLOBAL_ALERTS_FAILURE,
    GET_PUBLIC_GLOBAL_ALERTS_SUCCESS,
} from './constants';
import { AjaxError } from 'rxjs/ajax';
import { GlobalAlert } from '../definitions';

export const getPublicGlobalAlerts = createCustomAction(GET_PUBLIC_GLOBAL_ALERTS, type => {
    return () => ({
        type,
    });
});

export const getPublicGlobalAlertsFailure = createCustomAction(GET_PUBLIC_GLOBAL_ALERTS_FAILURE, type => {
    return (error: AjaxError) => ({
        type,
        error,
    });
});

export const getPublicGlobalAlertsSuccess = createCustomAction(GET_PUBLIC_GLOBAL_ALERTS_SUCCESS, type => {
    return (globalAlerts: GlobalAlert[]) => ({
        type,
        payload: {
            globalAlerts,
        },
    });
});
