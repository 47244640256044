export const SET_SORT = 'SET_SORT' as 'SET_SORT';
export const SORT_ASC = 'ASC' as 'ASC';
export const SORT_DESC = 'DESC' as 'DESC';

export const SET_PAGE = 'SET_PAGE';

export const SET_PER_PAGE = 'SET_PER_PAGE';

export const SET_FILTER = 'SET_FILTER';

const oppositeOrder = (direction) => (direction === SORT_DESC ? SORT_ASC : SORT_DESC);

/*
 * This reducer is for the react-router query string, NOT for redux.
 */
export interface QueryState {
    filter: {};
    order: 'ASC' | 'DESC';
    page: string | number; // e.g. "1" or 1
    perPage: string | number; // e.g. "1000" or 1000
    sort: string; // e.g. "firstName"
}

export type QueryAction =
    | { type: typeof SET_SORT; payload: string }
    | { type: typeof SET_PAGE; payload: string | number }
    | { type: typeof SET_FILTER; payload: {} }
    | { type: typeof SET_PER_PAGE; payload: string | number };

export default (previousState: QueryState, action: QueryAction): QueryState => {
    switch (action.type) {
        case SET_SORT:
            if (action.payload === previousState.sort) {
                return {
                    ...previousState,
                    order: oppositeOrder(previousState.order),
                    page: 1,
                };
            }

            return {
                ...previousState,
                sort: action.payload,
                order: SORT_ASC,
                page: 1,
            };

        case SET_PAGE:
            return { ...previousState, page: action.payload };

        case SET_PER_PAGE:
            return { ...previousState, page: 1, perPage: action.payload };
        case SET_FILTER: {
            return { ...previousState, page: 1, filter: action.payload };
        }

        default:
            return previousState;
    }
};
