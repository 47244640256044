/* eslint default-case: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { RemoteStatus, getKey } from '../reducer';
import { RootState } from 'reducers/rootReducer';
import isOffline from 'util/isOffline';

export interface DisplayStatusBasicProps {
    id: string;
    renderNetworkError: () => JSX.Element;
    renderUnhandledError: () => JSX.Element;
    renderServerError: (code: number, message?: string) => JSX.Element;
    renderLoading: (previousStatus: RemoteStatus | 'initial') => JSX.Element;
    renderSuccess: () => JSX.Element;
    renderMoved?: (newId: string) => JSX.Element;
}
/*
    We expose DisplayStatusBasicProps and DisplayStatusComponent in order to build our own 
    "specialized" displaystatusComponenets on non-standard resources (e.g. bpm/processInstances)
    which aren't fetched in the normal crud getOne flow.
    We do this by implementing our own 'mapStateToProps' and wrapping DisplayStatusComponent
*/
interface DisplayStatusProps extends DisplayStatusBasicProps {
    resource: string;
}
const initial = { _type: 'initial' } as const;
const mapStateToProps = (state: RootState, props: DisplayStatusProps) => {
    const remoteStatus = state.getOneStatus[getKey(props.resource, props.id)] || initial;
    return {
        remoteStatus,
    };
};
interface DisplayStatusComponentProps extends DisplayStatusProps, ReturnType<typeof mapStateToProps> {}

export const DisplayStatusComponent = (
    props: Pick<
        DisplayStatusComponentProps,
        | 'renderUnhandledError'
        | 'renderNetworkError'
        | 'renderServerError'
        | 'renderLoading'
        | 'renderMoved'
        | 'renderSuccess'
        | 'remoteStatus'
    >,
) => {
    const {
        renderNetworkError,
        renderServerError,
        renderLoading,
        renderSuccess,
        renderUnhandledError,
        renderMoved = renderSuccess as (newId: string) => JSX.Element,
    } = props;
    if (isOffline()) {
        return renderSuccess();
    }
    switch (props.remoteStatus._type) {
        case 'error': {
            const { code, message } = props.remoteStatus;
            if (code === 'Communication') {
                return renderNetworkError();
            }
            if (code === 'Unhandled') {
                return renderUnhandledError();
            }
            return renderServerError(code, message);
        }
        case 'initial': {
            return renderLoading('initial');
        }
        case 'loading': {
            const { previousStatus } = props.remoteStatus;
            return renderLoading(previousStatus);
        }
        case 'success': {
            return renderSuccess();
        }
        case 'moved': {
            const { newId } = props.remoteStatus;
            return renderMoved(newId);
        }
    }
};
const DisplayStatus: React.ComponentType<DisplayStatusProps> = React.memo(
    connect(mapStateToProps)(DisplayStatusComponent),
);
export default DisplayStatus;
