import React from 'react';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import Title from 'components/Title';
import { useSelector } from 'react-redux';
import {
    getOfflineAppTitleHtmlSelector,
    getOnlinePwaAppTitleHtmlSelector,
    getToolbarImageUrlSelector,
} from 'util/applicationConfig';
import isOffline, { isOfflineFromUrl } from 'util/isOffline';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import isTheInstalledApp from 'util/isTheInstalledApp';

interface ThemeableToolbarTitleProps {}
const ThemeableToolbarTitle: React.FunctionComponent<ThemeableToolbarTitleProps> = (props) => {
    const imageLink = useSelector(getToolbarImageUrlSelector);
    const offlineAppTitleHtml = useSelector(getOfflineAppTitleHtmlSelector);
    const onlinePwaAppTitleHtml = useSelector(getOnlinePwaAppTitleHtmlSelector);
    return (
        <NavLink
            to={isOfflineFromUrl() ? '/' + window.location.search : '/'}
            style={{ textDecoration: 'none', color: 'unset', flex: 1 }}
        >
            {isOffline() && offlineAppTitleHtml ? (
                <SafeHtmlAsReact html={offlineAppTitleHtml} />
            ) : isTheInstalledApp() && onlinePwaAppTitleHtml ? (
                <SafeHtmlAsReact html={onlinePwaAppTitleHtml} />
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    {imageLink ? (
                        <img style={{ maxHeight: '52px', paddingRight: '1em', width: 'auto' }} src={imageLink} alt="" />
                    ) : null}
                    <Typography variant="h6" aria-label="Title" component="h1">
                        <Title />
                    </Typography>
                </div>
            )}
        </NavLink>
    );
};

export default ThemeableToolbarTitle;
