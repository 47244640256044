import { Button, Card } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Check from '@material-ui/icons/Check';
import Warning from '@material-ui/icons/Warning';
import LinkButton from 'components/links/LinkButton';
import capitalize from 'lodash/capitalize';
import React, { useCallback, useState } from 'react';
import ViewConfig, { Entity, EntityField, FieldViewField, View, ViewField } from 'reducers/ViewConfigType';
import { concat } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';
import useViewConfig from 'util/hooks/useViewConfig';
import { validDataTypeWidgetCombinations } from 'viewConfigValidation/validWidgetCombinations';
import EntityConfigSearchTable, { EntityRowData } from './PickEntity';
import { WhichSetOfViews } from './WhichSetOfViews';

type ViewType = ViewConfig['views'][0]['viewType'];

const defaultWidgetForDataType = (dataType: EntityField['dataType']): ViewField['widgetType'] =>
    validDataTypeWidgetCombinations[dataType]?.[0];

export const createViewFieldFromEntityField = (entity: string, field: EntityField): FieldViewField => {
    const widgetType = defaultWidgetForDataType(field.dataType);
    if (!widgetType) {
        return null;
    }
    return {
        widgetType,
        entity,
        field: field.name,
    };
};

const defaultViewRoute = (entityName: string, type: ViewType) => {
    if (type === 'CREATE') {
        return `${entityName}/create`;
    }
    if (type === 'EDIT') {
        return `${entityName}/:id`;
    }
    if (type === 'SHOW') {
        return `${entityName}/:id/show`;
    }
    if (type === 'LIST') {
        return `${entityName}`;
    }
    return null;
};

const disallowedFields = [
    'id',
    'revision',
    'revisions',
    'mergeRecords',
    'events',
    'notes',
    'appCases',
    'relatedCases',
    'taskInstances',
    'documents',
    'subtitle',
    'title',
    'hasPossibleMatches',
    'dedupeStatus',
    'errorMessages',
    'createdDate',
    'createdBy',
    'lastModifiedBy',
    'lastModifiedDate',
];
const fieldIsAllowed = (field: EntityField) => {
    return !disallowedFields.includes(field.name);
};

export const createDefaultView = (entityConfig: Entity, type: ViewType): View => {
    const fields: FieldViewField[] =
        type === 'LIST'
            ? [
                  entityConfig.name.endsWith('Revision')
                      ? {
                            entity: entityConfig.name,
                            field: 'revInfo.timestamp',
                            order: 0,
                            widgetType: 'CALENDARTIME',
                        }
                      : {
                            entity: entityConfig.name,
                            field: 'title',
                            order: 0,
                            widgetType: 'TEXTBOX',
                        },
              ]
            : Object.values(entityConfig.fields).flatMap((f, i) => {
                  if (!fieldIsAllowed(f)) {
                      return [];
                  }
                  if (
                      type === 'CREATE' &&
                      (f.dataType === 'REFMANY' || f.dataType === 'REFMANYJOIN' || f.dataType === 'REFMANYMANY')
                  ) {
                      return [];
                  }
                  const viewField = createViewFieldFromEntityField(entityConfig.name, f);
                  if (!viewField) {
                      return [];
                  }
                  return [{ ...viewField, span: f.dataType === 'TEXTBLOB' ? 12 : 4, row: i + 1, column: 1 }];
              });
    return {
        entity: entityConfig.name,
        fields: fields.reduce((prev, curr) => {
            prev[curr.field] = curr;
            return prev;
        }, {}),
        name: entityConfig.name + capitalize(type),
        route: defaultViewRoute(entityConfig.name, type),
        viewType: type,
    };
};

type SubmitViewState =
    | {
          type: 'SUCCESS';
      }
    | { type: 'NOT_SUBMITTING' }
    | { type: 'SUBMITTING' }
    | {
          type: 'ERROR';
          error: AjaxError;
      };

const GenerateViewsButton: React.FunctionComponent<{ entityName; viewTypes: ViewType[] }> = ({
    entityName,
    viewTypes,
}) => {
    const [state, setState] = useState<SubmitViewState>({ type: 'NOT_SUBMITTING' });
    const viewConfig = useViewConfig();
    const entityConfig = viewConfig.entities[entityName];
    const handleClick = useCallback(() => {
        const views = viewTypes.map((vt) => {
            return createDefaultView(entityConfig, vt);
        });
        console.log({
            views,
        });
        const $ajaxes = concat(
            ...views.map(({ name, fields, ...v }) =>
                services.createView({
                    ...v,
                    fields: Object.values(fields),
                    viewDefName: name,
                    defaultView: true,
                } as any),
            ),
        );

        setState({ type: 'SUBMITTING' });
        const subscription = $ajaxes.subscribe(
            (res) => {
                if (res.status >= 200 && res.status < 300) {
                    setState({ type: 'SUCCESS' });
                } else {
                    setState({ type: 'NOT_SUBMITTING' });
                }
            },
            (error: AjaxError) => {
                setState({ type: 'ERROR', error });
            },
        );
    }, [entityConfig, viewTypes]);
    return (
        <div>
            <Button onClick={handleClick} variant="contained" color="primary">
                Generate&nbsp;{viewTypes.length}&nbsp;{entityName}&nbsp;views
            </Button>
            <div>
                {state.type === 'ERROR' ? (
                    <span>
                        <Warning id="create-defaultviews-failure" color="error" /> Save Failed{' '}
                        {state.error.status ? `with status ${state.error.status}` : ''}
                        &nbsp;
                    </span>
                ) : state.type === 'SUCCESS' ? (
                    <span>
                        <Check id="create-defaultviews-success" color="primary" />
                        Success!&nbsp;
                    </span>
                ) : null}
            </div>
        </div>
    );
};

const GenerateDefaultViews = () => {
    const [whichSet, setWhichSet] = React.useState<WhichSetOfViews>('CREATE_SHOW_EDIT_LIST');
    const [entityConfig, setEntityConfig] = React.useState<EntityRowData>();
    const handleWhichSetChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setWhichSet(e.target.value as WhichSetOfViews),
        [],
    );
    return (
        <div>
            <div style={{ margin: '1rem' }}>
                <LinkButton to="/view-definition-editor">
                    <span style={{ position: 'relative' }}>
                        <ArrowBack
                            fontSize="small"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                transform: 'translate(-50%,-50%)',
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;Edit ViewDefs
                    </span>
                </LinkButton>
            </div>
            <div>
                <input
                    type="radio"
                    name="wsov"
                    value={'ALL'}
                    checked={whichSet === 'ALL'}
                    onChange={handleWhichSetChange}
                />
                All views
                <input
                    type="radio"
                    name="wsov"
                    value={'CREATE_SHOW_EDIT_LIST'}
                    checked={whichSet === 'CREATE_SHOW_EDIT_LIST'}
                    onChange={handleWhichSetChange}
                />
                Main views (Create, Show, Edit, List)
                <input
                    type="radio"
                    name="wsov"
                    value={'MERGE_MATCH'}
                    checked={whichSet === 'MERGE_MATCH'}
                    onChange={handleWhichSetChange}
                />
                Merge + Match views
                <EntityConfigSearchTable
                    whichSetOfViews={whichSet}
                    setEntityConfig={(name, data) => setEntityConfig(data)}
                />
                <div style={{ height: '1em' }} />
                <Card style={{ textAlign: 'center', padding: '2em' }}>
                    {entityConfig ? (
                        <GenerateViewsButton
                            entityName={entityConfig.name}
                            viewTypes={entityConfig.viewTypesNeeded.split(',').map((e) => e.trim()) as ViewType[]}
                        />
                    ) : null}
                </Card>
            </div>
        </div>
    );
};

export default GenerateDefaultViews;
