import * as React from 'react';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import useViewConfig from 'util/hooks/useViewConfig';
import useValueSets from 'util/hooks/useValueSets';
import validate from 'bpm/components/TaskDetail/TaskForm/validate';
import useEntities from 'util/hooks/useEntities';
import useExpressionEval from 'expressions/Provider/hooks/useExpressionEval';
import { getValidationFromConfig } from 'expressions/formValidation/evaluateFromJson';
import { TaskForm } from 'reducers/taskFormType';
import { getValidations } from '../validate/validate';

const useLiveTaskFormValidation = ({ formDefinition, fields }: { formDefinition: TaskForm; fields: any }) => {
    const fc = React.useContext(formContext);
    const entities = useEntities();
    const valueSets = useValueSets();
    const viewConfig = useViewConfig();
    const spelCompiler = useExpressionEval();

    // a.k.a. 'validations which don't contain "outcome"'
    const overrideValidationsToRunLive = React.useMemo(() => {
        const { validations } = getValidations({
            formDefinition,
        });

        return validations.flatMap(([fieldId, validation]) => {
            const validationConfig = getValidationFromConfig(validation);
            if (validationConfig.isLeft()) {
                return [];
            }
            const allowedValidations = validationConfig.value.filter((v) => {
                const compiled = spelCompiler.compileExpression(v.expression);
                if (compiled.type === 'parse_failure') {
                    return false;
                }
                const paths = compiled.getPathsWithAll();
                const allowExpression = !paths?.includes('outcome') && !paths?.includes('_outcome');
                return allowExpression;
            });

            return [[fieldId, JSON.stringify(allowedValidations)] as [string, string]];
        });
    }, [spelCompiler, formDefinition]);

    const _onValidate = React.useRef(null);
    React.useMemo(() => {
        _onValidate.current = (values) => {
            const messagesIncludingUnexpectedErrors = validate({
                outcome: null,
                entities,
                valuesAfterExpressionsApplied: fc.fieldValues,
                visibleAndEditableFields: fc.visibleAndEditableFields,
                viewConfig,
                fields,
                formDefinition,
                valueSets,
                ignoreFieldLevel: true, // let the field itself handle its 'field-level' validation
                overrideValidationsToRunLive,
            });
            return messagesIncludingUnexpectedErrors;
        };
    }, [
        entities,
        fc.fieldValues,
        fc.visibleAndEditableFields,
        viewConfig,
        fields,
        formDefinition,
        valueSets,
        overrideValidationsToRunLive,
    ]);
    const onLiveValidate = React.useCallback((values) => {
        const res = _onValidate.current(values);
        return res;
    }, []);
    return onLiveValidate;
};

export default useLiveTaskFormValidation;
