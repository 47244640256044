import { CircularProgress, IconButton } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import {
    getLazyProcessVariablesRequest,
    ProcessVariableEntry,
} from 'bpm/components/ProcessDetail/ProcessVariables/getProcessVariables';
import AttemptRequest from 'components/AttemptRequest';
import React from 'react';
import { TaskForm } from '../../../../reducers/taskFormType';
import fillTaskFormValuesWithProcessVariables from './util/fillTaskFormValuesWithProcessVariables';

interface ProcessAndTaskContextControllerProps {
    processId: string;
    taskId: string;
    formCurrentlyEditing: TaskForm;
    children: (props: {
        templatedForm: TaskForm;
        expressionsToValues?: {
            [expression: string]:
                | {
                      type: 'success';
                      value: string;
                  }
                | {
                      type: 'failure';
                      error: string;
                  };
        };
        processVariables: {
            [variable: string]: string;
        };
    }) => JSX.Element;
}

const ProcessAndTaskContextController: React.FC<ProcessAndTaskContextControllerProps> = ({
    processId,
    taskId,
    formCurrentlyEditing,
    children,
}) => {
    // we would like:

    // 1. the set of process variables, so we can apply 'values' to our form.
    // 2. A refresh button to refresh everything
    // 3. A hook or function provided to children to evaluate groovy in the process/task context.

    // provide to FAC/context:
    /*
        1. the templated form (as fully as possible)
        2. A list of errors (for convenience but not necessary)
    */
    return (
        <AttemptRequest<ProcessVariableEntry[]>
            requestOnMount
            type="external"
            lazyRequest={getLazyProcessVariablesRequest(processId)}
            renderer={({ attemptAction }) => {
                const refreshBtn = (loading: boolean) =>
                    loading ? (
                        <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                    ) : (
                        <IconButton aria-label="refresh" style={{ marginLeft: 'auto' }} onClick={attemptAction}>
                            <Refresh />
                        </IconButton>
                    );
                return (state) => (
                    <span>
                        {state._tag === 'failure' ? (
                            <div>
                                Failed to fetch process variables.
                                <a // eslint-disable-line
                                    href="javascript:;" // eslint-disable-line
                                    onClick={attemptAction}
                                >
                                    try again?
                                </a>
                            </div>
                        ) : state._tag === 'pending' ? (
                            refreshBtn(true)
                        ) : state._tag === 'unsubmitted' ? (
                            refreshBtn(false)
                        ) : (
                            <span>
                                {refreshBtn(false)}
                                <br />
                                {children({
                                    templatedForm: fillTaskFormValuesWithProcessVariables(
                                        state.data,
                                        formCurrentlyEditing,
                                    ),
                                    processVariables: state.data.reduce((prev, curr) => {
                                        prev[curr.id] = curr.value;
                                        return prev;
                                    }, {}),
                                })}
                                {/* {renderProcessVariableTable(processId, attemptAction)(state.data)} */}
                            </span>
                        )}
                    </span>
                );
            }}
        />
    );
};
export default ProcessAndTaskContextController;
