import React, { Suspense } from 'react';
const CodeMirrorGroovyEditor = React.lazy(() => import('./CodeMirrorGroovyEditor'));
const LazyCodeMirrorGroovyEditor = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <CodeMirrorGroovyEditor {...props} />
        </Suspense>
    );
};
export default LazyCodeMirrorGroovyEditor;
