import React from 'react';
import FormDataFileUpload from './FormDataFileUpload';
import { API_URL } from 'config';
import { Helmet } from 'react-helmet';
import useUserIsSuper from 'util/hooks/useUserIsSuper';
import { RemoteData } from '@devexperts/remote-data-ts';

type Type = 'process' | 'form' | 'decision-table';
const getRoute = (type: Type) => {
    switch (type) {
        case 'process':
            return 'process-definition';
        case 'form':
            return 'form-definition';
        case 'decision-table':
            return 'dmn-definition';
    }
};

const getLabel = (type: Type) => {
    switch (type) {
        case 'process':
            return 'Process Definition';
        case 'form':
            return 'Form Definition';
        case 'decision-table':
            return 'Decision Table Definition';
    }
};
type VersionState =
    | {
          type: 'latest';
          keepExistingOnCompleted: boolean;
          other?: VersionState;
      }
    | {
          type: 'not_latest';
          version?: number;
          other?: VersionState;
      };
export default () => {
    const userIsSuper = useUserIsSuper();
    const [key, setKey] = React.useState<string | null>(null);
    const [versionState, setVersionState] = React.useState<VersionState>({
        type: 'latest',
        keepExistingOnCompleted: false,
    });
    const [type, setType] = React.useState<Type>('process');

    const handleLatestChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                setVersionState(
                    versionState.other
                        ? {
                              ...versionState.other,
                              other: versionState,
                          }
                        : {
                              type: 'latest',
                              keepExistingOnCompleted: false,
                              other: versionState,
                          },
                );
            } else {
                setVersionState(
                    versionState.other
                        ? {
                              ...versionState.other,
                              other: versionState,
                          }
                        : {
                              type: 'not_latest',
                              other: versionState,
                          },
                );
            }
        },
        [setVersionState, versionState],
    );
    const renderVersionState = <T extends any = unknown>(status: RemoteData<Error, T>) => {
        return (
            <>
                <label htmlFor="latest">Latest</label>&nbsp;
                <input
                    type="checkbox"
                    id="latest"
                    name="latest"
                    checked={versionState.type === 'latest'}
                    onChange={handleLatestChange}
                />
                {versionState.type !== 'latest' ? (
                    <>
                        <label htmlFor="version">Version Number</label>&nbsp;
                        <input
                            id="version"
                            disabled={status.isPending()}
                            type="number"
                            value={versionState.version}
                            onChange={(e) =>
                                setVersionState({
                                    ...versionState,
                                    version: parseInt(e.target.value),
                                })
                            }
                        />
                    </>
                ) : null}
                {versionState.type === 'latest' && type === 'form' ? (
                    <>
                        <label htmlFor="keepExisting">keep existing form on already completed tasks</label>&nbsp;
                        <input
                            type="checkbox"
                            id="keepExisting"
                            name="keepExisting"
                            checked={versionState.keepExistingOnCompleted}
                            onChange={(e) => {
                                setVersionState({
                                    ...versionState,
                                    keepExistingOnCompleted: e.target.checked,
                                });
                            }}
                        />
                    </>
                ) : null}
            </>
        );
    };
    if (!userIsSuper) {
        return <div>You do not have access to this page.</div>;
    }
    return (
        <div style={{ padding: '1em' }}>
            <Helmet>
                <title>Update Definitions</title>
            </Helmet>
            <FormDataFileUpload
                url={`${API_URL}bpm/${getRoute(type)}/${key}/update?version=${(() => {
                    if (versionState.type === 'latest') {
                        if (versionState.keepExistingOnCompleted) {
                            return 'latest&keepHistoricForm=true';
                        }
                        return 'latest';
                    }
                    return versionState.version;
                })()}`}
                render={({ onChange, onFormSubmit, status, fileStaged }) => {
                    return (
                        <div>
                            <label htmlFor="type">Upload Type</label>&nbsp;
                            <select id="type" value={type} onChange={(e) => setType(e.target.value as Type)}>
                                <option value={'process'}>Process Definition</option>
                                <option value={'form'}>Form Definition</option>
                                <option value={'decision-table'}>Decision Table Definition</option>
                            </select>
                            <form onSubmit={onFormSubmit}>
                                <br />
                                <label htmlFor="upload">{getLabel(type)}File</label>&nbsp;
                                <input id="upload" disabled={status.isPending()} type="file" onChange={onChange} />
                                <br />
                                <br />
                                <label htmlFor="key">{getLabel(type)} Key</label>&nbsp;
                                <input
                                    disabled={status.isPending()}
                                    type="text"
                                    onChange={(e) => setKey(e.target.value)}
                                />
                                <br />
                                <br />
                                {renderVersionState(status)}
                                <br />
                                <br />
                                <button
                                    disabled={
                                        status.isPending() ||
                                        !fileStaged ||
                                        !key ||
                                        (versionState.type !== 'latest' && !versionState.version)
                                    }
                                    type="submit"
                                >
                                    Upload
                                </button>
                                <p aria-live="polite" aria-atomic="true">
                                    {status.isFailure() ? (
                                        <React.Fragment>
                                            Upload Failed:
                                            <pre>{status.error.message}</pre>
                                        </React.Fragment>
                                    ) : status.isSuccess() ? (
                                        'Upload successful'
                                    ) : status.isPending() ? (
                                        'Loading...'
                                    ) : null}
                                </p>
                            </form>
                        </div>
                    );
                }}
            />
        </div>
    );
};
