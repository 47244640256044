import React, { useRef } from 'react';
import { EventOrValueHandler, WrappedFieldMetaProps } from 'redux-form';
import { RootState } from 'reducers/rootReducer';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import { FieldTitle } from '../aor/FieldTitle';
import {
    TextField,
    IconButton,
    Paper,
    MenuItem,
    withStyles,
    InputProps as InputPropsType,
    Tooltip,
} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { getPluralName } from 'components/generics/utils/viewConfigUtils';
import { WithStyles } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import useTextFieldUtils from 'fieldFactory/input/hooks/useTextFieldUtils';
import OfflineDropdown from './OfflineDropdown';
import { styles } from './';

interface EntityTypeaheadProps {
    fetchOnMount?: boolean;
    randomizeNameAsBrowserAutocompleteHack?: boolean;
    expansions?: string[];
    filterString?: string;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    isPopover?: boolean;
    label?: string;
    isRequired?: boolean;
    emptyText?: string;
    disabled?: boolean;
    allowEmptyQuery: boolean;
    input: {
        onBlur: EventOrValueHandler<string | null>;
        value: string | null;
    };
    source: string;
    reference: string;
    meta: WrappedFieldMetaProps;
}
const makeMapStateToProps = () => {
    const mapStateToProps = (state: RootState, props: EntityTypeaheadProps) => {
        return {
            refEntityDisplayNamePlural: getPluralName(state.viewConfig, props.reference),
            currentlySelectedEntity: fromPredicate<string>(Boolean)(props.input.value)
                .chain((id) => fromNullable(state.admin.entities[props.reference]).mapNullable((e) => e[id]))
                .getOrElse(null),
        };
    };
    return mapStateToProps;
};
interface EntityTypeaheadComponentProps
    extends EntityTypeaheadProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        WithStyles<typeof styles> {}

const EntityTypeaheadComponent: React.FunctionComponent<EntityTypeaheadComponentProps> = (props) => {
    const errorMessageId = React.useRef(uniqueId('entity-typeahead'));
    const currentlySelectedEntityTitle = (props.currentlySelectedEntity && props.currentlySelectedEntity.title) || '';
    const [inputValue, setInputValue] = React.useState(currentlySelectedEntityTitle);
    const lastEntityTitle = React.useRef(currentlySelectedEntityTitle);
    React.useEffect(() => {
        if (currentlySelectedEntityTitle !== lastEntityTitle.current && currentlySelectedEntityTitle !== inputValue) {
            if (currentlySelectedEntityTitle) {
                setInputValue(currentlySelectedEntityTitle);
            } else if (!props.meta.dirty) {
                setInputValue('');
            }
        }
        lastEntityTitle.current = currentlySelectedEntityTitle;
    }, [currentlySelectedEntityTitle, setInputValue, inputValue, props.meta.dirty]);
    const {
        label,
        meta,
        currentlySelectedEntity,
        isRequired,
        disabled,
        emptyText = 'None Selected',
        classes,
        isPopover,
        reference,
        dropdownPosition = 'below',
        refEntityDisplayNamePlural,
        source,
        allowEmptyQuery,
        randomizeNameAsBrowserAutocompleteHack = true,
        filterString,
        tooltipText,
    } = props;
    const uniqueNameToThrowOffChromeAutoFill = useRef(new Date().toISOString());
    const paperClass = dropdownPosition === 'below' ? classes.paper : classes.paperTop;
    const newFilter = React.useMemo(() => {
        const filterObject = getFilterFromFilterString(filterString);
        return filterObject;
    }, [filterString]);

    const {
        InputPropsClasses,
        createInputLabelProps,
        createFormHelperTextProps,
        muiErrorProp,
        helperText,
        fieldVariant,
        classnames,
    } = useTextFieldUtils(meta);
    const El = (
        <EntityInspect
            reference={reference}
            formId={`fromentitytypeahead ${source}`}
            renderComponent={(args) => (
                <div className={classes.root}>
                    <Downshift
                        inputValue={inputValue}
                        selectedItem={currentlySelectedEntity || ''}
                        onSelect={(record, ds) => {
                            if (record) {
                                setInputValue(record.title);
                                props.input.onBlur(record.id);
                            } else {
                                props.input.onBlur(null);
                            }
                        }}
                        itemToString={(record) => record.title}
                    >
                        {({
                            inputValue,
                            getInputProps,
                            getLabelProps,
                            getMenuProps,
                            getItemProps,
                            setItemCount,
                            clearItems,
                            selectedItem,
                            highlightedIndex,
                            isOpen,
                            openMenu,
                            clearSelection,
                        }) => {
                            const InputProps = getInputProps({
                                'aria-errormessage': meta.touched && meta.error ? errorMessageId.current : undefined,
                                placeholder: selectedItem ? selectedItem.title : emptyText,
                                disabled,
                                style: {
                                    textOverflow: 'ellipsis',
                                    marginRight: '60px',
                                },
                                onChange: (e) => {
                                    setInputValue(e.target.value);
                                },
                                onFocus: () => openMenu(),
                                autoComplete: 'off',
                            });
                            return (
                                <div className={classes.container}>
                                    <TextField
                                        margin="none"
                                        fullWidth={true}
                                        InputLabelProps={createInputLabelProps({
                                            shrink: true,
                                            ...getLabelProps({
                                                disabled: false,
                                            }),
                                        })}
                                        variant={fieldVariant}
                                        label={label && <FieldTitle label={label} isRequired={isRequired} />}
                                        InputProps={
                                            {
                                                inputProps: (() => {
                                                    if (randomizeNameAsBrowserAutocompleteHack) {
                                                        return {
                                                            ...InputProps,
                                                            name: uniqueNameToThrowOffChromeAutoFill.current,
                                                        };
                                                    }
                                                    return InputProps;
                                                })(),
                                                ...InputProps,
                                                classes: {
                                                    ...InputPropsClasses,
                                                    root: classnames(InputPropsClasses.root, classes.inputRoot),
                                                },
                                            } as InputPropsType
                                        }
                                        error={muiErrorProp}
                                        helperText={helperText}
                                        FormHelperTextProps={createFormHelperTextProps(InputProps as any)}
                                    />
                                    {selectedItem && (
                                        <IconButton
                                            classes={{
                                                root: classes.iconButton,
                                            }}
                                            onClick={() => args.selectId(selectedItem.id)}
                                            aria-label="view"
                                            style={
                                                fieldVariant && fieldVariant !== 'standard' ? { right: 6 } : undefined
                                            }
                                        >
                                            <RemoveRedEye />
                                        </IconButton>
                                    )}
                                    {selectedItem && !disabled && (
                                        <IconButton
                                            classes={{
                                                root: classes.iconButton,
                                            }}
                                            style={
                                                fieldVariant && fieldVariant !== 'standard'
                                                    ? { right: 36 }
                                                    : { right: 30 }
                                            }
                                            onClick={() => {
                                                clearSelection();
                                                setInputValue('');
                                            }}
                                            aria-label="clear"
                                        >
                                            <Clear />
                                        </IconButton>
                                    )}
                                    <div {...getMenuProps()}>
                                        {isOpen && (
                                            <Paper
                                                className={
                                                    isPopover
                                                        ? classnames(paperClass, classes.popoverPaper)
                                                        : paperClass
                                                }
                                                square={true}
                                            >
                                                {(() => {
                                                    if (!inputValue && !allowEmptyQuery) {
                                                        return (
                                                            <MenuItem component="div" aria-live="polite" disabled>
                                                                You have to enter a search query
                                                            </MenuItem>
                                                        );
                                                    }

                                                    return (
                                                        <OfflineDropdown
                                                            resource={reference}
                                                            selectedItem={selectedItem}
                                                            highlightedIndex={highlightedIndex}
                                                            refEntityDisplayNamePlural={refEntityDisplayNamePlural}
                                                            getItemProps={getItemProps}
                                                            inputValue={inputValue}
                                                            filter={newFilter}
                                                            setItemCount={setItemCount}
                                                        />
                                                    );
                                                })()}
                                            </Paper>
                                        )}
                                    </div>
                                </div>
                            );
                        }}
                    </Downshift>
                </div>
            )}
        />
    );
    if (tooltipText) {
        return (
            <Tooltip title={tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                <div>{El}</div>
            </Tooltip>
        );
    }
    return El;
};

const OfflineEntityTypeahead = compose(connect(makeMapStateToProps), withStyles(styles))(EntityTypeaheadComponent);
export default OfflineEntityTypeahead;
