import { combineReducers } from 'redux'; // eslint-disable-line import/no-extraneous-dependencies
import processInstance, { ProcessInstanceState } from './processInstance';
import uniq from 'lodash/uniq';
import { processTasksEventType as taskForProcessEvent } from '../../actions/tasksForProcessEvent';
import { taskEventType as taskEvent } from '../../actions/taskEvent';
import {
    ProcessInstanceFromAppTasks,
    mapToAppType,
} from '../../dataAdapters/network/api/task-instances/entities/processInstance';
import { getProcessInstanceSuccess } from 'bpm/processInstance/actions';
import { getType } from 'typesafe-actions';
import processInstanceStatusReducer from 'bpm/processInstance/reducers/status';
import { startProcessSuccess } from 'bpm/create-process-instance/actions';

export interface ByIdState {
    [key: string]: ProcessInstanceState;
}
const byId = (state: ByIdState = {}, action): ByIdState => {
    if (
        (action.type === taskEvent.getTasksSuccess || action.type === taskEvent.getSidebarTasksSuccess) &&
        action.payload &&
        action.payload.entities &&
        action.payload.entities.processInstances
    ) {
        return Object.assign(
            {},
            state,
            ...Object.entries(action.payload.entities.processInstances).map(
                ([id, pI]: [string, ProcessInstanceFromAppTasks]) => ({
                    [id]: processInstance(state[id], getProcessInstanceSuccess(id, mapToAppType(pI))),
                }),
            ),
        );
    }
    switch (action.type) {
        case getType(getProcessInstanceSuccess):
            return {
                ...state,
                [action.processId]: processInstance(state[action.processId], action),
            };
        case getType(startProcessSuccess): {
            const { payload } = action;
            const pid = payload.response.id;
            return {
                ...state,
                [pid]: processInstance(state[pid], action),
            };
        }
        case taskForProcessEvent.getOpenSuccess:
        case taskForProcessEvent.getAllSuccess:
        case taskForProcessEvent.getCompletedSuccess:
        case taskEvent.updateSuccess:
        case taskEvent.getSuccess: {
            const { payload } = action;
            const pid = payload.processInstanceId || payload.processId || payload.id;
            return {
                ...state,
                [pid]: processInstance(state[pid], action),
            };
        }
        default:
            return state;
    }
};

const allIds = (state: string[] = [], action): string[] => {
    switch (action.type) {
        case getType(getProcessInstanceSuccess): {
            return uniq([...state, action.processId]);
        }
        case taskForProcessEvent.getAllSuccess:
        case taskForProcessEvent.getCompletedSuccess:
        case taskForProcessEvent.getOpenSuccess:
        case taskEvent.updateSuccess:
        case taskEvent.getSuccess: {
            const { payload } = action;
            const pid = payload.processInstanceId || payload.processId || payload.id;
            return uniq([...state, pid]);
        }
        default:
            return state;
    }
};

export interface ProcessInstances {
    byId: ByIdState;
    allIds: string[];
    getOneStatus: ReturnType<typeof processInstanceStatusReducer>;
}
const reducer = combineReducers<ProcessInstances>({
    byId,
    allIds,
    getOneStatus: processInstanceStatusReducer,
});

export default reducer;

export const getAllProcessInstances = (state) => state.allIds.map((id) => state.byId[id]);

export const getListProcessInstances = (state) => state.list.ids.map((id) => state.byId[id]);

export const getListIsLoading = (state) => state.list && state.list.isLoading;
