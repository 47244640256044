import { parse } from 'querystring';
import produce from 'immer';
import deepEql from 'deep-eql';

const searchIsDifferentOnlyBySortOrPage = (search1: string, search2: string) => {
    const s1 = parse(search1);
    const s2 = parse(search2);
    const exceptForSort1 = produce(s1, (draftState) => {
        delete draftState['sort'];
        delete draftState['order'];
        delete draftState['perPage'];
        delete draftState['page'];
    });
    const exceptForSort2 = produce(s2, (draftState) => {
        delete draftState['sort'];
        delete draftState['order'];
        delete draftState['perPage'];
        delete draftState['page'];
    });
    return (
        deepEql(exceptForSort1, exceptForSort2) &&
        (s1.sort !== s2.sort || s1.order !== s2.order || s1.page !== s2.page || s1.perPage !== s2.perPage)
    );
};
export default searchIsDifferentOnlyBySortOrPage;
