import React from 'react';
import DisplayAddress from './DisplayAddress';
import { IAddressWidgetConfig } from './types';
import { EntityAddressVerificationField, FlowableAddressField } from '../../../translation/types';
import { BadConfig, getSource, isFieldFromFlowable } from './util';
import decodeAddressConfig from './util/decodeAddressConfig';

const getDisplayAddressVerif = (
    propConfiguration,
    fieldDefinition: EntityAddressVerificationField | FlowableAddressField,
    liveProps,
) => {
    try {
        const configStr: string | undefined = isFieldFromFlowable(fieldDefinition)
            ? fieldDefinition.params.address.configs
            : fieldDefinition.config;
        if (!configStr) {
            throw new Error('Address widget configuration is empty.');
        }
        const parsedConfig: IAddressWidgetConfig = decodeAddressConfig(configStr, 'THROW_ERROR');
        return (
            <DisplayAddress
                {...propConfiguration}
                source={getSource(fieldDefinition)}
                key={`${propConfiguration.source}-${propConfiguration.label}`}
                addField={true}
                config={parsedConfig.fieldMapping}
                showVerificationStatus={parsedConfig.showVerificationStatus || false}
                {...liveProps}
            />
        );
    } catch (e) {
        return (
            <BadConfig
                data-originaldefinition={propConfiguration['data-originaldefinition']}
                title={'Bad Configuration (Address)'}
                e={e}
            />
        );
    }
};

export default getDisplayAddressVerif;
