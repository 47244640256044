import React, { useCallback, useContext } from 'react';
import { AppBar, Toolbar, Button, MenuItem } from '@material-ui/core';
import { createStyles, Theme, useTheme, makeStyles } from '@material-ui/core/styles';
import { HashLink as Link } from 'react-router-hash-link';
import SwipeableTemporaryThemeDrawer from 'components/layouts/TogglableThemeEditor';
import useUserIsSuper from 'util/hooks/useUserIsSuper';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import useViewConfig from 'util/hooks/useViewConfig';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import AccountCircle from '@material-ui/icons/AccountCircle';
import generateMenuElements from './generateMenuElements';
import RefreshViewConfigButton from 'viewConfig/components/Refresh';
import ImportExportViewConfigBtn from 'components/importExportViewConfig';
import LoginButton from 'components/LoginButton';
import MenuRoot from './MenuRoot';
import { push as pushAction } from 'connected-react-router';
import { getUseEmailAsAccountDisplaySelector } from 'util/applicationConfig';
import { RootState } from 'reducers/rootReducer';
import { promptPickProfile } from 'auth/profiles/actions';
import useViewConfigKey from './util/useViewConfigKey';
import useAppTitleElem from './util/useAppTitleElem';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { overrideAppbarTitleSelector } from './overrideAppbarTitleSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hiddenSkipNav: {
            position: 'absolute',
            left: '-10000px',
            top: 'auto',
            overflow: 'hidden',
            '&$focused': {
                color: theme.palette.primary.contrastText,
                position: 'unset',
                flex: 1,
                'text-decoration': 'underline',
                outline: '1px dotted ' + theme.palette.primary.contrastText,
            },
        },
        focused: {},
        root: { flexGrow: 1 },
        toolbar: { flexWrap: 'wrap' },
    }),
);

const LinkButton = React.forwardRef((props, ref) => <Link to="#maincontent" {...props} ref={ref} />);

const ToolbarMenuComponent: React.FunctionComponent<{}> = React.memo(() => {
    const classes = useStyles();
    const isSuper = useUserIsSuper();
    const { invertMenu } = useContext(themeOverrideContext);
    const theme = useTheme();
    const viewConfig = useViewConfig();
    const { hideAllMenuItems, toolbarStyles } = useSelector(overrideAppbarTitleSelector);

    const dispatch = useDispatch();
    const logout = useCallback(() => {
        dispatch(pushAction('/logout-redirect'));
    }, [dispatch]);
    const profiles = useSelector((state: RootState) => state.profiles);
    const useEmailAsAccountDisplay = useSelector(getUseEmailAsAccountDisplaySelector);
    const TitleElem = useAppTitleElem();
    const applyTranslations = useEvaluateFormattedMessage().evaluateFormattedMessage;
    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                style={
                    invertMenu
                        ? { color: theme.palette.primary.main, backgroundColor: theme.palette.background.paper }
                        : undefined
                }
            >
                <Toolbar style={toolbarStyles} className={classes.toolbar}>
                    <Button
                        // use this when we move to using BrowserHistory
                        component={LinkButton}
                        disableFocusRipple={true}
                        classes={{
                            root: classes.hiddenSkipNav,
                            focusVisible: classes.focused,
                        }}
                        aria-label="Skip to main content"
                    >
                        Skip to main content
                    </Button>
                    {TitleElem}
                    {!hideAllMenuItems && (
                        <>
                            {generateMenuElements({ viewConfig, dispatch, applyTranslations })(viewConfig.menus)}
                            {viewConfig?.user?.login && viewConfig?.user?.login !== 'anonymousUser' && (
                                <MenuRoot
                                    renderTrigger={({ getTriggerProps }) => (
                                        <Button
                                            {...getTriggerProps({
                                                color: 'inherit',
                                                id: 'accountdropdown',
                                            })}
                                        >
                                            Account <ArrowDropDown color="inherit" /> <AccountCircle color="inherit" />
                                        </Button>
                                    )}
                                >
                                    {({ getMenuProps, Menu }) => {
                                        return (
                                            <Menu {...getMenuProps({ placement: 'bottom-end' })}>
                                                <MenuItem role="presentation">
                                                    {useEmailAsAccountDisplay
                                                        ? viewConfig.user?.email ?? viewConfig.user?.login
                                                        : viewConfig.user?.login}
                                                </MenuItem>
                                                {profiles.state === 'profile_selected' &&
                                                profiles.profiles.length > 1 ? (
                                                    <MenuItem
                                                        onClick={() => {
                                                            dispatch(promptPickProfile());
                                                        }}
                                                    >
                                                        Switch Profile
                                                    </MenuItem>
                                                ) : null}
                                                <MenuItem onClick={logout}>Logout</MenuItem>
                                            </Menu>
                                        );
                                    }}
                                </MenuRoot>
                            )}
                            {viewConfig?.user?.login === 'super' && <ImportExportViewConfigBtn mobile={false} />}
                            <RefreshViewConfigButton mobile={false} />
                            {viewConfig?.user?.login === 'anonymousUser' && <LoginButton />}
                            {isSuper && <SwipeableTemporaryThemeDrawer />}
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
});

// remount the ToolbarMenuComponent when viewConfig changes.
// because $[currentUserHasRole(...)] expressions won't rerun when the viewConfig changes.
// we just need to remount everything on a different viewConfig.
const ToolbarMenu: React.FC<{}> = () => {
    const key = useViewConfigKey();
    return <ToolbarMenuComponent key={key} />;
};

export default ToolbarMenu;
