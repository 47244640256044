import React, { useEffect, useRef } from 'react';
import { CircularProgress, IconButton, NativeSelect } from '@material-ui/core';
import { useState } from 'react';
import { downloadConfigPack } from './downloadConfigPack';
import { useCurrentBranch, usePossibleBranches } from 'branches/BranchExplorer';
import { CheckCircle, Error } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import FileDownload from '@material-ui/icons/SaveAlt';
import { useResetStateToInitialAfterASecondOfSuccess } from './resetStateToInitialAfterASecondOfSuccess';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { State } from './StateUnion';

const RenderDownloadButton = (props: {
    action: (branch: string | null, controller: AbortController) => Promise<void>;
    render: (args: {
        state: State;
        handleDownload: () => void;
        handleReset: () => void;
        branch: string;
        setBranch: Function;
    }) => JSX.Element;
}) => {
    const { render } = props;
    const [state, setState] = useState<State>({ type: 'INITIAL' });
    const branchTest = useCurrentBranch();
    const [branch, setBranch] = useState<string | null>(branchTest);
    const abortController = useRef<AbortController | null>(null);

    const handleDownload = () => {
        if (abortController.current) {
            abortController.current.abort();
        }
        const controller = new AbortController();
        abortController.current = controller;

        setState({ type: 'LOADING' });

        props
            .action(branch, abortController.current)
            .then(() => {
                setState({ type: 'SUCCESS' });
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.error(e);
                }
                if (!controller.signal.aborted) {
                    setState({ type: 'ERROR', errorMessage: e.message });
                }
            });
    };

    const handleReset = () => {
        setState({ type: 'INITIAL' });
    };

    useEffect(() => {
        return () => {
            if (abortController.current) {
                abortController.current.abort();
            }
        };
    }, []);

    useResetStateToInitialAfterASecondOfSuccess(state, setState);

    return render({ state, handleDownload, handleReset, branch, setBranch });
};

const DownloadConfigButton = () => {
    const branchChoices = usePossibleBranches().branches;
    const basicInfo = useSelector((state: RootState) => state.basicInfo);
    if (!basicInfo.showConfigMgmt) {
        return null;
    }

    return (
        <div
            style={{
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <RenderDownloadButton
                action={downloadConfigPack}
                render={({ state, handleDownload, handleReset, branch, setBranch }) => (
                    <div>
                        <label style={{ color: 'primary', fontSize: 'medium' }} htmlFor="select from dropdown">
                            Export ConfigPack by Branch: &nbsp;
                        </label>
                        <NativeSelect
                            id="select from dropdown"
                            defaultValue={branch}
                            name="Branch Option Dropdown"
                            onChange={(event) => {
                                const newValue = event.target.value;
                                setBranch(newValue);
                            }}
                        >
                            {branchChoices?.map((choice, index) => (
                                <option key={index} value={choice}>
                                    {choice}
                                </option>
                            ))}
                            <option value={null}>main(default)</option>
                        </NativeSelect>
                        <IconButton
                            size="small"
                            disabled={state.type === 'LOADING'}
                            color="inherit"
                            onClick={() => {
                                switch (state.type) {
                                    case 'INITIAL':
                                    case 'SUCCESS': {
                                        handleDownload();
                                        break;
                                    }
                                    case 'ERROR': {
                                        handleReset();
                                        break;
                                    }
                                }
                            }}
                            aria-label="Save ConfigPack"
                        >
                            {state.type === 'INITIAL' ? (
                                <Tooltip title={'Download'}>
                                    <FileDownload />
                                </Tooltip>
                            ) : state.type === 'LOADING' ? (
                                <Tooltip title={'Loading'}>
                                    <CircularProgress
                                        aria-live="assertive"
                                        color="inherit"
                                        size={24}
                                        aria-label="Loading"
                                        role="alert"
                                    />
                                </Tooltip>
                            ) : state.type === 'ERROR' ? (
                                <Tooltip title={'Request failed. Retry?'}>
                                    <Error color="error" aria-live="assertive" aria-label="Error Icon" role="alert" />
                                </Tooltip>
                            ) : state.type === 'SUCCESS' ? (
                                <CheckCircle color="primary" aria-label="Success Check Mark" />
                            ) : (
                                <div></div>
                            )}
                        </IconButton>
                    </div>
                )}
            ></RenderDownloadButton>
        </div>
    );
};

export default DownloadConfigButton;
