import React from 'react';
import GenericList from '../../../components/generics/genericList';
import { Link } from 'react-router-dom';
import { push as pushAction, RouterState } from 'connected-react-router';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Pagination from 'components/generics/genericList/Pagination';
import { withDateFormat } from '../../../fieldFactory/dateFormat/Broadcasts';
import TextField from 'fieldFactory/display/components/aor/TextField';
import { fieldSubscriberHoc } from '../../../fieldFactory/display/experimental/FieldSubscriber';
import moment from 'moment';
import { RootState } from 'reducers/rootReducer';
import { RenderListArguments } from 'components/generics/genericList/List';
import { layoutModeContext } from 'components/layouts/LayoutMode';
import { getUseNativeSelectSelector } from 'util/applicationConfig';
import Message from 'i18n/components/Message';

const config = require('../../../config.js');

const SubscribedTextField = fieldSubscriberHoc(TextField);

const getViewName = () => '_ACTIVETASK_LIST_SIDEBAR';

const mapStateToProps = (state: RootState) => ({
    nativeSelect: getUseNativeSelectSelector(state),
    currentUserId: state.viewConfig.user && state.viewConfig.user.id,
    taskFormat: (state.viewConfig && state.viewConfig.application.taskFormat) || 'standard',
    resource: state.viewConfig?.views?.[getViewName()]?.entity,
});

interface TaskListProps extends ReturnType<typeof mapStateToProps> {
    fakePush?: (search: string) => void;
    redirect: (newLocation: string) => void;
    location: RouterState['location'];
    formatDate: (dateStr: string) => string;
}

class TaskList extends React.Component<TaskListProps> {
    getTaskDueDate = (task) => (task.dueDate ? moment(task.dueDate).format('MM/DD/YYYY') + ' - ' : '');
    taskNameText = (task) => (
        <span>
            {this.getTaskDueDate(task)}
            {task.name}
        </span>
    );
    linkedEntityTitleText = (taskId, task) => (
        <SubscribedTextField
            key="processInstance.appCase.linkedEntity.title"
            source="processInstance.appCase.linkedEntity.title"
            resource={this.props.resource}
            id={taskId}
            defaultValue={
                <SubscribedTextField
                    key="processInstance.appCase.processName"
                    source="processInstance.appCase.processName"
                    resource={this.props.resource}
                    id={taskId}
                    record={task}
                    overrideRender={(elem, value) => (value ? <span>{elem}</span> : null)}
                />
            }
            record={task}
            overrideRender={(elem, value) => (value ? <span>{elem}</span> : null)}
        />
    );
    renderList = (args: RenderListArguments) => (
        <List>
            {args.ids.map((id, i) => {
                const task: any = args.data[id];
                const primaryText =
                    this.props.taskFormat === 'standard'
                        ? this.taskNameText(task)
                        : this.linkedEntityTitleText(id, task);
                const secondaryText =
                    this.props.taskFormat === 'standard' ? (
                        <p style={{ margin: 0 }}>
                            {this.linkedEntityTitleText(id, task)}
                            <br />
                            <SubscribedTextField
                                key="processInstance.appCase.caseNumber"
                                source="processInstance.appCase.caseNumber"
                                resource={this.props.resource}
                                id={id}
                                record={task}
                                overrideRender={(elem, value) =>
                                    value ? (
                                        <span>
                                            <Message id="processes.case" dm="Case" />
                                            {elem}
                                        </span>
                                    ) : null
                                }
                            />
                        </p>
                    ) : (
                        <p style={{ margin: 0 }}>{this.taskNameText(task)}</p>
                    );
                return (
                    <li>
                        <Link
                            aria-label={'Navigate to task' + (i + 1) + ': "' + task.name + '"'}
                            style={{ textDecoration: 'none', color: '-webkit-link' }}
                            key={id}
                            to={
                                task.processInstanceId
                                    ? `/processes/${task.processInstanceId}/tasks/${task.id}/start`
                                    : `/tasks/${task.id}`
                            }
                        >
                            <ListItem key={id} alignItems="flex-start" component="div">
                                <ListItemText primary={primaryText} secondary={secondaryText} />
                            </ListItem>
                        </Link>
                    </li>
                );
            })}
        </List>
    );
    render() {
        const props = this.props;
        if (!props.currentUserId) {
            return <span>Loading...</span>;
        }
        return (
            <layoutModeContext.Consumer>
                {({ mode }) => (
                    <GenericList
                        // TODO: READ THIS IN FROM WINDOW HEIGHT USING RESIZE LISTENER
                        perPage={mode === 'new' ? '6' : '4'}
                        useCard={false}
                        cancelRequestOnRouteChange={false}
                        appendExpansions={['processInstance.appCase.linkedEntity']}
                        hasCreate={false}
                        renderActions={() => null}
                        showFilters={false}
                        updateUrlFromFilter={!props.fakePush}
                        fakePush={props.fakePush}
                        onRowSelect={([task]: { processInstanceId?: string; id: string }[]) => {
                            props.redirect(
                                task.processInstanceId
                                    ? `/processes/${task.processInstanceId}/tasks/${task.id}/start`
                                    : `/tasks/${task.id}`,
                            );
                        }}
                        filter={{
                            endTime__NOT_EMPTY: false,
                            'assignee.id': props.currentUserId,
                        }}
                        renderList={this.renderList}
                        renderPagination={(args) => {
                            const style = (() => {
                                let _style: React.CSSProperties = {
                                    position: 'fixed',
                                    bottom: 0,
                                    marginRight: 'auto',
                                };
                                if (mode === 'new') {
                                    _style.right = 10;
                                } else {
                                    _style.left = 50;
                                }
                                return _style;
                            })();
                            return (
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    style={style}
                                >
                                    <Pagination
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Rows per page',
                                            },
                                            native: this.props.nativeSelect || undefined,
                                        }}
                                        {...args}
                                        showSmall={true}
                                    />
                                </div>
                            );
                        }}
                        isPopover={false}
                        {...props}
                        title={null}
                        resource={this.props.resource}
                        viewName={getViewName()}
                        formId={getViewName() + ':sidebar'}
                    />
                )}
            </layoutModeContext.Consumer>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        redirect: (newLocation: string) => dispatch(pushAction(newLocation)),
    };
};

const enhance = compose(withDateFormat, connect(mapStateToProps, mapDispatchToProps));
const WindowTaskList = enhance(TaskList);

interface ContainedTaskListProps {
    open: boolean;
}
interface ContainedTaskListState {
    search: string;
    key: number;
}

interface ContainedTaskListComponentProps extends ContainedTaskListProps {}

const createContainedLocation = (search) => ({ search });

export class ContainedTaskList extends React.Component<ContainedTaskListComponentProps, ContainedTaskListState> {
    memoizedCreateContainedLocation = memoizeOne(createContainedLocation);
    _isMounted: boolean;
    intervalID: NodeJS.Timeout;
    constructor(props: ContainedTaskListComponentProps) {
        super(props);
        this.state = {
            key: 1,
            search: '',
        };
        this._isMounted = true;
    }
    componentDidMount() {
        if (this.props.open) {
            this.startPoll();
        }
    }
    componentDidUpdate(prevProps: ContainedTaskListComponentProps) {
        if (this.props.open && !prevProps.open) {
            this.startPoll();
        }
        if (!this.props.open && prevProps.open) {
            this.clearPoll();
        }
    }
    incrementKey = () => {
        if (this._isMounted) {
            this.setState((state) => ({ ...state, key: state.key + 1 }));
        }
    };
    fakePush = (search: string | { search: string }) => {
        this.setState(typeof search === 'string' ? { search } : search);
    };
    startPoll = () => {
        this.intervalID = setInterval(this.incrementKey, config.TASK_POLL_PERIOD * 1000);
    };
    clearPoll = () => {
        clearInterval(this.intervalID);
    };
    componentWillUnmount() {
        this._isMounted = false;
        this.clearPoll();
    }
    render() {
        return (
            <WindowTaskList
                key={this.state.key}
                location={this.memoizedCreateContainedLocation(this.state.search)}
                fakePush={this.fakePush}
            />
        );
    }
}
export default ContainedTaskList;
