import { RECENT_TAB_OPEN } from '../actions/constants';

export interface RecentTab {
    resource: string | null;
    viewType: string | null;
    tabKey: string | null;
    id: string | null;
}
export default (
    previousState: RecentTab = {
        resource: null,
        viewType: null,
        tabKey: null,
        id: null,
    },
    { type, payload },
): RecentTab => {
    if (type === RECENT_TAB_OPEN) {
        return payload;
    }
    return previousState;
};
