import React from 'react';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import ViewConfig from '../reducers/ViewConfigType';
import { Link } from 'react-router-dom';
import { entityErrors } from './validationRules';
import { RootState } from 'reducers/rootReducer';

const colors = ['#9400D3', '#4B0082', '#0000FF', '#00FF00', '#FFFF00', '#FF7F00', '#FF0000', 'blue'];

interface ValidationComponentProps {
    viewConfig: ViewConfig;
}
interface ValidationComponentState {
    entityErrors: {
        [entityName: string]: {
            [field: string]: string[];
        };
    };
}
const generateEntityErrors = (viewConfig: ViewConfig) => {
    return Object.assign(
        {},
        ...sortBy(Object.values(viewConfig.entities), 'name')
            .map((entity) => ({ [entity.name]: entityErrors(entity, viewConfig) }))
            .filter(
                (eObj) =>
                    ([] as string[]).concat(
                        ...Object.values(eObj).map((fObj) => ([] as string[]).concat(...Object.values(fObj))),
                    ).length > 0,
            ),
    );
};
class ValidationComponent extends React.Component<ValidationComponentProps, ValidationComponentState> {
    state = {
        entityErrors: generateEntityErrors(this.props.viewConfig),
    };

    componentWillReceiveProps(nextProps: ValidationComponentProps) {
        if (this.props.viewConfig !== nextProps.viewConfig && nextProps.viewConfig) {
            this.setState({
                entityErrors: generateEntityErrors(nextProps.viewConfig),
            });
        }
    }
    render() {
        return (
            <div>
                Note that errors of type 7 (fieldConfigs with relatedEntities but no views defined for the related
                entity) are filtered by only those that will appear in the app (are included in some view).
                <ul>
                    {Object.entries(this.state.entityErrors).map(([entityName, errors]) => (
                        <li key={entityName}>
                            <div>
                                <h4>
                                    <Link to={`/EntityConfig/${this.props.viewConfig.entities[entityName].id}`}>
                                        {entityName}
                                    </Link>
                                </h4>
                                <div style={{ marginLeft: '1em' }}>
                                    <ul>
                                        {Object.entries(errors).map(([fieldName, fErrs]) => (
                                            <li key={fieldName}>
                                                {fieldName}
                                                <div style={{ marginLeft: '2em' }}>
                                                    <ul>
                                                        {fErrs.map((e) => (
                                                            <li key={e}>
                                                                <span
                                                                    style={{
                                                                        color: colors[
                                                                            parseInt(e.slice(1, e.indexOf('] ')), 10)
                                                                        ],
                                                                    }}
                                                                >
                                                                    {e.slice(1, e.indexOf('] '))}
                                                                </span>
                                                                {e.slice(e.indexOf('] ') + 1)}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default connect(({ viewConfig }: RootState) => ({ viewConfig }))(ValidationComponent);
