import { RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import { combineFieldsReq, entityNullInitializeValues } from 'expressions/formValidation';
import useEntities from 'util/hooks/useEntities';
import fromEntries from 'util/fromentries';
import { useMemo, useCallback } from 'react';
import useKeyCachingEval from 'expressions/Provider/hooks/useKeyCachingEval';
import { unflatten } from 'flat';
import { SearchValidations } from 'reducers/searchValidationsReducer';

export const combineResults = (args: {
    results: {
        [expression: string]: [any]; // expression to result
    };
    expressionsToFields: {
        [expression: string]: string[]; // fields
    };
    expressionsToMessages: {
        [expression: string]: string; // message
    };
}) => {
    const { results, expressionsToFields, expressionsToMessages } = args;
    return fromEntries(
        Object.entries(results).flatMap(([exp, [result]]) => {
            if (result) {
                return [];
            }
            return expressionsToFields[exp].map((field) => [field, expressionsToMessages[exp]] as [string, string]);
        }),
    );
};

const useSearchValidator = (params: { viewName: string; overrideSearchValidation?: SearchValidations[0] }) => {
    const { viewName, overrideSearchValidation } = params;
    const _validations = useSelector(
        (state: RootState) =>
            (state.searchValidations && state.searchValidations[viewName]) || overrideSearchValidation,
    );
    const validations = useMemo(() => {
        return _validations || [];
    }, [_validations]);
    const entities = useEntities();

    const expressionsToFields = useMemo(() => {
        return fromEntries(
            validations.map((v) => [v.expression, combineFieldsReq(v.expansionsRequired, v.valuesetFieldsRequired)]),
        ) as {
            [expression: string]: string[];
        };
    }, [validations]);
    const expressionsToMessages = useMemo(() => {
        return fromEntries(validations.map((v) => [v.expression, v.message])) as {
            _expression: string;
        };
    }, [validations]);

    const expressionMappings = useMemo(() => {
        return fromEntries(validations.map((v) => [v.expression, [v.expression]] as [string, [any]])) as {
            [expression: string]: [any];
        };
    }, [validations]);

    const evaluate = useKeyCachingEval(expressionMappings);

    const fieldsRequired = useMemo(() => validations.flatMap((v) => v.expansionsRequired), [validations]);
    const valuesetFieldsRequired = useMemo(
        () => fromEntries(validations.flatMap((vs) => Object.entries(vs.valuesetFieldsRequired))),
        [validations],
    );
    return useCallback(
        (values: {}) => {
            const valuesConvertedToRealValues = unflatten(
                Object.fromEntries(Object.entries(values).map(([k, v]) => [k.split('_~_').join('.'), v])),
            );
            const nullInitialized = entityNullInitializeValues(
                valuesConvertedToRealValues,
                fieldsRequired,
                valuesetFieldsRequired,
                entities,
            );
            const results = evaluate(unflatten(nullInitialized));
            return unflatten(
                combineResults({
                    results,
                    expressionsToFields,
                    expressionsToMessages,
                }),
            );
        },
        [entities, evaluate, expressionsToFields, fieldsRequired, valuesetFieldsRequired, expressionsToMessages],
    );
};
export default useSearchValidator;
