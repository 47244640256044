import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type ViewDetail = {
    uuid: string;
    formId: string; // this is the redux-form id
} & (
    | {
          type: 'entity';
          entityId?: string;
          //   isPopover: boolean;
          //   viewType: string;
          //   entityId?: string;
          //   entityType: string;
          /**
           * Should we include the uuid of the form that opened us? Is the order always going to be correct here?
           * or,
           * openedFrom?: field that opened us?
           */
      }
    | {
          type: 'start-form';
      }
);

export type ViewDetailsStackState = ViewDetail[];

const initialState: ViewDetailsStackState = [];

export const viewDetailsStackSlice = createSlice({
    name: 'ViewDetailsStack',
    initialState,
    reducers: {
        delViewDetailByUuid: (state, action: PayloadAction<string>) => {
            return state.filter(({ uuid }) => uuid !== action.payload);
        },
        popViewDetail: (state) => {
            state.pop();
        },
        pushViewDetail: (state, action: PayloadAction<ViewDetail>) => {
            state.push(action.payload);
        },
    },
});

// Action creators are generated for each case reducer function
export const { delViewDetailByUuid, popViewDetail, pushViewDetail } = viewDetailsStackSlice.actions;

export default viewDetailsStackSlice.reducer;
