import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { removeSnackbar } from '../actions';
import { RootState } from 'reducers/rootReducer';
import { Button } from '@material-ui/core';

interface NotifierProps {}
const mapStateToProps = (state: RootState) => ({
    notifications: state.notistack.notifications,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ removeSnackbar }, dispatch);

interface NotifierComponentProps
    extends ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps>,
        WithSnackbarProps,
        NotifierProps {}
class NotifierComponent extends React.Component<NotifierComponentProps> {
    displayed: number[] = [];

    storeDisplayed = (id) => {
        this.displayed = [...this.displayed, id];
    };

    shouldComponentUpdate({ notifications: newSnacks = [] }: NotifierComponentProps) {
        const { notifications: currentSnacks } = this.props;
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i += 1) {
            if (notExists) {
                continue;
            }
            notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [] } = this.props;

        notifications.forEach((notification) => {
            // Do nothing if snackbar is already displayed
            if (this.displayed.includes(notification.key)) {
                return;
            }
            // Display snackbar using notistack
            const action = (key) => (
                <React.Fragment>
                    {notification.detail && (
                        <Button
                            color="inherit"
                            onClick={() => {
                                prompt(`Copy stack trace to clipboard: Ctrl+C, Enter`, notification.detail);
                            }}
                        >
                            Inspect Stack Trace
                        </Button>
                    )}
                    <Button
                        color="inherit"
                        onClick={() => {
                            this.props.closeSnackbar(key);
                        }}
                    >
                        Dismiss
                    </Button>
                </React.Fragment>
            );

            this.props.enqueueSnackbar(notification.message, { ...notification.options, action });

            // write message to alert if it's an error
            if (notification.options && notification.options.variant === 'error') {
                const liveElem = document.getElementById('notistack-forscreenreader');
                liveElem.textContent = notification.message;
            }
            // Keep track of snackbars that we've displayed
            this.storeDisplayed(notification.key);
            // Dispatch action to remove snackbar from redux store
            this.props.removeSnackbar(notification.key);
        });
    }

    render() {
        return <div id="notistack-forscreenreader" role="alert" className="casetivity-off-screen"></div>;
    }
}

const Notifier: React.ComponentType<NotifierProps> = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withSnackbar(NotifierComponent));

export default Notifier;
