import React from 'react';
import axios from 'axios';
import { RemoteData, initial, pending, success, failure } from '@devexperts/remote-data-ts';
import buildHeaders from 'sideEffect/buildHeaders';

interface FormDataFileUploadProps {
    url: string;
    render: (args: {
        onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        status: RemoteData<Error, undefined>;
        fileStaged: boolean;
    }) => JSX.Element;
}

interface FormDataFileUploadState {
    file: File | null;
    status: RemoteData<Error, undefined>;
}
class FormDataFileUpload extends React.Component<FormDataFileUploadProps, FormDataFileUploadState> {
    state = {
        file: null,
        status: initial,
    };
    onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Stop form submit
        this.fileUpload(this.state.file);
    };
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ file: e.target.files[0] });
    };
    fileUpload = (file: File) => {
        this.setState(
            (state) => ({
                ...state,
                status: pending,
            }),
            () => {
                const formData = new FormData();
                formData.append('file', file);
                const config = {
                    headers: buildHeaders({
                        includeCredentials: true,
                        'Content-Type': 'multipart/form-data',
                    }),
                };
                axios
                    .post(this.props.url, formData, config)
                    .then((r) => {
                        this.setState((s) => ({
                            ...s,
                            status: success(undefined),
                        }));
                    })
                    .catch((e) => {
                        this.setState((s) => ({
                            ...s,
                            status: failure(e),
                        }));
                    });
            },
        );
    };

    render() {
        return this.props.render({
            status: this.state.status,
            onFormSubmit: this.onFormSubmit,
            onChange: this.onChange,
            fileStaged: Boolean(this.state.file),
        });
    }
}
export default FormDataFileUpload;
