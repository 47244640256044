export const STRING = 'STRING';
export const INTEGER = 'INTEGER';
export const LONG = 'LONG';
export const BIGDECIMAL = 'BIGDECIMAL';
export const FLOAT = 'FLOAT';
export const DOUBLE = 'DOUBLE';
export const BOOLEAN = 'BOOLEAN';
export const DATE = 'DATE';
export const BLOB = 'BLOB';
export const ANYBLOB = 'ANYBLOB';
export const IMAGEBLOB = 'IMAGEBLOB';
export const TEXTBLOB = 'TEXTBLOB';
export const INSTANT = 'INSTANT';
export const VALUESET = 'VALUESET';
export const VALUESET_MANY = 'VALUESETMANY';
export const REFONE = 'REFONE';
export const REFONE_JOIN = 'REFONEJOIN';
export const REFMANY = 'REFMANY';
export const REFMANY_JOIN = 'REFMANYJOIN';
export const REFMANYMANY = 'REFMANYMANY';
