import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { useStyles } from 'global-alerts/components/BannerComponent';
import Info from '@material-ui/icons/InfoOutlined';
import { Card, Typography, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import useViewConfig from 'util/hooks/useViewConfig';
import { View } from 'reducers/ViewConfigType';
import produce from 'immer';
import { viewToViewDef } from 'layout-editor/hooks/useSubmitView';
import { loadSuccess } from 'viewConfig/actions';
import { mergeViewIntoViewConfig } from 'layout-editor/components/EditableViewFormEditor';
import sortBy from 'lodash/sortBy';
import LinkToExampleView from './LinkToExample';
import PreviewPopup from './PreviewPopup';

const getViewsChangedSelector = () => {
    return createSelector(
        (state: RootState) => state.viewConfig && state.viewConfig.previewMeta,
        (
            previewMeta,
        ): null | [string, RootState['viewConfig']['previewMeta']['viewsChanged'][0]['old'], boolean | undefined][] => {
            if (!previewMeta) {
                return null;
            }
            return Object.entries(previewMeta.viewsChanged).map(([viewName, d]) => {
                return [viewName, d.old, d.viewDef];
            });
        },
    );
};

const PreviewBanner = (props) => {
    const viewsChangedSelector = useMemo(getViewsChangedSelector, []);
    const viewsChangedData = useSelector(viewsChangedSelector);

    const classes = useStyles(props);
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    const [tempUndone, setTempUndone] = useState<
        {
            viewName: string;
            view: View;
            viewDef: boolean;
        }[]
    >([]); // ViewDefs
    const undoViewPatch = (args: { viewName: string; old: View; viewDef: any }) => {
        const { viewName, old, viewDef } = args;
        const undoneViewConfig = produce(viewConfig, (draft) => {
            if (old) {
                draft.views[viewName] = old;
            } else {
                delete draft.views[viewName];
            }
            if (viewDef && draft.viewDefs && old) {
                draft.viewDefs[viewName] = {
                    entity: old.entity,
                    name: old.name,
                    definition: JSON.stringify(viewToViewDef(old)),
                };
            }
            delete draft.previewMeta.viewsChanged[viewName];
        });
        dispatch(loadSuccess(undoneViewConfig));
    };
    return viewsChangedData ? (
        <div className={classes.root}>
            {sortBy(
                [
                    ...tempUndone.map((t) => ({ ...t, type: 'tempUndone' } as const)),
                    ...viewsChangedData.map(
                        ([viewName, old, viewDef]) =>
                            ({
                                viewName,
                                old,
                                viewDef,
                                type: 'applied',
                            } as const),
                    ),
                ],
                ['viewName'],
            ).map((v, i) => {
                if (v.type === 'tempUndone') {
                    const { viewName, viewDef, view } = v;
                    return (
                        <Card key={i} className={classes.card}>
                            <div style={{ height: '100%', width: '.5em', backgroundColor: 'orange' }} />
                            <Info />
                            <Typography className={classes.heading}>Disabled patch: {viewName}</Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={false}
                                            onChange={() => {
                                                setTempUndone([...tempUndone.slice(0, i), ...tempUndone.slice(i + 1)]);
                                                dispatch(
                                                    loadSuccess(mergeViewIntoViewConfig(view, viewConfig, viewDef)),
                                                );
                                            }}
                                            name="checkedA"
                                        />
                                    }
                                    label="Preview patch"
                                />
                            </FormGroup>
                            <button
                                onClick={() => {
                                    setTempUndone([...tempUndone.slice(0, i), ...tempUndone.slice(i + 1)]);
                                }}
                            >
                                Remove
                            </button>
                            <PreviewPopup newView={view} old={viewConfig.views[viewName]} />
                        </Card>
                    );
                }
                const { viewName, old, viewDef } = v;
                const view = viewConfig.views[viewName];
                return (
                    <Card key={i} className={classes.card}>
                        <div style={{ height: '100%', width: '.5em', backgroundColor: 'orange' }} />
                        <Info />
                        <Typography className={classes.heading}>Previewing with modifications to {viewName}</Typography>
                        <LinkToExampleView viewName={viewName} />
                        &nbsp;
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked
                                        onChange={() => {
                                            setTempUndone([
                                                ...tempUndone,
                                                {
                                                    viewName,
                                                    view,
                                                    viewDef,
                                                },
                                            ]);
                                            undoViewPatch({ viewName, old, viewDef });
                                        }}
                                        name="checkedA"
                                    />
                                }
                                label="Disable Patch"
                            />
                        </FormGroup>
                        <button
                            onClick={() => {
                                undoViewPatch({ viewName, old, viewDef });
                            }}
                        >
                            Remove
                        </button>
                        {/* preview here */}
                        <PreviewPopup newView={viewConfig.views[viewName]} old={old} viewConfig={viewConfig} />
                    </Card>
                );
            })}
        </div>
    ) : null;
};
export default PreviewBanner;
