import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { Button, Card } from '@material-ui/core';
import CommentPanel from '../layout/CommentPanel';
import TwoPanel from '../layout/TwoPanel';
import TaskHeader from './TaskForm/TaskHeader';
import TaskAttributes from './TaskForm/TaskAttributes';
import { taskEventCreator } from '../../actions/taskEvent';
import TaskForm from './TaskForm/TaskForm';
import withAdjustedWidth from '../layout/withAdjustedWidth';
import { RootState } from '../../../reducers/rootReducer';
import { withDateFormat } from '../../../fieldFactory/dateFormat/Broadcasts';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';
import { getKey } from 'remoteStatus/one/reducer';
import { getTask } from 'bpm/task/actions';
import useIsAnonymous from 'util/hooks/useIsAnonymous';
import useFetchTaskForm from './TaskForm/hooks/useFetchTaskForm';
import { TaskLeftPanelProps, mapStateToTaskDetailProps } from './selectors';

const AdhocTaskLeftPanelComponent: React.FunctionComponent<TaskLeftPanelComponentProps> = ({
    taskId,
    task,
    isAdmin,
    completeTask,
    processId,
    currentUser,
    initialAssignee,
    renderLinkedEntity,
    formatDate,
}) => (
    <div>
        <Card style={{ padding: '1em', marginBottom: '1em' }}>
            <TaskHeader
                taskId={taskId}
                task={task}
                isAdmin={isAdmin}
                title={task && task.name ? `Task: ${task.name}` : ''}
                processId={processId}
            />
            <div>
                <TaskAttributes processId={processId} taskId={taskId} isAdmin={isAdmin} />
                <div style={{ padding: '16px' }}>
                    {task &&
                        (task.endDate ? (
                            <span>Task Completed on {formatDate(task.endDate)}</span>
                        ) : (
                            <Button
                                disabled={!task || initialAssignee !== currentUser}
                                variant="contained"
                                color="primary"
                                onClick={() => completeTask(taskId)}
                            >
                                Complete Task
                            </Button>
                        ))}
                </div>
            </div>
        </Card>
        {renderLinkedEntity && <div style={{ marginTop: '1em' }}>{renderLinkedEntity({})}</div>}
    </div>
);

const AdhocTaskLeftPanelComponentWithDate = withDateFormat(AdhocTaskLeftPanelComponent);

const initial = { _type: 'initial' } as const;

// returns true after the first load -> success transition.
const useDataLoadedOnce = (taskId: string) => {
    const dataState = useSelector((state: RootState) => state.getOneStatus[getKey('TaskInstance', taskId)] || initial);
    const prevDataState = React.useRef(dataState._type);
    const dataJustLoaded = dataState._type === 'success' && prevDataState.current !== 'success';
    const dataLoadedOnce = React.useRef(dataJustLoaded);
    if (dataJustLoaded) {
        dataLoadedOnce.current = true;
    }
    prevDataState.current = dataState._type;
    return dataLoadedOnce.current;
};

const FetchTaskForm: React.FC<{ taskId: string }> = ({ taskId }) => {
    useFetchTaskForm(taskId);
    return null;
};
const TaskLeftPanelComponent: React.FunctionComponent<TaskLeftPanelComponentProps> = (props) => {
    const dataLoadedOnce = useDataLoadedOnce(props.taskId);

    return (
        <>
            {props.task && props.taskId && props.processId ? <FetchTaskForm taskId={props.taskId} /> : null}
            <FormDisplayStatus
                // delay rendering until load once, (so that we know whether task is open or closed before rendering form,)
                // since closed forms might now render correctly (i.e. Outcome expressions might not be templated if the task closed)
                //  but on subsequent TaskInstance fetches (e.g. happens on claiming/assigning)
                // do show previous while loading, so the page doesn't collapse into loading indicator.
                showPrevWhileLoading={dataLoadedOnce}
                id={props.taskId}
                resource="TaskInstance"
                showSuccessOffline={!!props.task}
                renderSuccess={() => {
                    if (props.task && !props.task.formKey) {
                        return <AdhocTaskLeftPanelComponentWithDate {...props} />;
                    }
                    if (props.task && props.taskId && props.processId) {
                        return <TaskForm {...props} processId={props.processId} taskId={props.taskId} />;
                    }
                    return <div />;
                }}
                refresh={null}
            />
        </>
    );
};

const dispatches = {
    completeTask: taskEventCreator.completeTask,
};
interface TaskLeftPanelComponentProps
    extends TaskLeftPanelProps,
        ReturnType<typeof mapStateToTaskDetailProps>,
        Pick<typeof dispatches, 'completeTask'> {
    queryParams?: { ['claimIfUnassigned']?: boolean };
    formatDate: (date?: Date | string | null) => string;
}
export const TaskLeftPanel = compose(
    connect(mapStateToTaskDetailProps, dispatches),
    (BaseComponent) => (props: TaskLeftPanelComponentProps) => {
        const isAnonymous = useIsAnonymous();
        const claimIfUnassigned = !isAnonymous && props.queryParams?.claimIfUnassigned;
        const dispatch = useDispatch();
        React.useEffect(() => {
            dispatch(getTask(props.taskId, claimIfUnassigned));
        }, [props.taskId, dispatch, claimIfUnassigned]);
        return <BaseComponent {...props} />;
    },
)(TaskLeftPanelComponent);

const TaskView: React.FunctionComponent<{
    taskId: string;
    width: number;
}> = ({ taskId, width }) => (
    <TwoPanel
        width={width}
        leftPanelContent={<TaskLeftPanel taskId={taskId} />}
        rightPanelContent={<CommentPanel taskId={taskId} />}
        offCenterSplit={true}
    />
);

export default withAdjustedWidth(TaskView);
