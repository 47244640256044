/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */
import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { processContext } from 'bpm/components/processContext';
import casetivityViewContext from 'util/casetivityViewContext';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { RootState } from 'reducers/rootReducer';
import { getPrintTemplateByName } from 'printTemplate/getPrintTemplatesByName/actions';
import { CircularProgress } from '@material-ui/core';
import { refreshJwt, getUrl } from 'sideEffect/services';
import { ajax, AjaxError } from 'rxjs/ajax';
import Warning from '@material-ui/icons/Warning';
import { getDisplayText, getFileName } from 'printTemplate/component/PrintTemplateField';
import { useLocale } from 'i18n/useMessages';
import buildHeaders from 'sideEffect/buildHeaders';

interface BaseServerTemplatedPrintTemplateField {
    resource: string;
    type?: 'report' | 'pdf';
    printTemplateName: string;
    record?: {
        id: string;
        title?: string;
        entityType: string;
        config?: string;
    };
    displayText?: string;
}
type ServerTemplatedPrintTemplateFieldProps = (
    | {
          component?: 'a';
          anchorClass?: string;
          padding?: 'normal' | 'none';
      }
    | {
          component: 'button';
          buttonClass?: string;
      }
) &
    BaseServerTemplatedPrintTemplateField;
type FetchState =
    | {
          type: 'NOT_FETCHING';
      }
    | {
          type: 'FETCHING_1';
      }
    | {
          type: 'ERROR';
      }
    | {
          type: 'SUCCESS';
      };
const ServerTemplatedPrintTemplateField = (props: ServerTemplatedPrintTemplateFieldProps) => {
    const { type = 'pdf' } = props;
    const [state, setState] = useState<FetchState>({ type: 'NOT_FETCHING' });
    const viewContext = useContext(casetivityViewContext);
    const { taskId: taskInstanceId } = useContext(processContext);
    const { fieldValues, variables } = useContext(formContext);
    const formData = useMemo(() => ({ ...variables, ...fieldValues }), [variables, fieldValues]);
    const locale = useLocale();
    const dispatch = useDispatch();
    const printTemplateRecord = useSelector((state: RootState) => state.printTemplates.byName[props.printTemplateName]);
    const printTemplateId = printTemplateRecord && printTemplateRecord.id;
    const entityId = props.record && props.record.id;
    const fileName = getFileName(printTemplateRecord, props.record);
    useEffect(() => {
        if (taskInstanceId || viewContext === 'START_FORM') {
            dispatch(getPrintTemplateByName(props.printTemplateName));
        }
    }, [taskInstanceId, viewContext, dispatch, props.printTemplateName]);

    useEffect(() => {
        if (state.type === 'FETCHING_1') {
            const $ajax = refreshJwt(
                ajax({
                    url: getUrl('api/generate-print-template'),
                    method: 'POST',
                    headers: buildHeaders({
                        includeCredentials: true,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    }),
                    responseType: 'blob',
                    withCredentials: true,
                    body: {
                        printTemplateId,
                        entityId,
                        taskInstanceId,
                        type,
                        formData: {
                            ...formData,
                            _locale: locale,
                        },
                    },
                }),
            );
            const subscription = $ajax.subscribe(
                (res) => {
                    if (res.status >= 200 && res.status < 300) {
                        setState({ type: 'SUCCESS' });
                        saveAs(res.response, `${fileName}.pdf`);
                    } else {
                        setState({ type: 'ERROR' });
                    }
                },
                (error: AjaxError) => {
                    console.error(error);
                    setState({ type: 'ERROR' });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [type, printTemplateId, taskInstanceId, entityId, formData, locale, state.type, fileName]);

    const fetchAndDownload = useCallback(() => {
        setState({ type: 'FETCHING_1' });
    }, [setState]);
    const displayText = props.displayText ?? getDisplayText(printTemplateRecord);
    const renderStatusIcon = () =>
        state.type === 'FETCHING_1' ? (
            <CircularProgress style={{ height: 15, width: 15 }} />
        ) : state.type === 'ERROR' ? (
            <Warning color="error" />
        ) : null;

    const renderSpacedIcon = () => {
        const icon = renderStatusIcon();
        return (
            icon && (
                <span style={{ position: 'relative', width: 0 }}>
                    <span style={{ position: 'absolute', left: 8, marginTop: 1 }}>{icon}</span>
                </span>
            )
        );
    };
    if (props.component === 'button') {
        return (
            <button className={props.buttonClass} onClick={fetchAndDownload}>
                <span>
                    {displayText}
                    {renderSpacedIcon()}
                </span>
            </button>
        );
    }
    const anchor = (
        <a className={props.anchorClass} href="javascript:;" onClick={fetchAndDownload}>
            <span>
                {displayText}
                {renderSpacedIcon()}
            </span>
        </a>
    );
    if (props.padding === 'none') {
        return anchor;
    }
    return (
        <div style={{ paddingBottom: '.75em', paddingTop: '.5em', paddingLeft: '.5em', paddingRight: '.5em' }}>
            {anchor}{' '}
        </div>
    );
};

export default ServerTemplatedPrintTemplateField;
