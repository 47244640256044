import { crudGetListSuccess } from 'sideEffect/crud/getList/actions';
import { crudDeleteSuccess } from 'sideEffect/crud/delete/actions';
import { getType, ActionType } from 'typesafe-actions';
import getResource from './getResource';

export type ids = (string | number)[];
export default (resource: string) =>
    (
        previousState: ids = [],
        action: ActionType<typeof crudGetListSuccess> | ActionType<typeof crudDeleteSuccess>,
    ): ids => {
        const _resource = getResource(action);
        if (_resource !== resource) {
            return previousState;
        }
        switch (action.type) {
            case getType(crudGetListSuccess):
                return action.payload.data.result;
            case getType(crudDeleteSuccess): {
                const index = previousState.findIndex((el) => el === action.payload.id);
                if (index === -1) {
                    return previousState;
                }
                return [...previousState.slice(0, index), ...previousState.slice(index + 1)];
            }
            default:
                return previousState;
        }
    };

export const getIds = (state) => state;
