import ViewConfig, { MenuItem } from 'reducers/ViewConfigType';
import { push as pushAction } from 'connected-react-router';
import { translateRoute } from 'components/generics/utils/viewConfigUtils';
import { startProcessFromKey } from 'bpm/create-process-instance/actions';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';

const getHandleMenuClick =
    (viewConfig: ViewConfig, dispatch: (action: any) => void) => (m: MenuItem, callBeforeNav?: () => void) => {
        const getNavRoute = (conf: ViewConfig['menus'][0]) => {
            const _route = translateRoute(viewConfig, conf.route, conf.view);
            return _route.startsWith('/') ? _route : `/${_route}`;
        };
        const dispatchProcessStart = (processDefinitionKey: string) => {
            dispatch(
                startProcessFromKey(
                    {
                        processDefinitionKey,
                    },
                    {
                        handleRedirect: true,
                        successCb: () => null,
                        errorsCbs: {},
                        keyNotFoundCb: () => {
                            alert('process key "' + processDefinitionKey + '" not found.');
                        },
                    },
                ),
            );
        };

        const handleClick =
            m.route || m.view
                ? () => {
                      callBeforeNav?.();
                      if (m.route?.startsWith('startProcess(') && m.route?.endsWith(')')) {
                          const processDefinitionKey = m.route.slice('startProcess('.length, -1);
                          dispatchProcessStart(processDefinitionKey);
                      } else if (m.route?.startsWith('http')) {
                          window.location.href = m.route;
                      } else {
                          const route = getNavRoute(m);
                          const templatedRoute = evaluateFilterString(route, {
                              ...currentUserProperties(viewConfig),
                          });
                          dispatch(pushAction(templatedRoute));
                      }
                  }
                : undefined;
        return handleClick;
    };

export default getHandleMenuClick;
