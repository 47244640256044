import React, { useState, useCallback, useRef } from 'react';
import { RWTabListS, TabProvider, tabHasError } from 'components/generics/form/TabbableForm';
import { View } from 'reducers/ViewConfigType';
import { getTabsTitlesFromView, findTabsWithErrorsFromViewDef } from 'components/generics/utils/viewConfigUtils';
import { Tab as RWTab, TabPanel as RWTabPanel } from 'react-web-tabs';
import { useTheme, IconButton, Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Warning from '@material-ui/icons/Warning';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import Popup, { PopupProps } from 'components/Popup';
import AddTab, { FormData } from './AddTab';
import fromEntries from 'util/fromentries';
import Reorder from 'react-reorder';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import classnames from 'classnames';
import produce from 'immer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalDiv: {
            width: '100%',
        },
        listItem: {},
        reorderableListItem: {
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.grey[300],
            },
        },
    }),
);

const TabDetailsEditor: React.FunctionComponent<{
    tabKey?: string;
    renderToggler: PopupProps['renderToggler'];
    handleTabDataChange: (tabData: FormData, tabKey?: string) => void;
    initialValues?: Partial<FormData>;
}> = ({ initialValues, tabKey, handleTabDataChange, renderToggler }) => (
    <Popup
        renderDialogContent={({ closeDialog }) => {
            return (
                <div>
                    <IconButton style={{ float: 'right' }} onClick={closeDialog}>
                        <Clear />
                    </IconButton>
                    <AddTab
                        initialValues={initialValues}
                        onAdd={(tabData) => {
                            closeDialog();
                            handleTabDataChange(tabData, tabKey);
                        }}
                    />
                </div>
            );
        }}
        renderToggler={renderToggler}
    />
);

interface TabEditorProps {
    view: View;
    renderTab: (args: { tabKey: string; isActiveTab: boolean }) => JSX.Element;
    onViewChange: (args: { view: View }) => void;
}
const TabEditor: React.FunctionComponent<TabEditorProps> = (props) => {
    const { view, onViewChange } = props;
    const classes = useStyles(props);
    const [currentTabKey, setCurrentTabKey] = useState<string | null>(null);
    const tabTitles = props.view.tabs ? getTabsTitlesFromView(props.view) : [];
    const theme = useTheme();
    const tabsWithErrors = useSelector((state: RootState) => findTabsWithErrorsFromViewDef(state, props.view));
    const handleTabDelete = useCallback(
        (tabKey: string) => () => {
            onViewChange({
                view: {
                    ...view,
                    tabs: fromEntries(Object.entries(view.tabs).filter((t) => t[0] !== tabKey)),
                },
            });
        },
        [view, onViewChange],
    );
    const handleTabDataChange = useCallback(
        (tabData: FormData, tabKey?: string) => {
            const newView = produce(view, (draftView) => {
                if (!tabKey) {
                    //  new tab
                    if (!draftView.tabs) {
                        draftView.tabs = {};
                    }
                    draftView.tabs[tabData.label] = {
                        label: tabData.label,
                        fields: {},
                    };
                }
                if (draftView.tabs[tabKey]) {
                    draftView.tabs[tabKey].label = tabData.label;
                }
            });
            onViewChange({ view: newView });
        },
        [view, onViewChange],
    );
    const handleReorderTabs = useCallback(
        <T extends { currentTabKey: string }>(list: T[]) => {
            const resultingView = {
                view: {
                    ...view,
                    tabs: fromEntries(list.map(({ currentTabKey }) => [currentTabKey, view.tabs[currentTabKey]])),
                },
            };
            onViewChange(resultingView);
        },
        [view, onViewChange],
    );
    const [disableButtons, setDisableButtons] = useState(false);
    const clickedTabs = useRef([]);
    // the below COULD be
    // const handleTabChange = setCurrentTabKey;
    // however we need to prevent quickly flipping through tabs, because layouts need time to calculate their layouts while remaining mounted.
    const handleTabChange = React.useMemo(
        () => (tk: string) => {
            if (clickedTabs.current.includes(tk)) {
                // clicked tabs have their layouts already calculated, so we can flip past them.
                setCurrentTabKey(tk);
            } else {
                const TIME_IT_TAKES_FOR_LAYOUT_TO_CALCULATE_ITSELF = 500;
                // if it's a new layout, we NEED to wait for it to lay itself out, so
                // we have to prevent further tab/arrow/click into other tabs for TIME_IT_TAKES_FOR_LAYOUT_TO_CALCULATE_ITSELF
                clickedTabs.current.push(tk); // <- add to traversed tabs.
                setDisableButtons(true);
                // set the buttons to be disabled, to prevent rapidly navigating (w. arrow keys for example) to the next tab
                // before TIME_IT_TAKES_FOR_LAYOUT_TO_CALCULATE_ITSELF elapses.
                // we need to yield control so the buttons get disabled quickly (hence continued flow in setImmediate)
                setImmediate(() => {
                    setCurrentTabKey(tk);
                    setTimeout(() => {
                        setDisableButtons(false);
                        // wait for buttons to become enabled again, and then regain focus on the latest tab.
                        setImmediate(() => {
                            document
                                .getElementById(clickedTabs.current[clickedTabs.current.length - 1] + '-tab')
                                ?.focus();
                        });
                    }, TIME_IT_TAKES_FOR_LAYOUT_TO_CALCULATE_ITSELF);
                });
            }
        },
        [setCurrentTabKey, setDisableButtons],
    );
    return (
        <RWTabListS
            // need to remount everything when orderings change - otherwise after reordering, clicking on a particular tab won't work.
            key={tabTitles.reduce((prev, curr) => prev + ':' + curr, '')}
            defaultTab={currentTabKey}
            width="lg"
            onChange={handleTabChange}
            panes={tabTitles.map((tabKey, tabIx) => {
                return (
                    <RWTabPanel
                        tabId={tabKey}
                        key={tabIx + ':' + tabKey}
                        style={{ width: '100%' }}
                        render={() => {
                            const isActiveTab = currentTabKey === tabKey || (tabIx === 0 && !currentTabKey);
                            return (
                                <TabProvider isActive={isActiveTab} tabKey={tabKey}>
                                    <div style={{ width: '100%' }}>
                                        {(props.view.tabs || {})[tabKey].label && (
                                            <h2 style={{ paddingTop: '8px' }} id={`header:${tabKey}`}>
                                                {props.view.tabs![tabKey].label}
                                            </h2>
                                        )}
                                        {props.renderTab({ tabKey, isActiveTab })}
                                    </div>
                                </TabProvider>
                            );
                        }}
                    />
                );
            })}
            tabs={
                <React.Fragment>
                    <div style={{ margin: '20px', marginTop: '45px', padding: '10px' }}>
                        <Popup
                            divClass={classes.modalDiv}
                            renderDialogContent={({ closeDialog }) => {
                                return (
                                    <div style={{ width: '100%' }}>
                                        <div style={{ padding: '5px', paddingTop: '10px', paddingLeft: '10px' }}>
                                            <IconButton size="small" style={{ float: 'right' }} onClick={closeDialog}>
                                                <Clear />
                                            </IconButton>
                                            <TabDetailsEditor
                                                handleTabDataChange={handleTabDataChange}
                                                renderToggler={({ openDialog }) => {
                                                    return (
                                                        <span>
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={openDialog()}
                                                            >
                                                                Add Tab <Add />
                                                            </Button>
                                                        </span>
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <Reorder
                                                itemKey="currentTabKey"
                                                lock="horizontal"
                                                list={Object.entries(props.view.tabs || {}).map(([k, v]) => ({
                                                    currentTabKey: k,
                                                    label: v.label,
                                                }))}
                                                // A template to display for each list item
                                                template={(props) => (
                                                    <div
                                                        className={classnames(classes.reorderableListItem)}
                                                        style={{ padding: '1em' }}
                                                    >
                                                        <DragIndicatorIcon
                                                            style={{
                                                                opacity: 0.5,
                                                                verticalAlign: 'middle',
                                                                marginBottom: '3px',
                                                            }}
                                                        />
                                                        &nbsp;&nbsp;
                                                        {props.item.label}
                                                    </div>
                                                )}
                                                // Function that is called once a reorder has been performed
                                                callback={(
                                                    event,
                                                    itemThatHasBeenMoved,
                                                    itemsPreviousIndex,
                                                    itemsNewIndex,
                                                    reorderedArray,
                                                ) => {
                                                    handleReorderTabs(reorderedArray);
                                                }}
                                                selectedKey="tabKey"
                                                disableReorder={false}
                                                disableDragClass="no-drag"
                                            />
                                            <ul style={{ listStyleType: 'none', paddingRight: '5px', margin: 0 }}>
                                                {Object.entries(props.view.tabs || {}).map(([k, { label }], i, arr) => (
                                                    <li
                                                        key={k}
                                                        className={classes.listItem}
                                                        style={{
                                                            height: `calc(100% / ${arr.length})`,
                                                        }}
                                                    >
                                                        <div style={{ paddingTop: '11px' }}>
                                                            <div
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                            >
                                                                <TabDetailsEditor
                                                                    tabKey={k}
                                                                    initialValues={{ label }}
                                                                    handleTabDataChange={handleTabDataChange}
                                                                    renderToggler={({ openDialog }) => {
                                                                        return (
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={(e) => {
                                                                                    openDialog()();
                                                                                }}
                                                                            >
                                                                                <Edit />
                                                                            </IconButton>
                                                                        );
                                                                    }}
                                                                />
                                                                <IconButton
                                                                    size="small"
                                                                    className="no-drag"
                                                                    onClick={(e) => {
                                                                        handleTabDelete(k)();
                                                                    }}
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            }}
                            renderToggler={({ openDialog }) => (
                                <Button color="primary" variant="contained" onClick={openDialog()}>
                                    Edit Tabs&nbsp;&nbsp;
                                    <Edit />
                                </Button>
                            )}
                        />
                    </div>
                    {tabTitles.map((tabKey, tabIx) => {
                        const label = props.view.tabs![tabKey].label;
                        const hasError = tabHasError(tabsWithErrors, currentTabKey)(tabKey, tabIx);
                        return (
                            <RWTab
                                disabled={disableButtons && tabKey !== currentTabKey}
                                style={{
                                    ...(hasError ? { color: theme.palette.error.main } : {}),
                                    textAlign: 'left',
                                }}
                                key={tabIx + ':' + tabKey}
                                tabFor={tabKey}
                            >
                                {label}
                                {hasError ? (
                                    <span style={{ position: 'relative' }}>
                                        <Warning
                                            style={{
                                                color: hasError ? theme.palette.error.main : undefined,
                                                marginLeft: 10,
                                                position: 'absolute',
                                                bottom: 0,
                                            }}
                                        />
                                    </span>
                                ) : null}
                            </RWTab>
                        );
                    })}
                </React.Fragment>
            }
        />
    );
};

export default TabEditor;
