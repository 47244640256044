import * as React from 'react';
import { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import WithErrorBoundary from '../WithErrorBoundary';
import memoize from 'lodash/memoize';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const UncheckedGrid: any = Grid;
/*
    span	the number of cells,0 corresponds to display: none
    offset	the number of cells to the left of the grid spacing, no cell in grid spacing
    order	col number in the row
    xs	<576px and also default setting, could be a span value or a object contain above props
    sm	≥576px, could be a span value or a object contain above props
    md	≥768px, could be a span value or a object contain above props
    lg	≥992px, could be a span value or a object contain above props
    xl	≥1200px, could be a span value or a object contain above props
    https://github.com/evgenyrodionov/flexboxgrid2
*/

const encloseInARow = (cols, flexStart = false) => {
    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={0}>
                {cols}
            </Grid>
        </Grid>
    );
};

const getGridStyle = memoize((rowMargin: 'normal' | 'none', alignSelf: boolean) => {
    const rowMarginStyle = rowMargin === 'normal' ? { marginTop: '16px', marginBottom: '8px' } : {};
    return alignSelf ? { alignSelf: 'flex-start', ...rowMarginStyle } : rowMarginStyle;
});

const displayField = <FieldProps extends { renderDesc?: () => React.ReactNode; dropdownPosition?: 'above' | 'below' }>(
    field: React.ReactElement<FieldProps>,
    dropdownsFlipUp?: boolean,
) => (
    <WithErrorBoundary>
        <div>
            {dropdownsFlipUp ? React.cloneElement(field, { dropdownPosition: 'above' } as Partial<FieldProps>) : field}
            {field.props.renderDesc ? (
                <div style={{ paddingLeft: '10px', marginTop: '-5px' }}>{field.props.renderDesc()}</div>
            ) : null}
        </div>
    </WithErrorBoundary>
);
const encloseInACol = (
    field,
    dropdownsFlipUp = false,
    smSize: number | null = 12,
    xsSize: number | null = smSize,
    rowMargin = 'normal',
) => {
    if (field === undefined) {
        return <Grid item={true} xs={12} sm={12} md={12} lg={12} />;
    }
    if (field.props.dontShowCol) {
        return <div style={{ display: 'none' }} />;
    }
    return (
        <UncheckedGrid
            item={true}
            xs={(xsSize === null ? field.props.span : xsSize) || false}
            sm={(smSize === null ? field.props.span : smSize) || false}
            md={field.props.span || false}
            lg={field.props.span || false}
            xl={field.props.span || false}
            style={getGridStyle(rowMargin as 'normal' | 'none', field.props.alignSelf)}
        >
            {displayField(field, dropdownsFlipUp)}
        </UncheckedGrid>
    );
};

const encloseInAGrid = (rows) => (
    <div>
        <Grid alignItems="flex-end" container={true} spacing={0}>
            {rows.map((row, key) => React.cloneElement(row, { key }))}
        </Grid>
    </div>
);

const orderFieldsByCol = (fields) => {
    return orderBy(fields, 'props.column', 'asc');
};

interface RGridProps {
    smSize?: number | null;
    xsSize?: number | null;
    flexStart?: boolean;
    fields?: ReactElement<{
        row?: number;
        span?: number;
    }>[];
    lastRowDropdownsFlipUp?: boolean;
    rowMargin?: 'none' | 'normal';
    printRowMargin?: string | number;
}

const sortFieldGroupsByRow = (fields) => {
    let sortedFields = fields;
    if (fields) {
        sortedFields = fields
            .filter((field) => field != null)
            .sort((field1, field2) => {
                if (!field1.props.row && !field2.props.row) {
                    return 0;
                }
                if (!field1.props.row && field2.props.row) {
                    return 1;
                }
                if (field1.props.row && !field2.props.row) {
                    return -1;
                }
                if (field1.props.row && field2.props.row) {
                    if (field1.props.row < field2.props.row) {
                        return -1;
                    }
                    if (field1.props.row > field2.props.row) {
                        return 1;
                    }
                }
                return 0;
            });
    }

    return groupBy(sortedFields, 'props.row');
};

const RGrid: React.FunctionComponent<RGridProps> = React.memo(
    ({ fields, lastRowDropdownsFlipUp = false, flexStart, smSize, xsSize, rowMargin }: RGridProps) => {
        const children = React.useMemo(() => {
            let fieldGroupsByRow = sortFieldGroupsByRow(fields);

            const rows: ReactElement<{}>[] = [];

            const fieldGroups = Object.values(fieldGroupsByRow);
            fieldGroups.forEach((group, i) => {
                const orderedFieldsOfARow = orderFieldsByCol(group);
                const cols: ReactElement<{}>[] = [];
                if (orderedFieldsOfARow.length > 0) {
                    const isLastRow = i === fieldGroups.length - 1;
                    orderedFieldsOfARow.forEach((field, key) => {
                        cols.push(
                            React.cloneElement(
                                encloseInACol(field, lastRowDropdownsFlipUp && isLastRow, smSize, xsSize, rowMargin),
                                { key },
                            ),
                        );
                    });
                } else {
                    cols.push(encloseInACol(undefined));
                }
                rows.push(encloseInARow(cols, flexStart));
            });
            return encloseInAGrid(rows);
        }, [fields, lastRowDropdownsFlipUp, flexStart, smSize, xsSize, rowMargin]);

        return <div style={{ width: '100%' }}>{children}</div>;
    },
);

const RGridForPrint: React.FunctionComponent<RGridProps> = React.memo(({ fields, printRowMargin }: RGridProps) => {
    const children = React.useMemo(() => {
        const _encloseInAGrid = (rows) => <div>{rows.map((row, key) => React.cloneElement(row, { key }))}</div>;
        const _encloseInARow = (cols) => {
            return (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(12, 1fr)',
                        paddingTop: printRowMargin ?? '4px',
                    }}
                >
                    {cols}
                </div>
            );
        };
        const _encloseInACol = (field, dropdownsFlipUp = false) => {
            if (field === undefined) {
                return <div />;
            }
            if (field.props.dontShowCol) {
                return <div style={{ display: 'none' }} />;
            }
            return (
                <div
                    style={{
                        gridColumn: `span ${field.props.span}`,
                        pageBreakInside: 'avoid',
                        breakInside: 'avoid',
                        pageBreakBefore: 'auto',
                        pageBreakAfter: 'auto',
                    }}
                >
                    {displayField(field)}
                </div>
            );
        };

        let fieldGroupsByRow = sortFieldGroupsByRow(fields);

        const rows: ReactElement<{}>[] = [];

        const fieldGroups = Object.values(fieldGroupsByRow);
        fieldGroups.forEach((group, i) => {
            const orderedFieldsOfARow = orderFieldsByCol(group);
            const cols: ReactElement<{}>[] = [];
            if (orderedFieldsOfARow.length > 0) {
                orderedFieldsOfARow.forEach((field, key) => {
                    cols.push(React.cloneElement(_encloseInACol(field), { key }));
                });
            } else {
                cols.push(_encloseInACol(undefined));
            }
            const someNonemptyColumn = group.some((col) => col?.props && !col?.props?.dontShowCol);
            if (someNonemptyColumn) {
                rows.push(_encloseInARow(cols));
            }
        });
        return _encloseInAGrid(rows);
    }, [fields, printRowMargin]);

    return <div style={{ width: '100%' }}>{children}</div>;
});

const RGridController = (props: RGridProps) => {
    const printMode = useSelector((state: RootState) => state.printMode);
    return printMode ? <RGridForPrint {...props} /> : <RGrid {...props} />;
};
export default RGridController;
