import React, { useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { postFixInUrl } from 'clients/utils/translateFieldWithSearchTypeAppended';

const cardActionStyle: React.CSSProperties = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useMaybeGetSearchFromFilter = (listViewName: string, filterValues: {}) => {
    const viewConfig = useViewConfig();
    const mapSearchToCreate = useMemo(() => {
        const config = viewConfig.views[listViewName]?.config;
        if (config) {
            try {
                const { mapSearchToCreate = false } = JSON.parse(config);
                return mapSearchToCreate;
            } catch (e) {
                console.log('failed on ' + listViewName + ' config: ', config);
                console.error(e);
                return false;
            }
        }
        return false;
    }, [viewConfig, listViewName]);
    const search = useMemo(() => {
        if (!mapSearchToCreate) {
            return undefined;
        }
        const _search = Object.entries(filterValues || {})
            .flatMap(([k, v]) => {
                const [field, _searchType = null] = k.split('__');
                const searchType = _searchType as keyof typeof postFixInUrl;
                if (
                    !searchType ||
                    searchType === 'CONTAINS' ||
                    searchType === 'EXACT' ||
                    searchType === 'STARTS_WITH'
                ) {
                    return [`${field}=${v}`];
                }
                return [];
            })
            .join('&');
        return _search ? `?${_search}` : undefined;
    }, [mapSearchToCreate, filterValues]);
    return search;
};

export default useMaybeGetSearchFromFilter;
