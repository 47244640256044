import { createCustomAction } from 'typesafe-actions';
import { START_PROCESS, START_PROCESS_SUCCESS, START_PROCESS_FROM_KEY, START_PROCESS_FAILURE } from './constants';
import { ErrorsCbs } from 'sideEffect/crud/errorTypes';
import { AjaxError } from 'rxjs/ajax';

/*
action": {
    "type": "CUSTOM_POST",
    "payload": {
        "url": "api/bpm/process-instances",
        "body": {
            "processDefinitionKey": "MIISAddUsersDev",
            "values": {
                "ProviderId": "${record_id}",
                "actionType": "Add"
            }
        }
    }
},
*/
export interface StartProcessPayload {
    processDefinitionId: string;
    values?: {};
}
export interface StartProcessFromKeyPayload {
    processDefinitionKey: string;
    values?: {};
}
export interface StartProcessResponse {
    id: string;
    businessKey: string;
    // I think there are a bunch of other properties we get back as well.
}
export const startProcess = createCustomAction(START_PROCESS, (type) => {
    return (
        payload: StartProcessPayload,
        successCb?: (response: StartProcessResponse) => void,
        errorsCbs?: ErrorsCbs,
        handleRedirect?: boolean,
    ) => ({
        type,
        payload,
        successCb,
        errorsCbs,
        handleRedirect,
    });
});

export const startProcessFromKey = createCustomAction(START_PROCESS_FROM_KEY, (type) => {
    return (
        payload: StartProcessFromKeyPayload,
        options: {
            keyNotFoundCb?: () => void;
            successCb?: (response: StartProcessResponse) => void;
            errorsCbs?: ErrorsCbs;
            handleRedirect?: boolean;
        },
    ) => ({
        type,
        payload,
        ...options,
    });
});

export const startProcessSuccess = createCustomAction(START_PROCESS_SUCCESS, (type) => {
    return (response: StartProcessResponse, initialPayload: StartProcessPayload) => ({
        type,
        payload: {
            response,
        },
        initialPayload,
    });
});

export const startProcessFailure = createCustomAction(START_PROCESS_FAILURE, (type) => {
    return (error: AjaxError, initialPayload: StartProcessPayload) => ({
        type,
        payload: {
            error,
        },
        initialPayload,
    });
});
