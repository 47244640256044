import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import SsgAppBarMobile from '../SsgAppBarMobile';
import buildHeaders from 'sideEffect/buildHeaders';

const config = require('../../config.js');

export const dropzoneStyle = {
    // process upload dropzone
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    textAlign: 'center',
    color: '#606060',
    borderStyle: 'solid',
    borderColor: '#00000070',
};
class BulkUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    onDrop = (files) => {
        const data = new FormData();
        data.append('file', files[0]);
        const request = new Request(`${config.API_URL}import/${this.props.entityType}`, {
            method: 'POST',
            credentials: 'same-origin',
            body: data,
            headers: buildHeaders({ includeCredentials: true }),
        });
        fetch(request)
            .then((response) => {
                console.log(response);
                if (response.status < 200 || response.status >= 300) {
                    this.props.notifyFailure();
                } else {
                    this.props.notifySuccess();
                    // TODO: once json response is added to bulk upload, process here if needed
                    /*
                    response.json().then((data) => {
                        // do something
                    });
                    */
                }
            })
            .catch(this.props.notifyFailure);
    };

    render() {
        return (
            <div>
                {<SsgAppBarMobile title={`Upload Bulk ${this.props.entityType} File`} />}
                <Card style={{ marginBottom: '.5em' }}>
                    <CardHeader title={`Upload Bulk ${this.props.entityType} File`} />
                    <div style={{ padding: '15px' }}>
                        <Dropzone multiple={false} onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} style={dropzoneStyle}>
                                        <input {...getInputProps({ 'aria-label': 'Upload File' })} />
                                        <p>Drag Import File Here</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </Card>
            </div>
        );
    }
}

BulkUpload.defaultProps = {
    entityType: null,
};

const enhance = compose(
    connect(null, {
        notifyFailure: () => enqueueSnackbarAction({ message: 'Upload failed', options: { variant: 'error' } }),
        notifySuccess: () => enqueueSnackbarAction({ message: 'Upload succeeded', options: { variant: 'success' } }),
    }),
);

export default enhance(BulkUpload);
