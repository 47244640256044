import { useContext, useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import casetivityViewContext from 'util/casetivityViewContext';
import useEntities from 'util/hooks/useEntities';
import { setupGenericContext } from '../setupGenericContext';
import useValueSets from 'util/hooks/useValueSets';
import { useIntl } from 'react-intl';

const useEvalContext = (overrideEntities?: any) => {
    const viewConfig = useViewConfig();
    const entities = useEntities() || overrideEntities;
    const viewContext = useContext(casetivityViewContext);
    const valueSets = useValueSets();
    const intl = useIntl();
    const context = useMemo(
        () =>
            setupGenericContext({
                intl,
                viewConfig,
                entities,
                valueSets,
                viewContext,
            }),
        [viewConfig, entities, valueSets, viewContext, intl],
    );
    return context;
};
export default useEvalContext;
