import React, { FunctionComponent, useMemo, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import uniq from 'lodash/uniq';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { loadValueSets as loadValueSetsAction } from 'valueSets/actions';
import { allowsEdit, getAccessLevelForEntity, getValueSetCodesRequiredForEntity } from '../utils/viewConfigUtils';
import prefetchLists from '../form/prefetchLists';
import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import { createVisibilityVSCodeLiteralsSelector } from '../form/valuesetCodeExpressionLiteralsSelector';
import DeferredSpinner from 'components/DeferredSpinner';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';
import { Provider } from '../form/refreshContext';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import formTypeContext from '../form/formTypeContext';
import Title from '../title/Title';
import { RootState } from 'reducers/rootReducer';
import useViewConfig from 'util/hooks/useViewConfig';
import ShowForm from './ShowForm2';
import { diff } from 'jsondiffpatch';
import { ShowActions } from '../viewActions/ActionsWithOverrides';
import { useMinimalRecord } from '../genericEdit/useMinimalRecord';
import withRestrictions from '../utils/withRestrictions';
import { useOverrideTitle } from '../form/hooks/configurations/overrideTitle';
import useTabToPrefetch from '../form/hooks/useTabToPrefetch';

export interface ShowProps {
    id: string;
    viewName: string;
    createMobileAppBar?: boolean;
    resource: string;
    formId?: string;
    actions?: any;
    toolbar?: any;
    renderTitleElement?: (props: { titleElem: JSX.Element }) => JSX.Element;
    embeddedInFormId?: string;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    disallowClickAwayNavigation?: boolean;
}
const Show: FunctionComponent<ShowProps> = (props) => {
    const {
        id,
        viewName,
        resource,
        toolbar,
        createMobileAppBar,
        actions,
        formId,
        renderTitleElement,
        embeddedInFormId,
        disallowClickAwayNavigation,
    } = props;
    const getValueSetCodeLiterals = useMemo(createVisibilityVSCodeLiteralsSelector, []);
    const valueSetCodeLiterals = useSelector((state: RootState) => getValueSetCodeLiterals(state, { viewName }));
    const tabToPrefetch = useTabToPrefetch({ id, resource, viewName });
    const viewConfig = useViewConfig();
    const officialViewConfig = useViewConfig(false);
    const accessLevel = getAccessLevelForEntity(viewConfig, resource);
    const hasEdit = allowsEdit(accessLevel);
    const dispatch = useDispatch();
    const updateData = useCallback(() => {
        const valueSetCodes = uniq([
            ...valueSetCodeLiterals,
            ...getValueSetCodesRequiredForEntity(viewConfig, viewName),
        ]).map((valueset) => ({ valueSet: valueset }));
        if (valueSetCodes.length > 0) {
            dispatch(loadValueSetsAction(valueSetCodes));
        }
        dispatch(
            crudGetOneAction({
                monitorRequest: true,
                resource,
                id,
                view: viewName,
                override:
                    officialViewConfig !== viewConfig
                        ? {
                              patchViewConfig: diff(officialViewConfig, viewConfig),
                          }
                        : undefined,
                cb: (responseId, responseData) => {
                    if (`${id}` !== `${responseId}`) {
                        // redirect if id is different (due to merge)
                        dispatch(pushAction(`/${resource}/${responseId}/show`));
                    }
                },
            }),
        );
    }, [viewName, viewConfig, valueSetCodeLiterals, dispatch, officialViewConfig, id, resource]);

    const lastId = useRef(id);
    useEffect(() => {
        updateData();
        prefetchLists(viewConfig, viewName, id, (args) => dispatch(crudGetListAction(args)), tabToPrefetch);
    }, []); // eslint-disable-line
    useEffect(() => {
        if (id && lastId.current && id !== lastId.current) {
            updateData();
            lastId.current = id;
        }
    }, [id, updateData]);

    const refresh = useCallback(
        (event?: Event) => {
            if (event) {
                event.stopPropagation();
            }
            updateData();
            prefetchLists(viewConfig, viewName, id, (args) => dispatch(crudGetListAction(args)));
        },
        [viewConfig, viewName, id, dispatch, updateData],
    );
    const clonedActions = useMemo(() => {
        return actions ? (
            React.cloneElement(actions, {
                hasDefaultEdit: hasEdit,
                hasEdit,
                viewName,
                id,
            })
        ) : (
            <ShowActions hasEdit={hasEdit} viewName={viewName} id={id} hasDefaultEdit={hasEdit} />
        );
    }, [viewName, id, hasEdit, actions]);
    const { dataLoaded } = useMinimalRecord(resource, id);
    const { element: overrideTitleElement } = useOverrideTitle(viewName);

    const renderSuccess = useCallback(
        () =>
            dataLoaded ? (
                <ShowForm
                    disallowClickAwayNavigation={disallowClickAwayNavigation}
                    formId={formId}
                    viewName={viewName}
                    embeddedInFormId={embeddedInFormId}
                    id={id}
                    actions={clonedActions}
                    resource={resource}
                    toolbar={toolbar}
                    referenceFieldsShouldFetchInitialData
                    createMobileAppBar={createMobileAppBar}
                    renderTitleElement={renderTitleElement}
                    evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                />
            ) : (
                <DeferredSpinner />
            ),
        [
            dataLoaded,
            toolbar,
            createMobileAppBar,
            clonedActions,
            formId,
            embeddedInFormId,
            viewName,
            id,
            resource,
            renderTitleElement,
            props.evaluatedAdhocSPELVariables,
            disallowClickAwayNavigation,
        ],
    );
    return (
        <Provider value={refresh}>
            <formTypeContext.Provider value="SHOW">
                {createMobileAppBar ? (
                    <SsgAppBarMobile
                        title={overrideTitleElement ?? <Title component="span" resource={resource} id={id} />}
                    />
                ) : null}
                <FormDisplayStatus
                    id={id}
                    resource={resource}
                    showSuccessOffline={dataLoaded}
                    renderSuccess={renderSuccess}
                    refresh={refresh}
                />
            </formTypeContext.Provider>
        </Provider>
    );
};

const ShowWrapper = withRestrictions(Show);
export default ShowWrapper;
