import React, { Suspense } from 'react';
const PrintTemplateTester = React.lazy(() => import('./'));
const LazyPrintTemplateTester = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <PrintTemplateTester />
        </Suspense>
    );
};
export default LazyPrintTemplateTester;
