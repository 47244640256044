import { EXPRESSIONTEMPLATES_LOCALSTORAGE_KEY } from './constants';
import { TemplateExpressionsGenerated } from './TemplateExpressionsGenerated';
import { fromPredicate } from 'fp-ts/lib/Option';
import { getStorage } from 'storage/storage';

const initialize = (): TemplateExpressionsGenerated => {
    return fromPredicate<string>(Boolean)(getStorage().getItem(EXPRESSIONTEMPLATES_LOCALSTORAGE_KEY))
        .map((serializedTemplateExpressions) => JSON.parse(serializedTemplateExpressions))
        .getOrElse({});
};
export default initialize;
