import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { actionButtonExpressionsGenerated } from './actions';
import { ActionButtonExps } from './ActionButtonExps';

export default (previousState: ActionButtonExps = {}, action: RootAction): ActionButtonExps => {
    switch (action.type) {
        case getType(actionButtonExpressionsGenerated):
            return action.payload;
        default:
            return previousState;
    }
};
