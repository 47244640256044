import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { loadValueSets } from 'valueSets/actions';
import useViewConfig from 'util/hooks/useViewConfig';
import getEntityExpansionsForDepth from '../util/getEntityExpansionsForDepth';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';

const useFetchDataForReachableViewsOffline = (entityViewName: string, id?: string) => {
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        const { valueSets, expansions } = getEntityExpansionsForDepth(viewConfig)(entityViewName, 2);
        dispatch(
            loadValueSets(
                valueSets.map((vs) => ({
                    valueSet: vs,
                })),
            ),
        );
        if (id) {
            dispatch(
                crudGetOne({
                    id,
                    resource: viewConfig.views[entityViewName].entity,
                    appendExpansions: expansions,
                    view: -1,
                }),
            );
        }
    }, [viewConfig, entityViewName, dispatch, id]);

    return fetchData;
};

export default useFetchDataForReachableViewsOffline;
