import * as publicGlobalAlerts from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

const getPublicGlobalAlertsFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(publicGlobalAlerts.getPublicGlobalAlerts)),
        flatMap(
            (action): Observable<RootAction> =>
                services.getPublicGlobalAlerts().pipe(
                    map(resp => publicGlobalAlerts.getPublicGlobalAlertsSuccess(resp)),
                    catchError(e => of(publicGlobalAlerts.getPublicGlobalAlertsFailure(e))),
                ),
        ),
    );

export default getPublicGlobalAlertsFlow;
