import React from 'react';
import { ErrorsCbs } from 'sideEffect/crud/errorTypes';

export interface AttemptRequestPropsInternal<T = undefined> {
    type: 'internal';
    renderer: (actionContext: {
        attemptAction: (args: {
            onSuccess?: () => void;
            onErrorsCbs?: ErrorsCbs;
            lazyRequest: () => Promise<any>;
        }) => void;
    }) => (state: AttemptRequestState<T>) => JSX.Element;
}
type AttemptRequestProps<T = undefined> =
    | {
          type: 'external';
          renderer: (actionContext: { attemptAction: () => void }) => (state: AttemptRequestState<T>) => JSX.Element;
          onSuccess?: () => void;
          onErrorsCbs?: ErrorsCbs;
          lazyRequest: () => Promise<any>;
          requestOnMount?: boolean;
      }
    | AttemptRequestPropsInternal<T>;
export type AttemptRequestState<T = undefined> =
    | {
          _tag: 'unsubmitted';
      }
    | {
          _tag: 'pending';
      }
    | {
          _tag: 'success';
          data: T;
      }
    | {
          _tag: 'failure';
          message: string;
          body?: {};
          status?: number;
      };

class AttemptRequest<T = undefined> extends React.Component<AttemptRequestProps<T>, AttemptRequestState<T>> {
    constructor(props: AttemptRequestProps<T>) {
        super(props);
        this.state = {
            _tag: 'unsubmitted',
        };
        this.makeRequest = this.makeRequest.bind(this);
    }
    componentDidMount() {
        if (this.props.type === 'internal') {
        } else {
            const { requestOnMount } = this.props;
            if (requestOnMount) {
                this.makeRequest();
            }
        }
    }
    makeRequest(args: { onSuccess?: () => void; onErrorsCbs?: ErrorsCbs; lazyRequest: () => Promise<any> }): void;
    makeRequest(): void;
    makeRequest(args?: { onSuccess?: () => void; onErrorsCbs?: ErrorsCbs; lazyRequest: () => Promise<any> }) {
        const lazyRequest = this.props.type === 'internal' ? args.lazyRequest : this.props.lazyRequest;
        const onSuccess = this.props.type === 'internal' ? args.onSuccess : this.props.onSuccess;
        const onErrorsCbs = this.props.type === 'internal' ? args.onErrorsCbs : this.props.onErrorsCbs;

        this.setState({ _tag: 'pending' }, () =>
            lazyRequest()
                .then(async (response) => {
                    if (response && response.status >= 200 && response.status < 300) {
                        let data: T | undefined = undefined;
                        try {
                            data = await response.json();
                        } catch (e) {
                            // this is acceptable since some requests have no data
                            console.error('Failed to parse response body');
                            console.error(data);
                        }
                        this.setState({ _tag: 'success', data }, () => {
                            if (onSuccess) {
                                onSuccess();
                            }
                        });
                    } else {
                        const responseError: any = new Error(response.statusText);
                        let body: {} | undefined = undefined;
                        try {
                            body = await response.json();
                            responseError.body = body;
                        } catch {}
                        if (response.status && typeof response.status === 'number') {
                            responseError.status = response.status;
                        }
                        throw responseError;
                    }
                })
                .catch((e) => {
                    this.setState({ _tag: 'failure', message: e.message, status: e.status, body: e.body }, () => {
                        if (onErrorsCbs) {
                            const forAllErrorsCb = onErrorsCbs['*'];
                            if (forAllErrorsCb) {
                                forAllErrorsCb();
                            }
                            const maybeErrorCb = onErrorsCbs[e.status];
                            if (maybeErrorCb) {
                                maybeErrorCb();
                            }
                        }
                    });
                }),
        );
    }
    render() {
        return this.props.renderer({ attemptAction: this.makeRequest })(this.state);
    }
}

export default AttemptRequest;
