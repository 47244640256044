import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import { offlineTaskExpirationDatesKeyVal } from 'IndexedDB/offlineTaskExpirationDates';
import { offlineTaskToProfileIdbKeyVal } from 'IndexedDB/offlineTaskToProfile';
import { offlineTaskToEntityKeyVal } from 'IndexedDB/offlineTaskToEntityKeyMap';
import { useEffect, useState } from 'react';
import { getExpiredOfflineDataSubscribedComponentsRegistry } from 'offline_app/offline_stateful_tasks/ExpiredOfflineDataSubscribedComponentsRegistry';
/*
    i.e. get all unencrypted data, so we can look up the information we need when we are online, without decrypting using user PIN.
*/
type NonsensitiveOfflineTaskData = {
    [taskId: string]: {
        expirationDate: Date;
        userProfileDisplay: string;
        userProfileId: string;
        pendingNestedEntityUpdates: number;
    };
};
const getNonsensitiveOfflineTaskData = async () => {
    const taskIds = await idbKeyval.keys();
    let data: NonsensitiveOfflineTaskData = {};
    for (let i = 0; i < taskIds.length; i++) {
        let taskId = taskIds[i];
        const record: Partial<NonsensitiveOfflineTaskData[string]> = {};
        record.expirationDate = (await offlineTaskExpirationDatesKeyVal.get(taskId)).expires;
        const userProfile = await offlineTaskToProfileIdbKeyVal.get(taskId);
        record.userProfileDisplay = userProfile.display; // could also use userId and look up from viewConfig.
        record.userProfileId = userProfile.userId;
        const offlineEntitySubmits = await offlineTaskToEntityKeyVal.get(taskId);
        record.pendingNestedEntityUpdates = Object.keys(offlineEntitySubmits ?? {}).length;
        data[taskId] = record as NonsensitiveOfflineTaskData[string];
    }
    return data;
};
export const useNonsensitiveOfflineTaskData = () => {
    const [state, setState] = useState<NonsensitiveOfflineTaskData>(null);
    useEffect(() => {
        const getAndSet = async () => {
            let data = await getNonsensitiveOfflineTaskData();
            setState(data);
        };
        getAndSet();
        getExpiredOfflineDataSubscribedComponentsRegistry().registerCallback(getAndSet);
        return () => {
            getExpiredOfflineDataSubscribedComponentsRegistry().unregisterCallback(getAndSet);
        };
    }, []);
    return state;
};
