import { getDataTypeForFieldExpr, isFieldViewField } from '../../utils/viewConfigUtils';
import {
    REFMANY,
    REFMANY_JOIN,
    REFMANYMANY,
    VALUESET_MANY,
    REFONE,
    REFONE_JOIN,
    VALUESET,
} from '../../utils/fieldDataTypes';
import ViewConfig from '../../../../reducers/ViewConfigType';

/* Utilities */

/*
    Filter fields to those:
    1. That are present in the view, AND
    2. That (if they are references), are id lists (end with *Ids)
*/
const stripFieldsForMerge = (values: (number | string)[], viewName: string, viewConfig: ViewConfig) => {
    const viewFields = viewConfig.views[viewName].fields;
    return Object.assign(
        {},
        ...Object.entries(values)
            .filter(([key, val]) => {
                const vf = viewFields[key];
                if (vf && isFieldViewField(vf)) {
                    const dt = getDataTypeForFieldExpr(viewConfig, vf.entity, key);
                    return (
                        dt !== REFMANY &&
                        dt !== REFMANY_JOIN &&
                        dt !== REFMANYMANY &&
                        dt !== VALUESET_MANY &&
                        // we want to send the references, not the actual expansions
                        dt !== REFONE &&
                        dt !== REFONE_JOIN &&
                        dt !== VALUESET
                    );
                } else {
                    return (
                        (key.endsWith('Ids') && viewFields[key.slice(0, key.lastIndexOf('Ids'))]) ||
                        (key.endsWith('Id') && viewFields[key.slice(0, key.lastIndexOf('Id'))])
                    );
                }
            })
            .map(([key, val]) => ({ [key]: val })),
    );
};

export default stripFieldsForMerge;
