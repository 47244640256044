import { EntityBase, GenericCrudService, GenericAjaxResponse, GenericCrudArgs } from 'sideEffect/services';
import { FetchAction } from 'actions/rootAction';
import { concat, of, Observable, empty } from 'rxjs';
import { map, catchError, tap, flatMap } from 'rxjs/operators';
import { fetchError, fetchEnd } from 'actions/aor/fetchActions';
import { HttpError } from '../../HttpError';
import ViewConfig from 'reducers/ViewConfigType';
import {
    AllFailureActionCreators,
    AllSuccessActionCreators,
    AllInitialActions,
    AllFailureActions,
    RootCrudAction,
    CreateUpdateSuccessActionCreators,
    GetSuccessActionCreators,
} from '../../../actionTypes';
import { ErrorsCbs } from '../../../errorTypes';

import { isArray } from 'util';
import { CreateUpdateFlowContext } from './createUpdate';
import { GetFlowContext } from './getOne';
import { processResponse } from '../../normalizeEntityResponse';
import { stripCallbacksFromPayload } from '../../stripCallbacks';
import { AjaxError } from 'rxjs/ajax';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import set from 'lodash/set';
import deepExtend from 'deep-extend';
import { getExpensiveCalcsNotInUrlExpansion, RecursiveEntity } from '../../getExpensiveCalcsNotExpanded';

export interface FlowOptions<D> {
    service: GenericCrudService<D>;
    failureAction: AllFailureActionCreators;
    successAction: AllSuccessActionCreators;
    errorsCbs?: ErrorsCbs;
    successCb?: (...args: any[]) => void;
}
export interface FlowContext {
    initialRequestPayload: AllInitialActions['payload'];
    resource: string;
    viewConfig: ViewConfig;
}

export type crudFlow = <D>(
    requestArgs: GenericCrudArgs<D>,
    params: FlowOptions<D>,
    context: FlowContext,
    triggerFetchStart?: boolean,
) => // ) => Observable<RootAction>;
Observable<RootCrudAction | FetchAction>;

// utilities below
export const getResponseAndThrowErrorForNon200 = map(<R>(r: GenericAjaxResponse): R => {
    const { status, response } = r;
    if (status < 200 || status >= 300) {
        throw new HttpError(response.message, status);
    } else {
        return response || null;
    }
});

export const isEntityBase = (response: any): response is EntityBase =>
    response && !isArray(response) && response.id && response.entityType;

type cb = <T extends EntityBase>(id?: string, data?: T) => void;
export const callSuccessCb = (cb?: cb) =>
    tap(<R extends EntityBase | EntityBase[] | null>(response?: R) => {
        if (cb) {
            if (isEntityBase(response)) {
                cb(response.id, response);
            } else {
                cb();
            }
        }
    });

export type DontOverwrite = {
    [entityType: string]: {
        [id: string]: {
            [field: string]: true;
        };
    };
};
export const getDontOverwrite = (
    viewConfig: ViewConfig,
    r: EntityBase,
    entities: {},
    expensiveCalcFieldsNotExpandedInUrl?: string[],
): DontOverwrite | undefined => {
    return r && expensiveCalcFieldsNotExpandedInUrl && expensiveCalcFieldsNotExpandedInUrl.length > 0
        ? expensiveCalcFieldsNotExpandedInUrl.reduce((prev, curr) => {
              if (curr.includes('.')) {
                  const recordDataIsOn = traverseGetData(viewConfig, curr.slice(0, curr.lastIndexOf('.')), r, entities);
                  if (recordDataIsOn.isSome()) {
                      const { entityType, id } = recordDataIsOn.value;
                      set(prev, `${entityType}.${id}.${curr.slice(curr.lastIndexOf('.') + 1)}`, true);
                  }
              } else {
                  const { entityType, id } = r;
                  set(prev, `${entityType}.${id}.${curr}`, true);
              }
              return prev;
          }, {})
        : undefined;
};
export const getDontOverwriteList = (
    viewConfig: ViewConfig,
    rs: EntityBase[],
    entities: {},
    expensiveCalcFieldsNotExpandedInUrl?: string[],
): DontOverwrite | undefined => {
    if (!expensiveCalcFieldsNotExpandedInUrl || expensiveCalcFieldsNotExpandedInUrl.length === 0) {
        return undefined;
    }
    return rs.reduce((prev, r) => {
        const dontOverwrite = getDontOverwrite(viewConfig, r, entities, expensiveCalcFieldsNotExpandedInUrl);
        if (dontOverwrite) {
            return deepExtend(prev, dontOverwrite);
        }
        return prev;
    }, {} as DontOverwrite);
};
export function flatMapResponseEntityToSuccessActions(
    successAction: CreateUpdateSuccessActionCreators,
    context: CreateUpdateFlowContext | GetFlowContext,
);
export function flatMapResponseEntityToSuccessActions(successAction: GetSuccessActionCreators, context: GetFlowContext);
export function flatMapResponseEntityToSuccessActions(
    successAction: any,
    context: CreateUpdateFlowContext | GetFlowContext,
) {
    return flatMap(<T extends EntityBase>(r: T) => {
        const processedResponse = r && processResponse(context.resource, context.viewConfig, r);
        // if we are receiving a record coming back with a different id than requested
        // it was merged, and has a new id.
        // create a link to the new record in our store.
        const maybeRequestedId = (context as GetFlowContext).initialRequestPayload?.id;
        if (maybeRequestedId && processedResponse.result !== maybeRequestedId) {
            processedResponse.entities = {
                ...processedResponse.entities,
                [context.resource]: {
                    ...processedResponse.entities[context.resource],
                    [maybeRequestedId]: processedResponse.entities[context.resource][processedResponse.result],
                },
            };
        }
        const dontOverwrite = getDontOverwrite(
            context.viewConfig,
            r,
            processedResponse.entities,
            getExpensiveCalcsNotInUrlExpansion(context.restUrl, context.viewConfig, r as unknown as RecursiveEntity),
        );
        return concat(
            of(
                successAction(
                    processedResponse,
                    stripCallbacksFromPayload(context.initialRequestPayload),
                    dontOverwrite,
                ),
            ),
            of(fetchEnd()),
        );
    });
}

const errorConstructorNamesToIgnoreAsFailures = ['SubmissionError'];

export const handleErrors = <E extends HttpError | AjaxError, A extends AllFailureActions>(
    errorsCbs: ErrorsCbs,
    createFailureAction: (err: E) => A,
) =>
    catchError<RootCrudAction | FetchAction, Observable<RootCrudAction | FetchAction>>((err: E) => {
        /* Ignore everything in this case? */
        if (errorConstructorNamesToIgnoreAsFailures.indexOf(err.name) !== -1) {
            return empty();
        }
        const forAllErrorsCb = errorsCbs['*'];
        if (forAllErrorsCb) {
            forAllErrorsCb(err as AjaxError);
        }
        const maybeErrorCb = errorsCbs[err.status];
        if (maybeErrorCb) {
            maybeErrorCb();
            /* Originally, we skipped the failureAction dispatch. Not sure why though, so added back. */
        }
        return concat(of(createFailureAction(err)), of(fetchError()));
    });
