import { View } from 'reducers/ViewConfigType';
/*
    A view needs manual migration (meaning, the drag-and-drop doesn't work) if:
    1. Any fields in a row add up to more than 12 columns.
*/
const anyFieldssetSpansMoreThan12Columns = (fields: View['fields'] | View['tabs'][0]['fields']) => {
    let colsPerRow: {
        [row: number]: number;
    } = {};
    Object.entries(fields).forEach(([key, field]) => {
        if (typeof field.row === 'number') {
            if (typeof colsPerRow[field.row] === 'number') {
                colsPerRow[field.row] += field.span || 4;
            } else {
                colsPerRow[field.row] = field.span || 4;
            }
        }
    });
    return Object.entries(colsPerRow).some(([row, count]) => {
        return count > 12;
    });
};
const needsManualMigration = (view: Partial<View>) => {
    if (view.viewType !== 'CREATE' && view.viewType !== 'EDIT' && view.viewType !== 'SHOW') {
        return false;
    }
    return Boolean(
        (view.fields && anyFieldssetSpansMoreThan12Columns(view.fields)) ||
            (view.tabs && Object.values(view.tabs).some((t) => anyFieldssetSpansMoreThan12Columns(t.fields))),
    );
};

export default needsManualMigration;
