import { useMemo } from 'react';
import getConfiguredValidations from 'fieldFactory/translation/fromEntity/getConfiguredValidation';
import useViewConfig from 'util/hooks/useViewConfig';
import { maxLength, required } from 'fieldFactory/util/validate';

/**
 * If the user doesn't have access to CustomReport in the viewConfig, we should have a reasonable fallback.
 */
const defaultValidations = {
    name: (value: unknown) => required(value) || maxLength(75)(value),
    description: (value: unknown) => maxLength(200)(value),
};
const useCustomReportFieldError = (field: string, value: unknown) => {
    const viewConfig = useViewConfig();
    const entityNameValidations = useMemo(
        () => getConfiguredValidations(viewConfig, 'CustomReport', field, 'TEXTBOX'),
        [viewConfig, field],
    );
    return useMemo(() => {
        if (entityNameValidations.length === 0) {
            // no validation could be found. Let's hardcode some defaults
            return defaultValidations[field]?.(value) || '';
        }
        return entityNameValidations
            .map((validation) => validation(value, { [field]: value }, {}))
            .filter(Boolean)
            .join(', ');
    }, [field, entityNameValidations, value]);
};

export default useCustomReportFieldError;
