import { createSelector } from 'reselect';
import { FormFieldConfigs, FormFieldUnion } from '../../../../fieldFactory/translation/fromFlowable/types/index';
import { createGetInitialValues, createGetStartFormInitialValues } from './getInitialValues';
import { RootState } from '../../../../reducers/rootReducer';
import { TaskForm } from '../../../../reducers/taskFormType';
import createFormContext from 'components/generics/form/EntityFormContext/util/createFormContext';
import { createGetEntities, createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import createDeepEqlSelector from 'components/generics/form/EntityFormContext/util/createDeepEqlSelector';
import { fromNullable } from 'fp-ts/lib/Option';
import {
    getExpressionsFromFields,
    getConceptsAvailableExpressionsFromFields,
    getOptionAvailabilityExpressionsFromFields,
    getFormValues,
    getLiveFormInitial,
    getInjectedVisibilityTestExpressions,
    getValueset1Fields,
    wrapEachValueInArray,
} from 'bpm/form-context-utils';
import combiner, {
    getNullIfHidden,
    getNullIfDisabled,
    getRefManyFilterExpressions,
    getTableExpressions,
    getUseBackingValuesRegardlessOfDisplayStatus,
    getFilteredRef1FilterExpressions,
} from 'bpm/form-context-utils/selectorCombiner';
import defaultFormContext from 'bpm/form-context-utils/defaultFormContext';
import { FormContextEvaluator, AvailableOptionsExpressions } from 'expressions/CachingEvaluator/FormContextEvaluator';
import fromEntries from 'util/fromentries';

type FCPProps =
    | {
          contextType: 'existing-task-form';
          taskId: string;
          relatedEntityResource?: string;
          relatedEntityId?: string;
          overrideFormDefinition?: TaskForm;
      }
    | {
          contextType: 'start-form';
          formDefinition: TaskForm;
          formId?: string;
      };

const getTaskFormFields = (state: RootState, props: FCPProps) => {
    return props.contextType === 'start-form'
        ? props.formDefinition.fields
        : props.overrideFormDefinition
        ? props.overrideFormDefinition.fields
        : fromNullable(state.taskForms[props.taskId])
              .mapNullable((t) => t.fields)
              .getOrElse(undefined);
};

const createGetTaskFormExpressions = (expType: keyof FormFieldConfigs) =>
    createSelector(getTaskFormFields, getExpressionsFromFields(expType));

const createGetTaskFormConceptsAvailableExpressions = () =>
    createSelector(getTaskFormFields, getConceptsAvailableExpressionsFromFields);

const createGetTaskFormOptionAvailabilityExpressions = () =>
    createSelector(getTaskFormFields, getOptionAvailabilityExpressionsFromFields);

const createGetVariableExpressions = () =>
    createSelector(getTaskFormFields, (fields) =>
        fromEntries(
            (fields ?? [])
                .filter(
                    (f) =>
                        f.type === 'expression' && f.value && typeof f.value === 'string' && f.value.startsWith('$=['),
                )
                .map((f) => [f.id, f.value.slice(3, -1)]),
        ),
    );

const getFormContextEvaluatorSelector = () => {
    return createSelector(
        getTaskFormFields,
        createGetTaskFormExpressions('editable'),
        createGetTaskFormExpressions('visibility'),
        createGetTaskFormConceptsAvailableExpressions(),
        createGetTaskFormOptionAvailabilityExpressions(),
        getInjectedVisibilityTestExpressions,
        (state: RootState) => state.viewConfig,
        createGetVariableExpressions(),
        (
            fields: FormFieldUnion[] = [],
            editabilityExpressions: {
                [formField: string]: string;
            },
            visibilityExpressions: {
                [formField: string]: string;
            },
            valueset1AvailableConceptsExpressions: {
                [source: string]: string;
            },
            dropdownAvailableOptionsExpressions: AvailableOptionsExpressions,
            injectedVisibilityTestExpression: {} | undefined,
            viewConfig,
            variables,
        ) => {
            const isWizardForm = fields.some((f) => f.type === 'wizard-control');
            const useBackingValuesRegardlessOfDisplayStatus = getUseBackingValuesRegardlessOfDisplayStatus(
                fields,
                isWizardForm,
            );
            const Evaluator = new FormContextEvaluator({
                basedOnEntityOptions: null,
                evaluationFactors: {
                    variables,
                    fieldWidgets: (fields || [])
                        .map((f) => f.id)
                        .reduce((prev, curr) => {
                            prev[curr] = [curr];
                            return prev;
                        }, {}),
                    dropdownAvailableOptionsExpressions,
                    editabilityExpressions: wrapEachValueInArray(editabilityExpressions),
                    visibilityExpressions: wrapEachValueInArray({
                        ...visibilityExpressions,
                        ...(injectedVisibilityTestExpression || {}),
                    }),
                    reference1EntityFilterExpressions: getFilteredRef1FilterExpressions(fields || []),
                    referenceManyEntityFilterExpressions: getRefManyFilterExpressions(fields || [], viewConfig),
                    valueset1Fields: getValueset1Fields(fields || []),
                    valueset1AvailableConceptsExpressions,
                    tableExpressions: getTableExpressions(fields || []),
                    useBackingValuesRegardlessOfDisplayStatus,
                    nullWhenHidden: getNullIfHidden(fields || []),
                    nullWhenDisabled: getNullIfDisabled(fields || []),
                },
                options: { dateFormat: viewConfig && viewConfig.application && viewConfig.application.dateFormat },
                viewConfig,
            });
            return Evaluator;
        },
    );
};

export const createExtraTaskContextSelector = () =>
    createSelector(
        (state: RootState, taskId: string) => {
            const task = state.bpm.tasks.byId[taskId];
            if (!task) {
                return null;
            }
            return Boolean(task.endDate);
        },
        (isClosed) => {
            if (isClosed === null) {
                return undefined;
            }
            return {
                _taskComplete: isClosed,
            };
        },
    );
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    const getExistingTaskInitialValues = createGetInitialValues();
    const getStartFormInitialValues = createGetStartFormInitialValues();
    const formContextEvaluatorSelector = getFormContextEvaluatorSelector();
    const extraTaskContextSelector = createExtraTaskContextSelector();
    const selector = createSelector(
        formContextEvaluatorSelector,
        getTaskFormFields,
        getFormValues,
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) =>
            props.contextType === 'start-form'
                ? getStartFormInitialValues(state, props)
                : // use initial in store first, in case we reinitialized in the component after successful save.
                  getLiveFormInitial(state) || getExistingTaskInitialValues(state, props),
        (state: RootState, props: FCPProps) =>
            props.contextType === 'existing-task-form' ? extraTaskContextSelector(state, props.taskId) : undefined,
        combiner,
    );
    return createDeepEqlSelector(selector);
};

export const { formContext, formContextHoc, FormContextProvider } = createFormContext(
    createFormContextSelector,
    defaultFormContext,
);
