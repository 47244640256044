import React, { useMemo } from 'react';
import { isTableFieldErrorMessage } from 'fieldFactory/input/components/EditableTable/util/utils';
import { FormFieldUnion, TableFormField } from 'fieldFactory/translation/fromFlowable/types';
import trimEnd from 'lodash/trimEnd';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import createErrorTableFromMessages from 'fieldFactory/input/components/EditableTable/util/createErrorTableFromMessages';

export const isTableField = (f: FormFieldUnion): f is TableFormField => {
    return f && f.type === 'table';
};

export const stripAsteriskFromLabel = (label) => trimEnd(`${label}`, ' *');

/**
 * We need to make this recursive.
 */

interface ErrorsListProps {
    formErrors: {
        [field: string]: string[];
    } | null;
    fields: FormFieldUnion[];
    classes?: {
        li?: string;
    };
}
const ErrorsListItems = ({ formErrors, fields, classes = {} }: ErrorsListProps) => {
    const fieldsById = useMemo(() => {
        return (fields || []).reduce((prev, curr) => {
            return {
                ...prev,
                [curr.id]: curr,
            };
        }, {});
    }, [fields]);
    return (
        <React.Fragment>
            {Object.entries(formErrors ?? {}).map(([k, e]: [string, string[]]) => (
                <EvaluateFormattedMessage>
                    {({ evaluateFormattedMessage }) => {
                        // lookup label here and use instead of key below
                        const f = fieldsById[k];

                        let message: string[] | string | JSX.Element = evaluateFormattedMessage(
                            Array.isArray(e) ? e.join(',') : e,
                        );

                        const messageIsTopLevelOnly = e.length === 1 && !isTableFieldErrorMessage(e[0]);
                        if (f && isTableField(f) && !messageIsTopLevelOnly) {
                            const fieldMessages = createErrorTableFromMessages(e.filter(isTableFieldErrorMessage));
                            const fieldMessageEntries = Object.entries(fieldMessages);
                            message = (
                                <div>
                                    {fieldMessageEntries.map(([row, fieldErrors], i) => {
                                        return (
                                            <div key={row}>
                                                {`Row ${(typeof row === 'string' ? parseInt(row, 10) : row) + 1}: `}
                                                <div>
                                                    <ul>
                                                        <ErrorsListItems
                                                            formErrors={fieldErrors}
                                                            fields={f.params.columnObj}
                                                            classes={classes}
                                                        />
                                                    </ul>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        if (f && f.type === 'expression') {
                            return (
                                <li key={k} className={classes.li}>
                                    {message}
                                </li>
                            );
                        }
                        return (
                            <li key={k} className={classes.li}>
                                {f?.name?.trim() ? `${stripAsteriskFromLabel(f.name)}: ` : ''}
                                {message}
                            </li>
                        );
                    }}
                </EvaluateFormattedMessage>
            ))}
        </React.Fragment>
    );
};

export default ErrorsListItems;
