import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { unMarkTaskForOffline } from '../offlineTasks/actions';
import { setCurrentlyWritingToOffline, unsetCurrentlyWritingToOffline } from './actions';

type TaskWritingToOffline = string | null;
const taskCurrentlyWritingToOfflineReducer = (
    state: TaskWritingToOffline = null,
    action: RootAction,
): TaskWritingToOffline => {
    switch (action.type) {
        case getType(setCurrentlyWritingToOffline): {
            return action.payload.taskId;
        }
        case getType(unsetCurrentlyWritingToOffline): {
            return null;
        }
        case getType(unMarkTaskForOffline): {
            if (action.payload.taskId === state) {
                return null;
            }
            return state;
        }
        default:
            return state;
    }
};

export default taskCurrentlyWritingToOfflineReducer;
