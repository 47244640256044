import ProcessInstance from '../../../../../types/processInstance';

export interface ProcessInstanceFromRestProcessInstances {
    id: string;
    businessKey: string;
    processDefinitionId: string;
    tenantId: string;
    started: string;
    ended?: string;
    startedBy: {
        id: string;
        fullName: string;
        groups: any[];
        privileges: any[];
    };
    processDefinitionName: string;
    processDefinitionKey: string;
    processDefinitionCategory: string;
    processDefinitionVersion: number;
    processDeploymentId: string;
    graphicalNotationDefined: boolean;
    startFormDefined: boolean;
    variables?: { name: string; value: any }[];
}

const mapToAppType = ({
    id,
    businessKey,
    started,
    startedBy, // translate this
    ended,
    variables,
}: ProcessInstanceFromRestProcessInstances): ProcessInstance => ({
    id,
    businessKey,
    startUser: startedBy.id,
    startTime: started,
    endTime: ended,
    variables: variables && Object.assign({}, ...variables.map((f) => ({ [f.name]: f.value }))),
});

const mapToApiType = () => new Error('unimplemented');

export { mapToAppType, mapToApiType };
