import ViewConfig from '../reducers/ViewConfigType';
import { WILDCARD_ENTITY } from './createUnionSchema';
import fromEntries from 'util/fromentries';

const isObject = (value) => !!value && value.constructor === Object;
const isArray = (value) => !!value && value.constructor === Array;
// recurse through object tree and remove all @id keys
type EntityWithId = { id: number; entityType: string };

type RefObj = { '@ref': string };
type ExpressionType = RefObj | EntityWithId | string | number | ExpressionArray;
interface ExpressionArray extends Array<ExpressionType> {}

function isEntityWithId(data: ExpressionType): data is EntityWithId {
    return isObject(data) && typeof data === 'object' && data['id'];
}

export const applyAndMarkWildcardEntities = (obj, viewConfig?: ViewConfig) => {
    const ids: {
        [key: string]: {
            id: number;
            entityType: string;
        };
    } = {};

    const stripIds = (toStrip: ExpressionType) => {
        if (isEntityWithId(toStrip)) {
            return fromEntries(
                Object.keys(toStrip)
                    .filter((k) => {
                        if (k === '@id') {
                            if (ids[toStrip[k]]) {
                                throw Error('Multiple @ids with same value');
                            }
                            ids[toStrip[k]] = {
                                id: toStrip.id,
                                entityType: toStrip.entityType,
                            };
                            return false;
                        }
                        return true;
                    })
                    .map((key) => [key, stripIds(toStrip[key])]),
            );
        }
        if (isArray(toStrip) && toStrip instanceof Array) {
            return toStrip.map((o) => stripIds(o));
        }
        return toStrip;
    };

    const stripped = stripIds(obj);

    const replaceRefs = (toReplace: ExpressionType, isWildcardEntity: boolean) => {
        const entity = isEntityWithId(toReplace) ? toReplace.entityType : null;
        if (isEntityWithId(toReplace) && !entity) {
            if (toReplace.id === (toReplace as any).processInstanceId) {
                toReplace = {
                    // reassign to a new object instead of reassigning the old one. No side effects.
                    ...toReplace,
                    entityType: 'ProcessInstance',
                };
            } else if (toReplace.id === (toReplace as any).revision) {
                toReplace = {
                    // reassign to a new object instead of reassigning the old one. No side effects.
                    ...toReplace,
                    entityType: 'RevInfo',
                };
            } else if (toReplace.id && toReplace['schema'] && isWildcardEntity && Object.keys(toReplace).length === 2) {
                // wildcard reference from a GET_MANY fetch. leave it.
                return toReplace;
            } else {
                throw new Error(`Entity ${JSON.stringify(toReplace)} with no entityType.`);
            }
        }
        if (entity && viewConfig && !viewConfig.entities[entity]) {
            throw new Error(`Entity "${JSON.stringify(entity)}"
                from ${JSON.stringify(toReplace)} not found in viewconfig.`);
        }
        const isWildcardRelationship = (key: string) =>
            !viewConfig
                ? false
                : entity && viewConfig.entities[entity].fields[key]
                ? viewConfig.entities[entity].fields[key].relatedEntity === WILDCARD_ENTITY
                : false;
        if (isObject(toReplace)) {
            if (toReplace['@ref']) {
                const ref = ids[toReplace['@ref']];
                if (isWildcardEntity) {
                    return { id: ref.id, schema: ref.entityType };
                }
                return ref.id;
            }

            const unexpandedWildcardRelForKey = (key: string): false | undefined | string =>
                key.endsWith('Id') &&
                isWildcardRelationship(key.slice(0, -2)) &&
                !toReplace[key.slice(0, -2)] &&
                // return the type for convenience, since we need it!
                toReplace[key.slice(0, -2) + 'Type'];

            return Object.assign(
                {},
                ...Object.keys(toReplace)
                    .filter((k) => k !== 'casetivityExtraFields')
                    .map((key) => {
                        const value = replaceRefs(toReplace[key], isWildcardRelationship(key));
                        return {
                            [key]: value,
                            // if the object contains an unexpanded wildcard,
                            // lets insert the { id, schema } reference now.
                            ...(unexpandedWildcardRelForKey(key) &&
                            (typeof value === 'number' || typeof value === 'string')
                                ? {
                                      [key.slice(0, -2)]: {
                                          id: value,
                                          schema: unexpandedWildcardRelForKey(key),
                                      },
                                  }
                                : undefined),
                        };
                    }),
            );
        }
        if (isArray(toReplace) && toReplace instanceof Array) {
            return toReplace.map((o) => replaceRefs(o, isWildcardEntity));
        }
        return toReplace;
    };
    return replaceRefs(stripped, false);
};

// version which just replaces { @refs } objects with the ids.
// when we apply viewConfig, then we replace them with { id: <id>, schema: <entityType> }
// so that we can denormalize.
export const apply = (obj) => applyAndMarkWildcardEntities(obj);
