import React, { Component } from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';
import classnames from 'classnames';
import { GetComponentProps } from 'util/typeUtils';

const styles = createStyles({
    button: {
        position: 'relative',
    },
    iconPaddingStyle: {
        marginRight: '0.5em',
    },
});

interface SaveButtonProps extends Partial<Pick<GetComponentProps<typeof Button>, 'variant'>> {
    className?: string;
    handleSubmitWithRedirect?: (redirect?: string | boolean | (() => void)) => () => void;
    invalid?: boolean;
    label?: string;
    redirect?: string | boolean | (() => void);
    saving?: boolean;
    submitOnEnter?: boolean;
    icon?: JSX.Element | null;
    onClick?: () => void;
    disabled?: boolean;
}
interface SaveButtonComponentProps extends SaveButtonProps, WithStyles<typeof styles> {}

export class SaveButtonComponent extends Component<SaveButtonComponentProps> {
    static defaultProps = {
        handleSubmitWithRedirect: () => () => null,
        icon: <ContentSave />,
    };

    handleClick = (e) => {
        const { handleSubmitWithRedirect, invalid, redirect, saving, onClick } = this.props;

        if (saving) {
            // prevent double submission
            e.preventDefault();
        } else {
            if (invalid) {
                // showNotification('ra.message.invalid_form', 'warning');
            }
            // always submit form explicitly regardless of button type
            if (e) {
                e.preventDefault();
            }
            if (handleSubmitWithRedirect) {
                handleSubmitWithRedirect(redirect)();
            }
        }

        if (typeof onClick === 'function') {
            onClick();
        }
    };

    render() {
        const {
            disabled,
            className,
            classes,
            label = 'Save',
            saving,
            submitOnEnter,
            variant = 'contained',
            icon,
        } = this.props;

        const type = submitOnEnter ? 'submit' : 'button';
        return (
            <Button
                className={classnames(classes.button, className)}
                variant={variant}
                type={type}
                onClick={this.handleClick}
                color={saving ? 'default' : 'primary'}
                disabled={disabled || !!saving}
                endIcon={
                    saving ? (
                        <CircularProgress size={25} thickness={2} className={classes.iconPaddingStyle} />
                    ) : (
                        icon &&
                        React.cloneElement(icon, {
                            className: classes.iconPaddingStyle,
                        })
                    )
                }
            >
                {label}
            </Button>
        );
    }
}

const enhance = compose(withStyles(styles));

const SaveButton: React.SFC<SaveButtonProps> = enhance(SaveButtonComponent);
export default SaveButton;
