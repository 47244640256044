import React from 'react';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import uniqueId from 'lodash/uniqueId';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import CasetivitySelect from 'components/CasetivitySelect';
import useTextFieldUtils from '../hooks/useTextFieldUtils';

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
        },
    });

interface SelectProps extends WithStyles<typeof styles> {
    input: Pick<WrappedFieldInputProps, 'onBlur' | 'value'>;
    meta?: Partial<WrappedFieldMetaProps>;
    label: string;
    source?: string;
    renderLabel?: boolean;
}
const NullableBooleanComponent: React.FC<SelectProps> = ({
    source,
    classes,
    input,
    meta,
    renderLabel = true,
    label,
}) => {
    const {
        InputPropsClasses,
        createInputLabelProps,
        createFormHelperTextProps,
        muiErrorProp,
        helperText,
        selectClass,
    } = useTextFieldUtils(meta);
    const ariaErrormessageRef = React.useRef(uniqueId('nullable-boolean-ErrorMsg'));
    const SelectProps: React.InputHTMLAttributes<HTMLDivElement> = {
        'aria-label': label,
        'aria-invalid': Boolean(meta.error),
        'aria-describedby': muiErrorProp ? ariaErrormessageRef.current : undefined,
    };
    const inputId = `${source}-nullable-boolean`;
    const inputName = `${source}`;

    const { fieldVariant, forceLabelShrink } = React.useContext(themeOverrideContext);
    return (
        <FormControl error={muiErrorProp} variant={fieldVariant} fullWidth={true} /* className={classes.formControl} */>
            {renderLabel && (
                <InputLabel {...createInputLabelProps()} shrink={forceLabelShrink} htmlFor={inputId}>
                    {label}
                </InputLabel>
            )}
            <CasetivitySelect
                label={label}
                value={input.value ? 'true' : input.value === false ? 'false' : 'null'}
                onChange={(e) => {
                    const value = e.target.value;
                    switch (value) {
                        case 'null': {
                            input.onBlur(null);
                            break;
                        }
                        case 'true': {
                            input.onBlur(true);
                            break;
                        }
                        case 'false': {
                            input.onBlur(false);
                            break;
                        }
                    }
                }}
                inputProps={{
                    name: inputName,
                    id: inputId,
                }}
                className={selectClass}
                SelectDisplayProps={SelectProps}
            >
                {({ OptionComponent }) => [
                    <OptionComponent key="null" value="null">
                        None
                    </OptionComponent>,
                    <OptionComponent key="true" className="dropdownMenuItem" value="true">
                        True
                    </OptionComponent>,
                    <OptionComponent key="false" className="dropdownMenuItem" value="false">
                        False
                    </OptionComponent>,
                ]}
            </CasetivitySelect>
            {muiErrorProp && (
                <FormHelperText
                    {...createFormHelperTextProps()}
                    aria-live="assertive"
                    id={SelectProps['aria-describedby']}
                    error={muiErrorProp}
                >
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
const NullableBoolean = withStyles(styles)(NullableBooleanComponent);

export default NullableBoolean;
