import { createCustomAction } from 'typesafe-actions';
import {
    LOAD_VIEW_CONFIG,
    LOAD_VIEW_CONFIG_FAILURE,
    LOAD_VIEW_CONFIG_SUCCESS,
    VIEW_CONFIG_LOADING,
    RELOAD_VIEW_CONFIG_FROM_LOCALSTORAGE,
} from './constants';
import ViewConfig from 'reducers/ViewConfigType';

export const reloadFromLocalstorage = createCustomAction(RELOAD_VIEW_CONFIG_FROM_LOCALSTORAGE, (type) => {
    return () => ({
        type,
    });
});

export const load = createCustomAction(LOAD_VIEW_CONFIG, (type) => {
    return (notifyOnSuccess: boolean = true, redirectTo?: string) => ({
        type,
        notifyOnSuccess,
        redirectTo,
    });
});

export const loading = createCustomAction(VIEW_CONFIG_LOADING, (type) => {
    return () => ({
        type,
    });
});

export const loadSuccess = createCustomAction(LOAD_VIEW_CONFIG_SUCCESS, (type) => {
    return (viewConfig: ViewConfig) => ({
        type,
        payload: {
            viewConfig,
        },
    });
});

export const loadFailure = createCustomAction(LOAD_VIEW_CONFIG_FAILURE, (type) => {
    return (error: Error) => ({
        type,
        error,
    });
});
