import React, { Suspense } from 'react';
const TransformTaskForm = React.lazy(() => import('./TransformTaskForm'));
const LazyTransformForm = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <TransformTaskForm />
        </Suspense>
    );
};
export default LazyTransformForm;
