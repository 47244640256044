import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../src/reducers/rootReducer';

export interface CurrProcSearchProps {
    render: (arg: { currentSearch: string | null }) => JSX.Element | null;
}
const makeMapStateToProps = () => {
    const mapStateToProps = (state: RootState, props: CurrProcSearchProps) => {
        return {
            currentSearch: state.bpm.currentProcessSearch.query || null,
        };
    };
    return mapStateToProps;
};
interface CurrProcSearchComponentProps
    extends CurrProcSearchProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>> {}

class CurrProcSearchComponent extends React.Component<CurrProcSearchComponentProps> {
    render() {
        const { render, currentSearch } = this.props;
        return render({ currentSearch });
    }
}

const CurrProcSearch: React.ComponentType<CurrProcSearchProps> = connect(makeMapStateToProps)(CurrProcSearchComponent);

export default CurrProcSearch;
