import React, { FunctionComponent, useEffect, useCallback } from 'react';
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import { CompileExpression } from 'expressions/Provider/implementations/CompileExpression';
import useViewConfig from 'util/hooks/useViewConfig';
import {
    isFieldViewField,
    isRefManyField,
    isRefOneField,
    isValidEntityFieldExpression,
    isValueSetField,
    isRefManyManyField,
    isValueSetManyField,
    getFieldSourceFromPath,
} from 'components/generics/utils/viewConfigUtils';
import WidgetPicker from './WidgetField';
import ViewConfig, { View, ViewField } from 'reducers/ViewConfigType';
import uniq from 'lodash/uniq';
import { contextFunctions } from 'expressions/Provider/getContexts';
import AutocompleteSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';
import { methodCallsAllowed } from 'fieldFactory/input/components/ValidationExpressionEditor/methodCallsAllowed';
import getImpl from 'expressions/Provider/implementations/getImpl';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
    }),
);

const compileExpression: CompileExpression = getImpl().compileExpression;

export const UpdateMeta: FunctionComponent<{}> = (props) => {
    const methods = useFormContext<ExpressionData>();
    const { setValue, trigger } = methods;
    const expressionText: string = methods.watch('expression');
    useEffect(() => {
        methods.register('fieldsRequired');
        methods.register('compileSuccess');
        methods.register('methodsAndFunctions');
        return () => {
            methods.unregister('fieldsRequired');
            methods.unregister('compileSuccess');
        };
    }, []); // eslint-disable-line
    useEffect(() => {
        if (expressionText) {
            const compiled = compileExpression(expressionText);
            if (compiled.type === 'parse_success') {
                setValue('methodsAndFunctions', compiled.methodsAndFunctions, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                setValue('fieldsRequired', compiled.getExpansions(), {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                setValue('compileSuccess', true as any);
                trigger(['fieldsRequired']);
            } else {
                setValue('methodsAndFunctions', []);
                setValue('fieldsRequired', []);
                setValue('compileSuccess', false as any, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                trigger('fieldsRequired');
            }
        } else {
            setValue('methodsAndFunctions', [], {
                shouldDirty: true,
                shouldValidate: true,
            });
            setValue('fieldsRequired', [], {
                shouldDirty: true,
                shouldValidate: true,
            });
            setValue('compileSuccess', false as any, {
                shouldDirty: true,
                shouldValidate: true,
            });
            trigger('fieldsRequired');
        }
    }, [expressionText, setValue, trigger]);
    return null;
};

export interface ExpressionData {
    expression: string;
    fieldsRequired: string[];
    compileSuccess: boolean;
    methodsAndFunctions: string[];
    widget: string[];
}

interface EditExpressionProps {
    viewName?: string;
    initialValues?: Pick<ExpressionData, 'expression' | 'fieldsRequired' | 'widget'> &
        Partial<Pick<ExpressionData, 'compileSuccess' | 'methodsAndFunctions'>>;
    onSubmit: (data: ExpressionData) => void;
    appendIds?: boolean;
    warn?: (selectedWidgets?: string[]) => React.ReactNode;
    widgetMapsToSourceInsteadOfId?: boolean;
}

const getRealFieldPaths = (viewConfig: ViewConfig) => (fields: View['searchFields']) => {
    return Object.fromEntries(
        Object.entries(fields).flatMap<[string, ViewField]>(([k, v]) => {
            const [key, searchModifier] = k.split('__');
            const getNewKey = (append: string) =>
                searchModifier ? key + append + '__' + searchModifier : key + append;
            if (!isFieldViewField(v)) {
                return [[k, v]];
            }
            if (
                isRefOneField(viewConfig, v.entity, v.field, 'POP_LAST') ||
                isValueSetField(viewConfig, v.entity, v.field, 'POP_LAST')
            ) {
                return [
                    // [k, v],
                    [getNewKey('Id'), v],
                ];
            }
            if (
                isRefManyField(viewConfig, v.entity, v.field, 'POP_LAST') ||
                isRefManyManyField(viewConfig, v.entity, v.field, 'POP_LAST') ||
                isValueSetManyField(viewConfig, v.entity, v.field, 'POP_LAST')
            ) {
                return [
                    // [k, v],
                    [getNewKey('Ids'), v],
                ];
            }
            return [[k, v]];
        }),
    );
};
export const useValidationResolver = (viewName: string, viewConfig: ViewConfig, optional: boolean = false) => {
    return useCallback(
        async (data) => {
            let errors = {};
            if (viewName && data.compileSuccess) {
                const baseEntity = viewConfig.views[viewName].entity;
                const invalidFunctions = uniq(
                    (data.methodsAndFunctions || []).flatMap((_fnnm, i) => {
                        if (contextFunctions[_fnnm] || methodCallsAllowed.includes(_fnnm)) {
                            return [];
                        }
                        return [_fnnm];
                    }),
                );
                if (invalidFunctions.length > 0) {
                    errors['methodsAndFunctions'] = `Functions not found: ${invalidFunctions.join(', ')}`;
                }
                const invalidPaths = uniq(
                    (data.fieldsRequired || []).flatMap((_fieldPath, i) => {
                        const [fieldDataPath, searchModifier] = _fieldPath.split('__');
                        const fieldPath = getFieldSourceFromPath(viewConfig, baseEntity, fieldDataPath);

                        if (!isValidEntityFieldExpression(viewConfig, baseEntity, fieldPath)) {
                            return [_fieldPath];
                        }
                        // handle searchModifier mismatches below
                        const { searchFields: _searchFields } = viewConfig.views[viewName];
                        const searchFields = getRealFieldPaths(viewConfig)(_searchFields ?? {});
                        if (searchModifier) {
                            // look for missing 'field__SEARCHTYPE'
                            if (searchFields && !searchFields[fieldDataPath + '__' + searchModifier]) {
                                // fix alternatives message.
                                const alternatives = Object.keys(searchFields).filter((sk) => sk.startsWith(fieldPath));
                                return [
                                    fieldDataPath +
                                        '__' +
                                        searchModifier +
                                        ` (Did you mean: ${alternatives.join(', ')}?)`,
                                ];
                            }
                            if (!searchFields) {
                                return [fieldDataPath + '__' + searchModifier + '(No searchFields in the view)'];
                            }
                        } else {
                            if (searchFields && Object.keys(searchFields).length > 0) {
                                //look for missing 'field' (no searchType)
                                // -------
                                // we are configuring expressions on search, since that's
                                // the only scenario in which we edit expressions on a view with a 'searchFields' key
                                if (!searchFields[fieldDataPath]) {
                                    const alternatives = Object.keys(searchFields).filter((sk) =>
                                        sk.startsWith(fieldPath),
                                    );
                                    if (alternatives.length > 0) {
                                        return [`${_fieldPath} (Did you mean: ${alternatives.join(', ')}?)`];
                                    }
                                    return [_fieldPath];
                                }
                            }
                        }

                        return [];
                    }),
                );

                if (invalidPaths.length > 0) {
                    errors['fieldsRequired'] = `Paths not found: ${invalidPaths.join(', ')}`;
                }
            }
            if (!data.compileSuccess) {
                if (!data.expression && optional) {
                    return {
                        errors: errors,
                        values: data,
                    };
                }
                errors['fieldsRequired'] = 'Failed to compile expression.';
            }
            return {
                errors: errors,
                values: data,
            };
        },
        [viewName, viewConfig, optional],
    );
};

const EditExpression: FunctionComponent<EditExpressionProps> = (props) => {
    const { appendIds, warn } = props;
    const classes = useStyles(props);
    const { viewName, onSubmit } = props;
    const viewConfig = useViewConfig();

    const resolver = useValidationResolver(viewName, viewConfig);
    const methods = useForm<ExpressionData>({
        resolver,
        defaultValues: props.initialValues,
        mode: 'onChange',
    });
    const currWidgets = methods.watch('widget');
    const { errors } = methods;
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <label>
                    <b>Expression *</b>
                    <Controller
                        rules={{ required: 'Provide an expression' }}
                        rootEntity={viewConfig.views[viewName].entity}
                        as={AutocompleteSpelEditor as any}
                        defaultValue={props.initialValues && props.initialValues['expression']}
                        name="expression"
                        control={methods.control as any}
                        hideDocs
                    />
                </label>
                <ErrorMessage errors={errors} name="expression" />
                <pre className={classes.error}>{errors['methodsAndFunctions']}</pre>
                <pre className={classes.error}>{errors['fieldsRequired']}</pre>
                <WidgetPicker
                    useSourceInsteadOfId={props.widgetMapsToSourceInsteadOfId}
                    append={appendIds}
                    view={viewConfig.views[viewName]}
                    defaultValue={props.initialValues && props.initialValues['widget']}
                />
                {warn?.(currWidgets) ?? null}
                <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                    Save
                </Button>
            </form>
            <UpdateMeta />
        </FormProvider>
    );
};
export default EditExpression;
