import React, { useEffect } from 'react';
import GlobalAlertsBannerComponent from 'global-alerts/components/BannerComponent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getPrivateGlobalAlerts } from '../actions';
import { getFetchGlobalAlertsSelector } from 'util/applicationConfig';
import { deserialize } from 'reducers/lists/list/serializeDeserialize';

const PrivateGlobalAlerts = (props) => {
    const dispatch = useDispatch();
    const fetchGlobalAlerts = useSelector(getFetchGlobalAlertsSelector);
    useEffect(() => {
        if (fetchGlobalAlerts) {
            dispatch(getPrivateGlobalAlerts());
        }
    }, [fetchGlobalAlerts, dispatch]);
    const privateAlerts = useSelector((state: RootState) => {
        return state.globalAlerts.privateAlerts;
    });
    return deserialize(privateAlerts).fold(
        null,
        null,
        (err) => null,
        (data) => (data ? <GlobalAlertsBannerComponent globalAlerts={data} /> : null),
    );
};
export default PrivateGlobalAlerts;
