import { useTheme, Button, CardActions, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';
import CodemirrorGroovyEditor from './CodeMirrorGroovyEditor';

type SubmissionState =
    | {
          type: 'initial';
      }
    | {
          type: 'pending';
          script: string;
      }
    | {
          type: 'ajax-error';
          error?: AjaxError;
      }
    | {
          type: 'success';
          response: {
              result: string;
          };
      };
const useSubmit = (processInstanceId: string) => {
    const [state, setState] = useState<SubmissionState>({ type: 'initial' });
    React.useEffect(() => {
        if (state.type === 'pending') {
            const $ajax = services.runScript(processInstanceId, state.script);

            const subscription = $ajax.subscribe(
                (res) => {
                    setState({ type: 'success', response: res.response });
                },
                (error: AjaxError) => {
                    setState({ type: 'ajax-error', error });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [state, processInstanceId]);
    const submitData = React.useCallback(
        (script: string) => {
            setState({ type: 'pending', script });
        },
        [setState],
    );
    return [state, submitData] as [typeof state, typeof submitData];
};

const SubmitAdhocProcessScript = (props: { processInstanceId: string }) => {
    const [script, setScript] = useState('');

    const [state, submit] = useSubmit(props.processInstanceId);
    const theme = useTheme();
    return (
        <div style={{ height: '100%', border: '1px solid black' }}>
            <CodemirrorGroovyEditor initialValue={script} onChange={setScript} />
            <CardActions>
                <Button
                    disabled={state.type === 'pending'}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        submit(script);
                    }}
                >
                    Submit
                    {state.type === 'pending' ? (
                        <>
                            <span>&nbsp;</span>
                            <CircularProgress style={{ height: 15, width: 15 }} />
                        </>
                    ) : null}
                </Button>
            </CardActions>
            {state.type === 'ajax-error' ? (
                <pre style={{ color: theme.palette.error.dark }}>{JSON.stringify(state.error.response, null, 1)}</pre>
            ) : state.type === 'success' ? (
                <pre>{JSON.stringify(state.response.result, null, 1)}</pre>
            ) : null}
        </div>
    );
};
export default SubmitAdhocProcessScript;
