import React from 'react';
import { RenderListArguments } from 'components/generics/genericList/List';
import InlineDatagrid from './Datagrid';
import InlineCreateListField from './TableWithRepeatedCreateViews';

export interface GetRenderMultiCardListConfig {
    viewName?: string;
    entityType?: string;
    id?: string;
    source: string;
    useCreateView?: boolean;
    createViewName?: string;
}

const getRenderInlineList =
    <
        RenderListProps extends {
            resource: string;
            referencedByField?: string;
            data: RenderListArguments['data'];
            onRowSelect?: RenderListArguments['onRowSelect'];
            showCreate?: boolean;
            disabled?: boolean;
            defaultRenderer?: RenderListArguments['defaultRenderer'];
        },
    >(
        config: GetRenderMultiCardListConfig,
    ) =>
    (props: RenderListProps & { renderNoData?: () => JSX.Element }) => {
        if (config.useCreateView) {
            return (
                <>
                    {props?.defaultRenderer?.(props as any)}
                    <InlineCreateListField
                        handleSelectExistingRecord={(id) => props.onRowSelect([props.data[id]], props.data)}
                        referencedByField={props.referencedByField}
                        resource={props.resource}
                        rootEntityType={config.entityType}
                        id={config.id}
                        source={config.source}
                        overrideViewName={config.viewName}
                        showCreate={props.showCreate}
                        disabled={props.disabled}
                        useCreateView={config.useCreateView}
                        createViewName={config.createViewName}
                        renderNoData={props.renderNoData}
                    />
                </>
            );
        }
        return (
            <InlineDatagrid
                handleSelectExistingRecord={(id) => props.onRowSelect([props.data[id]], props.data)}
                referencedByField={props.referencedByField}
                resource={props.resource}
                rootEntityType={config.entityType}
                id={config.id}
                source={config.source}
                overrideViewName={config.viewName}
                showCreate={props.showCreate}
                disabled={props.disabled}
            />
        );
    };
export default getRenderInlineList;
