import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        formControl: {
            margin: theme.spacing(1),
        },
    }),
);

const labels = {
    vis: 'Visibility Expressions',
    action: 'Action Buttons',
    editable: 'Editability Expressions',
    concept: 'Concept Filtering Expressions',
} as const;
export const CheckboxesGroup = <
    InitialState extends { vis: boolean; action: boolean } | { vis: boolean; editable: boolean; concept: boolean },
>({
    initialState,
    render,
}: {
    initialState: InitialState;
    render: (state: InitialState, setDisabled: (disabled: boolean) => void) => JSX.Element;
}) => {
    const classes = useStyles();
    const [state, setState] = React.useState<InitialState>(initialState);
    const [disabled, setDisabled] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div style={{ margin: '1em' }}>
            <Typography variant="h5" component="h2">
                Transfer your work
            </Typography>
            <p>
                Select the attributes you would also like to transfer, and then select the view below you would like to
                transfer your work to.
            </p>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Attributes</FormLabel>
                    <FormGroup>
                        {Object.keys(initialState).map((k) => {
                            return (
                                <FormControlLabel
                                    key={k}
                                    control={
                                        <Checkbox
                                            disabled={disabled}
                                            checked={state[k]}
                                            onChange={handleChange}
                                            name={k}
                                        />
                                    }
                                    label={labels[k]}
                                />
                            );
                        })}
                        {/* <FormHelperText>Select the properties you would like to transfer</FormHelperText> */}
                    </FormGroup>
                </FormControl>
            </div>
            {render(state, setDisabled)}
        </div>
    );
};
