import parseUnevaluatedFormBrackets from 'bpm/task-form-editor/util/parseUnevaluatedFormBrackets';
import React, { useMemo, useRef, useState } from 'react';
import { TaskForm } from '../../../../reducers/taskFormType';
import ParamsForm, { ExpressionsToUsages } from './form/ParamsForm';
import { templateValues } from 'bpm/task-form-editor/util/applyValuesToFormBrackets';
import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import groupBy from 'lodash/groupBy';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

interface ServerTemplatedBracketsControllerProps {
    processId?: string;
    taskForm: TaskForm;
    renderWhenReady: (props: { taskForm: TaskForm }) => JSX.Element;
}
const ServerTemplatedBracketsController: React.FC<ServerTemplatedBracketsControllerProps> = ({
    processId,
    taskForm,
    renderWhenReady,
}) => {
    const [templatedTaskForm, setTemplatedTaskForm] = useState(taskForm);
    const keyRef = useRef(1);
    const key = useMemo(() => {
        return keyRef.current++;
    }, [templatedTaskForm]); // eslint-disable-line
    const {
        params: initialParams,
        fields,
        outcomes,
    } = useMemo(() => {
        return parseUnevaluatedFormBrackets(taskForm);
    }, [taskForm]);
    const { params: templatedFormParams } = useMemo(() => {
        return parseUnevaluatedFormBrackets(templatedTaskForm);
    }, [templatedTaskForm]);

    const expressionsToUsages = useMemo(() => {
        let expressionsToUses: ExpressionsToUsages = {};
        initialParams.forEach((_expression) => {
            const expression = _expression.trim();
            [fields, outcomes].forEach((fieldsOrOutcomes) => {
                Object.entries(fieldsOrOutcomes).forEach(([fieldOrOutcome, pathsObj]) => {
                    Object.entries(pathsObj).forEach(([path, { full, params }]) => {
                        if (params.find((param) => param.trim() === expression)) {
                            if (!expressionsToUses[expression]) {
                                expressionsToUses[expression] = {};
                            }
                            let key = fieldsOrOutcomes === fields ? 'fields' : 'outcomes';
                            if (!expressionsToUses[expression][key]) {
                                expressionsToUses[expression][key] = {};
                            }
                            expressionsToUses[expression][key][fieldOrOutcome + ':' + path] = full;
                        }
                    });
                });
            });
        });
        return expressionsToUses;
    }, [fields, outcomes, initialParams]);

    const fieldsWithDuplicateIds = useMemo(() => {
        const fieldsById = groupBy(taskForm.fields, 'id');
        return Object.entries(fieldsById).filter(([id, fieldsOfId]) => fieldsOfId.length > 1);
    }, [taskForm]);

    let warningEls: JSX.Element[] = [];
    if (fieldsWithDuplicateIds.length > 0) {
        warningEls.push(
            <div key="dupes" style={{ paddingTop: '1em' }}>
                {fieldsWithDuplicateIds.map(([id, fields]) => {
                    return (
                        <Alert severity="error">
                            {fields.length} fields have the duplicate id "{id}"
                        </Alert>
                    );
                })}
            </div>,
        );
    }
    const fieldsMissingIdPostfix = useMemo(() => {
        const types = ['entity-lookup', 'entity-typeahead', 'value-set-dropdown', 'value-set-radiobox'];

        const fieldsById = groupBy(
            taskForm.fields.filter((f) => types.includes(f.type)),
            'id',
        );
        return Object.entries(fieldsById)
            .filter(([id, fieldsOfId]) => fieldsOfId.some((f) => !f.id.endsWith('Id')))
            .flatMap(([id, fields]) => fields.map((f) => [id, f] as [typeof id, typeof f]));
    }, [taskForm]);

    if (fieldsMissingIdPostfix.length > 0) {
        warningEls.push(
            <div key="missingId" style={{ paddingTop: '1em' }}>
                {fieldsMissingIdPostfix.map(([id, field]) => {
                    return (
                        <Alert severity="error">
                            A field of type {field.type} as an id "{id}" when it should probably be "{id}Id"
                        </Alert>
                    );
                })}
            </div>,
        );
    }
    const fieldsMissingIdsPostfix = useMemo(() => {
        const types = [
            'entity-multi-select-chip',
            'multiple-entity-typeahead',
            'list-view',
            'value-set-multi-checkbox',
            'value-set-multi-select',
        ];

        const fieldsById = groupBy(
            taskForm.fields.filter((f) => types.includes(f.type)),
            'id',
        );
        return Object.entries(fieldsById)
            .filter(([id, fieldsOfId]) => fieldsOfId.some((f) => !f.id.endsWith('Ids')))
            .flatMap(([id, fields]) => fields.map((f) => [id, f] as [typeof id, typeof f]));
    }, [taskForm]);

    if (fieldsMissingIdsPostfix.length > 0) {
        warningEls.push(
            <div key="missingIds" style={{ paddingTop: '1em' }}>
                {fieldsMissingIdsPostfix.map(([id, field]) => {
                    return (
                        <Alert severity="error">
                            A field of type {field.type} as an id "{id}" when it should probably be "{id}Ids"
                        </Alert>
                    );
                })}
            </div>,
        );
    }
    let warningEl =
        warningEls.length > 0 ? (
            <div style={{ padding: '1em 0em' }}>
                <Typography variant="h5">Fix the following issues:</Typography>
                {warningEls}
            </div>
        ) : null;
    if (initialParams.length > 0) {
        return (
            <div>
                {warningEl}
                <ParamsForm
                    processId={processId}
                    formKey={taskForm.key}
                    submit={(values) => {
                        let templatedForm = templateValues(taskForm, values);
                        setTemplatedTaskForm(templatedForm as TaskForm);
                    }}
                    expressionsToUsages={expressionsToUsages}
                    params={initialParams.reduce((prev, expression) => {
                        prev[expression] = null;
                        return prev;
                    }, {})}
                />
                {templatedFormParams.length === 0 ? (
                    <WithErrorBoundary key={key}>{renderWhenReady({ taskForm: templatedTaskForm })}</WithErrorBoundary>
                ) : null}
            </div>
        );
    }
    return (
        <div>
            {warningEl}
            <WithErrorBoundary>{renderWhenReady({ taskForm })}</WithErrorBoundary>
        </div>
    );
};
export default ServerTemplatedBracketsController;
