import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { setLocale } from './actions';
import { Locale } from './domain.d';

const localeReducer = (state: Locale = 'en', action: RootAction): Locale => {
    switch (action.type) {
        case getType(setLocale):
            return action.payload.locale;
        default:
            return state;
    }
};
export default localeReducer;
