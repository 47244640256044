import { REPLACE_STATE } from 'actions/constants';
import { replace } from 'connected-react-router';
import moment from 'moment';
import { getDencryptTaskDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeTaskData';
import { setEntitySubmitsInTaskContext } from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/actions';
import { RootState } from 'reducers/rootReducer';
import { Store } from 'redux';
import { getOfflineSubmitEntries } from '../back_online/components/getOfflineSubmitEntries';

const initializeFormState = (store: Store, state: RootState) => {
    const existingState = store.getState();
    const {
        form,
        admin: { entities },
        valueSets,
        viewConfig,
        viewValidations,
        entityValidations,
        entityVisibility,
        entityEditability,
        entityConceptExps,
    } = state;
    store.dispatch({
        type: REPLACE_STATE,
        payload: {
            ...existingState,
            // we need to pass our old entities to trigger merge conflicts
            admin: {
                ...existingState.admin,
                entities,
            },
            valueSets,
            form,
            viewConfig,
            viewValidations,
            entityValidations,
            entityVisibility,
            entityEditability,
            entityConceptExps,
        },
    });
};

export const initializeLinkedEntityFormState = (store: Store, state: RootState) => {
    // redux-form needs to be explicitly initialized
    store.dispatch({
        type: '@@redux-form/INITIALIZE',
        meta: {
            form: 'record-form',
            keepDirty: true,
            lastInitialValues: state.form['record-form']?.initial,
            updateUnregisteredFields: true,
        },
        payload: state.form['record-form']?.initial,
    });
    return new Promise<void>((resolve) => {
        setImmediate(() => {
            initializeFormState(store, state);
            resolve();
        });
    });
};
export const initializeTaskFormFormStates = (store: Store, state: RootState) => {
    initializeFormState(store, state);
    store.dispatch({
        type: '@@redux-form/INITIALIZE',
        meta: {
            form: 'current-task-form',
            keepDirty: false,
            lastInitialValues: state.form['current-task-form']?.initial,
            updateUnregisteredFields: true,
        },
        payload: state.form['current-task-form']?.values,
    });
    return initializeLinkedEntityFormState(store, state);
};

const loadTaskStateFromIDB = async (taskId: string, store: Store, cb?: () => void) => {
    store.dispatch(replace('/_temp' + window.location.search));

    // not triggering subscriptions yet - we do that at the bottom of the function.
    const data = await getDencryptTaskDataPromptController().promptDecodeTaskData(taskId, false);

    const { router: futureRouter, entitySubmitsInTaskContext, offlineTasks: _, ...rest } = data;
    const { router, offlineTasks } = store.getState();

    const payload = { router, offlineTasks, ...rest } as RootState;
    await initializeTaskFormFormStates(store, payload);

    store.dispatch({
        type: REPLACE_STATE,
        payload,
    });
    // we are appending router.location.search here, because the only value it can have on task pages
    // is ?offline=1,
    // and we want that to stick around.
    store.dispatch(replace(futureRouter.location.pathname + router.location.search));

    await initializeTaskFormFormStates(store, payload);

    getOfflineSubmitEntries(taskId).then((entries) => {
        const data = Object.entries(entries)
            .sort(([a], [b]) => {
                const ma = moment(a);
                if (ma.isBefore(b)) {
                    return -1;
                }
                if (ma.isAfter(b)) {
                    return 1;
                }
                return 0;
            })
            .map(([key, value]) => value);
        store.dispatch(setEntitySubmitsInTaskContext(taskId, data));
    });
    await new Promise((resolve) => setTimeout(resolve, 100));
    cb?.();
    getDencryptTaskDataPromptController().runSubscriptions();
};

export default loadTaskStateFromIDB;
