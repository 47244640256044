import { ViewField } from 'reducers/ViewConfigType';

/**
 * When a merge view is triggered from an Edit view,
 * we use the Edit view field definitions.
 * However there may be blank labels, and 'aria-labelledby' configuration
 * (which won't work in the popup).
 * So we need to address that to ensure labels are present by
 * manipulating the widget definitions slightly.
 */
const convertFieldDefinitionForMergeView = <T extends ViewField>(viewField: T): T => {
    const labelIsWhitespace = (viewField.label?.length ?? 0) > 0 && (viewField.label?.trim()?.length ?? 0) === 0;
    const config = viewField.config
        ? (() => {
              try {
                  const parsedConfig = JSON.parse(viewField.config);
                  const { labelledBy, ...rest } = parsedConfig;
                  return JSON.stringify(rest);
              } catch (e) {
                  return viewField.config;
              }
          })()
        : undefined;
    return labelIsWhitespace ? { ...viewField, label: undefined, config } : viewField;
};

export default convertFieldDefinitionForMergeView;
