import React, { FunctionComponent, useState } from 'react';
import { saveAs } from 'file-saver';
import buildHeaders from 'sideEffect/buildHeaders';
import DropzoneUpload from './DropzoneUpload';

const config = require('../../config.js');

interface UploadFileWithSkipCalcsProps {
    title: string;
    dropMessage: string;
    resourceUrl: string;
}

export const maybeDownloadInlineContentDisposition = async (response: Response) => {
    const disposition = response.headers.get('Content-Disposition');
    if (disposition?.includes('inline')) {
        let fileName;
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
        }
        const blob = await response.blob();
        saveAs(blob, fileName);
    }
};

const getLazyRequest = (skipCalcs: boolean, resourceUrl: string) => (files: File[]) => async () => {
    const data = new FormData();
    data.append('file', files[0]);
    data.append('skipCalcs', skipCalcs as any);
    const request = new Request(`${config.API_URL}${resourceUrl}`, {
        method: 'POST',
        credentials: 'same-origin',
        body: data,
        headers: buildHeaders({ includeCredentials: true }),
    });

    const response = await fetch(request);
    await maybeDownloadInlineContentDisposition(response);
    return response;
};

export const UploadFileWithSkipCalcs: FunctionComponent<UploadFileWithSkipCalcsProps> = (props) => {
    const { title, dropMessage, resourceUrl } = props;
    const [skipCalcs, setSkipCalcs] = useState(true);
    const handleCalcAllChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSkipCalcs(!event.target.checked);
        },
        [setSkipCalcs],
    );
    return (
        <div>
            <DropzoneUpload
                accept={() => {
                    // eslint-disable-next-line no-restricted-globals
                    return confirm(
                        skipCalcs
                            ? 'Calculated fields will be skipped. Continue?'
                            : 'Calculated fields will be calculated after import. Continue?',
                    );
                }}
                getLazyRequest={getLazyRequest(skipCalcs, resourceUrl)}
                title={title}
                ariaLabel={title}
                dropzoneText={`Drag ${dropMessage}`}
            />
            <div style={{ padding: '1em' }}>
                <label htmlFor="calcAll">Calc all entities after import?</label>
                <input
                    type="checkbox"
                    id="calcAll"
                    name="calcAll"
                    checked={!skipCalcs}
                    onChange={handleCalcAllChange}
                />
            </div>
        </div>
    );
};

export default UploadFileWithSkipCalcs;
