import React, { useMemo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { ListboxComponent } from 'layout-editor/add-field/virtualize';
import useViewConfig from 'util/hooks/useViewConfig';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import get from 'lodash/get';

interface EntityTypeSelectProps {
    defaultValue?: string;
    fieldName: string;
    label: string;
    required?: string;
}

const EntityTypeSelect: React.SFC<EntityTypeSelectProps> = (props) => {
    const { defaultValue, fieldName, required, label } = props;
    const { register, unregister, setValue, errors, trigger } = useFormContext();

    const handleChange = (e, targetName) => {
        setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    React.useEffect(() => {
        register({ name: fieldName }, { required });
        return () => {
            unregister(fieldName);
        };
    }, []); // eslint-disable-line

    const viewConfig = useViewConfig();
    const options = useMemo(() => {
        return Object.keys(viewConfig.entities);
    }, [viewConfig]);

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <Autocomplete
            options={options}
            defaultValue={defaultValue}
            autoHighlight
            onChange={(e, value) => {
                handleChange(value, fieldName);
                trigger();
            }}
            getOptionLabel={(option) => option}
            renderOption={(option) => option}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label={label}
                    margin="normal"
                    fullWidth
                    variant={fieldVariant}
                    error={
                        Array.isArray(get(errors, fieldName))
                            ? get(errors, fieldName).length > 0
                            : Boolean(get(errors, fieldName))
                    }
                    helperText={<ErrorMessage errors={errors} name={fieldName} />}
                    inputProps={{
                        ...params.inputProps,
                        draggable: false,
                        autoComplete: 'disabled',
                    }}
                />
            )}
        />
    );
};

export default EntityTypeSelect;
