import * as React from 'react';
import { SFC, ReactElement } from 'react';
import CommentPanel from './CommentPanel';

// Width: 1, 2, or 3
// side: 'left' or 'right'

// disabling twopanel, stack only
const buildCardStyle = (width, side, offCenterSplit = true) => {
    // if (width === 1) {
    return {
        width: '100%',
        marginRight: 0,
    };
    /* }
    if (offCenterSplit) {
        return {
            // golden ratio
            // width: (side === 'left' ? '60.8%' : '37.2%'),
            width: (side === 'left' ? '70%' : '28%'),
            float: side
        };
    }
    return {
        width: '49%',
        float: side
    }; */
};

type leftPanelProps = {
    taskId?: string;
    processId?: string;
    width: number;
    relatedEntityResource: string;
    relatedEntityId: string;
};
type rightPanelProps = {
    processId?: string;
    taskId?: string;
};

const TwoPanel: SFC<{
    width: number;
    taskId?: string;
    processId?: string;
    leftPanelContent: ReactElement<leftPanelProps> | ReactElement<leftPanelProps>[];
    offCenterSplit: boolean;
    rightPanelContent: ReactElement<rightPanelProps> | ReactElement<rightPanelProps>[];
    relatedEntityResource?: string;
    relatedEntityId?: string;
}> = ({
    width,
    offCenterSplit,
    leftPanelContent,
    rightPanelContent,
    taskId,
    processId,
    relatedEntityResource,
    relatedEntityId,
}) => (
    <div>
        <div style={{ marginTop: '.5em' }} />
        <div style={buildCardStyle(width, 'right', offCenterSplit)}>
            {
                /* eslint-disable no-nested-ternary */
                rightPanelContent ? (
                    rightPanelContent.constructor === Array ? (
                        (rightPanelContent as ReactElement<rightPanelProps>[]).map((c) =>
                            React.cloneElement(c, {
                                ...(processId ? { processId } : {}),
                                ...(taskId ? { taskId } : {}),
                            }),
                        )
                    ) : (
                        React.cloneElement(rightPanelContent as ReactElement<rightPanelProps>, {
                            ...(processId ? { processId } : {}),
                            ...(taskId ? { taskId } : {}),
                        })
                    )
                ) : (
                    <CommentPanel processId={processId} taskId={taskId} />
                )
            }
        </div>
        <div style={{ marginTop: '1em' }} />
        <div style={buildCardStyle(width, 'left', offCenterSplit)}>
            {leftPanelContent &&
                (leftPanelContent.constructor === Array
                    ? (leftPanelContent as ReactElement<leftPanelProps>[]).map((c) =>
                          React.cloneElement(c, {
                              ...(processId ? { processId } : {}),
                              ...(taskId ? { taskId } : {}),
                              width,
                              relatedEntityResource,
                              relatedEntityId,
                          }),
                      )
                    : React.cloneElement(leftPanelContent as ReactElement<leftPanelProps>, {
                          ...(processId ? { processId } : {}),
                          ...(taskId ? { taskId } : {}),
                          width,
                          relatedEntityResource,
                          relatedEntityId,
                      }))}
        </div>
        <div style={{ clear: 'both' }} />
    </div>
);

export default TwoPanel;
