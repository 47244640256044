import { getValidationInFormContext } from 'fieldFactory/util/validate';
import { AddressPopoverValue, IAddressWidgetConfig } from './types';

export const getAddressValidation = (
    parsedConfig: Pick<
        IAddressWidgetConfig,
        'fieldMapping' | 'fixedValues' | 'validationMessage' | 'verificationRequired'
    >,
    source: string,
) =>
    getValidationInFormContext((value: AddressPopoverValue, allValues = {}, props) => {
        if (
            (parsedConfig.verificationRequired === 'WHEN_NOT_EMPTY' &&
                // not empty
                Object.entries(parsedConfig.fieldMapping)
                    .filter(([_stdF]) => {
                        const stdF = _stdF.endsWith('Code')
                            ? _stdF.slice(0, -4)
                            : _stdF.endsWith('Id')
                            ? _stdF.slice(0, -2)
                            : _stdF;
                        return !(
                            parsedConfig.fixedValues &&
                            (parsedConfig.fixedValues[stdF] ||
                                parsedConfig.fixedValues[stdF + 'Id'] ||
                                parsedConfig.fixedValues[stdF + 'Code'])
                        );
                    })
                    .find(([_, mappedField]: [string, string]) => allValues[mappedField])) ||
            parsedConfig.verificationRequired === 'ALWAYS'
        ) {
            return !value || value === 'NO_MATCH' || value === 'ERROR' ? parsedConfig.validationMessage : undefined;
        }
        return undefined;
    }, source);
