import { RootAction } from 'actions/rootAction';
import { crudUpdate, crudUpdateFailure, crudUpdateSuccess } from 'sideEffect/crud/update/actions';
import { getType } from 'typesafe-actions';
import produce from 'immer';

type EntityFormSubmissionState = {
    [formId: string]: 'submitting' | 'submitted' | 'failed';
};

const entityFormSubmissionStateReducer = (
    state: EntityFormSubmissionState = {},
    action: RootAction,
): EntityFormSubmissionState => {
    switch (action.type) {
        case getType(crudUpdate): {
            const formId = action.payload.formId;
            if (!formId) {
                return state;
            }
            return {
                ...state,
                [formId]: 'submitting',
            };
        }
        case getType(crudUpdateFailure): {
            const formId = action.requestPayload.formId;
            if (!formId) {
                return state;
            }
            return {
                ...state,
                [formId]: 'failed',
            };
        }
        case getType(crudUpdateSuccess): {
            const formId = action.requestPayload.formId;
            if (!formId) {
                return state;
            }
            return {
                ...state,
                [formId]: 'submitted',
            };
        }
        case '@@redux-form/DESTROY': {
            return produce(state, (draft) => {
                action.meta.form.forEach((formId) => {
                    delete draft[formId];
                });
            });
        }
        default:
            return state;
    }
};

export default entityFormSubmissionStateReducer;
