import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import { withDateFormat } from '../../dateFormat/Broadcasts';

const DateField = ({ elStyle, record, source, formatDate }) => {
    if (!record) return null;
    const value = get(record, source);
    if (value == null) return null;
    const dateString = formatDate(value);
    return <span style={elStyle}>{dateString}</span>;
};

/*
    These propTypes are important to have, even if they are not used.
*/
DateField.propTypes = {
    addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    elStyle: PropTypes.shape({}),
    label: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    record: PropTypes.shape({}),
    source: PropTypes.string.isRequired,
    formatDate: PropTypes.func.isRequired,
};

DateField.defaultProps = {
    addLabel: undefined,
    elStyle: {},
    label: undefined,
    record: undefined,
};

const PureDateField = withDateFormat(pure(DateField));

PureDateField.defaultProps = {
    addLabel: true,
};

export default PureDateField;
