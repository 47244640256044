import React from 'react';
import isTheInstalledApp, { setIsMacApp } from 'util/isTheInstalledApp';
/**
 * When we need to rerender because the app installed itself.
 *
 */
const useIsTheInstalledApp = () => {
    const [isInstalledApp, setIsInstalledApp] = React.useState(isTheInstalledApp());
    React.useEffect(() => {
        const listener = ({ matches }) => {
            const isInstalled = isTheInstalledApp();
            setIsInstalledApp(isInstalled);
            if (isInstalled) {
                setIsMacApp();
            } else {
                // the app never goes from installed to not-installed, so this is fine.
            }
        };
        const mm1 = window.matchMedia?.(`(display-mode: standalone)`);
        const mm2 = window.matchMedia?.(`(display-mode: minimal-ui)`);
        mm1?.addEventListener('change', listener);
        mm2?.addEventListener('change', listener);
        return () => {
            mm1?.removeEventListener('change', listener);
            mm2?.removeEventListener('change', listener);
        };
    }, []);
    return isInstalledApp;
};

export default useIsTheInstalledApp;
