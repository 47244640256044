import React, { useReducer } from 'react';
import Divider from '@material-ui/core/Divider';
import List, { ListProps } from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useViewConfig from 'util/hooks/useViewConfig';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import ViewConfig, { MenuItem } from 'reducers/ViewConfigType';
import Collapse from '@material-ui/core/Collapse';
import { push as pushAction } from 'connected-react-router';
import ExitToApp from '@material-ui/icons/ExitToApp';
import fromEntries from 'util/fromentries';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import { useDispatch, useSelector } from 'react-redux';
import { startProcessFromKey } from 'bpm/create-process-instance/actions';
import { translateRoute } from 'components/generics/utils/viewConfigUtils';
import useLogout from 'auth/hooks/useLogout';
import { getFooterConfigSelector } from 'util/applicationConfig';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: theme.mixins.toolbar,
    }),
);
type MenuState = {
    [menuItemName: string]: boolean;
};
const getFlatMenuState = (initial: boolean) => {
    const _getFlatMenuState = (menu: ViewConfig['menus']): MenuState => {
        return menu.reduce((prev, curr) => {
            if (curr.children) {
                return { ...prev, [curr.name]: initial, ..._getFlatMenuState(curr.children) };
            }
            return prev;
        }, {});
    };
    return _getFlatMenuState;
};

const useStylesRecursiveList = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        litem: (props: RecursiveListProps) => {
            const depth = props.depth || 0;
            return {
                paddingLeft: theme.spacing(2 + depth),
            };
        },
        liHasChildren: {
            fontWeight: 'bold',
        },
        liText: (props: RecursiveListProps) => {
            const depth = props.depth || 0;
            return {
                fontSize: `${depth === 0 ? '100' : '95'}%`,
            };
        },
        expandIcon: {
            opacity: '.5',
        },
    }),
);
interface RecursiveListProps {
    clickHandlers: {
        [menuName: string]: () => void;
    };
    menuState: MenuState;
    menu: MenuItem[];
    ListProps?: ListProps<any>;
    depth?: number;
}
const RecursiveList: React.FunctionComponent<RecursiveListProps> = React.memo((props) => {
    const depth = typeof props.depth === 'undefined' ? 0 : props.depth;
    const _props = { ...props, depth };
    const classes = useStylesRecursiveList(_props);
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    const handleRoute = React.useCallback(
        (route: string) => {
            if (route.startsWith('startProcess(') && route.endsWith(')')) {
                dispatch(
                    startProcessFromKey(
                        {
                            processDefinitionKey: route.slice('startProcess('.length, -1),
                        },
                        {
                            handleRedirect: true,
                            successCb: () => null,
                            errorsCbs: {},
                        },
                    ),
                );
            } else {
                dispatch(pushAction(route.startsWith('/') ? route : `/${route}`));
            }
        },
        [dispatch],
    );
    return (
        <List {...props.ListProps} className={classes.root}>
            {props.menu.map((m) => {
                if (!m.children || m.children.length === 0) {
                    const translatedRoute = translateRoute(viewConfig, m.route, m.view);
                    return (
                        <ListItem
                            onClick={() => handleRoute(translatedRoute)}
                            className={classes.litem}
                            button
                            key={m.name}
                        >
                            <ListItemText className={classes.liText} primary={m.label} />
                        </ListItem>
                    );
                }
                const open = props.menuState[m.name];
                const handleClick = props.clickHandlers[m.name];
                return (
                    <React.Fragment key={m.name}>
                        <ListItem className={classes.litem} button onClick={handleClick}>
                            <ListItemText
                                className={classes.liText}
                                primaryTypographyProps={{
                                    className: classes.liHasChildren,
                                }}
                                primary={m.label}
                            />
                            {/* {open ? (
                                <ExpandLess className={classes.expandIcon} />
                            ) : (
                                <ExpandMore className={classes.expandIcon} />
                            )} */}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <RecursiveList
                                depth={props.depth + 1}
                                menu={m.children}
                                clickHandlers={props.clickHandlers}
                                menuState={props.menuState}
                                ListProps={{
                                    component: 'div',
                                    disablePadding: true,
                                }}
                            />
                        </Collapse>
                    </React.Fragment>
                );
            })}
        </List>
    );
});
const DrawerMenu = React.memo((props) => {
    const footerConfig = useSelector(getFooterConfigSelector);
    const classes = useStyles(props);
    const viewConfig = useViewConfig();
    const logout = useLogout(false);
    const defaultMenuState: MenuState = React.useMemo(() => {
        return getFlatMenuState(false)(viewConfig.menus);
    }, [viewConfig]);
    const [menuState, dispatch] = useReducer((state, nameToToggle: string): MenuState => {
        return {
            ...state,
            [nameToToggle]: !state[nameToToggle],
        };
    }, defaultMenuState);
    const clickHandlers = React.useMemo(() => {
        return fromEntries(
            Object.keys(defaultMenuState).map(
                (menuName) => [menuName, () => dispatch(menuName)] as [string, () => void],
            ),
        );
    }, [defaultMenuState]);
    const dispatchReduxAction = useDispatch();
    const handleManageViewConfigRedirect = React.useCallback(() => {
        dispatchReduxAction(pushAction('/admin/application-information'));
    }, [dispatchReduxAction]);
    return (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <RecursiveList depth={0} menu={viewConfig.menus} menuState={menuState} clickHandlers={clickHandlers} />
            <Divider />
            <List>
                <ListItem button onClick={logout}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
                <ListItem button onClick={handleManageViewConfigRedirect}>
                    <ListItemIcon>
                        <SwapVerticalCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Application Information" />
                </ListItem>
            </List>
            {Boolean(footerConfig) ? <div style={{ height: '35px' }} /> : null}
        </div>
    );
});

export default DrawerMenu;
