import * as React from 'react';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { getView } from '../../utils/viewConfigUtils';
import mapField from '../../utils/labelField';
import FieldMatrixProps from '../interfaces/FieldMatrix';
import { FieldViewField } from '../../../../reducers/ViewConfigType';
import { LiveFieldConfig } from 'fieldFactory/ConfigTypes';
import { getIsEntityJoinMultiSelect } from '../../utils/viewConfigUtils/getFieldProperties/viewFields';
import AddRemoveRecordButton from '../components/AddRemoveRecordButton';
import get from 'lodash/get';
import { fromNullable } from 'fp-ts/lib/Option';
import { change } from 'redux-form';
import { Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import convertFieldDefinitionForMergeView from '../utilities/convertFieldDefinitionForMergeView';

export const hiddenLabelStyle: React.CSSProperties = {
    clip: 'rect(1px, 1px, 1px, 1px)',
    clipPath: 'inset(50%)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
};

const SubscribedCheckboxComponent = (props) => (
    <Checkbox
        inputProps={{
            'aria-label': `Select ${props.colLabel} ${props.label || props.source}`,
        }}
        {...props}
    />
);
interface SubscribedCheckboxProps {
    label?: string;
    source: string;
    value: any;
    colLabel: string;
}

const adjustValue = (value) => {
    if (typeof value === 'undefined') {
        return '';
    }
    return value;
};
const mapCheckboxDispatchToProps = (dispatch, ownProps) => ({
    onChange: () => {
        dispatch(change('mergeForm', ownProps.source, adjustValue(ownProps.value)));
    },
});
const SubscribedCheckbox: React.ComponentType<SubscribedCheckboxProps> = connect(
    (state: RootState, props: SubscribedCheckboxProps) => {
        return {
            checked: fromNullable(state.form)
                .chain((f) => fromNullable(f['mergeForm']))
                .map((mf) => get(mf.values, props.source))
                .map((v) => {
                    return adjustValue(v) === adjustValue(props.value);
                })
                .getOrElse(false),
        };
    },
    mapCheckboxDispatchToProps,
)(SubscribedCheckboxComponent);

const injectAddRemoveButtonToMultiSelect = (
    f: React.ReactElement<{
        source: string;
        showActionChildren?: React.ReactElement<{ source: string }>;
    }>,
) =>
    React.cloneElement(f, {
        showActionChildren: (
            <AddRemoveRecordButton source={f.props.source.endsWith('Ids') ? f.props.source : `${f.props.source}Ids`} />
        ),
    });

interface FieldProps {
    key: string;
    field: { props: { label?: string; source: string } };
    record: {};
}
const injectDisplayFieldsForRecord =
    (recordNameInProps, fieldsKey, columnLabel) =>
    (
        props: FieldMatrixProps & {
            generateDisplayFields: (config: LiveFieldConfig) => (fieldDefs: {}[]) => React.ReactElement<{}>[];
        },
    ) => {
        const { generateDisplayFields, resource, viewConfig, basePath, match } = props;
        const generate = generateDisplayFields({ match, renderLabel: true });
        const record = props[recordNameInProps];
        const view = getView(viewConfig, props.viewName || `${resource}Merge`);
        const fieldsForView = ([] as FieldViewField[])
            .concat(
                Object.values(view.fields).filter((f) => f.widgetType !== 'EXPRESSION') as FieldViewField[],
                ...(Object.values(view.tabs || {}).map((t) =>
                    Object.values(t.fields).filter((f) => f.widgetType !== 'EXPRESSION'),
                ) as FieldViewField[][]),
            )
            .filter((f) =>
                props.onlyFields
                    ? props.onlyFields.indexOf(f.field) !== -1 ||
                      props.onlyFields.indexOf(`${f.field}Id`) !== -1 ||
                      props.onlyFields.indexOf(`${f.field}Ids`) !== -1
                    : true,
            );

        const mapToLabelledField = (f) => mapField(f, record, resource, basePath);

        const withCheckbox = (f: React.ReactElement<FieldProps>) => {
            const id = `${f.props.key}-${recordNameInProps}-${columnLabel}-${f.props.field.props.source}`;
            const label = f.props.field.props.label;
            const source = f.props.field.props.source;

            return (
                <span id={id} style={{ display: 'flex' }} key={`${f.props.key}-${recordNameInProps}`}>
                    <label htmlFor={id}>
                        <span style={hiddenLabelStyle}>{columnLabel}</span>
                    </label>
                    <SubscribedCheckbox
                        colLabel={columnLabel}
                        label={label}
                        source={source}
                        value={get(f.props.record, source)}
                    />
                    {f}
                </span>
            );
        };
        const generatedViewFields = fieldsForView.flatMap((_field) => {
            const field = convertFieldDefinitionForMergeView(_field);
            if (getIsEntityJoinMultiSelect(viewConfig)(field)) {
                return props.includeRefManys
                    ? generate([field]).filter(Boolean).map(injectAddRemoveButtonToMultiSelect).map(mapToLabelledField)
                    : [];
            } else {
                return generate([field]).filter(Boolean).map(mapToLabelledField).map(withCheckbox);
            }
        });
        return {
            [fieldsKey]:
                record &&
                viewConfig &&
                match &&
                generatedViewFields.map((f: React.ReactElement<{ key: string }>) =>
                    React.cloneElement(f, { key: `${f.props.key}-${recordNameInProps}` }),
                ),
        };
    };
export default (recordNameInProps, injectFieldsWithName, columnLabel) =>
    withPropsOnChange(
        ['generateDisplayFields', recordNameInProps, 'resource', 'viewConfig', 'viewName', 'basePath'],
        injectDisplayFieldsForRecord(recordNameInProps, injectFieldsWithName, columnLabel),
    );
