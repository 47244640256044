import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { RootState } from 'reducers/rootReducer';
import isOffline from 'util/isOffline';
import { clearSessionStorage, getStorage } from 'storage/storage';

const useLogout = (redirectBackOnLogin: boolean = true) => {
    const dispatch = useDispatch();
    const currPath = useSelector((state: RootState) => {
        return state.router.location.pathname;
    });
    const currSearch = useSelector((state: RootState) => {
        return state.router.location.search;
    });

    const logoutFn = useCallback(() => {
        if (isOffline()) {
            // no login - just clear storage, then reload to force PIN re-entry.
            getStorage().clear();
            clearSessionStorage();
            window.location.reload();
            return;
        }

        if (currPath === '/' || !redirectBackOnLogin) {
            dispatch(pushAction('/logout-redirect'));
        } else if (currPath !== '/login' && currPath !== '/logout-redirect') {
            const path = `/logout-redirect?redirectTo=${encodeURIComponent(currPath + currSearch)}`;
            dispatch(pushAction(path));
        }
    }, [dispatch, currPath, currSearch, redirectBackOnLogin]);
    return logoutFn;
};
export default useLogout;
