import { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TaskForm } from 'reducers/taskFormType';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';

const FetchInitialQueryParams: FunctionComponent<{
    taskForm: TaskForm;
    queryParams?: {};
}> = ({ taskForm, queryParams }) => {
    const dispatch = useDispatch();
    const initialLookups: {
        entityId: string;
        entityType: string;
    }[] = useMemo(
        () =>
            taskForm.fields.flatMap((f) => {
                const value = queryParams?.[f.id];
                if (
                    f.id &&
                    typeof value !== 'undefined' &&
                    (f.type === 'entity-lookup' || f.type === 'entity-typeahead') &&
                    f.params.entity
                ) {
                    return [
                        {
                            entityId: value,
                            entityType: f.params.entity,
                        },
                    ];
                }
                return [];
            }),
        [taskForm, queryParams],
    );
    useEffect(() => {
        initialLookups.forEach(({ entityType, entityId }) => {
            dispatch(
                crudGetOne({
                    id: entityId,
                    resource: entityType,
                    view: -1,
                }),
            );
        });
    }, [initialLookups, dispatch]);
    return null;
};

export default FetchInitialQueryParams;
