import { crudGetList as epicCrudGetList } from 'sideEffect/crud/getList/actions';
import { APPCASE_PAGE_PREFIX } from '../../components/generics/utils/viewConfigUtils';

const getAppCaseByProcess = (processInstanceId: string | number, businessKey: string) => {
    return epicCrudGetList(
        {
            resource: 'AppCase',
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'DESC' },
            filter: { processInstanceId },
            view: `${APPCASE_PAGE_PREFIX}${businessKey}`,
        },
        false, // don't cancel request when route changes
    );
};
export default getAppCaseByProcess;
