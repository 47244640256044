import React, { FunctionComponent } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import Show from './Show2';
import SimpleDynamicView from '../DynamicView/SimpleDynamicView';
import { getIsDynamicView } from '../DynamicView/util';

interface GenericShowProps {
    viewName: string;
    createMobileAppBar?: boolean;
    resource: string;
    match: {
        params: {
            id: string;
        };
    };
    actions?: any;
    formId?: string;
    toolbar?: any;
    titleElement?: JSX.Element;
    renderTitleElement?: (props: { titleElem: JSX.Element }) => JSX.Element;
    embeddedInFormId?: string;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    disallowClickAwayNavigation?: boolean;
}

const GenericShow: FunctionComponent<GenericShowProps> = (props) => {
    const id = props.match?.params?.id;
    /**
     * Control rendering according to isDynamicView view configuration here.
     */
    const viewConfig = useViewConfig();
    const delayRenderingUntilFetch = React.useMemo(() => {
        return getIsDynamicView(viewConfig, props.viewName);
    }, [viewConfig, props.viewName]);
    if (delayRenderingUntilFetch) {
        // render component with pending states and resulting viewname
        return (
            <SimpleDynamicView
                id={id}
                entityType={props.resource}
                viewType="show"
                render={(casetivityViewName) => {
                    return <Show {...props} id={id} viewName={casetivityViewName ?? props.viewName} />;
                }}
            />
        );
    }
    return <Show {...props} id={id} />;
};
export default GenericShow;
