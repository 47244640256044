import ViewConfig from 'reducers/ViewConfigType';
import { fromNullable } from 'fp-ts/lib/Option';

/*
#getCurrentUserProperty('organizationId')
#currentUserHasRole('ROLE_DPH')
#getCurrentUserId()
#getCurrentUserLogin()
*/

export const currentUserProperties = <VC extends { user: ViewConfig['user'] }>(viewConfig: VC) => {
    const userO = fromNullable(viewConfig.user);
    const getCurrentUserProperty = (propertyKey: string) => {
        return userO
            .map((user) => user.properties)
            .chain(fromNullable)
            .map((p) => p[propertyKey])
            .chain(fromNullable)
            .getOrElse(null);
    };
    const currentUserHasRole = (roleName: string) => {
        return userO
            .map((user) => user.roles)
            .chain(fromNullable)
            .map((roles) => !!roles.find((r) => r.toLowerCase() === roleName.toLowerCase()))
            .getOrElse(false);
    };
    const getCurrentUserId = () => {
        return userO.map<string | null>((u) => u.id).getOrElse(null);
    };
    const getCurrentUserLogin = () => {
        return userO.map<string | null>((u) => u.login).getOrElse(null);
    };
    const userIsAnonymous = () => {
        return getCurrentUserLogin() === 'anonymousUser';
    };
    return {
        getCurrentUserProperty,
        currentUserHasRole,
        getCurrentUserId,
        getCurrentUserLogin,
        userIsAnonymous,
    };
};
