import { TOGGLE_DEBUG_MODE, DEBUG_MODE_OFF, DEBUG_MODE_ON } from '../actions/constants';

export default (previousState = false, { type }) => {
    if (type === TOGGLE_DEBUG_MODE) {
        return !previousState;
    }
    if (type === DEBUG_MODE_OFF) {
        return false;
    }
    if (type === DEBUG_MODE_ON) {
        return true;
    }
    return previousState;
};
