import React, { useState, FunctionComponent, useContext } from 'react';
import { Card, FormGroup, FormControlLabel, Checkbox, IconButton, CircularProgress } from '@material-ui/core';
import ViewDefSearchTable from 'layout-editor/build-layout/steps/components/PickViewDef/VirtualizedViewDefTable';
import Edit from '@material-ui/icons/Edit';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import Warning from '@material-ui/icons/Warning';
import Delete from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import AttemptRequest from 'components/AttemptRequest';
import * as config from 'config';
import stableStringify from 'json-stable-stringify';
import ReactDiffViewer from 'react-diff-viewer';
import useViewConfig from 'util/hooks/useViewConfig';
import { fixViewFieldOrderings } from 'layout-editor/UniversalViewWizard/cleanFieldCoordinates';
import { convert } from 'layout-editor/diff-viewsdefs-from-views/convertViewDefToView';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import useWidth from 'util/hooks/useWidth';
import { viewConfigNeedsRefreshContext } from 'components/layouts/ViewConfigNeedsRefresh/ViewConfigNeedsRefresh';
import buildHeaders from 'sideEffect/buildHeaders';

interface DeleteViewDefProps {
    viewName: string;
}
const DeleteViewDef: FunctionComponent<DeleteViewDefProps> = ({ viewName }) => {
    const { setViewConfigNeedsRefresh } = useContext(viewConfigNeedsRefreshContext);
    return (
        <AttemptRequest
            type="internal"
            renderer={({ attemptAction }) =>
                (state) =>
                    (
                        <div>
                            <IconButton
                                className="deleteViewDef"
                                disabled={state._tag === 'pending'}
                                onClick={() =>
                                    attemptAction({
                                        lazyRequest: () =>
                                            fetch(`${config.BACKEND_BASE_URL}api/view-config/update-view/${viewName}`, {
                                                method: 'DELETE',
                                                headers: buildHeaders({
                                                    includeCredentials: true,
                                                    Accept: 'application/json',
                                                    'Content-Type': 'application/json',
                                                }),
                                            }).then((response) => {
                                                setTimeout(() => {
                                                    setViewConfigNeedsRefresh(true);
                                                }, 20 * 1000);
                                                return response;
                                            }),
                                    })
                                }
                            >
                                {state._tag === 'pending' ? (
                                    <span>
                                        <CircularProgress style={{ height: 20, width: 20 }} />
                                    </span>
                                ) : state._tag === 'success' ? (
                                    <CheckboxIcon color="primary" />
                                ) : state._tag === 'failure' ? (
                                    <Warning color="error" />
                                ) : (
                                    <Delete color="error" />
                                )}
                            </IconButton>
                        </div>
                    )}
        />
    );
};
interface inspectViewDefPanelProps {
    viewName: string;
}
const InspectViewDefPanel: FunctionComponent<inspectViewDefPanelProps> = ({ viewName }) => {
    const [shouldCorrectCoordinates, setShouldCorrectCoordinates] = useState(true);
    const viewConfig = useViewConfig();
    if (!viewName) {
        return null;
    }
    const oldValue = shouldCorrectCoordinates
        ? viewConfig.views[viewName] && fixViewFieldOrderings(viewConfig.views[viewName])
        : viewConfig.views[viewName];
    const viewDef = viewConfig.viewDefs[viewName];
    if (!viewDef) {
        return null;
    }
    const parsedDef = JSON.parse(viewDef.definition);
    return (
        <div style={{ flex: 1, padding: '1em', paddingTop: 0 }}>
            <h2>{viewName}</h2>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={shouldCorrectCoordinates}
                                onChange={() => {
                                    setShouldCorrectCoordinates(!shouldCorrectCoordinates);
                                }}
                                name="checked"
                            />
                        }
                        label="Correct coordinates"
                    />
                </FormGroup>
                <IconButton component={Link} to={`/load-layout/${viewName}`}>
                    <Edit />
                </IconButton>
                <DeleteViewDef key={viewName} viewName={viewName} />
            </div>
            {viewConfig.views[viewName] && (
                <ReactDiffViewer
                    oldValue={stableStringify(oldValue, { space: ' ' })}
                    newValue={stableStringify(convert(parsedDef, viewConfig), { space: ' ' })}
                    splitView={true}
                />
            )}
        </div>
    );
};

const ReviewViewDefs = (props) => {
    const [viewName, setViewName] = useState('');
    const width = useWidth();
    return (
        <Card style={{ margin: '1em', padding: '1em' }}>
            {width === 'xs' || width === 'sm' ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                    <div>
                        <h2 style={{ marginLeft: '.5em ' }}>Select View Definition</h2>
                        <ViewDefSearchTable setViewName={setViewName} />
                    </div>
                    <InspectViewDefPanel viewName={viewName} />
                </div>
            ) : (
                <SplitPane split="vertical">
                    <Pane initialSize="50%">
                        <h2 style={{ marginLeft: '.5em ' }}>Select View Definition</h2>
                        <ViewDefSearchTable setViewName={setViewName} />
                    </Pane>
                    <InspectViewDefPanel viewName={viewName} />
                </SplitPane>
            )}
            {/* <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                <ViewDefSearchTable setViewName={setViewName} />
                <InspectViewDefPanel viewName={viewName} />
            </div> */}
        </Card>
    );
};
export default ReviewViewDefs;
