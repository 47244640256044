import React from 'react';
import { Button } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import PINInput from './PinInput';
import Message from 'i18n/components/Message';

type Data = {
    pin: string;
};

interface PinFormProps {
    onSubmit: (pin: string) => void;
    renderActions: (params: { submitBtn: JSX.Element }) => JSX.Element;
}
const PIN_LENGTH = 6;
const PinForm: React.FC<PinFormProps> = ({ onSubmit, renderActions }) => {
    const methods = useForm<Data>({
        mode: 'onChange',
    });
    return (
        <form onSubmit={methods.handleSubmit(({ pin }) => onSubmit(pin))}>
            <div style={{ paddingLeft: '1em', paddingRight: '2em' }}>
                <Controller
                    name="pin"
                    control={methods.control}
                    render={({ onChange, value }) => {
                        return (
                            <PINInput
                                numChars={PIN_LENGTH}
                                input={{
                                    onChange,
                                    value,
                                }}
                            />
                        );
                    }}
                />
            </div>
            {renderActions({
                submitBtn: (
                    <Button
                        disabled={methods.watch().pin?.trim()?.length !== PIN_LENGTH}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        <Message id="dialog.continue" dm="Continue" />
                    </Button>
                ),
            })}
        </form>
    );
};

export default PinForm;
