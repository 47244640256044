import * as processInstance from '../actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { GetOneStatus, createLoading, createError, createSuccess, createMoved } from 'remoteStatus/one/reducer';

const processInstanceStatusReducer = (state: GetOneStatus = {}, action: RootAction): GetOneStatus => {
    switch (action.type) {
        case getType(processInstance.getProcessInstance): {
            const key = action.payload;
            const existing = state[key] || 'initial';
            return {
                ...state,
                [key]: createLoading(existing),
            };
        }
        case getType(processInstance.getProcessInstanceFailure): {
            const key = action.requestPayload;
            return {
                ...state,
                [key]: createError(action.error),
            };
        }
        case getType(processInstance.getProcessInstanceSuccess): {
            const key = action.processId;
            if (action.response.id !== action.processId) {
                const newKey = action.response.id;
                return {
                    ...state,
                    [newKey]: createSuccess(),
                    [key]: createMoved(action.response.id),
                };
            }
            return {
                ...state,
                [key]: createSuccess(),
            };
        }
        default:
            return state;
    }
};
export default processInstanceStatusReducer;
