const splitByRegex = (regex: string) => (input: string) => {
    const rx = new RegExp(regex);
    const match = rx.exec(input);
    if (!match) {
        return null;
    } else {
        const index = match.index;
        const matching: string = match[0];
        const beforeMatch: string = input.slice(0, index);
        const afterMatch: string = input.slice(index + matching.length);
        return {
            matching,
            beforeMatch,
            afterMatch,
        };
    }
};
export default splitByRegex;
