import * as t from 'io-ts';
import runtimeValidatedJSONParse from '../../util/runtimeValidatedJSONParse';
import {
    getContainsCodes,
    getLookupEntityField,
    getLookupConceptIdsFromValuesetGroup,
    getGetConceptIdsFromCodes,
    getValuesetReverseLookupUtilities,
    getValuesetConceptIdsFromCodes,
} from '../contextUtils';
import ViewConfig from '../../reducers/ViewConfigType';
import { RootState } from '../../reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { currentUserProperties } from '../contextUtils/currentUser';

export type parsingErrors =
    | { tag: 'ValidationErrors'; validationErrors: t.ValidationError[] }
    | { tag: 'ParsingError'; input: string; errorMessage: string };
export type ErrorUnion = { message: string; name: string } | parsingErrors;

const expressionArrayConfig = t.array(t.string);
type configType = t.TypeOf<typeof expressionArrayConfig>;

export const entityAndConceptLookupUtils = (
    entities: { Concept?: {} },
    viewConfig: ViewConfig,
    valueSets: RootState['valueSets'],
) => {
    const lookupEntityData = getLookupEntityField(viewConfig, entities);
    return {
        containsCodes: getContainsCodes(entities.Concept || {}),
        lookupEntityData,
        mapToEntityData: (entityType: string, ids: null | string[], path: string) => {
            if (!ids) {
                return [];
            }
            return ids.map((id) => lookupEntityData(entityType, id, path));
        },
        lookupConceptIdsFromValuesetGroup: getLookupConceptIdsFromValuesetGroup(valueSets, entities),
        ...getValuesetReverseLookupUtilities(valueSets, fromNullable(entities.Concept).getOrElse({})),
        ...currentUserProperties(viewConfig),
        getConceptIdsFromCodes: getGetConceptIdsFromCodes(entities.Concept || {}),
        getValuesetConceptIdsFromCodes: getValuesetConceptIdsFromCodes(valueSets, entities),
    };
};
export const getExpressionsFromConfig = (json: string) =>
    runtimeValidatedJSONParse<configType>(expressionArrayConfig)(json);
