import * as viewConfig from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, catchError, map, flatMap, tap, withLatestFrom, delay } from 'rxjs/operators';
import { of, concat, Observable } from 'rxjs';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { fetchEnd, fetchStart } from 'actions/aor/fetchActions';
import stripUnusedItems from 'stripUnusedItemsFromViewConfig';
import ViewConfig from 'reducers/ViewConfigType';
import { rebuildSchema } from 'sideEffect/crud/util/normalizeEntityResponse';
import { getProcessDefinitions } from 'bpm/processDefinitions/actions';
import { getStorage } from 'storage/storage';
import { fromNullable } from 'fp-ts/lib/Option';
import { replace as replaceAction } from 'connected-react-router';
import { getAnonViewConfig, writeViewDefsToVC } from 'configureStore/reducer';
import { syncedActionsController } from 'configureStore/syncedActionsController';
import modifyViewConfig from 'modifyViewConfig/modifyViewConfig';
import { getMapRequestsSelector, getStorageModeSelector } from 'util/applicationConfig';
import { registerUrlRewrite, clearRegistry } from 'sideEffect/url-rewrite-registry/registry';
import { setStartingUserId } from 'auth/profiles/actions';
import { _storeViewConfig } from './util';

let buildIdentifier: string | number | null;

const storeViewConfig = tap(_storeViewConfig);

const refreshIfNewBuildDetectedOtherwiseStoreBuildID = tap((vc: ViewConfig) => {
    if (buildIdentifier && buildIdentifier !== vc.application.build) {
        getStorage().clear();
        window.location.href = '/';
    } else {
        buildIdentifier = vc.application.build;
    }
});

export const reloadViewConfigFromLocalstorageFlow: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) => {
    return action$.pipe(
        filter(isActionOf(viewConfig.reloadFromLocalstorage)),
        withLatestFrom(state$.pipe(map((state) => getMapRequestsSelector(state)))),
        map(([action, mapRequests]) => {
            return [
                fromNullable(getStorage().getItem('viewconfig'))
                    .map(JSON.parse)
                    .getOrElseL(getAnonViewConfig) as ViewConfig,
                mapRequests,
            ] as [ViewConfig, typeof mapRequests];
        }),
        tap(([vc, mapRequests]) => {
            rebuildSchema(vc);
            clearRegistry();
            mapRequests?.forEach(({ match, to }) => {
                registerUrlRewrite({ user: vc.user })({ regexp: match, rewrite: to });
            });
        }),
        map(([vc]) => vc),
        map(writeViewDefsToVC),
        map((vc) => {
            return viewConfig.loadSuccess(vc);
        }),
    );
};

const loadViewConfigFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(viewConfig.load)),
        withLatestFrom(
            state$.pipe(map((state) => state.viewConfigIsLoading)),
            state$.pipe(map((state) => getMapRequestsSelector(state))),
            state$.pipe(map((state) => getStorageModeSelector(state))),
            state$.pipe(map((state: RootState) => state.profiles)),
        ),
        filter(([action, viewConfigIsLoading, mapRequests, storageMode, profiles]) => !viewConfigIsLoading),
        flatMap(([action, _, mapRequests, storageMode, profiles]) =>
            concat<Observable<RootAction>>(
                of(fetchStart()),
                of(viewConfig.loading()),
                services.getViewConfig().pipe(
                    map((vc) => modifyViewConfig(vc)),
                    map(stripUnusedItems),
                    refreshIfNewBuildDetectedOtherwiseStoreBuildID,
                    storeViewConfig,
                    map(writeViewDefsToVC),
                    tap((vc) => {
                        rebuildSchema(vc);
                        clearRegistry();
                        mapRequests?.forEach(({ match, to }) => {
                            registerUrlRewrite({ user: vc.user })({ regexp: match, rewrite: to });
                        });
                    }),
                    tap((vc) => {
                        // tell other tabs they can update viewConfig from localstorage
                        // lets let syncing happen even when using profiles
                        if (storageMode !== 'sessionStorage' /* && profiles.state === 'no_profiles' */) {
                            (syncedActionsController.trigger as any)(viewConfig.reloadFromLocalstorage());
                        }
                    }),
                    flatMap((vc: ViewConfig) => {
                        let actionsToConcat: Observable<RootAction>[] = [];
                        if (action.redirectTo) {
                            actionsToConcat.push(of(replaceAction(action.redirectTo)).pipe(delay(250)));
                        }
                        if (profiles.state === 'pick_profile' && vc.user.id) {
                            actionsToConcat.push(of(setStartingUserId(vc.user.id)));
                        }
                        if (!action.notifyOnSuccess) {
                            return concat(
                                ...(
                                    [
                                        of(viewConfig.loadSuccess(vc)),
                                        of(fetchEnd()),
                                        of(getProcessDefinitions(false)),
                                    ] as Observable<RootAction>[]
                                ).concat(actionsToConcat),
                            );
                        }
                        return concat(
                            ...(
                                [
                                    of(viewConfig.loadSuccess(vc)),
                                    of(fetchEnd()),
                                    of(
                                        enqueueSnackbarAction({
                                            message: 'Configuration loaded',
                                            options: { variant: 'success' },
                                        }),
                                    ),
                                    of(getProcessDefinitions()),
                                ] as Observable<RootAction>[]
                            ).concat(actionsToConcat),
                        );
                    }),
                    catchError((err) => {
                        return concat(
                            of(viewConfig.loadFailure(err)),
                            of(fetchEnd()),
                            of(
                                enqueueSnackbarAction({
                                    message: 'View configuration failed to load.',
                                    options: { variant: 'error' },
                                }),
                            ),
                        );
                    }),
                ),
            ),
        ),
    );
};
export default loadViewConfigFlow;
