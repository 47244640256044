import { useMemo } from 'react';
import { purifyHtml } from 'fieldFactory/display/components/purifyHtml';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

const useTemplateMessagesAndPurifyHtml = (html: string) => {
    const templatedHtml = useEvaluatedFormattedMessage(html);
    return useMemo(() => {
        return templatedHtml && purifyHtml(templatedHtml);
    }, [templatedHtml]);
};
export default useTemplateMessagesAndPurifyHtml;
