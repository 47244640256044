import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { setExpressionTesterOpen } from './actions';

const expressionTesterOpenReducer = (state: boolean = false, action: RootAction) => {
    switch (action.type) {
        case getType(setExpressionTesterOpen): {
            return action.open;
        }
        default:
            return state;
    }
};
export default expressionTesterOpenReducer;
