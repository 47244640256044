import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { setDownloadedListViews, unsetDownloadedListViews } from './actions';
import { DownloadedListViews } from './data';

const downloadedListViewsReducer = (state: DownloadedListViews = null, action: RootAction): DownloadedListViews => {
    switch (action.type) {
        case getType(setDownloadedListViews): {
            return action.payload.data;
        }
        case getType(unsetDownloadedListViews): {
            return null;
        }
        default:
            return state;
    }
};

export default downloadedListViewsReducer;
