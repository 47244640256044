import React, { FunctionComponent } from 'react';
import expressionEvaluatorContext from './expressionEvaluatorContext';
import getImpl from './implementations/getImpl';

interface ExpressionEvalProviderProps {}
const ExpressionEvalProvider: FunctionComponent<ExpressionEvalProviderProps> = (props) => {
    return (
        <expressionEvaluatorContext.Provider
            value={{
                impl: getImpl(),
            }}
        >
            {props.children}
        </expressionEvaluatorContext.Provider>
    );
};

export default ExpressionEvalProvider;
