import * as actions from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, flatMap, catchError, tap, map, withLatestFrom } from 'rxjs/operators';
import { of, concat, Observable, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { load as loadViewConfigAction } from 'viewConfig/actions';
import { getProcessDefinitions } from 'bpm/processDefinitions/actions';
import { storageController } from 'storage';
import { storeImpersonating } from './storage';
import { push as pushAction } from 'connected-react-router';
import { syncedActionsController } from 'configureStore/syncedActionsController';
import { getStorageModeSelector } from 'util/applicationConfig';

const impersonateUserFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(actions.impersonateUser)),
        withLatestFrom(
            state$.pipe(map((state) => state.viewConfig && state.viewConfig.user && state.viewConfig.user.title)),
            state$.pipe(map((state) => state.router.location.pathname)),
            state$.pipe(map((state) => getStorageModeSelector(state))),
            state$.pipe(map((state) => state.profiles)),
        ),
        flatMap(
            ([action, prevUserTitle, currentLocation, storageMode, profiles]): Observable<RootAction> =>
                services.impersonateUser(action.payload.userId).pipe(
                    flatMap((ajaxResponse) => {
                        if (ajaxResponse.status < 200 || ajaxResponse.status >= 300) {
                            return throwError({
                                status: ajaxResponse.status,
                            });
                        }
                        return of(ajaxResponse.response);
                    }),
                    tap((responseBody) => {
                        storageController.startImpersonating(responseBody.id_token);
                        storeImpersonating({
                            userId: action.payload.userId,
                            previousUserTitle: prevUserTitle,
                            currentLocation,
                        });

                        // there used to be a  && profiles.state === 'no_profiles' check here.
                        // not sure it was necessary.
                        if (storageMode !== 'sessionStorage') {
                            (syncedActionsController.trigger as any)(
                                actions.impersonateUserSuccess(action.payload.userId, prevUserTitle, currentLocation),
                            );
                        }
                    }),
                    flatMap((response) =>
                        concat<RootAction>(
                            of(actions.impersonateUserSuccess(action.payload.userId, prevUserTitle, currentLocation)),
                            of(pushAction('/_temp')),
                            of(loadViewConfigAction(false, currentLocation)),
                            of(getProcessDefinitions(false)),
                        ),
                    ),
                    catchError((err: AjaxError) => {
                        return of(actions.impersonateUserFailure(action.payload.userId, err.status));
                    }),
                ),
        ),
    );
export default impersonateUserFlow;
