import { Input, InputLabel } from '@material-ui/core';
import CasetivitySelect from 'components/CasetivitySelect';
import React, { useMemo, useReducer, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Temporal } from '@js-temporal/polyfill';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import moment from 'moment';

type Unit = 'days' /* | 'weeks' */ | 'months' | 'years';

type RelativeDateProps = {
    label: string;
    value: string;
    relativeDateAs: 'diff' | 'date';
    onChange: (value: string) => void;
};

const getDiffFromDate = (date: string): [quantity: number, unit: Unit, direction: 1 | -1] => {
    if (!date) {
        return null;
    }
    const pd = Temporal.PlainDate.from(date);
    const now = Temporal.Now.plainDateISO();
    const diff = pd.since(now, {
        roundingIncrement: 1,
        smallestUnit: 'days',
        largestUnit: 'years',
        roundingMode: 'trunc',
    });
    // smallest to largest: order is important
    for (let unit of ['days', /* 'weeks',*/ 'months', 'years'] as const) {
        if (diff[unit] !== 0) {
            const quantity = pd.since(now, {
                smallestUnit: unit,
                largestUnit: unit,
                roundingIncrement: 1,
                roundingMode: 'trunc',
            })[unit];
            return [quantity < 0 ? quantity * -1 : quantity, unit, quantity >= 0 ? 1 : -1];
        }
    }
    return null;
};

const produceDateFromDiffs = (quantity: number, unit: Unit, dir: 1 | -1): string => {
    if (quantity === null || unit === null || dir === null) {
        return null;
    }
    const now = Temporal.PlainDate.from(Temporal.Now.plainDateISO());
    const then = now.add({
        [unit]: quantity * dir,
    });
    return then.toString();
};

export const encodeDiff = (date: string) => {
    const [quantity, unit, dir] = getDiffFromDate(date) ?? [0, 'days', -1];
    return `${JSON.stringify(date)}|${JSON.stringify(quantity)}|${JSON.stringify(unit)}|${JSON.stringify(dir)}`;
};

export const isDiffEncoding = (str: unknown): str is string => {
    if (typeof str !== 'string') {
        return false;
    }
    const parts = str.split('|');
    return (
        parts.length === 4 && parts[0].startsWith('"') && parts[0].endsWith('"') && [1, -1].includes(parseInt(parts[3]))
    );
};

export const decodeDiff = (diff: string) => {
    const [date, quantity, unit, dir] = diff.split('|').map((str) => {
        return JSON.parse(str);
    });

    const today = Temporal.Now.plainDateISO();
    const then = today
        .add({
            [unit]: quantity * dir,
        })
        .toString();

    return then;
};

export const RelativeDate: React.FC<RelativeDateProps> = (props) => {
    const internalId = useMemo(() => uniqueId('relativedate-'), []);
    const quantId = internalId + ':quant';
    const unitId = internalId + ':unit';
    const dirId = internalId + ':dir';
    const [quantity, unit, dir] = useMemo(() => {
        const date =
            props.relativeDateAs === 'diff'
                ? (() => {
                      try {
                          return decodeDiff(props.value);
                      } catch (e) {
                          // we are switching between modes, so lets be graceful
                          console.error(e);
                          return props.value;
                      }
                  })()
                : props.value;
        const fallback: ReturnType<typeof getDiffFromDate> = moment(date).isValid()
            ? [0, 'days', -1]
            : [null, null, null];
        return getDiffFromDate(date) ?? fallback;
    }, [props.value, props.relativeDateAs]);
    const quantityRef = useRef(quantity);
    if (quantity !== null) {
        quantityRef.current = quantity;
    }
    const unitRef = useRef(unit ?? 'days');
    if (unit !== null) {
        unitRef.current = unit;
    }
    const dirRef = useRef(dir ?? -1);
    if (dir !== null) {
        dirRef.current = dir;
    }
    const [, rerender] = useReducer((state) => state + 1, 1);

    const quantityValue = typeof quantityRef.current === 'number' ? '' + (quantityRef.current ?? '') : '';

    const unitValue = unitRef.current ?? unit;
    const debugMode = useSelector((state: RootState) => state.debugMode);
    const handleChange = () => {
        const value = produceDateFromDiffs(quantityRef.current, unitRef.current, dirRef.current);
        if (props.relativeDateAs === 'diff') {
            props.onChange(encodeDiff(value));
        } else {
            props.onChange(value);
        }
        rerender();
    };
    const checkBoxId = useMemo(() => uniqueId('todaycheckbox'), []);

    return (
        <span>
            {debugMode && JSON.stringify(props.value)}
            <div>
                <InputLabel shrink>{props.label}</InputLabel>
                <span id={quantId} className="casetivity-off-screen">{`${props.label} unit`}</span>
                <Input
                    style={{ width: 75, paddingBottom: '1px' }}
                    inputProps={{ 'aria-label': 'Quantity', style: { marginBottom: '-.5px', width: '100%' } }}
                    aria-labelledby={quantId}
                    type="number"
                    value={quantityValue}
                    onChange={(e) => {
                        if (e.target.value === '') {
                            props.onChange(null);
                            quantityRef.current = null;
                            rerender();
                            return;
                        }
                        const parsed = parseInt(e.target.value);
                        if (isNaN(parsed)) {
                            quantityRef.current = null;
                            rerender();
                        } else {
                            quantityRef.current = parsed;
                            handleChange();
                        }
                    }}
                />
                <span id={unitId} className="casetivity-off-screen">{`${props.label} unit`}</span>
                <CasetivitySelect
                    style={{ width: 80 }}
                    value={unitValue}
                    onChange={(event) => {
                        const unit = event.target.value as Unit;
                        unitRef.current = unit;
                        handleChange();
                    }}
                    SelectDisplayProps={{
                        'aria-describedby': unitId,
                    }}
                    label=""
                >
                    {({ OptionComponent }) => {
                        const options: Unit[] = ['days', /* 'weeks',*/ 'months', 'years'];
                        return options.map((option) => {
                            return (
                                <OptionComponent key={option} value={option}>
                                    {option}
                                </OptionComponent>
                            );
                        });
                    }}
                </CasetivitySelect>
                <span id={dirId} className="casetivity-off-screen">{`${props.label} direction`}</span>
                <CasetivitySelect
                    style={{ width: 115 }}
                    value={dirRef.current}
                    onChange={(event) => {
                        const dir = event.target.value as 1 | -1;
                        dirRef.current = dir;
                        handleChange();
                    }}
                    SelectDisplayProps={{
                        'aria-describedby': dirId,
                    }}
                    label=""
                >
                    {({ OptionComponent }) => {
                        return [
                            <OptionComponent key="b" value={-1}>
                                ago
                            </OptionComponent>,
                            <OptionComponent key="f" value={1}>
                                in the future
                            </OptionComponent>,
                        ];
                    }}
                </CasetivitySelect>
                <div style={{}}>
                    <label htmlFor={checkBoxId}>Today (&#177; 0 days)</label>
                    <input
                        type="checkbox"
                        id={checkBoxId}
                        name="todaycheckbox"
                        checked={quantityRef.current === 0}
                        onChange={(e) => {
                            if (!e.target.checked) {
                                quantityRef.current = null;
                                props.onChange(null);
                                return;
                            }
                            const nowStr = Temporal.Now.plainDateISO().toString();
                            const value = props.relativeDateAs === 'diff' ? encodeDiff(nowStr) : nowStr;
                            quantityRef.current = 0;
                            unitRef.current = 'days';
                            dirRef.current = -1;
                            props.onChange(value);
                        }}
                    />
                </div>
            </div>
        </span>
    );
};

const ControlledRelativeDate = (props) => {
    return (
        <RelativeDate
            relativeDateAs={props.relativeDateAs}
            value={props.input.value}
            onChange={props.input.onChange}
            label={props.label}
        />
    );
};
export default ControlledRelativeDate;
