import { Button } from '@material-ui/core';
import Popup from 'components/Popup';
import b64ToFile, { saveFile } from 'fieldFactory/util/b64ToFile';
import React, { useCallback } from 'react';
import useWidth from 'util/hooks/useWidth';

let isChromeIOS = /CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent);

interface B64ImagePopupProps {
    b64: string;
    filename: string;
    contentType: string;
    AnchorProps: Partial<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>;
    children: React.ReactNode;
}
const B64ImagePopup: React.FC<B64ImagePopupProps> = (props) => {
    const { filename, b64, contentType } = props;

    const download = useCallback(
        (e) => {
            e?.stopPropagation?.();
            e?.preventDefault?.();
            const data = b64ToFile(b64, filename, contentType);
            saveFile(data);
        },
        [filename, b64, contentType],
    );

    const width = useWidth();
    return (
        <Popup
            ComponentProps={width === 'xs' ? { fullScreen: true } : undefined}
            renderDialogContent={({ closeDialog }) => (
                <div style={{ textAlign: 'center', padding: '.5em' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '4px' }}>
                        {!isChromeIOS ? (
                            <Button size="small" variant="contained" color="primary" onClick={download}>
                                Download
                            </Button>
                        ) : (
                            <span />
                        )}
                        <Button size="small" variant="contained" onClick={closeDialog}>
                            Close
                        </Button>
                    </div>
                    <img
                        className="casetivity-popup-image"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                        alt={filename}
                        src={'data:image/png;base64,' + b64}
                    />
                </div>
            )}
            renderToggler={({ openDialog }) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                    href="javascript:;" // eslint-disable-line
                    {...props.AnchorProps}
                    onClick={openDialog()}
                >
                    {props.children}
                </a>
            )}
        />
    );
};
export default B64ImagePopup;
