/*
    View Config
*/

export const EXPORT_VIEW_CONFIG = 'EXPORT_VIEW_CONFIG';

export const VIEW_CONFIG_NOT_EXPORTED = 'VIEW_CONFIG_NOT_EXPORTED';

export const PROCESSES_NOT_EXPORTED = 'PROCESSES_NOT_EXPORTED';

/*
    Open/Close Task Drawer
*/

export const TASK_DRAWER_OPEN = 'TASK_DRAWER_OPEN';

export const TASK_DRAWER_CLOSE = 'TASK_DRAWER_CLOSE';

/* Value Set Related Actions */
export const VALUE_SET_NOT_RECEIVED = 'VALUE_SET_NOT_RECEIVED';
/* Value Set Groups */
export const VALUE_SET_GROUP_NOT_RECEIVED = 'VALUE_SET_GROUP_NOT_RECEIVED';
// valueset multiple
export const MULTIPLE_VALUE_SETS_NOT_RECEIVED = 'MULTIPLE_VALUE_SETS_NOT_RECEIVED';
// concepts

/*
    View Locking
*/

export const SET_AS_TOP_VIEW = 'SET_AS_TOP_VIEW';

export const UNSET_AS_TOP_VIEW = 'UNSET_AS_TOP_VIEW';

/*
    Merge
*/

export const MERGE = 'MERGE';

export const MERGE_SUCCESS = 'MERGE_SUCCESS';

export const MERGE_FAILURE = 'MERGE_FAILURE';

/*
    Not a match
*/
export const NOT_MATCH = 'NOT_MATCH';

/*
    Most Recent Tab opened
*/

export const RECENT_TAB_OPEN = 'RECENT_TAB_OPEN';

/*
    Turn off fetches for debug mode
*/

export const TOGGLE_DEBUG_MODE = 'TOGGLE_DEBUG_MODE';
export const DEBUG_MODE_OFF = 'DEBUG_MODE_OFF';
export const DEBUG_MODE_ON = 'DEBUG_MODE_ON';

/*
    Import a new redux-state for debugging
*/

export const REPLACE_STATE = '_REPLACE_STATE';

/*
    print mode
*/
export const TOGGLE_PRINT_MODE = 'TOGGLE_PRINT_MODE';

// clear redux state
export const CLEAR_STORE = 'CLEAR_STORE';
export const CLEAR_STORE_BUT_KEEP_LOCATION = 'CLEAR_STORE_BUT_KEEP_LOCATION';
