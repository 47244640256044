import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getAppTitleSelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

export const getDisplayTitle = (state: RootState) => {
    if (state.basicInfo && state.basicInfo.application) {
        const { title, environment } = state.basicInfo.application;
        if (environment && typeof environment === 'string' && !environment.toLowerCase().startsWith('prod')) {
            return `${title} - ${environment}`;
        }
        return title;
    }
    return 'Casetivity';
};

const Title = ({ titleText }) => {
    //return <span>{titleText}</span>;
    return <SafeHtmlAsReact html={titleText} />;
};

const enhance = connect((state: RootState) => ({
    titleText: getAppTitleSelector(state) || getDisplayTitle(state),
}));
export default enhance(Title);
