import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { RegistrationConfirmationFormProps } from './RegistrationConfirmationForm';
import LoginAppBar from 'auth/components/LoginAppBar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            display: 'grid',
            placeItems: 'center',
            padding: '1em',
        },
        backdrop: {
            height: '100vh',
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

const DefaultRegistrationForm: RegistrationConfirmationFormProps['renderForm'] = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.backdrop}>
            <LoginAppBar />
            <div className={classes.container}>
                <div style={{ paddingBottom: '40px' }}>
                    <Card>
                        <CardHeader title="Confirm Registration" />
                        <Divider />
                        <CardContent>
                            <div style={{ paddingLeft: '1em' }}>Registration Code</div>
                            {props.CodeElement}
                        </CardContent>
                        <Divider />
                        <CardContent>
                            {props.LoginInputElement}
                            <br />
                            <br />
                            {props.PasswordElement}
                            <br />
                            {props.ConfirmPasswordElement}
                            <br />
                            {props.ErrorMessageElement}
                        </CardContent>

                        <CardActions style={{ padding: '1em' }}>
                            {props.SubmitElement}
                            {props.StatusIndicatorElement}
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DefaultRegistrationForm;
