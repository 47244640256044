import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
const emptyObj = {};
const useFormSyncErrors = () => {
    return useSelector((state: RootState) => {
        return fromNullable(state.form)
            .map((f) => f['record-form'])
            .chain(fromNullable)
            .map((f) => f.syncErrors)
            .chain(fromNullable)
            .getOrElse(emptyObj);
    });
};
export default useFormSyncErrors;
