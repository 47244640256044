import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Warning from '@material-ui/icons/Warning';
import { Card } from '@material-ui/core';
import { userAgent } from 'userAgent';

interface IEBannerComponentProps {}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        card: {
            backgroundColor: theme.palette.background.paper,
            padding: '.5em 1em',
            borderRadius: 0,
            borderLeft: '4px solid ' + theme.palette.error.main,
            borderBottom: '1px solid grey',
            display: 'flex',
        },
        heading: {
            marginRight: '1em',
            marginLeft: '1em',
            flex: 1,
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular as any,
        },
    }),
);
const isIe = userAgent.isIe();
const IEBannerComponent = (props: IEBannerComponentProps) => {
    const classes = useStyles(props);
    if (!isIe) {
        return null;
    }
    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <div style={{ height: '100%', width: '.5em', backgroundColor: 'red' }} />
                <Warning />
                <Typography className={classes.heading}>
                    Internet Explorer is not well supported. Please use a modern browser like Chrome, Edge or Firefox.
                </Typography>
            </Card>
        </div>
    );
};
export default IEBannerComponent;
