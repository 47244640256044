import { createCustomAction } from 'typesafe-actions';
import { SET_ENTITY_SUBMITS_IN_TASKCONTEXT, UNSET_ENTITY_SUBMITS_IN_TASKCONTEXT } from './constants';
import { Entry } from './Entry';

export const setEntitySubmitsInTaskContext = createCustomAction(SET_ENTITY_SUBMITS_IN_TASKCONTEXT, (type) => {
    return (taskId: string, entries: Entry[]) => ({
        type,
        payload: {
            taskId,
            entries,
        },
    });
});

export const unsetEntitySubmitsInTaskContext = createCustomAction(UNSET_ENTITY_SUBMITS_IN_TASKCONTEXT, (type) => {
    return () => ({
        type,
    });
});
