import React, { FunctionComponent, useContext, useMemo, useCallback } from 'react';
import { reduxForm, InjectedFormProps, getFormSyncErrors, FormErrors } from 'redux-form';
import { Toolbar, Card, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import FileDownloadButton from 'report2/components/FileDownloadButton';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';
import useKeyCachingEval from 'expressions/Provider/hooks/useKeyCachingEval';
import fromEntries from 'util/fromentries';
import { ReportDefinitionParam, convertToFlowableTypeField } from 'report2/ReportDefinition';
import { flowablePreprocessValuesForEval } from 'expressions/formValidation';
import useEntities from 'util/hooks/useEntities';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import useValueSets from 'util/hooks/useValueSets';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import set from 'lodash/set';
import { getUseRelativeDatesSelector } from 'util/applicationConfig';

const returnTrue = () => true;

interface FormExtraProps {
    outputOptions: string[];
    longRunning?: boolean;
    _submit: (fileType: string, values: any) => void;
    children: (args: {
        valid: boolean;
        triggerValidation: () => void;
        getSubmit: (fileType: string) => () => void;
        OutputOptions: JSX.Element;
        submitFailed: boolean;
        syncErrors: FormErrors<{}, string>;
    }) => JSX.Element;
}
const _Form: FunctionComponent<FormExtraProps & InjectedFormProps<any, FormExtraProps>> = (props) => {
    const { children, handleSubmit, _submit, change, valid, outputOptions, longRunning, submitFailed, error, form } =
        props;
    const syncErrorsSelector = useMemo(() => getFormSyncErrors(form), [form]);
    const syncErrors = useSelector(syncErrorsSelector);
    const triggerValidation = handleSubmit(() => null);
    const getSubmit = (fileType: string) => handleSubmit((values) => _submit(fileType, values));

    const OutputOptions = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: '1.5em',
                paddingBottom: '1em',
            }}
        >
            {outputOptions &&
                outputOptions.map((value, index) => {
                    const fileType = value.toUpperCase();
                    return (
                        <FileDownloadButton
                            key={index}
                            triggerValidation={triggerValidation}
                            valid={valid}
                            longRunning={longRunning}
                            handleSubmit={getSubmit(fileType)}
                        >
                            {fileType}
                        </FileDownloadButton>
                    );
                })}
        </div>
    );
    return (
        <form autoComplete="off">
            {children({ getSubmit, triggerValidation, valid, OutputOptions, submitFailed, syncErrors })}
        </form>
    );
};

export const Form = reduxForm<any, FormExtraProps>({
    form: 'current-report-form',
})(_Form);

export const wrappedFields = (
    fields: React.ReactElement<any>[],
    hideFields?: {
        [source: string]: true;
    },
    mapHiddenField: (field: React.ReactElement<any>, key: any) => JSX.Element = (field, key) => (
        <div key={key} style={{ display: 'none' }}>
            {field}
        </div>
    ),
) =>
    (fields || []).map((field, i) => {
        const source = field.props.source;
        if (
            hideFields?.[
                source.endsWith('Ids') ? source.slice(0, -3) : source.endsWith('Id') ? source.slice(0, -2) : source
            ]
        ) {
            return mapHiddenField(field, i);
        }
        return (
            <div key={i} style={{ paddingBottom: '1em', maxWidth: 256 * 2 }}>
                {field}
            </div>
        );
    });

export interface ReportDefinition {
    longRunning?: boolean;
    id: number | string; // removable
    name: string; // removable
    displayName: string;
    description: string;
    definition: string; // removable
    config: {
        availableOutputs: string[];
        aboveOutputsHtml?: string;
        validations?: {
            expression: string;
            message: string;
        }[];
        filters?: {
            [fieldName: string]: string;
        };
    };
    fields: ReportDefinitionParam[];
}
interface ReportFormComponentProps {
    reportDefinition: ReportDefinition;
    onSubmit: (fileType: string, formData: any) => void;
}

export const useFields = <RD extends Pick<ReportDefinition, 'fields' | 'config'>>(reportDefinition: RD) => {
    const fieldFactory = useContext(FieldFactoryContext);
    const relativeDates = useSelector(getUseRelativeDatesSelector);
    const convertedFields = useMemo(() => {
        return reportDefinition.fields.map((f) => {
            let flowableField = convertToFlowableTypeField(f);
            if (flowableField.type === 'date' && relativeDates) {
                flowableField.type = 'relative-date';
            }
            const filterEntry = reportDefinition.config?.filters?.[f.name];
            if (filterEntry) {
                set(flowableField, 'params.filter', filterEntry);
            }
            return flowableField;
        });
    }, [reportDefinition, relativeDates]);
    const fields = useMemo(() => {
        const config = {
            dataSource: DataSource.FLOWABLE,
            mode: Mode.INPUT_NOWARN,
            validate: true,
            connected: true,
            options: {},
        };
        return fieldFactory(config)({ relativeDateAs: 'date' })(convertedFields);
    }, [fieldFactory, convertedFields]);

    return [fields, convertedFields];
};

export const getOutputOptions = (config: ReportDefinition['config']) => {
    const { availableOutputs } = config ?? {};
    if (availableOutputs?.length > 0) {
        return availableOutputs;
    }
    return ['pdf', 'xlsx', 'csv', 'txt', 'xml'];
};

export const useReportFormFieldsAndValidation = (reportDefinition: Pick<ReportDefinition, 'config' | 'fields'>) => {
    const [fields, convertedFields] = useFields(reportDefinition);
    const validations = useMemo(() => {
        return (reportDefinition.config && reportDefinition.config.validations) || [];
    }, [reportDefinition]);
    const [expressionToFields, expressionToExpression, expressionToMessage] = useMemo(() => {
        const expressionToFields = validations
            .flatMap((v) => {
                const fieldsRequired = getFieldsRequiredForExpression(v.expression);
                return fieldsRequired.map((fr) => [v.expression, fr] as [string, string]);
            })
            .reduce((prev, [expression, field]) => {
                if (!prev[expression]) {
                    prev[expression] = [field];
                } else {
                    prev[expression].push(field);
                }
                return prev;
            }, {} as { [field: string]: string[] });
        const expressionToExpression = fromEntries(
            validations.map((v) => [v.expression, [v.expression]] as [string, string[]]),
        );
        const expressionToMessage = fromEntries(validations.map((v) => [v.expression, v.message] as [string, string]));
        return [expressionToFields, expressionToExpression, expressionToMessage];
    }, [validations]);
    const evaluator = useKeyCachingEval(expressionToExpression);
    const entities = useEntities();
    const valueSets = useValueSets();
    const dateFormat = useSelector((state: RootState) => state.viewConfig.application.dateFormat);
    const processValues = useCallback(
        (values: any) => {
            const preparedValues = flowablePreprocessValuesForEval(
                values,
                convertedFields,
                entities,
                {
                    dateFormat,
                    viewContext: '' as any,
                },
                valueSets,
            );
            return preparedValues;
        },
        [convertedFields, entities, valueSets, dateFormat],
    );
    const validate = useCallback(
        (values: any) => {
            const processedValues = processValues(values);
            const result = Object.entries(evaluator(processedValues))
                .flatMap(([expression, [result]]) => {
                    if (result) {
                        return [];
                    }
                    return (expressionToFields[expression] || []).map((field) => {
                        return [field, expressionToMessage[expression]] as [string, string];
                    });
                })
                .reduce((prev, [field, msg]) => {
                    if (!prev[field]) {
                        prev[field] = msg;
                    } else {
                        prev[field] += '\n' + msg;
                    }
                    return prev;
                }, {});
            return result;
        },
        [evaluator, expressionToMessage, expressionToFields, processValues],
    );
    return [fields, validate] as [typeof fields, typeof validate];
};

const ReportFormComponent: FunctionComponent<ReportFormComponentProps> = (props) => {
    const { reportDefinition } = props;
    const outputOptions = useMemo(() => {
        const { config } = reportDefinition;
        return getOutputOptions(config);
    }, [reportDefinition]);
    const [fields, validate] = useReportFormFieldsAndValidation(reportDefinition);
    return (
        <div style={{ height: '100%' }}>
            <Helmet>
                <title>{reportDefinition.displayName}</title>
            </Helmet>
            <Card>
                <Toolbar>
                    <Typography component="h1" variant="h5">
                        Report {reportDefinition.displayName}
                    </Typography>
                </Toolbar>

                <div style={{ marginLeft: '1.5em', marginTop: '1em' }}>
                    <Typography variant="h6" component="h2">
                        Description
                    </Typography>
                    <span>{reportDefinition.description}</span>
                    <Form
                        outputOptions={outputOptions}
                        longRunning={reportDefinition.longRunning}
                        shouldValidate={returnTrue}
                        validate={validate}
                        _submit={props.onSubmit}
                    >
                        {({ OutputOptions }) => (
                            <React.Fragment>
                                <div style={{ marginTop: '1.5em' }}>{wrappedFields(fields)}</div>
                                {reportDefinition?.config?.aboveOutputsHtml ? (
                                    <SafeHtmlAsReact html={reportDefinition.config.aboveOutputsHtml} />
                                ) : null}
                                <div style={{ marginLeft: '1rem' }}>
                                    <Typography variant="h6" component="h3">
                                        {reportDefinition.longRunning ? 'Run Report' : 'Download Report'}
                                    </Typography>
                                    {OutputOptions}
                                </div>
                            </React.Fragment>
                        )}
                    </Form>
                </div>
            </Card>
            <div style={{ height: '100%' }} />
        </div>
    );
};

export default ReportFormComponent;
