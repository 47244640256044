import { useReducer } from 'react';

export type IdleTimerState =
    | {
          status: 'ACTIVE';
          activeDate: Date;
      }
    | {
          status: 'IDLE';
          date: Date;
      };
const useIdleTimerState = () => {
    return useReducer(
        (state: IdleTimerState, action: IdleTimerState): IdleTimerState => {
            switch (action.status) {
                case 'ACTIVE': {
                    return action;
                }
                case 'IDLE': {
                    if (state.status !== 'IDLE') {
                        return action;
                    }
                }
            }
            return state;
        },
        { status: 'ACTIVE', activeDate: new Date() },
    );
};
export default useIdleTimerState;
