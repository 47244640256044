import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { setDownloadedRefOneViews, unsetDownloadedRefOneViews } from './actions';
import { DownloadedRef1Views } from './data';

const downloadedRefOneViewsReducer = (state: DownloadedRef1Views = null, action: RootAction): DownloadedRef1Views => {
    switch (action.type) {
        case getType(setDownloadedRefOneViews): {
            return action.payload.data;
        }
        case getType(unsetDownloadedRefOneViews): {
            return null;
        }
        default:
            return state;
    }
};

export default downloadedRefOneViewsReducer;
