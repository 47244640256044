import { getStorage } from 'storage/storage';
import { fromNullable } from 'fp-ts/lib/Option';

const IMPERSONATE_KEY = 'currentlyimpersonating';
interface StoredData {
    userId: string;
    previousUserTitle: string;
    currentLocation: string;
}
export const clearImpersonatingStorage = () => {
    getStorage().removeItem(IMPERSONATE_KEY);
};
export const storeImpersonating = (action: StoredData) => {
    getStorage().setItem(IMPERSONATE_KEY, JSON.stringify(action));
};
export const getImpersonating = (): StoredData => {
    return fromNullable(getStorage().getItem(IMPERSONATE_KEY)).map(JSON.parse).getOrElse(null);
};
