import ViewConfig from '../reducers/ViewConfigType';
import injectMrgListViewsFromMatchViews from './injectMatchViewsFromMrgViews/index';
const hardViews = {
    ...require('./ProcessList.json'),
    ...require('./ProcessPage.json'),
    ...require('./TaskList.json'),
    ...require('./TaskListDashboard.json'),
    ...require('./TaskListForProcess.json'),
    ...require('./BpmCommentList.json'),
    ...require('./ActiveTaskListDashboard.json'),
    ...require('./ActiveTaskListSidebar.json'),
};

const mergeWithViewConfig = (viewConfig: ViewConfig): ViewConfig =>
    injectMrgListViewsFromMatchViews({
        ...viewConfig,
        views: {
            ...hardViews,
            ...viewConfig.views,
        },
    });
export default mergeWithViewConfig;
