import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, formValueSelector } from 'redux-form';
import { taskEventCreator } from '../../../../actions/taskEvent';
import TaskAttributesWhenOpenComponent from './TaskAttributesWhenOpen';
import TaskAttributesWhenClosedComponent from './TaskAttributesWhenClosed';
import AdhocTaskAttributes from './AdhocTaskAttributes';
import { RootState } from '../../../../../reducers/rootReducer';
import moment from 'moment';
import { datify } from 'fieldFactory/input/components/DateInput/datify';
import useHasMinimalHeader from 'bpm/hooks/useHasMinimalHeader';

const selector = formValueSelector('task-attributes-form');

const mapStateToProps = (state: RootState, ownProps) => {
    const task = ownProps.taskId && state.bpm.tasks.byId[ownProps.taskId];
    const schedDateString =
        ownProps.taskId &&
        state.admin.entities.TaskInstance &&
        state.admin.entities.TaskInstance[ownProps.taskId] &&
        (state.admin.entities.TaskInstance[ownProps.taskId] as any).schedDate;
    return {
        initialValues: {
            dueDate: datify(task.dueDate && moment(task.dueDate).toDate(), 'ISO'),
            description: task.description,
            name: task.name,
            priority: task.priority,
            schedDate: schedDateString,
            schedDuration: task.schedDuration,
            attendeesIds: task.attendeesIds,
            outcome: task.outcome || (task.endDate ? 'Completed' : undefined),
        },
        outcome: selector(state, 'outcome'),
        dueDateSelected: selector(state, 'dueDate'),
        description: selector(state, 'description'),
        name: selector(state, 'name'),
        priority: selector(state, 'priority'),
        schedDate: selector(state, 'schedDate'),
        attendeesIds: selector(state, 'attendeesIds'),
        schedDuration: selector(state, 'schedDuration'),
        taskForms: state.taskForms,
        task,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit: (values) => dispatch(taskEventCreator.updateTask(ownProps.taskId, values)),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        touchOnChange: true,
        enableReinitialize: true,
        form: 'task-attributes-form',
    }),
);

export default enhance(AdhocTaskAttributes);
export const TaskAttributesWhenOpen = enhance(TaskAttributesWhenOpenComponent);
export const TaskAttributesWhenClosed = enhance(TaskAttributesWhenClosedComponent);

export const ProcessTaskAttributes = compose(
    connect((state: RootState, ownProps: { taskId?: string }) => {
        const task = ownProps.taskId && state.bpm.tasks.byId[ownProps.taskId];
        return { task };
    }),
)((props) => {
    const hasMinimalHeader = useHasMinimalHeader();
    if (hasMinimalHeader) {
        return null;
    }
    return props.task && props.task.endDate ? (
        <TaskAttributesWhenClosed {...props} />
    ) : (
        <TaskAttributesWhenOpen {...props} />
    );
});
