import { createCustomAction } from 'typesafe-actions';
import { UNDISABLE_TASK_FORMS } from './constants';

export const undisableTaskForms = createCustomAction(UNDISABLE_TASK_FORMS, (type) => {
    return (disable: boolean) => ({
        type,
        payload: {
            disable,
        },
    });
});
