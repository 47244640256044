import { taskEventType as taskEvent } from '../../actions/taskEvent';
import ITask from '../../types/task';

export type TaskState = Partial<ITask> & { comments: string[] };

const defaultTaskPermissions = { canClaimTask: false, canAssignTask: false, potentialUsers: [] };

export default (state: TaskState = { comments: [], taskPermissions: defaultTaskPermissions }, action): TaskState => {
    switch (action.type) {
        case taskEvent.assignSuccess: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case taskEvent.updateSuccess:
        case taskEvent.getSuccess: {
            return {
                comments: state.comments,
                ...action.payload,
                taskPermissions: state.taskPermissions,
            };
        }
        case taskEvent.getPotentialUsersSuccess: {
            return {
                ...state,
                taskPermissions: action.payload.taskPermissions,
            };
        }
        default:
            return state;
    }
};
