import React, { useCallback, useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { PersonalizedReportParams } from 'custom-reports/types';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { ReportDefinition, ReportDefinitionParam, convertToFlowableTypeField } from 'report2/ReportDefinition';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from '../../fieldFactory/translation/types/DataSource';
import { Form, FormSpy } from 'react-final-form';
import produce from 'immer';
import { Button, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import { useSelector } from 'react-redux';
import { getUseRelativeDatesSelector } from 'util/applicationConfig';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const DefaultValue = ({
    fieldDefinition,
    initialValue,
    onChange,
}: {
    fieldDefinition: ReportDefinitionParam;
    initialValue: unknown;
    onChange: (value: unknown) => void;
}) => {
    const fieldFactory = useContext(FieldFactoryContext);
    const relativeDates = useSelector(getUseRelativeDatesSelector);
    const fields = useMemo(() => {
        const config = {
            dataSource: DataSource.FLOWABLE,
            mode: Mode.INPUT_NOWARN,
            validate: true,
            connected: 'ff',
            options: {},
        };
        return fieldFactory(config)({
            relativeDateAs: 'diff',
        })(
            [convertToFlowableTypeField(fieldDefinition)].map((f) => ({
                ...f,
                type: f.type === 'date' && relativeDates ? 'relative-date' : f.type,
            })),
        );
    }, [fieldFactory, fieldDefinition, relativeDates]);
    const handleSubmit = useCallback(() => {}, []);

    const initialValues = useMemo(() => {
        const v = {};
        v[fields[0].props.source] = initialValue ?? null;
        return v;
    }, [fieldDefinition, fields]); // eslint-disable-line

    return (
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
            {(props) => (
                <>
                    {fields}
                    <FormSpy
                        subscription={{ values: true }}
                        onChange={(formState) => {
                            const value = Object.values(formState.values)[0];
                            onChange(value);
                        }}
                    />
                </>
            )}
        </Form>
    );
};

export default function EditParamsTable<Definition extends Pick<ReportDefinition, 'params'>>(props: {
    reportDefinition: Definition;
    params: PersonalizedReportParams;
    setParams: (params: PersonalizedReportParams, isDeleteOfInvalidParam?: boolean) => void;
}) {
    const classes = useStyles();

    const fieldDefinitions = useMemo(() => {
        return props.reportDefinition.params.reduce((prev, curr) => {
            prev[curr.name] = curr;
            return prev;
        }, {});
    }, [props.reportDefinition]);
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Default Value</TableCell>
                        <TableCell>Visibility</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(props.params).map(([paramName, paramConf]) => {
                        const fieldDefinition = fieldDefinitions[paramName];
                        return (
                            <TableRow key={paramName}>
                                <TableCell component="th" scope="row">
                                    {paramName}
                                </TableCell>
                                <TableCell>
                                    {(() => {
                                        if (!fieldDefinition) {
                                            return (
                                                <Alert severity="error">
                                                    Field not found in definition.&nbsp;
                                                    <Button
                                                        onClick={() => {
                                                            const newParams = produce(props.params, (draftParams) => {
                                                                delete draftParams[paramName];
                                                            });
                                                            props.setParams(newParams, true);
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                        color="inherit"
                                                    >
                                                        Delete?
                                                    </Button>
                                                </Alert>
                                            );
                                        }
                                        return (
                                            <DefaultValue
                                                fieldDefinition={fieldDefinition}
                                                initialValue={paramConf.defaultValue}
                                                onChange={(newDefaultValue) => {
                                                    const newParams = produce(props.params, (draft) => {
                                                        draft[paramName].defaultValue = newDefaultValue;
                                                    });
                                                    props.setParams(newParams);
                                                }}
                                            />
                                        );
                                    })()}
                                </TableCell>
                                <TableCell>
                                    <RadioGroup
                                        value={paramConf.visibility ?? 'hidden'}
                                        onChange={(e) => {
                                            const newParams = produce(props.params, (draft) => {
                                                draft[paramName].visibility = e.target.value as any;
                                            });
                                            props.setParams(newParams);
                                        }}
                                    >
                                        <FormControlLabel
                                            disabled={!fieldDefinition}
                                            control={<Radio />}
                                            label="Visible"
                                            value={'visible'}
                                        />
                                        <FormControlLabel
                                            disabled={!fieldDefinition}
                                            control={<Radio />}
                                            label="Hidden"
                                            value={'hidden'}
                                        />
                                        <FormControlLabel
                                            disabled={!fieldDefinition}
                                            control={<Radio />}
                                            label="Show under 'additional options'"
                                            value={'toggle-to-show'}
                                        />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
