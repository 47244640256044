import { createCustomAction } from 'typesafe-actions';
import { FETCH_START, FETCH_CANCEL, FETCH_END, FETCH_ERROR } from './types';

export const fetchStart = createCustomAction(FETCH_START, (type) => {
    return () => ({ type });
});

export const fetchCancel = createCustomAction(FETCH_CANCEL, (type) => {
    return () => ({ type });
});
export const fetchEnd = createCustomAction(FETCH_END, (type) => {
    return () => ({ type });
});
export const fetchError = createCustomAction(FETCH_ERROR, (type) => {
    return () => ({ type });
});
