import { denormalize } from 'normalizr';
import deepEql from 'deep-eql';
import { RootState } from '../../../reducers/rootReducer';
import buildSchemaFromView from '../../../viewConfigSchema/denormalizing/buildSchemaFromView';
import { EntityValidations } from '../../../reducers/entityValidationsReducer';
import ViewConfig from '../../../reducers/ViewConfigType';
import memoizeOne from 'memoize-one';

const viewSchemas = {
    Edit: {},
    Show: {},
    Match: {},
    Merge: {},
};

export const getEmptyEntityBags = memoizeOne((viewConfig: ViewConfig) => {
    return Object.assign({}, ...Object.keys(viewConfig.entities).map((k) => ({ [k]: {} })));
});

const createGetData = (viewType: 'Edit' | 'Show' | 'Match' | 'Merge', param: string = 'id') => {
    /* Only create each schema once. */
    const getSchema = (viewConfig, resource, entityValidations?: EntityValidations[0]) => {
        if (viewSchemas[viewType][resource]) {
            return viewSchemas[viewType][resource];
        }
        // console.log('building schema for ', viewType);
        const thisSchema = buildSchemaFromView(viewConfig, `${resource}${viewType}`, entityValidations)[resource];
        viewSchemas[viewType][resource] = thisSchema;
        return thisSchema;
    };

    const data = {};
    const previousLoading = {};
    const getData = (
        state: RootState,
        props: {
            resource: string;
            match: { params: { [param: string]: string } };
        },
    ) => {
        const identifier = `${props.resource}:${props.match.params[param]}`;
        const prevLoading = previousLoading[identifier];
        const currLoading = state.admin.loading;
        if (Object.prototype.hasOwnProperty.call(previousLoading, identifier) && prevLoading > 0 && currLoading === 0) {
            // stopped loading
            const id: string = decodeURIComponent(props.match.params[param]);
            const newData =
                state.admin.entities[props.resource] &&
                denormalize(
                    id,
                    getSchema(
                        state.viewConfig,
                        props.resource,
                        viewType === 'Edit' ? state.entityValidations[props.resource] : [],
                    ),
                    { ...getEmptyEntityBags(state.viewConfig), ...state.admin.entities },
                );
            if (!deepEql(data[identifier], newData)) {
                // keep referential integrity
                data[identifier] = newData;
            }
        }
        previousLoading[identifier] = state.admin.loading;
        return data[identifier];
    };
    return getData;
};
export default createGetData;
