import { useMemo } from 'react';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const useEntities = () => {
    const getEntities = useMemo(createGetEntities, []);
    /*
        Important!
        we do NOT want:
            const entities = useSelector(getEntities);

        because we want to recalculate this on re-render, not just dispatched actions.
        This is necessary because we need to track cached vs current values, and the assumption our function is pure
        bypasses our logic controlling whether we update our cache or not.
    */
    const entities = useSelector((state: RootState) => getEntities(state));
    return entities;
};

export default useEntities;
