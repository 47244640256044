import { getAllFieldsFromView, isFieldViewField } from 'components/generics/utils/viewConfigUtils';
import ViewConfig from 'reducers/ViewConfigType';

const getFieldsWithConfigProperty = (
    viewConfig: ViewConfig,
    viewName: string,
    property: string,
): {
    [field: string]: true;
} =>
    getAllFieldsFromView(viewConfig, viewName).reduce((prev, f) => {
        if (isFieldViewField(f)) {
            if (f.config) {
                try {
                    const parsedConfig = JSON.parse(f.config);
                    if (parsedConfig[property]) {
                        prev[f.field] = true;
                    }
                    return prev;
                } catch (e) {
                    return prev;
                }
            }
        }
        return prev;
    }, {});

export default getFieldsWithConfigProperty;
