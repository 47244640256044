import React, { FunctionComponent, useCallback } from 'react';
import Popup from 'components/Popup';
import FullWidthTabs from 'expression-tester/entity-form/SplitView/Tabs';
import JsonDownload from 'expression-tester/util/JsonDownload';
import { ResultJsonDiff } from 'layout-editor/UniversalViewWizard/steps/Result';
import { SubmitViewButton } from 'layout-editor/UniversalViewWizard/steps/Result';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import { Button } from '@material-ui/core';
import { _storeViewConfig } from 'viewConfig/util';
import Save from '@material-ui/icons/Save';

interface SaveToLocalStorageButtonProps {
    viewConfig: ViewConfig;
}
const SaveToLocalStorageButton: FunctionComponent<SaveToLocalStorageButtonProps> = ({ viewConfig }) => {
    const handleClick = useCallback(() => {
        _storeViewConfig(viewConfig);
    }, [viewConfig]);
    return (
        <Button onClick={handleClick} color="primary" variant="contained">
            Save modified viewConfig to localStorage
        </Button>
    );
};

const PreviewPopup: FunctionComponent<{
    newView: View; // viewConfig.views[viewName]
    old?: View;
    viewConfig?: ViewConfig;
}> = ({ newView, old, viewConfig }) => {
    return (
        <Popup
            renderToggler={({ openDialog }) => (
                <Button endIcon={<Save />} size="small" color="primary" variant="contained" onClick={openDialog()}>
                    Review and Save Changes
                </Button>
            )}
            renderDialogContent={({ closeDialog }) => {
                const diffElement = <ResultJsonDiff initialValues={newView} diffFrom="provided" old={old || {}} />;
                const saveOrSubmitElement = (
                    <div>
                        <JsonDownload json={newView} />
                        &nbsp;&nbsp;
                        <SubmitViewButton mode="PUT" view={newView} />
                        &nbsp;&nbsp;
                        {viewConfig && <SaveToLocalStorageButton viewConfig={viewConfig} />}
                    </div>
                );
                return (
                    <div>
                        <FullWidthTabs
                            items={[
                                ['view diff', diffElement],
                                ['save or submit', saveOrSubmitElement],
                            ]}
                        />
                    </div>
                );
            }}
        />
    );
};

export default PreviewPopup;
