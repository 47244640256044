import React from 'react';
import useTitle from './hooks/useTitle';
import useDisplayName from './hooks/useDisplayName';
import { isWidthUp } from '@material-ui/core/withWidth';
import useWidth from 'util/hooks/useWidth';
import { Width } from 'bpm/components/layout/withAdjustedWidth';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

const useDontShowAboveWidth = (displayAboveWidth: Width) => {
    const width = useWidth();
    return displayAboveWidth && !isWidthUp(displayAboveWidth, width, false);
};

interface DisplayAboveWidthProps {
    displayAboveWidth?: Width;
    children: JSX.Element;
}

export const DisplayAboveWidth: React.FC<DisplayAboveWidthProps> = ({ displayAboveWidth, children }) => {
    const dontShow = useDontShowAboveWidth(displayAboveWidth);
    if (dontShow) {
        return null;
    }
    return children;
};

interface TitleProps {
    resource: string;
    id: string;
    prefixToTitle?: string;
    displayAboveWidth?: Width;
    component: React.ElementType;
}
const Title: React.FunctionComponent<TitleProps> = ({
    prefixToTitle = '',
    resource,
    id,
    displayAboveWidth,
    component = 'h1',
}) => {
    const Component = component;
    const fallBackTitle = `${resource} ${id}`;
    const recordTitle = useTitle({ resource, id });
    const displayName = useDisplayName({ resource, type: 'SINGULAR' });

    const titleText =
        (prefixToTitle ? prefixToTitle + ' ' : '') +
        (recordTitle ? `${displayName || resource}: ${recordTitle || fallBackTitle}` : '');

    const dontShow = useDontShowAboveWidth(displayAboveWidth);
    if (dontShow) {
        return null;
    }
    return (
        <Component>
            <SafeHtmlAsReact html={titleText} />
        </Component>
    );
};

export default Title;
