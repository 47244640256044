import React, { FunctionComponent } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Button, IconButton } from '@material-ui/core';
import Popup from 'components/Popup';
import Delete from '@material-ui/icons/Delete';
import EditExpression from './ExpressionEditor';

interface SearchValidation {
    expression: string;
    message: string;
}
interface SearchValidationsEditorProps {
    viewName: string;
    setSearchValidations: (searchValidations: SearchValidation[]) => void;
    searchValidations?: SearchValidation[];
}
const SearchValidationsEditor: FunctionComponent<SearchValidationsEditorProps> = (props) => {
    const { searchValidations = [], setSearchValidations } = props;
    const getRenderDialogContent =
        (initialValues?: SearchValidation, i?: number) =>
        ({ closeDialog }) =>
            (
                <div style={{ margin: '1em', padding: '1em' }}>
                    <EditExpression
                        viewName={props.viewName}
                        initialValues={initialValues}
                        onSubmit={({ expression, message }) => {
                            const rowData = { expression, message };
                            if (typeof i === 'number') {
                                const newData = [
                                    ...searchValidations.slice(0, i),
                                    rowData,
                                    ...searchValidations.slice(i + 1),
                                ];
                                setSearchValidations(newData);
                                closeDialog();
                            } else {
                                setSearchValidations([...searchValidations, rowData]);
                                closeDialog();
                            }
                        }}
                    />
                </div>
            );
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Expression</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {searchValidations.map((sv, i) => (
                        <TableRow key={i}>
                            <TableCell>{sv.expression}</TableCell>
                            <TableCell>{sv.message}</TableCell>
                            <Popup
                                renderDialogContent={getRenderDialogContent(sv, i)}
                                renderToggler={({ openDialog }) => (
                                    <TableCell>
                                        <Button size="small" variant="contained" color="primary" onClick={openDialog()}>
                                            Edit
                                        </Button>
                                        &nbsp;&nbsp;
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSearchValidations([
                                                    ...searchValidations.slice(0, i),
                                                    ...searchValidations.slice(i + 1),
                                                ]);
                                            }}
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </TableCell>
                                )}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <br />
            <Popup
                renderDialogContent={getRenderDialogContent()}
                renderToggler={({ openDialog }) => (
                    <Button size="small" variant="contained" color="primary" onClick={openDialog()}>
                        Add
                    </Button>
                )}
            />
        </div>
    );
};

export default SearchValidationsEditor;
