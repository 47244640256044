import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const useProcessIsOpen = (processId: string) =>
    useSelector((state: RootState) => {
        return (
            processId &&
            state.bpm.processInstances.byId[processId] &&
            !state.bpm.processInstances.byId[processId].endTime
        );
    });
export default useProcessIsOpen;
