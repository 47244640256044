import React, { FunctionComponent, useState, useCallback, useMemo } from 'react';

type LayoutModeContext = {
    mode: 'old' | 'new';
    toggleContext: () => void;
};
export const layoutModeContext = React.createContext<LayoutModeContext>({
    mode: 'old',
    toggleContext: () => {
        throw new Error('No context provided');
    },
});

interface LayoutModeProviderProps {
    initial: LayoutModeContext['mode'];
}
export const LayoutModeProvider: FunctionComponent<LayoutModeProviderProps> = (props) => {
    const [mode, setMode] = useState(props.initial);
    const toggleContext = useCallback(() => {
        setMode(mode === 'new' ? 'old' : 'new');
    }, [mode, setMode]);
    const context = useMemo(() => {
        return {
            mode,
            toggleContext,
        };
    }, [toggleContext, mode]);
    return <layoutModeContext.Provider value={context}>{props.children}</layoutModeContext.Provider>;
};
