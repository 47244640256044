import { put, takeEvery } from 'redux-saga/effects';
import { LOAD_VIEW_CONFIG_SUCCESS } from 'viewConfig/constants';
import ViewConfig, { ComponentField } from '../reducers/ViewConfigType';
import { tryCatch, fromPredicate } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';
import { EntityValidations } from '../reducers/entityValidationsReducer';
import { extractValidationMetadata } from './util/getValidation';
import { getStorage } from 'storage/storage';
import { getValidationFromConfig } from 'expressions/formValidation/evaluateFromJson';
import {
    getAllFieldEntriesFromView,
    getViewNameFromComponentField,
    isComponentField,
} from 'components/generics/utils/viewConfigUtils';
import { parsingOrValidationErrMsg } from 'expressions/formValidation';

const getValidationString = (config: string) =>
    fromPredicate<string>(Boolean)(config)
        .chain((c) => tryCatch(() => JSON.parse(c)))
        .mapNullable((c) => c.validations)
        .map((v) => JSON.stringify(v))
        .toUndefined();

const getViewValidations = (
    viewName: string,
    rootEntity: string,
    viewConfig: ViewConfig,
    viewConfiguration: ReturnType<typeof getValidationFromConfig> | null,
    rebaseOntoRootPath?: string,
): (EntityValidations[string][number] & { __originalView?: string })[] => {
    const allFieldEntries = getAllFieldEntriesFromView(viewConfig, viewName);
    const componentEntries: [string, ComponentField][] = allFieldEntries.filter((t): t is [string, ComponentField] =>
        isComponentField(t[1]),
    );

    const componentValidations = componentEntries.reduce((prev, [componentFieldId, componentField]) => {
        const componentViewName = getViewNameFromComponentField(componentField);
        const componentViewConfig = getValidationString(viewConfig.views[componentViewName].config);
        const validationConf = componentViewConfig?.trim() ? getValidationFromConfig(componentViewConfig) : null;

        const newEntries = getViewValidations(
            componentViewName,
            rootEntity,
            viewConfig,
            validationConf,
            componentField.field,
        ).map((c) => ({
            ...c,
            __originalView: componentViewName,
        }));
        return prev.concat(newEntries);
    }, [] as (EntityValidations[string][number] & { __originalView?: string })[]);

    // TODO: now, must handle the base case.
    const rootValidations =
        viewConfiguration
            ?.mapLeft((error) => {
                console.error(`There is an invalid configuration on view "${viewName}". Error below:`);
                console.error(parsingOrValidationErrMsg(error));
                return error;
            })
            ?.map((validations) => {
                return extractValidationMetadata(validations, viewConfig, rootEntity, rebaseOntoRootPath);
            })
            ?.getOrElse([]) ?? [];

    return [...componentValidations.flat(), ...rootValidations];
};

const getViewValidationsFromViewConfig = function* () {
    yield takeEvery(
        LOAD_VIEW_CONFIG_SUCCESS,
        function* ({
            payload: { viewConfig },
        }: {
            type: typeof LOAD_VIEW_CONFIG_SUCCESS;
            payload: { viewConfig: ViewConfig };
        }) {
            const validResourceConfigs = mapOption(Object.entries(viewConfig.views), ([viewName, view]) => {
                const validationsStr = getValidationString(view.config);
                const validationsConfig = !validationsStr ? null : getValidationFromConfig(validationsStr);
                return tryCatch(() => {
                    const validations = getViewValidations(
                        viewName,
                        viewConfig.views[viewName].entity,
                        viewConfig,
                        validationsConfig,
                    );
                    return validations;
                })
                    .filter((validations) => !!validations?.length)
                    .map((validations) => ({
                        [viewName]: validations,
                    }));
            });
            const viewValidations: EntityValidations = Object.assign({}, ...validResourceConfigs);
            getStorage().setItem('viewValidations', JSON.stringify(viewValidations));
            yield put({ type: 'VIEW_VALIDATIONS_GENERATED', payload: viewValidations });
        },
    );
};
export default getViewValidationsFromViewConfig;
