import * as React from 'react';
import { TaskForm } from '../../../../../reducers/taskFormType';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { flowablePreprocessValuesForEval } from 'expressions/formValidation';
import useViewConfig from 'util/hooks/useViewConfig';
import useValueSets from 'util/hooks/useValueSets';
import useEntities from 'util/hooks/useEntities';
import useFormHasErrors from 'bpm/components/TaskDetail/TaskForm/hooks/useFormHasErrors';
import { formContext as taskFormContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';

const useEvaluateOutcomeExpression = (
    formDefinition: TaskForm,
    name: string,
    config: 'visibility' | 'editable',
    defaultTo: any = true,
) => {
    const outcomeButtonConfig = fromNullable(formDefinition.outcomes)
        .mapNullable((outcomes) => outcomes.find((o) => o.name && o.name.toLowerCase() === name.toLowerCase()))
        .mapNullable((saveOutcome) => saveOutcome.configs);

    const exp = outcomeButtonConfig
        .mapNullable((configs) => configs[config])
        .chain(fromPredicate<string>(Boolean))
        .getOrElse(defaultTo);
    // Reliance on 'record-form' key isn't great...
    // It works because 'record-form' always refers to the linked-entity,
    // due to only one task-form + linkedEntity form appearing on the base page at once.

    const entityFormHasErrors = useFormHasErrors('record-form');
    const entities = useEntities();
    const viewConfig = useViewConfig();
    const valueSets = useValueSets();
    const fc = React.useContext(taskFormContext);
    const context = React.useMemo(
        () =>
            flowablePreprocessValuesForEval(
                { ...fc.fieldValues, entityFormHasErrors },
                formDefinition.fields,
                entities,
                {
                    dateFormat: viewConfig && viewConfig.application && viewConfig.application.dateFormat,
                },
                valueSets,
            ),
        [fc.fieldValues, entityFormHasErrors, entities, valueSets, formDefinition, viewConfig],
    );

    const result = useSingleKeyCachingExpression(exp, context, defaultTo);
    return result;
};
export default useEvaluateOutcomeExpression;
