import { Theme } from '@material-ui/core/styles';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { makeStyles } from '@material-ui/core/styles';
import { WrappedFieldMetaProps } from 'redux-form';
import formatError from 'fieldFactory/util/formatError';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) => {
    const warnColor = theme.palette.warning.main;
    return {
        warnText: {
            color: `${warnColor}!important` as any,
        },
        outlinedInput: {
            '&$focused $notchedOutline': {
                border: '1px solid ' + warnColor,
            },
        },
        select: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${warnColor}!important` as any,
            },
            '&.MuiInput-underline.Mui-error:not(:hover):not($focused):after': {
                borderBottomColor: warnColor,
            },
            '&.MuiFilledInput-underline.Mui-error:after': {
                borderBottomColor: warnColor,
            },
        },
        notchedOutline: {
            borderColor: `${warnColor}!important` as any,
        },
        warnUnderline: {
            '&.Mui-error::after': {
                borderBottomColor: warnColor,
            },
        },
    };
});

const useWarningOrErrorUtils = ({
    touched,
    error,
    warning,
}: Partial<WrappedFieldMetaProps>): {
    textErrorClass?: string;
    inputUnderlineClass?: string;
    notchedOutlineClass?: string;
    outlinedInputClass?: string;
    helperText?: string;
    selectClass?: string;
    formHelperTextClasses?: {
        error: string;
    };
    muiErrorProp: boolean; // for 'error' props e.g. on TextInput, FormControl, etc. This is true if 'warning' as well as error, since we override error classes for warning styling.
} => {
    const touchedError: string | undefined = touched ? error : undefined;
    const touchedWarning: string | undefined = touched ? warning : undefined;
    const { warnText, warnUnderline, notchedOutline, outlinedInput, select } = useStyles();

    const { evaluateFormattedMessage, translate } = useEvaluateFormattedMessage();
    const errorHelperText = useMemo(
        () =>
            touchedError
                ? `${translate({ id: 'validate.error' })}: ${evaluateFormattedMessage(formatError(touchedError))}`
                : undefined,
        [touchedError, evaluateFormattedMessage, translate],
    );

    const warningHelperText = useMemo(
        () =>
            touchedWarning
                ? `${translate({ id: 'validate.warning' })}: ${evaluateFormattedMessage(formatError(touchedWarning))}`
                : undefined,
        [touchedWarning, evaluateFormattedMessage, translate],
    );

    // Inputs handle error styling on their own, so we can rely on their default classes if there's an error.
    // Expect the input component to handle setting the 'error' flags on the Mui components using 'muiErrorProp' so they know to change their state.
    // then we can pass these as class overrides to change the error colors to warning colors
    return {
        textErrorClass: touchedError ? undefined : touchedWarning ? warnText : undefined,
        selectClass: touchedError ? undefined : touchedWarning ? select : undefined,
        outlinedInputClass: touchedError ? undefined : touchedWarning ? outlinedInput : undefined,
        inputUnderlineClass: touchedError ? undefined : touchedWarning ? warnUnderline : undefined,
        notchedOutlineClass: touchedError ? undefined : touchedWarning ? notchedOutline : undefined,
        helperText: errorHelperText ?? warningHelperText,
        muiErrorProp: touched && (error || warning),
        formHelperTextClasses: touchedError
            ? undefined
            : touchedWarning
            ? {
                  error: warnText,
              }
            : undefined,
    };
};

export const WarningOrErrorUtils: React.FC<{
    meta: WrappedFieldMetaProps;
    children: (props: ReturnType<typeof useWarningOrErrorUtils>) => JSX.Element;
}> = ({ children, meta }) => {
    const utilProps = useWarningOrErrorUtils(meta);
    return children(utilProps);
};
export default useWarningOrErrorUtils;
