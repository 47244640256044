import React from 'react';
import { Card, CardContent, CardActions, Button } from '@material-ui/core';
import FileDownload from '@material-ui/icons/SaveAlt';

import Popup from 'components/Popup';

interface FileDownloadButtonProps {
    handleSubmit: () => void;
    longRunning: boolean;
    triggerValidation: () => void;
    valid: boolean;
}
const FileDownloadButton: React.FunctionComponent<FileDownloadButtonProps> = (props) => {
    const { longRunning, handleSubmit, valid, triggerValidation } = props;

    return (
        <div style={{ marginRight: '1em', paddingBottom: '1em' }}>
            {longRunning && (
                <Popup
                    renderToggler={({ openDialog }) => (
                        <Button
                            onClick={(e) => {
                                triggerValidation();
                                if (valid) {
                                    openDialog()();
                                }
                            }}
                        >
                            {props.children}&nbsp;
                            <FileDownload />
                        </Button>
                    )}
                    renderDialogContent={({ closeDialog }) => {
                        const submitReportAndClose = () => {
                            handleSubmit();
                            closeDialog();
                        };
                        return (
                            <Card>
                                <CardContent>
                                    This is a long running report and will be emailed to you when complete. Do you want
                                    to proceed?
                                </CardContent>
                                <CardActions>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        onClick={submitReportAndClose}
                                        style={{ marginRight: 15 }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={closeDialog}
                                        style={{ marginRight: 15 }}
                                    >
                                        No
                                    </Button>
                                </CardActions>
                            </Card>
                        );
                    }}
                />
            )}

            {!longRunning && (
                <Button type="submit" onClick={handleSubmit}>
                    {props.children}&nbsp;
                    <FileDownload />
                </Button>
            )}
        </div>
    );
};

export default FileDownloadButton;
