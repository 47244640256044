import { checkLastRequestTime, checkLastRequestTimeSuccess, checkLastRequestTimeFailure } from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap, withLatestFrom, delay, tap } from 'rxjs/operators';
import { of, concat, EMPTY } from 'rxjs';
import { tryAllRetriesNeedingAuth } from 'IndexedDB';
import isOffline from 'util/isOffline';

const ONE_MINUTE = 60 * 1000;
const RETRY_ON_FAILURE_INTERVAL = 0.5 * ONE_MINUTE;
const REFRESH_IF_NO_REQUEST_MADE_WITHIN = 4 * ONE_MINUTE;
const refreshTokenAfterNoRequestTimeFlow: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) =>
    isOffline()
        ? EMPTY
        : action$.pipe(
              filter(isActionOf(checkLastRequestTime)),
              withLatestFrom(state$.pipe(map((state: RootState) => state.reauth.lastAjaxSuccess))),
              filter(([action, checkLastRequestTime]) => {
                  return (
                      checkLastRequestTime <=
                      Date.now() -
                          ((window as any).CASETIVITY_REFRESH_IF_NO_REQUEST_MADE_WITHIN ||
                              REFRESH_IF_NO_REQUEST_MADE_WITHIN)
                  );
              }),
              flatMap(([action, lastRequestTime]) =>
                  services.refreshToken().pipe(
                      tap(() => {
                          tryAllRetriesNeedingAuth();
                      }),
                      map(() => checkLastRequestTimeSuccess()),
                      catchError((e) =>
                          !action.spawnLoopUntilSuccess
                              ? of(checkLastRequestTimeFailure(e))
                              : concat(
                                    of(checkLastRequestTimeFailure(e)),
                                    of(true).pipe(
                                        delay(
                                            (window as any).CASETIVITY_RETRY_ON_FAILURE_INTERVAL ||
                                                RETRY_ON_FAILURE_INTERVAL,
                                        ),
                                        map(() => checkLastRequestTime(true)),
                                    ),
                                ),
                      ),
                  ),
              ),
          );

export default refreshTokenAfterNoRequestTimeFlow;
