import PickViewDef from 'layout-editor/build-layout/steps/components/PickViewDef/PickViewDef';
import React, { useContext, useState } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { TransferChangesPayload } from './domain';
import { CheckboxesGroup } from './SelectTransferredProperties';
import produceCombinedView from './util/produceCombinedView';
import { viewDefWizardContext } from 'layout-editor/viewdef-editor/viewDefWizardContext';

interface TransferChangesProps extends TransferChangesPayload {}
const TransferChanges: React.FC<TransferChangesProps> = ({ toViewType, view }) => {
    const viewConfig = useViewConfig();
    const [selectedViewName, setSelectedViewName] = useState('');
    const ViewDefWizard = useContext(viewDefWizardContext);
    if (!view) {
        return null;
    }
    return (
        <div>
            <CheckboxesGroup
                initialState={
                    toViewType === 'CREATE'
                        ? {
                              concept: true,
                              editable: true,
                              vis: true,
                          }
                        : { action: false, vis: true }
                }
                render={(state, setDisabled) => {
                    return (
                        <div>
                            <PickViewDef
                                fixedFilter={{
                                    viewType: toViewType,
                                    entity: view?.entity,
                                }}
                                viewName={selectedViewName}
                                setViewName={(viewName) => {
                                    setSelectedViewName(viewName);
                                    setDisabled(Boolean(viewName));
                                }}
                            />
                            {selectedViewName &&
                                (() => {
                                    const produced = produceCombinedView({
                                        includeActionButtons: Boolean(state.action),
                                        includeConceptExpressions: Boolean(state.concept),
                                        includeEditabilityExpressions: Boolean(state.editable),
                                        includeVisibilityExpressions: Boolean(state.vis),
                                        noTabs: toViewType === 'CREATE',
                                        viewConfig,
                                        noXManys: toViewType === 'CREATE',
                                        inlineManys: toViewType === 'CREATE',
                                    })(view, viewConfig.views[selectedViewName]);
                                    return (
                                        <div style={{ margin: '1em' }}>
                                            <ViewDefWizard mode="UPDATE_EXISTING" initialValues={produced} />
                                        </div>
                                    );
                                })()}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default TransferChanges;
