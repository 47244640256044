import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getKey } from 'remoteStatus/one/reducer';

const useStatus = (resource: string, id: string) => {
    const status = useSelector((state: RootState) => {
        return id && state.getOneStatus[getKey(resource, id)];
    });
    return status;
};
export default useStatus;
