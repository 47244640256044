// from admin-on-rest
/* @link http://stackoverflow.com/questions/46155/validate-email-address-in-javascript */
import get from 'lodash/get';

const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

const isEmpty = (value) =>
    typeof value === 'undefined' || value === null || value === '' || (Array.isArray(value) && value.length === 0);

export const required = (value, _?: any, props?: any) => {
    return isEmpty(value) ? '%{validate.Required}' : undefined;
};

export const requiredNonEmptyArray = (value, _, props) => {
    return !value || value.length === 0 ? '%{validate.Required}' : undefined;
};

export const minLength = (min: number | string, message?: string) => (value, _, props) =>
    !isEmpty(value) && value.length < (parseInt as any)(min, 10)
        ? message || `%{validate.minLength,${min}}`
        : undefined;

export const maxLength = (max: number | string, message?: string) => (value, _?: any, props?: any) =>
    !isEmpty(value) && value.length > (parseInt as any)(max, 10)
        ? message || `%{validate.maxLength,${max}}`
        : undefined;

export const minValue =
    (min, message, convertTo: 'INTEGER' | 'FLOAT' = 'INTEGER') =>
    (value, _, props) => {
        if (!isEmpty(value) && !isNaN(value)) {
            const asNum = convertTo === 'INTEGER' ? parseInt(value, 10) : parseFloat(value);
            if (asNum < min) {
                return message || `%{validate.minValue,${min}}`;
            }
        }
        return undefined;
    };
export const maxValue =
    (max, message, convertTo: 'INTEGER' | 'FLOAT' = 'INTEGER') =>
    (value, _, props) => {
        if (!isEmpty(value) && !isNaN(value)) {
            const asNum = convertTo === 'INTEGER' ? parseInt(value, 10) : parseFloat(value);
            if (asNum > max) {
                return message || `%{validate.maxValue,${max}}`;
            }
        }
        return undefined;
    };

export const number = (value, _, props) =>
    !isEmpty(value) && isNaN(Number(value)) ? '%{validate.mustBeNumber}' : undefined;

export const regex = (pattern, message) => (value, _, props) =>
    !isEmpty(value) && typeof value === 'string' && !pattern.test(value) ? message : undefined;

export const email = regex(EMAIL_REGEX, '%{validate.mustBeEmail}');

export const choices = (list, message) => (value, _, props) =>
    !isEmpty(value) && list.indexOf(value) === -1 ? message : undefined;

export const getValidationInFormContext = (
    validation: (value: any, values: any, props: any) => string | undefined,
    fieldSource: string,
) => {
    return (
        value: any,
        values: any,
        props: { valuesForRegisteredFieldsOnly?: any; adjustedFormValues?: any; valuesAfterExpressionsApplied?: any },
    ): string | undefined => {
        // Edit forms use 'valuesForRegisteredFieldsOnly' as the prop key for adjusted 'Form Context' values
        if (props.valuesForRegisteredFieldsOnly) {
            const valueToApply = get(props.valuesForRegisteredFieldsOnly, fieldSource);
            return validation(valueToApply, props.valuesForRegisteredFieldsOnly, props);
        }
        // Create forms use 'adjustedFormValues'
        if (props.adjustedFormValues) {
            const valueToApply = get(props.adjustedFormValues, fieldSource);
            return validation(valueToApply, props.adjustedFormValues, props);
        }
        if (props.valuesAfterExpressionsApplied) {
            const valueToApply = get(props.valuesAfterExpressionsApplied, fieldSource);
            return validation(valueToApply, props.valuesAfterExpressionsApplied, fieldSource);
        }
        // run the validation against redux_form backing state.
        return validation(value, values, props);
    };
};
