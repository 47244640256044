import React, { useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText } from '@material-ui/core';
import SortDirField from 'layout-editor/add-field/components/SortDirField';
import FieldPath from 'layout-editor/add-field/components/FieldPath';

interface ConfigData {
    field?: string;
    direction?: 'ASC' | 'DESC';
}

interface FormData {
    fieldPath?: string;
    sortDir?: 'ASC' | 'DESC';
}

interface OverrideSecondarySortFormProps {
    rootEntity: string;
    onSubmit: (config: ConfigData) => void;
    initialValues?: Partial<ConfigData>;
}
const OverrideSecondarySortForm: React.FunctionComponent<OverrideSecondarySortFormProps> = (props) => {
    const defaultValues: FormData = useMemo(() => {
        if (props.initialValues) {
            return {
                fieldPath: props.initialValues.field || '',
                sortDir: props.initialValues.direction || 'ASC',
            };
        }
        return { field: '', sortDir: 'ASC' };
    }, [props.initialValues]);
    const methods = useForm<FormData>({
        defaultValues,
    });
    const onSubmit = (data: FormData) => {
        props.onSubmit({
            field: data.fieldPath,
            direction: data.sortDir,
        });
    };
    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <FieldPath
                        defaultValue={defaultValues && defaultValues['fieldPath']}
                        resource={props.rootEntity}
                        depth={3}
                    />
                    <ErrorMessage
                        render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                        errors={methods.errors}
                        name="fieldPath"
                    />
                    <SortDirField />
                    <div style={{ marginBottom: '5px' }} />
                    <input type="submit" value="Submit" />
                </form>
            </FormProvider>
        </div>
    );
};

export default OverrideSecondarySortForm;
