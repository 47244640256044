import * as React from 'react';
import { Component } from 'react';
import { createElement } from 'react';
import { Provider, connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { RootState } from './reducers/rootReducer';
import FieldFactoryProvider from './fieldFactory/FieldFactoryProvider';
import DateFormatProvider from './fieldFactory/dateFormat/DateFormatProvider';
import SsgResponsiveLayout from './components/layouts/SsgResponsiveLayout';
import DebugAndReportingHelper from './components/DebugAndReportingHelper';
import LBI from './components/LoadBasicInfo';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { routerHistory } from 'configureStore';
import PasswordReset from 'auth/password-reset/components/SelfReset';
import ConsoleErrorCount from 'components/ConsoleErrorCount';
import FormSaveNotifierProvider from 'formSaveNotifier/components/Provider';
import WithEnvConfigGlobalStylesheet from 'components/WriteEnvConfigGlobalStylesheet';
import store from 'configureStore/store';
import WindowTitle from 'components/WindowTitle';
import Layout from 'components/layouts/NewLayout';
import { LayoutModeProvider, layoutModeContext } from 'components/layouts/LayoutMode';
import customRoutes from './routes/customRoutes';
import DashboardPage from 'dashboard2/components/DashboardPage';
import ThemeOverrideProvider from 'components/layouts/ThemeOverrideProvider';
import OfflineApp from 'offline_app';
import LayoutRoot, { ThemeRoot } from 'components/layouts/LayoutRoot';
import MfaConfirm from 'mfa/MfaConfirm';
import QR from 'mfa/totp/QR';
import { TemplatableLogin } from 'auth/components/CustomLogin';
import { parse, stringify } from 'query-string';
import RegistrationConfirmationPage from 'auth/registration/RegistrationConfirmationPage';
import { useCurrentMessages, useLocale } from 'i18n/useMessages';
import { IntlProvider } from 'react-intl';
import ExpressionEvalProvider from 'expressions/Provider';
import { getDefault } from 'reducers/basicInfoReducer';
import { getMapRequests } from 'util/applicationConfig';
import { clearRegistry, registerUrlRewrite } from 'sideEffect/url-rewrite-registry/registry';
import useGoogleAnalytics from 'analytics/useGoogleAnalytics';
import useOverrideHomepageComponent from 'components/layouts/hooks/useOverrideHomepageElement';
import { setCurrentLang } from 'sideEffect/language-registry/registry';
import OfflinePinEntryPopup from 'offline_app/offlinePinEntryPopup/OfflinePinEntryPopup';
import isOffline from 'util/isOffline';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { viewDefWizardContext } from 'layout-editor/viewdef-editor/viewDefWizardContext';
import ViewDefWizard from 'layout-editor/viewdef-editor/ViewDefWizard';

class ScrollToTopComponent extends Component<{
    pathname: string;
}> {
    componentDidUpdate(prevProps: { pathname: string }) {
        if (this.props.pathname !== prevProps.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}
const LoadBasicInfo = LBI;
const ScrollToTop = connect((state: RootState) => {
    return {
        pathname: state.router.location.pathname,
    };
})(ScrollToTopComponent);

type MFAState = 'email' | 'totp_needs_reg' | 'totp_reg' | 'finished';
const initialMfaState: MFAState = (() => {
    if ((window as any).CASETIVITY_MFA === 'totp') {
        if ((window as any).CASETIVITY_MFA_REGISTRATION) {
            return 'totp_needs_reg';
        } else {
            return 'totp_reg';
        }
    } else if ((window as any).CASETIVITY_MFA === 'email') {
        return 'email';
    }
    return 'finished';
})();

export const InnerApp = (props) => {
    const layoutMode = React.useContext(layoutModeContext);
    const [mfaState, setMfaState] = React.useState<'email' | 'totp_needs_reg' | 'totp_reg' | 'finished'>(
        initialMfaState,
    );
    const messages = useCurrentMessages();
    const locale = useLocale();
    useGoogleAnalytics();
    const Homepage = useOverrideHomepageComponent() ?? DashboardPage;
    return (
        <>
            <ExpressionEvalProvider>
                <viewDefWizardContext.Provider value={ViewDefWizard}>
                    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateFormatProvider>
                                <FieldFactoryProvider>
                                    <ConnectedRouter history={routerHistory}>
                                        <QueryParamProvider
                                            adapter={ReactRouter5Adapter}
                                            options={{
                                                searchStringToObject: parse,
                                                objectToSearchString: stringify,
                                            }}
                                        >
                                            <LoadBasicInfo>
                                                <FormSaveNotifierProvider>
                                                    <WithEnvConfigGlobalStylesheet />
                                                    <WindowTitle />
                                                    <ThemeRoot>
                                                        <OfflinePinEntryPopup />
                                                        <ScrollToTop>
                                                            {mfaState === 'email' ? (
                                                                <MfaConfirm
                                                                    promptElement="Please enter the confirmation code sent to your email."
                                                                    onSuccess={() => setMfaState('finished')}
                                                                />
                                                            ) : mfaState === 'totp_reg' ? (
                                                                <MfaConfirm
                                                                    promptElement="Please enter the code in your Authenticator app."
                                                                    onSuccess={() => setMfaState('finished')}
                                                                />
                                                            ) : mfaState === 'totp_needs_reg' ? (
                                                                <QR
                                                                    onComplete={() => setMfaState('totp_reg')}
                                                                    promptElement="Scan the QR Code to register using the Google Authenticator app."
                                                                />
                                                            ) : (
                                                                <div style={{ height: '100%' }}>
                                                                    <Switch>
                                                                        <Route
                                                                            exact={true}
                                                                            path="/login"
                                                                            render={({ location }) =>
                                                                                createElement(TemplatableLogin, {
                                                                                    location,
                                                                                })
                                                                            }
                                                                        />
                                                                        <Route
                                                                            exact={true}
                                                                            path="/registration/confirm"
                                                                            render={({ location }) => {
                                                                                const login = location.search
                                                                                    ? (parse(location.search)
                                                                                          ?.login as string)
                                                                                    : undefined;
                                                                                return (
                                                                                    <RegistrationConfirmationPage
                                                                                        login={login}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                        <Route
                                                                            path="/reset/finish"
                                                                            render={({ location }) =>
                                                                                createElement(PasswordReset, {
                                                                                    location,
                                                                                })
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="/"
                                                                            render={() =>
                                                                                isOffline() ? (
                                                                                    <LayoutRoot>
                                                                                        <OfflineApp />
                                                                                    </LayoutRoot>
                                                                                ) : layoutMode.mode === 'new' ? (
                                                                                    <Layout />
                                                                                ) : (
                                                                                    createElement(SsgResponsiveLayout, {
                                                                                        dashboard: Homepage,
                                                                                        customRoutes,
                                                                                    })
                                                                                )
                                                                            }
                                                                        />
                                                                    </Switch>
                                                                    <div
                                                                        style={{
                                                                            position: 'fixed',
                                                                            minWidth: '2em',
                                                                            minHeight: '2em',
                                                                            bottom: 0,
                                                                            right: 0,
                                                                            zIndex: 2147483647,
                                                                        }}
                                                                    >
                                                                        <span style={{ display: 'inline-flex' }}>
                                                                            <ConsoleErrorCount />
                                                                            <DebugAndReportingHelper />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </ScrollToTop>
                                                    </ThemeRoot>
                                                </FormSaveNotifierProvider>
                                            </LoadBasicInfo>
                                        </QueryParamProvider>
                                    </ConnectedRouter>
                                </FieldFactoryProvider>
                            </DateFormatProvider>
                        </MuiPickersUtilsProvider>
                    </IntlProvider>
                </viewDefWizardContext.Provider>
            </ExpressionEvalProvider>
        </>
    );
};

(() => {
    (window as any).casetivity_store = store;
    /*
        Set up url rewrites using viewConfig currently stored in localstorage, and basicInfo.
        basicInfo contains the configured rewrites, and viewConfig contains user properties
        that might be used in expressions, like the user's current role.
    */
    const { viewConfig } = store.getState();
    clearRegistry();
    getMapRequests(getDefault())?.forEach(({ match, to }) => {
        registerUrlRewrite({ user: viewConfig.user })({ regexp: match, rewrite: to });
    });

    store.subscribe(() => {
        setCurrentLang(store.getState().locale);
    });
})();

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <ThemeOverrideProvider>
                    <LayoutModeProvider initial="old">
                        <InnerApp />
                    </LayoutModeProvider>
                </ThemeOverrideProvider>
            </Provider>
        );
    }
}

export default App;
