import React, { useMemo, useContext, FunctionComponent } from 'react';
// import ReactTooltip from 'react-tooltip';
import { getRefEntityName } from '../../components/generics/utils/viewConfigUtils/index';
import * as fieldTypes from '../fieldTypes';
import FileUpload from './components/FileUpload/index';
import ProcessFileUpload from './components/ProcessFileUpload';
import ChoicesSelect from './components/ChoicesSelect';
import RefManyMultiSelectIdsList from './components/RefmanyMultiselectIdsList';
import { RefProviderProps } from './components/RefmanyMultiselectIdsList/EntityInputProps';
import HardReference from './components/HardReference';
import { getCustomViewName } from 'components/generics/utils/viewConfigUtils';
import RadioSelect from './components/ChoicesRadio';
import DateTimePicker from './components/DateTimePicker';
import PopoverRefInput from '../popovers/PopoverRefInput';
import Checkbox from './components/Checkbox';
import bindFieldToOtherForm from './components/hoc/bindFieldToOtherForm';
import ListSelect from './components/ListSelect';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import PrintTemplateNameLookupField from 'printTemplate/component/PrintTemplateField';
import EditableTable from './components/EditableTable/index';
import SelectOneOf from './components/SelectOneOf';
import ViewConfigType from '../../reducers/ViewConfigType';
import { Field, FlowableDataField, FlowableTableField } from '../translation/types/index';
import { DataSource } from '../translation/types/DataSource';
// added for valuesetList from flowable expression
import { connect } from 'react-redux';
import ValueSetListField, { ValueSetListFieldComponent } from '../display/components/ValueSetListField';
import labelField from '../../components/generics/utils/labelField';
import WYSIWYG from './components/HtmlWysiwyg';
import TextInput from './components/TextInput';
import LongTextInput from './components/LongTextInput';
import ValueSelectDownshift from './components/ValueSelectDownshift';
import ValuesetManySelectDownshift from './components/ValuesetManySelectDownshift';
import expressionElement from '../display/expressionElement';
import getPopoverAddress from './components/Address/getPopoverAddress';
import MultiCheckbox from './components/MultiCheckbox';
import getValuesetCheckbox from './components/ValuesetOneCheckbox/getValuesetCheckbox';
import BooleanInput from './components/BooleanInput';
import get from 'lodash/get';
import {
    isDisabledEntityField,
    hasConfiguredEntity,
    isEntityDataField,
    isFieldFromFlowable,
    getConfigProperty,
    isRequired,
    getFlowableFieldConfigProperty,
    getImageWidth,
    simpleGetConfProperty,
    getNumChars,
} from './fieldUtils';
import FormHelperTextNoErr from '../input/components/TextField/FormHelperTextNoErr';
import memoizeOne from 'memoize-one';
import { formContext as taskFormContext } from '../../bpm/components/TaskDetail/TaskForm/FormContext';
import { getDirection } from './fieldUtils/checkboxAndRadio';
import {
    getViewName,
    getExpansions,
    getShowViewName,
    getEditViewName,
    gethasNoSearch,
    getCreateViewName,
    getOpenTo,
} from './fieldUtils/reference';
import BigCalendar from './components/BigCalendar';
import Currency from './components/Currency';
import { getLabel } from './fieldUtils';
import getListSelectProps from './components/ListSelect/getListSelectProps';
import GetPossibleMatchView from './components/PossibleMatches/getPossibleMatchView';
import NumberInput from './components/NumberInput';
import getEventFieldGroup from './components/Event/getEvent';
import { Subtract } from 'utility-types';
import { getAriaSupportProperties } from '../ariaSupport';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { injectErrorsToAllFieldsContext } from 'components/generics/form/forceInjectErrorsForAllFieldsContext';
import { Typography, useTheme } from '@material-ui/core';
import { processContext } from 'bpm/components/processContext';
import { RootState } from 'reducers/rootReducer';
import DmsDoc from './components/DmsDoc';
import NullableBoolean from './components/NullableBoolean';
import ValuesetSuggest from './components/ValuesetSuggest/Controller';
import { tableRowContext, getTableRowContext, TableRowContext } from './components/EditableTable/util/tableRowContext';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import getExpansionsFromFilter from 'isomorphic-query-filters/expand';
import ServerTemplatedPrintTemplateField from 'printTemplate/serverTemplating/component/ServerTemplatedPrintTemplateField';
import {
    printTemplatePrefix,
    printTemplatePdfPrefix,
    printTemplateReportPrefix,
} from 'fieldFactory/util/expressionConstants';
import TimeInput from './components/TimePicker';
import formTypeContext from 'components/generics/form/formTypeContext';
import getDisplayAddressVerif from './components/Address/getDisplayAddress';
import ValidationsEditorField from './components/ValidationExpressionEditor';
import { Field as RFField, BaseFieldProps } from 'redux-form';
import { isEvent } from 'fieldFactory/input/components/DebouncedTextInput';
import trimChars from 'util/trimChars';
import AuthCodeInput from './components/AuthCodeInput';
import RecaptchaInput from './components/Recaptcha/Recaptcha';
import ValuesetSelectForSearchController from './components/ValuesetSelectForSearchController/ValuesetSelectForSearchController';
import WizardControl from './components/WizardControl/WizardControl';
import withTooltip from 'fieldFactory/util/withTooltip';
import useWarningOrErrorUtils from './hooks/useWarningOrErrorUtils';
import getEntityTypeahead from './components/EntityTypeahead/getEntityTypeahead';
import isOffline from 'util/isOffline';
import getVideoWatched from './components/VideoWatched/getVideoWatched';
import JsonSchemaForm from './components/JsonSchemaForm/JsonSchemaForm';
import JsonSchemaFormBuilder from './components/JsonSchemaFormBuilder';
import USTDayInput from './components/DayUSTInput/DayUSTInput';
import ScrollToFieldFlag from 'fieldFactory/ScrollToField/ScrollToFieldFlag';
import Address2 from './components/Address2/Address2';
import getAddressWidgetProps from './components/Address2/getAddressWidgetProps';
import { withEvaluatedFilter } from './inputHigherOrderComponents/withEvaluatedFilter';
import { withNullFilteredRefoneOverride } from './inputHigherOrderComponents/withNullFilteredRefoneOverride';
import type { Input } from './inputHigherOrderComponents/withNullFilteredRefoneOverride';
import EvaluateInEntityContext from 'expressions/components/EvaluateExpressionInEntityContext';
import ControlledMultiRelativeDate from './components/RelativeDate/MultiRelativeDateField';
import ComponentPlaceholder from 'fieldFactory/display/components/ComponentPlaceholder';
const NO_FILE_UPLOAD_OFFLINE_MSG = 'File uploads are not available offline';

type Meta = any;

const getConceptIdsFromContexts = (
    c: TableRowContext,
    fc: ReturnType<typeof evaluateContext2>,
    source: string,
    fieldInstanceIdentifier?: string,
) => {
    const availableConceptsEntry = (() => {
        if (c) {
            const currentTableRowContext = getTableRowContext(c, fc);
            return (
                (currentTableRowContext && currentTableRowContext.valuesetFieldAvailableConceptIds[source]) ??
                currentTableRowContext.valuesetFieldAvailableConceptIds[fieldInstanceIdentifier]
            );
        }
        return (
            fc.valuesetFieldAvailableConceptIds[source] ?? fc.valuesetFieldAvailableConceptIds[fieldInstanceIdentifier]
        );
    })();
    const conceptIds =
        availableConceptsEntry && availableConceptsEntry !== '*' ? Object.keys(availableConceptsEntry) : undefined;
    return {
        availableConceptsEntry,
        conceptIds,
    };
};

const withConceptIdsOverride = (from: 'Flowable' | 'Entity') => (BaseComponent) => {
    return class WithConceptIdsOverride extends React.Component<{
        input: Input;
        source: string;
        fieldInstanceIdentifier?: string;
    }> {
        emptyArray = [];
        getInput = memoizeOne((overrideValue) => {
            return {
                ...this.props.input,
                value: overrideValue,
            };
        });
        getConceptIds = memoizeOne((conceptIdsStr: string) => {
            if (conceptIdsStr === '') {
                return [];
            }
            return conceptIdsStr.split(',');
        });
        getOverriddenEmptyValue = () => {
            return this.isMany() ? this.emptyArray : null;
        };
        isMany = () => this.props.source.endsWith('Ids');
        render() {
            const props = this.props;
            const formContext = from === 'Flowable' ? taskFormContext : entityFormContext;
            return (
                <tableRowContext.Consumer>
                    {(c) => {
                        return (
                            <formContext.Consumer>
                                {(fc) => {
                                    const source = props.source.endsWith('Id')
                                        ? props.source.slice(0, -2)
                                        : this.isMany()
                                        ? props.source.slice(0, -3)
                                        : props.source;
                                    const { conceptIds, availableConceptsEntry } = getConceptIdsFromContexts(
                                        c,
                                        fc,
                                        source,
                                        props.fieldInstanceIdentifier,
                                    );

                                    const overrideValue = (() => {
                                        if (this.isMany()) {
                                            if (!props.input.value) {
                                                return this.emptyArray;
                                            }
                                            if (
                                                conceptIds &&
                                                availableConceptsEntry !== '*' &&
                                                Array.isArray(props.input.value)
                                            ) {
                                                // we need to filter value
                                                return props.input.value.filter(
                                                    (conceptId) => availableConceptsEntry[conceptId],
                                                );
                                            }
                                            return props.input.value;
                                        }
                                        return conceptIds &&
                                            availableConceptsEntry !== '*' &&
                                            !availableConceptsEntry[props.input.value]
                                            ? this.getOverriddenEmptyValue()
                                            : props.input.value;
                                    })();

                                    const newInputProp = this.getInput(overrideValue);

                                    return (
                                        <BaseComponent
                                            {...props}
                                            input={newInputProp}
                                            conceptIds={conceptIds && this.getConceptIds(conceptIds.join(','))}
                                        />
                                    );
                                }}
                            </formContext.Consumer>
                        );
                    }}
                </tableRowContext.Consumer>
            );
        }
    };
};

const withInputFromFormContextValues = (BaseComponent) => (props) => {
    const trContext = useContext(tableRowContext);
    const fc = useContext(taskFormContext);
    const valueInFormContext = fc.fieldValues[props.source];
    const input = useMemo(() => {
        return {
            ...props.input,
            value: valueInFormContext,
        };
    }, [valueInFormContext, props.input]);
    if (trContext) {
        // this adjustment of fieldValues has already been handled by overrideFieldValueIfDisabled
        // really this hoc can be made redudant if we always do correction in overrideFieldValueIfDisabled,
        // so using formContext values in the 'non-disabled' case as well (i.e. always use get(formContext.fieldValues, source) to overwrite input.value)
        return <BaseComponent {...props} />;
    }
    return <BaseComponent {...props} input={input} />;
};
const withOptionsOverride = (BaseComponent) => {
    return class WithOptionsOverride extends React.Component<{ input: Input; source: string }> {
        getInput = memoizeOne((overrideValue) => {
            return {
                ...this.props.input,
                value: overrideValue,
            };
        });
        render() {
            const props = this.props;
            return (
                <taskFormContext.Consumer>
                    {({ availableOptions, fieldValues }) => {
                        const availableOptionsEntry = availableOptions[props.source];
                        let input = props.input;
                        if (availableOptionsEntry) {
                            input = this.getInput(fieldValues[props.source]);
                        }
                        return <BaseComponent {...props} input={input} />;
                    }}
                </taskFormContext.Consumer>
            );
        }
    };
};

export const overrideFieldValueIfDisabled = (type: 'Flowable' | 'Entity') => (BaseComponent) => {
    const WithFieldValueOverriddenIfDisabled: FunctionComponent<{
        input: BaseFieldProps;
        isHardDisabled?: boolean;
        disabled?: boolean;
        source: string;
        fieldInstanceIdentifier?: string;
    }> = (props) => {
        const { source, disabled, isHardDisabled, fieldInstanceIdentifier } = props;
        const { name, onChange, onBlur, onDragStart, onDrop, onFocus } = props.input || {};
        const getDisabledInput = useMemo(() => {
            return memoizeOne((realValue) => {
                return {
                    name,
                    onDragStart,
                    onDrop,
                    onFocus,
                    onChange,
                    onBlur,
                    value: realValue,
                };
            });
        }, [name, onChange, onBlur, onDragStart, onDrop, onFocus]);
        const formContext = type === 'Flowable' ? taskFormContext : entityFormContext;
        return (
            <tableRowContext.Consumer>
                {(c) => {
                    return (
                        <formContext.Consumer>
                            {(fc) => {
                                const disabledByExpression = (() => {
                                    if (c) {
                                        // we know we are in Task context
                                        const currentTableRowContext = getTableRowContext(
                                            c,
                                            fc as ReturnType<typeof evaluateContext2>,
                                        );
                                        return (
                                            currentTableRowContext &&
                                            !!currentTableRowContext.disabledFields[fieldInstanceIdentifier || source]
                                        );
                                    }

                                    return (
                                        !!fc.disabledFields[fieldInstanceIdentifier || source] ||
                                        // below: workaround for old configuration where 'Id' used to be appended to expression keys
                                        // (broken by https://src.casetivity.com/ssg/casetivity-front-end/-/commit/5e1de4ee9134cd1a1d7d3b4e1fd6c6aadb7cc596)
                                        !!(
                                            fieldInstanceIdentifier &&
                                            source?.endsWith('Id') &&
                                            !fieldInstanceIdentifier.endsWith('Id') &&
                                            fc.disabledFields[fieldInstanceIdentifier + 'Id']
                                        )
                                    );
                                })();
                                const disable = disabled || disabledByExpression;
                                const input = (() => {
                                    if (c) {
                                        const currentTableRowContext = getTableRowContext(
                                            c,
                                            fc as ReturnType<typeof evaluateContext2>,
                                        );
                                        return getDisabledInput(
                                            currentTableRowContext && get(currentTableRowContext.fieldValues, source),
                                        );
                                    }
                                    return disabledByExpression
                                        ? getDisabledInput(get(fc.fieldValues, source))
                                        : props.input;
                                })();
                                return (
                                    <BaseComponent
                                        {...props}
                                        isHardDisabled={isHardDisabled || (type === 'Flowable' && disabled)}
                                        input={input}
                                        disabled={disable}
                                        key={disable ? 'disabled' : 'editable'}
                                    />
                                );
                            }}
                        </formContext.Consumer>
                    );
                }}
            </tableRowContext.Consumer>
        );
    };
    return WithFieldValueOverriddenIfDisabled;
};
/*
    Only map warnings onto 'meta.error' for flowable task related forms.
    We did this originally in order to allow a 'save' type submission even if there are validation errors,
    by registering field-level validations as warnings.

    This hoc also allows 'injected' errors or warnings which are passed to all fields: this is just for testing that we are rendering them properly.
*/
const mapWarningsToErrors = (isFlowable: boolean) => (BaseComponent) => {
    return ({ meta = {}, ...props }: { meta: Meta }) => {
        const { error: injected, WARN_PREFIX } = useContext(injectErrorsToAllFieldsContext);
        const injectedError: string | undefined = injected && !injected.startsWith(WARN_PREFIX) ? injected : undefined;
        const injectedWarning: string | undefined =
            injected && injected.startsWith(WARN_PREFIX) ? injected.slice(WARN_PREFIX.length) : undefined;
        const formType = useContext(formTypeContext);
        // because we want to show errors right away on EDIT type forms
        const touched = formType === 'EDIT' ? true : meta.touched;
        const newMeta = useMemo(() => {
            if (!isFlowable) {
                return {
                    ...meta,
                    touched,
                    error: meta.error || injectedError,
                    warning: meta.warning || injectedWarning,
                };
            }
            // in flowable,
            return {
                ...meta,
                touched,
                // we map warnings onto the error field
                error: meta.error || meta.warning || injectedError || undefined,
            };
        }, [touched, meta, injectedError, injectedWarning]);

        return <BaseComponent {...props} meta={newMeta} />;
    };
};

const appendRequiredLabel = (
    viewConfig: ViewConfigType,
    fieldDefinition: Field,
    liveProps: any,
    overrideLabel?: string,
) => {
    const label = overrideLabel ?? fieldDefinition.label;
    if (label === 'Radio') {
        console.warn(`my label: ${label}`);
        console.warn(fieldDefinition);
    }

    return label &&
        !liveProps.neverShowRequiredAsterisk &&
        (isRequired(viewConfig, fieldDefinition) || fieldDefinition.name === 'Radio') &&
        !label.endsWith('*')
        ? label + ' *'
        : label;
};

const makeConsoleError = (msg: string) => () => {
    console.error(msg);
    return undefined;
};

const getLayoutParam = (
    fieldDefinition: Field,
    name: 'row' | 'column' | 'span',
    defaultValue = name === 'row' ? 1000 : name === 'span' ? 8 : 0,
) => {
    return isFieldFromFlowable(fieldDefinition)
        ? fieldDefinition.params && fieldDefinition.params[name]
            ? +fieldDefinition.params[name]
            : defaultValue
        : fieldDefinition[name];
};

const withoutIdOrIdsPostfix = (source) =>
    source.endsWith('Ids') ? source.slice(0, -3) : source.endsWith('Id') ? source.slice(0, -2) : source;

export default (
    // inner field component level
    fieldDefinition: Field,
    viewConfig: ViewConfigType,
    configuration,
    liveProps,
) => {
    let overrideTooltipText: string;
    const t = fieldTypes;
    let RComponent;
    const row = getLayoutParam(fieldDefinition, 'row', configuration?.getDefaultRow?.());
    const column = getLayoutParam(fieldDefinition, 'column', configuration?.getDefaultCol?.());
    const ariaSupportProps = getAriaSupportProperties(row, column, fieldDefinition);
    const overrideAriaLabel = simpleGetConfProperty<{ overrideAriaLabel?: string }>(
        'overrideAriaLabel',
        undefined,
    )(fieldDefinition);
    /**
     * So far, readOnlyIfDisabled implemented only for valueset-dropdown
     */
    const readOnlyIfDisabled = simpleGetConfProperty<{ readOnlyIfDisabled?: boolean }>(
        'readOnlyIfDisabled',
        undefined,
    )(fieldDefinition);
    const ariaInputProps = {};
    let renderLabel = true;
    if (ariaSupportProps && ariaSupportProps.labelledBy) {
        ariaInputProps['aria-labelledby'] = ariaSupportProps.labelledBy;
        renderLabel = false;
    }
    // used to be GenericProps & any
    // changed to any for now - needs refactoring.
    let propConfiguration: any = {
        'data-originaldefinition': fieldDefinition['data-originaldefinition'],
        row,
        column,
        span: getLayoutParam(fieldDefinition, 'span', configuration?.getDefaultSpan?.()),
        source: fieldDefinition.name,
        label: appendRequiredLabel(viewConfig, fieldDefinition, liveProps),
        searchType: isEntityDataField(fieldDefinition) ? fieldDefinition.searchType : undefined,
        ariaInputProps,
        renderLabel,
        readOnlyIfDisabled,
        options: {
            fullWidth: true,
        },
        elStyle: { width: '100%' },
    };
    if (liveProps.noPad) {
        propConfiguration.noPad = liveProps.noPad;
    }
    if (overrideAriaLabel) {
        // we will change the aria-label, but we also need to pass this as a prop to components
        // so they can override their internal handling (e.g. Downshift components with menus).
        propConfiguration.overrideAriaLabel = overrideAriaLabel;
    }
    if (isEntityDataField(fieldDefinition)) {
        getConfigProperty<{ defaultValue: string }>('defaultValue')(fieldDefinition).fold(null, (defaultValue) => {
            propConfiguration.defaultValue = defaultValue;
        });
    }

    if (fieldDefinition.type === fieldTypes.ADDRESS_VERIFICATION) {
        // console.log(fieldDefinition);
        if (configuration.nonEditableAddressWidget || liveProps.nonEditableAddressWidget) {
            // do this to prevent errors, for example on Edit form layout-editor, where the full widget crashes.
            // we display a placeholder instead.
            return getDisplayAddressVerif(propConfiguration, fieldDefinition, liveProps);
        }
        return getPopoverAddress(propConfiguration, fieldDefinition, liveProps, viewConfig);
    }

    if (fieldDefinition.type === fieldTypes.HTML_EXPRESSION) {
        return expressionElement(
            fieldDefinition,
            {
                ...propConfiguration,
                ...liveProps,
                id: `expression:r${row}c${column}`,
            },
            fieldDefinition._dataSource,
        );
    }

    switch (fieldDefinition.type) {
        case t.ADDRESS_VERIFICATION_2: {
            RComponent = Address2;
            propConfiguration = {
                ...propConfiguration,
                ...getAddressWidgetProps(fieldDefinition),
            };
            break;
        }
        case t.TEXT:
            RComponent = TextInput;
            if (isEntityDataField(fieldDefinition)) {
                // the full path doesn't start on "fieldDefinition.configuredEntity"
                // - let's just get the last property in the chain
                const propertyOnConfiguredEntity = fieldDefinition.name?.split('.')?.pop();
                const fieldConfigMask =
                    viewConfig.entities[fieldDefinition.configuredEntity]?.fields?.[propertyOnConfiguredEntity]?.mask;
                propConfiguration.mask = getConfigProperty('mask')(fieldDefinition).getOrElse(
                    fieldConfigMask || undefined,
                );
            } else if (fieldDefinition.params && fieldDefinition.params.mask) {
                propConfiguration.mask = fieldDefinition.params.mask;
            }
            if (liveProps.isForSearch) {
                const normalize = (value) => {
                    /*
                        Normalize values allowed in TextInput in search here! E.g. remove ascii
                    */
                    value = trimChars(value, /[\040-\176]/);
                    return value;
                };
                RComponent = (props) => {
                    const input = React.useMemo(() => {
                        return {
                            ...props.input,
                            onChange: (eventOrValue) => {
                                const value = isEvent(eventOrValue) ? eventOrValue.target.value : eventOrValue;
                                props.input.onChange(normalize(value));
                            },
                            onBlur: (eventOrValue) => {
                                const value = isEvent(eventOrValue) ? eventOrValue.target.value : eventOrValue;
                                props.input.onBlur(normalize(value));
                            },
                        };
                    }, [props.input]);
                    return <TextInput {...props} input={input} normalizeState={normalize} />;
                };
            }
            break;
        case t.MULTILINE_TEXT:
            RComponent = LongTextInput;
            break;
        case t.FLOAT:
            RComponent = (props) => <NumberInput isInteger={false} {...props} />;
            break;
        case t.INTEGER:
            RComponent = (props) => <NumberInput isInteger={true} {...props} />;
            break;
        case t.RECAPTCHA:
            const overrideSiteKey = simpleGetConfProperty<{ siteKey: string }>('siteKey', '')(fieldDefinition);
            if (overrideSiteKey) {
                propConfiguration.siteKey = overrideSiteKey;
            }
            RComponent = RecaptchaInput;
            break;
        case t.TOGGLE:
            RComponent = BooleanInput;
            propConfiguration = {
                ...propConfiguration,
                defaultValue: false,
            };
            break;
        case t.RELATIVE_DATE:
            RComponent = ControlledMultiRelativeDate;
            break;
        case t.UST_DAY:
            RComponent = USTDayInput;
            propConfiguration = {
                ...propConfiguration,
                mode: 'ISO',
            };
            break;
        case t.TIME:
            RComponent = TimeInput;
            break;
        case t.DATETIME:
            RComponent = DateTimePicker;
            break;
        case t.WIZARD_CONTROL:
            RComponent = WizardControl;
            propConfiguration.steps = simpleGetConfProperty<{ steps: string | number }>('steps', 1)(fieldDefinition);
            propConfiguration.nextButtonText = simpleGetConfProperty<{ nextButtonText?: string }>(
                'nextButtonText',
                undefined,
            )(fieldDefinition);
            propConfiguration.validateBeforeContinue = simpleGetConfProperty<{ validateBeforeContinue?: boolean }>(
                'validateBeforeContinue',
                true,
            )(fieldDefinition);
            propConfiguration.backButtonText = simpleGetConfProperty<{ backButtonText?: string }>(
                'backButtonText',
                undefined,
            )(fieldDefinition);
            if (typeof propConfiguration.steps === 'string') {
                propConfiguration.steps = parseInt(propConfiguration.steps);
            }
            propConfiguration.nextStepExpression = simpleGetConfProperty<{ nextStepExpression: string }>(
                'nextStepExpression',
                undefined,
            )(fieldDefinition);
            break;
        case t.DATE:
            RComponent = USTDayInput;
            const hideCalendarButton = simpleGetConfProperty<{ hideCalendar?: Boolean }>(
                'hideCalendar',
                false,
            )(fieldDefinition);
            propConfiguration.hideCalendarButton = hideCalendarButton;
            break;
        case t.NULLABLE_BOOLEAN:
            RComponent = NullableBoolean;
            break;
        case t.ZONE_DATE:
            RComponent = (props) => <div>ZONE_DATE is unsupported.</div>;
            break;
        case t.IMAGE:
            RComponent = FileUpload;
            propConfiguration.isImage = true;
            propConfiguration.imageWidth = getImageWidth(fieldDefinition);
            if (isOffline()) {
                propConfiguration.disabled = true;
                overrideTooltipText = NO_FILE_UPLOAD_OFFLINE_MSG;
            }
            break;
        case t.AUTH_CODE:
            propConfiguration.numChars = getNumChars(fieldDefinition);
            RComponent = AuthCodeInput;
            break;
        case t.FILE_UPLOAD:
            if (isOffline()) {
                propConfiguration.disabled = true;
                overrideTooltipText = NO_FILE_UPLOAD_OFFLINE_MSG;
            }
            RComponent = FileUpload;
            break;
        case t.PROCESS_FILE_UPLOAD:
            if (isOffline()) {
                propConfiguration.disabled = true;
                overrideTooltipText = NO_FILE_UPLOAD_OFFLINE_MSG;
            }
            if (isFieldFromFlowable(fieldDefinition) && fieldDefinition.params && fieldDefinition.params.multiple) {
                propConfiguration = {
                    ...propConfiguration,
                    multiple: true,
                };
            }
            if (isFieldFromFlowable(fieldDefinition)) {
                propConfiguration.dontShowUploadInput = Boolean(fieldDefinition.readOnly);
                if (fieldDefinition.params?.accept) {
                    propConfiguration.accept = fieldDefinition.params.accept;
                    propConfiguration.acceptError = fieldDefinition.params?.acceptError;
                }
            }
            RComponent = ProcessFileUpload;
            break;
        case t.JSONSCHEMA_FORM_BUILDER:
            RComponent = JsonSchemaFormBuilder;
            break;
        case t.EMAIL:
            RComponent = (props) => <TextInput type={'email'} {...props} />;
            break;
        case t.VALUESET_CHECKBOX:
            const fi = getValuesetCheckbox(fieldDefinition, propConfiguration);
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(fi.Component);
            propConfiguration = fi.props;
            break;
        case t.VALUESET_SELECT:
            /*
            fieldDefinition <- read
            propConfiguration <- mutate or return new

            return
                propConfiguration
                RComponent
        */
            if (isEntityDataField(fieldDefinition)) {
                getConfigProperty<{ defaultCode: string }>('defaultCode')(fieldDefinition).fold(null, (defaultCode) => {
                    propConfiguration.defaultCode = defaultCode;
                });
                getConfigProperty<{ group: string }>('group')(fieldDefinition).fold(null, (group) => {
                    propConfiguration.group = group;
                });
            }
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(ValueSelectDownshift); // ValuesetSelect;
            if (
                /* VALUESET_CHECKBOX NOT YET IMPLEMENTED FOR FLOWABLE. Checking here to do the cast properly */
                fieldDefinition.type === t.VALUESET_SELECT &&
                isFieldFromFlowable(fieldDefinition) &&
                fieldDefinition.params &&
                fieldDefinition.params.valueSet
            ) {
                const valueSet = fieldDefinition.params.valueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    fetchOwnData: true,
                    ...propConfiguration,
                    group,
                    valueSet,
                };
            } else {
                propConfiguration = {
                    fetchOwnData: false,
                    ...propConfiguration,
                    source: !fieldDefinition.name.endsWith('Id') ? `${fieldDefinition.name}Id` : fieldDefinition.name,
                };
            }
            if (liveProps.isForSearch) {
                const Inner = withConceptIdsOverride(fieldDefinition._dataSource)(ValueSelectDownshift);

                const ValuesetSelectForSearch = (props) => {
                    return (
                        <ValuesetSelectForSearchController {...props}>
                            {(props) => <Inner {...(props as any)} />}
                        </ValuesetSelectForSearchController>
                    );
                };
                RComponent = ValuesetSelectForSearch;
            }
            break;
        case t.VALUESET_MULTISELECT:
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(ValuesetManySelectDownshift); // ValuesetMultiselect;
            if (isEntityDataField(fieldDefinition)) {
                getConfigProperty<{ defaultCodes: string[] }>('defaultCodes')(fieldDefinition).fold(
                    null,
                    (defaultCodes) => {
                        propConfiguration.defaultCodes = defaultCodes;
                    },
                );
                getConfigProperty<{ group: string }>('group')(fieldDefinition).fold(null, (group) => {
                    propConfiguration.group = group;
                });
            }
            const source = !propConfiguration.source.endsWith('Ids')
                ? `${propConfiguration.source}Ids`
                : propConfiguration.source;

            if (
                isFieldFromFlowable(fieldDefinition) &&
                fieldDefinition.params &&
                fieldDefinition.params.multiSelectValueSet
            ) {
                const valueSet = fieldDefinition.params.multiSelectValueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    fetchOwnData: true,
                    ...propConfiguration,
                    group,
                    source,
                    valueSet,
                };
            } else {
                propConfiguration = {
                    fetchOwnData: false,
                    ...propConfiguration,
                    source: liveProps.isForSearch ? source.slice(0, -3) + '.id' : source,
                };
            }
            break;
        case t.MULTIPLE_ENTITY_TYPEAHEAD:
        case t.ENTITY_TYPEAHEAD: {
            const fi = getEntityTypeahead(fieldDefinition, propConfiguration, viewConfig, {
                isForSearch: liveProps.isForSearch,
                ignoreFilters: configuration.ignoreFilters,
                isOffline: isOffline(),
            });
            RComponent = fi.Component;
            propConfiguration = {
                ...fi.props,
                label: appendRequiredLabel(viewConfig, fieldDefinition, liveProps, fi.props.label),
            };
            break;
        }
        case t.REFONE_JOIN_SELECT:
        case t.REFONE_SELECT: {
            if (isOffline()) {
                // if flowable, render an entity-typeahead instead.
                // if entity, render an uneditable placeholder.
                if (isFieldFromFlowable(fieldDefinition)) {
                    const fi = getEntityTypeahead(fieldDefinition, propConfiguration, viewConfig, {
                        isForSearch: liveProps.isForSearch,
                        ignoreFilters: configuration.ignoreFilters,
                        isOffline: isOffline(),
                    });
                    RComponent = fi.Component;
                    propConfiguration = fi.props;
                    break;
                } else {
                    propConfiguration.isHardDisabled = true;
                    propConfiguration.editViewName = -1;
                }
            } else {
                propConfiguration.noSearch = gethasNoSearch(fieldDefinition);
                propConfiguration.editViewName = getEditViewName(fieldDefinition);
            }
            /*
                Three possibilities here:
                1. hidden reference to a linkedEntity
                2. Hardcoded reference back to a parent entity on Create view
                3. Popover selector
            */
            propConfiguration.viewName = getViewName(fieldDefinition);
            propConfiguration.createViewName = getCreateViewName(fieldDefinition);
            propConfiguration.showViewName = getShowViewName(fieldDefinition);
            if (
                fieldDefinition.name === configuration.parentField ||
                `${fieldDefinition.name}Id` === `${configuration.parentField}`
            ) {
                // backreference set
                if (fieldDefinition.name === 'linkedEntity') {
                    // linkedEntity - hidden hardcoded field
                    /*
                        The below is not used anymore for setting the form value.
                        Leaving it as a dummy field for now.
                    */
                    RComponent = NumberInput;
                    propConfiguration = {
                        ...propConfiguration,
                        style: { display: 'none' },
                        source: `${fieldDefinition.name}Id`,
                        defaultValue: configuration.parentEntityIdValue,
                    };
                } else {
                    // hard backreference e.g. on Create for a reference entity.
                    RComponent = HardReference;
                    propConfiguration = {
                        ...propConfiguration,
                        parentEntityIdValue: configuration.parentEntityIdValue,
                        source: `${fieldDefinition.name}Id`,
                    };
                }
            } else {
                // popover reference picker
                // used to be:  fieldDefinition.type === t.REFONE_JOIN_SELECT ? PopoverRefJoinInput : PopoverRefInput;
                RComponent = PopoverRefInput;

                const openTo = getOpenTo(fieldDefinition);
                if (openTo) {
                    propConfiguration.openTo = openTo;
                }
                const disabledDisplayHtml = isFieldFromFlowable(fieldDefinition)
                    ? undefined
                    : getConfigProperty<{ disabledDisplayHtml?: string }>('disabledDisplayHtml')(
                          fieldDefinition,
                      ).toUndefined();
                if (disabledDisplayHtml?.trim()) {
                    propConfiguration.disabledDisplayHtml = disabledDisplayHtml;
                }
                let refEntityName: string | null = null;
                const filterString = configuration.ignoreFilters
                    ? null
                    : isFieldFromFlowable(fieldDefinition)
                    ? fieldDefinition.params.filter
                    : getConfigProperty('filter')(fieldDefinition).getOrElse(null);

                if (filterString) {
                    propConfiguration.filterString = filterString;
                    RComponent = withEvaluatedFilter(
                        RComponent,
                        propConfiguration.filterString,
                        isFieldFromFlowable(fieldDefinition) ? 'Flowable' : 'Entity',
                        fieldDefinition['data-originaldefinition'],
                    );
                }
                if (isEntityDataField(fieldDefinition)) {
                    propConfiguration = {
                        // search field needs to fetch initial data
                        // also on create views where the ID is set via url query param
                        fetchOwnData: Boolean(liveProps.isForSearch || liveProps.isForCreate),
                        fetchViewExpansions: true,
                        ...propConfiguration,
                    };
                    if (fieldDefinition.configuredEntity) {
                        refEntityName = getRefEntityName(
                            viewConfig,
                            fieldDefinition.configuredEntity,
                            fieldDefinition.name,
                            'POP_LAST',
                        );
                    }
                }
                if (isFieldFromFlowable(fieldDefinition)) {
                    propConfiguration = {
                        fetchOwnData: true,
                        ...propConfiguration,
                    };
                    // evaluate the filterString in the form context
                    if (fieldDefinition.params && fieldDefinition.params.entity) {
                        refEntityName =
                            fieldDefinition.params.entity[0].toUpperCase() + fieldDefinition.params.entity.slice(1);
                    }
                }

                if (refEntityName) {
                    propConfiguration = {
                        ...propConfiguration,
                        source: `${fieldDefinition.name}${isEntityDataField(fieldDefinition) ? 'Id' : ''}`, // append Id only for entities
                        reference: refEntityName,
                        expansions: propConfiguration.filterString
                            ? (() => {
                                  const filterExpansions = getExpansionsFromFilter(propConfiguration.filterString);
                                  const customExpansions = getExpansions(fieldDefinition);
                                  return [...filterExpansions, ...customExpansions];
                              })()
                            : getExpansions(fieldDefinition),
                    };
                }
                RComponent = withNullFilteredRefoneOverride(fieldDefinition._dataSource)(RComponent);
            }
            break;
        }
        case t.ONEOF: {
            RComponent = SelectOneOf;
            propConfiguration = {
                ...propConfiguration,
                addLabel: true,
                source: `${propConfiguration.source}Id`,
            };
            break;
        }
        case t.VALUESET_SUGGEST: {
            if (isEntityDataField(fieldDefinition)) {
                getConfigProperty<{ valueSet: string }>('valueSet')(fieldDefinition).fold(null, (valueSet) => {
                    propConfiguration.valueSet = valueSet;
                });
                getConfigProperty<{ group: string }>('group')(fieldDefinition).fold(null, (group) => {
                    propConfiguration.group = group;
                });
            }
            if (isFieldFromFlowable(fieldDefinition) && fieldDefinition.params && fieldDefinition.params.valueSet) {
                const valueSet = fieldDefinition.params.valueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    fetchOwnData: true,
                    ...propConfiguration,
                    shouldFetchValueset: true,
                    group,
                    valueSet,
                };
            } else {
                propConfiguration = {
                    ...propConfiguration,
                    shouldFetchValueset: true,
                    fetchOwnData: true,
                };
            }
            const isFromFlowable = isFieldFromFlowable(fieldDefinition);
            const ValuesetSuggestWithConceptOverrides = (props) => {
                const formContext = isFromFlowable ? taskFormContext : entityFormContext;
                const c = useContext(tableRowContext);
                return (
                    <formContext.Consumer>
                        {(fc) => {
                            const { conceptIds } = getConceptIdsFromContexts(c, fc, props.source);
                            return <ValuesetSuggest {...props} conceptIds={conceptIds} />;
                        }}
                    </formContext.Consumer>
                );
            };
            RComponent = ValuesetSuggestWithConceptOverrides;
            break;
        }
        case t.REFMANYMANY_CHIP:
            RComponent = RefManyMultiSelectIdsList;
            propConfiguration.viewName = getViewName(fieldDefinition);

            const adjustedSource = propConfiguration.source.endsWith('Ids')
                ? propConfiguration.source
                : `${propConfiguration.source}Ids`;
            const mmchipprops: Subtract<RefProviderProps & { input: Input; meta: Meta }, { input: Input; meta: Meta }> =
                isFieldFromFlowable(fieldDefinition)
                    ? {
                          ...propConfiguration,
                          expansions: getExpansions(fieldDefinition),
                          filter: getFilterFromFilterString(
                              configuration.ignorefilters ? undefined : fieldDefinition.params.filter,
                          ),
                          type: 'Input(NoBackingEntity)',
                          reference:
                              (fieldDefinition.params as any).entity[0].toUpperCase() +
                              (fieldDefinition.params as any).entity.slice(1),
                          renderer: 'CHIP',
                          source: adjustedSource,
                          commitChanges: false,
                          overrideRenderNoResultsText: (
                              <div style={{ padding: '.25em', paddingLeft: '1em' }}>
                                  <Typography gutterBottom={true} variant="subtitle1" component="div">
                                      None Selected
                                  </Typography>
                              </div>
                          ),
                      }
                    : liveProps.isForSearch
                    ? {
                          ...propConfiguration,
                          type: 'Input(NoBackingEntity)',
                          reference: fieldDefinition.refEntityName,
                          renderer: 'CHIP',
                          source: adjustedSource.slice(0, -3) + '.id',
                          commitChanges: false,
                          overrideRenderNoResultsText: (
                              <div style={{ padding: '.25em', paddingLeft: '1em' }}>
                                  <Typography gutterBottom={true} variant="subtitle1" component="div">
                                      None Selected
                                  </Typography>
                              </div>
                          ),
                      }
                    : {
                          ...propConfiguration,
                          expansions: getExpansions(fieldDefinition),
                          type: 'Input(FromEntity)',
                          renderer: 'CHIP',
                          source: adjustedSource,
                          commitChanges: true,
                      };
            propConfiguration = {
                ...mmchipprops,
                addLabel: false,
                allowEmpty: true,
            };
            break;
        case t.JSONSCHEMA_FORM:
            if (isEntityDataField(fieldDefinition)) {
                RComponent = JsonSchemaForm;
                try {
                    const config = JSON.parse(fieldDefinition.config);
                    if (config) {
                        propConfiguration.readSchemasFromField = config.readSchemasFromField;
                        propConfiguration.schema = config.schema ? JSON.parse(config.schema) : '';
                        propConfiguration.uiSchema = config.uiSchema ? JSON.parse(config.uiSchema) : '';
                    }
                } catch (e) {
                    console.error('could not parse the below config for field ' + fieldDefinition.name);
                    console.log(fieldDefinition);
                }
            } else {
                RComponent = () => <div>Not implemented for task forms.</div>;
            }

            break;
        case t.MANYMANY_MULTI_CARD:
        case t.REFMANY_MULTISELECT_IDSLIST: {
            if (isOffline()) {
                propConfiguration.disabled = true;
                propConfiguration.noClick = true;
            }
            const renderer = fieldDefinition.type === t.MANYMANY_MULTI_CARD ? 'CARD_EDITABLE' : 'LIST';
            RComponent = RefManyMultiSelectIdsList;

            const mmprops: Subtract<RefProviderProps & { input: Input; meta: Meta }, { input: Input; meta: Meta }> =
                isFieldFromFlowable(fieldDefinition)
                    ? {
                          ...propConfiguration,
                          expansions: getExpansions(fieldDefinition),
                          type: 'Input(NoBackingEntity)',
                          reference:
                              (fieldDefinition.params as any).entity[0].toUpperCase() +
                              (fieldDefinition.params as any).entity.slice(1),
                          renderer,
                          source: `${propConfiguration.source}Ids`,
                          commitChanges: false,
                      }
                    : liveProps.isForSearch
                    ? {
                          ...propConfiguration,
                          type: 'Input(NoBackingEntity)',
                          reference: fieldDefinition.refEntityName,
                          renderer,
                          source: `${propConfiguration.source}.id`,
                          commitChanges: false,
                      }
                    : (() => {
                          let filter = getConfigProperty('filter')(fieldDefinition).toNullable();
                          if (filter) {
                              RComponent = withEvaluatedFilter(
                                  ({ filterString, ...props }: any) => {
                                      const filterObj = useMemo(() => {
                                          return getFilterFromFilterString(filterString);
                                      }, [filterString]);
                                      return <RefManyMultiSelectIdsList {...props} filter={filterObj} />;
                                  },
                                  filter,
                                  'Entity',
                                  fieldDefinition['data-originaldefinition'],
                              );
                          }
                          let listFilter = getConfigProperty('listFilter')(fieldDefinition).toNullable();
                          if (listFilter) {
                              const InnerRComponent = RComponent;
                              RComponent = withEvaluatedFilter(
                                  ({ filterString, ...props }: any) => {
                                      const filterObj = useMemo(() => {
                                          return getFilterFromFilterString(filterString);
                                      }, [filterString]);
                                      return <InnerRComponent {...props} listFilter={filterObj} />;
                                  },
                                  listFilter,
                                  'Entity',
                                  fieldDefinition['data-originaldefinition'],
                              );
                          }
                          if (liveProps.isForCreate) {
                              return {
                                  ...propConfiguration,
                                  expansions: getExpansions(fieldDefinition),
                                  type: 'Input(NoBackingEntity)',
                                  renderer,
                                  reference:
                                      viewConfig.entities[fieldDefinition.configuredEntity].fields[
                                          propConfiguration.source
                                      ].relatedEntity,
                                  source: `${propConfiguration.source}Ids`,
                                  commitChanges: false,
                              };
                          } else {
                              return {
                                  ...propConfiguration,
                                  expansions: getExpansions(fieldDefinition),
                                  type: 'Input(FromEntity)',
                                  renderer,
                                  source: `${propConfiguration.source}Ids`,
                                  commitChanges: true,
                              };
                          }
                      })();
            const getViewNameOf = (type: 'LIST' | 'EDIT' | 'SHOW' | 'CREATE') =>
                getCustomViewName(type, false)(
                    isFieldFromFlowable(fieldDefinition)
                        ? propConfiguration.reference
                        : getRefEntityName(
                              viewConfig,
                              fieldDefinition.configuredEntity,
                              fieldDefinition.name,
                              'TRAVERSE_PATH',
                          ),
                    viewConfig,
                    (fieldDefinition as any).config,
                );
            propConfiguration = {
                ...mmprops,
                hidePaginationIfNotNeeded: simpleGetConfProperty<{ hidePaginationIfNotNeeded?: boolean }>(
                    'hidePaginationIfNotNeeded',
                    false,
                )(fieldDefinition),
                viewName: getViewName(fieldDefinition) ?? getViewNameOf('LIST'),
                editViewName: getEditViewName(fieldDefinition) ?? getViewNameOf('EDIT'),
                showViewName: getShowViewName(fieldDefinition) ?? getViewNameOf('SHOW'),
                createViewName: getCreateViewName(fieldDefinition) ?? getViewNameOf('CREATE'),
                openTo: getOpenTo(fieldDefinition),
                allowEmpty: true,
                addLabel: false,
            };
            const defineSPELVariables = simpleGetConfProperty<{ defineSPELVariables?: string }>(
                'defineSPELVariables',
                null,
            )(fieldDefinition);

            if (defineSPELVariables) {
                const InnerRComponent = RComponent;
                RComponent = (props) => {
                    return (
                        <EvaluateInEntityContext
                            expression={defineSPELVariables || null}
                            defaultOnException={false}
                            useLiveValues
                        >
                            {({ expressionResult: evaluatedAdhocSPELVariables }) => {
                                return (
                                    <InnerRComponent
                                        {...props}
                                        evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                    />
                                );
                            }}
                        </EvaluateInEntityContext>
                    );
                };
            }
            break;
        }
        case t.CHOICES_SELECT: {
            RComponent = withOptionsOverride(ChoicesSelect);
            propConfiguration = {
                ...propConfiguration,
                choices: isFieldFromFlowable(fieldDefinition)
                    ? fieldDefinition.options
                    : makeConsoleError('Choices Select choices not defined for Non-flowable data sources')(),
            };
            break;
        }
        case t.VIDEO_WATCHED: {
            const { Component, props } = getVideoWatched(fieldDefinition, propConfiguration);
            RComponent = Component;
            propConfiguration = props;
            break;
        }
        case t.RADIO: {
            const label = getLabel(fieldDefinition);
            RComponent = withOptionsOverride(RadioSelect);
            propConfiguration = {
                ...propConfiguration,
                direction: getDirection(fieldDefinition),
                label,
                choices: isFieldFromFlowable(fieldDefinition)
                    ? fieldDefinition.options
                    : makeConsoleError('Radio choices not defined for Non-flowable data sources')(),
                addLabel: false,
            };
            break;
        }
        case t.CHECKBOX: {
            RComponent = Checkbox;
            type CheckboxAdhocConfig = {
                htmlId?: string;
                unlinkVisualLabel?: boolean;
            };
            propConfiguration = {
                ...propConfiguration,
                htmlId: simpleGetConfProperty<CheckboxAdhocConfig>('htmlId', undefined)(fieldDefinition),
                unlinkVisualLabel: simpleGetConfProperty<CheckboxAdhocConfig>(
                    'unlinkVisualLabel',
                    undefined,
                )(fieldDefinition),
                defaultValue: false,
            };
            break;
        }
        case t.COMPONENT: {
            RComponent = ComponentPlaceholder;
            break;
        }
        case t.DMS_DOCUMENT: {
            RComponent = DmsDoc;
            if (isFieldFromFlowable(fieldDefinition) && fieldDefinition.params && fieldDefinition.params.entity) {
                propConfiguration.reference =
                    fieldDefinition.params.entity[0].toUpperCase() + fieldDefinition.params.entity.slice(1);
            }
            propConfiguration.addLabel = true;
            propConfiguration.type = isFieldFromFlowable(fieldDefinition) ? 'Input(NoBackingEntity)' : 'Input(Entity)';
            break;
        }
        case t.VALIDATION_EXPRESSION_EDITOR: {
            RComponent = ValidationsEditorField;
            break;
        }
        case t.LIST: {
            RComponent =
                !configuration.ignoreFilters && fieldDefinition.params.filter
                    ? withNullFilteredRefoneOverride('Flowable')(
                          withEvaluatedFilter(
                              ({ filterString, ...props }: any) => <ListSelect {...props} filter={filterString} />,
                              fieldDefinition.params.filter,
                              'Flowable',
                              fieldDefinition['data-originaldefinition'],
                          ),
                      )
                    : ListSelect;
            RComponent = withInputFromFormContextValues(RComponent);

            const { filter, ...restOfParams } = fieldDefinition.params;
            propConfiguration = {
                ...propConfiguration,
                ...restOfParams,
                filter: configuration.ignoreFilters ? undefined : filter,
                ...getListSelectProps(propConfiguration, fieldDefinition),
            };

            break;
        }
        case t.TABLE: {
            if (isFieldFromFlowable(fieldDefinition)) {
                RComponent = EditableTable;
                propConfiguration = {
                    ...propConfiguration,
                    disabled: fieldDefinition.readOnly,
                    allowRowAddDelete: getFlowableFieldConfigProperty<{ allowRowAddDelete?: boolean }>(
                        'allowRowAddDelete',
                    )(fieldDefinition as FlowableTableField).getOrElse(true),
                    copyRows: getFlowableFieldConfigProperty<{ copyRows?: boolean }>('copyRows')(
                        fieldDefinition as FlowableTableField,
                    ).getOrElse(true),
                    reorderable: getFlowableFieldConfigProperty<{ reorderable?: boolean }>('reorderable')(
                        fieldDefinition as FlowableTableField,
                    ).getOrElse(true),
                    addRowText: getFlowableFieldConfigProperty<{ addRowText?: string }>('addRowText')(
                        fieldDefinition as FlowableTableField,
                    ).toUndefined(),
                    colSpec: fieldDefinition.params.columnObj,
                };
            } else {
                makeConsoleError('Table is only defined for Flowable fields at the moment.');
                RComponent = (props) => <div {...props} />;
            }
            break;
        } /* eslint-disable no-fallthrough */
        case t.EXPRESSION: {
            const getView = (expression: string): null | [string, string] => {
                if (!expression || typeof expression !== 'string') {
                    return null;
                }
                const pre = 'view_$';
                if (expression.startsWith(pre)) {
                    const rest = expression.slice(pre.length);
                    const [view, id] = rest.split('__');
                    return [view, id];
                }
                return null;
            };
            const maybeViewExpression = getView(fieldDefinition.value);
            if (maybeViewExpression) {
                const [viewName, id] = maybeViewExpression;
                // only handling possibleMatchView right now.
                RComponent = (props) => {
                    const [currId, setCurrId] = React.useState(id);
                    return (
                        <processContext.Consumer>
                            {(c) => (
                                <GetPossibleMatchView
                                    onDataChange={c.refresh}
                                    onIdChanged={setCurrId}
                                    editInPopover={true}
                                    viewName={viewName}
                                    id={currId}
                                />
                            )}
                        </processContext.Consumer>
                    );
                };
                break;
            }
            // PRINT TEMPLATE CASE
            const valueSetManyDisplayPrefix = 'VS_MANY__';
            if (typeof fieldDefinition.value === 'string' && fieldDefinition.value.startsWith(printTemplatePrefix)) {
                if (isOffline()) {
                    RComponent = () => null;
                    propConfiguration['hideMe'] = true;
                    break;
                }
                // this should be a printTemplate download.
                const printTemplateName = fieldDefinition.value.slice(printTemplatePrefix.length);
                RComponent = PrintTemplateNameLookupField;
                propConfiguration = {
                    ...propConfiguration,
                    dontConnect: true,
                    printTemplateName,
                };
                break;
            } else if (
                typeof fieldDefinition.value === 'string' &&
                (fieldDefinition.value.startsWith(printTemplatePdfPrefix) ||
                    fieldDefinition.value.startsWith(printTemplateReportPrefix))
            ) {
                if (isOffline()) {
                    RComponent = () => null;
                    propConfiguration['hideMe'] = true;
                    break;
                }
                const printTemplateName = fieldDefinition.value.slice(printTemplatePdfPrefix.length);
                RComponent = ServerTemplatedPrintTemplateField;
                propConfiguration = {
                    ...propConfiguration,
                    type: fieldDefinition.value.startsWith(printTemplateReportPrefix) ? 'report' : 'pdf',
                    dontConnect: true,
                    printTemplateName,
                };
                break;
            }
            // valuesetManyDisplay
            // format: VS_MANY__[entityId]:[entityType]__[field]
            else if (
                typeof fieldDefinition.value === 'string' &&
                fieldDefinition.value.startsWith(valueSetManyDisplayPrefix)
            ) {
                const [entityId, rest] = fieldDefinition.value.slice(valueSetManyDisplayPrefix.length).split(':');
                const [entityType, field] = rest.split('__');
                // console.log(entityType, entityId, field);
                RComponent = connect((state: RootState) => ({
                    record: (state.admin.entities[entityType] || {})[entityId],
                }))(((props) =>
                    labelField(
                        <ValueSetListField addLabel={true} label={propConfiguration.label} {...props} />,
                        props.record,
                        entityType,
                        `/${entityType}`,
                    )) as React.SFC<{ record: {} }>);
                propConfiguration = {
                    ...propConfiguration,
                    source: field,
                };
                break;
            } else if (fieldDefinition.params && fieldDefinition.params.expressionCustom === 'VSM') {
                RComponent = (props) =>
                    labelField(
                        <ValueSetListFieldComponent
                            addLabel={true}
                            label={propConfiguration.label}
                            {...propConfiguration}
                            {...props}
                        />,
                        { fakeSource: fieldDefinition.value },
                        '',
                        '',
                    );
                propConfiguration = {
                    ...propConfiguration,
                    concepts: Object.assign({}, ...fieldDefinition.value.map((e) => ({ [e.id]: e }))),
                    source: 'fakeSource',
                };
                break;
            } else if (
                fieldDefinition.params?.configs?.validation?.trim() &&
                fieldDefinition.params?.configs?.validation?.trim() !== '[]'
            ) {
                const ExpressionValidationComponent = ({ meta }) => {
                    const theme = useTheme();
                    const utils = useWarningOrErrorUtils(meta);
                    if (!meta.error || (Array.isArray(meta.error) && meta.error.length === 0)) {
                        return null;
                    }
                    return (
                        <span
                            style={{
                                whiteSpace: 'pre-wrap',
                                fontSize: '0.75rem',
                                color: theme.palette.error.dark,
                            }}
                        >
                            {utils.helperText}
                        </span>
                    );
                };
                const ComponentY = (props) => {
                    return <RFField name={fieldDefinition.name} component={ExpressionValidationComponent} />;
                };
                return <ComponentY {...propConfiguration} source={fieldDefinition.name} />;
            } else {
                let text;
                const isErrorRelated =
                    Array.isArray(fieldDefinition.value) &&
                    fieldDefinition.value.every(
                        (obj) =>
                            Object.prototype.hasOwnProperty.call(obj, 'errorMessage') &&
                            Object.prototype.hasOwnProperty.call(obj, 'row'),
                    );
                if (isErrorRelated) {
                    text = `${fieldDefinition.value.length} errors:`;
                } else {
                    text = fieldDefinition.value;
                }
                let C;
                switch (fieldDefinition.params && fieldDefinition.params.size) {
                    case '5':
                        C = `<h2>${fieldDefinition.value}</h2>`;
                        break;
                    case '4':
                        C = `<h3>${fieldDefinition.value}</h3>`;
                        break;
                    case '3':
                        C = `<h4>${fieldDefinition.value}</h4>`;
                        break;
                    case '2':
                        C = `<h5>${fieldDefinition.value}</h5>`;
                        break;
                    default:
                        C = fieldDefinition.value;
                }
                propConfiguration = {
                    ...propConfiguration,
                    value: text,
                    isExpression: true,
                };
                const newDefinition: any = {};
                newDefinition.htmlConfig = C;

                return expressionElement(
                    newDefinition as any,
                    {
                        ...propConfiguration,
                        ...liveProps,
                        id: `${
                            fieldDefinition._dataSource === DataSource.FLOWABLE ? 'flowable' : ''
                        }expression:r${row}c${column}`,
                    },
                    fieldDefinition._dataSource,
                );
            }
        }
        case t.WYSIWYG: {
            RComponent = WYSIWYG;
            break;
        }
        case t.BIGCALENDAR: {
            RComponent = BigCalendar; // props => <div>Big calendar...</div>;
            break;
        }
        case t.VALUESET_MULTICHECKBOX: {
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(MultiCheckbox);
            const _source = !propConfiguration.source.endsWith('Ids')
                ? `${propConfiguration.source}Ids`
                : propConfiguration.source;
            if (
                isFieldFromFlowable(fieldDefinition) &&
                fieldDefinition.params &&
                fieldDefinition.params.multiSelectValueSet
            ) {
                const valueSet = fieldDefinition.params.multiSelectValueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    ...propConfiguration,
                    group,
                    source: _source,
                    valueSet,
                    addLabel: false,
                    fetchOwnData: true,
                    direction: getDirection(fieldDefinition),
                    label: appendRequiredLabel(viewConfig, fieldDefinition, liveProps, getLabel(fieldDefinition)),
                };
            } else {
                if (isEntityDataField(fieldDefinition)) {
                    getConfigProperty<{ group: string }>('group')(fieldDefinition).fold(null, (group) => {
                        propConfiguration.group = group;
                    });
                }
                propConfiguration = {
                    ...propConfiguration,
                    source: _source,
                    disabled: false,
                    addLabel: false,
                    fetchOwnData: false,
                    direction: getDirection(fieldDefinition),
                    label: appendRequiredLabel(viewConfig, fieldDefinition, liveProps, getLabel(fieldDefinition)),
                };
            }
            break;
        }
        case t.CURRENCY: {
            RComponent = Currency;
            break;
        }
        case t.EVENT: {
            return getEventFieldGroup(propConfiguration, fieldDefinition, liveProps);
        }
        default:
            throw Error(`uncaught field: ${JSON.stringify(fieldDefinition)}`);
    }

    const disabled =
        propConfiguration.disabled ||
        (isFieldFromFlowable(fieldDefinition) && fieldDefinition.readOnly) ||
        (hasConfiguredEntity(fieldDefinition) &&
            (isDisabledEntityField(fieldDefinition, liveProps, viewConfig) ||
                (configuration.optInWriteable &&
                    Object.keys(configuration.optInWriteable).indexOf(
                        withoutIdOrIdsPostfix(propConfiguration.source),
                    ) === -1)));

    if (configuration.optInWriteable && configuration.optInWriteable[withoutIdOrIdsPostfix(propConfiguration.source)]) {
        const nameInOtherForm = configuration.optInWriteable[withoutIdOrIdsPostfix(propConfiguration.source)].id;
        RComponent = bindFieldToOtherForm('current-task-form', nameInOtherForm)(RComponent);
    }

    if (liveProps.overrideFieldValueIfDisabled) {
        RComponent = overrideFieldValueIfDisabled(fieldDefinition._dataSource)(RComponent);
    }
    // Don't do the below if final-form
    if (!configuration.ff) {
        RComponent = mapWarningsToErrors(isFieldFromFlowable(fieldDefinition))(RComponent);
    }
    if (!propConfiguration.ariaInputProps['aria-labelledby'] && typeof propConfiguration.label === 'string') {
        propConfiguration.ariaInputProps['aria-label'] = propConfiguration.overrideAriaLabel ?? propConfiguration.label;
    }
    // key for the unique widget - "source" won't be unique if there are multiple fields pointing to the same piece of data
    const fieldInstanceIdentifier =
        fieldDefinition._dataSource === 'Entity' && fieldDefinition.fieldInstanceIdentifier
            ? fieldDefinition.fieldInstanceIdentifier
            : propConfiguration.source;

    const tooltipText =
        overrideTooltipText ?? simpleGetConfProperty<{ tooltipText?: '' }>('tooltipText', '')(fieldDefinition);
    if (tooltipText) {
        const hasDynamicDropdownPosition = [
            t.VALUESET_MULTISELECT,
            t.VALUESET_SELECT,
            t.ENTITY_TYPEAHEAD,
            t.VALUESET_SUGGEST,
            t.MULTIPLE_ENTITY_TYPEAHEAD,
        ].includes(fieldDefinition.type);

        if (hasDynamicDropdownPosition) {
            propConfiguration.tooltipText = tooltipText;
        } else if (fieldDefinition.type === t.CHOICES_SELECT) {
            RComponent = withTooltip(tooltipText, 'top')(RComponent);
        } else {
            RComponent = withTooltip(tooltipText)(RComponent);
        }
    }
    const OldComponent = RComponent;
    const RComponentWithScrollFlag = (props) => {
        return (
            <>
                {props.meta?.form ? <ScrollToFieldFlag formId={props.meta.form} source={props.source} /> : null}
                <OldComponent {...props} />
            </>
        );
    };
    RComponent = RComponentWithScrollFlag;

    return (
        <RComponent
            {...propConfiguration}
            fieldInstanceIdentifier={fieldInstanceIdentifier}
            key={`${propConfiguration.source}-${propConfiguration.label}`}
            validate={fieldDefinition.validate}
            warn={(fieldDefinition as FlowableDataField).warn}
            addField={true}
            renderDesc={() =>
                fieldDefinition.description && <FormHelperTextNoErr>{fieldDefinition.description}</FormHelperTextNoErr>
            }
            {...liveProps}
            disabled={disabled || liveProps.disabled}
        />
    );
};
