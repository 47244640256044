import React, { useCallback, useState } from 'react';
import { saveAs } from 'file-saver';
import { useSelector, useStore } from 'react-redux';
import { TOGGLE_DEBUG_MODE } from '../actions/constants';
import BugReport from '@material-ui/icons/BugReport';
import FileDownload from '@material-ui/icons/SaveAlt';
import Wifi from '@material-ui/icons/Wifi';
import WifiLock from '@material-ui/icons/WifiLock';
import Ballot from '@material-ui/icons/Ballot';
import BallotOutlined from '@material-ui/icons/BallotOutlined';
import { toSerializableState, rehydrateState } from '../reducers/serializeDeserialize';
import { RootState } from '../reducers/rootReducer';
import { REPLACE_STATE } from '../actions/constants';
import TogglePrintMode from './TogglePrintMode';
import { startNewRootEpic } from 'configureStore';
import { setExpressionTesterOpen as setExpressionTesterOpenAction } from 'expression-tester/actions';
import { undisableTaskForms as undisableTaskFormsAction } from 'bpm/undisable-task-form/actions';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { push as pushAction } from 'connected-react-router';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import { layoutModeContext } from './layouts/LayoutMode';

const useStyles = makeStyles((theme) => ({
    warning: {
        color: theme.palette.error.main,
    },
    hideExceptOnHover: {
        width: '100%',
        height: '100%',
        display: 'flex',
        cursor: 'pointer',
        '&:not(:hover)': {
            color: 'transparent',
        },
        '&:hover': {},
    },
    transparent: {
        color: 'transparent',
    },
    whenPopover: {
        color: 'white',
    },
}));

const DebugAndReportingHelper = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const secure = useSelector((state: RootState) => (state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true));
    const debugMode = useSelector((state: RootState) => state.debugMode);
    const somePopover = useSelector((state: RootState) => state.viewStack && state.viewStack.length > 0);
    const expressionTesterOpen = useSelector((state: RootState) => state.expressionTesterOpen);
    const undisabledTaskForms = useSelector((state: RootState) => state.undisableTaskForms);
    const store = useStore();
    const replaceState = useCallback(
        (state: RootState) => store.dispatch({ type: REPLACE_STATE, payload: state }),
        [store],
    );
    const toggleDebugMode = useCallback(() => store.dispatch({ type: TOGGLE_DEBUG_MODE }), [store]);
    const endEpic = useCallback(() => store.dispatch({ type: 'EPIC_END' }), [store]);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);
    const importState = useCallback(
        (file: File) => {
            const success = (content) => {
                const strState = JSON.parse(content);
                replaceState(rehydrateState(strState));
                store.dispatch(pushAction((strState as RootState).router.location.pathname));
            };
            const fileReader = new FileReader();
            fileReader.onload = (evt: ProgressEvent & { target: { result?: ArrayBuffer | string } | null }) => {
                if (evt && evt.target && evt.target.result) {
                    success(evt.target.result);
                }
            };
            fileReader.readAsText(file);
        },
        [store, replaceState],
    );

    const report = useCallback(() => {
        const state = store.getState();
        const stringState = JSON.stringify(toSerializableState(state));
        saveAs(new Blob([stringState], { type: 'application/json' }), 'debug_state.json');
    }, [store]);

    return (
        <div
            className={somePopover ? classes.whenPopover : undefined}
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
        >
            {/* debugMode is being used to prevent requests in offline-mode. */}
            {/* debugMode && !open && (
        <span className={classes.warning} style={{ whiteSpace: 'nowrap' }}>
            Side-effects Disabled <WifiLock />
        </span>
    ) */}
            <div
                style={{ color: open ? 'unset' : undefined }}
                className={secure ? classes.transparent : classes.hideExceptOnHover}
            >
                {secure ? <BugReport onDoubleClick={toggleOpen} /> : <BugReport onClick={toggleOpen} />}
                {open && <FileDownload onClick={report} />}
                {open && (
                    <input
                        type="file"
                        style={{
                            marginBottom: '1em',
                        }}
                        accept="application/json"
                        onChange={(e) => e.target.files && importState(e.target.files[0])}
                    />
                )}
                {open && debugMode && (
                    <WifiLock
                        onClick={() => {
                            toggleDebugMode();
                            startNewRootEpic();
                        }}
                    />
                )}
                {open && !debugMode && (
                    <Wifi
                        onClick={() => {
                            toggleDebugMode();
                            endEpic();
                        }}
                    />
                )}
                {open && expressionTesterOpen && (
                    <Ballot
                        onClick={() => {
                            store.dispatch(setExpressionTesterOpenAction(false));
                        }}
                    />
                )}
                {open && !expressionTesterOpen && (
                    <BallotOutlined
                        onClick={() => {
                            store.dispatch(setExpressionTesterOpenAction(true));
                        }}
                    />
                )}
                {open && (
                    <Button
                        onClick={() => {
                            store.dispatch(undisableTaskFormsAction(!undisabledTaskForms));
                        }}
                    >
                        {undisabledTaskForms ? 'Forms undisabled' : 'Undisable forms'}
                    </Button>
                )}
                {open && (
                    <layoutModeContext.Consumer>
                        {({ mode, toggleContext }) => (
                            <IconButton onClick={toggleContext}>
                                <VerticalSplitIcon />
                            </IconButton>
                        )}
                    </layoutModeContext.Consumer>
                )}
                {open && <TogglePrintMode />}
                {open && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let loc = prompt('Location: ', '');
                            if (loc) {
                                store.dispatch(pushAction(loc));
                            }
                        }}
                    >
                        PushNav
                    </Button>
                )}
            </div>
        </div>
    );
};

export default DebugAndReportingHelper;
