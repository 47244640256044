import * as React from 'react';
import { useData } from 'fieldFactory/offline/XMany';
import useViewConfig from 'util/hooks/useViewConfig';
import { FieldViewField } from 'reducers/ViewConfigType';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from '../../../translation/types/DataSource';
import getFields from 'components/generics/genericList/getFields';
import Table from './CustomDatagrid/CustomDatagrid';
import { getAccessLevelForEntityField, getLabelForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import { Button, IconButton, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Field } from 'redux-form';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import ControlledValidatedForm from './ControlledValidatedRowForm';
import orderBy from 'lodash/orderBy';
import { v4 as uuidv4 } from 'uuid';

interface InlineDatagridProps {
    source: string;
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    filter?: string;
    overrideViewName?: string;
    referencedByField?: string;
    handleSelectExistingRecord: (id: string) => void;
    showCreate?: boolean;
    disabled?: boolean;
}

interface InlineDatagridFieldProps extends InlineDatagridProps {
    input: {
        onChange: (value: {}[]) => void;
        value?: {}[];
    };
    meta?: {
        submitFailed?: boolean;
    };
}
function InlineDatagrid({
    id,
    source,
    rootEntityType,
    resource,
    filter,
    overrideViewName,
    referencedByField,
    input: { value, onChange },
    showCreate,
    handleSelectExistingRecord,
    disabled,
    meta,
}: InlineDatagridFieldProps) {
    // adding sorting
    const [sort, setSort] = React.useState<[string, 'asc' | 'desc']>(null);

    const data = useData(rootEntityType, source, id, filter);
    const rows = React.useMemo(() => Object.values(data) as any[], [data]);
    const viewConfig = useViewConfig();
    const accessLevel = React.useMemo(
        () => getAccessLevelForEntityField(viewConfig, rootEntityType, source, 'TRAVERSE_PATH'),
        [viewConfig, rootEntityType, source],
    );

    const listViewName = overrideViewName ?? viewConfig.entities[resource]?.defaultViews?.LIST?.name;
    const fieldFactory = React.useContext(FieldFactoryContext);
    const fieldElemDefinitionTuples: [any, FieldViewField, any][] = React.useMemo(() => {
        const fields = getFields(viewConfig, listViewName, true, referencedByField);
        const inputFields = fieldFactory({
            // TODO: figure out validation.
            dataSource: DataSource.ENTITY,
            mode: Mode.INPUT_NOWARN,
            validate: true,
            connected: 'ff',
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
                ff: true,
            },
        })({ record: { id, entityType: rootEntityType }, resource, basePath: '/' + resource })(fields);
        return fieldFactory({
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
            },
        })({ record: { id, entityType: rootEntityType }, resource, basePath: '/' + resource, isForSearch: true })(
            fields,
        ).map((f, i) => [f, fields[i], inputFields[i]] as [any, FieldViewField, any]);
    }, [viewConfig, listViewName, referencedByField, id, rootEntityType, resource, fieldFactory]);

    const initialValue = React.useMemo(() => {
        if (Array.isArray(value)) {
            return value.filter((e) => !e['id']).map((e) => ({ ...e, _key: uuidv4() }));
        }
        return [];
    }, [value]);

    const [additionalRows, setAdditionalRows] = React.useState(initialValue);
    React.useEffect(() => {
        onChange([...rows.map(({ id }) => ({ id })), ...additionalRows.map(({ _key, ...rest }) => rest)]);
    }, [rows, additionalRows, onChange]);

    const submitFailed = meta?.submitFailed;
    const additionalRowElements = React.useMemo(() => {
        return additionalRows.map((r, i) => (
            <tr key={r._key}>
                <ControlledValidatedForm
                    submitFailed={submitFailed}
                    resource={resource}
                    registeredFields={fieldElemDefinitionTuples.map(([, f]) => f.field)}
                    values={r}
                    setValues={(r) => {
                        setAdditionalRows([
                            ...(i > 0 ? additionalRows.slice(0, i) : []),
                            r as { _key: string },
                            ...(i !== additionalRows.length - 1 ? additionalRows.slice(i + 1) : []),
                        ]);
                    }}
                >
                    {(props) => {
                        return (
                            <>
                                {fieldElemDefinitionTuples.map(([displayE, f, inputE]) => (
                                    <td
                                        style={{
                                            paddingTop: '16px',
                                            paddingBottom: '16px',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                        key={f.field}
                                    >
                                        {React.cloneElement(inputE, { record: r })}
                                    </td>
                                ))}
                                <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <div
                                        style={{
                                            display: 'inline-flex',
                                            flex: '0 0 auto',
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            verticalAlign: 'middle',
                                            height: '100%',
                                            marginLeft: '1em',
                                        }}
                                    >
                                        <div style={{ margin: '-14px' }}>
                                            <IconButton
                                                aria-label="Remove Row"
                                                onClick={() =>
                                                    setAdditionalRows([
                                                        ...additionalRows.slice(0, i),
                                                        ...additionalRows.slice(i + 1),
                                                    ])
                                                }
                                            >
                                                <Clear />
                                            </IconButton>
                                        </div>
                                    </div>
                                </td>
                            </>
                        );
                    }}
                </ControlledValidatedForm>
            </tr>
        ));
    }, [additionalRows, fieldElemDefinitionTuples, resource, submitFailed]);

    const sortedRows = React.useMemo(() => {
        if (!sort) {
            return rows;
        }
        const [field, dir] = sort;
        return orderBy(rows, [field], [dir]);
    }, [rows, sort]);
    const TableContent = (
        <TableBody>
            {sortedRows.map((r, i) => (
                <TableRow key={'existing -' + i}>
                    {fieldElemDefinitionTuples.map(([e, f]) => (
                        <TableCell
                            component="td"
                            className="td-data"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            key={f.field}
                        >
                            <div
                                style={{
                                    display: 'inline-flex',
                                    flex: '0 0 auto',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    verticalAlign: 'middle',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'block',
                                        textAlign: 'left',
                                        marginLeft: '1em',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                    }}
                                >
                                    {React.cloneElement(e, { record: r })}
                                </div>
                            </div>
                        </TableCell>
                    ))}
                    <TableCell component="td" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div
                            style={{
                                display: 'inline-flex',
                                flex: '0 0 auto',
                                textAlign: 'center',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                height: '100%',
                                marginLeft: '1em',
                            }}
                        >
                            <div style={{ margin: '-14px' }}>
                                <IconButton
                                    aria-label="Edit Row"
                                    onClick={() => {
                                        handleSelectExistingRecord(r['id']);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
            ))}
            {!disabled && additionalRowElements}
        </TableBody>
    );

    return (
        <div>
            <Table
                currentSort={sort}
                setSort={setSort}
                headers={[
                    ...fieldElemDefinitionTuples.map(([, f]) => ({
                        label: f.label ?? getLabelForFieldExpr(viewConfig, resource, f.field, 'TRAVERSE_PATH'),
                        field: f.field,
                    })),
                    {
                        label: '',
                    },
                ]}
                minCellWidth={120}
                tableContent={TableContent}
                renderBelow={({ updateInternalTableHeight }) => {
                    if (disabled || showCreate === false || accessLevel <= 2) {
                        return null;
                    }
                    return (
                        <Button
                            color="primary"
                            endIcon={<Add />}
                            onClick={() => {
                                setAdditionalRows([...additionalRows, { _key: uuidv4() }]);
                                setImmediate(() => updateInternalTableHeight());
                            }}
                        >
                            Add Row
                        </Button>
                    );
                }}
            />
        </div>
    );
}

const renderField = (props) => {
    return <InlineDatagrid {...props} />;
};
const InlineDatagridField = (props: InlineDatagridProps) => {
    return <Field {...props} name={props.source} component={renderField} />;
};

export default InlineDatagridField;
