import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import ContentSend from '@material-ui/icons/Send';
import { currentDashboardActions } from 'dashboard2/dashboard-config/actions';

export const buttonStyle = {
    height: '100%',
    width: '100%',
    fontSize: '20px',
    lineHeight: '1em',
};

interface LinkWidgetProps {
    title: string;
    toDashboard: string;
}

const DashboardLinkWidgetComponent = ({ title, toDashboard }: LinkWidgetProps) => {
    const dispatch = useDispatch();
    const onClick = useCallback(() => {
        dispatch(currentDashboardActions.setCurrentDash(toDashboard));
    }, [dispatch, toDashboard]);
    return (
        <Button variant="contained" onClick={onClick} color="primary" style={buttonStyle}>
            {title}&nbsp;
            <ContentSend />
        </Button>
    );
};

export default DashboardLinkWidgetComponent;
