import React, { useEffect, useState } from 'react';
import { Dialog, makeStyles, Theme, createStyles, Button, Typography, CardActions } from '@material-ui/core';
import WarnIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warn: {
            color: theme.palette.warning.dark,
        },
    }),
);

interface WarningPopupProps {
    warning?: string;
    onContinue: () => void;
}

const WarningPopup: React.FC<WarningPopupProps> = (props) => {
    const { warning, onContinue } = props;
    const classes = useStyles(props);
    const [open, setOpen] = useState(Boolean(warning));
    useEffect(() => {
        if (warning) {
            setOpen(true);
        }
    }, [warning]);
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
        >
            <div style={{ padding: '1em' }}>
                <Typography variant="h5" component="div">
                    <WarnIcon className={classes.warn} />
                    &nbsp;&nbsp;Warning
                </Typography>
                <p>
                    <b>{warning}</b>
                </p>
            </div>
            <CardActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        onContinue();
                        setOpen(false);
                    }}
                >
                    Continue
                </Button>
                <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
            </CardActions>
        </Dialog>
    );
};
export default WarningPopup;
