import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

const useDisplayName = (args: { resource: string; type: 'SINGULAR' | 'PLURAL' }) => {
    const { resource, type } = args;
    return useSelector((state: RootState) => {
        return fromNullable(state.viewConfig.entities)
            .mapNullable((e) => e[resource])
            .mapNullable((r) => (type === 'SINGULAR' ? r.displayName : r.displayNamePlural))
            .toUndefined();
    });
};

export default useDisplayName;
