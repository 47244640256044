import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

export const nullAdhocVariablesContext = { getVar: () => null, getVars: () => ({}) };

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getAdhocVariablesContextSelector = <T>() =>
    createDeepEqualSelector(
        (adhocVariables: Record<string, unknown>) => adhocVariables,
        (adhocVariables) => {
            return {
                getVar: (path: string) => get(adhocVariables, path, null),
                getVars: () => adhocVariables ?? {},
            };
        },
    );
export default getAdhocVariablesContextSelector;
