import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ContentCreate from '@material-ui/icons/Create';

interface EditButtonProps {
    resource: string;
    id: string;
    label?: string;
    disabled?: boolean;
}
const EditButton: FunctionComponent<EditButtonProps> = React.memo(({ resource, label = 'Edit', id, disabled }) => (
    <Button
        disabled={disabled}
        color="primary"
        component={Link}
        to={`/${resource}/${id}`}
        style={{ overflow: 'inherit' }}
    >
        {label}&nbsp;
        <ContentCreate />
    </Button>
));

export default EditButton;
