import React from 'react';
import FileInput from './FileInput';
import { RootState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import ViewConfig from 'reducers/ViewConfigType';
import { formValueSelector } from 'redux-form';
import { tryCatch, fromNullable } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { getDefaultPathsForPrintTemplate } from '@mkanai/casetivity-shared-js/lib/print-templates/parseDataPaths';

interface FileUploadProps {
    meta: any;
    source: string;
    label: string | JSX.Element;
    input: any;
    renderLabel: boolean;
    ariaInputProps: {};
    disabled?: boolean;
    resource: string;
    viewConfig: ViewConfig;
    entityConfName?: string | null;
    isImage?: boolean;
    imageWidth?: string;
}
const FileUpload = (props: FileUploadProps) => {
    return (
        <div>
            <FileInput
                imageWidth={props.imageWidth}
                isImage={props.isImage}
                mode="DMS"
                resource={props.resource}
                /*
                uncomment to operate with documentb64 directly
                mode="FILE"
                */
                meta={props.meta}
                source={props.source}
                label={props.label}
                input={props.input}
                renderLabel={props.renderLabel}
                ariaInputProps={props.ariaInputProps}
                disabled={props.disabled}
                deriveOnUpload={
                    props.resource === 'PrintTemplate' && props.source === 'document'
                        ? {
                              config: (docB64: string) =>
                                  getDefaultPathsForPrintTemplate(props.viewConfig, props.entityConfName, docB64)
                                      .chain((paths) => tryCatch(() => JSON.stringify(paths, null, 4)))
                                      .getOrElse(''),
                          }
                        : {}
                }
            />
        </div>
    );
};
const makeMapStateToProps = () => {
    const selectors: { [formId: string]: Function } = {};
    const getEntityNameFromId = createSelector(
        (state: RootState, props: { entityConfId: string }) => props.entityConfId,
        (state: RootState) => state.viewConfig,
        (entityConfId, viewConfig) =>
            entityConfId &&
            fromNullable(Object.values(viewConfig.entities).find((e) => e.id === entityConfId))
                .map((r) => r.name)
                .getOrElse(null),
    );
    return (state: RootState, props) => {
        if (!selectors[props.meta.form]) {
            selectors[props.meta.form] = formValueSelector(props.meta.form);
        }
        return {
            viewConfig: state.viewConfig,
            entityConfName: getEntityNameFromId(state, {
                entityConfId: selectors[props.meta.form](state, 'entityConfId'),
            }),
        };
    };
};
export default connect(makeMapStateToProps)(FileUpload);
