import { createCustomAction } from 'typesafe-actions';
import { GET_PROCESS_INSTANCE, GET_PROCESS_INSTANCE_FAILURE, GET_PROCESS_INSTANCE_SUCCESS } from './constants';
import ProcessInstance from 'bpm/types/processInstance';

export const getProcessInstance = createCustomAction(GET_PROCESS_INSTANCE, (type) => {
    return (processId: string) => ({ type, payload: processId });
});

export const getProcessInstanceSuccess = createCustomAction(GET_PROCESS_INSTANCE_SUCCESS, (type) => {
    return (processId: string, response: ProcessInstance) => ({
        type,
        processId,
        response,
    });
});

export const getProcessInstanceFailure = createCustomAction(GET_PROCESS_INSTANCE_FAILURE, (type) => {
    return (processId: string, error: Error) => ({
        type,
        requestPayload: processId,
        error,
    });
});
