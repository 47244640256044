import { getOfflineSubmitEntries } from 'offline_app/offline_stateful_tasks/back_online/components/getOfflineSubmitEntries';
import replaceId from './replaceId';
import persistEncryptData from 'offline_app/offline_entity_submits/persistEncryptData';

const updateEntriesPostCreation = async (taskId: string, temporaryId: string, createdId: string) => {
    const offlineSubmits = await getOfflineSubmitEntries(taskId);

    const keys = Object.keys(offlineSubmits);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const e = offlineSubmits[key];
        const transformedTarget = replaceId(temporaryId, createdId)(e.target);
        if (transformedTarget) {
            const newEntry = {
                taskId: e.taskId,
                target: transformedTarget,
            };
            await persistEncryptData(key, newEntry);
        }
    }
};

export default updateEntriesPostCreation;
