import { Action } from 'redux';

export interface TaskFormSubmitPayload {
    taskId: string;
    requestBody: {};
    // set this when we want to dispatch success ourselves,
    // for example after our cb promise completes.
    skipSuccessAction?: boolean;
}
export interface TaskFormSubmitAction extends Action {
    payload: TaskFormSubmitPayload;
    cb?: Function;
    errorCb?: () => void;
}

/**
 * [0]: taskId,
 * [1]: form representation
 * treating this as a 2-tuple, but maybe an object would be better due to added names
 */
export type TaskFormSavePayload = TaskFormSaveAction['payload'];
export type TaskFormSaveAction = ReturnType<(typeof taskFormEventCreator)['saveTaskForm']>;

export type TaskFormGetPayload = string | number;
export interface TaskFormGetAction extends Action {
    payload: TaskFormGetPayload;
}

const taskFormEventType = {
    get: 'GET_TASK_FORM',
    getSuccess: 'GET_TASK_FORM_SUCCESS',
    getFailure: 'GET_TASK_FORM_FAILURE',

    save: 'SAVE_TASK_FORM',
    saveSuccess: 'SAVE_TASK_FORM_SUCCESS',
    saveFailure: 'SAVE_TASK_FORM_FAILURE',

    submit: 'SUBMIT_TASK_FORM',
    submitSuccess: 'SUBMIT_TASK_FORM_SUCCESS',
    submitFailure: 'SUBMIT_TASK_FORM_FAILURE',
    cancel: 'SUBMIT_TASK_FORM_CANCEL',
} as const;

const taskFormEventCreator = {
    saveTaskForm: (
        taskId: string,
        formId: string,
        formData: { values: {}; entityFieldValues?: {} },
        cb: () => void = () => null,
        errorCb: () => void = () => null,
        isAutoSave: boolean = false,
    ) => {
        const payload = { formId, ...formData };
        return {
            type: taskFormEventType.save,
            payload: [taskId, payload] as const,
            cb,
            errorCb,
            isAutoSave,
        };
    },
    submitTaskForm: (
        taskId: string,
        formId: string,
        formData: { values: {}; entityFieldValues?: {} },
        outcome = 'Complete',
        outcomeDisplay = 'Complete',
        cb: (response: { nextTaskId?: string; processComplete: boolean; error: string }) => void = () => null,
        errorCb: () => void = () => null,
        skipSuccessAction: boolean = false,
    ): TaskFormSubmitAction => {
        const requestBody = { formId, ...formData, outcome, outcomeDisplay };
        return {
            type: taskFormEventType.submit,
            payload: {
                taskId,
                requestBody,
                skipSuccessAction,
            },
            cb,
            errorCb,
        };
    },
};

export { taskFormEventType, taskFormEventCreator };
