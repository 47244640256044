import pda from './actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { initial, pending, success, failure } from '@devexperts/remote-data-ts';
import { profileSelected } from 'auth/profiles/actions';
import { impersonateUserSuccess } from 'impersonate/actions';
import { serialize, serialrd } from 'reducers/lists/list/serializeDeserialize';

const userPrimaryDashboardReducer = (
    state: serialrd<false | string, Error> = serialize(initial),
    action: RootAction,
): serialrd<false | string, Error> => {
    switch (action.type) {
        case getType(impersonateUserSuccess):
        case getType(profileSelected):
            return serialize(initial);
        case getType(pda.getPrimaryDash): {
            return serialize(pending);
        }
        case getType(pda.getPrimaryDashSuccess): {
            return serialize(success(action.user.primaryDashboardId || false));
        }
        case getType(pda.setPrimaryDashSuccess): {
            return serialize(success(action.dashboardId || false));
        }
        case getType(pda.getPrimaryDashFailure): {
            return serialize(failure(JSON.parse(JSON.stringify(action.error))));
        }
        default:
            return state;
    }
};
export default userPrimaryDashboardReducer;
