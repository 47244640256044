import ViewConfig from '../../../reducers/ViewConfigType';
import { getAccessLevelForEntity, allowsEdit } from '../../../components/generics/utils/viewConfigUtils';

export const hasEditPermission = (viewConfig: ViewConfig, entityType: string) => {
    return allowsEdit(getAccessLevelForEntity(viewConfig, entityType));
};

export const getDefaultViewName = (viewConfig: ViewConfig, entityType: string) => {
    const type = hasEditPermission(viewConfig, entityType) ? 'EDIT' : 'SHOW';
    return viewConfig.entities[entityType].defaultViews[type].name;
};
