import React from 'react';

interface InjectErrorsToAllFieldsContext {
    set: (error: string | null) => void;
    error: string | null;
    WARN_PREFIX: '[warn]:';
}
const WARN_PREFIX = '[warn]:'; // quick way to support toggling as warning vs. error - include WARN_PREFIX at start.
const injectErrorsToAllFieldsContext = React.createContext<InjectErrorsToAllFieldsContext>({
    set: () => {
        console.warn('field disability override set called outside of context provider');
        return null;
    },
    error: null,
    WARN_PREFIX,
});

interface InjectErrorsToAllFieldsContextState {
    error: string | null;
}
interface InjectErrorsToAllFieldsContextProps {}
class InjectErrorsToAllFieldsContextProvider extends React.Component<
    InjectErrorsToAllFieldsContextProps,
    InjectErrorsToAllFieldsContextState
> {
    state: InjectErrorsToAllFieldsContextState = { error: null };
    set = (error: string | null) => {
        this.setState({ error });
    };
    render() {
        return (
            <injectErrorsToAllFieldsContext.Provider
                value={{
                    set: this.set,
                    error: this.state.error,
                    WARN_PREFIX,
                }}
            >
                {this.props.children}
            </injectErrorsToAllFieldsContext.Provider>
        );
    }
}

const TestingTools = (props) => {
    if (process.env.NODE_ENV === 'development') {
        return (
            <>
                <injectErrorsToAllFieldsContext.Consumer>
                    {(c) => {
                        const isError = c.error && !c.error.startsWith(c.WARN_PREFIX);
                        const isWarning = c.error && c.error.startsWith(c.WARN_PREFIX);
                        return (
                            <span>
                                <button onClick={() => c.set(isError ? null : 'Foo!')}>
                                    {isError ? 'uninject "Foo" errors' : 'Debug: inject "foo" errors'}
                                </button>
                                <button onClick={() => c.set(isWarning ? null : c.WARN_PREFIX + 'Foo!')}>
                                    {isWarning ? 'uninject "Foo" warnings' : 'Debug: inject "foo" warnings'}
                                </button>
                            </span>
                        );
                    }}
                </injectErrorsToAllFieldsContext.Consumer>
            </>
        );
    }
    return null;
};

const withInjectErrorsToAllFieldsContextProvider = (BaseComponent) => (props) =>
    (
        <InjectErrorsToAllFieldsContextProvider>
            <BaseComponent {...props} />
        </InjectErrorsToAllFieldsContextProvider>
    );
export {
    TestingTools,
    InjectErrorsToAllFieldsContextProvider,
    injectErrorsToAllFieldsContext,
    withInjectErrorsToAllFieldsContextProvider,
};
