import React, { useRef, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

type Props = {
    characters?: number;
    onChange: (res: string) => void;
    /**
     * @deprecated Since version 1.2.0 Will be deleted in version 2.0. Use inputClassName instead.
     */
    inputStyle?: React.CSSProperties;
    /**
     * @deprecated Since version 1.2.0 Will be deleted in version 2.0. Use containerClassName instead.
     */
    containerStyle?: React.CSSProperties;
    inputClassName?: string;
    containerClassName?: string;
    internalLabelPrefix: string;
    // Element to display below when the code is required, and the values are incomplete
    RequiredErrorMessage?: JSX.Element | null;
    focusOnMount?: boolean;
} & (
    | {
          inputType?: '*';
          allowedCharacters?: RegExp;
          password?: boolean;
      }
    | {
          inputType: 'numeric';
      }
);
const getErrorMessageId = (() => {
    let id = 1;
    return () => {
        return 'authcode-errormessage-' + id++;
    };
})();
const AuthCode: React.FC<Props> = (props) => {
    const {
        characters = 6,
        onChange,
        inputStyle,
        containerStyle,
        inputClassName,
        containerClassName,
        internalLabelPrefix,
        RequiredErrorMessage,
        focusOnMount = false,
    } = props;
    const errormessageId = useMemo(() => getErrorMessageId(), []);
    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const fom = useRef(focusOnMount);
    useEffect(() => {
        if (fom.current) {
            inputsRef.current[0].focus();
        }
    }, []);

    const sendResult = () => {
        const res = inputsRef.current.map((input) => input.value).join('');
        onChange(res);
    };

    const isValidInputValue = (value: string) => {
        if (props.inputType === 'numeric') {
            return value.match('^[0-9]*$');
        }
        return value.match(props.allowedCharacters ?? '^[A-Za-z0-9]*$');
    };

    // using onInput instead of onChange because if you type the same value as existing, onChange isn't triggered.
    // and we don't get the focus moving to the next item.
    const handleOnInput = (e: React.FormEvent<HTMLInputElement>) => {
        if (isValidInputValue(e.currentTarget.value)) {
            if (e.currentTarget.nextElementSibling !== null) {
                (e.currentTarget.nextElementSibling as HTMLInputElement).focus();
            }
        } else {
            e.currentTarget.value = '';
        }
        sendResult();
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;
        if (key === 'Backspace') {
            if (target.value === '' && target.previousElementSibling !== null) {
                if (target.previousElementSibling !== null) {
                    (target.previousElementSibling as HTMLInputElement).focus();
                    e.preventDefault();
                }
            } else {
                target.value = '';
            }
            sendResult();
        }
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const value = e.clipboardData.getData('Text');
        if (isValidInputValue(value)) {
            for (let i = 0; i < characters && i < value.length; i++) {
                inputsRef.current[i].value = value.charAt(i);
                if (inputsRef.current[i].nextElementSibling !== null) {
                    (inputsRef.current[i].nextElementSibling as HTMLInputElement).focus();
                }
            }
            sendResult();
        }
        e.preventDefault();
    };
    const intl = useIntl();

    const inputs = [];
    for (let i = 0; i < characters; i++) {
        inputs.push(
            <input
                pattern={props.inputType === 'numeric' ? '[0-9]*' : undefined}
                inputMode={props.inputType === 'numeric' ? 'numeric' : undefined}
                key={i}
                aria-label={`${internalLabelPrefix} ${intl.formatMessage(
                    { id: 'auth.code.characterOf' },
                    { '0': i + 1, '1': characters },
                )}`}
                aria-describedby={!inputsRef.current?.[i]?.value && RequiredErrorMessage ? errormessageId : undefined}
                onInput={handleOnInput}
                onKeyDown={handleOnKeyDown}
                onFocus={handleOnFocus}
                onPaste={handleOnPaste}
                type={props.inputType !== 'numeric' && props.password ? 'password' : 'text'}
                ref={(el: HTMLInputElement) => (inputsRef.current[i] = el)}
                maxLength={1}
                className={inputClassName}
                style={inputStyle}
            />,
        );
    }

    return (
        <div className={containerClassName} style={containerStyle}>
            {inputs}
            {RequiredErrorMessage && <div id={errormessageId}>{RequiredErrorMessage}</div>}
        </div>
    );
};

export default AuthCode;
