import { createCustomAction } from 'typesafe-actions';
import {
    CLOSE_PROFILE_PROMPT,
    PROFILES_LOADED,
    PROFILE_SELECTED,
    PROMPT_PICK_PROFILE,
    SET_STARTING_USERID,
} from './constants';

export interface ProfileEntry {
    userId: string;
    name: string;
}
export const profilesLoaded = createCustomAction(PROFILES_LOADED, (type) => {
    return (profiles: ProfileEntry[], defaultUserId?: string) => ({
        type,
        payload: {
            profiles,
            defaultUserId,
        },
    });
});
export const setStartingUserId = createCustomAction(SET_STARTING_USERID, (type) => {
    return (userId: string) => ({
        type,
        payload: {
            userId,
        },
    });
});
export const promptPickProfile = createCustomAction(PROMPT_PICK_PROFILE, (type) => {
    return () => ({
        type,
    });
});

export const closeProfilePrompt = createCustomAction(CLOSE_PROFILE_PROMPT, (type) => {
    return () => ({
        type,
    });
});

export const profileSelected = createCustomAction(PROFILE_SELECTED, (type) => {
    return (userId: string) => ({
        type,
        payload: {
            userId,
        },
    });
});
