import React from 'react';
import {
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio,
    WithStyles,
    withStyles,
    Theme,
    createStyles,
    FormHelperText,
    FormGroup,
    Checkbox,
    FormLabel,
} from '@material-ui/core';
import compose from 'recompose/compose';
import { valuesetOneHoc } from '../ValueSelectDownshift';
import { Concept } from '../Concept';
import get from 'lodash/get';
import { ValuesetKeeperArounder } from '../ValuesetKeeperArounder';
import classnames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import uniqueId from 'lodash/uniqueId';
import { WarningOrErrorUtils } from 'fieldFactory/input/hooks/useWarningOrErrorUtils';

type Input = any;
type Meta = any;

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        checkBox: {
            width: 24,
            height: 24,
            marginLeft: 0,
        },
        addPadding: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        formControlLabelVertical: {
            // this prevents whitespace to right being clickable
            // when radio options are listed vertically
            alignSelf: 'flex-start',
            marginRight: 'auto',
        },
        error: {
            color: palette.error.main,
            fontSize: '0.75rem',
        },
    });

export interface ConnectedComponentProps {
    labelledBy?: string;
    id: string;
    direction?: 'HORIZONTAL' | 'VERTICAL';
    deselectable?: boolean;
    valueSet?: string;
    resource: string;
    source: string;
    shouldFetchValueset?: boolean;
    disabled?: boolean;
    record?: {};
    label: string | null;
    input: Input;
    meta: Meta;
    ariaInputProps?: {};
    renderLabel?: boolean;
    group?: string;
}

interface ValuesetCheckboxProps extends ConnectedComponentProps, WithStyles<typeof styles> {
    dataTableByDisplay: {
        [display: string]: Concept;
    };
}
const removeId = (name: string) => (name && name.endsWith('Id') ? name.slice(0, -2) : name);

class ValuesetCheckbox extends React.Component<ValuesetCheckboxProps> {
    private helperTextId = uniqueId('valueset-checkbox-errorid');
    private legendId = uniqueId('valueset-checkbox-legendid');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    public valuesetKeeperArounder: ValuesetKeeperArounder = new ValuesetKeeperArounder();

    getValue = () => {
        const conceptIds = fromNullable(this.props.input && this.props.input.value).getOrElse(
            get(this.props.record, this.props.source),
        );
        if (conceptIds !== undefined) {
            if (conceptIds instanceof Array) {
                conceptIds.forEach((conceptId) => this.valuesetKeeperArounder.add(conceptId));
            } else {
                this.valuesetKeeperArounder.add(conceptIds);
            }
        }
        return conceptIds;
    };
    isChecked = (id) => {
        return this.getValue() && `${this.getValue()}` === `${id}`;
    };
    handleChange = (event) => {
        const id: string | number | null | undefined = event.target.value;
        if (!this.props.input) {
            return;
        }
        if (id === this.getValue()) {
            this.props.input.onBlur(null);
        } else {
            this.props.input.onBlur(id);
        }
    };
    getFlexDirection = (): 'row' | 'column' => {
        const { direction = 'VERTICAL' } = this.props;
        return direction === 'HORIZONTAL' ? 'row' : 'column';
    };
    makeCheckBoxes = (dataTableByDisplay) => {
        // Make the checkboxes from the datatable
        const {
            deselectable,
            disabled,
            classes,
            meta: { touched, error } = { touched: false, error: '' },
        } = this.props;
        const options = Object.keys(dataTableByDisplay).filter(
            (key) =>
                this.props.dataTableByDisplay[key].active ||
                this.valuesetKeeperArounder.encountered(this.props.dataTableByDisplay[key].id),
        );
        if (options.length === 0) {
            return null;
        }
        const groupProps = {
            style: {
                display: 'flex',
                flexDirection: this.getFlexDirection(),
                justifyContent: this.getFlexDirection() === 'row' ? 'flex-start' : undefined,
                flexWrap: 'wrap',
            },
        } as const;
        if (deselectable) {
            return (
                <FormGroup {...groupProps}>
                    {options.map((displayKey) => {
                        const id = this.props.dataTableByDisplay[displayKey]?.id;
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.isChecked(id)}
                                        name={displayKey}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.props.input.onBlur(id);
                                            } else if (this.props.input.value === id) {
                                                this.props.input.onBlur(null);
                                            }
                                        }}
                                        className={classnames(classes.checkBox, {
                                            [classes.addPadding]: this.getFlexDirection() === 'row' ? true : false,
                                        })}
                                    />
                                }
                                disabled={disabled}
                                label={dataTableByDisplay[displayKey].display}
                                value={`${id}`}
                                key={displayKey}
                            />
                        );
                    })}
                </FormGroup>
            );
        }
        return (
            <RadioGroup
                {...groupProps}
                aria-labelledby={this.legendId}
                tabIndex={0}
                name={removeId(this.props.source)}
                value={this.getValue() ? `${this.getValue()}` : ''}
                onChange={this.handleChange}
                onBlur={() => this.props.input && this.props.input.onBlur(undefined)}
            >
                {options.map((key) => (
                    <FormControlLabel
                        control={
                            <Radio
                                className={classnames(classes.checkBox, {
                                    [classes.addPadding]: this.getFlexDirection() === 'row' ? true : false,
                                })}
                            />
                        }
                        disabled={this.props.disabled}
                        label={this.props.dataTableByDisplay[key].display}
                        value={`${this.props.dataTableByDisplay[key].id}`}
                        key={key}
                        className={
                            this.getFlexDirection() === 'column'
                                ? classnames(classes.formControlLabelVertical)
                                : undefined
                        }
                    />
                ))}
            </RadioGroup>
        );
    };
    render() {
        const { label, dataTableByDisplay, renderLabel, meta = {}, classes } = this.props;
        const fieldSetProps = {
            ...this.props.ariaInputProps,
            'aria-invalid': Boolean(meta.touched && meta.error),
            'aria-describedby': meta.touched && meta.error ? this.helperTextId : undefined,
        };
        return (
            <WarningOrErrorUtils meta={meta}>
                {({ muiErrorProp, textErrorClass, helperText }) => (
                    <>
                        <FormControl
                            {...fieldSetProps}
                            component="fieldset"
                            margin="none"
                            style={{ width: '100%' }}
                            error={muiErrorProp}
                        >
                            {label && renderLabel && (
                                <FormLabel id={this.legendId} component="legend" className={textErrorClass}>
                                    {label}
                                </FormLabel>
                            )}
                            <div style={{ marginTop: '.5em', marginLeft: '11px' }}>
                                {this.makeCheckBoxes(dataTableByDisplay)}
                            </div>
                        </FormControl>
                        {muiErrorProp ? (
                            <FormHelperText
                                aria-live="assertive"
                                id={this.helperTextId}
                                className={classnames(classes.error, textErrorClass)}
                            >
                                {helperText}
                            </FormHelperText>
                        ) : null}
                    </>
                )}
            </WarningOrErrorUtils>
        );
    }
}

const Multicheckbox = compose(valuesetOneHoc, withStyles(styles))(ValuesetCheckbox);

export default Multicheckbox;
