import { RootAction } from 'actions/rootAction';
import { isAjaxSuccessAction } from 'reauth-interval-and-retries/isRequestSuccess';

type LastAjaxSuccess = number | null;
const lastAjaxSuccessReducer = (state: LastAjaxSuccess = null, action: RootAction) => {
    if (isAjaxSuccessAction(action)) {
        return Date.now();
    }
    return state;
};
export default lastAjaxSuccessReducer;
