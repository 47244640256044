import ViewConfig from 'reducers/ViewConfigType';
import { fromPredicate, fromEither } from 'fp-ts/lib/Option';
import { parseConfig } from 'expressions/entityViewConfig/parse';

const getViewConf = (viewConfig: ViewConfig, viewName?: string) =>
    fromPredicate<string>(Boolean)(viewName && viewConfig?.views?.[viewName]?.config).chain((c) =>
        fromEither(
            parseConfig(c).mapLeft((e) => {
                console.error(e);
                return e;
            }),
        ),
    );
export default getViewConf;
