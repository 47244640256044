import getImpl from 'expressions/Provider/implementations/getImpl';

const injected = ['viewContext'];
export const convertFieldsRequiredToUseableFields = (fields: string[], includeInjected: boolean = false) =>
    fields
        .filter((f) => (includeInjected ? true : injected.indexOf(f) === -1))
        .map(
            (
                f, // get everything before the first _ALL_
            ) =>
                f.includes('._ALL_')
                    ? f.slice(0, f.indexOf('._ALL_'))
                    : f.includes('_ALL_')
                    ? f.slice(0, f.indexOf('_ALL_'))
                    : f, // < - don't think this is necessary but not removing it jic
        );

const getFieldsRequiredForExpression = (expression: string, includeArrayMappings = false) => {
    const impl = getImpl();
    const compiled = impl.compileExpression(expression);
    if (compiled.type === 'parse_failure') {
        throw new Error(compiled.msg);
    }
    if (includeArrayMappings) {
        return compiled.getPathsWithAll();
    }
    return convertFieldsRequiredToUseableFields(compiled.getExpansionsWithAll());
};
export default getFieldsRequiredForExpression;
