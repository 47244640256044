import React from 'react';
import getRenderer from '../../components/generics/genericList/renderList';
import ProcessList from '../ProcessList/index';
import RedirectShowButton from '../../components/generics/button/RedirectShowButton';

interface ProcessSelectorProps {
    initialSearch?: string;
    disableCaseTypeChange?: boolean;
    selectProcess: (appCaseId: string | number) => void;
}
interface ProcessSelectorState {
    location: { pathname: string; search: string };
}

class ProcessSelector extends React.Component<ProcessSelectorProps, ProcessSelectorState> {
    static renderer = getRenderer({}, {});
    constructor(props: ProcessSelectorProps) {
        super(props);
        this.state = {
            location: {
                pathname: '/processes',
                search: props.initialSearch || '?filter=%7B%22assignee_ANY%22%3Atrue%7D',
            },
        };
    }
    updateLocation = (search: string | ProcessSelectorState['location']) => {
        if (typeof search === 'string') {
            this.setState((prevState) => ({ ...prevState, location: { ...prevState.location, search } }));
        } else {
            this.setState({
                location: search,
            });
        }
    };
    render() {
        return (
            <ProcessList
                disableCaseTypeChange={this.props.disableCaseTypeChange}
                renderList={(r) =>
                    ProcessSelector.renderer({
                        ...r,
                        onRowSelect: (sel, allData) => {
                            if (sel[0]) {
                                this.props.selectProcess(sel[0].id);
                            }
                        },
                        // example.
                        renderAtRowEnd: (rpps, record) => [
                            <RedirectShowButton
                                key="RedirectShowButtonForProcess"
                                resource={rpps.resource}
                                record={record}
                            />,
                        ],
                    })
                }
                location={this.state.location}
                interceptLocationChange={this.updateLocation}
            />
        );
    }
}
export default ProcessSelector;
