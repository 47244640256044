import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Address2Props } from './types';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import useEvaluateInEntityContext, {
    EvaluateInEntityContextOptions,
    useEvaluatorInEntityContext,
} from 'expressions/hooks/useEvaluateInEntityContext';
import buildHeaders from 'sideEffect/buildHeaders';
import getImpl from 'expressions/Provider/implementations/getImpl';
import useValueSets from 'util/hooks/useValueSets';
import useConcepts from 'util/hooks/useConcepts';
import { useStore } from 'react-redux';
import { change } from 'redux-form';
import useViewConfig from 'util/hooks/useViewConfig';
import { getFieldSourceFromPath, getValueSetForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import useRemoteData from 'util/hooks/useRemoteData';
import {
    Button,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import isEqualWith from 'lodash/isEqualWith';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';

/**
 *
 * isEqual, but treat '', and null the same
 */
const valuesEqual = (left: Record<string, unknown>, right: Record<string, unknown>): boolean => {
    return isEqualWith(left, right, (l, r) => {
        if (l === '' && r === null) {
            return true;
        }
        if (r === '' && l === null) {
            return true;
        }
        return undefined;
    });
};

const config = require('../../../../config.js');

interface ApiAddress {
    casetivityCity: string;
    casetivityCityCode: string;
    casetivityCounty: string;
    casetivityState: string;
    casetivityStateCode: string;
    casetivityStreet: string;
    casetivityUnit: string;
    casetivityZip: string;
    casetivityZipPlus: string;
    latitude: number;
    longitude: number;
    oneLiner: string;
}
interface Suggestion extends ApiAddress {
    addressValidationDate: string;
    latitude: number;
    longitude: number;
    censusBlock: string;
    censusKey: string;
    censusTract: string;
    melissaAddressKey: string;
    matchFound: 'Match found' | 'Match not found';
    resultCodes: string[];
}

type Response = {
    current: ApiAddress;
    recommendations: [Suggestion];
    results: string[];
};

type MaybeGetFieldValueset = (field: string) => string | null;

const useFieldExpressionAttributes = (field: string, maybeGetFieldValueset: MaybeGetFieldValueset) => {
    const maybeValueSet = maybeGetFieldValueset(field);
    const valueSets = useValueSets();
    const concepts = useConcepts();
    const valueSet = maybeValueSet && valueSets[maybeValueSet];
    const lookupTable = useMemo(() => {
        const dict: {
            [code: string]: string; // id
        } = {};
        valueSet?.conceptIds?.forEach((id) => {
            const code = concepts[id]?.code;
            if (!code) {
                console.error(`code not found in valueset ${maybeValueSet} concept id ${id}`);
            } else {
                dict[code] = id;
            }
        });
        return dict;
    }, [valueSet, concepts, maybeValueSet]);
    return {
        maybeValueSet,
        lookupTable,
    };
};

/**
 *
 * @param expression SPEL expression containing at max, a single field (which we will update)
 * @param maybeGetFieldValueset returns a valueset string, if we need to look up the id for a concept to update the field in the expression
 */
const useGetUpdateField = (
    expression: string,
    maybeGetFieldValueset: MaybeGetFieldValueset,
    dispatchValue: (field: string, value: string) => void,
    rootEntity: string,
) => {
    const viewConfig = useViewConfig();
    const compiledExpression = useMemo(() => getImpl().compileExpression(expression), [expression]);
    const fields = compiledExpression.type === 'parse_failure' ? [] : compiledExpression.getPathsWithAll();
    if (fields.length === 0) {
        // no fields found
    }
    const [field] = fields;
    const { lookupTable, maybeValueSet } = useFieldExpressionAttributes(field, maybeGetFieldValueset);

    return (codeOrText: string) => {
        if (!maybeValueSet) {
            dispatchValue(field, codeOrText);
            return;
        }
        const id = lookupTable[codeOrText];
        if (!id) {
            // throw? not sure.
            return;
        }
        const fieldSource = getFieldSourceFromPath(viewConfig, rootEntity, field);
        dispatchValue(`${fieldSource}Id`, id);
        dispatchValue(`${fieldSource}Code`, codeOrText);
    };
};

const useGetUpdateVsManyField = (
    field: string,
    maybeGetFieldValueset: MaybeGetFieldValueset,
    dispatchValue: (field: string, value: string[]) => void,
    rootEntity: string,
) => {
    const viewConfig = useViewConfig();
    const { lookupTable, maybeValueSet } = useFieldExpressionAttributes(field, maybeGetFieldValueset);

    return useCallback(
        (codes: null | string[]) => {
            if (!field) {
                return;
            }
            if (!maybeValueSet) {
                console.error(`Valueset couldn't be looked up for field "${field}" in field "${field}"`);
                return;
            }
            const fieldSource = getFieldSourceFromPath(viewConfig, rootEntity, field);
            if (!codes || !Array.isArray(codes)) {
                dispatchValue(`${fieldSource}Ids`, null);
                dispatchValue(`${fieldSource}Codes`, null);
                return;
            }
            const ids = codes.map((code) => lookupTable[code]);
            if (ids.some((id) => !id)) {
                // throw? not sure.
                console.error(
                    `At least one code in 'resultCodes' couldn't be looked up:\n codes: ${JSON.stringify(
                        codes,
                    )}\n ids:${JSON.stringify(ids)}`,
                );
                return;
            }

            dispatchValue(`${fieldSource}Ids`, ids);
            dispatchValue(`${fieldSource}Codes`, codes);
        },
        [dispatchValue, lookupTable, field, maybeValueSet, rootEntity, viewConfig],
    );
};

const useUpdateWriteOnlyField = (
    key: keyof Address2Props['writeOnlyFields'],
    writeOnlyFields: Address2Props['writeOnlyFields'],
    dispatchChange: (field: string, value: unknown) => void,
) => {
    const field = writeOnlyFields[key];
    return useCallback(
        (value: unknown) => {
            if (!field) {
                return;
            }
            dispatchChange(field, value);
        },
        [field, dispatchChange],
    );
};

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
    },
}));

const Address2 = (props: Address2Props) => {
    const {
        input: { onBlur },
    } = props;
    const classes = useStyles();
    const options: EvaluateInEntityContextOptions = {
        useLiveValues: true,
        throwOnException: false,
        defaultOnException: null,
    };
    const viewConfig = useViewConfig();
    const store = useStore();
    const viewName = useCurrentFormContext().viewName;
    const rootEntity = viewConfig.views[viewName].entity;
    const line1 = useEvaluateInEntityContext(props.addressExpressions.line1, options);
    const evalLine1 = useEvaluatorInEntityContext(props.addressExpressions.line1, options);
    const line2 = useEvaluateInEntityContext(props.addressExpressions.line2, options);
    const evalLine2 = useEvaluatorInEntityContext(props.addressExpressions.line2, options);
    const city = useEvaluateInEntityContext(props.addressExpressions.city, options);
    const evalCity = useEvaluatorInEntityContext(props.addressExpressions.city, options);
    const county = useEvaluateInEntityContext(props.addressExpressions.county, options);
    const evalCounty = useEvaluatorInEntityContext(props.addressExpressions.county, options);
    const state = useEvaluateInEntityContext(props.addressExpressions.state, options);
    const evalState = useEvaluatorInEntityContext(props.addressExpressions.state, options);
    const zip = useEvaluateInEntityContext(props.addressExpressions.zip, options);
    const evalZip = useEvaluatorInEntityContext(props.addressExpressions.zip, options);

    const acceptedValuesRef = useRef({
        line1,
        line2,
        city,
        county,
        state,
        zip,
    });

    const getMaybeValuesetFromField = useCallback(
        (field: string) => {
            if (!field) {
                return undefined;
            }
            const source = getFieldSourceFromPath(viewConfig, rootEntity, field);
            try {
                return getValueSetForFieldExpr(viewConfig, rootEntity, source, 'TRAVERSE_PATH');
            } catch (e) {
                return undefined;
            }
        },
        [viewConfig, rootEntity],
    );

    const dispatchChange = useCallback(
        (field, value) => {
            store.dispatch(change(props.meta.form, field, value));
        },
        [store, props.meta.form],
    );

    const updateLine1 = useGetUpdateField(
        props.addressExpressions.line1,
        getMaybeValuesetFromField,
        (field, value) => {
            acceptedValuesRef.current.line1 = evalLine1({ [field]: value });
            dispatchChange(field, value);
        },
        rootEntity,
    );
    const updateLine2 = useGetUpdateField(
        props.addressExpressions.line2,
        getMaybeValuesetFromField,
        (field, value) => {
            acceptedValuesRef.current.line2 = evalLine2({ [field]: value });
            dispatchChange(field, value);
        },
        rootEntity,
    );
    const updateCity = useGetUpdateField(
        props.addressExpressions.city,
        getMaybeValuesetFromField,
        (field, value) => {
            acceptedValuesRef.current.city = evalCity({ [field]: value });
            dispatchChange(field, value);
        },
        rootEntity,
    );
    const updateCounty = useGetUpdateField(
        props.addressExpressions.county,
        getMaybeValuesetFromField,
        (field, value) => {
            acceptedValuesRef.current.county = evalCounty({ [field]: value });
            dispatchChange(field, value);
        },
        rootEntity,
    );
    const updateState = useGetUpdateField(
        props.addressExpressions.state,
        getMaybeValuesetFromField,
        (field, value) => {
            acceptedValuesRef.current.state = evalState({ [field]: value });
            dispatchChange(field, value);
        },
        rootEntity,
    );
    const updateZip = useGetUpdateField(
        props.addressExpressions.zip,
        getMaybeValuesetFromField,
        (field, value) => {
            acceptedValuesRef.current.zip = evalZip({ [field]: value });
            dispatchChange(field, value);
        },
        rootEntity,
    );

    // now writeOnlyFields
    const updateZipPlus = useUpdateWriteOnlyField('zipPlus', props.writeOnlyFields, dispatchChange);
    const updateLongitude = useUpdateWriteOnlyField('longitude', props.writeOnlyFields, dispatchChange);
    const updateLatitude = useUpdateWriteOnlyField('latitude', props.writeOnlyFields, dispatchChange);
    const updateCensusBlock = useUpdateWriteOnlyField('censusBlock', props.writeOnlyFields, dispatchChange);
    const updateCensusKey = useUpdateWriteOnlyField('censusKey', props.writeOnlyFields, dispatchChange);
    const updateCensusTract = useUpdateWriteOnlyField('censusTract', props.writeOnlyFields, dispatchChange);
    const updateMelissaAddressKey = useUpdateWriteOnlyField('melissaAddressKey', props.writeOnlyFields, dispatchChange);
    const updateAddressValidationDate = useUpdateWriteOnlyField(
        'addressValidationDate',
        props.writeOnlyFields,
        dispatchChange,
    );
    const updateResultCodes = useGetUpdateVsManyField(
        props.writeOnlyFields.resultCodes,
        getMaybeValuesetFromField,
        dispatchChange,
        rootEntity,
    );

    const {
        state: gisLookupState,
        fold: gisLookupFold,
        setSuccess: setGisLookupSuccess,
        setError: setGisLookupError,
        setPending: setGisLookupPending,
        setInitial: setGisLookupInitial,
    } = useRemoteData<Response, string>();
    const tryIt = async () => {
        setGisLookupPending();
        fetch(`${config.API_URL}verify`, {
            method: 'POST',
            // credentials: 'same-origin',
            credentials: 'include',
            headers: buildHeaders({
                includeCredentials: true,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                casetivityStreet: line1,
                casetivityUnit: line2,
                casetivityCity: city,
                casetivityZip: zip,
                casetivityState: state,
            }),
        })
            .then((r) => r.json())
            .then((r: Response) => {
                setGisLookupSuccess(r);
            })
            .catch((e) => {
                console.error(e);
                setGisLookupError(e.message);
            });
    };

    const clearWriteOnlyFields = useCallback(() => {
        updateZipPlus(null);
        updateLongitude(null);
        updateLatitude(null);
        updateCensusBlock(null);
        updateCensusKey(null);
        updateCensusTract(null);
        updateMelissaAddressKey(null);
        updateAddressValidationDate(null);
        updateResultCodes(null);
    }, [
        updateZipPlus,
        updateLongitude,
        updateLatitude,
        updateCensusBlock,
        updateCensusKey,
        updateCensusTract,
        updateMelissaAddressKey,
        updateAddressValidationDate,
        updateResultCodes,
    ]);

    useEffect(() => {
        if (
            !valuesEqual(acceptedValuesRef.current, {
                line1,
                line2,
                city,
                county,
                state,
                zip,
            })
        ) {
            onBlur('NO_MATCH');
            clearWriteOnlyFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [line1, line2, city, county, state, zip, clearWriteOnlyFields]);

    const expressionTesterOpen = useExpressionTesterOpen() === 'OPEN_ALL';

    const setVerified = useCallback(() => {
        setGisLookupInitial();
        onBlur('FOUND_ADDRESS');
    }, [onBlur, setGisLookupInitial]);

    const continueNoMatch = useCallback(() => {
        setGisLookupInitial();
        acceptedValuesRef.current = {
            line1,
            line2,
            county,
            state,
            city,
            zip,
        };
        onBlur('MANUAL_OVERRIDE');
        clearWriteOnlyFields();
    }, [setGisLookupInitial, onBlur, line1, line2, county, state, city, zip, clearWriteOnlyFields]);
    const renderData = (response: Response) => {
        return (
            <Collapse mountOnEnter={true} appear={true} unmountOnExit={true} in={true}>
                {response.recommendations.length > 0 ? <Divider style={{ marginTop: '.5em' }} /> : null}
                {(() => {
                    const [recc] = response.recommendations;
                    if (!recc || recc.matchFound === 'Match not found') {
                        return (
                            <>
                                <Alert
                                    severity="warning"
                                    action={
                                        <Button onClick={continueNoMatch} size="small" variant="contained">
                                            Continue (no match)
                                        </Button>
                                    }
                                >
                                    Match not found
                                </Alert>
                            </>
                        );
                    }
                    return (
                        <List style={{ overflow: 'auto', maxHeight: 300 }}>
                            {response.recommendations.map((s, i) => {
                                return (
                                    <ListItem role="listitem" key={i}>
                                        <ListItemText primary={s.oneLiner} />

                                        <Button
                                            size="small"
                                            onClick={() => {
                                                updateLine1(s.casetivityStreet);
                                                updateLine2(s.casetivityUnit);
                                                updateCity(s.casetivityCity);
                                                updateCounty(s.casetivityCounty);
                                                updateState(s.casetivityState);
                                                updateZip(s.casetivityZip);

                                                updateZipPlus(s.casetivityZipPlus);
                                                updateLongitude(s.longitude);
                                                updateLatitude(s.latitude);
                                                updateCensusBlock(s.censusBlock);
                                                updateCensusKey(s.censusKey);
                                                updateCensusTract(s.censusTract);
                                                updateMelissaAddressKey(s.melissaAddressKey);
                                                updateAddressValidationDate(s.addressValidationDate);
                                                updateResultCodes(s.resultCodes);

                                                setVerified();
                                            }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Select
                                        </Button>
                                    </ListItem>
                                );
                            })}
                            <ListItem role="listitem">
                                <ListItemText primary="" />
                                <ListItemSecondaryAction>
                                    <Button onClick={continueNoMatch} variant="contained" size="small">
                                        Continue (no match)
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    );
                })()}
            </Collapse>
        );
    };
    return (
        <div>
            <Button variant="contained" color="primary" onClick={tryIt}>
                Verify Address
            </Button>
            {gisLookupFold(
                () => null,
                (prev) => (prev ? renderData(prev) : null),
                renderData,
                (error) => (
                    <div>Error. {error}</div>
                ),
            )}
            {props.meta.error && (
                <span className={classes.error} style={{ marginTop: '1em', display: 'inline-block' }}>
                    {props.meta.error}
                </span>
            )}
            {expressionTesterOpen && (
                <>
                    <p>value: {props.input.value}</p>
                    <pre>
                        {JSON.stringify(
                            {
                                line1,
                                line2,
                                city,
                                county,
                                state,
                                zip,
                            },
                            null,
                            1,
                        )}
                    </pre>
                </>
            )}
        </div>
    );
};

export default Address2;
