import React, { FunctionComponent, useContext } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { TextField } from '@material-ui/core';
import { DebouncedField } from 'fieldFactory/input/components/DebouncedTextInput';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';

type ControllerProps = React.ComponentProps<typeof Controller>;
const ControlledTextField: FunctionComponent<
    Pick<ControllerProps, 'label' | 'name'> &
        Partial<Pick<ControllerProps, 'defaultValue' | 'rules' | 'disabled'>> & {
            debounced?: boolean;
        }
> = (props) => {
    const methods = useFormContext();
    const { errors, control } = methods;

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <Controller
            disabled={props.disabled}
            rules={props.rules}
            InputLabelProps={getInputLabelProps({ shrink: true })}
            variant={fieldVariant}
            label={props.label}
            as={props.debounced ? DebouncedField : TextField}
            margin="normal"
            error={Array.isArray(errors[props.name]) ? errors[props.name].length > 0 : Boolean(errors[props.name])}
            helperText={<ErrorMessage errors={errors} name={props.name} />}
            defaultValue={props.defaultValue}
            fullWidth
            name={props.name}
            control={control}
        />
    );
};

export default ControlledTextField;
