import React, { FunctionComponent, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextField } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import get from 'lodash/get';

interface EditTitleOverrideFormProps {
    initialExpression?: string;
    onSubmit: (data: FormData) => void;
}

interface FormData {
    titleOverrideExpression: string;
}

const EditTitleOverrideForm: FunctionComponent<EditTitleOverrideFormProps> = (props) => {
    const { onSubmit, initialExpression } = props;
    const methods = useForm<FormData>({
        defaultValues: {
            titleOverrideExpression: initialExpression,
        },
        mode: 'onChange',
    });
    const { errors } = methods;
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Controller
                InputLabelProps={getInputLabelProps({ shrink: true })}
                label="Title Expression"
                variant={fieldVariant}
                name="titleOverrideExpression"
                fullWidth
                control={methods.control}
                defaultValue={initialExpression ?? ''}
                margin="normal"
                as={TextField}
                error={Boolean(get(errors, 'titleOverrideExpression'))}
                helperText={get(errors, 'titleOverrideExpression')?.message}
            />
            <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                Save
            </Button>
        </form>
    );
};
export default EditTitleOverrideForm;
