import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import GenericList from 'components/generics/genericList';
import getListRenderer from 'components/generics/genericList/renderList';
import { push as pushAction } from 'connected-react-router';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';

const styles = (theme: Theme) =>
    createStyles({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            position: 'relative',
            overflowY: 'auto',
            maxHeight: 270,
            '@media print': {
                overflowY: 'unset',
                maxHeight: 'unset',
            },
        },
    });
interface MyReportsWidgetProps {
    reportName: string[];
    outputContentType: string[];
    viewName: string;
    running: boolean;
    longRunning: boolean;
    startTime: string;
}

const mapStateToProps = (state: RootState, props: MyReportsWidgetProps) => {
    return {
        viewNames: state.viewConfig && state.viewConfig.views,
    };
};

const dispatches = {
    redirect: pushAction,
};
type Dispatches = typeof dispatches;

interface MyReportsWidgetComponentProps
    extends Dispatches,
        MyReportsWidgetProps,
        ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles> {}
interface MyReportsWidgetState {
    location: {
        pathname: string;
        search: string;
    };
}
class MyReportsWidgetComponent extends Component<MyReportsWidgetComponentProps, MyReportsWidgetState> {
    state: MyReportsWidgetState = {
        location: {
            pathname: '',
            search: '',
        },
    };
    static renderer = getListRenderer({}, {});
    render() {
        const { reportName, outputContentType, viewName, running, longRunning, startTime } = this.props;
        const viewNameModified = viewName ? viewName : '_REPORT_LOG_LIST_DASHBOARD';
        let filterObject = {};
        if (reportName !== undefined && reportName.length > 0) {
            filterObject['reportName__IN'] = `${reportName}`;
        }
        if (outputContentType !== undefined && outputContentType.length > 0) {
            filterObject['outputContentType__IN'] = `${outputContentType}`;
        }
        if (running !== undefined && running) {
            filterObject['endTime__NOT_EMPTY'] = true;
        }
        if (longRunning !== undefined && longRunning) {
            filterObject['longRunning__EXACT'] = true;
        }
        if (startTime !== undefined && startTime) {
            filterObject['startTime__GREATER_EQUAL'] = `${startTime}`;
        }

        if (!(Object.keys(this.props.viewNames).filter((key) => key === viewNameModified).length === 1)) {
            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'rgba(0,0,0,0.6)',
                    }}
                >
                    <span style={{ flexShrink: 0 }}>View name is incorrect or not specified</span>
                </div>
            );
        } else {
            return (
                <div style={{ height: '100%' }}>
                    <GenericList
                        useCard={false}
                        actions={null}
                        onRowSelect={([reportLog]: any) => {
                            this.props.redirect(`/reportLog/${reportLog.id}/show`);
                        }}
                        overrideApi={'api/report-logs-by-current-user'}
                        filter={filterObject}
                        hasCreate={false}
                        multiSelectable={true}
                        updateUrlFromFilter={false}
                        viewName={viewNameModified}
                        showFilters={false}
                        resource={'ReportLog'}
                        showImmediately={true}
                        perPage={'25'}
                        renderList={(r) =>
                            getListRenderer(
                                this.props.classes,
                                {},
                            )({
                                ...r,
                                ariaProps: { ...r.ariaProps, 'aria-label': 'My Reports' },
                            })
                        }
                        location={this.state.location}
                        fakePush={(location) => {
                            this.setState((state) => ({ ...state, location }));
                        }}
                    />
                </div>
            );
        }
    }
}

const enhance = compose(connect(mapStateToProps, dispatches), withStyles(styles));

const MyReportsWidget: React.ComponentType<MyReportsWidgetProps> = enhance(MyReportsWidgetComponent);
export default MyReportsWidget;
