import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';

const setupFieldValuesFromTable = (
    tableId: string,
    tableData: { [field: string]: any }[],
    columnObj?: FormFieldUnion[], // if provided, null-initialize fields.
): { [newId: string]: any } => {
    let result = {};
    tableData.forEach((row, i) => {
        // null init this row
        if (columnObj) {
            columnObj.forEach((f) => {
                result[`${tableId}_c_${f.id}_${i}`] = null;
            });
        }
        Object.entries(row).forEach(([fieldId, value]) => {
            const valuePath = `${tableId}_c_${fieldId}_${i}`;
            result[`${tableId}_c_${fieldId}_${i}`] = value === '' || typeof value === 'undefined' ? null : value;
            if (Array.isArray(value)) {
                let r = columnObj?.find((col) => col.id === fieldId);
                if (r && r?.type === 'table') {
                    Object.assign(result, setupFieldValuesFromTable(valuePath, value, r.params?.columnObj));
                }
            }
        });
    });
    return result;
};

export default setupFieldValuesFromTable;
