import { combineReducers } from 'redux'; // eslint-disable-line import/no-extraneous-dependencies
import uniq from 'lodash/uniq';
import * as pd from 'bpm/processDefinitions/actions';
import ProcessDefinition from 'bpm/types/processDefinition';
import { getType } from 'typesafe-actions';
import { RootAction } from 'actions/rootAction';

export interface ById {
    [key: string]: ProcessDefinition;
}
const byId = (state: ById = {}, action: RootAction): ById => {
    switch (action.type) {
        case getType(pd.getProcessDefinitionsSuccess): {
            const {
                _data: {
                    entities: { ProcessDefinition },
                },
            } = action.payload;
            return Object.assign({}, state, ProcessDefinition);
        }
        default:
            return state;
    }
};

const allIds = (state: string[] = [], action: RootAction): string[] => {
    switch (action.type) {
        case getType(pd.getProcessDefinitionsSuccess): {
            const {
                _data: { result },
            } = action.payload;
            return uniq([...state, ...result]);
        }
        default:
            return state;
    }
};

const isLoading = (state: boolean = false, action: RootAction): boolean => {
    switch (action.type) {
        case getType(pd.getProcessDefinitionsLoading):
            return true;
        case getType(pd.getProcessDefinitionsFailure):
        case getType(pd.getProcessDefinitionsSuccess):
            return false;
        default:
            return state;
    }
};

export interface ProcessDefinitions {
    byId: ById;
    allIds: string[];
    isLoading: boolean;
}
const processDefinitions = combineReducers<ProcessDefinitions>({
    byId,
    allIds,
    isLoading,
});

export default processDefinitions;
