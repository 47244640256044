import { uniqueId } from 'lodash';
import moment from 'moment';
import React, { useMemo, useReducer, useRef } from 'react';
import {
    Distance,
    PeriodUnit,
    StartOrEnd,
    decodePeriodStartEnd,
    encodePeriodStartEnd,
    getPeriodStartEndFromPlainDate,
    getPlainDateFromPeriodStartEnd,
} from './utils';
import CasetivitySelect from 'components/CasetivitySelect';
import { InputLabel } from '@material-ui/core';

interface PeriodStartEndProps {
    label: string;
    value: string;
    relativeDateAs: 'diff' | 'date';
    onChange: (value: string) => void;
}
const PeriodStartEnd = (props: PeriodStartEndProps) => {
    const internalId = useMemo(() => uniqueId('period-start-end-'), []);
    const startOrEndId = internalId + '-startOrEnd';
    const distanceId = internalId + '-distance';
    const unitId = internalId + '-unit';

    const unitRef = useRef<PeriodUnit>(null);

    const [startOrEnd, distance, unit] = useMemo(() => {
        const date =
            props.relativeDateAs === 'diff'
                ? (() => {
                      try {
                          return decodePeriodStartEnd(props.value);
                      } catch (e) {
                          // we are switching between modes, so lets be graceful
                          console.error(e);
                          return props.value;
                      }
                  })()
                : props.value;
        const fallback: ReturnType<typeof getPeriodStartEndFromPlainDate> = moment(date).isValid()
            ? ['startOf', 0, 'week']
            : [null, null, null];
        return getPeriodStartEndFromPlainDate(date, unitRef.current) ?? fallback;

        // let's let it rerender if unitRef.current changes, because we still must recalculate our unit if unitRef.current changed, even though
        // the date might not have changed. e.g. changing to 'quarter' from 'month' if the month is the first of the quarter - the date is not different,
        // although the unit is.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, props.relativeDateAs, unitRef.current]);

    if (unit !== null) {
        unitRef.current = unit;
    }

    const startOrEndRef = useRef(startOrEnd);
    if (startOrEnd !== null) {
        startOrEndRef.current = startOrEnd;
    }

    const distanceRef = useRef(distance);
    if (distance !== null) {
        distanceRef.current = distance;
    }

    const [, rerender] = useReducer((state) => state + 1, 1);

    const startOrEndValue = startOrEndRef.current ?? startOrEnd;
    const distanceValue = typeof distanceRef.current === 'number' ? '' + (distanceRef.current ?? '') : '';
    const unitValue = unitRef.current ?? unit;

    const handleChange = () => {
        if (!startOrEndRef.current || typeof distanceRef.current !== 'number' || !unitRef.current) {
            rerender();
            return;
        }
        const value = getPlainDateFromPeriodStartEnd(startOrEndRef.current, distanceRef.current, unitRef.current) || '';
        if (props.relativeDateAs === 'diff') {
            props.onChange(encodePeriodStartEnd(value, unitRef.current));
        } else {
            props.onChange(value);
        }
        rerender();
    };

    return (
        <div style={{ marginTop: '.5em' }}>
            <InputLabel shrink>{props.label}</InputLabel>
            {/* Start of or End of */}
            <span id={startOrEndId} className="casetivity-off-screen">{`${props.label} start or end`}</span>
            <CasetivitySelect
                style={{ minWidth: '100px' }}
                value={startOrEndValue}
                onChange={(event) => {
                    const startOrEnd = event.target.value as StartOrEnd;
                    startOrEndRef.current = startOrEnd;
                    handleChange();
                }}
                SelectDisplayProps={{
                    'aria-describedby': startOrEndId,
                }}
                label=""
            >
                {({ OptionComponent }) => {
                    const options: StartOrEnd[] = ['startOf', 'endOf'];
                    const labels: {
                        [key in StartOrEnd]: string;
                    } = {
                        startOf: 'Start of',
                        endOf: 'End of',
                    };
                    return options.map((option) => {
                        return (
                            <OptionComponent key={option} value={option}>
                                {labels[option]}
                            </OptionComponent>
                        );
                    });
                }}
            </CasetivitySelect>

            {/* Distance (current, prev, ...) */}
            <span id={distanceId} className="casetivity-off-screen">{`${props.label} current or previous`}</span>
            <CasetivitySelect
                style={{ minWidth: '100px' }}
                value={distanceValue}
                onChange={(event) => {
                    const distance = parseInt(event.target.value as string) as Distance;
                    distanceRef.current = distance;
                    handleChange();
                }}
                SelectDisplayProps={{
                    'aria-describedby': distanceId,
                }}
                label=""
            >
                {({ OptionComponent }) => {
                    const options: Distance[] = [0, -1];

                    return options.map((option) => {
                        return (
                            <OptionComponent key={'' + option} value={'' + option}>
                                {option === 0 ? 'current' : 'previous'}
                            </OptionComponent>
                        );
                    });
                }}
            </CasetivitySelect>

            {/* Unit */}
            <span id={unitId} className="casetivity-off-screen">{`${props.label} period`}</span>
            <CasetivitySelect
                style={{ minWidth: '100px' }}
                value={unitValue}
                onChange={(event) => {
                    const unit = event.target.value as PeriodUnit;
                    unitRef.current = unit;
                    handleChange();
                }}
                SelectDisplayProps={{
                    'aria-describedby': unitId,
                }}
                label=""
            >
                {({ OptionComponent }) => {
                    const options: PeriodUnit[] = ['week', 'month', 'quarter', 'year'];

                    return options.map((option) => {
                        return (
                            <OptionComponent key={option} value={option}>
                                {option}
                            </OptionComponent>
                        );
                    });
                }}
            </CasetivitySelect>
        </div>
    );
};

export default PeriodStartEnd;
