import React from 'react';
import PopoverRefInput from 'fieldFactory/popovers/PopoverRefInput';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';

const SelectTask: React.FC<{ taskId: string; formKey: string }> = ({ taskId, formKey }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    return (
        <div style={{ paddingTop: '1em' }}>
            <PopoverRefInput
                input={{
                    onBlur: (id, data) => {
                        if (typeof id !== 'undefined') {
                            // set new query parameters in the url

                            if (id) {
                                dispatch(push(pathname + '?processId=' + data.processInstanceId + '&taskId=' + id));
                            } else if (id === null) {
                                // clear query params
                                dispatch(push(pathname));
                            }
                        }
                    },
                    value: taskId,
                }}
                meta={{}}
                viewName="_TASK_LIST_DASHBOARD"
                reference="TaskInstance"
                resource="TaskInstance"
                source="foo"
                label="Preview Task Instance"
                options={{}}
                filterString={`formKey=${formKey}`}
            />
        </div>
    );
};
export default SelectTask;
