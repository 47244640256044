import { TasksFilter } from './taskEvent';
const processTasksEventType = {
    // emissions
    get: 'GET_TASKS_FOR_PROCESS',
    cancellableGet: 'CANCELLABLE_GET_TASKS_FOR_PROCESS',
    getCancelled: 'GET_TASKS_FOR_PROCESS_CANCELLED',
    // success types
    getAllSuccess: 'GET_TASKS_FOR_PROCESS_SUCCESS',
    getCompletedSuccess: 'GET_COMPLETED_TASKS_FOR_PROCESS_SUCCESS',
    getOpenSuccess: 'GET_OPEN_TASKS_FOR_PROCESS_SUCCESS',
    // failure types
    getFailure: 'GET_TASKS_FOR_PROCESS_FAILURE',
};

const processTasksEventCreator = {
    cancellableGetTasksForProcess: (processId, options: TasksFilter = {}) => ({
        type: processTasksEventType.cancellableGet,
        payload: { processId, options },
    }),
    getTasksForProcess: (processId, options: TasksFilter = {}) => ({
        type: processTasksEventType.get,
        payload: { processId, options },
    }),
};

export { processTasksEventType, processTasksEventCreator };
