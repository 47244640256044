import React, { useState, FunctionComponent } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    IconButton,
    CardActions,
    useTheme,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Popup from 'components/Popup';
import EditExpression from './EditExpression';
import Alert from '@material-ui/lab/Alert/Alert';

interface ExpressionEditorProps {
    viewName?: string;
    rows?: RowData[];
    setRows?: (rows: RowData[]) => void;
    appendIds?: boolean;
    widgetMapsToSourceInsteadOfId?: boolean;
}

export interface RowData {
    expression: string;
    fieldsRequired: string[];
    widget: string[];
    // ast: string;
}
const ExpressionEditor: FunctionComponent<ExpressionEditorProps> = (props) => {
    const [_rows, _setRows] = useState([]);
    const rows: RowData[] = props.rows || _rows;
    const setRows = props.setRows || _setRows;
    const theme = useTheme();
    /*
        [table you and add and remove rows to/from.]

    */
    const getRenderDialogContent =
        (initialValues?: RowData, i?: number) =>
        ({ closeDialog }) =>
            (
                <div style={{ padding: '1em', minWidth: '60vw' }}>
                    <EditExpression
                        widgetMapsToSourceInsteadOfId={props.widgetMapsToSourceInsteadOfId}
                        warn={(selectedWidgets = []) => {
                            const widgetsInOtherExpressions = selectedWidgets.filter((widget) =>
                                [...rows.slice(0, i), ...rows.slice(i + 1)].some((r) => r.widget?.includes(widget)),
                            );
                            if (widgetsInOtherExpressions.length === 0) {
                                return null;
                            }
                            return (
                                <ul
                                    style={{
                                        listStyle: 'none',
                                    }}
                                >
                                    <li>
                                        {widgetsInOtherExpressions.map((w) => {
                                            return (
                                                <Alert role="alertdialog" aria-live="assertive" severity="warning">
                                                    The widget <em>{w}</em> is currently used in another expression of
                                                    the same type
                                                </Alert>
                                            );
                                        })}
                                    </li>
                                </ul>
                            );
                        }}
                        appendIds={props.appendIds}
                        viewName={props.viewName}
                        initialValues={initialValues}
                        onSubmit={(rowData) => {
                            if (typeof i === 'number') {
                                const newData = [...rows.slice(0, i), rowData, ...rows.slice(i + 1)];
                                setRows(newData);
                                closeDialog();
                            } else {
                                setRows([...rows, rowData]);
                                closeDialog();
                            }
                        }}
                    />
                </div>
            );
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Expression</TableCell>
                        <TableCell>Widgets</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((r, i) => {
                        return (
                            <TableRow key={r.expression}>
                                <TableCell>{r.expression}</TableCell>
                                <TableCell>{r.widget && r.widget.join(', ')}</TableCell>
                                <Popup
                                    renderDialogContent={getRenderDialogContent(r, i)}
                                    renderToggler={({ openDialog }) => (
                                        <TableCell>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={openDialog()}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    )}
                                />
                                <Popup
                                    renderDialogContent={({ closeDialog }) => (
                                        <>
                                            <div style={{ margin: '1em' }}>Delete expression?</div>
                                            <CardActions>
                                                <Button onClick={closeDialog}>Cancel</Button>
                                                <Button
                                                    style={{ color: theme.palette.error.main }}
                                                    onClick={() => {
                                                        const newData = [...rows.slice(0, i), ...rows.slice(i + 1)];
                                                        setRows(newData);
                                                        closeDialog();
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </CardActions>
                                        </>
                                    )}
                                    renderToggler={({ openDialog }) => (
                                        <TableCell>
                                            <IconButton
                                                style={{ color: theme.palette.error.main }}
                                                size="small"
                                                onClick={openDialog()}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                />
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <br />
            <Popup
                ComponentProps={{
                    maxWidth: false,
                }}
                renderDialogContent={getRenderDialogContent()}
                renderToggler={({ openDialog }) => (
                    <Button size="small" variant="contained" color="primary" onClick={openDialog()}>
                        Add
                    </Button>
                )}
            />
        </div>
    );
};

export default ExpressionEditor;
