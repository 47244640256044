import { SET_AS_TOP_VIEW, UNSET_AS_TOP_VIEW } from '../actions/constants';

const viewStack = (state: string[] = [], action: { type: string; payload: string }) => {
    switch (action.type) {
        case SET_AS_TOP_VIEW:
            return [action.payload, ...state];
        case UNSET_AS_TOP_VIEW: {
            const [top, ...rest] = state;
            if (top !== action.payload) {
                // Error(`FormId (${action.payload}) to pop doesn't match top view in the stack (${top}).`);
                // this could be due to a page change. Just set it to [], but log to console
                console.warn(`FormId (${action.payload}) to pop doesn't match top view in the stack (${top}).
                If this is not due to a page change, this indicates a problem.`);
                // search for the first occurrance
                const indexOf = state.indexOf(action.payload);
                if (indexOf === -1) {
                    console.warn(`FormId (${action.payload}) not found in the view stack (${state}).
                    If this is not due to a page change, this is a problem.`);
                    return [];
                }
                return [...state.slice(0, indexOf), ...state.slice(indexOf + 1)];
            }
            return rest;
        }
        default:
            return state;
    }
};

export default viewStack;
