import { Option, none, some } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import fromEntries from 'util/fromentries';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import flattenObject from 'flat';
import flattenArray from 'lodash/flatten';
import deepEql from 'deep-eql';
import { processExpr } from '@mkanai/casetivity-shared-js/lib/spel/evaluate';
import { alertOnce } from './util';
import set from 'lodash/set';

export type Evaler = {
    methodsRequired: string[];
    fieldsRequired: string[];
    (context: {}): (values: {}) => any;
};

const useForErrors = (values: {}) => true;
const transformExpr = (__expression: string) => {
    const _expression = processExpr({ stripHashes: true })(__expression).trim();
    const expression = (() => {
        if (_expression.startsWith('[') && _expression.endsWith(']')) {
            return (JSON.parse(_expression) as string[]).join(' && ');
        }
        return _expression;
    })();
    return expression;
};
class CachingExpression {
    cachedValues: {
        [key: string]: Option<any>;
    };
    expression: string;
    cachedResult: Option<any>;
    getEvaluateFn: (context: {}) => (values: {}) => any;
    evaluateFn: (values: {}) => any;
    resultEquality: 'shalloweql' | 'deepeql';
    lastResultCameFromCache: boolean;
    didError: boolean;
    methodsRequired: string[];
    constructor(
        expression: string,
        evaluateFn: (__expression: string) => Evaler,
        initialContext: {},
        resultEquality: 'shalloweql' | 'deepeql',
    ) {
        this.didError = false;
        try {
            // most expressions differ by whitespace.
            // it would be best if, instead of an expression, we past a (parsed) ast representation of the expression
            // console.log('e: ', expression);
            this.expression = expression;
            this.cachedResult = none;

            const evaler = evaluateFn(this.expression);
            this.cachedValues = fromEntries(evaler.fieldsRequired.map((k) => [k, none] as [string, Option<any>]));
            this.methodsRequired = evaler.methodsRequired;
            this.getEvaluateFn = evaler;
            this.evaluateFn = this.getEvaluateFn(initialContext);
        } catch (e) {
            console.error(e);
            console.error(`The above error occurred for expression '${this.expression}' while parsing`);
            this.getEvaluateFn = (context: {}) => useForErrors;
            this.evaluateFn = useForErrors;
            alertOnce(this.expression, e);
            this.didError = true;
        }
        this.resultEquality = resultEquality;
        this.lastResultCameFromCache = false;
    }
    isEqual = (left: any, right: any) => {
        if (this.resultEquality === 'shalloweql') {
            return left === right;
        }
        return deepEql(left, right);
    };
    clearCache = (newContext: {}) => {
        if (!this.didError) {
            this.evaluateFn = this.getEvaluateFn(newContext);
            this.cachedResult = none;
        }
    };
    // return Some(x) if there is a new value x calculated.
    maybeEvaluate = (values: {}): Option<any> => {
        if (
            this.cachedResult.isNone() ||
            Object.entries(this.cachedValues).find(
                ([k, maybeValue]) => maybeValue.isNone() || maybeValue.value !== get(values, k),
            )
        ) {
            const newResult = tryCatch(
                () => this.evaluateFn(values),
                (e: Error) => {
                    alertOnce(this.expression, e);
                    this.didError = true; // this has reached an end-state.
                    this.cachedValues = {}; // No longer watch any values.
                    return e;
                },
            ).getOrElseL(() => useForErrors(values));
            if (this.cachedResult.isNone() || !this.isEqual(newResult, this.cachedResult.value)) {
                this.cachedResult = some(newResult);

                // update cached values for the new evaluation result
                Object.entries(this.cachedValues).forEach(([k, cachedValue]) => {
                    if (cachedValue.isNone() || !this.isEqual(get(values, k), cachedValue.value)) {
                        this.cachedValues[k] = some(get(values, k));
                    }
                });
                this.lastResultCameFromCache = false;
            } else {
                this.lastResultCameFromCache = true;
            }
        } else {
            this.lastResultCameFromCache = true;
        }
        return this.cachedResult;
    };
}

interface CachingEvaluatorState {
    [expression: string]: CachingExpression;
}

export class CachingEvaluator {
    state: CachingEvaluatorState;
    resultEquality: 'shalloweql' | 'deepeql';
    constructor(
        expressions: string[],
        evaluationFn: (expression: string) => Evaler,
        initialContext: {},
        resultEquality: 'shalloweql' | 'deepeql',
    ) {
        this.resultEquality = resultEquality;
        this.state = fromEntries(
            uniq(expressions).map((expression) => {
                return [
                    expression,
                    new CachingExpression(expression, evaluationFn, initialContext, this.resultEquality),
                ] as [string, CachingExpression];
            }),
        );
    }
    getChangedExpressions = () => {
        return Object.entries(this.state).flatMap(([key, ce]) => (!ce.lastResultCameFromCache ? [key] : []));
    };
    someValueWasRecalculated = () => {
        return Boolean(Object.values(this.state).find((ce) => !ce.lastResultCameFromCache));
    };
    deleteExpressions = (expressionsToDelete: string[]) => {
        expressionsToDelete.forEach((expression) => {
            delete this.state[expression];
        });
    };
    addExpressions = (expressionsToAdd: string[], evaluationFn: (expression: string) => Evaler, initialContext: {}) => {
        expressionsToAdd.forEach((expression) => {
            if (!this.state[expression]) {
                this.state[expression] = new CachingExpression(
                    expression,
                    evaluationFn,
                    initialContext,
                    this.resultEquality,
                );
            }
        });
    };
    replaceExpressionsPreservingCaches = (
        newExpressions: string[],
        evaluationFn: (expression: string) => Evaler,
        initialContext: {},
    ) => {
        // delete any that can be dropped
        this.deleteExpressions(Object.keys(this.state).filter((expression) => !newExpressions.includes(expression)));
        this.addExpressions(newExpressions, evaluationFn, initialContext);
    };
    clearCaches = (forExpressionsThatMatch: (expression) => boolean, newContext: {}) => {
        Object.keys(this.state).forEach((expression) => {
            if (forExpressionsThatMatch(expression)) {
                this.state[expression].clearCache(newContext);
            }
        });
    };
    // returns an object of any changed values.
    evaluateAll = (values: {}): {
        [expression: string]: any;
    } => {
        // below: works and probably cleanest, but a bit slow for such a hot path.
        // return fromEntries(
        //     mapOption(Object.entries(this.state), ([expression, cachingExp]) =>
        //         cachingExp.maybeEvaluate(values).map(r => [expression, r] as [string, any]),
        //     ),
        // );
        // faster implementation below:

        let res = {};
        Object.entries(this.state).forEach(([expression, cachingExp]) => {
            cachingExp.maybeEvaluate(values).fold(null, (r) => (res[expression] = r));
        });
        return res;
    };
}

export interface Expressions {
    [key: string]: string[] | Expressions;
}
const traverseExpressions = <ExpressionsShape extends Expressions>(
    expressions: ExpressionsShape,
    cb: (path: string[], item: Expressions | string[]) => void,
    currPath: string[] = [],
) => {
    if (expressions) {
        Object.entries(expressions).forEach(([key, value]) => {
            const path = [...currPath, key];
            cb(path, value);
            if (!Array.isArray(value)) {
                traverseExpressions(value, cb, path);
            }
        });
    }
};
const transformExpressions = <ExpressionsShape extends Expressions>(expressions: ExpressionsShape) => {
    let result = {};
    traverseExpressions(expressions, (path, item) => {
        if (Array.isArray(item)) {
            set(result, path, item.map(transformExpr));
        } else if (item && typeof item === 'object') {
            set(result, path, {});
        }
    });
    return result as ExpressionsShape;
};
const mapExpressionsToPaths = <ExpressionsShape extends Expressions>(expressions) => {
    const invertedExpressions = {};
    Object.entries<string[]>(flattenObject(expressions, { safe: true }))
        .filter(([, exp]) => Array.isArray(exp))
        .forEach(([path, expressions]) => {
            (expressions || []).forEach((exp) => {
                if (!invertedExpressions[exp]) {
                    invertedExpressions[exp] = [path];
                } else {
                    invertedExpressions[exp].push(path);
                }
            });
        });
    return invertedExpressions;
};
const getExpressionsFromShape = <ExpressionsShape extends Expressions>(expressions: ExpressionsShape) => {
    return flattenArray<string>(Object.values(flattenObject(expressions, { safe: true })))
        .filter(Boolean)
        .filter(
            (v) =>
                // flatten (from 'flat' library) doesn't remove empty objects, so filter these out
                typeof v === 'string',
        );
};

class KeyCachingEvaluator<ExpressionsShape extends Expressions> {
    cachingEvaluator: CachingEvaluator;
    // expression -> keys that use it
    invertedExpressions: {
        [expression: string]: string[];
    };
    expressions: ExpressionsShape;
    constructor(
        expressions: ExpressionsShape,
        evaluationFn: (expression: string) => Evaler,
        initialContext: {},
        resultEquality: 'shalloweql' | 'deepeql',
    ) {
        this.expressions = transformExpressions(expressions);
        this.cachingEvaluator = new CachingEvaluator(
            getExpressionsFromShape(this.expressions),
            evaluationFn,
            initialContext,
            resultEquality,
        );
        this.invertedExpressions = mapExpressionsToPaths(this.expressions);
    }
    // call this whenever we need to pass a new closure, since some state it closes on has changed.
    // doesn't replace evaluationFn for existing expressions, so manually clear caches if you want that.
    changeExpressions = (
        newExpressions: ExpressionsShape,
        evaluationFnForAnyNew: (expression: string) => Evaler,
        initialContext: {},
    ) => {
        this.expressions = transformExpressions(newExpressions);
        this.cachingEvaluator.replaceExpressionsPreservingCaches(
            getExpressionsFromShape(this.expressions),
            evaluationFnForAnyNew,
            initialContext,
        );
        this.invertedExpressions = mapExpressionsToPaths(this.expressions);
    };
    clearCaches = (forExpressionsThatMatch: (expression) => boolean, newContext: {}) => {
        this.cachingEvaluator.clearCaches(forExpressionsThatMatch, newContext);
    };
    someValueWasRecalculated = () => {
        return this.cachingEvaluator.someValueWasRecalculated();
    };
    getChangedEvaluations = () => {
        return uniq(
            this.cachingEvaluator.getChangedExpressions().flatMap((e) => {
                return this.invertedExpressions[e];
            }),
        );
    };
    // return key value pairs of all new values
    evaluateAll = (values: {}) => {
        type ExpressionResult<T> = {
            [k in keyof T]: T[k] extends [string] ? [any] : T[k] extends string[] ? any[] : ExpressionResult<T[k]>;
        };
        let prO: {
            [key: string]: any[];
        } = {};
        Object.entries(this.cachingEvaluator.evaluateAll(values)).forEach(([expression, value]) => {
            this.invertedExpressions[expression].forEach((path) => {
                if (!prO[path]) {
                    prO[path] = [value];
                } else {
                    prO[path].push(value);
                }
            });
        });
        let result = {};
        traverseExpressions(this.expressions, (path, item) => {
            if (Array.isArray(item)) {
                const pathStr = path.join('.');
                set(result, path, prO[pathStr]);
            } else if (item && typeof item === 'object') {
                set(result, path, {});
            }
        });
        return result as ExpressionResult<ExpressionsShape>;
    };
}

export default KeyCachingEvaluator;
