import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, tap } from 'rxjs/operators';
import { loadSuccess } from 'viewConfig/actions';
import {
    isFieldViewField,
    getRefEntityName,
    getAllFieldEntriesFromView,
    expandComponentFields,
} from 'components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Option';
import { viewItemFilterExpressionsGenerated } from './actions';
import { VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY } from './constants';
import { getStorage } from 'storage/storage';
import { ViewItemFilterExpressionsGeneratedType } from './ViewItemFilterExpressionsGeneratedType';
import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import uniq from 'lodash/uniq';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import fromEntries from 'util/fromentries';
import { parseTemplateString } from 'viewConfigCalculations/util/parseTemplateString';

export const getSubExpressionsOfFilterTemplate = (filterString: string): string[] => {
    let subExpressions: string[] = [];
    const parse = getNextBracketedExpression({
        bracketClosesOn: ']',
        bracketNaivelyOpensOn: '[',
        bracketOpensOn: '$[',
    });
    const parseForFields = (str: string) => {
        parse(str).fold(str, ({ before, inner, after }) => {
            subExpressions.push(inner);
            parseForFields(after);
            return '';
        });
    };
    parseForFields(filterString);
    return uniq(subExpressions);
};

export const getFilterExpressionsFromView = (viewConfig: ViewConfig, view: View) => {
    const allFieldEntries = expandComponentFields(
        viewConfig,
        getAllFieldEntriesFromView(viewConfig, view.name),
        view.entity,
        { rebaseExpressionsWithinFields: false },
    ).expandedFieldsByRow.flat();

    const entries = allFieldEntries.flatMap(([fieldId, f]) => {
        if (isFieldViewField(f) && f.config && (f.widgetType === 'SELECT' || f.widgetType === 'ENTITY_TYPEAHEAD')) {
            return tryCatch(() => JSON.parse(f.config))
                .mapNullable((c) => c.filter)
                .map((filter) => {
                    const rebase = !f.__originalField
                        ? undefined
                        : f.field.slice(0, `.${f.__originalField}`.length * -1);
                    return parseTemplateString(filter, viewConfig, view.entity, rebase);
                })
                .map((e): [string, ViewItemFilterExpressionsGeneratedType[0][0]][] => [
                    [
                        fieldId,
                        {
                            ...e,
                            fieldName: f.field.endsWith('Id') ? f.field : `${f.field}Id`,
                            searchEntity: getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST'),
                        },
                    ],
                ])
                .getOrElse([]);
        }
        return [];
    });

    return fromEntries(entries);
};

const generateViewItemFilterExpressions: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) =>
    action$.pipe(
        filter(isActionOf(loadSuccess)),
        map(({ payload: { viewConfig } }) => {
            return fromEntries(
                Object.entries(viewConfig.views).flatMap(([viewName, view]) => {
                    const entriesObj = getFilterExpressionsFromView(viewConfig, view);
                    if (Object.keys(entriesObj).length > 0) {
                        return [[viewName, entriesObj]];
                    }
                    return [];
                }),
            );
        }),
        tap((vExps: ViewItemFilterExpressionsGeneratedType) => {
            getStorage().setItem(VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY, JSON.stringify(vExps));
        }),
        map((e) => {
            return viewItemFilterExpressionsGenerated(e);
        }),
    );
export default generateViewItemFilterExpressions;
