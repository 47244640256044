import { getSpelMode } from 'ts-spel-utils/getSpelMode';
import { ExpressionEvaluatorContext } from '../expressionEvaluatorContext';
import tsImpl from './tsImpl';
import jsImpl from './jsImpl';
import compatibilityImpl from './compatibility';

const getImpl = (): ExpressionEvaluatorContext => {
    const spelMode = getSpelMode();
    switch (spelMode) {
        case 'ts-spel':
            return tsImpl;
        case 'compatibility':
            return compatibilityImpl;
        case 'spel2js':
            return jsImpl;
    }
};
export default getImpl;
