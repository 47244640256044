import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';
import { PotentialUsers } from './reducer/potentialUsers';
import { deserialize } from 'reducers/lists/list/serializeDeserialize';

export const getCanClaimTask = () =>
    createSelector(
        <P extends { taskId: string }>(state: RootState, props: P) =>
            state.bpm.taskPermissions.canClaimTask[props.taskId],
        (remoteCanClaimTask) => {
            if (remoteCanClaimTask) {
                return deserialize(remoteCanClaimTask).fold(
                    false,
                    false,
                    (errorMessage) => false,
                    (v) => !!v,
                );
            }
            return false;
        },
    );
export const getCanAssignTask = () =>
    createSelector(
        <P extends { taskId: string }>(state: RootState, props: P) =>
            state.bpm.taskPermissions.canAssignTask[props.taskId],
        (remoteCanAssignTask) => {
            if (remoteCanAssignTask) {
                return deserialize(remoteCanAssignTask).fold(
                    false,
                    false,
                    (errorMessage) => false,
                    (v) => !!v,
                );
            }
            return false;
        },
    );
export const getPotentialUserLogins = () =>
    createSelector(
        <P extends { taskId: string }>(state: RootState, props: P) =>
            state.bpm.taskPermissions.potentialUsers[props.taskId],
        (remotePotentialUsers) => {
            if (remotePotentialUsers) {
                return deserialize(remotePotentialUsers).fold<string[]>(
                    [],
                    [],
                    (errorMessage) => [],
                    (potentialUsers) => potentialUsers.logins,
                );
            }
            return [];
        },
    );
export const getPotentialUsersByLogin = () =>
    createSelector(
        <P extends { taskId: string }>(state: RootState, props: P) =>
            state.bpm.taskPermissions.potentialUsers[props.taskId],
        (remotePotentialUsers) => {
            if (remotePotentialUsers) {
                return deserialize(remotePotentialUsers).fold<PotentialUsers['byLogin']>(
                    {},
                    {},
                    (errorMessage) => ({}),
                    (potentialUsers) => potentialUsers.byLogin,
                );
            }
            return {};
        },
    );

export const getPotentialUsers = () => {
    return createSelector(getPotentialUserLogins(), getPotentialUsersByLogin(), (logins, byLogin) =>
        logins.map((login) => byLogin[login]),
    );
};
