import getParentFieldInChild from './getParentFieldInChild';
import {
    getLongestRefonePathInPath,
    getPathBackFromFieldPath,
    getRefEntityName,
} from 'components/generics/utils/viewConfigUtils';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { useFieldSubscribeWithDelta } from 'fieldFactory/display/experimental/FieldSubscriber';
import { GetBackrefsToParentProps } from './GetBackrefsToParentProps';
import useViewConfig from 'util/hooks/useViewConfig';
import { useContext, useMemo } from 'react';

/**
 *
 * in 'useBackrefsToParent' we get the path back to the leading ref-1 path (not our root record) if necessary, because
 * in x-manys we don't show the widget if there's not an intermediate record in a path like that.
 *
 * However when we are inline, we always show the inline editable list, even if any intermediate records haven't been
 * created yet.
 *
 * so e.g. refone1.refone2.refmany
 * we would get back refone2.refone1.root if only the root record exists
 *
 * we would get back refone2.refone1 if root and refone1 exist
 *
 * we would get back refone2 if root and refone1 and refone2 exist.
 *
 * and all with the correct id and entity type of the nearest one we resolved.
 *

 */
export const useInlineBackrefsToParent = ({
    parentEntityName,
    parentId,
    source,
    linkedEntityFormat,
    endWith = 'Id',
}: GetBackrefsToParentProps) => {
    const viewConfig = useViewConfig();
    const sourceUntilLastReachableValue = useMemo(
        () => getLongestRefonePathInPath(viewConfig, parentEntityName, source),
        [viewConfig, parentEntityName, source],
    );
    const { parentFieldInChild, newParentEntityName } = getParentFieldInChild({
        parentEntityName,
        source,
        linkedEntityFormat,
        endWith,
        viewConfig,
    });
    const fullPathBackToRoot = getPathBackFromFieldPath(viewConfig, parentEntityName, source, 'linked<entityType>');

    const efc = useContext(entityFormContext);

    const { value: nearestExistingRecord, path: longestPathFound } = useFieldSubscribeWithDelta({
        id: parentId,
        source: sourceUntilLastReachableValue,
        resource: parentEntityName,
        delta: efc.dirtyValues,
        getValueAtLongestResolvablePath: true,
    });

    if (!nearestExistingRecord?.id) {
        return null;
    }
    if (longestPathFound !== sourceUntilLastReachableValue) {
        const fieldPathToNearestExistingRecord =
            fullPathBackToRoot
                .split('.')
                .reverse()
                .slice(longestPathFound.split('.').filter(Boolean).length)
                .reverse()
                .join('.') + endWith;

        const entityTypeOfNearestExistingRecord =
            longestPathFound === ''
                ? parentEntityName
                : getRefEntityName(viewConfig, parentEntityName, longestPathFound, 'TRAVERSE_PATH');
        return {
            parentEntityName: entityTypeOfNearestExistingRecord,
            parentFieldInChild: fieldPathToNearestExistingRecord,
            parentId: nearestExistingRecord.id,
        };
    }
    return {
        parentEntityName: newParentEntityName,
        parentId: nearestExistingRecord.id,
        parentFieldInChild,
    };
};
