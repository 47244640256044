import { Button, CardActions, CardHeader, TextField, Typography } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { createFieldPathsWithDepthSelector, useFilterOptions } from 'layout-editor/add-field/components/FieldPath';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ListboxComponent } from 'layout-editor/add-field/virtualize';
import * as FieldDataType from 'components/generics/utils/fieldDataTypes';
import { useSelector } from 'react-redux';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import { RootState } from 'reducers/rootReducer';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import produce from 'immer';
import { tryCatch } from 'fp-ts/lib/Option';
import {
    getAttrOfTraversedFieldExprIncludingLinkedX,
    getEntityFieldFromPath,
    isFieldViewField,
} from 'components/generics/utils/viewConfigUtils';
import {
    createDefaultView,
    createViewFieldFromEntityField,
} from 'layout-editor/generateDefaultViews/GenerateDefaultViews';

const BulkAddFields: React.FC<{
    viewConfig: ViewConfig;
    view: View;
    onChange: (view: View) => void;
    onClose: () => void;
}> = ({ viewConfig, view, onChange, onClose }) => {
    const defaultValue = useMemo(() => [], []);
    const fieldPathsWithDepthSelector = useMemo(createFieldPathsWithDepthSelector, []);
    const initialFieldPaths = useSelector((state: RootState) =>
        fieldPathsWithDepthSelector(state, {
            allowPropertiesOnManys: false,
            depth: 2,
            resource: view.entity,
        }),
    );

    const [fieldsToAdd, setFieldsToAdd] = useState<string[]>([]);
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const filterOptions = useFilterOptions({
        resource: view.entity,
        allowPropertiesOnManys: false,
    });

    const handleSubmit = useCallback(() => {
        const newView = produce(view, (draftView) => {
            // sort by increasing length so we create new tabs before adding any deeply nested fields to them.
            [...fieldsToAdd]
                .sort((a, b) => a.length - b.length)
                .forEach((field) => {
                    try {
                        const dt = getAttrOfTraversedFieldExprIncludingLinkedX(
                            viewConfig,
                            draftView.entity,
                            field,
                            'dataType',
                        );
                        const re = getAttrOfTraversedFieldExprIncludingLinkedX(
                            viewConfig,
                            draftView.entity,
                            field,
                            'relatedEntity',
                        ) as string;

                        const [startingPath] = field.split('.');
                        const foundTab = draftView.tabs[startingPath];

                        // If it's a ref-1, and doesn't seem seem to belong nested under an existing tab, add it as a new tab
                        if (dt === FieldDataType.REFONE && !foundTab) {
                            // Use the proper field label as the label for the tab we will create.
                            const label =
                                (getAttrOfTraversedFieldExprIncludingLinkedX(
                                    viewConfig,
                                    draftView.entity,
                                    field,
                                    'label',
                                ) as string) || field;

                            if (!draftView.tabs) {
                                draftView.tabs = {};
                            }
                            if (!draftView.tabs[field]) {
                                draftView.tabs[field] = {
                                    label,
                                    fields: {},
                                };
                            }
                            const newViewFields: View['fields'] = (() => {
                                const referenceView = createDefaultView(viewConfig.entities[re], draftView.viewType);
                                return Object.fromEntries(
                                    Object.entries(referenceView.fields).map(([k, refField]) => {
                                        const deepKey = `${field}.${k}`;
                                        if (!isFieldViewField(refField)) return [deepKey, refField];
                                        return [
                                            deepKey,
                                            {
                                                ...refField,
                                                field: `${field}.${refField.field}`,
                                            },
                                        ];
                                    }),
                                );
                            })();
                            draftView.tabs[field].fields = newViewFields;
                            return;
                        }

                        const createViewField = () => {
                            const toBaseOfLast = field.split('.').pop();
                            const [entityFieldRootEntity, entityField] = tryCatch(() =>
                                getEntityFieldFromPath(viewConfig, draftView.entity, toBaseOfLast),
                            ).getOrElse([null, null]);
                            if (!entityField || !entityFieldRootEntity) return null;
                            return createViewFieldFromEntityField(entityFieldRootEntity, entityField);
                        };
                        const newViewField = createViewField();
                        if (!newViewField) {
                            return;
                        }
                        // For deeper paths, add to the tabs created above if possible
                        if (foundTab) {
                            foundTab.fields[field] = newViewField;
                            return;
                        }
                        // If not a ref-1, add to the main part of the view.
                        draftView.fields[field] = newViewField;
                    } catch (e) {
                        console.error(e);
                    }
                });
        });

        onChange(newView);
        onClose();
    }, [fieldsToAdd, view, onClose, onChange, viewConfig]);
    return (
        <>
            <CardHeader title="Add Fields" />
            <div style={{ margin: '0rem 1rem' }}>
                <Typography>Select the field paths to add</Typography>
                <Autocomplete
                    multiple={true}
                    options={initialFieldPaths}
                    defaultValue={defaultValue}
                    autoHighlight
                    onChange={(e, value) => setFieldsToAdd(value)}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    selectOnFocus
                    filterOptions={filterOptions}
                    ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant={fieldVariant as any}
                            InputLabelProps={getInputLabelProps({ shrink: true })}
                            label="Field"
                            margin="normal"
                            fullWidth
                            inputProps={{
                                ...params.inputProps,
                                draggable: false,
                                autoComplete: 'disabled',
                            }}
                        />
                    )}
                />
            </div>
            <CardActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} disabled={fieldsToAdd.length === 0} variant="contained" color="primary">
                    Submit
                </Button>
            </CardActions>
        </>
    );
};

export default BulkAddFields;
