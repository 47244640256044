import React from 'react';
import Reorder from 'react-reorder';
import { TaskForm } from '../../../reducers/taskFormType';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import classnames from 'classnames';
import {
    makeStyles,
    Theme,
    createStyles,
    IconButton,
    Dialog,
    Button,
    CardActions,
    CardContent,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
// import EditActionButton from './EditActionButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import EditOutcome from './EditOutcomeForm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            display: 'inline-block',
        },
        list: {
            display: 'inline-block',
        },
        reorderableListItem: {
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.grey[300],
            },
        },
    }),
);

interface OutcomesEditorProps {
    outcomes: TaskForm['outcomes'];
    setOutcomes: (outcomes: TaskForm['outcomes']) => void;
    renderOutcome?: (outcome: TaskForm['outcomes'][0]) => JSX.Element;
    taskForm: TaskForm;
}
const OutcomesEditor: React.FunctionComponent<OutcomesEditorProps> = ({
    taskForm,
    outcomes,
    setOutcomes,
    renderOutcome = (oc) => (
        <Button color="primary" variant="contained">
            {oc.name}
        </Button>
    ),
}) => {
    const classes = useStyles();
    const [openForEditIx, setOpenForEditIx] = React.useState<number | 'newOutcome'>(-1);
    const [openForDelIx, setOpenForDelIx] = React.useState<number>(-1);
    return (
        <div style={{ width: '100%' }}>
            {openForDelIx !== -1 ? (
                <Dialog open={openForDelIx !== -1} onClose={() => setOpenForDelIx(-1)}>
                    <div style={{ padding: '1em' }}>
                        <CardContent>Delete outcome "{outcomes[openForDelIx].name}"?</CardContent>
                        <CardActions>
                            <Button
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                                onClick={() => setOpenForDelIx(-1)}
                            >
                                Close
                            </Button>
                            <Button
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const filtered = outcomes.filter(
                                        (item) => item.name !== outcomes[openForDelIx].name,
                                    );
                                    setOutcomes(filtered);
                                    setOpenForDelIx(-1);
                                }}
                            >
                                Delete
                            </Button>
                        </CardActions>
                    </div>
                </Dialog>
            ) : null}
            <Dialog maxWidth={false} fullWidth={true} open={openForEditIx !== -1} onClose={() => setOpenForEditIx(-1)}>
                {openForEditIx !== -1 && (
                    <div style={{ margin: '1em', padding: '1em' }}>
                        <EditOutcome
                            taskForm={taskForm}
                            initialValues={openForEditIx === 'newOutcome' ? ({} as any) : outcomes[openForEditIx]}
                            onSubmit={(data) => {
                                if (openForEditIx === 'newOutcome') {
                                    setOutcomes([...outcomes, data]);
                                } else {
                                    setOutcomes([
                                        ...outcomes.slice(0, openForEditIx),
                                        data,
                                        ...outcomes.slice(openForEditIx + 1),
                                    ]);
                                }
                                setOpenForEditIx(-1);
                            }}
                        />
                    </div>
                )}
            </Dialog>
            <Reorder
                itemKey="name"
                list={outcomes}
                template={(props) => (
                    <span
                        className={classnames(classes.reorderableListItem)}
                        style={{ padding: '5px', height: '100%', verticalAlign: 'middle' }}
                    >
                        <DragIndicatorIcon
                            style={{
                                opacity: 0.5,
                                verticalAlign: 'middle',
                            }}
                        />
                        {renderOutcome(props.item)}
                        <IconButton
                            size="small"
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                            onClick={(e) => {
                                setOpenForEditIx(outcomes.indexOf(props.item));
                            }}
                        >
                            <Edit color="primary" />
                        </IconButton>
                        <IconButton
                            size="small"
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                            onClick={() => setOpenForDelIx(outcomes.indexOf(props.item))}
                        >
                            <Delete color="primary" />
                        </IconButton>
                    </span>
                )}
                // Function that is called once a reorder has been performed
                callback={(event, itemThatHasBeenMoved, itemsPreviousIndex, itemsNewIndex, reorderedArray) => {
                    setOutcomes(reorderedArray);
                }}
                listClass={classes.list}
                itemClass={classes.listItem}
                selectedKey="tabKey"
                disableReorder={false}
                disableDragClass="no-drag"
            />
            <Button
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    setOpenForEditIx('newOutcome');
                }}
            >
                Add outcome&nbsp;
                <Add />
            </Button>
        </div>
    );
};

export default OutcomesEditor;
