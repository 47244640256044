import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { IconButton } from '@material-ui/core';
import StyleIcon from '@material-ui/icons/Style';
import EditThemeProperties from './ThemeEditor';

const useStyles = makeStyles({
    root: {
        width: 250,
    },
});

const ThemeEditor = (props) => {
    const classes = useStyles(props);
    return (
        <div className={classes.root} role="presentation">
            <EditThemeProperties />
        </div>
    );
};

export default function SwipeableTemporaryThemeDrawer() {
    const [state, setState] = React.useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="Preview themes" color="inherit" onClick={toggleDrawer(true)}>
                <StyleIcon />
            </IconButton>
            <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
                <ThemeEditor />
            </Drawer>
        </React.Fragment>
    );
}
