import { VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY } from './constants';
import { ViewItemFilterExpressionsGeneratedType } from './ViewItemFilterExpressionsGeneratedType';
import { fromPredicate } from 'fp-ts/lib/Option';
import { getStorage } from 'storage/storage';

const initialize = (): ViewItemFilterExpressionsGeneratedType => {
    return fromPredicate<string>(Boolean)(getStorage().getItem(VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY))
        .map((serializedConceptExpressions) => JSON.parse(serializedConceptExpressions))
        .getOrElse({});
};
export default initialize;
