import React from 'react';
// import { processInstanceEventCreator } from '../../bpm/actions/processInstanceEvent';
/* eslint-disable import/prefer-default-export */

import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { EntityActions } from './viewActions/ActionsWithOverrides/EntityActions';
import ProcessView from '../../bpm/components/ProcessDetail';
import { impersonateUser } from 'impersonate/actions';
/*
export const forceAlwaysExpand: {
    [entityName: string]: {
        [fieldName: string]: string
    }
} = {
    RelatedCase: {
        appCase: 'AppCase?REFONE'
    }
};

export const forceSchemaIgnore: {
    [entityName: string]: {
        [fieldName: string]: string
    }
} = {
    AppCase: {
        processInstance: 'ProcessInstance?REFONE' // actually, only keys here are used, value doesn't matter
    }
};

*/

export const inListDeletes = {
    RelatedCase: true,
};

export const customShowRedirects = {
    AppCase: [
        {
            getDataForPopover: (record) => record.processInstanceId,
            popover: (processInstanceId) =>
                processInstanceId ? (
                    <ProcessView
                        process={processInstanceId}
                        task={null}
                        showAppBar={false}
                        showLinkedEntity={false}
                        readOnly={true}
                    />
                ) : null,
            ariaLabelledBy: (rec) => 'foo',
            ariaDescribedBy: (rec) => 'foo description',
        },
        {
            _isRowClick: true,
            redirectFormula: (record) => `/processes/${record.processInstanceId}`,
        },
    ],
    RelatedCase: [
        {
            // this must return a string for update check
            getDataForPopover: (record, entities, viewConfig) =>
                traverseGetData(viewConfig, 'appCase.processInstanceId', record, entities).getOrElse(null),
            popover: (data) => {
                return data ? (
                    <ProcessView
                        process={data}
                        task={null}
                        showAppBar={false}
                        showLinkedEntity={false}
                        readOnly={true}
                    />
                ) : null;
            },
            ariaLabelledBy: (rec) => 'foo',
            ariaDescribedBy: (rec) => 'foo description',
        },
        {
            _isRowClick: true,
            redirectFormula: (record, entities, viewConfig) => {
                const data = traverseGetData(viewConfig, 'appCase.processInstanceId', record, entities);
                return data.map((pid) => `/processes/${pid}`).getOrElse('');
            },
        },
    ],
};

export const customEntityListActions = {};

const possibleMatches: EntityActions[0] = {
    displayRule:
        'record && record?.hasPossibleMatches == true ' +
        '&& allowsMerge(getAccessLevelForEntity(viewConfig, resource))',
    label: 'Possible Matches',
    key: 'poss-matches',
    url: '/${resource}/${record_id}/matches', // eslint-disable-line no-template-curly-in-string
};

export const customEntityShowActions: {
    [key: string]: EntityActions;
} = {
    '.*$': [possibleMatches],
    User: [
        {
            displayRule: 'record && record?.canImpersonate == true && !_impersonating',
            label: 'Impersonate',
            key: 'impersonate-user',
            action: {
                type: 'IMPERSONATE_USER',
                payload: {
                    userId: '${record_id}', // eslint-disable-line no-template-curly-in-string
                },
            } as ReturnType<typeof impersonateUser>,
        },
        {
            displayRule: 'record && record?.canResetMfa == true && !_impersonating',
            label: 'Reset MFA',
            key: 'reset-mfa',
            action: {
                type: 'CUSTOM_POST',
                payload: {
                    url: 'api/reset-mfa',
                    body: {
                        userId: '${record_id}', // eslint-disable-line no-template-curly-in-string
                    },
                },
                cb: () => {
                    // just a way of giving some success status easily.
                    window.location.href = window.location.href; // eslint-disable-line
                },
            },
        },
        {
            displayRule: 'record && record?.canUnlock == true && !_impersonating',
            label: 'Unlock User',
            key: 'unlock',
            action: {
                type: 'CUSTOM_POST',
                payload: {
                    url: 'api/reset-login-attempts',
                    body: {
                        userId: '${record_id}', // eslint-disable-line no-template-curly-in-string
                    },
                },
                cb: () => {
                    // just a way of giving some success status easily.
                    window.location.href = window.location.href; // eslint-disable-line
                },
            },
        },
    ],
};
export const customEntityEditActions: {
    [key: string]: EntityActions;
} = {
    '.*$': [possibleMatches],
};
/*

Leaving these as examples for the actions we have used in the past.
customEntityShow and customEntityEdit actions are depreciated and should be set using EntityView.config

export const customEntityShowActions = {
    AppCase: [{
        label: 'View Case',
        key: 'process-instance',
        url: record => `/processes/${record.processInstance.id}`
    }],
    EntityConfig: [
        {
            label: 'Create Views',
            key: 'create-views',
            action: redirector => record => ({
                type: 'CUSTOM_POST',
                payload: {
                    url: `api/view-config/create-views/${record.id}`
                },
            })
        }
    ],
    Person: [
        {
            label: 'Add Address',
            key: 'add-address',
            action: redirector => record => ({
                type: 'CUSTOM_POST',
                payload: {
                    url: 'api/bpm/process-instances',
                    body: {
                        processDefinitionKey: 'add-person-address',
                        values: {
                            personId: record.id
                        }
                    }
                },
                cb: (payload, responseBody) => {
                    redirector(`/processes/${responseBody.id}`);
                }
            })
        }
    ],
    Client: [
        {
            label: 'Create Client Case',
            key: 'new-client-case',
            action: redirector => record => ({
                type: 'CUSTOM_POST',
                payload: {
                    url: 'api/bpm/process-instances',
                    body: {
                        processDefinitionKey: 'CLPPP_Client_Case',
                        values: {
                            searchforexistingclient: record.id
                        }
                    }
                },
                cb: (payload, responseBody) => {
                    redirector(`/processes/${responseBody.id}`);
                }
            })
        },
        {
            label: 'Create CHW Case',
            key: 'new-chw-case',
            action: redirector => record => ({
                type: 'CUSTOM_POST',
                payload: {
                    url: 'api/bpm/process-instances',
                    body: {
                        processDefinitionKey: 'Community_Health_Worker_Case',
                        values: {
                            searchforclient: record.id
                        }
                    }
                },
                cb: (payload, responseBody) => {
                    redirector(`/processes/${responseBody.id}`);
                }
            })
        }
    ],
    Address: [
        {
            label: 'Create Environmental Case',
            key: 'new-env-case',
            action: redirector => record => ({
                type: 'CUSTOM_POST',
                payload: {
                    url: 'api/bpm/process-instances',
                    body: {
                        processDefinitionKey: 'Envionmental_Case',
                        values: {
                            selectaddress: record.id
                        }
                    }
                },
                cb: (payload, responseBody) => {
                    redirector(`/processes/${responseBody.id}`);
                }
            })
        }
    ],
    /\* EntityConfig: [
        {
            label: 'Create Views',
            key: 'create-views',
            action: redirector => record => ({
                type: 'CUSTOM_POST', // there is also 'CUSTOM_PUT'
                payload: {
                    url: `api/view-config/create-views/${record.id}`,
                    body: record // posts the record of the current page. Remove to send no body.
                },
                // optional callback to dispatch redirection. payload is the payload obove.
                // cb: (payload, responseBody) => { redirector(`/EntityView/${record.id}`); }
            })
        }
    ], *\/
    '.*Revision$': [ /* DEMO
        {
            label: 'Button 1',
            key: 'button-1',
            url: '/tasks'
        },
        {
            label: 'Start Create Case',
            key: 'launch-client-case',
            action: redirector => record => processInstanceEventCreator.createProcessInstanceFromKey(
                'upload-people',
                piid => redirector(`/processes/${piid}`)
            )
        } *\/
    ]
};
export const customEntityEditActions = {
};
*/

export const customSortFields = {
    '.*Revision$': {
        'revInfo.timestamp': true,
        'revInfo.revision': true,
    },
};
