import { storageController } from 'storage';
import { getViewConfigFromStorage } from './getViewConfigFromStorage';
import { getCurrentLang } from './language-registry/registry';

interface BuildHeadersOptions {
    // add content-types as needed.
    Accept?: 'application/json' | 'application/zip';
    'Content-Type'?: 'application/json' | 'multipart/form-data';
    includeCredentials: boolean;
}
/*
 interestingly, if I have
 'Accept': undefined
 in the headers passed to fetch,
 it literally passes the string 'undefined' as the request Accept header which breaks things.
 So lets filter out undefined headers, and also null/empty string while we are at it.
*/
const truthyEntriesOnly = <T extends Partial<Record<string, string>>>(obj: T): Required<T> =>
    Object.fromEntries(Object.entries(obj).filter(([k, v]) => Boolean(v))) as Required<T>;

const buildHeaders = (options: BuildHeadersOptions) => {
    const { Accept, includeCredentials = true, 'Content-Type': contentType } = options;
    const headers = truthyEntriesOnly({
        credentials: 'same-origin',
        Accept,
        'Content-Type': contentType,
        clientTzOffset: new Date().getTimezoneOffset() + '',
        'X-lang': getCurrentLang(),
    });
    if (includeCredentials) {
        const authToken = storageController.getToken();
        if (authToken) {
            headers['Authorization'] = `Bearer ${authToken}`;
            const vc = getViewConfigFromStorage();
            // that this is the viewconfig of the impersonated user, if impersonating
            if (vc) {
                headers['X-user-login'] = vc.user.login;
            }
        }
    }
    return headers;
};
export default buildHeaders;
