import { offlineTaskIsPristine } from 'IndexedDB/offlineTaskIsPristine';
import { offlineTaskToEntityKeyVal } from 'IndexedDB/offlineTaskToEntityKeyMap';
import { useEffect, useState } from 'react';
import makeCancelable from 'util/makeCancelable';

const useIsPristine = (taskId: string) => {
    const [isPristine, setIsPristine] = useState<'pending' | true | false>('pending');
    useEffect(() => {
        if (process.env.NODE_ENV === 'test') {
            return;
        }
        if (!taskId) {
            return;
        }
        const getIsPristine = async (taskId: string) => {
            const taskDataPristine = (await offlineTaskIsPristine.get(taskId)) ?? true;
            const entitySubmitsPristine = !(await offlineTaskToEntityKeyVal.keys()).includes(taskId);
            return taskDataPristine && entitySubmitsPristine;
        };
        const cancelableIsPristine = makeCancelable(getIsPristine(taskId));
        cancelableIsPristine.promise.then((r) => {
            setIsPristine(r);
        });
        return () => {
            cancelableIsPristine.cancel();
        };
    }, [taskId]);
    return isPristine;
};

export default useIsPristine;
