import { TaskForm } from '../../../reducers/taskFormType';
import isPlainObject from 'lodash/isPlainObject';
import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import { accumulateExpressions } from 'expressions/Provider/hooks/accumulateExpressions';
import uniq from 'lodash/uniq';

const parse = getNextBracketedExpression({
    bracketClosesOn: '}',
    bracketNaivelyOpensOn: '{',
    bracketOpensOn: '${',
});

const accBrackets = (
    obj: unknown,
): {
    [path: string]: {
        full: string;
        params: string[];
    };
} => {
    const innerAccBrackets = (obj: unknown, path = '') => {
        if (isPlainObject(obj)) {
            return Object.entries(obj).reduce((prev, [key, val]) => {
                let acced = innerAccBrackets(val, (path ? path + '.' : path) + key);
                return Object.assign(prev, acced);
            }, {});
        }
        if (Array.isArray(obj)) {
            return obj.reduce((prev, curr, i) => {
                let acced = innerAccBrackets(curr, (path ? path + '.' : path) + `[${i}]`);
                return Object.assign(prev, acced);
            }, {});
        }
        if (typeof obj === 'string') {
            const params = accumulateExpressions(obj, parse);
            if (params.length === 0) {
                return {};
            }
            return {
                [path]: {
                    full: obj,
                    params,
                },
            };
        }
    };
    return innerAccBrackets(obj);
};

const parseUnevaluatedFormBrackets = (form: Partial<TaskForm>) => {
    let allParams: string[] = [];
    let fields: {
        [fieldId: string]: {
            [path: string]: {
                full: string;
                params: string[];
            };
        };
    } = {};
    form.fields.forEach((field) => {
        const results = accBrackets(field);
        fields[field.id] = results;
        Object.values(results).forEach(({ params }) => {
            params.forEach((param) => {
                allParams.push(param.trim());
            });
        });
    });
    let outcomes: {
        [outcomeName: string]: {
            [path: string]: {
                full: string;
                params: string[];
            };
        };
    } = {};
    form.outcomes?.forEach((outcome) => {
        const results = accBrackets(outcome);
        outcomes[outcome.name] = results;
        Object.values(results).forEach(({ params }) => {
            params.forEach((param) => {
                allParams.push(param.trim());
            });
        });
    });
    return {
        fields,
        outcomes,
        params: uniq(allParams),
    };
};
export default parseUnevaluatedFormBrackets;
