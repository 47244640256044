import { createSelector } from 'reselect';
import { RootState } from '../../../../reducers/rootReducer';
import { EXTERNALGISID } from './util/getAllFieldsExpected';
import createRecordSelector from './util/recordSelector';
import { forceBooleanFieldsBooleanAndArrayFieldsArraysForFieldDefinitions } from './util/enforceBoolAndArrayValues';
import { getExpressions } from './util/entityVisExp';
import { reuseFormContext } from './util/createFormContext';
import { createGetEntities, createGetValueSets } from './util/getEntities';
import createDeepEqlSelector from './util/createDeepEqlSelector';
import uniq from 'lodash/uniq';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/EntityFieldConceptExps';
import {
    isFieldViewField,
    isFieldToAppendIdsToSource,
    isFieldToAppendIdToSource,
    isValueSetField,
    getValueSetForFieldExpr,
} from 'components/generics/utils/viewConfigUtils';
import { ViewEditableExps } from 'reducers/entityEditabilityReducer';
import fromEntries from 'util/fromentries';
import { ViewItemFilterExpressionsGeneratedType } from 'viewConfigCalculations/filterExpressions/ViewItemFilterExpressionsGeneratedType';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { ViewField } from 'reducers/ViewConfigType';
import getAdhocVariablesContextSelector from './util/getVariablesContextSelector';
import { formContext } from '.';

export interface FCPProps {
    fieldDefinitions: ViewField[];
    values: Record<string, unknown>;
    initialValues: Record<string, unknown>;
    entityType: string;
    overrides?: {
        editableExps?: ViewEditableExps[0];
        conceptExps?: EntityFieldConceptExps[0];
        filterExps?: ViewItemFilterExpressionsGeneratedType[0];
    };
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    // might be useful.
    // entityFormContextRef?: EntityFormContextRef;
}

const getExpressionsSelector = () => {
    return createSelector(
        (state: RootState, props: FCPProps): EntityFieldConceptExps[0] => props.overrides?.conceptExps ?? emptyObj,
        (state: RootState, props: FCPProps): ViewEditableExps[0] => props.overrides?.editableExps ?? emptyObj,
        (state: RootState, props: FCPProps): ViewItemFilterExpressionsGeneratedType[0] =>
            props.overrides?.filterExps ?? emptyObj,
        (entityConceptExps, viewFieldEditability, viewItemFilterExps) => {
            return {
                entityConceptExps,
                viewFieldEditability,
                viewItemFilterExps,
            };
        },
    );
};

/**
 *
 * TODO
 * I think viewContext is a way of signalling which formContext (show, edit, task-form, etc) to _use_.
 *
 * SHOULD WE PROVIDE OUR OWN CONTEXT, AND HAVE CONSUMERS SWITCH BASED ON viewContext (probably a 'useEntityContext' which returns a different one depending)
 * OR
 * SHOULD WE REUSE THE SAME ENTITYCONTEXT?
 * Pro: Don't have to change anything....
 * That's probably the easiest actually...
 */

const getFormContextEvaluatorSelector = () => {
    const expressionsSelector = getExpressionsSelector();

    return createSelector(
        (state: RootState, props: FCPProps) => props.entityType,
        expressionsSelector,
        (state: RootState, props: FCPProps) => state.viewConfig,
        (state: RootState, props: FCPProps) => state.entityValidations,
        (state: RootState, props: FCPProps) => props.fieldDefinitions,

        (entityType, expressions, viewConfig, entityValidations, fieldDefinitions) => {
            const { entityConceptExps, viewFieldEditability, viewItemFilterExps } = expressions;

            const fieldsToWidgets = fieldDefinitions.reduce((prev, curr) => {
                if (!isFieldViewField(curr)) {
                    return prev;
                }
                const source = isFieldToAppendIdsToSource(viewConfig)(entityType)(curr)
                    ? curr.field + 'Ids'
                    : isFieldToAppendIdToSource(viewConfig)(entityType)(curr)
                    ? curr.field + 'Id'
                    : curr.field;
                prev[source] = [source];
                return prev;
            }, {});

            const allValueset1Fields2: {
                [field: string]: string;
            } = fieldDefinitions.reduce((prev, curr) => {
                if (isFieldViewField(curr) && isValueSetField(viewConfig, curr.entity, curr.field)) {
                    prev[curr.field] = getValueSetForFieldExpr(viewConfig, curr.entity, curr.field);
                }
                return prev;
            }, {});

            const editabilityExpressions = getExpressions(viewFieldEditability);

            const fieldsUsedInExpressions = uniq([
                ...Object.values(viewFieldEditability).flatMap((c) =>
                    c.flatMap((cc) => cc.dataPaths || (cc as any).fieldsRequired),
                ),
                ...Object.values(entityValidations[entityType] || {}).flatMap(
                    (c) => c.dataPaths || (c as any).fieldsRequired,
                ),
                ...Object.values(viewItemFilterExps || {}).flatMap((c) => c.dataPaths || (c as any).fieldsRequired),
                ...Object.values(entityConceptExps || {}).flatMap((c) => c.dataPaths || (c as any).fieldsRequired),
            ]);

            const reference1EntityFilterExpressions =
                viewItemFilterExps &&
                fromEntries(
                    Object.entries(viewItemFilterExps).map(([field, e]) => {
                        return [
                            field,
                            {
                                entityType: e.searchEntity,
                                expression: e.expression,
                            },
                        ] as [string, { entityType: string; expression: string }];
                    }),
                );

            return new FormContextEvaluator({
                basedOnEntityOptions: {
                    basedOnEntity: entityType,
                    fieldsUsedInExpressions,
                },
                evaluationFactors: {
                    fieldWidgets: fieldsToWidgets,
                    dropdownAvailableOptionsExpressions: {},
                    valueset1AvailableConceptsExpressions: Object.assign(
                        {},
                        ...Object.values(entityConceptExps).map((ca) => ({ [ca.fieldName]: ca.expression })),
                    ),
                    valueset1Fields: allValueset1Fields2,
                    visibilityExpressions: {},
                    editabilityExpressions,
                    tableExpressions: {},
                    reference1EntityFilterExpressions,
                    useBackingValuesRegardlessOfDisplayStatus: {
                        [EXTERNALGISID]: true,
                    },
                },
                options: {
                    // Possibly have a new type of viewContext, but maybe not.
                    // viewContext,
                    dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
                    // backref,
                },
                viewConfig,
            });
        },
    );
};
const emptyObj = {};
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    const recordSelector = createRecordSelector();
    const formContextEvaluatorSelector = getFormContextEvaluatorSelector();
    const adhocVariablesContextSelector = getAdhocVariablesContextSelector();
    const formContextSelector = createSelector(
        formContextEvaluatorSelector,
        (state: RootState, props: FCPProps) => state.viewConfig,
        (state: RootState, props: FCPProps) => props.entityType,
        (state: RootState, props: FCPProps) => props.initialValues,
        // TODO: not sure exactly about this, but we can either
        // 1. Pass the 'initialValues' of final-form, or
        // 2. pass id and entityType and the fields needed
        // state.form![props.formId || 'record-form']?.initial ?? recordSelector(state, props),
        (state: RootState, props: FCPProps) => props.values,
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) => adhocVariablesContextSelector(props.evaluatedAdhocSPELVariables),
        (state: RootState, props: FCPProps) => props.fieldDefinitions,
        // (state: RootState, props: FCPProps) => props.entityFormContextRef,
        (
            formContextEvaluator,
            viewConfig,
            entityType,
            initialValues: {},
            values: {},
            entities: {},
            valueSets,
            adhocVariablesContext,
            fieldDefinitions,
        ) => {
            const result = formContextEvaluator.evaluate(
                forceBooleanFieldsBooleanAndArrayFieldsArraysForFieldDefinitions(viewConfig, fieldDefinitions)(values),
                valueSets,
                forceBooleanFieldsBooleanAndArrayFieldsArraysForFieldDefinitions(
                    viewConfig,
                    fieldDefinitions,
                )(initialValues),
                entities,
                adhocVariablesContext,
            );
            const { availableOptions, tableRowContexts, ...rest } = result;
            const res = { ...rest, adhocVariablesContext };
            // if (entityFormContextRef) entityFormContextRef.current = res;
            return res;
        },
    );

    return createDeepEqlSelector(formContextSelector);
};

export type EntityFormContext = ReturnType<ReturnType<typeof createFormContextSelector>>;

const {
    formContext: listRowFormContext,
    FormContextProvider: ListRowFormContextProvider /* : _EntityFormContextProvider */,
} = reuseFormContext(createFormContextSelector, formContext);

// const { FormContextProvider: EntityFormContextProvider, formContextHoc } = _EntityFormContextProvider;
//     // TODO: do we need one?
//     // getProviderAndHocWithViewContext(_EntityFormContextProvider);
// export { formContext, formContextHoc, EntityFormContextProvider };
export { listRowFormContext, ListRowFormContextProvider };
