import React from 'react';
import { Field as RFField } from 'redux-form';
import { Field as FFField } from 'react-final-form';
import { required } from 'fieldFactory/util/validate';
import Labeled from 'components/generics/utils/Labeled';
import useDebugFeaturesAreEnabled from 'util/hooks/useDebugFeaturesAreEnabled';

const isRequired = (validate) => {
    if (validate === required) return true;
    if (Array.isArray(validate)) {
        return validate.includes(required);
    }
    return false;
};

export const adjustFieldName = (source = '', replacePeriodsInFieldName) => {
    const adjustedSource = typeof source === 'number' ? `_${source.toString()}` : source;
    return replacePeriodsInFieldName ? adjustedSource.split('.').join(replacePeriodsInFieldName) : adjustedSource;
};

const FormField = ({ input, replacePeriodsInFieldName, forceEditable, ff = false, ...rest }) => {
    const { validate: _validate, ...inputProps } = input.props;
    const debugFeaturesEnabled = useDebugFeaturesAreEnabled();

    // if debugFeatures are enabled, lets fail loudly (inputProps.source being undefined)
    const inputSource = (() => {
        const errorMessage = `Some field has a undefined source. This usually indicates an expression field that failed to be templated, and has an undefined value.`;
        if (debugFeaturesEnabled && typeof inputProps.source === 'undefined') {
            throw new Error(errorMessage);
        }
        if (typeof inputProps.source !== 'undefined') {
            return inputProps.source;
        }
        console.error(errorMessage);
        return '';
    })();
    const Field = ff ? FFField : RFField;
    /**
     * In final-form, arrays of validations isn't a valid prop.
     * Lets construct a single function to do the job.
     */
    const validate =
        Array.isArray(_validate) && ff
            ? _validate.reduce((prev, curr) => {
                  if (!prev) {
                      return curr;
                  }
                  return (...inputs) => {
                      return prev(...inputs) || curr(...inputs);
                  };
              }, undefined)
            : _validate;
    if (inputProps.addField) {
        if (inputProps.addLabel) {
            return (
                <Field
                    {...rest}
                    {...inputProps}
                    validate={validate}
                    disabled={forceEditable ? false : rest.disabled || inputProps.disabled}
                    record={inputProps.record || rest.record}
                    choices={rest.choices || inputProps.choices}
                    name={adjustFieldName(inputSource, replacePeriodsInFieldName)}
                    component={Labeled}
                    label={inputProps.label}
                    isRequired={isRequired(inputProps.validate)}
                >
                    {input}
                </Field>
            );
        }
        return (
            <Field
                {...rest}
                {...inputProps}
                validate={validate}
                disabled={forceEditable ? false : rest.disabled || inputProps.disabled}
                record={inputProps.record || rest.record}
                choices={rest.choices || inputProps.choices}
                label={inputProps.label || ' '}
                name={adjustFieldName(inputSource, replacePeriodsInFieldName)}
                component={input.type}
                isRequired={isRequired(inputProps.validate)}
            />
        );
    }
    if (inputProps.addLabel) {
        return (
            <Labeled
                {...rest}
                label={inputProps.label || ' '}
                source={inputSource}
                isRequired={isRequired(inputProps.validate)}
                fullWidth
            >
                {input}
            </Labeled>
        );
    }
    return typeof input.type === 'string' ? input : React.cloneElement(input, rest);
};

export default FormField;
