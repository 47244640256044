import { createCustomAction } from 'typesafe-actions';
import { SET_CURR_WRITING_OFFLINE, UNSET_CURR_WRITING_OFFLINE } from './constants';

export const setCurrentlyWritingToOffline = createCustomAction(SET_CURR_WRITING_OFFLINE, (type) => {
    return (taskId: string, openGate = true) => ({
        type,
        payload: {
            taskId,
        },
        meta: {
            openGate,
        },
    });
});

export const unsetCurrentlyWritingToOffline = createCustomAction(UNSET_CURR_WRITING_OFFLINE, (type) => {
    return () => ({
        type,
    });
});
