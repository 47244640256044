const removeNullAndUndefinedKeys = (obj: {}): {} => {
    const copiedObject = { ...obj };
    Object.entries(copiedObject).forEach(([key, value]) => {
        if (typeof value === 'undefined' || value === null) {
            delete copiedObject[key];
        }
    });
    return copiedObject;
};
export default removeNullAndUndefinedKeys;
