import isObject from 'lodash/isObject';
import isDate from 'lodash/isDate';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';

const isNullBlankOrUndefined = (o) => {
    return typeof o === 'undefined' || o == null || o === '';
};

const objectDifference = (object, base, ignoreBlanks = false) => {
    if (!isObject(object) || isDate(object)) {
        return object;
    }
    return transform(object as any, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            if (ignoreBlanks && isNullBlankOrUndefined(value) && isNullBlankOrUndefined(base[key])) {
                return;
            }
            result[key] = isObject(value) && isObject(base[key]) ? objectDifference(value, base[key]) : value;
        }
    });
};
const difference = (left, right, ignoreBlanks = false) => {
    return {
        left: objectDifference(left, right, ignoreBlanks),
        right: objectDifference(right, left, ignoreBlanks),
    };
};
export default difference;
