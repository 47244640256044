import * as gO from 'sideEffect/crud/getOne/actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { isHttpError, HttpError, isAjaxError } from 'sideEffect/crud/util/HttpError';
import { AjaxError } from 'rxjs/ajax';

export type RemoteError = {
    _type: 'error';
    code: number | 'Communication' | 'Unhandled';
    message?: string;
};

export const createError = (e: Error | HttpError | AjaxError): RemoteError => {
    return {
        _type: 'error',
        code:
            (isHttpError(e) || isAjaxError(e)) && e.status
                ? e.status
                : e.name && e.name.startsWith('AjaxError')
                ? 'Communication'
                : 'Unhandled',
        message:
            isAjaxError(e) && e.response && e.responseType === 'json'
                ? e.response.description || e.response.message || e.message
                : e.message,
    };
};
export type RemoteSuccess = {
    _type: 'success';
};
export const createSuccess = (): RemoteSuccess => {
    return {
        _type: 'success',
    };
};
export type RemoteMoved = {
    _type: 'moved';
    newId: string;
};
export const createMoved = (newId: string): RemoteMoved => {
    return {
        _type: 'moved',
        newId,
    };
};
export type RemoteLoading = {
    _type: 'loading';
    previousStatus: RemoteStatus | 'initial';
};
export const createLoading = (status: RemoteLoading['previousStatus']): RemoteLoading => {
    return {
        _type: 'loading',
        previousStatus: status,
    };
};
export type RemoteStatus = RemoteError | RemoteSuccess | RemoteLoading | RemoteMoved;

export interface GetOneStatus {
    [key: string]: RemoteStatus;
}
export const getKey = (entity: string, id: string) => {
    return `${entity}:${id}`;
};

const getOneStatusReducer = (state: GetOneStatus = {}, action: RootAction): GetOneStatus => {
    switch (action.type) {
        case getType(gO.crudGetOne): {
            const key = getKey(action.payload.resource, action.payload.id);
            const existing = state[key] || 'initial';
            return {
                ...state,
                [key]: createLoading(existing),
            };
        }
        case getType(gO.crudGetOneFailure): {
            const key = getKey(action.requestPayload.resource, action.requestPayload.id);
            return {
                ...state,
                [key]: createError(action.payload),
            };
        }
        case getType(gO.crudGetOneSuccess): {
            const key = getKey(action.requestPayload.resource, action.requestPayload.id);
            if (action.payload.data.result !== action.requestPayload.id) {
                const newKey = getKey(action.requestPayload.resource, action.payload.data.result);
                return {
                    ...state,
                    [newKey]: createSuccess(),
                    [key]: createMoved(action.payload.data.result),
                };
            }
            return {
                ...state,
                [key]: createSuccess(),
            };
        }
        default:
            return state;
    }
};
export default getOneStatusReducer;
