import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import {
    getDataTypeForFieldExpr,
    isFieldViewField,
    getRefEntityName,
    getDefaultSort,
    getCustomViewName,
    getAllPrefilters,
    getPathBackFromFieldPath,
    getLongestRefonePathInPath,
    expandComponentFields,
    getAllFieldEntriesFromView,
} from '../utils/viewConfigUtils';
import { REF_MANY_PER_PAGE } from '../../../config';
import useViewConfig from 'util/hooks/useViewConfig';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import ViewConfig, { FieldViewField } from 'reducers/ViewConfigType';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import isPlainObject from 'lodash/isPlainObject';
import { DownloadedListViews } from 'offline_app/offline_stateful_tasks/download/downloadedListViews/data';

export const getAllFields = (viewConfig: ViewConfig, viewName: string, tabKey?: string): FieldViewField[] => {
    return expandComponentFields(
        viewConfig,
        getAllFieldEntriesFromView(viewConfig, viewName, tabKey),
        viewConfig.views[viewName].entity,
        {
            rebaseExpressionsWithinFields: true,
            replaceXmanyWithMultiCard: false,
        },
    )
        .expandedFieldsByRow.flat()
        .map((t) => t[1])
        .filter((f) => isFieldViewField(f)) as FieldViewField[];
};

const getReferenceManyFields = (viewConfig: ViewConfig, allFields: FieldViewField[]) =>
    allFields.filter((f) => getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFMANY');

const getManyManyFields = (viewConfig: ViewConfig, allFields: FieldViewField[]) =>
    allFields.filter((f) => getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFMANYMANY');

export const constructOfflineListsAtCurrentLevel = (viewConfig: ViewConfig, viewName: string, id: string) => {
    const fields = getAllFields(viewConfig, viewName);
    const referenceManyManyFields = getManyManyFields(viewConfig, fields);
    const referenceManyFields = getReferenceManyFields(viewConfig, fields);
    const resource = viewConfig.views[viewName]?.entity;
    const downloadedListViewsAtCurrentLevel = [...referenceManyFields, ...referenceManyManyFields].reduce(
        (prev, curr) => {
            if (!prev[resource]) {
                prev[resource] = {};
            }
            if (!prev[resource][id]) {
                prev[resource][id] = {};
            }
            if (!prev[resource][id][curr.field]) {
                prev[resource][id][curr.field] = {};
            }
            if (!prev[resource][id][curr.field][curr.config]) {
                prev[resource][id][curr.field][curr.config] = true;
            }
            return prev;
        },
        {} as DownloadedListViews,
    );
    return downloadedListViewsAtCurrentLevel;
};
const getPrefetchListRequests = (
    viewConfig: ViewConfig,
    viewName: string,
    id: string,
    tabKey?: string,
    options?: { perPage?: number },
): [string, FirstArgument<typeof crudGetListAction>][] => {
    const { perPage } = Object.assign(
        {
            // we allow overriding perPage so we can use this to fetch all data in download-for-offline.
            // if we actually use this as a prefetch to make the widget fetch unnecessary, we have to use the exact query the widget will use,
            // therefore REF_MANY_PER_PAGE
            perPage: REF_MANY_PER_PAGE,
        },
        options,
    );
    const allFields: FieldViewField[] = getAllFields(viewConfig, viewName, tabKey);
    const referenceManyFields = getReferenceManyFields(viewConfig, allFields);

    return referenceManyFields.flatMap((f) => {
        const leadingRefOne = getLongestRefonePathInPath(viewConfig, viewConfig.views[viewName].entity, f.field);
        if (leadingRefOne) {
            // this field is dynamic depending on the reference. We can't prefetch based on the dynamic field without knowing the referred data.
            // (this doesn't mean the below wouldn't work, just our components expect to filter based on the reference in the dynamic case)
            return [];
        }

        const filterString = (() => {
            if (f.config) {
                try {
                    let parsedConf = JSON.parse(f.config);
                    if (isPlainObject(parsedConf)) {
                        return parsedConf.filter as string;
                    }
                    return null;
                } catch {
                    return null;
                }
            }
        })();
        if (filterString?.includes('$[')) {
            // if filters are dynamic according to form contents, don't prefetch. Let this remain the responsibility of the widget.
            // (the widget will make this check as well, so it fetches even if the tab is marked with referenceFieldsShouldFetchInitialData being false)
            return [];
        }
        const reference = getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST');

        const entity = viewConfig.views[viewName].entity;
        // to work, this has to correspond to the permanentFilter in RefManyMultiSelect
        const target = getPathBackFromFieldPath(viewConfig, entity, f.field) as string;

        const viewNameOvr = getCustomViewName('LIST')(reference, viewConfig, f.config);
        return [
            [
                f.field,
                {
                    resource: reference,
                    pagination: {
                        page: 1,
                        perPage,
                    },
                    filter: {
                        [f.field === 'revisions' ? `${target}Id` : `${target}.id`]: id,
                        ...getAllPrefilters(viewConfig, viewConfig.views[viewNameOvr].entity, viewNameOvr),
                        ...getFilterFromFilterString(filterString),
                    },
                    sort: getDefaultSort(viewConfig, viewNameOvr) || { field: 'id', order: 'DESC' },
                    view: viewNameOvr,
                },
            ],
        ];
    });
};

const usePrefetchLists = () => {
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const prefetchLists = useCallback(
        (
            viewName: string,
            id: string,
            tabKey?: string,
            options?: { perPage?: number },
            doneCb?: (results: [string, any[]][]) => void,
        ) => {
            let promises: Promise<[string, any[]]>[] = [];
            const requests = getPrefetchListRequests(viewConfig, viewName, id, tabKey, options);

            requests.forEach(([field, r]) => {
                const newPromise = new Promise<[string, any[]]>((resolve, reject) => {
                    dispatch(
                        crudGetListAction({
                            ...r,
                            cb: ({ response }) => resolve([field, response]),
                            errorsCbs: {
                                '*': () => reject(),
                            },
                        }),
                    );
                });
                promises.push(newPromise);
            });
            Promise.all(promises).then((results) => {
                doneCb?.(results);
            });
        },
        [dispatch, viewConfig],
    );
    return prefetchLists;
};

type FirstArgument<T> = T extends (arg1: infer U, ...args: any[]) => any ? U : any;

const prefetchLists = (
    viewConfig: ViewConfig,
    viewName: string,
    id: string,
    crudGetList: (arg: FirstArgument<typeof crudGetListAction>) => void,
    tabKey?: string,
) => {
    const requests = getPrefetchListRequests(viewConfig, viewName, id, tabKey);
    requests.forEach(([, r]) => crudGetList(r));
};

export { usePrefetchLists };
export default prefetchLists;
