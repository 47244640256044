import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import createProcessingInstructions from 'templatePage/createProcessingInstructions';
import { getCustomPasswordResetPageHtmlSelector } from 'util/applicationConfig';
import { getDefaultRenderer, PasswordResetElements } from './DefaultRendererComponent';
import HtmlToReact from 'html-to-react';
import useTemplateMessagesAndPurifyHtml from 'templatePage/hooks/templateMessagesAndPurifyHtml';

const htmlToReactParser = new HtmlToReact.Parser();

const isValidNode = function () {
    return true;
};

const Template: React.FC<PasswordResetElements & { showTitle: boolean }> = (props) => {
    const { PasswordConfirmElement, PasswordElement, PasswordResetStatusElement, PageTextElement, ActionsElement } =
        props;
    const processingInstructions = useMemo(() => {
        return createProcessingInstructions([
            { tag: 'data-reset-password', Element: PasswordElement },
            { tag: 'data-reset-passwordconfirm', Element: PasswordConfirmElement },
            { tag: 'data-reset-status', Element: PasswordResetStatusElement },
            { tag: 'data-reset-pagetext', Element: PageTextElement },
            { tag: 'data-reset-actions', Element: ActionsElement },
        ]);
    }, [PasswordElement, PasswordConfirmElement, PasswordResetStatusElement, PageTextElement, ActionsElement]);

    const customPasswordResetHtml = useSelector(getCustomPasswordResetPageHtmlSelector);
    const templatedHtml = useTemplateMessagesAndPurifyHtml(customPasswordResetHtml);
    const TemplatedReactElement = useMemo(() => {
        if (!templatedHtml) {
            return null;
        }
        return htmlToReactParser.parseWithInstructions(templatedHtml, isValidNode, processingInstructions);
    }, [processingInstructions, templatedHtml]);

    if (!TemplatedReactElement) {
        return getDefaultRenderer({ showTitle: props.showTitle })(props);
    }
    return TemplatedReactElement;
};

export default Template;
