import React, { useState } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import ReactDiffViewer from 'react-diff-viewer';
import stableStringify from 'json-stable-stringify';
import { Link } from 'react-router-dom';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    IconButton,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import Warning from '@material-ui/icons/Warning';
import Delete from '@material-ui/icons/Delete';
import Check from '@material-ui/icons/Check';
import AttemptRequest from 'components/AttemptRequest';
import * as config from 'config';
import { fixViewFieldOrderings } from 'layout-editor/UniversalViewWizard/cleanFieldCoordinates';
import AutoDelete from './AutoDelete';
import { convert } from './convertViewDefToView';
import buildHeaders from 'sideEffect/buildHeaders';

const DiffViewsFromViewDefs = (props) => {
    const viewConfig = useViewConfig();
    const [shouldCorrectCoordinates, setShouldCorrectCoordinates] = useState(true);
    const [openAll, setOpenAll] = useState(false);
    const [onlyDifferent, setOnlyDifferent] = useState(false);
    const [viewType, setViewType] = useState('');
    const handleChangeViewType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setViewType(event.target.value as string);
    };
    return (
        <div style={{ margin: '1em' }}>
            <FormControl>
                <InputLabel shrink={true} id="filter-view-type-label">
                    Filter View Type
                </InputLabel>
                <Select
                    native
                    labelId="filter-view-type-label"
                    id="filter-view-type"
                    value={viewType}
                    onChange={handleChangeViewType}
                    style={{ minWidth: 250 }}
                >
                    <option value="">None</option>
                    <option value="LIST">List</option>
                    <option value="SHOW">Show</option>
                    <option value="EDIT">Edit</option>
                    <option value="CREATE">Create</option>
                    <option value="MATCH">Match</option>
                    <option value="MERGE">Merge</option>
                </Select>
            </FormControl>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={openAll}
                            onChange={() => {
                                setOpenAll(!openAll);
                            }}
                            name="checked"
                        />
                    }
                    label="Open All"
                />
            </FormGroup>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={onlyDifferent}
                            onChange={() => {
                                setOnlyDifferent(!onlyDifferent);
                            }}
                            name="checked"
                        />
                    }
                    label="Only Different"
                />
            </FormGroup>
            {openAll && <AutoDelete />}
            <div style={{ height: '1em' }} />
            {Object.entries(viewConfig.viewDefs || {})
                .sort(([a], [b]) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                })
                .flatMap(([vn, vd]) => {
                    const oldValue = shouldCorrectCoordinates
                        ? viewConfig.views[vn] && fixViewFieldOrderings(viewConfig.views[vn])
                        : viewConfig.views[vn];
                    const parsedDef = JSON.parse(vd.definition);
                    if (viewType !== '' && parsedDef.viewType !== viewType) {
                        return [];
                    }
                    const oldValueStr = stableStringify({ route: null, ...oldValue }, { space: ' ' });
                    const newValueStr = stableStringify(convert({ route: null, ...parsedDef }, viewConfig), {
                        space: ' ',
                    });
                    if (onlyDifferent && oldValueStr === newValueStr) {
                        return [];
                    }
                    return [
                        <ExpansionPanel expanded={openAll || undefined} key={vn + ' ' + openAll}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${vn}-content`}
                                id={`panel${vn}-header`}
                            >
                                {vn}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={shouldCorrectCoordinates}
                                                onChange={() => {
                                                    setShouldCorrectCoordinates(!shouldCorrectCoordinates);
                                                }}
                                                name="checked"
                                            />
                                        }
                                        label="Correct coordinates"
                                    />
                                </FormGroup>
                                <span>
                                    <IconButton component={Link} to={`/load-layout/${vn}`}>
                                        <Edit />
                                    </IconButton>
                                </span>
                                <AttemptRequest
                                    type="internal"
                                    renderer={({ attemptAction }) =>
                                        (state) =>
                                            (
                                                <div>
                                                    <IconButton
                                                        className="deleteViewDef"
                                                        disabled={state._tag === 'pending'}
                                                        onClick={() =>
                                                            attemptAction({
                                                                lazyRequest: () =>
                                                                    fetch(
                                                                        `${config.BACKEND_BASE_URL}api/view-config/update-view/${vn}`,
                                                                        {
                                                                            method: 'DELETE',
                                                                            headers: buildHeaders({
                                                                                includeCredentials: true,
                                                                                Accept: 'application/json',
                                                                                'Content-Type': 'application/json',
                                                                            }),
                                                                        },
                                                                    ),
                                                            })
                                                        }
                                                    >
                                                        {state._tag === 'pending' ? (
                                                            <span>
                                                                <CircularProgress style={{ height: 20, width: 20 }} />
                                                            </span>
                                                        ) : state._tag === 'success' ? (
                                                            <Check color="primary" />
                                                        ) : state._tag === 'failure' ? (
                                                            <Warning color="error" />
                                                        ) : (
                                                            <Delete color="error" />
                                                        )}
                                                    </IconButton>
                                                </div>
                                            )}
                                />
                                {viewConfig.views[vn] && (
                                    <ReactDiffViewer oldValue={oldValueStr} newValue={newValueStr} splitView={true} />
                                )}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>,
                    ];
                })}
        </div>
    );
};

export default DiffViewsFromViewDefs;
