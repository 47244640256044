import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';

const ControlledJSONEditor: FunctionComponent<{
    name: string;
    defaultValue?: {};
    filterValue?: (value: any) => any;
    asString?: boolean;
}> = ({ name, defaultValue, filterValue, asString = false }) => {
    const { setValue, register, unregister, watch } = useFormContext();
    const handleChange = useCallback(
        (value) => {
            if (asString) {
                value = JSON.stringify(value);
            }
            setValue(name, value, {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        [setValue, name, asString],
    );
    React.useEffect(() => {
        register({ name });
        return () => {
            unregister(name);
        };
    }, []); // eslint-disable-line
    let value = watch(name, defaultValue);
    if (asString) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            value = '';
        }
    }
    console.log('value', value);
    const json = useMemo(() => {
        if (filterValue) {
            return filterValue(value);
        }
        return value;
    }, [value, filterValue]);
    return <JSONEditorDemo json={json} onChangeJSON={handleChange} />;
};

export default ControlledJSONEditor;
