import moment from 'moment';
import { fromNullable } from 'fp-ts/lib/Option';

const dateToDateTimeString = (value) => {
    return fromNullable(value)
        .mapNullable((v) => moment(v).set('seconds', 0).set('milliseconds', 0).toISOString())
        .map<string | null>((isoStr) => isoStr.split('.')[0] + 'Z')
        .getOrElse(null);
};
export default dateToDateTimeString;
