import { View, ViewField, FieldViewField } from 'reducers/ViewConfigType';
import groupBy from 'lodash/groupBy';
import fromEntries from 'util/fromentries';
import produce from 'immer';

type ViewDefField = ViewField;

const cleanFieldCoordOrdering = (fields: ViewDefField[], attr: 'row' | 'column' | 'order'): ViewDefField[] => {
    // we will reorder these so that 'rows' and 'columns' start from 0 and increment by 1
    /*
        {
            undefined: [{...}, {...}],
            0: [{...}, {...}],
            10: [{...}, {...}, {...}],
            20: [{...}]
        }
    */
    const fieldsByAttr = groupBy(fields, attr);
    /*
        {
            0: 0,
            10: 1,
            20: 2
        }
    */
    const mapAttrToOrdering = fromEntries(
        Object.keys(fieldsByAttr)
            .filter((k) => typeof k !== 'undefined')
            .map((v) => parseInt(v))
            .sort((a, b) => a - b)
            .map((origOrder, index) => [origOrder, index] as [number, number]),
    );

    return Object.entries(fieldsByAttr).flatMap(([col, fields]) => {
        if (typeof col === 'undefined' || col === null) {
            return fields;
        }
        if (attr === 'column') {
            const min = fields
                .map((f) => f.column)
                .filter((c) => typeof c !== 'undefined')
                .reduce((prev, curr) => Math.min(prev, curr), 9999999);
            return fields.map((f) => ({
                ...f,
                column: typeof f.column !== 'undefined' && min !== 9999999 ? f.column - min : f.column,
            }));
        }
        // otherwise just straight ordering (0, 1, 2, etc.)
        return fields.map((f) => {
            if (f.hasOwnProperty(attr)) {
                return { ...f, [attr]: mapAttrToOrdering[parseInt(col)] };
            }
            return f;
        });
    });
};

const cleanCoordOrdering = (fields: View['fields'], attr: 'row' | 'column' | 'order'): View['fields'] => {
    const fieldEntriesByAttr = groupBy(Object.entries(fields), `[1].${attr}`);
    const mapAttrToOrdering = fromEntries(
        Object.keys(fieldEntriesByAttr)
            .filter((k) => typeof k !== 'undefined')
            .map((v) => parseInt(v))
            .sort((a, b) => a - b)
            .map((origOrder, index) => [origOrder, index] as [number, number]),
    );
    return fromEntries(
        Object.entries(fieldEntriesByAttr).flatMap((t) => {
            const [col, fieldEntries] = t;
            if (typeof col === 'undefined' || col === null) {
                return fieldEntries;
            }
            if (attr === 'row') {
                const min = fieldEntries
                    .map(([, f]) => f.column)
                    .filter((c) => typeof c !== 'undefined')
                    .reduce((prev, curr) => Math.min(prev, curr), 9999999);
                return fieldEntries.map(([k, f]) => {
                    const opAttr = 'column';
                    return [
                        k,
                        {
                            ...f,
                            // rebase values from 0
                            [opAttr]: typeof f[opAttr] !== 'undefined' && min !== 9999999 ? f[opAttr] - min : f[opAttr],
                        },
                    ] as [string, ViewField];
                });
            }
            return fieldEntries.map(([fn, f]) => {
                if (f.hasOwnProperty(attr)) {
                    return [fn, { ...f, [attr]: mapAttrToOrdering[parseInt(col)] }] as [string, ViewField];
                }
                return [fn, f] as [string, ViewField];
            });
        }),
    );
};
const orderForGrid = (fields: ViewDefField[]) =>
    cleanFieldCoordOrdering(cleanFieldCoordOrdering(cleanFieldCoordOrdering(fields, 'row'), 'column'), 'order');

const change0spanTo12 = (field: View['fields'][0]) => {
    const span = field.span;
    if (span === 0) {
        return { ...field, span: 12 };
    }
    return field;
};
export const change0SpanTo12ForFields = (fields: View['fields']) => {
    return fromEntries(Object.entries(fields).map(([k, v]) => [k, change0spanTo12(v)]));
};
export const orderFieldObjectRowCol = (fields: View['fields']) =>
    cleanCoordOrdering(cleanCoordOrdering(fields, 'row'), 'column');

export const orderFieldObjectForGrid = (fields: View['fields']) =>
    // sometimes our saved values remain 0...
    // change0SpanTo12ForFields(
    cleanCoordOrdering(cleanCoordOrdering(cleanCoordOrdering(fields, 'row'), 'column'), 'order');
// );

export const fixViewDefFieldOrderings = <
    ViewDef extends {
        viewType: View['viewType'];
        fields: ViewDefField[];
        searchFields?: ViewDefField[];
        tabs?: {
            fields: ViewDefField[];
            label: string;
        }[];
    },
>(
    viewDef: ViewDef,
) => {
    return produce(viewDef, (draftViewDef) => {
        if (viewDef.fields) {
            draftViewDef.fields = orderForGrid(viewDef.fields);
        }
        if (viewDef.searchFields) {
            draftViewDef.searchFields = orderForGrid(viewDef.searchFields);
        }
        if (viewDef.tabs) {
            viewDef.tabs.forEach((tab, i) => {
                draftViewDef.tabs[i].fields = orderForGrid(tab.fields);
            });
        }
    });
};

export const fixViewFieldOrderings = (view: View) => {
    return produce(view, (draftView) => {
        if (view.fields) {
            draftView.fields = orderFieldObjectForGrid(view.fields);
        }
        if (view.searchFields) {
            draftView.searchFields = orderFieldObjectForGrid(view.searchFields) as {
                [field: string]: FieldViewField;
            };
        }
        if (view.tabs) {
            Object.values(draftView.tabs).forEach((tab, i) => {
                tab.fields = orderFieldObjectForGrid(tab.fields);
            });
        }
    });
};
