/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['34aa50dc'] = validate10;
const schema11 = {
    type: 'object',
    title: 'My Reports',
    properties: {
        reportName: { items: { type: 'string' }, title: 'Report Names', type: 'array' },
        outputContentType: { items: { type: 'string' }, title: 'Output Content Types', type: 'array' },
        viewName: { title: 'View Name', type: 'string' },
        running: { title: 'Running', type: 'boolean' },
        longRunning: { title: 'Long Running', type: 'boolean' },
        startTime: { format: 'date-time', title: 'Start Time', type: 'string' },
    },
    dependencies: {},
    required: [],
    $id: '34aa50dc',
};
const formats0 = { _items: ['require("ajv-formats/dist/formats").', { str: 'fullFormats' }, ''] }['date-time'];

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="34aa50dc" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.reportName !== undefined) {
            let data0 = data.reportName;
            if (Array.isArray(data0)) {
                const len0 = data0.length;
                for (let i0 = 0; i0 < len0; i0++) {
                    let data1 = data0[i0];
                    if (typeof data1 !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/reportName/' + i0,
                            schemaPath: '#/properties/reportName/items/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.properties.reportName.items.type,
                            parentSchema: schema11.properties.reportName.items,
                            data: data1,
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                }
            } else {
                const err1 = {
                    instancePath: instancePath + '/reportName',
                    schemaPath: '#/properties/reportName/type',
                    keyword: 'type',
                    params: { type: 'array' },
                    message: 'must be array',
                    schema: schema11.properties.reportName.type,
                    parentSchema: schema11.properties.reportName,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err1];
                } else {
                    vErrors.push(err1);
                }
                errors++;
            }
        }
        if (data.outputContentType !== undefined) {
            let data2 = data.outputContentType;
            if (Array.isArray(data2)) {
                const len1 = data2.length;
                for (let i1 = 0; i1 < len1; i1++) {
                    let data3 = data2[i1];
                    if (typeof data3 !== 'string') {
                        const err2 = {
                            instancePath: instancePath + '/outputContentType/' + i1,
                            schemaPath: '#/properties/outputContentType/items/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.properties.outputContentType.items.type,
                            parentSchema: schema11.properties.outputContentType.items,
                            data: data3,
                        };
                        if (vErrors === null) {
                            vErrors = [err2];
                        } else {
                            vErrors.push(err2);
                        }
                        errors++;
                    }
                }
            } else {
                const err3 = {
                    instancePath: instancePath + '/outputContentType',
                    schemaPath: '#/properties/outputContentType/type',
                    keyword: 'type',
                    params: { type: 'array' },
                    message: 'must be array',
                    schema: schema11.properties.outputContentType.type,
                    parentSchema: schema11.properties.outputContentType,
                    data: data2,
                };
                if (vErrors === null) {
                    vErrors = [err3];
                } else {
                    vErrors.push(err3);
                }
                errors++;
            }
        }
        if (data.viewName !== undefined) {
            let data4 = data.viewName;
            if (typeof data4 !== 'string') {
                const err4 = {
                    instancePath: instancePath + '/viewName',
                    schemaPath: '#/properties/viewName/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.viewName.type,
                    parentSchema: schema11.properties.viewName,
                    data: data4,
                };
                if (vErrors === null) {
                    vErrors = [err4];
                } else {
                    vErrors.push(err4);
                }
                errors++;
            }
        }
        if (data.running !== undefined) {
            let data5 = data.running;
            if (typeof data5 !== 'boolean') {
                const err5 = {
                    instancePath: instancePath + '/running',
                    schemaPath: '#/properties/running/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.running.type,
                    parentSchema: schema11.properties.running,
                    data: data5,
                };
                if (vErrors === null) {
                    vErrors = [err5];
                } else {
                    vErrors.push(err5);
                }
                errors++;
            }
        }
        if (data.longRunning !== undefined) {
            let data6 = data.longRunning;
            if (typeof data6 !== 'boolean') {
                const err6 = {
                    instancePath: instancePath + '/longRunning',
                    schemaPath: '#/properties/longRunning/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.longRunning.type,
                    parentSchema: schema11.properties.longRunning,
                    data: data6,
                };
                if (vErrors === null) {
                    vErrors = [err6];
                } else {
                    vErrors.push(err6);
                }
                errors++;
            }
        }
        if (data.startTime !== undefined) {
            let data7 = data.startTime;
            if (typeof data7 === 'string') {
                if (!formats0.validate(data7)) {
                    const err7 = {
                        instancePath: instancePath + '/startTime',
                        schemaPath: '#/properties/startTime/format',
                        keyword: 'format',
                        params: { format: 'date-time' },
                        message: 'must match format "' + 'date-time' + '"',
                        schema: 'date-time',
                        parentSchema: schema11.properties.startTime,
                        data: data7,
                    };
                    if (vErrors === null) {
                        vErrors = [err7];
                    } else {
                        vErrors.push(err7);
                    }
                    errors++;
                }
            } else {
                const err8 = {
                    instancePath: instancePath + '/startTime',
                    schemaPath: '#/properties/startTime/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.startTime.type,
                    parentSchema: schema11.properties.startTime,
                    data: data7,
                };
                if (vErrors === null) {
                    vErrors = [err8];
                } else {
                    vErrors.push(err8);
                }
                errors++;
            }
        }
    } else {
        const err9 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err9];
        } else {
            vErrors.push(err9);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
