import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) =>
    createStyles({
        message: {
            flex: 1,
        },
    }),
);
const GoBackOnlineBanner = () => {
    const classes = useStyles();
    return (
        <div>
            <Alert severity="info" classes={classes}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div>
                        {/* Pop off ?offline=1 query string */}
                        <a href={window.location.pathname}>Switch to online mode</a>&nbsp;
                        <i>You will remain in offline mode if there is no internet connection</i>
                    </div>
                </div>
            </Alert>
        </div>
    );
};

export default GoBackOnlineBanner;
