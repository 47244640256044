import { processActivityEvent } from '../actions/processActivityEvent';
import sagaFactory, { configType, HttpVerb } from '../../sagas/util/sagaFactory';

const config: configType = {
    actionType: processActivityEvent.get,
    verb: HttpVerb.GET,
    url: (payload: { processId: string | number }) =>
        `api/bpm/process-instances/${payload.processId}/activities/sub-process`,
    success: (
        payload: {
            processId: string | number;
        },
        responseBody,
    ) => ({
        type: processActivityEvent.getSuccess,
        payload: {
            processId: payload.processId,
            data: responseBody,
        },
    }),
    failure: (payload, errorDetails) => ({
        type: processActivityEvent.getFailure,
        requestPayload: payload,
        payload: errorDetails,
    }),
    callCb: (payload, responseBody, cb) => {
        if (cb) {
            cb(responseBody);
        }
    },
    callErrorCb: (cb) => {
        if (cb) {
            cb();
        }
    },
};

const saga = sagaFactory(config);
export default saga;
