import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { RootState } from 'reducers/rootReducer';
import AttemptRequest, { AttemptRequestPropsInternal } from 'components/AttemptRequest';
import { Helmet } from 'react-helmet';

interface PasswordResetProps {
    renderForm: AttemptRequestPropsInternal['renderer'];
    setBackgroundColor?: boolean;
}

const mapStateToProps = (state: RootState) => ({
    appColor: state.basicInfo && state.basicInfo.applicationColor,
});
export type PasswordResetResponse =
    | undefined
    | {
          mfaQrUrl?: string;
          mfaSetupRequired?: boolean;
      };
interface PasswordResetComponentProps extends PasswordResetProps, ReturnType<typeof mapStateToProps> {}
class PasswordResetComponent extends React.Component<PasswordResetComponentProps> {
    render() {
        const { renderForm } = this.props;
        return (
            <>
                <Helmet>
                    <title>Reset Password</title>
                </Helmet>
                <div role="main" style={{ height: '100%' }}>
                    <AttemptRequest<PasswordResetResponse> type="internal" renderer={renderForm} />
                </div>
            </>
        );
    }
}

const PasswordReset: React.ComponentType<PasswordResetProps> = compose(connect(mapStateToProps))(
    PasswordResetComponent,
);
export default PasswordReset;
