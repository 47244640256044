import * as undisableTaskForms from './actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';

const undisableTaskFormsReducer = (previousState: boolean = false, action: RootAction): boolean => {
    switch (action.type) {
        case getType(undisableTaskForms.undisableTaskForms): {
            return action.payload.disable;
        }
        default:
            return previousState;
    }
};
export default undisableTaskFormsReducer;
