import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';
import { storageController } from 'storage';
import { Button, Card, CardContent, CircularProgress, useTheme } from '@material-ui/core';
import LoginAppBar from 'auth/components/LoginAppBar';

interface QRProps {
    onComplete: () => void;
    promptElement: React.ReactNode;
}

type Status =
    | {
          type: 'initial';
      }
    | {
          type: 'error';
          errorType: 'auth' | 'network' | 'UNEXPECTED';
      }
    | {
          type: 'pending';
      }
    | {
          type: 'success';
          qrUrl: string;
      };

const QR: FunctionComponent<QRProps> = ({ onComplete, promptElement }) => {
    const idToken = useMemo(() => {
        return storageController.getToken();
    }, []);
    const [status, setStatus] = useState<Status>({ type: 'initial' });
    const getQRUrl = useCallback(() => {
        setStatus({ type: 'pending' });
        const $ajax = services.mfaGetQRUrl();
        const subscription = $ajax.subscribe(
            (res) => {
                setStatus({ type: 'success', qrUrl: res.mfaQrUrl });
            },
            (error: AjaxError) => {
                if (typeof error.status === 'number' && error.status >= 400 && error.status < 500) {
                    setStatus({ type: 'error', errorType: 'auth' });
                } else if (!error.status) {
                    setStatus({ type: 'error', errorType: 'network' });
                } else {
                    setStatus({ type: 'error', errorType: 'UNEXPECTED' });
                }
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, []);
    useEffect(() => {
        getQRUrl();
    }, [getQRUrl]);
    const theme = useTheme();
    return (
        <div>
            <div style={{ position: 'fixed', top: 0 }}>
                <LoginAppBar />
            </div>
            <div
                style={{
                    backgroundColor: theme.palette.primary.dark,
                    height: '100vh',
                    display: 'grid',
                    placeItems: 'center',
                }}
            >
                <Card>
                    <CardContent>
                        <p style={{ textAlign: 'center' }}>{promptElement}</p>
                        {status.type === 'error' ? (
                            <p style={{ textAlign: 'center', color: theme.palette.error.dark }}>
                                {status.errorType === 'network' ? (
                                    <>
                                        You are not connected to the internet. <button onClick={getQRUrl}>retry</button>
                                    </>
                                ) : status.errorType === 'auth' ? (
                                    <>An authentication error has occurred. You session may be expired.</>
                                ) : status.errorType === 'UNEXPECTED' ? (
                                    'Unexpected error'
                                ) : null}
                            </p>
                        ) : status.type === 'pending' ? (
                            <p style={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </p>
                        ) : status.type === 'success' ? (
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={status.qrUrl} alt="QR Code" />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Button color="primary" variant="contained" onClick={onComplete}>
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default QR;
