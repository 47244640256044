import { combineReducers } from 'redux';
import canClaimTask from './canClaimTask';
import canAssignTask from './canAssignTask';
import potentialUsers from './potentialUsers';

export default combineReducers({
    canClaimTask,
    potentialUsers,
    canAssignTask,
});
