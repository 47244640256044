import ViewConfig from 'reducers/ViewConfigType';
import { fromEither, Option, fromNullable, some } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { parsingOrValidationErrMsg } from 'expressions/formValidation';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import removeMethodHashes from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import maybeLogError from 'viewConfigCalculations/util/logErrorIfPermissioned';
import getImpl from 'expressions/Provider/implementations/getImpl';
import {
    getAllFieldEntriesFromView,
    getViewConfiguration,
    getViewNameFromComponentField,
    isComponentField,
    isValidEntityFieldExpression,
} from 'components/generics/utils/viewConfigUtils';

const getConceptExpressions = (
    viewName: string,
    viewConfig: ViewConfig,
    viewConfiguration: Option<string>,
    rebaseOntoRootPath?: string,
): Option<{
    [k: string]: {
        expression: string;
        fieldName: string;
        expansionsRequired: string[];
        dataPaths: string[];
        valuesetLiterals: string[];
        valuesetFieldsRequired: {
            [fieldSource: string]: string;
        };
    };
}> => {
    const componentFieldExpressions = getAllFieldEntriesFromView(viewConfig, viewName)
        .flatMap(([k, f]) => {
            if (!isComponentField(f)) {
                return [];
            }
            const componentViewName = getViewNameFromComponentField(f);

            const exp = getConceptExpressions(
                componentViewName,
                viewConfig,
                fromEither(
                    tryCatch(() => getViewConfiguration(viewConfig, componentViewName)).mapLeft((e) => console.log(e)),
                ).chain(fromNullable),
                f.field,
            ).map((c): typeof c => {
                return Object.fromEntries(
                    Object.entries(c).map(([key, v]: [string, any]) => {
                        const newKey = [k, key].join('.');
                        return [newKey, { ...v, fieldName: newKey }];
                    }),
                );
            });
            return [exp.getOrElse({})];
        })
        .reduce((prev, curr) => {
            return { ...prev, ...curr };
        }, {});

    const currentViewExpressions = viewConfiguration
        .map(parseConfig)
        .map((e) => e.map((c) => c.conceptIdsForFields))
        // prints errors for the failed configs
        .map((e) =>
            e.mapLeft((error) => {
                maybeLogError(viewConfig)(parsingOrValidationErrMsg(error));
                return error;
            }),
        )
        .chain(fromEither)
        .chain(fromNullable)
        .map((v) => {
            // Either empty object or array object
            return mapOption(
                Object.entries(v).map(([f, e]) => [f, removeMethodHashes(e)]),
                ([fieldName, expression]) =>
                    fromEither(
                        tryCatch(
                            () => {
                                const impl = getImpl();
                                let compiled = impl.compileExpression(expression);
                                if (compiled.type === 'parse_failure') {
                                    throw new Error(compiled.msg);
                                }
                                let expr = expression;
                                if (rebaseOntoRootPath) {
                                    compiled = compiled.rebase
                                        ? compiled.rebase((path) => {
                                              // Verify path
                                              if (
                                                  isValidEntityFieldExpression(
                                                      viewConfig,
                                                      viewConfig.views[viewName].entity,
                                                      path,
                                                  )
                                              ) {
                                                  return [rebaseOntoRootPath];
                                              }
                                              return [];
                                          })
                                        : compiled;
                                    expr = compiled.prettyPrint();
                                }

                                const expansionsRequired = compiled.getExpansionsWithAll();
                                const dataPaths = compiled.getPathsWithAll();
                                const valuesetLiterals = compiled.getValueSetLiterals();
                                return {
                                    expression: expr,
                                    fieldName,
                                    expansionsRequired,
                                    dataPaths,
                                    valuesetLiterals,
                                };
                            },
                            (e: Error) => {
                                // prints errors for the failed SPEL compilation
                                maybeLogError(viewConfig)('Error parsing SPEL entityConfig validation expression', e);
                                return e;
                            },
                        ).chain(setupValuesetFieldsRequired(viewConfig, viewConfig.views[viewName].entity, expression)),
                    ),
            );
        })
        .map((v) => Object.fromEntries(v.map((c) => [c.fieldName, c])));
    if (currentViewExpressions.isNone()) {
        if (Object.keys(componentFieldExpressions).length > 0) {
            return some(componentFieldExpressions);
        }
    }

    return currentViewExpressions.map((v) => ({ ...componentFieldExpressions, ...v }));
};

export default getConceptExpressions;
