import { TaskForm } from '../../../../../reducers/taskFormType';

/*
    Get all the ids of recaptcha elements.

    This is because we want to null out our recaptchas on failed submission.
    The way I want to do this is for the form to look up those pieces of the form data, and set their values to 'null'
    Then the component can reset() the ReCAPTCHA instance when it sees its value (which it is subscribed to) set to null

*/
export const getAllRecaptchaInstances = (formDefinition: TaskForm): string[] => {
    return formDefinition.fields.filter((f) => f.type === 'recaptcha').map((f) => f.id);
};
