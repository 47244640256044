import React from 'react';
import { TextField, InputAdornment, Theme, makeStyles } from '@material-ui/core';
import get from 'lodash/get';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
        <NumericFormat
            {...other}
            fixedDecimalScale={true}
            thousandSeparator={true}
            getInputRef={inputRef}
            decimalScale={2}
        />
    );
};

const getValue = (record, source) => {
    return get(record, source);
};

const hideIfNoRecord = branch(
    (props) => !props.record || (!getValue(props.record, props.source) && props.hideLabel), // record is not provided
    renderNothing,
);

const useStyles = makeStyles((theme: Theme) => ({
    underline: {
        '&::after': {
            transform: 'unset',
            borderBottom: '1px solid rgb(189, 189, 189)',
        },
    },
}));

const CurrencyDisplay = ({ record, source, ariaInputProps }) => {
    // const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const classes = useStyles();
    return (
        <TextField
            InputLabelProps={{
                shrink: true,
                disabled: false,
            }}
            fullWidth
            // variant={fieldVariant}
            value={getValue(record, source)}
            InputProps={{
                classes: { underline: classes.underline },
                inputComponent: NumberFormatCustom,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{ ...ariaInputProps, style: { padding: '2px 0' } }} // eslint-disable-line
            disabled={true}
        />
    );
};

export default hideIfNoRecord(CurrencyDisplay);
