import { createCustomAction } from 'typesafe-actions';
import { CHECK_LAST_REQUEST_TIME, CHECK_LAST_REQUEST_TIME_SUCCESS, CHECK_LAST_REQUEST_TIME_FAILURE } from './constants';
import { AjaxError } from 'rxjs/ajax';

export const checkLastRequestTime = createCustomAction(CHECK_LAST_REQUEST_TIME, (type) => {
    return (spawnLoopUntilSuccess: boolean = true) => ({
        type,
        spawnLoopUntilSuccess,
    });
});

export const checkLastRequestTimeSuccess = createCustomAction(CHECK_LAST_REQUEST_TIME_SUCCESS, (type) => {
    return () => ({
        type,
    });
});

export const checkLastRequestTimeFailure = createCustomAction(CHECK_LAST_REQUEST_TIME_FAILURE, (type) => {
    return (error: AjaxError) => ({
        type,
        // we use the _error key to prevent redirect the way we do on normal 401 errors.
        _error: error,
    });
});
