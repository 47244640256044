import * as fieldTypes from '../../fieldTypes';
import getInputTypeFromFlowableField from './getTypeFromFlowableField';
import getValidationFromFlowableField from './getValidationFromFlowableField';
import ViewConfig from '../../../reducers/ViewConfigType';
import * as flowableFieldTypes from './flowableFieldTypes';
import { FormFieldUnion, SimpleFormField } from './types';
import {
    FieldFromFlowable,
    FlowableDropdownField,
    FlowableExpressionField,
    FlowableDataField,
    FlowableRadioField,
} from '../types';
import { DataSource } from '../types/DataSource';
import { Mode } from 'fieldFactory/Mode';
import { IntlShape } from 'react-intl';

// output type
export interface BaseFlowableField {
    name: string;
    type: (typeof fieldTypes)[keyof typeof fieldTypes];
    'data-originaldefinition': string;
    label: string | null;
    readOnly?: boolean;
    value?: any;
    required?: boolean;
    params?: SimpleFormField['params'];
    description?: string;
    _dataSource: DataSource.FLOWABLE;
}

export const mapFormField = (
    viewConfig: ViewConfig,
    flowableFieldDefinition: FormFieldUnion,
    linkedEntity?: string, // passed for the sake of validating linkedEntity fields against entity rules.
    // comes from props passed to withFieldFactory decorated component. (props.relatedEntityResource)
): FieldFromFlowable => {
    const originalDefinition = JSON.stringify(flowableFieldDefinition);
    const { id: name, name: label, readOnly, type: flowableType, value } = flowableFieldDefinition;

    if (flowableType === undefined) {
        console.warn('field with undefined type', flowableFieldDefinition);
    }

    const type = getInputTypeFromFlowableField(flowableType);
    const { params: oldParams, required } = flowableFieldDefinition;
    const params = {
        ...oldParams,
    };
    if (oldParams && (oldParams as any).singleSelectValueSet) {
        params['valueSet'] = (oldParams as any).singleSelectValueSet;
    }
    const description = (() => {
        const fc = params?.configs?.fieldConfig;
        if (fc) {
            try {
                return JSON.parse(fc).description;
            } catch {
                return undefined;
            }
        }
        return undefined;
    })();
    const baseFieldAttrs: BaseFlowableField = {
        name,
        type,
        label,
        readOnly,
        value,
        required,
        params,
        description,
        'data-originaldefinition': originalDefinition,
        _dataSource: DataSource.FLOWABLE,
    };

    switch (flowableFieldDefinition.type) {
        case flowableFieldTypes.EXPRESSION: {
            const { expression } = flowableFieldDefinition;
            return {
                ...baseFieldAttrs,
                type,
                name: name || `ExpressionField:${name}-${expression}-${value}-${label}`,
            } as FlowableExpressionField;
        }
        case flowableFieldTypes.RADIO:
        case flowableFieldTypes.DROPDOWN: {
            const { options, hasEmptyValue } = flowableFieldDefinition;
            return {
                ...baseFieldAttrs,
                type,
                options,
                hasEmptyValue,
                warn: getValidationFromFlowableField(flowableFieldDefinition),
            } as FlowableDropdownField | FlowableRadioField;
        }
        default: {
            /*
            case flowableFieldTypes.TABLE:
            case flowableFieldTypes.VALUE_SET_DROPDOWN:
            case flowableFieldTypes.ENTITY_LOOKUP:
            */
            const warn = getValidationFromFlowableField(flowableFieldDefinition);
            return {
                ...baseFieldAttrs,
                type,
                params,
                warn,
            } as FlowableDataField;
        }
    }
};

export default (intl: IntlShape, viewConfig, linkedEntity?: string, mode?: Mode) =>
    (
        fieldDefinitions, // mode is there just to match interface
    ): FieldFromFlowable =>
        fieldDefinitions.map((flowableDef) => {
            const res = mapFormField(viewConfig, flowableDef, linkedEntity);
            if (mode === Mode.INPUT_NOWARN) {
                if (res['warn']) {
                    const { warn, ...rest } = res as any;
                    return {
                        ...rest,
                        validate: warn,
                    };
                }
            }
            return res;
        });
