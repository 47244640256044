import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { WrappedFieldInputProps, change, formValueSelector } from 'redux-form';
import { Subtract } from 'utility-types';
import { RootState } from 'reducers/rootReducer';
import { getValue } from '../DebouncedTextInput';

const equalEnough = (v1, v2) => {
    if (v1 === '' && v2 === null) {
        return true;
    }
    if (v1 === null && v2 === '') {
        return true;
    }
    return v1 === v2;
};
const getMakeMapStateToProps = (linkedFormId: string, fieldInOtherForm: string) => () => {
    let selector: undefined | ((state: RootState, fieldName: string) => any);
    return (state: RootState) => {
        if (!selector) {
            selector = formValueSelector(linkedFormId);
        }
        return {
            valueInOtherForm: selector(state, fieldInOtherForm),
        };
    };
};
const getDispatch = (linkedFormId: string, fieldInOtherForm: string) => (dispatch) => ({
    propagateChangeToOtherForm: (value) => dispatch(change(linkedFormId, fieldInOtherForm, value)),
});
const getEnhance = (linkedFormId: string, fieldInOtherForm: string) =>
    compose(
        connect(getMakeMapStateToProps(linkedFormId, fieldInOtherForm), getDispatch(linkedFormId, fieldInOtherForm)),
    );

/*
    Usage:
    const NewComponent = bindFieldToOtherForm('current-task-form', nameInOtherForm)(BaseComponent);

    we need 2-way binding,
    (maybe?: combine bindings.) Really though, Saving the entity should be prevented if either field has errors...
*/
interface InjectedProps {
    input: Partial<WrappedFieldInputProps>;
}

const bindFieldToOtherForm =
    <BaseProps extends InjectedProps>(linkedForm: string, nameInOtherForm: string) =>
    (_BaseComponent: React.ComponentType<BaseProps>) => {
        // fix for TypeScript issues: https://github.com/piotrwitek/react-redux-typescript-guide/issues/111
        const BaseComponent = _BaseComponent as React.ComponentType<InjectedProps>;
        const enhance = getEnhance(linkedForm, nameInOtherForm);
        type HocProps = Subtract<BaseProps, InjectedProps> &
            ReturnType<ReturnType<ReturnType<typeof getMakeMapStateToProps>>> &
            ReturnType<ReturnType<typeof getDispatch>> & {
                input: WrappedFieldInputProps;
            };
        type HocState = {
            readonly value: any;
        };
        class Hoc extends React.Component<HocProps, HocState> {
            static displayName = `bindFieldToOtherForm(${BaseComponent.name})`;
            static readonly WrappedComponent = BaseComponent;
            /* static getDerivedStateFromProps(props: HocProps, state: HocState): null | HocState {
            if (props.input.value !== state.value) {
                return { value: props.input.value };
            }
            if (props.valueInOtherForm !== state.value) {
                return { value: props.valueInOtherForm };
            }
            return null;
        }
        */
            readonly state: HocState = {
                value: (this.props.input && this.props.input.value) || '',
            };
            componentDidUpdate(prevProps: HocProps, prevState: HocState, snapshot) {
                if (
                    !equalEnough(prevProps.input.value, this.props.input.value) &&
                    !equalEnough(this.props.input.value, this.state.value)
                ) {
                    this.setState(
                        {
                            value: this.props.input.value,
                        },
                        () => this.props.propagateChangeToOtherForm(this.props.input.value),
                    );
                } else if (!equalEnough(prevProps.valueInOtherForm, this.props.valueInOtherForm)) {
                    this.setState(
                        {
                            value: this.props.valueInOtherForm,
                        },
                        () => this.props.input.onBlur(this.props.valueInOtherForm),
                    );
                }
            }
            handleChange: WrappedFieldInputProps['onChange'] = (eventOrValue) => {
                const { propagateChangeToOtherForm } = this.props;
                const value = getValue(eventOrValue);
                propagateChangeToOtherForm(value);
                this.props.input.onChange(value);
            };
            handleBlur: WrappedFieldInputProps['onBlur'] = (eventOrValue) => {
                const { propagateChangeToOtherForm } = this.props;
                const value = getValue(eventOrValue);
                propagateChangeToOtherForm(value);
                this.props.input.onBlur(value);
            };
            render() {
                return (
                    <BaseComponent
                        {...this.props}
                        input={{
                            onBlur: this.handleBlur,
                            onChange: this.handleChange,
                            value: this.state.value,
                        }}
                    />
                );
            }
        }
        return enhance(Hoc);
    };

export default bindFieldToOtherForm;
