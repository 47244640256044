import { useEffect, useState, useCallback } from 'react';
import { services } from 'sideEffect/services';
import { RemoteData, initial, pending, success, failure } from '@devexperts/remote-data-ts';
import { AjaxError } from 'rxjs/ajax';
import { ReportDefinition } from 'report2/ReportDefinition';
import produce from 'immer';

/**
 * Remove any asterisks from the ends of names of fields
 */
const cleanReportDefinition = (reportDefinition: ReportDefinition) => {
    return produce(reportDefinition, (draft) => {
        draft.params.forEach((p) => {
            if (p.name.endsWith('*')) {
                p.name = p.name.slice(0, -1);
            }
        });
    });
};

const useReportDefinition = (reportName: string) => {
    const [refreshKey, setRefreshKey] = useState(1);
    const refresh = useCallback(() => {
        setRefreshKey(refreshKey + 1);
    }, [refreshKey, setRefreshKey]);
    const [state, setState] = useState<RemoteData<AjaxError, ReportDefinition>>(initial);
    const fetchReport = useCallback(
        (_reportName) => {
            const $ajax = services.getReportDefinition(_reportName);
            setState(pending);
            const subscription = $ajax.subscribe(
                (res) => {
                    const cleanedDefinition = cleanReportDefinition(res);
                    setState(success(cleanedDefinition));
                },
                (error: AjaxError) => {
                    console.error(error);
                    setState(failure(error));
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        },
        [setState],
    );
    useEffect(() => {
        const cleanup = fetchReport(reportName);
        return cleanup;
    }, [refreshKey, reportName, fetchReport]);
    return [state, refresh] as const;
};
export default useReportDefinition;
