import { createCustomAction } from 'typesafe-actions';
import { SET_DOWNLOADED_LIST_VIEWS, UNSET_DOWNLOADED_LIST_VIEWS } from './constants';
import { DownloadedListViews } from './data';

export const setDownloadedListViews = createCustomAction(SET_DOWNLOADED_LIST_VIEWS, (type) => {
    return (data: DownloadedListViews) => ({
        type,
        payload: {
            data,
        },
    });
});

export const unsetDownloadedListViews = createCustomAction(UNSET_DOWNLOADED_LIST_VIEWS, (type) => {
    return () => ({
        type,
    });
});
