import Form from '@rjsf/material-ui/dist';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import validator from '@rjsf/validator-ajv8';
import TextInput, { TextFieldInputProps } from 'fieldFactory/input/components/DebouncedTextInput';
import customWidgets from 'fieldFactory/input/components/JsonSchemaForm/customWidgets';
import { ValidatorType } from '@rjsf/utils';

const ControlledJSONSchemaForm: FunctionComponent<{
    name: string;
    defaultValue?: {};
    schema: string;
    uiSchema: string;
    asString?: boolean;
    validator?: ValidatorType;
}> = ({
    name,
    defaultValue,
    schema: schemaStr,
    uiSchema: uiSchemaStr,
    validator: appliedValidator,
    asString = false,
}) => {
    const { setValue, register, unregister, watch } = useFormContext();
    const handleChange = useCallback(
        (value) => {
            if (asString) {
                value = JSON.stringify(value);
            }
            setValue(name, value, {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        [setValue, name, asString],
    );
    React.useEffect(() => {
        register({ name });
        return () => {
            unregister(name);
        };
    }, []); // eslint-disable-line
    let value = watch(name, defaultValue);
    if (asString) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            value = '';
        }
    }
    const input = useRef<Partial<TextFieldInputProps<string>>>({});
    input.current.value = value;
    input.current.onChange = handleChange;
    input.current.onBlur = handleChange;

    const schema = useMemo(() => JSON.parse(schemaStr), [schemaStr]);
    const uiSchema = useMemo(() => {
        const _uiSchema = JSON.parse(uiSchemaStr);
        return {
            ..._uiSchema,
            'ui:submitButtonOptions': {
                norender: true,
            },
        };
    }, [uiSchemaStr]);
    return (
        <TextInput
            debounceTime={500}
            emptyInitialValue=""
            input={input.current as TextFieldInputProps<string>}
            renderInput={(args) => {
                return (
                    <Form
                        validator={appliedValidator || validator}
                        widgets={customWidgets}
                        schema={schema}
                        uiSchema={uiSchema}
                        formData={args.value || ''}
                        onChange={(e) => {
                            args.onChange(e.formData || '');
                        }}
                        onBlur={() => {
                            args.onBlur(args.value);
                        }}
                    />
                );
            }}
        />
    );
};

export default ControlledJSONSchemaForm;
