/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['6d32b963'] = validate10;
const schema11 = {
    type: 'object',
    title: 'Entity Search',
    properties: {
        entity: { title: 'Entity', type: 'string', description: 'The entity type to search for' },
        viewName: {
            title: 'View Name',
            type: 'string',
            description: 'Optional: Provide the name of a custom list view on the entity',
        },
        navTo: {
            title: 'Route to navigate to on item click',
            type: 'string',
            description: 'Optional: you can template a route. e.g. /processes/$[id]',
        },
        filterConfig: {
            title: 'filter',
            type: 'string',
            description: 'E.g. foo.bar__IN=el1;el2,key=value,key2=value2',
        },
        showSearchFields: { title: 'Show Search Fields', type: 'boolean' },
    },
    dependencies: {},
    required: [],
    $id: '6d32b963',
};

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="6d32b963" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.entity !== undefined) {
            let data0 = data.entity;
            if (typeof data0 !== 'string') {
                const err0 = {
                    instancePath: instancePath + '/entity',
                    schemaPath: '#/properties/entity/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.entity.type,
                    parentSchema: schema11.properties.entity,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
        if (data.viewName !== undefined) {
            let data1 = data.viewName;
            if (typeof data1 !== 'string') {
                const err1 = {
                    instancePath: instancePath + '/viewName',
                    schemaPath: '#/properties/viewName/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.viewName.type,
                    parentSchema: schema11.properties.viewName,
                    data: data1,
                };
                if (vErrors === null) {
                    vErrors = [err1];
                } else {
                    vErrors.push(err1);
                }
                errors++;
            }
        }
        if (data.navTo !== undefined) {
            let data2 = data.navTo;
            if (typeof data2 !== 'string') {
                const err2 = {
                    instancePath: instancePath + '/navTo',
                    schemaPath: '#/properties/navTo/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.navTo.type,
                    parentSchema: schema11.properties.navTo,
                    data: data2,
                };
                if (vErrors === null) {
                    vErrors = [err2];
                } else {
                    vErrors.push(err2);
                }
                errors++;
            }
        }
        if (data.filterConfig !== undefined) {
            let data3 = data.filterConfig;
            if (typeof data3 !== 'string') {
                const err3 = {
                    instancePath: instancePath + '/filterConfig',
                    schemaPath: '#/properties/filterConfig/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.filterConfig.type,
                    parentSchema: schema11.properties.filterConfig,
                    data: data3,
                };
                if (vErrors === null) {
                    vErrors = [err3];
                } else {
                    vErrors.push(err3);
                }
                errors++;
            }
        }
        if (data.showSearchFields !== undefined) {
            let data4 = data.showSearchFields;
            if (typeof data4 !== 'boolean') {
                const err4 = {
                    instancePath: instancePath + '/showSearchFields',
                    schemaPath: '#/properties/showSearchFields/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.showSearchFields.type,
                    parentSchema: schema11.properties.showSearchFields,
                    data: data4,
                };
                if (vErrors === null) {
                    vErrors = [err4];
                } else {
                    vErrors.push(err4);
                }
                errors++;
            }
        }
    } else {
        const err5 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
