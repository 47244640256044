import mergeWithViewConfig from 'injectViews';
import ViewConfig from 'reducers/ViewConfigType';
import setupDefaultViews from './setupDefaultViews/setupDefaultViews';

const modifyViewConfig = (viewConfig: ViewConfig): ViewConfig => {
    // right now, this doesn't do the override of views with viewDefs,
    // it only injects views the app defines, and adjusts the defaultViews entry for entities
    // TODO: Might be good to move the view adjustment here,
    // (currently it's being done in the useViewConfig hook, and possibly some other places as well.)
    return setupDefaultViews(mergeWithViewConfig(viewConfig));
};
export default modifyViewConfig;
