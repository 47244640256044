import { createCustomAction } from 'typesafe-actions';
import {
    GET_PRIVATE_GLOBAL_ALERTS,
    GET_PRIVATE_GLOBAL_ALERTS_FAILURE,
    GET_PRIVATE_GLOBAL_ALERTS_SUCCESS,
} from './constants';
import { AjaxError } from 'rxjs/ajax';
import { GlobalAlert } from '../definitions';

export const getPrivateGlobalAlerts = createCustomAction(GET_PRIVATE_GLOBAL_ALERTS, (type) => {
    return () => ({
        type,
    });
});

export const getPrivateGlobalAlertsFailure = createCustomAction(GET_PRIVATE_GLOBAL_ALERTS_FAILURE, (type) => {
    return (error: AjaxError) => ({
        type,
        error,
    });
});

export const getPrivateGlobalAlertsSuccess = createCustomAction(GET_PRIVATE_GLOBAL_ALERTS_SUCCESS, (type) => {
    return (globalAlerts: GlobalAlert[]) => ({
        type,
        payload: {
            globalAlerts,
        },
    });
});
