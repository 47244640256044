import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOfflineTasks } from '../offlineTasks/actions';

export const loadOfflineTasksFromIDB = async () => {
    const taskIds = await idbKeyval.keys();
    const offlineTasks = taskIds.reduce((prev, curr) => {
        prev[curr.toString()] = true;
        return prev;
    }, {} as { [taskId: string]: true });
    return offlineTasks;
};

const LoadOfflineTasksFromIDB = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (process.env.NODE_ENV === 'test') {
            // IndexedDB not available in tests.
            return;
        }
        const load = async () => {
            const offlineTasks = await loadOfflineTasksFromIDB();
            dispatch(setOfflineTasks(offlineTasks));
        };
        load();
    }, [dispatch]);
    return null;
};

export default LoadOfflineTasksFromIDB;
