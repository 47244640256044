import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'; // v1.x
import { RenderFormProps } from './LoginFormProps';
import LoginAppBar from './LoginAppBar';
import { Card, CardActions } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLoginConfiguredTextSelector, getShowContinueWithoutLoggingInSelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import LinkButton from 'components/links/LinkButton';
import AppFooter from 'footer';
import useBasicInfo from 'util/hooks/useBasicInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.main,
        },
        backdrop: {
            minHeight: '100vh',
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
        },
        mainBack: {
            height: '100vh',
            overflow: 'hidden',
            display: 'grid',
            placeItems: 'center',
        },
        main: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        footerContainer: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            overflow: 'hidden',
        },
        card: {
            minWidth: 300,
        },
        avatar: {
            margin: '1em',
            textAlign: 'center',
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: 16,
            width: '100%',
            display: 'flex',
        },
    }),
);

const DefaultLoginForm: React.FC<
    RenderFormProps & {
        ErrorMessageElement: JSX.Element;
    }
> = (props) => {
    const classes = useStyles();
    const loginConfiguredText = useSelector(getLoginConfiguredTextSelector);
    const showContinueWithoutLoggingIn = useSelector(getShowContinueWithoutLoggingInSelector);
    const basicInfo = useBasicInfo();
    return (
        <div
            className={classes.backdrop}
            role="main"
            style={{
                display: 'table',
                tableLayout: 'fixed',
                height: '100%',
                width: '100%',
            }}
        >
            <div style={{ display: 'table-row' }}>
                <LoginAppBar />
            </div>
            <div
                style={{
                    display: 'table-row',
                    height: '100%',
                }}
            >
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>{props.Logo}</div>
                        {basicInfo.formLoginEnabled === false ? (
                            !!loginConfiguredText && (
                                <div style={{ padding: '0px 16px' }}>
                                    <SafeHtmlAsReact html={loginConfiguredText} />
                                </div>
                            )
                        ) : (
                            <>
                                <div className={classes.form}>
                                    {props.ErrorMessageElement}
                                    <br />
                                    <div className={classes.input}>{props.UsernameElement}</div>
                                    <div className={classes.input}>{props.PasswordElement}</div>
                                </div>
                                {loginConfiguredText && (
                                    <div style={{ padding: '0px 16px' }}>
                                        <SafeHtmlAsReact html={loginConfiguredText} />
                                    </div>
                                )}
                                <CardActions>{props.SubmitButtonElement}</CardActions>
                                <CardActions style={{ paddingTop: 0 }}>{props.PasswordResetButtonElement}</CardActions>
                            </>
                        )}
                        {props.BelowLoginFormElement ?? null}
                        {(window as any).CASETIVITY_ANON_VIEW_CONFIG && showContinueWithoutLoggingIn && (
                            <CardActions style={{ paddingTop: 0 }}>
                                <p
                                    style={{
                                        margin: '0px',
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <LinkButton to="/">Continue without logging in</LinkButton>
                                </p>
                            </CardActions>
                        )}
                    </Card>
                </div>
            </div>
            <div
                style={{
                    display: 'table-row',
                }}
            >
                <AppFooter />
            </div>
        </div>
    );
};
export default DefaultLoginForm;
