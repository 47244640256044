import React from 'react';
import { FormControl, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Subtract } from 'utility-types';
import uniqueId from 'lodash/uniqueId';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import CasetivitySelect from 'components/CasetivitySelect';

type Input = any;
type Meta = any;

const styles = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 195,
    },
});

interface Choices {
    [key: string]: {};
}
interface SelectProps {
    disabled?: boolean;
    classes: {
        formControl: string;
    };
    input: Input;
    meta?: Meta;
    label: string;
    choices: Choices;
    inputId?: string;
    inputName?: string;
    source?: string;
    nameProp?: string;
    valueProp?: string;
    showNoneChoice?: boolean;
    noneChoiceText?: string;
}
const SelectComponent: React.SFC<SelectProps> = ({
    source,
    classes,
    input,
    meta,
    label,
    choices,
    inputId,
    inputName,
    nameProp = 'name',
    valueProp,
    showNoneChoice = true,
    disabled,
    noneChoiceText = 'None',
}) => {
    const { fieldVariant, forceLabelShrink } = React.useContext(themeOverrideContext);
    const inputLabelId = React.useRef(uniqueId('ChoicesSelect'));
    return (
        <FormControl variant={fieldVariant} disabled={disabled} className={classes.formControl}>
            <InputLabel shrink={forceLabelShrink} id={inputLabelId.current}>
                {label}
            </InputLabel>
            <CasetivitySelect
                label={label}
                disabled={disabled}
                value={input.value}
                onChange={(e) => input.onBlur(e.target.value || undefined)}
                SelectDisplayProps={{ 'aria-describedby': inputLabelId.current }}
                inputProps={{
                    name: inputName,
                    id: inputId,
                }}
            >
                {({ OptionComponent }) => {
                    let res = [];
                    if (showNoneChoice) {
                        res.push(
                            <OptionComponent key="none-default" value="">
                                {noneChoiceText}
                            </OptionComponent>,
                        );
                    }
                    if (!choices) {
                        return res;
                    }
                    return [
                        ...res,
                        ...Object.entries(choices).map(([key, choice]) => (
                            <OptionComponent
                                className="dropdownMenuItem"
                                key={key}
                                value={valueProp ? choice[valueProp] : key}
                            >
                                {choice[nameProp]}
                            </OptionComponent>
                        )),
                    ];
                }}
            </CasetivitySelect>
        </FormControl>
    );
};
const SelectInput: React.SFC<Subtract<SelectProps, Pick<SelectProps, 'classes'>>> = withStyles(styles)(
    SelectComponent,
) as any;

export default SelectInput;
