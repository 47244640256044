/* eslint-disable no-restricted-globals */
import { EncryptedData } from './domain';
import { _encrypt, _decrypt, EncryptionInput, SessionLocalSecrets } from './encryptionUtils';
/**
 *
 * @param {any} data JSON data to encrypt
 * @param {string} pin encryption PIN
 * @returns {string} encrypted data
 */
export const encrypt = async (
    data: any,
    encryptionInput: EncryptionInput,
): Promise<[EncryptedData, SessionLocalSecrets]> => {
    return await _encrypt(JSON.stringify(data), encryptionInput);
};

/**
 * @param {EncryptedData} encryptedData data encrypted with PIN
 * @param {string} pin to attempt decryption.
 * @returns {any} encrypted value
 * @throws Error if pin doesn't match encrypted data.
 */
export const decrypt = async (encryptedData: EncryptedData, encryptionInput: EncryptionInput) => {
    try {
        const json = await _decrypt(encryptedData, encryptionInput);
        return JSON.parse(json);
    } catch (e) {
        console.error(e);
        throw new Error('An unexpected error occured while decrypting data');
    }
};
