import * as taskForm from '../actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from 'reducers/taskFormType';

import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap, withLatestFrom } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import getValuesetsForFormAction from './getValuesetsForFormAction';
import getRefOnesForFormActions from './getRefOnesForFormActions';
import ViewConfig from 'reducers/ViewConfigType';
import getRefManysForFormActions from './getRefManysForFormActions';
// import { tap } from 'rxjs/operators';
// import { getSubsequentActions } from 'workers/subsequentActionsCalculatedForForm/epic';

export const getFollowupActionsFromTaskForm = (taskForm: TaskForm, viewConfig: ViewConfig) => {
    return [
        getValuesetsForFormAction(taskForm, viewConfig),
        ...getRefOnesForFormActions(taskForm, viewConfig),
        ...getRefManysForFormActions(taskForm, viewConfig),
    ];
};

const fetchTaskFormFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(taskForm.getTaskForm)),
        withLatestFrom(state$.pipe(map((state) => state.viewConfig))),
        flatMap(([action, viewConfig]) =>
            concat(
                of(taskForm.getTaskFormLoading(action.payload.taskId)),
                services.getTaskForm(action.payload.taskId).pipe(
                    flatMap((response) => {
                        const actions = [
                            ...getFollowupActionsFromTaskForm(response, viewConfig),
                            taskForm.getTaskFormSuccess(action.payload.taskId, response),
                        ];
                        return concat(...actions.map((action) => of(action)));
                    }),
                    // tap(response => {
                    //     /* Do heavy calculations based on taskForm
                    //     in a web-worker. The subsequentActionsCalculatedForForm epic
                    //     has an event listener on the worker, and will dispatch the necessary
                    //     actions when finished.
                    //     This includes dispatching the actions to fetch the necessary valuesets,
                    //     and any records that need to be fetched.
                    //     */
                    //     getSubsequentActions(response, viewConfig);
                    // }),
                    // map(response => taskForm.getTaskFormSuccess(action.payload.taskId, response)),
                    catchError((err) => {
                        console.error(err);
                        return of(taskForm.getTaskFormFailure(action.payload.taskId, err));
                    }),
                ),
            ),
        ),
    );
export default fetchTaskFormFlow;
