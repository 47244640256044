import React from 'react';
import { withWidth } from '@material-ui/core';
import { WithWidth, isWidthUp } from '@material-ui/core/withWidth';
import { Width } from 'bpm/components/layout/withAdjustedWidth';

interface ViewTitleProps {
    title: string | JSX.Element;
    displayAboveWidth?: Width;
}
interface ViewTitleComponentProps extends WithWidth, ViewTitleProps {}
const ViewTitleComponent: React.SFC<ViewTitleComponentProps> = ({ title, width, displayAboveWidth }) => {
    if (displayAboveWidth && !isWidthUp(displayAboveWidth, width, false)) {
        return null;
    }
    return <h1>{title}</h1>;
};

const ViewTitle: React.ComponentType<ViewTitleProps> = withWidth({
    initialWidth: 'md',
})(ViewTitleComponent);
export default ViewTitle;
