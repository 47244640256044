import { isZero, isNumEqual } from 'expressions/contextUtils';
import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import fromEntries from 'util/fromentries';
import { createExtraTaskContextSelector } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { setupGenericContext } from './setupGenericContext';

export const universalContext = {
    ...createRootContext({}),
    isZero,
    isNumEqual,
    isFrontend: () => true,
    isBackend: () => false,
};

const stubbedExtraTaskContext: ReturnType<ReturnType<typeof createExtraTaskContextSelector>> = {
    _taskComplete: false,
};

export const contextFunctions = fromEntries(
    Object.entries(
        setupGenericContext({
            viewConfig: {} as any,
            entities: {},
            valueSets: {},
            viewContext: 'ENTITY',
            backref: {} as any,
        }),
    )
        .filter(([k, v]) => typeof v === 'function')
        .map(([k]) => [k, true] as [string, true]),
);

export const taskContextPrimitives = fromEntries(
    Object.entries(
        setupGenericContext({
            viewConfig: {} as any,
            entities: {},
            valueSets: {},
            viewContext: 'PROCESS_TASK',
            extra: stubbedExtraTaskContext,
        }),
    )
        .filter(([k, v]) => typeof v !== 'function')
        .map(([k]) => [k, true] as [string, true]),
);
