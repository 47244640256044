import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import { RootState } from 'reducers/rootReducer';
import { getAllFieldEntriesFromView, isFieldViewField } from '../utils/viewConfigUtils';
import ViewConfig from 'reducers/ViewConfigType';

const emptyArray = [];

export const createValidationVSCodeLiteralsSelector = <
    P extends { overrideViewConfig?: ViewConfig; viewName: string; resource: string },
>() => {
    const selector = createSelector(
        // (state: RootState, props: P) => state.entityValidations[props.resource],
        (state: RootState, props: P) => state.entityValidations,
        (state: RootState, props: P) => props.viewName,
        (state: RootState, props: P) => props.resource,
        (state: RootState, props: P) => props.overrideViewConfig || state.viewConfig,
        (entityValidations, viewName, resource, viewConfig) => {
            const valuesetLiterals = uniq(
                getAllFieldEntriesFromView(viewConfig, viewName)
                    .flatMap(([fieldKey, field]) => {
                        if (isFieldViewField(field) && field.entity && field.entity !== resource) {
                            // we want to include the validations for this entity, if they exist
                            return [field.entity];
                        }
                        return [];
                    })
                    .concat([resource])
                    .flatMap((entity) => {
                        if (entityValidations[entity]) {
                            return entityValidations[entity].flatMap((ev) => ev.valuesetLiterals);
                        }
                        return [];
                    }),
            );
            if (valuesetLiterals.length > 0) {
                return valuesetLiterals;
            }
            return emptyArray;
        },
    );
    return selector;
};
export const createVisibilityVSCodeLiteralsSelector = <P extends { viewName: string }>() => {
    const selector = createSelector(
        (state: RootState, props: P) => state.entityVisibility[props.viewName],
        (entityVisibility) => {
            return entityVisibility
                ? uniq(Object.values(entityVisibility).flatMap((evarr) => evarr.flatMap((ev) => ev.valuesetLiterals)))
                : emptyArray;
        },
    );
    return selector;
};

export const createConceptAvailVSCodeLiteralsSelector = <P extends { viewName: string }>() => {
    const selector = createSelector(
        (state: RootState, props: P) => state.entityConceptExps[props.viewName],
        (conceptAvailability) => {
            return conceptAvailability
                ? uniq(Object.values(conceptAvailability).flatMap((ev) => ev.valuesetLiterals))
                : emptyArray;
        },
    );
    return selector;
};

export const getEditableViewVSCodeLiteralsSelector = <
    P extends { overrideViewConfig?: ViewConfig; viewName: string; resource: string },
>() => {
    const vals = createValidationVSCodeLiteralsSelector<P>();
    const vis = createVisibilityVSCodeLiteralsSelector<P>();
    const conc = createConceptAvailVSCodeLiteralsSelector<P>();
    return createSelector(vals, vis, conc, (validations, visibility, conceptAvailability) =>
        uniq([...validations, ...visibility, ...conceptAvailability]),
    );
};
