import React from 'react';
import isOffline from 'util/isOffline';
import OfflineXMany, { useOfflineData } from 'fieldFactory/offline/XMany';
import RefmanyMultiselect from '.';

/**
 * This is duplicated in generateField, but importing it there causes dependency cycles to be a problem.
 */
const XManyComponent = (props) => {
    const { record, source } = props;
    const { id, entityType } = record;
    const [entriesAtCurrentNesting] = useOfflineData(entityType, source, id);
    if (isOffline() || entriesAtCurrentNesting?.some((e) => e.source === source)) {
        return <OfflineXMany {...props} />;
    }
    return <RefmanyMultiselect {...props} />;
};
export default XManyComponent;
