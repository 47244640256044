import { combineReducers } from 'redux'; // eslint-disable-line import/no-extraneous-dependencies
import { processActivityEvent as activityEvent } from '../actions/processActivityEvent';
import { ErrorDetails } from 'sagas/util/sagaFactory';
import { ProcessActivities, RemoteDataWithPrevData } from './types';

const byId = (state = {}, action) => {
    switch (action.type) {
        case activityEvent.getSuccess: {
            return Object.assign({}, state, ...action.payload.data.map((d) => ({ [d.id]: d })));
        }
        default:
            return state;
    }
};
const getPrevData = (prevEntry: RemoteDataWithPrevData<ErrorDetails | Error, string[]>) =>
    prevEntry?.status === 'success' ? prevEntry.data : prevEntry?.status === 'error' ? prevEntry.prevData : undefined;

const byProcess = (state: ProcessActivities['byProcess'] = {}, action): ProcessActivities['byProcess'] => {
    switch (action.type) {
        case activityEvent.get: {
            const prevEntry = state[action.payload.processId];
            const prevData = getPrevData(prevEntry);
            return {
                ...state,
                [action.payload.processId]: {
                    status: 'pending',
                    prevData,
                },
            };
        }
        case activityEvent.getSuccess: {
            return {
                ...state,
                [action.payload.processId]: {
                    status: 'success',
                    data: action.payload.data.map((d) => d.id),
                },
            };
        }
        case activityEvent.getFailure: {
            const prevEntry = state[action.requestPayload.processId];
            const prevData = getPrevData(prevEntry);
            return {
                ...state,
                [action.requestPayload.processId]: {
                    status: 'error',
                    error: action.payload,
                    prevData,
                },
            };
        }
        default:
            return state;
    }
};

const processActivities = combineReducers<ProcessActivities>({
    byId,
    byProcess,
});

export default processActivities;
