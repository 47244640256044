import React, { useEffect, useMemo, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useNonsensitiveOfflineTaskData } from 'offline_app/hooks/useNonsensitiveOfflineTaskData';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { createSelector } from 'reselect';
import Chip from '@material-ui/core/Chip';
import useViewConfig from 'util/hooks/useViewConfig';
import useEntities from 'util/hooks/useEntities';
import HelpIcon from '@material-ui/icons/Help';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { push } from 'connected-react-router';
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    IconButton,
    ListItemSecondaryAction,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { loadOfflineTasksFromIDB } from 'offline_app/offline_stateful_tasks/components/LoadOfflineTasksFromIDB';
import { deleteOfflineTaskData } from 'offline_app/expiration/deleteExpiredTasks';
import { getExpiredOfflineDataSubscribedComponentsRegistry } from 'offline_app/offline_stateful_tasks/ExpiredOfflineDataSubscribedComponentsRegistry';
import { setOfflineTasks } from 'offline_app/offline_stateful_tasks/offlineTasks/actions';
import loadProfile from 'auth/profiles/util/loadProfile';
import { RootState } from 'reducers/rootReducer';

export const TimeRemaining = ({ until }: { until: moment.MomentInput }) => {
    const untilMmnt = useMemo(() => moment(until), [until]);
    const initialMinutesRemaining = useMemo(() => untilMmnt.diff(new Date(), 'minutes'), [untilMmnt]);
    const [minutesRemaining, setMinutesRemaining] = useState(initialMinutesRemaining);
    useEffect(() => {
        const ivl = setInterval(() => {
            const mr = untilMmnt.diff(new Date(), 'minutes');
            setMinutesRemaining(mr);
        }, 60 * 1000);
        return () => {
            clearInterval(ivl);
        };
    }, [untilMmnt]);
    const days = Math.floor(minutesRemaining / 1440);
    const minutesRemainingAfterDays = minutesRemaining - days * 1440;
    const hours = Math.floor(minutesRemainingAfterDays / 60);
    const minutesRemainingAfterHours = minutesRemainingAfterDays - hours * 60;
    return (
        <>
            {days > 0 ? `${days} day${days === 1 ? '' : 's'}, ` : ''}
            {hours > 0 ? `${hours} hour${hours === 1 ? '' : 's'}, ` : ''}
            {minutesRemainingAfterHours} minute{minutesRemaining !== 1 ? 's' : ''}
        </>
    );
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            // maxWidth: 500,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            // maxHeight: 1200,
        },
        listSubHeaderRoot: {
            fontSize: theme.typography.subtitle1.fontSize,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
        },
    }),
);

export const useDeletePrompt = () => {
    const [deletePrompt, setDeletePrompt] = useState<{
        taskName: string;
        appCaseName: string;
        taskId: string;
    }>(null);
    const dispatch = useDispatch();
    return {
        setDeletePrompt,
        PromptElem: deletePrompt && (
            <Dialog open>
                <CardHeader title={`Delete offline data?`} />
                <CardContent>
                    <p>{deletePrompt.taskName}</p>
                    <p>{deletePrompt.appCaseName}</p>
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="primary" onClick={() => setDeletePrompt(null)}>
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            (async () => {
                                await deleteOfflineTaskData(deletePrompt.taskId);
                                setDeletePrompt(null);
                                const offlineTasks = await loadOfflineTasksFromIDB();
                                dispatch(setOfflineTasks(offlineTasks));
                                getExpiredOfflineDataSubscribedComponentsRegistry().dispatchSomeOfflineTasksExpired();
                            })();
                        }}
                    >
                        Delete&nbsp;
                        <DeleteIcon color="error" />
                    </Button>
                </CardActions>
            </Dialog>
        ),
    };
};

export const NoOfflineWork = () => (
    <div style={{ display: 'grid', placeItems: 'center' }}>
        <Typography variant="subtitle1" component="span">
            You have no offline work saved in the current browser.
        </Typography>
    </div>
);

export const getCaseTitleFromEntities = (entities: any, processId: string) => {
    const pi = entities['ProcessInstance']?.[processId];
    const appCase = entities['AppCase']?.[pi?.appCaseId];
    const processName = appCase?.['processName'];
    const linkedEntityTitle = entities[appCase?.linkedEntityType]?.[appCase?.linkedEntityId]?.title;
    return appCase
        ? (processName ? processName + (linkedEntityTitle ? ': ' : '') : appCase.title) + (linkedEntityTitle ?? '')
        : null;
};
export const ExpirationSecondaryText = ({ expirationDate }: { expirationDate: Date }) => (
    <>
        Will be deleted {moment(expirationDate).calendar()}
        <br />
        (in <TimeRemaining until={expirationDate} />)
    </>
);

const ListOfOfflineTasks: React.FunctionComponent<{
    onSelect?: () => void;
}> = ({ onSelect }) => {
    const classes = useStyles();
    const data = useNonsensitiveOfflineTaskData();
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);
    const profiles = useSelector((state: RootState) => state.profiles);
    useEffect(() => {
        if (!data) {
            return;
        }
        // fetch and request process and task instances.
        // can delay update until requests  complete via cb and errorsCbs
        dispatch(
            crudGetList({
                cb: () => {
                    setImmediate(() => setLoaded(true));
                },
                errorsCbs: {
                    '*': () => {
                        setImmediate(() => setErrored(true));
                    },
                },
                resource: 'TaskInstance',
                view: null,
                sort: { field: 'id', order: 'ASC' },
                pagination: {
                    page: 1,
                    perPage: 100,
                },
                filter: {
                    id__IN: Object.keys(data).join(','),
                },
                appendExpansions: ['processInstance.appCase.linkedEntity'],
            }),
        );
    }, [data, dispatch]);

    const getEntities = useMemo(createGetEntities, []);
    const combinedOnlineAndOfflineDataSelector = useMemo(
        () =>
            createSelector(getEntities, (entities) => {
                if (!loaded) {
                    return null;
                }
                let x = Object.entries(data).map(([taskId, record]) => {
                    const taskInstance = entities['TaskInstance']?.[taskId];
                    const processInstance = entities['ProcessInstance']?.[taskInstance?.['processInstanceId']];
                    const appCase = entities['AppCase']?.[processInstance?.['appCaseId']];
                    return {
                        taskId,
                        taskInstance,
                        processInstance,
                        appCase,
                        ...record,
                    };
                });
                const groupedData = groupBy(x, 'processInstance.id');
                return groupedData;
            }),
        [data, getEntities, loaded],
    );
    const combinedOnlineAndOfflineData = useSelector(combinedOnlineAndOfflineDataSelector);
    const entities = useEntities();
    const { PromptElem, setDeletePrompt } = useDeletePrompt();
    const store = useStore();
    const pathname = useSelector((state: RootState) => state.router.location.pathname);
    const viewConfig = useViewConfig();
    if (errored) {
        return null;
    }
    if (!data || !combinedOnlineAndOfflineData || !loaded) {
        return (
            <div style={{ padding: '1em' }}>
                <CircularProgress />
            </div>
        );
    }
    if (loaded && Object.keys(combinedOnlineAndOfflineData).length === 0) {
        return <NoOfflineWork />;
    }

    return (
        <>
            {PromptElem}
            <List
                className={classes.root}
                // not sure what the purpose is of that subheader below.
                // Removing it so it's not confusing to screen reader users.
                // FISH-3866
                // subheader={<li />}
            >
                {Object.entries(combinedOnlineAndOfflineData)?.map(([processInstanceId, children]) => {
                    const pi = entities['ProcessInstance']?.[processInstanceId];
                    const appCase = entities['AppCase']?.[pi?.appCaseId];
                    const maybeCaseTitle = getCaseTitleFromEntities(entities, processInstanceId);
                    return (
                        <li key={'pid: ' + processInstanceId} className={classes.listSection}>
                            <ul className={classes.ul}>
                                <ListSubheader classes={{ root: classes.listSubHeaderRoot }}>
                                    {maybeCaseTitle ?? (
                                        <span>
                                            Unknown Cases&nbsp;
                                            <Tooltip title="Your data may be available under a different profile, or you may have been removed access to it.">
                                                <HelpIcon
                                                    style={{
                                                        height: '16px',
                                                        width: '16px',
                                                        verticalAlign: 'middle',
                                                        marginBottom: '3px',
                                                    }}
                                                />
                                            </Tooltip>
                                        </span>
                                    )}
                                </ListSubheader>
                                {children.map((record) => {
                                    const isDifferentUser = record.userProfileId !== viewConfig.user?.id;
                                    const isAlternateProfile =
                                        isDifferentUser && profiles.state !== 'no_profiles'
                                            ? profiles.profiles?.some(({ userId }) => userId === record.userProfileId)
                                            : false;
                                    const switchText = isAlternateProfile
                                        ? 'Switch to profile: ' + record.userProfileDisplay
                                        : 'Log in as this user to continue working';
                                    return (
                                        <ListItem
                                            disabled={!pi || !appCase || (isDifferentUser && !isAlternateProfile)}
                                            onClick={() => {
                                                onSelect?.();
                                                dispatch(
                                                    push(
                                                        `/processes/${processInstanceId}/tasks/${record.taskId}/start`,
                                                    ),
                                                );
                                            }}
                                            button
                                            key={'item: ' + processInstanceId + ': ' + record.taskId}
                                        >
                                            <ListItemText
                                                inset
                                                primary={
                                                    <span>
                                                        {record.taskInstance?.name ?? 'Unknown Task'}
                                                        <span className="casetivity-off-screen">
                                                            {maybeCaseTitle
                                                                ? ` belonging to case ${maybeCaseTitle}`
                                                                : 'belonging to an unknown case'}
                                                        </span>
                                                    </span>
                                                }
                                                secondary={
                                                    <ExpirationSecondaryText expirationDate={record.expirationDate} />
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                {isDifferentUser ? (
                                                    <Tooltip title={switchText}>
                                                        <Chip
                                                            avatar={<AccountCircle />}
                                                            onClick={
                                                                isAlternateProfile
                                                                    ? () => {
                                                                          // load and redirect back to current page.
                                                                          loadProfile(
                                                                              store,
                                                                              record.userProfileId,
                                                                              pathname,
                                                                          );
                                                                      }
                                                                    : undefined
                                                            }
                                                            size="small"
                                                            label={record.userProfileDisplay}
                                                            aria-label={switchText}
                                                        />
                                                    </Tooltip>
                                                ) : !pi || !appCase ? (
                                                    <Chip size="small" label="No longer available" />
                                                ) : null}
                                                <IconButton
                                                    onClick={() =>
                                                        setDeletePrompt({
                                                            taskName: record.taskInstance?.name,
                                                            appCaseName: appCase?.title,
                                                            taskId: record.taskId,
                                                        })
                                                    }
                                                    edge="end"
                                                    aria-label="delete"
                                                    size="small"
                                                >
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </List>
        </>
    );
};
export default ListOfOfflineTasks;
