import React from 'react';
import { IconButton, IconButtonProps, makeStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    iconButtonRoot: {
        top: 0,
    },
    iconButtonRootFocusable: {
        position: 'absolute',
        opacity: 0,
        height: 0,
        width: 0,
        left: '-1rem',
        zIndex: -1000,
        '&:focus': {
            opacity: 1,
            height: 'unset',
            width: 'unset',
            zIndex: 1,
        },
    },
    iconButtonRootHovered: {
        position: 'absolute',
        left: '-1rem',
        opacity: 1,
        height: 'unset',
        width: 'unset',
        zIndex: 1,
    },
    iconRoot: {
        '-moz-transform': 'scaleX(-1)',
        '-o-transform': 'scaleX(-1)',
        '-webkit-transform': 'scaleX(-1)',
        transform: 'scaleX(-1)',
        filter: 'FlipH',
        '-ms-filter': 'FlipH',

        fontSize: '1rem',
    },
}));
const TabbableIconButton: React.ComponentType<{
    iconButtonProps: IconButtonProps;
    hovered?: boolean;
    IconComponent?: React.ComponentType<{}>;
}> = ({ iconButtonProps, hovered, IconComponent = Edit }) => {
    const classes = useStyles();
    return (
        <IconButton
            className={classNames(
                classes.iconButtonRoot,
                hovered ? classes.iconButtonRootHovered : classes.iconButtonRootFocusable,
            )}
            {...iconButtonProps}
        >
            <IconComponent className={classes.iconRoot} />
        </IconButton>
    );
};
export default TabbableIconButton;
