import uniq from 'lodash/uniq';
import { ConceptInApp } from '../domain';
import produce from 'immer';

export interface ValueSets {
    [valueSetCode: string]: {
        id?: string;
        display?: string;
        invalid: boolean;
        conceptIds: string[];
        groups: {
            [groupName: string]: {
                invalid: boolean;
                ids: string[];
            };
        };
        allConceptsLoaded?: boolean;
    };
}

const combineGroups = (
    ids: string[],
    conceptsBag: { [id: string]: ConceptInApp },
    freshGroups:
        | '*'
        | {
              [group: string]: true;
          },
    prevGroups: ValueSets[0]['groups'] = {},
) => {
    // So we don't do duplicate work.
    const getIdsMapForGroup = (() => {
        const cachedResults: {
            [groupCode: string]: { [id: string]: true };
        } = {};
        return (groupCode: string) => {
            if (!cachedResults[groupCode]) {
                cachedResults[groupCode] = (prevGroups[groupCode]?.ids ?? []).reduce((prev, curr) => {
                    prev[curr] = true;
                    return prev;
                }, {});
            }
            return cachedResults[groupCode];
        };
    })();

    const concepts = ids.map((id) => conceptsBag[id]).filter(Boolean);
    const result: ValueSets[0]['groups'] = produce(prevGroups, (draftState) => {
        concepts.forEach((concept) => {
            const groups = uniq(concept.group?.split(',')?.map((g) => g.trim()) ?? []);
            groups.forEach((groupCode) => {
                const idsMapForGroup = getIdsMapForGroup(groupCode);

                const groupCanBeConsideredFullyFetched = freshGroups === '*' || freshGroups[groupCode];
                const invalid = groupCanBeConsideredFullyFetched
                    ? false
                    : prevGroups[groupCode]
                    ? prevGroups[groupCode].invalid === true
                    : true;
                if (!draftState[groupCode]) {
                    draftState[groupCode] = {
                        ids: [],
                        invalid,
                    };
                }
                if (!idsMapForGroup[concept.id]) {
                    draftState[groupCode].ids.push(concept.id);
                }
                draftState[groupCode].invalid = invalid;
            });
        });
        return draftState;
    });
    return result;
};
export default combineGroups;
