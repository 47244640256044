import { createCustomAction } from 'typesafe-actions';
import { SET_DOWNLOADED_REF_ONE_VIEWS, UNSET_DOWNLOADED_REF_ONE_VIEWS } from './constants';
import { DownloadedRef1Views } from './data';

export const setDownloadedRefOneViews = createCustomAction(SET_DOWNLOADED_REF_ONE_VIEWS, (type) => {
    return (data: DownloadedRef1Views) => ({
        type,
        payload: {
            data,
        },
    });
});

export const unsetDownloadedRefOneViews = createCustomAction(UNSET_DOWNLOADED_REF_ONE_VIEWS, (type) => {
    return () => ({
        type,
    });
});
