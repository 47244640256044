import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm /* initialize */ } from 'redux-form';
import compose from 'recompose/compose';
import { createSelector } from 'reselect';
import { fromNullable, none, tryCatch, some, fromPredicate } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';
import { adjustFieldName } from '../../../fieldFactory/util/FormField';
import { RootState } from '../../../reducers/rootReducer';
import ViewConfig from '../../../reducers/ViewConfigType';
import { Button } from '@material-ui/core';
import RGridInner from '../fields/display/RGrid';
import { standardPadding } from 'fieldFactory/mapToFormField';
import { evaluatePreFilter } from '../utils/viewConfigUtils';
import { overrideSearchValidationsContext } from 'layout-editor/UniversalViewWizard/steps/Step3List';
import { v4 as uuidv4 } from 'uuid';
import useSearchValidator from '../form/validate/useSearchValidation';
import fromEntries from 'util/fromentries';
import flatten from 'flat';

const makeMapStateToProps = () => {
    const hiddenFieldsSelector = createGetSearchFieldsByCriteriaSelector(
        ({ visible, prefilter }) => visible === false || visible === 'false',
    );
    const disabledFieldsSelector = createGetSearchFieldsByCriteriaSelector(({ visible, prefilter, viewConfig }) => {
        const somePrefilterResult = (() => {
            if (prefilter || (prefilter as any) === false || (prefilter as any) === 0) {
                const prefilterResult = evaluatePreFilter(viewConfig)(prefilter);
                return prefilterResult.chain(fromPredicate(Boolean)).isSome();
            }
            return false;
        })();
        if (visible === 'disabled_if_prefiltered' && somePrefilterResult) {
            return true;
        }
        return (visible === true || visible === 'true') && somePrefilterResult;
    });
    const mapStateToProps = (state: RootState, props) => ({
        hiddenFields: hiddenFieldsSelector(state, props),
        disabledFields: disabledFieldsSelector(state, props),

        searchValidations: props.overrideSearchValidations[props.viewName] || state.searchValidations[props.viewName],
        form: props.formId || 'filterForm',
        viewConfig: props.viewConfig || state.viewConfig,
        concepts: state.admin.entities.Concept,
        activeField:
            state.form && state.form[props.formId || 'filterForm']
                ? state.form[props.formId || 'filterForm'].active
                : '',
        registeredFields:
            state.form && state.form[props.formId || 'filterForm']
                ? state.form[props.formId || 'filterForm'].registeredFields
                : '',
        valueSets: state.valueSets,
    });
    return mapStateToProps;
};
type ConnectedProps = ReturnType<ReturnType<typeof makeMapStateToProps>>;
interface FormProps extends Pick<ConnectedProps, 'searchValidations' | 'concepts' | 'viewConfig' | 'valueSets'> {
    filtersAttributes?: {
        source: string;
        searchType?: string;
    }[];
    setFilters: (filters: {}) => void;
    children: (arg: { handleSubmit: any }) => JSX.Element;
    searchValidation: (values: {}) => {};
}
const Form = reduxForm<{}, FormProps>({
    enableReinitialize: true,
    destroyOnUnmount: true,
    onChange: (values, dispatch, props: FormProps) => {
        props.setFilters(
            fromEntries(
                Object.entries(values).map(([k, v]) => {
                    if (typeof v === 'string') {
                        return [k, v.trim()];
                    }
                    return [k, v];
                }),
            ),
        );
    },
    shouldValidate: (params) => {
        return true;
    },
    validate: (values, props: FormProps) => {
        const fattrs = (props.filtersAttributes || [])
            .map(({ source, searchType }) => {
                return `${source.split('.').join('_~_')}${searchType ? `__${searchType}` : ''}`;
            })
            .map((k) => ({ [k]: null }));
        const valattrs = Object.entries(values).map(([key, value]) => ({ [key]: value === '' ? null : value }));
        const newValues = Object.assign({}, ...fattrs, ...valattrs);
        return Object.fromEntries(
            Object.entries(flatten(props.searchValidation(newValues))).map(([k, v]) => {
                return [k.split('.').join('_~_'), v];
            }),
        );
    },
})((props) => {
    return props.children({
        handleSubmit: props.handleSubmit,
    });
});

type GetSearchFieldsProps = { viewConfig?: ViewConfig; viewName: string };
const createGetSearchFieldsByCriteriaSelector = (
    matching: (config: {
        visible?: boolean | 'default' | 'disabled_if_prefiltered' | 'true' | 'false';
        prefilter?: string;
        viewConfig: ViewConfig;
    }) => boolean,
) =>
    createSelector(
        (state: RootState, props: GetSearchFieldsProps) => props.viewName,
        (state: RootState, props: GetSearchFieldsProps) => props.viewConfig ?? state.viewConfig,
        (viewName: string | undefined, viewConfig: ViewConfig): { [fieldName: string]: true } =>
            fromNullable(viewName)
                .chain((vn) => fromNullable(viewConfig.views[vn]))
                .chain((v) => fromNullable(v.searchFields))
                .fold<{ [fieldName: string]: true }>({}, (sf) =>
                    Object.assign(
                        {},
                        ...mapOption<[string, (typeof sf)[0]], { [fieldName: string]: true }>(
                            Object.entries(sf),
                            ([field, sfi]) =>
                                fromNullable(sfi.config || null)
                                    .chain((sfconf) => tryCatch(() => JSON.parse(sfconf)))
                                    .chain((fc) =>
                                        matching({ ...fc, viewConfig })
                                            ? some<{ [fieldName: string]: true }>({ [field]: true })
                                            : none,
                                    ),
                        ),
                    ),
                ),
    );

const emptyRecord = {};
const Hidden = (props) => <div style={{ display: 'none' }}>{props.children}</div>;

interface FilterFormProps {
    resource: string;
    initialValues: {};
    setFilters: (filters: {}) => void;
    submitFilters: (state?: { filters: {} }) => void;
    clearFilters: () => void;
    permanentFilter: {};
    referencedFromEntity?: boolean;
    viewConfig: ViewConfig;
    viewName?: string;
    formId: string;
    filters: React.ReactElement<{
        row?: number;
        source: string;
        replacePeriodsInFieldName?: boolean;
        searchType?: string; // e.g. CONTAINS, EQUAL etc.
        style?: {};
    }>[];
    filtersAttributes?: {
        source: string;
        searchType?: string;
    }[];
    clearValuesInForm: (fields?: string[]) => void;
    showButtons: boolean;
}

export const appendArrayIfNonEmpty = (arrayToAppendTo, arrayToAppend) =>
    arrayToAppendTo.length > 0 ? [...arrayToAppendTo, ...arrayToAppend] : [];

const getName = (keepIdOrIdsPostfix: boolean) => (filterElement: FilterFormProps['filters'][0], i) => {
    if (filterElement.props.source) {
        const source = (() => {
            const _source = filterElement.props.replacePeriodsInFieldName
                ? adjustFieldName(filterElement.props.source, filterElement.props.replacePeriodsInFieldName)
                : filterElement.props.source;
            if (keepIdOrIdsPostfix) {
                return _source;
            }
            return _source.endsWith('Id')
                ? _source.slice(0, -2)
                : _source.endsWith('Ids')
                ? _source.slice(0, -3)
                : _source;
        })();
        return `${source}${filterElement.props.searchType ? `__${filterElement.props.searchType}` : ''}`;
    }
    return i;
};

const Wrapped = (props) => <div style={standardPadding}>{props.children}</div>;

export const FilterFormComponent: React.SFC<
    FilterFormProps & ReturnType<ReturnType<typeof makeMapStateToProps>> & { handleSubmit: Function }
> = ({
    resource,
    submitFilters,
    clearValuesInForm,
    filters,
    hiddenFields,
    disabledFields,
    showButtons,
    initialValues,
    concepts,
    valueSets,
    searchValidations,
    viewConfig,
    filtersAttributes,
    setFilters,
    form,
    viewName,
}) => {
    const searchValidation = useSearchValidator({ viewName, overrideSearchValidation: searchValidations });
    const id = React.useMemo(() => uuidv4(), []);
    return (
        <Form
            searchValidation={searchValidation}
            setFilters={setFilters}
            form={form}
            viewConfig={viewConfig}
            searchValidations={searchValidations}
            concepts={concepts}
            valueSets={valueSets}
            filtersAttributes={filtersAttributes}
            initialValues={initialValues}
        >
            {({ handleSubmit }) => (
                <form
                    id={id}
                    onSubmitCapture={(e) => {
                        if (e.target !== e.currentTarget) {
                            /**
                             * Unfortunately we may have nested <form> elements in the React tree.
                             *
                             * Consider the scenario where we have a popup search field (rendering a search <form>) inside another search <form>
                             *
                             * If we do not have the check above, it's not guaranteed that the <form> we want is calling onSubmitCapture.
                             * It seems like events will bubble through all of the <form>s, but not necessarily in bottom-up order.
                             *
                             * Because we only care about handling the event if we were the one to trigger it:
                             * we can ignore if we are not the e.target (e.currentTarget is always us - element with the event listener.)
                             *
                             * The one that actually handled/triggered the event will call e.preventDefault and stop our default form navigation for us.
                             */
                            return false;
                        }
                        e.preventDefault();
                        handleSubmit(() => submitFilters())(e);
                        e.stopPropagation();
                        return false;
                    }}
                >
                    <div>
                        <RGridInner
                            fields={(filters || []).map((filterElement, i) => {
                                const name = getName(false)(filterElement, i);
                                const source = getName(true)(filterElement, i);
                                return (
                                    <Wrapped key={i} {...filterElement.props}>
                                        {' '}
                                        {hiddenFields[name.split('_~_').join('.')] ? (
                                            <Hidden {...filterElement.props}>
                                                {filterElement.props.source ? (
                                                    <Field
                                                        allowEmpty={true}
                                                        {...filterElement.props}
                                                        name={source}
                                                        key={source}
                                                        component={filterElement.type}
                                                        resource={resource}
                                                        record={emptyRecord}
                                                    />
                                                ) : (
                                                    filterElement
                                                )}
                                            </Hidden>
                                        ) : disabledFields[name] ? (
                                            <filterElement.type
                                                {...filterElement.props}
                                                resource={resource}
                                                disabled={true}
                                                input={{
                                                    value: initialValues[getName(true)(filterElement, i)],
                                                }}
                                                meta={{}}
                                            />
                                        ) : filterElement.props.source ? (
                                            <Field
                                                allowEmpty={true}
                                                {...filterElement.props}
                                                name={source}
                                                component={filterElement.type}
                                                resource={resource}
                                                record={emptyRecord}
                                            />
                                        ) : (
                                            filterElement
                                        )}
                                    </Wrapped>
                                );
                            })}
                        />
                        {showButtons && (filters || []).length > 0 && (
                            <div key="searchbtngroup" style={{ ...standardPadding, marginTop: '0.5em' }}>
                                <Button variant="contained" color="primary" type="submit" form={id}>
                                    <span style={{ width: 45 }}>Search</span>
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const toClear = filters
                                            .filter((f, i) => {
                                                const name = getName(false)(f, i);
                                                return (
                                                    !disabledFields[name] && !hiddenFields[name.split('_~_').join('.')]
                                                );
                                            })
                                            .map(getName(true));
                                        clearValuesInForm(toClear);
                                    }}
                                >
                                    <span style={{ width: 45 }}>Clear</span>
                                </Button>
                            </div>
                        )}
                        <div style={{ clear: 'right' }} />
                    </div>
                </form>
            )}
        </Form>
    );
};

FilterFormComponent.defaultProps = {
    initialValues: {},
};

const enhance = compose(
    (BaseComponent) => (props) => {
        const overrideSearchValidations = useContext(overrideSearchValidationsContext);
        const key = overrideSearchValidations[props.viewName]
            ? overrideSearchValidations[props.viewName].reduce((prev, curr) => {
                  return prev + ';' + curr.expression;
              }, '')
            : 'std.';
        return <BaseComponent {...props} key={key} overrideSearchValidations={overrideSearchValidations} />;
    },
    connect(makeMapStateToProps, null),
);

const FilterForm: React.SFC<FilterFormProps> = enhance(FilterFormComponent);
export default FilterForm;
