import React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

interface InjectComponentDialogComponentProps {
    renderLabel: (arg: { onClick: (e?: React.MouseEvent<HTMLElement>) => void }) => React.ReactElement<any>;
    ariaLabelledBy: string;
    ariaDescribedBy: string;
    content: React.ReactNode;
}
interface InjectComponentDialogComponentState {
    open: boolean;
}
export default class InjectComponentDialogComponent extends React.Component<
    InjectComponentDialogComponentProps,
    InjectComponentDialogComponentState
> {
    constructor(props: InjectComponentDialogComponentProps) {
        super(props);
        this.state = {
            open: false,
        };
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClickOpenEvent = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.handleClickOpen();
    };
    handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ open: false });
    };
    render() {
        const { ariaLabelledBy, ariaDescribedBy, content, renderLabel } = this.props;
        const { open } = this.state;
        return (
            <div>
                {renderLabel({
                    onClick: this.handleClickOpenEvent,
                })}
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    open={open}
                    maxWidth={false}
                    fullWidth={true}
                    onClose={this.handleClose}
                    aria-labelledby={ariaLabelledBy}
                    aria-describedby={ariaDescribedBy}
                >
                    <div>
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                            }}
                        >
                            <IconButton
                                style={{
                                    backgroundColor: 'white',
                                }}
                                aria-label="Close dialog"
                                onClick={this.handleClose}
                            >
                                <Clear />
                            </IconButton>
                        </div>
                        {content}
                    </div>
                </Dialog>
            </div>
        );
    }
}
