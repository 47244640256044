import { getViewConfigFromStorage } from 'sideEffect/getViewConfigFromStorage';
import { storageController } from 'storage';

const buildFetchOptions = () => {
    const token = storageController.getToken();
    const options = {};
    options.credentials = 'same-origin';
    if (token) {
        options.user = {
            authenticated: true,
            token: `Bearer ${token}`,
            Cookie: `${window.document.cookie}`,
        };
        const vc = getViewConfigFromStorage();
        if (vc && vc.user.login) {
            options.headers = new Headers({
                'X-user-login': vc.user.login,
            });
        }
    }
    return options;
};

export default buildFetchOptions;
