import React from 'react';
import compose from 'recompose/compose';
import { OutputParametricSelector } from 'reselect';
import { connect } from 'react-redux';
import { RootState } from '../../../../../reducers/rootReducer';

interface ResultFunc<R> {
    (...args: any[]): R;
}

export const reuseFormContext = <P, R>(
    createFormContextSelector: () => OutputParametricSelector<RootState, P, R, ResultFunc<R>>,
    existingContext: React.Context<R>,
) => {
    const makeMapStateToProps = () => {
        const formContextCreator = createFormContextSelector();
        return (state: RootState, props: P) => {
            const fc = formContextCreator(state, props);
            return {
                formContext: fc,
            };
        };
    };

    const formContext = existingContext;

    class FormContextProviderComponent extends React.Component<P & ReturnType<ReturnType<typeof makeMapStateToProps>>> {
        render() {
            return <formContext.Provider value={this.props.formContext}>{this.props.children}</formContext.Provider>;
        }
    }

    const FormContextProvider: React.SFC<P> = compose(connect(makeMapStateToProps))(FormContextProviderComponent);

    const formContextHoc =
        (overrideProps?: Partial<P>) =>
        <X extends P>(BaseComponent) =>
        (props: X) =>
            (
                <FormContextProvider {...props} {...overrideProps}>
                    <BaseComponent {...props} />
                </FormContextProvider>
            );
    return {
        formContext,
        FormContextProvider,
        formContextHoc,
    };
};
const createFormContext = <P, R>(
    createFormContextSelector: () => OutputParametricSelector<RootState, P, R, ResultFunc<R>>,
    defaultContext: R,
) => {
    const formContext = React.createContext(defaultContext);
    return reuseFormContext(createFormContextSelector, formContext);
};
export default createFormContext;
