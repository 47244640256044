import React, { useMemo, FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { View } from 'reducers/ViewConfigType';
import ListView from 'templatePage/templatableComponents/ListView';
import NavigateNext from '@material-ui/icons/NavigateNext';

interface Step4ListProps {
    initialValues: Partial<View>;
    onSubmit: () => void; // we aren't editing data here.
}
const Step4List: FunctionComponent<Step4ListProps> = (props) => {
    const { initialValues, onSubmit: _onSubmit } = props;
    const viewConfig = useViewConfig();
    const overrideViewConfig = useMemo(() => {
        return produce(viewConfig, (draft) => {
            draft.views[initialValues.name] = {
                ...initialValues,
            } as View;
            return draft;
        });
    }, [viewConfig, initialValues]);
    if (!initialValues.viewType || !initialValues.entity || !initialValues.name) {
        return <div>Please fill out step 1.</div>;
    }
    return (
        <div style={{ padding: '1em', margin: '1em' }}>
            <h2>Step 4: Review</h2>
            <div>
                <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                    <ListView viewName={initialValues.name} showActions />
                </OverriddenViewConfigContext.Provider>
                <div style={{ width: '100%', textAlign: 'right' }}>
                    <Button id="step4-submit" variant="contained" color="primary" onClick={_onSubmit}>
                        Submit&nbsp;
                        <NavigateNext />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Step4List;
