import { fetchCancel, fetchEnd, fetchError, fetchStart } from 'actions/aor/fetchActions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';

const loading = (previousState: number = 0, action: RootAction): number => {
    switch (action.type) {
        case getType(fetchStart):
            return previousState + 1;
        case getType(fetchEnd):
        case getType(fetchError):
        case getType(fetchCancel):
            return Math.max(previousState - 1, 0);
        default:
            return previousState;
    }
};

export default loading;
