import React, { useMemo } from 'react';
import Form from '@rjsf/material-ui/dist';
import { UiSchema } from '@rjsf/utils';
import get from 'lodash/get';
import validator from '@rjsf/validator-ajv8';
import { useJsonSchemaFormSchemas } from './JsonSchemaForm';

interface DisplayJsonSchemaFormProps {
    record?: {};
    source: string;
    schema?: any;
    uiSchema?: UiSchema;
    readSchemasFromField?: string;
}
const DisplayJsonSchemaForm: React.FunctionComponent<DisplayJsonSchemaFormProps> = (props) => {
    const { schema, uiSchema: _uiSchema } = useJsonSchemaFormSchemas(props);
    const uiSchema = useMemo(
        () => ({
            ..._uiSchema,
            'ui:submitButtonOptions': {
                norender: true,
            },
        }),
        [_uiSchema],
    );

    if (!schema || !uiSchema) {
        return null;
    }
    const value = get(props.record, props.source);
    return (
        <Form
            disabled
            schema={schema}
            uiSchema={uiSchema}
            formData={JSON.parse(value || null) ?? ''}
            validator={validator}
        />
    );
};

export default DisplayJsonSchemaForm;
