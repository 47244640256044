import React, { useState } from 'react';

const WithTextState: React.SFC<{
    initial: string;
    children: (args: { text: string; setText: (text: string) => void }) => JSX.Element | null;
}> = (props) => {
    const [text, setText] = useState(props.initial);
    return props.children({ text, setText });
};

export default WithTextState;
