import ViewConfig from '../../reducers/ViewConfigType';
import { fromEither, Option, fromPredicate } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { formValidationConfigType, getValidationFromConfig } from '../../expressions/formValidation/evaluateFromJson';
import { parsingOrValidationErrMsg } from '../../expressions/formValidation';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import { ValidationLevel } from 'reducers/entityValidationsReducer';
import getImpl from 'expressions/Provider/implementations/getImpl';
import { getRefEntityName, isValidEntityFieldExpression } from 'components/generics/utils/viewConfigUtils';

export const extractValidationMetadata = (
    validations: formValidationConfigType,
    viewConfig: ViewConfig,
    rootEntity: string,
    rebaseOntoRootPath?: string,
) =>
    mapOption(validations, ({ message, expression, level: _level = 'E' }) =>
        fromEither(
            tryCatch(
                () => {
                    let compileResult = getImpl().compileExpression(expression);
                    if (compileResult.type === 'parse_success') {
                        const level: ValidationLevel = _level?.toLowerCase()?.startsWith('w') ? 'warn' : 'error';

                        let expr = expression;
                        if (rebaseOntoRootPath) {
                            compileResult = compileResult.rebase
                                ? compileResult.rebase((path) => {
                                      // Verify path
                                      const rebasedEntity = getRefEntityName(
                                          viewConfig,
                                          rootEntity,
                                          rebaseOntoRootPath,
                                          'TRAVERSE_PATH',
                                      );
                                      if (isValidEntityFieldExpression(viewConfig, rebasedEntity, path)) {
                                          return [rebaseOntoRootPath];
                                      }
                                      return [];
                                  })
                                : compileResult;
                            expr = compileResult.prettyPrint();
                        }
                        return {
                            level,
                            expression: expr,
                            message,
                            dataPaths: compileResult.getPathsWithAll(),
                            expansionsRequired: compileResult.getExpansionsWithAll(),
                            valuesetLiterals: compileResult.getValueSetLiterals(),
                        };
                    }
                    console.error(compileResult.msg);
                    console.error('Error compiling entityConfig validation expression. Error above');
                },
                (e: Error) => {
                    // prints errors for the failed SPEL compilation
                    console.error('Error parsing SPEL entityConfig validation expression', e);
                    return e;
                },
            ).chain(setupValuesetFieldsRequired(viewConfig, rootEntity, expression)),
        ),
    );

const getValidation = (key: string, resource: string, viewConfig: ViewConfig, viewConfiguration: Option<string>) =>
    viewConfiguration
        .chain(fromPredicate<string>(Boolean))
        .map(getValidationFromConfig)
        // prints errors for the failed configs
        .map((e) =>
            e.mapLeft((error) => {
                console.error(`There is an invalid configuration on EntityConfig "${resource}". Error below:`);
                console.error(parsingOrValidationErrMsg(error));
                return error;
            }),
        )
        .chain(fromEither)
        .map((validations) => extractValidationMetadata(validations, viewConfig, resource))
        .map((v) => ({ [key]: v }));

export default getValidation;
