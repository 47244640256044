/**
 * 'GateOpen' here is used in the way of 'floodgate'
 * when true, offline data can be overwritten.
 * when false, it is not.
 *
 * We use this to prevent writing (and overwriting) our data
 *  until all our data is loaded, and tricky intermediate states passed over safely.
 */

import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import {
    setCurrentlyWritingToOffline,
    unsetCurrentlyWritingToOffline,
} from '../currentlyWritingToOfflineState/actions';

const saveOfflineGateOpenReducer = (state = false, action: RootAction) => {
    switch (action.type) {
        case getType(setCurrentlyWritingToOffline): {
            if (action.meta?.openGate) {
                return true;
            }
            return state;
        }
        case getType(unsetCurrentlyWritingToOffline): {
            return false;
        }
    }
    return state;
};

export default saveOfflineGateOpenReducer;
