function fromEntries<B>(iterable: [string | number, B][]): {
    [key in string | number]: B;
} {
    return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
    }, {});
}

export default fromEntries;
