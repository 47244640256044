import { ProcessStatus, Filter } from '../types';

export const getPartialFilterFromState = (state: ProcessStatus): Filter => {
    switch (state) {
        case ProcessStatus.OPEN:
            return { 'processInstance.endTime__NOT_EMPTY': false };
        case ProcessStatus.CLOSED:
            return { 'processInstance.endTime__NOT_EMPTY': true };
        default:
            return { 'processInstance.endTime__NOT_EMPTY': undefined }; // overwrite existing
    }
};

export const getStateFromFilter = (filter: Filter = {}) => {
    if (filter) {
        if (filter['processInstance.endTime__NOT_EMPTY'] === false) {
            return ProcessStatus.OPEN;
        } else if (filter['processInstance.endTime__NOT_EMPTY'] === true) {
            return ProcessStatus.CLOSED;
        }
    }
    return ProcessStatus.ALL;
};

export const getAssigneeFromFilter = (filter: Filter = {}) => {
    return (filter && filter['processInstance.tasks.assignee.id']) || null;
};
export const getPartialFilterFromAssignee = (assigneeId?: string | null): Filter => {
    if (assigneeId) {
        return {
            'processInstance.tasks.assignee.id': assigneeId,
            assignee_ANY: undefined,
        };
    }
    return {
        assignee_ANY: true,
        'processInstance.tasks.assignee.id': undefined,
    };
};
