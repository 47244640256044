import { getType } from 'typesafe-actions';
import { RootAction } from 'actions/rootAction';
import { clearWaitingForMfa, login, loginMfaRequired, loginSuccess, loginWithMfaCodeSuccess } from 'auth/actions';

type MfaRequiredState =
    | {
          type: 'null';
      }
    | {
          type: 'mfa-pending';
          temporaryToken: string;
          redirectTo?: string;
          mfaQrUrl?: string;
          mfaQrImage?: string;
          mfaKey?: string;
      };
const mfaReducer = (
    state: MfaRequiredState = {
        type: 'null',
    },
    action: RootAction,
): MfaRequiredState => {
    switch (action.type) {
        case getType(login):
        case getType(clearWaitingForMfa):
        case getType(loginWithMfaCodeSuccess):
        case getType(loginSuccess): {
            if (state.type !== 'null') {
                return {
                    type: 'null',
                };
            }
            return state;
        }
        case getType(loginMfaRequired): {
            return {
                type: 'mfa-pending',
                temporaryToken: action.response.id_token,
                redirectTo: action.redirectTo,
                mfaQrUrl: action.response.mfaQrUrl,
                mfaQrImage: action.response.mfaQrImage,
                mfaKey: action.response.mfaKey,
            };
        }
        default:
            return state;
    }
};
export default mfaReducer;
