import { taskFormEventType, TaskFormSaveAction } from 'bpm/actions/taskFormEvent';

interface LastSaveState {
    taskId?: string;
    date?: number;
    data?: {};
    isAutoSave?: boolean;
    status:
        | {
              type: 'initial';
          }
        | {
              type: 'success';
          }
        | {
              type: 'pending';
          }
        | {
              type: 'failed';
          };
}
const lastSaveReducer = (
    state: LastSaveState = {
        status: {
            type: 'initial',
        },
    },
    action: any,
): LastSaveState => {
    switch (action.type) {
        // maybe also clear off date when initing.
        case taskFormEventType.save: {
            const { isAutoSave, payload } = action as TaskFormSaveAction;
            const [a, b] = payload;
            return {
                ...state,
                taskId: a,
                date: Date.now(),
                data: b.values,
                isAutoSave,
                status: {
                    type: 'pending',
                },
            };
        }
        case taskFormEventType.saveFailure: {
            return {
                ...state,
                status: {
                    type: 'failed',
                },
            };
        }
        case taskFormEventType.saveSuccess: {
            return {
                ...state,
                status: {
                    type: 'success',
                },
            };
        }
        default:
            return state;
    }
};
export default lastSaveReducer;
