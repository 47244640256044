import React from 'react';
import { Dialog } from '@material-ui/core';
import { GenericCreate } from 'components/generics';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import CloseButton from 'fieldFactory/popovers/PopoverCloseButton';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

interface CreateDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onCreateCb: (data: {}) => void;
    resource: string;
    viewName?: string;
    injectCreateValues?: {};
    parentEntityName?: string;
    parentFieldInChild?: string;
    parentId?: string;
}
const CreateDialog: React.SFC<CreateDialogProps> = (props) => {
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const viewName = props.viewName || viewConfig.entities[props.resource]?.defaultViews?.CREATE?.name;
    if (!viewName) {
        return null;
    }
    return (
        <Dialog
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
            open={props.open}
            onClose={() => props.setOpen(false)}
            maxWidth={false}
            fullWidth={true}
        >
            <div>
                <GenericCreate
                    injectValues={props.injectCreateValues}
                    viewName={viewName}
                    formId={`popover-create-form-${props.viewName}-Parent:${props.parentEntityName}:${props.parentId}`}
                    redirect={false}
                    parentEntity={props.parentEntityName}
                    parentField={props.parentFieldInChild}
                    parentId={props.parentId}
                    onCreateCb={(id, data) => {
                        props.setOpen(false);
                        props.onCreateCb(data);
                    }}
                    toolbar={
                        <Toolbar>
                            <SaveButton />
                            <CloseButton handleClose={() => props.setOpen(false)} />
                        </Toolbar>
                    }
                />
            </div>
        </Dialog>
    );
};

export default CreateDialog;
