import localStorageFallbackStorage from 'local-storage-fallback';
import { getStorageMode } from 'util/applicationConfig';
import { getPutBackIsMacApp } from 'util/isTheInstalledApp';
import LZstring from 'lz-string';
import * as fflate from 'fflate';
import memoizeOne from 'memoize-one';

type StorageMode = 'sessionStorage' | 'fallback';

interface Storage {
    clear(): void;
    getItem(key: string): string | null;
    removeItem(key: string): void;
    setItem(key: string, value: string): void;
}
let currentStorage: {
    mode: StorageMode;
    storage: Storage;
} = {
    mode: 'fallback',
    storage: localStorageFallbackStorage,
};

const setStorageMode = (mode: StorageMode) => {
    currentStorage = {
        mode,
        storage: mode === 'sessionStorage' ? window.sessionStorage : localStorageFallbackStorage,
    };
};

/**
 * Always write compressed, but if what's stored is uncompressed, let it be
 * This is because we want to not break anything by deploying this, since people have uncompressed stuff in their localstorage.
 */

class StorageCompression {
    private header: string;
    private compressFn: (value: string) => string;
    private decompressFn: (value: string) => string;
    private memoizedDecompress: {
        [key: string]: (value: string) => string;
    };
    constructor(
        header: string,
        params: {
            compressFn: (value: string) => string;
            decompressFn: (value: string) => string;
            memoizeDecompressKeys?: string[];
        },
    ) {
        this.header = header;
        this.compressFn = params.compressFn;
        this.decompressFn = params.decompressFn;
        this.memoizedDecompress = {};
        params.memoizeDecompressKeys?.forEach((key) => {
            this.memoizedDecompress[key] = memoizeOne(this._decompress);
        });
    }
    public compress = (value: string) => {
        return this.header + this.compressFn(value);
    };
    private _decompress = (value: string) => {
        if (value?.startsWith(this.header)) {
            const compressed = value.slice(this.header.length);
            return this.decompressFn(compressed);
        }
    };
    public decompress = (value: string, key?: string) => {
        return (this.memoizedDecompress?.[key] ?? this._decompress)?.(value);
    };
    public matches = (value: string) => {
        return value?.startsWith(this.header);
    };
}
const strategies = {
    lzstring: new StorageCompression('|||', {
        compressFn: LZstring.compress,
        decompressFn: LZstring.decompress,
        memoizeDecompressKeys: ['viewconfig'],
    }),
    fflate: new StorageCompression('>>>', {
        compressFn: (value: string) => {
            const buf = fflate.strToU8(value);
            const compressed = fflate.compressSync(buf, { level: 6, mem: 8 });
            return fflate.strFromU8(compressed, true);
        },
        decompressFn: (value: string) => {
            const uint8array = fflate.strToU8(value, true);
            const decompressed = fflate.decompressSync(uint8array);
            return fflate.strFromU8(decompressed);
        },
        memoizeDecompressKeys: ['viewconfig'],
    }),
};

const maybeDecompress = (value: string, key?: string) => {
    for (const strategy of Object.values(strategies)) {
        if (strategy.matches(value)) {
            return strategy.decompress(value, key);
        }
    }
    return value;
};

/** TODO use this for viewConfig */
const memoizedDecompressViewConfig = memoizeOne((compressed: string) => {
    return LZstring.decompress(compressed);
});

export const getStorage = (): Storage => {
    /**
     * Prevent illegal invocation by using arrow functions
     * https://stackoverflow.com/questions/41126149/using-a-shortcut-function-gives-me-a-illegal-invocation-error
     */
    return {
        getItem: (k) => {
            const maybeCompressed = currentStorage.storage.getItem(k);
            return maybeDecompress(maybeCompressed, k);
        },
        removeItem: (k) => currentStorage.storage.removeItem(k),
        setItem: (k, v) => {
            const value = v.length <= 1000000 ? v : strategies.fflate.compress(v);
            currentStorage.storage.setItem(k, value);
        },
        clear: () => {
            const putBack = getPutBackIsMacApp();
            currentStorage.storage.clear();
            putBack();
        },
    };
};

export const setStorageModeFromBasicInfo = () => {
    const basicInfo = JSON.parse((window as any).CASETIVITY_BASIC_INFO);
    const mode = getStorageMode(basicInfo);
    setStorageMode(mode);
};

export const clearSessionStorage = () => {
    const putBack = getPutBackIsMacApp();
    sessionStorage.clear();
    putBack();
};
