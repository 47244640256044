import { API_URL } from 'config';
import buildHeaders from 'sideEffect/buildHeaders';

// has to be lazy so we get the token from storage later
const createRefreshTokenRequest = () =>
    new Request(`${API_URL}authenticate/refresh`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
    });
export default createRefreshTokenRequest;
