import React, { forwardRef, useState } from 'react';
import { ButtonBase, ButtonBaseProps, createStyles, makeStyles } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme) =>
    createStyles({
        dropdown: {
            display: 'inline-grid',
        },
        sortButton: {
            '&:focus': {
                color: theme.palette.text.secondary,
            },
        },
    }),
);

export const SortArrows = (props: { active?: boolean; direction?: 'asc' | 'desc'; innerProps?: any }) => {
    const classes = useStyles();
    const doubleArrows = (
        <>
            <ArrowDropUp viewBox="1 -9 24 24" color="disabled" />
            <ArrowDropDown viewBox="1 9 24 24" color="disabled" />
        </>
    );
    return (
        <div {...props.innerProps} className={classes.dropdown}>
            {!props.active ? doubleArrows : props.direction === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />}
        </div>
    );
};

export const CenteredIcon: React.FC<{
    children: JSX.Element;
    outerStyle?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
}> = ({ children, outerStyle, innerStyle }) => {
    return (
        <span style={{ position: 'relative', paddingRight: '2rem', ...outerStyle }}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '1rem',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    ...innerStyle,
                }}
            >
                {children}
            </div>
        </span>
    );
};

export const SortLabel: React.FC<{
    label: React.ReactNode;
    active?: boolean;
    direction?: 'asc' | 'desc';
    ButtonProps?: ButtonBaseProps;
    ref?: React.Ref<HTMLButtonElement>;
}> = forwardRef(({ label, active, direction, ButtonProps }, ref) => {
    const classes = useStyles();
    const [hovered, setHovered] = useState(false);

    return (
        <ButtonBase
            ref={ref}
            className={classes.sortButton}
            style={hovered ? { opacity: '.7' } : undefined}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            {...ButtonProps}
        >
            <b>{label}</b>
            <CenteredIcon>
                <SortArrows active={active || hovered} direction={hovered && !active ? 'asc' : direction} />
            </CenteredIcon>
        </ButtonBase>
    );
});
