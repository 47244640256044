import { EncryptionInput } from 'encryption/encryptionUtils';
import sessionSecretsController from 'offline_app/sessionSecretsController';

type OnPinCb = (pin: string) => void;
type OnPinCbRegistrationSubscription = (cb: OnPinCb, message?: string) => void;
class PromptController {
    private onPinCb: OnPinCb;
    private subscription: OnPinCbRegistrationSubscription;

    private registerPromptCb(onPin: OnPinCb, message?: string) {
        this.onPinCb = onPin;
        this.subscription?.(this.onPinCb, message);
    }
    unregisterPromptCb() {
        this.onPinCb = null;
    }
    public subscribe(onCbRegistration: OnPinCbRegistrationSubscription) {
        this.subscription = onCbRegistration;
    }
    public unSubscribe() {
        this.subscription = null;
    }

    public async getPin(message?: string): Promise<EncryptionInput> {
        const currentlyStoredSecrets = sessionSecretsController.get();
        if (currentlyStoredSecrets) {
            return Promise.resolve({ type: 'secrets', secrets: currentlyStoredSecrets });
        }
        return new Promise((resolve, reject) => {
            this.registerPromptCb((pin) => {
                // lets leave it up to the caller to check if the pin is valid, before
                // setting pin or secrets in storage.
                resolve({ type: 'pin', pin });
            }, message);
        });
    }
}

let promptController: PromptController;
export const getPromptController = () => {
    if (!promptController) {
        promptController = new PromptController();
    }
    return promptController;
};
