export const onTaskPage = (pathname: string) => /^\/processes\/[a-z0-9-]+\/tasks\/[a-z0-9-]+\/start$/.test(pathname);

export const getTaskId = (pathname: string) => {
    if (!onTaskPage(pathname)) {
        return null;
    }
    return pathname.split('/tasks/')?.[1]?.split('/start')?.[0];
};

export const getProcessId = (pathname: string) => {
    if (!onTaskPage(pathname)) {
        return null;
    }
    return pathname.split('/processes/')[1].split('/tasks/')[0];
};
