import { connect } from 'react-redux';
import { GetHandleTitleClickProps } from 'dashboard2/widgets/GetHandleTitleClickProps';
import { push as pushAction } from 'connected-react-router';

interface GetPushActionProps {
    url: string;
}

const dispatches = {
    push: pushAction,
};
type Dispatches = typeof dispatches;

const GetPushAction: React.ComponentType<GetHandleTitleClickProps & GetPushActionProps> = connect(
    null,
    dispatches,
)((props: GetHandleTitleClickProps & GetPushActionProps & Dispatches) => {
    return props.render({
        handleTitleClick: () => props.push(props.url),
    });
});

export default GetPushAction;
