import React, { FunctionComponent, useMemo, useEffect } from 'react';
import EditableViewFormLayout from 'layout-editor/components/EditableViewFormLayout';
import { useFormContext } from 'react-hook-form';
import { reduxForm } from 'redux-form';
import { View } from 'reducers/ViewConfigType';

const Form = reduxForm({})((props) => <React.Fragment>{props.children}</React.Fragment>);

interface ListViewSearchControllerProps {
    initialValues: Partial<View>;
}
const ListViewSearchController: FunctionComponent<ListViewSearchControllerProps> = (props) => {
    const { initialValues } = props;
    const { watch, setValue, register, unregister } = useFormContext<Partial<View>>();
    useEffect(() => {
        register({ name: 'searchFields' });
        return () => {
            unregister('searchFields');
        };
    }, []); // eslint-disable-line
    const view: View = watch() as any;
    const viewMappedToLayout = useMemo(() => {
        const { searchFields = {} } = view;
        console.log('view', view);
        return {
            entity: initialValues.entity,
            name: initialValues.name,
            route: initialValues.route,
            viewType: 'LIST' as const,
            fields: searchFields,
        };
    }, [view, initialValues]);
    const inner = (
        <Form form="listview-editor-search">
            <EditableViewFormLayout
                addFieldLabel="Add Search field"
                hasTabs={false}
                mode="EDIT"
                includeSearchType={true}
                entityType={initialValues.entity}
                view={viewMappedToLayout}
                onViewChange={({ view }) => {
                    setValue('searchFields', view.fields as View['searchFields'], {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                }}
            />
        </Form>
    );
    return inner;
};

export default ListViewSearchController;
