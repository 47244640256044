import React, { FunctionComponent, useCallback, useContext } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles, Theme, createStyles, Button, TextField } from '@material-ui/core';
import useViewConfig from 'util/hooks/useViewConfig';
import { getFieldSourceFromPath, isValidEntityFieldExpression } from 'components/generics/utils/viewConfigUtils';
import ViewConfig from 'reducers/ViewConfigType';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import uniq from 'lodash/uniq';
import { UpdateMeta } from 'expression-tester/entity-form/ViewDefinitionConfig/EditExpression';
import ControlledTextField from 'react-hook-form-utils/ControlledTextField';
import { contextFunctions } from 'expressions/Provider/getContexts';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutocompleteSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';
import { methodCallsAllowed } from './methodCallsAllowed';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
    }),
);

export interface ExpressionData {
    level?: 'E' | 'W';
    expression: string;
    fieldsRequired: string[];
    compileSuccess: boolean;
    methodsAndFunctions?: string[];
    message: string;
}

interface EditExpressionProps {
    resource: string;
    initialValues?: Partial<ExpressionData>;
    onSubmit: (data: ExpressionData) => void;
}

export const useEntityValidationValidationResolver = (
    baseEntity: string,
    viewConfig: ViewConfig,
    optional: boolean = false,
) => {
    return useCallback(
        async (data) => {
            let errors = {};
            if (!data.message) {
                errors['message'] = 'A message is required';
            }
            if (data.compileSuccess) {
                const invalidFunctions = uniq(
                    (data.methodsAndFunctions || []).flatMap((_fnnm, i) => {
                        if (contextFunctions[_fnnm] || methodCallsAllowed.includes(_fnnm)) {
                            return [];
                        }
                        return [_fnnm];
                    }),
                );
                if (invalidFunctions.length > 0) {
                    errors['methodsAndFunctions'] = `Functions not found: ${invalidFunctions.join(', ')}`;
                }
                const invalidPaths = uniq(
                    (data.fieldsRequired || []).flatMap((_fieldPath, i) => {
                        const fieldPath = getFieldSourceFromPath(viewConfig, baseEntity, _fieldPath);
                        if (!isValidEntityFieldExpression(viewConfig, baseEntity, fieldPath)) {
                            return [_fieldPath];
                        }
                        return [];
                    }),
                );
                if (invalidPaths.length > 0) {
                    errors['fieldsRequired'] = `Paths not found: ${invalidPaths.join(', ')}`;
                }
            }
            if (!data.compileSuccess) {
                if (!data.expression && optional) {
                    return {
                        errors: errors,
                        values: data,
                    };
                }
                errors['fieldsRequired'] = 'Failed to compile expression.';
            }
            return {
                errors: errors,
                values: data,
            };
        },
        [baseEntity, viewConfig, optional],
    );
};
const EditValidationExpression: FunctionComponent<EditExpressionProps> = (props) => {
    const classes = useStyles(props);
    const { resource, onSubmit } = props;
    const viewConfig = useViewConfig();

    const resolver = useEntityValidationValidationResolver(resource, viewConfig);
    const methods = useForm<ExpressionData>({
        resolver,
        defaultValues: props.initialValues,
        mode: 'onChange',
    });
    const { errors } = methods;
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <label>
                    <b>Expression *</b>
                    <Controller
                        rules={{ required: 'An expression is required' }}
                        as={AutocompleteSpelEditor as any}
                        debounceTime={0}
                        rootEntity={resource}
                        defaultValue={props.initialValues && props.initialValues['expression']}
                        name="expression"
                        control={methods.control as any}
                        hideDocs
                    />
                </label>
                <ErrorMessage errors={errors} name="expression" />
                <pre className={classes.error}>{errors['fieldsRequired']}</pre>
                <pre className={classes.error}>{errors['methodsAndFunctions']}</pre>
                <ControlledTextField
                    label="Message *"
                    name="message"
                    defaultValue={props.initialValues && props.initialValues['message']}
                />
                <Controller
                    name="level"
                    control={methods.control}
                    defaultValue={props.initialValues?.level ?? ''}
                    render={({ onChange, value }) => (
                        <Autocomplete
                            options={['E', 'W']}
                            value={value}
                            defaultValue={props.initialValues?.level ?? ''}
                            autoHighlight
                            onChange={(e, value) => onChange(value)}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={fieldVariant as any}
                                    InputLabelProps={getInputLabelProps({ shrink: true })}
                                    label="Level"
                                    margin="normal"
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        draggable: false,
                                        autoComplete: 'disabled',
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                    Save
                </Button>
            </form>
            <UpdateMeta />
        </FormProvider>
    );
};
export default EditValidationExpression;
