import React, { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import WithStringEvaluatedInFormContext from 'fieldFactory/popovers/PopoverRefInput/EvaluateStringTemplateInFormContext';
import getExpansionsFromFilter from 'isomorphic-query-filters/expand';
import uniq from 'lodash/uniq';
import { getTemplateDataPaths } from 'util/getTemplateDataPaths';

export const withEvaluatedFilter =
    (
        BaseComponent: any,
        filterString: string,
        from: 'Flowable' | 'Entity' | 'AdHocEntity',
        originalDefinition?: string,
    ) =>
    (props) => {
        const expansions = useMemo(() => {
            if (filterString) {
                return uniq([...getExpansionsFromFilter(filterString), ...(props.expansions || [])]);
            }
            return props.expansions || [];
        }, [props.expansions]);
        const getExpansionsFromTemplatedFilter = useMemo(
            () =>
                memoizeOne((expansions: string[], evaldFilter: string) => {
                    return uniq([...expansions, ...getExpansionsFromFilter(evaldFilter)]);
                }),
            [],
        );
        const childFn = (evaldFilter: string) => {
            const templatedExpansions = getExpansionsFromTemplatedFilter(expansions, evaldFilter);
            return <BaseComponent {...props} expansions={templatedExpansions} filterString={evaldFilter} />;
        };
        if (from === 'AdHocEntity') {
            // this item was added for x-many field filters
            // because I wasn't sure we wanted to add them to the 'viewItemFilterExps' store/localstorage data.
            // it basically means "you can't look up expansions in the store/localstorage filterExpressions
            // so ensure they are provided in the 'context' prop.""
            const { expansionsRequired, dataPaths } = getTemplateDataPaths(filterString);
            return (
                <WithStringEvaluatedInFormContext
                    from="Entity"
                    context={{ type: 'adhoc', expansionsRequired, dataPaths, valuesetFieldsRequired: {} }}
                    templateString={filterString}
                >
                    {childFn}
                </WithStringEvaluatedInFormContext>
            );
        }
        if (from === 'Entity') {
            return (
                <WithStringEvaluatedInFormContext
                    data-originaldefinition={originalDefinition}
                    context={{ type: 'source', source: props.source }}
                    from="Entity"
                    templateString={filterString}
                >
                    {childFn}
                </WithStringEvaluatedInFormContext>
            );
        }
        return (
            <WithStringEvaluatedInFormContext from="Flowable" templateString={filterString}>
                {childFn}
            </WithStringEvaluatedInFormContext>
        );
    };
