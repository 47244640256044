import React from 'react';
import { Link } from 'react-router-dom';
import { Button, withWidth, createStyles, Theme, makeStyles } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import { WithWidth } from '@material-ui/core/withWidth';
import Fab from '@material-ui/core/Fab';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        floating: {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 60,
            left: 'auto',
            position: 'fixed',
        },
        flat: {
            overflow: 'inherit',
        },
    }),
);

interface CreateButtonProps {
    resource: string;
    label?: string;
}
interface CreateButtonComponentProps extends CreateButtonProps, WithWidth {}
const CreateButtonComponent: React.SFC<CreateButtonComponentProps> = (props) => {
    const { resource, label = 'Create', width } = props;
    const classes = useStyles(props);
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const createRoute = fromNullable(viewConfig.entities[resource])
        .mapNullable((e) => e.defaultViews.CREATE)
        .mapNullable((c) => c.name)
        .mapNullable((n) => viewConfig.views[n])
        .mapNullable((v) => v.route)
        .map((r) => (r.startsWith('/') ? r : `/${r}`))
        .getOrElse(`/${resource}/create`);
    return width === 'xs' ? (
        <Fab color="primary" to={createRoute} className={classes.floating} component={Link}>
            <ContentAdd />
        </Fab>
    ) : (
        <Button variant="text" color="primary" to={createRoute} component={Link} className={classes.flat}>
            {label} <ContentAdd />
        </Button>
    );
};

const enhance = withWidth({
    initialWidth: 'md',
}) as any;

const CreateButton: React.ComponentType<CreateButtonProps> = enhance(CreateButtonComponent);
export default CreateButton;
