import React, { useCallback, useMemo } from 'react';
import RegistrationConfirmationForm, { RegistrationConfirmationElements } from './RegistrationConfirmationForm';
import createProcessingInstructions from 'templatePage/createProcessingInstructions';
import { useSelector } from 'react-redux';
import { getCustomRegistrationPageHtmlSelector } from 'util/applicationConfig';
import HtmlToReact from 'html-to-react';
import DefaultRegistrationForm from './DefaultRegistrationForm';
import useRootTheme from 'components/layouts/hooks/useRootTheme';
import useTemplateMessagesAndPurifyHtml from 'templatePage/hooks/templateMessagesAndPurifyHtml';

const htmlToReactParser = new HtmlToReact.Parser();

const isValidNode = function () {
    return true;
};

const Template: React.FC<RegistrationConfirmationElements> = (props) => {
    const {
        SubmitElement,
        ErrorMessageElement,
        ConfirmPasswordElement,
        PasswordElement,
        LoginInputElement,
        CodeElement,
        StatusIndicatorElement,
        EmailLoginInputElement,
    } = props;
    const processingInstructions = useMemo(() => {
        return createProcessingInstructions([
            { tag: 'data-registration-errormessage', Element: ErrorMessageElement },
            { tag: 'data-registration-username', Element: LoginInputElement },
            { tag: 'data-registration-emaillogin', Element: EmailLoginInputElement },
            { tag: 'data-registration-password', Element: PasswordElement },
            { tag: 'data-registration-confirmpassword', Element: ConfirmPasswordElement },
            { tag: 'data-registration-submit', Element: SubmitElement },
            { tag: 'data-registration-code', Element: CodeElement },
            { tag: 'data-registration-statusindicator', Element: StatusIndicatorElement },
        ]);
    }, [
        EmailLoginInputElement,
        SubmitElement,
        ErrorMessageElement,
        ConfirmPasswordElement,
        PasswordElement,
        LoginInputElement,
        CodeElement,
        StatusIndicatorElement,
    ]);

    const customRegistrationPageHtml = useSelector(getCustomRegistrationPageHtmlSelector);
    const templatedHtml = useTemplateMessagesAndPurifyHtml(customRegistrationPageHtml);
    const TemplatedReactElement = useMemo(() => {
        if (!templatedHtml) {
            return null;
        }
        return htmlToReactParser.parseWithInstructions(templatedHtml, isValidNode, processingInstructions);
    }, [processingInstructions, templatedHtml]);

    if (!TemplatedReactElement) {
        return <DefaultRegistrationForm {...props} />;
    }
    return TemplatedReactElement;
};

const RegistrationConfirmationPage: React.FC<{ login?: string }> = (props) => {
    const theme = useRootTheme();
    const renderForm = useCallback((props: RegistrationConfirmationElements) => {
        return <Template {...props} />;
    }, []);
    return <RegistrationConfirmationForm login={props.login} renderForm={renderForm} />;
};

export default RegistrationConfirmationPage;
