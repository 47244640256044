import React, { Suspense } from 'react';
import { ViewDefData } from './domain';
import type { Filter } from './VirtualizedViewDefTableComponent';
const VirtualizedViewDefTableComponent = React.lazy(() => import('./VirtualizedViewDefTableComponent'));

const VirtualizedViewDefTable: React.FC<{
    setViewName: (viewName: string, data: ViewDefData) => void;
    fixedFilter?: Filter;
}> = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <VirtualizedViewDefTableComponent {...props} />
        </Suspense>
    );
};
export default VirtualizedViewDefTable;
