import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Card } from '@material-ui/core';
import ViewTitle from '../ViewTitle';
import { push as pushAction } from 'connected-react-router';
import { CreateParams, crudCreate as crudCreateAction } from 'sideEffect/crud/create/actions';
import { EntityBase } from 'sideEffect/services';
import SimpleForm, { Save } from './SimpleForm2';
import BackTo from '../button/BackTo';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import formTypeContext from '../form/formTypeContext';
import useViewConfig from 'util/hooks/useViewConfig';
import useEntitiesAreLoading from 'util/hooks/useEntitiesAreLoading';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { loadValueSets as loadValueSetsAction } from 'valueSets/actions';
import { getValueSetCodesRequiredForEntity, getAccessLevelForEntity, allowsEdit } from '../utils/viewConfigUtils';
import { getEditableViewVSCodeLiteralsSelector } from '../form/valuesetCodeExpressionLiteralsSelector';
import { RootState } from 'reducers/rootReducer';
import withRestrictions from '../utils/withRestrictions';
import { HideBackToController } from '../form/hooks/configurations/hideBackTo';
import { setAsTopView, unsetAsTopView } from 'popoverStackManagement/actions';
import { useOverrideTitle } from '../form/hooks/configurations/overrideTitle';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import useRegisterViewDetails from 'popoverStackManagement/viewDetailsStack/useRegisterViewDetails';

interface CreateProps {
    formId?: string;
    injectValues?: {};
    viewName: string;
    title?: string | JSX.Element;
    toolbar?: JSX.Element;
    onCreateCb?: <T extends EntityBase>(id: string, data: T) => void;
    redirect?: false;
    parentId?: string;
    parentEntity?: string;
    parentField?: string;
    location?: Partial<Location>;
    interceptSubmit?: (args: CreateParams) => void;
    hideFields?: string[];
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}
const Create: FunctionComponent<CreateProps> = (props) => {
    const {
        onCreateCb: _onCreateCb,
        title,
        parentId,
        parentEntity,
        viewName,
        formId = 'record-form',
        parentField,
        redirect,
        toolbar,
        injectValues,
        location,
        interceptSubmit,
        hideFields,
        evaluatedAdhocSPELVariables,
    } = props;
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[props.viewName].entity;
    const { element: overrideTitleElement } = useOverrideTitle(props.viewName);
    const viewTitle = viewConfig.entities[resource]?.displayName || resource;
    const titleElement =
        overrideTitleElement ??
        (() => {
            if (typeof title === 'string' && title) {
                return <SafeHtmlAsReact html={title} />;
            }
            return <span>{title || <SafeHtmlAsReact html={`Create ${viewTitle}`} />}</span>;
        })();
    const isLoading = useEntitiesAreLoading();
    const dispatch = useDispatch();
    const getValueSetCodeLiterals = useMemo(getEditableViewVSCodeLiteralsSelector, []);
    const valueSetCodeLiterals = useSelector((state: RootState) =>
        getValueSetCodeLiterals(state, {
            viewName,
            resource,
            overrideViewConfig: viewConfig,
        }),
    );
    useEffect(() => {
        const valuesets = uniq(
            getValueSetCodesRequiredForEntity(viewConfig, viewName).concat(valueSetCodeLiterals),
        ).map((valueSetCode) => ({ valueSet: valueSetCode }));
        dispatch(loadValueSetsAction(valuesets));

        if (props.formId) {
            dispatch(setAsTopView(props.formId));
            return () => {
                dispatch(unsetAsTopView(props.formId));
            };
        }
    }, []); // eslint-disable-line

    useRegisterViewDetails(formId, 'entity');

    const getRedirectPath = React.useCallback(
        (newId: string) => {
            if (redirect === false) {
                return undefined;
            }
            // redirect back to our creator if we have a parent set.
            if (parentId && parentEntity) {
                return `/${parentEntity}/${parentId}${
                    allowsEdit(getAccessLevelForEntity(viewConfig, parentEntity)) ? '' : '/show'
                }`;
            }
            return `/${resource}/${newId}/${allowsEdit(getAccessLevelForEntity(viewConfig, resource)) ? '' : 'show'}`;
        },
        [resource, parentEntity, redirect, parentId, viewConfig],
    );
    const isClppp = viewConfig.application.name === 'clppp';
    const save = useCallback<Save>(
        (record, alwaysCb) => {
            const onCreateCb =
                _onCreateCb || alwaysCb
                    ? (id, data) => {
                          if (_onCreateCb) {
                              _onCreateCb(id, data);
                          }
                          const redirectPath = getRedirectPath(id);
                          if (redirectPath) {
                              dispatch(pushAction(redirectPath));
                          }
                          if (alwaysCb) {
                              alwaysCb(null);
                          }
                      }
                    : undefined;
            const errorsCbs = alwaysCb ? { '*': alwaysCb } : {};
            const createParams = {
                resource,
                data: record,
                cb: onCreateCb,
                errorsCbs,
                restUrl: resource === 'User' && !isClppp ? 'api/new-user' : undefined,
            } as const;
            if (interceptSubmit) {
                interceptSubmit(createParams);
            }
            dispatch(crudCreateAction(createParams));
        },
        [isClppp, interceptSubmit, resource, _onCreateCb, dispatch, getRedirectPath],
    );
    return (
        <formTypeContext.Provider value="CREATE">
            <SsgAppBarMobile title={titleElement} />
            <div className="create-page">
                <Card style={{ opacity: isLoading ? 0.8 : 1, padding: '.5em' }}>
                    {/* actions here */}
                    <HideBackToController viewName={viewName}>
                        <div style={{ float: 'right' }}>
                            <BackTo />
                        </div>
                    </HideBackToController>
                    <SimpleForm
                        evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                        TitleElement={<ViewTitle displayAboveWidth="xs" title={titleElement} />}
                        search={location?.search}
                        viewName={viewName}
                        formId={formId}
                        injectValues={injectValues}
                        save={save}
                        toolbar={toolbar}
                        parentEntityIdValue={parentId}
                        parentEntityName={parentEntity}
                        parentField={parentField}
                        hideFields={hideFields}
                    />
                </Card>
            </div>
        </formTypeContext.Provider>
    );
};

const CreateWrapper = withRestrictions(Create);
export default CreateWrapper;
