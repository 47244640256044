import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../reducers/rootReducer';
import { allowsEdit, getAccessLevelForEntity } from './generics/utils/viewConfigUtils';
import { createSelector } from 'reselect';

interface RenderLinkToRecordParam {
    href: string | null;
    getHref: (pageType: 'SHOW' | 'EDIT') => string | null;
}
interface LinkToRecordProps {
    referenceResource: string;
    referenceId: string;
}
interface LinkToRecordComponentProps extends LinkToRecordProps, RenderLinkToRecordParam {
    renderLinkToRecord: (arg: RenderLinkToRecordParam) => JSX.Element | null;
}
class LinkToRecordComponent extends React.Component<LinkToRecordComponentProps> {
    render() {
        const { renderLinkToRecord, href, getHref } = this.props;
        return renderLinkToRecord({ href, getHref });
    }
}
const getGetHref = (resource: string, id: string) => (pageType: 'SHOW' | 'EDIT') => {
    if (!resource || !id) {
        return null;
    }
    return `/${resource}/${encodeURIComponent(id)}${pageType === 'EDIT' ? '' : '/show'}`;
};

const getHrefSelector = () =>
    createSelector(
        (state: RootState, props: LinkToRecordProps) => state.viewConfig,
        (state: RootState, props: LinkToRecordProps) => props.referenceResource,
        (state: RootState, props: LinkToRecordProps) => props.referenceId,
        (viewConfig, resource, id): RenderLinkToRecordParam => {
            const entityIsEditable = allowsEdit(getAccessLevelForEntity(viewConfig, resource));
            const getHref = getGetHref(resource, id);
            return {
                href: getHref(entityIsEditable ? 'EDIT' : 'SHOW'),
                getHref,
            };
        },
    );
const makeMapStateToProps = () => {
    const hrefSelector = getHrefSelector();
    return (state: RootState, props: LinkToRecordProps) => {
        return hrefSelector(state, props);
    };
};
const LinkToRecord = connect(makeMapStateToProps)(LinkToRecordComponent);

export default LinkToRecord;
