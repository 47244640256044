import { initial, RemoteData, success, failure, pending } from '@devexperts/remote-data-ts';
import { Button, CircularProgress } from '@material-ui/core';
import { TaskFormBuilder } from 'bpm/task-form-editor/TaskFormBuilder';
import memoizeOne from 'memoize-one';
import React, { useState, useEffect, useMemo } from 'react';
import { TaskForm } from '../reducers/taskFormType';
import { Link } from 'react-router-dom';
import { ajax, AjaxError } from 'rxjs/ajax';
import ArrowBack from '@material-ui/icons/ArrowBack';

interface FormProps {
    url: string;
    id: string;
}
type FormData = {
    description: string;
    formDefinition: TaskForm;
    version: number;
    id: string;
    key: string;
    lastUpdated: Date;
    lastUpdatedBy: string;
    name: string;
};
const Form: React.FC<FormProps> = ({ url, id }) => {
    const [state, setState] = useState<RemoteData<AjaxError, FormData>>(initial);
    useEffect(() => {
        if (state.isPending()) {
            const $ajax = ajax({
                url: url + '/modeler/app/rest/form-models/' + id,
                withCredentials: true,
                responseType: 'json',
                headers: {
                    credentials: 'same-origin',
                },
            });

            const subscription = $ajax.subscribe(
                (data) => {
                    setState(success(data.response));
                },
                (err) => {
                    setState(failure(err));
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [state, id, url]);
    useEffect(() => {
        setState(pending);
    }, []);
    const removeValuesFromForm = useMemo(
        () =>
            memoizeOne((form: TaskForm): Partial<TaskForm> => {
                return {
                    ...form,
                    fields: form.fields?.map(({ value, ...f }) => {
                        if (f.type === 'expression') {
                            return {
                                ...f,
                                value: f.expression,
                            };
                        }
                        return f;
                    }) as TaskForm['fields'],
                };
            }),
        [],
    );
    return (
        <div>
            <div style={{ paddingLeft: '1em' }}>
                <Button variant="contained" color="primary" component={Link} to="/bpm-modeler">
                    <ArrowBack />
                    &nbsp;Back
                </Button>
            </div>

            {state.fold(
                null,
                <CircularProgress />,
                (err) => (
                    <div>Failed: try again.</div>
                ),
                (data) => (
                    <TaskFormBuilder
                        modelerUrl={url}
                        formId={id}
                        initialTaskForm={removeValuesFromForm(data.formDefinition)}
                    />
                ),
            )}
        </div>
    );
};
export default Form;
