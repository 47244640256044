import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

export type ExpressionTesterOpen = 'OPEN_ALL' | 'OPEN_EXPRESSIONPANEL' | 'CLOSED';

export const expressionTesterOpenContext = React.createContext<ExpressionTesterOpen>('CLOSED');

const useExpressionTesterOpen = (): ExpressionTesterOpen => {
    const expressionTesterOpenInContext = useContext(expressionTesterOpenContext);
    const open = useSelector((state: RootState) => state.expressionTesterOpen);
    return open ? 'OPEN_ALL' : expressionTesterOpenInContext;
};
export default useExpressionTesterOpen;
