import { ExpressionEventType } from '../actions/expressionTestActions';

const initialState = {};

export default function basicInfoReducer(state = initialState, action) {
    switch (action.type) {
        case ExpressionEventType.SubmitExpressionSuccess:
            return {
                ...state,
                value: action.body,
            };
        case ExpressionEventType.SubmitExpressionFailure:
            return {
                ...state,
                value: action.body,
            };
        case ExpressionEventType.ClearValue:
            return {
                ...state,
                value: null,
                // config: null
            };
        case ExpressionEventType.SubmitVisExpressionTask:
            return {
                ...state,
                configTask: action.visExpression,
            };
        case ExpressionEventType.SubmitVisExpressionEntity:
            return {
                ...state,
                configEntity: action.expressionConfig,
            };
        case ExpressionEventType.ClearTaskOverrides:
            return {
                ...state,
                configTask: null,
            };
        case ExpressionEventType.ClearEntityOverrides:
            return {
                ...state,
                configEntity: null,
            };
        default:
            return state;
    }
}
