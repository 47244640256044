let currentLangRegistry: {
    langCode: string;
} = {
    langCode: 'en',
};

export const setCurrentLang = (langCode: string) => {
    currentLangRegistry.langCode = langCode;
};

export const getCurrentLang = (): string => {
    return currentLangRegistry.langCode;
};
