import { DEBUG_MODE_ON } from 'actions/constants';
import { setCurrentlyWritingToOffline } from 'offline_app/offline_stateful_tasks/currentlyWritingToOfflineState/actions';
import loadTaskStateFromIDB from 'offline_app/offline_stateful_tasks/util/loadTaskStateFromIDB';
import { RootState } from 'reducers/rootReducer';
import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import { Store } from 'redux';
import { getTaskId } from './util';

export const loadTaskStateToStore = (store: Store, onLoad: (taskId: string) => void) => {
    const taskId = getTaskId(store.getState().router.location.pathname);
    if ((store.getState() as RootState).taskCurrentlyWritingToOffline !== taskId) {
        store.dispatch(setCurrentlyWritingToOffline(taskId));
        if (!store.getState().debugMode) {
            store.dispatch({ type: 'EPIC_END' });
            store.dispatch({ type: DEBUG_MODE_ON });
        }
        (async () => {
            const existingEntry = await idbKeyval.get(taskId);
            /*
                We  need to short circuit if there's no existing entry
                (to prevent infinite wait loop in loadTaskStateFromIDB)
            */
            if (!existingEntry) {
                return;
            }
            loadTaskStateFromIDB(taskId, store, () => onLoad(taskId));
        })();
    }
};
