import { offlineEntitySubmitsIdbKeyVal } from '../../IndexedDB/offlineEntitySubmits';
import { offlineTaskExpirationDatesKeyVal } from '../../IndexedDB/offlineTaskExpirationDates';
import { idbKeyval } from '../../IndexedDB/offlineTasksDb';
import { offlineTaskToEntityKeyVal } from '../../IndexedDB/offlineTaskToEntityKeyMap';
import { offlineTaskToProfileIdbKeyVal } from '../../IndexedDB/offlineTaskToProfile';
import { offlineTaskIsPristine } from '../../IndexedDB/offlineTaskIsPristine';
import moment from 'moment';

export const deleteOfflineTaskData = async (taskId: string) => {
    // delete this, AND all associated entity saves + profile data

    // delete primary data entry
    await idbKeyval.delete(taskId);

    // delete expiration entry.
    await offlineTaskExpirationDatesKeyVal.delete(taskId);
    // delete pristine flag
    await offlineTaskIsPristine.delete(taskId);
    // iterate through associated entity saves and delete each.
    const entitySubmitsForTask = await offlineTaskToEntityKeyVal.get(taskId);
    if (entitySubmitsForTask) {
        let entitySubmitKeysForTask = Object.keys(entitySubmitsForTask);
        for (let i = 0; i < entitySubmitKeysForTask.length; i++) {
            const entitySubmitKey = entitySubmitKeysForTask[i];
            await offlineEntitySubmitsIdbKeyVal.delete(entitySubmitKey);
        }
        await offlineTaskToEntityKeyVal.delete(taskId);
    }
    // delete associated profile data
    await offlineTaskToProfileIdbKeyVal.delete(taskId);
};

export const deleteExpiredTasks = async (): Promise<boolean> => {
    if (process.env.NODE_ENV === 'test') {
        return false;
    }
    let someTaskKeyDeleted = false;
    const deleteTaskDataEntry = async (taskId: string) => {
        await deleteOfflineTaskData(taskId);
        someTaskKeyDeleted = true;
    };
    const allTaskKeys = await idbKeyval.keys();
    const allExpiryKeys = await offlineTaskExpirationDatesKeyVal.keys();
    const allProfileKeys = await offlineTaskToProfileIdbKeyVal.keys();

    // delete all expiry keys without matching task entries.
    for (let i = 0; i < allExpiryKeys.length; i++) {
        const key = allExpiryKeys[i];
        if (!allTaskKeys.includes(key)) {
            await offlineTaskExpirationDatesKeyVal.delete(key);
        }
    }
    // delete all profile keys without matching task entries.
    for (let i = 0; i < allProfileKeys.length; i++) {
        const key = allProfileKeys[i];
        if (!allTaskKeys.includes(key)) {
            await offlineTaskToProfileIdbKeyVal.delete(key);
        }
    }
    // check that off task keys have matching expiry keys
    for (let i = 0; i < allTaskKeys.length; i++) {
        const taskId = allTaskKeys[i];
        if (!allExpiryKeys.includes(taskId)) {
            console.error('expiry key not found for task key "' + taskId + '"');
            await deleteTaskDataEntry(taskId);
        } else {
            const expiryEntry = await offlineTaskExpirationDatesKeyVal.get(taskId);
            const mmtExpires = moment(expiryEntry.expires);
            if (!expiryEntry?.expires || !mmtExpires.isValid()) {
                throw new Error('invalid format for expiry entry: ' + JSON.stringify(expiryEntry));
            }
            // check if expires is after today
            const isCurrentlyExpired = mmtExpires.isSameOrBefore(new Date());
            if (isCurrentlyExpired) {
                await deleteTaskDataEntry(taskId);
            }
        }
    }
    return someTaskKeyDeleted;
};
export default deleteExpiredTasks;
