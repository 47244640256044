import React, { useContext, FunctionComponent } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { TextField, Button } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import EntityTypeSelect from 'layout-editor/build-layout/fields/EntityTypeSelect';
import ViewTypeSelect from 'layout-editor/build-layout/fields/ViewTypeSelect';
import { View } from 'reducers/ViewConfigType';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import NavigateNext from '@material-ui/icons/NavigateNext';

interface Step1Props {
    initialValues: Partial<View>;
    onSubmit: (action: {
        type: 'replace' | 'write';
        payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
    }) => void;
}
const Step1: FunctionComponent<Step1Props> = (props) => {
    const { onSubmit: _onSubmit, initialValues } = props;

    const methods = useForm({
        defaultValues: initialValues,
        mode: 'onBlur',
    });
    const { handleSubmit, errors } = methods;
    const onSubmit = ({ name, entity, viewType, route: _route }) => {
        const route = viewType === 'COMPONENT' ? undefined : _route;
        // we erase any existing data if entity changed, since we are at step 1
        if (entity !== initialValues.entity) {
            _onSubmit({ type: 'replace', payload: { name, entity, viewType, route } });
        } else {
            _onSubmit({ type: 'write', payload: { name, entity, viewType, route } });
        }
    };

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <div style={{ padding: '1em', margin: '1em' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                    <NavWarning />
                    <h2>Step 1: Basic View Information</h2>
                    <EntityTypeSelect
                        fieldName="entity"
                        label="Entity *"
                        required="A view requires an entity"
                        defaultValue={initialValues && initialValues.entity}
                    />
                    <Controller
                        rules={{ required: 'A view needs a name' }}
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        label="Name *"
                        as={TextField}
                        variant={fieldVariant}
                        margin="normal"
                        error={Array.isArray(errors.name) ? errors.name.length > 0 : Boolean(errors.name)}
                        helperText={<ErrorMessage errors={errors} name="name" />}
                        // defaultValue={props.initialValues && props.initialValues['name']}
                        fullWidth
                        name="name"
                        control={methods.control}
                    />
                    <ViewTypeSelect
                        required
                        fullWidth
                        defaultValue={(initialValues && initialValues.viewType) || null}
                    />
                    {methods.watch('viewType') === 'COMPONENT' ? null : (
                        <Controller
                            InputLabelProps={getInputLabelProps({ shrink: true })}
                            variant={fieldVariant}
                            label="Route"
                            as={TextField}
                            margin="normal"
                            helperText="Provide a route if you would like the view to be accessible through a url"
                            // defaultValue={props.initialValues && props.initialValues['route']}
                            fullWidth
                            name="route"
                            control={methods.control}
                        />
                    )}
                    <div style={{ marginTop: '1em', textAlign: 'right' }}>
                        <Button id="step1-submit" variant="contained" color="primary" type="submit">
                            Submit&nbsp;
                            <NavigateNext />
                        </Button>
                    </div>
                </FormProvider>
            </form>
        </div>
    );
};

export default Step1;
