import { ReportDefinition } from 'report2/ReportDefinition';
import { PersonalizedReportConfig } from 'custom-reports/types';

const getInitialConfig = <ReportDef extends Pick<ReportDefinition, 'params'>>(
    reportDef: ReportDef,
): PersonalizedReportConfig => {
    return {
        // below: all columns initially
        // outputColumns: reportDef.outputColumns,
        outputColumns: [],
        sort: [],
        params: reportDef.params.reduce((prev, curr) => {
            prev[curr.name] = {
                // all hidden to start
                visibility: 'hidden',
            };
            return prev;
        }, {}),
    };
};

export default getInitialConfig;
