import * as del from './actions';
import { isActionOf } from 'typesafe-actions';
import { Services } from 'sideEffect/services';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap } from 'rxjs/operators';
import coreCrudDeleteFlow from 'sideEffect/crud/util/epics/CoreCrud/delete';

const crudDeleteFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(del.crudDelete)),
        withLatestFrom(state$.pipe(map((state) => state.viewConfig))),
        flatMap(([action, viewConfig]) => {
            const { resource, id, cb, restUrl, errorsCbs } = action.payload;
            return coreCrudDeleteFlow(
                {
                    restUrl: (restUrl || viewConfig.entities[resource].restUrl) + '/' + id,
                },
                {
                    service: services.crudDelete,
                    failureAction: del.crudDeleteFailure,
                    successAction: del.crudDeleteSuccess,
                    successCb: cb,
                    errorsCbs,
                },
                {
                    resource,
                    viewConfig,
                    initialRequestPayload: action.payload,
                },
            );
        }),
    );
export default crudDeleteFlow;
